/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/prop-types */
import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect, useContext, useMemo } from 'react';

import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
} from 'material-react-table';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReactMaterialTable from 'src/layouts/_common/react-material-table';
import moment from 'moment';
import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  Typography,
  lighten,
  Table,
  Container,
  TableBody,
  TableContainer,
  Tab,
  Card,
  Tabs,
  alpha,
  Tooltip,
  IconButton,
} from '@mui/material';


import { AccountCircle, Send, Print } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import queryString from 'query-string';
import { useSnackbar } from 'src/components/snackbar';
import { useDebounce } from 'src/hooks/use-debounce';
import {
  useTable,
  getComparator,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import { fetcher, endpoints, destroy } from 'src/utils/axios';
import { useEventListener } from 'src/hooks/use-event-listener';
import { TableRowsLoader, UPermission } from 'src/_mock/constant_funcation';

import useSWR, { mutate } from 'swr';
import { AuthContext } from 'src/auth/context/jwt';
import UserTableRow from '../table-row';
import UserTableToolbar from '../table-toolbar';
import UserTableFiltersResult from '../table-filters-result';
import UserQuickEditForm from '../quick-edit-form';



// ----------------------------------------------------------------------

const STATUS_OPTIONS = [{ value: 'all', label: 'All' }];

const TABLE_HEAD = [
  { id: 'no', label: 'No.', width: 90 },
  { id: 't_date', label: 'Voucher Date' },
  { id: 'id', label: 'Voucher No.' },
  { id: 'cb', label: 'Cash/Debit' },
  { id: 'account', label: 'Party Name' },
  { id: 'amount', label: 'Bill Amount', width: 150 },
  { id: '', label: 'Action', width: 90 },
];

const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
};

// ----------------------------------------------------------------------

export default function ListView() {
  // const table = useTable();
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();
  const { user } = useContext(AuthContext);
  const permission = UPermission('component', 'automobiledisbursementListPage');

  // const columns = useMemo(
  //   () => [
  //     {
  //       accessorFn: (row) => `${row.identity}`,
  //       id: 'identity',
  //       header: 'Sr No.',
  //       size: 150,
  //       datatype: "number",
  //     },
  //     {
  //       accessorFn: (row) => `${row.entry_details.find(x => x.order === 2).account.name}`,
  //       id: 'entry_details->account.name',
  //       header: 'Financer',
  //       size: 250,
  //       datatype: "string",
  //     },
  //     {
  //       accessorKey: 'amount',
  //       header: 'Amount',
  //       size: 200,
  //       datatype: 'amount'
  //     },
  //     {
  //       accessorFn: (row) => new Date(row.dt),
  //       id: 'dt',
  //       header: 'Created Date',
  //       filterVariant: 'date',
  //       sortingFn: 'datetime',
  //       datatype: "date",
  //     },
  //   ],
  //   [],
  // );
  const quickEdit = useBoolean();
  const columnConfig = [
    {
      accessorFn: (row) => `${row.identity}`,
      id: 'identity',
      header: 'Sr No.',
      size: 150,
      datatype: 'text',
    },
    {
      accessorFn: (row) => `${row.entry_details?.find(x => x.order === 2).account.name}`,
      id: 'entry_details->account.name',
      header: 'Financer',
      size: 250,
      datatype: 'text',
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      size: 200,
      datatype: 'currency',
    },
    {
      accessorFn: (row) => new Date(row.dt),
      id: 'dt',
      header: 'Created Date',
      size: 200,
      datatype: 'date',
    },
  ];
  return (
    <ReactMaterialTable componentName="automobiledisbursementListPage" url="automobile/entries/?" key="12" QuickEditForm={UserQuickEditForm} />
  );
}
