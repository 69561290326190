import { FormatMoney } from 'format-money-js';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { Skeleton, TableCell, TableRow, useTheme } from '@mui/material';
import { AuthContext } from 'src/auth/context/jwt';
import { isNaN } from 'lodash';
import writtenNumber from 'written-number';

export const convertToWords = (number) => {
  // Convert number to words
  const words = writtenNumber(number, { lang: 'en' });

  // Capitalize the first letter and append "only"
  return `${words.charAt(0).toUpperCase() + words.slice(1)} only`;
}
export const stickHeader = () => {
  const tables = document.querySelectorAll('table.pvtTable')
  Array.from(tables).forEach(table => {
    const trs = table.querySelectorAll('thead tr')
    Array.from(trs).reduce((prev, cur) => {
      const height = cur.getBoundingClientRect().height
      cur.childNodes.forEach(node => {
        // console.info(node)
        node.setAttribute('style', `position: sticky; top: ${prev}px`)
      })
      return height + prev
    }, 0)
  })
}

export const nearestDateList = (data) => {
  let nearestDate = moment();
  let smallestDifference = 0;
  let obj = {};
  if (Array.isArray(data))
    for (const record of data) {
      const date = moment(record.appdate, 'YYYY-MM-DD');
      const difference = moment().diff(date, 'minutes');

      if (date.isSame(moment())) {
        nearestDate = date;
        obj = record;
        break;
      }

      if ((difference >= 0 && difference < smallestDifference) || smallestDifference === 0) {
        smallestDifference = difference;
        nearestDate = date;
        obj = record;
      }
    }
  return obj;
};

// export const [selectedRow, setSelectedRow] = [0,()=>{}];

export const handleRowClick = (rowId, setSelectedRow, selectedRow) => {
  setSelectedRow(rowId === selectedRow ? 0 : rowId);
};

export const handleKeyDownInTable = (
  event,
  maxCount,
  rowId,
  selectedRow,
  setSelectedRow,
  tableEnterKeyListner,
  defaultValue = 0,
  modalStatus
) => {
  if (modalStatus === window.$accLedList) {
    if (event.key === 'ArrowDown') {
      setSelectedRow(rowId === selectedRow ? 0 : rowId);
      const nextRowId = selectedRow + 1;
      const rowCount = maxCount - 1;
      if (nextRowId <= rowCount) {
        setSelectedRow(nextRowId);
      } else {
        setSelectedRow(defaultValue);
      }
    } else if (event.key === 'ArrowUp') {
      setSelectedRow(rowId === selectedRow ? 0 : rowId);
      const nextRowId = selectedRow - 1;
      if (nextRowId >= defaultValue) {
        setSelectedRow(nextRowId);
      } else {
        setSelectedRow(maxCount - 1);
      }
    } else if (event.key === 'Enter') {
      tableEnterKeyListner();
    }
  }
};

export const handleOnWheelScroll = (
  e,
  setSelectedRow,
  selectedRow,
  maxCount,
  defaultValue = 0,
  modalStatus
) => {
  if (modalStatus === window.$accLedList)
    if (e.deltaY > 0) {
      const nextRowId = selectedRow + 1;
      const rowCount = maxCount - 1;
      if (nextRowId <= rowCount) {
        setSelectedRow(nextRowId);
      } else {
        setSelectedRow(defaultValue);
      }
    } else {
      const nextRowId = selectedRow - 1;
      if (nextRowId >= defaultValue) {
        setSelectedRow(nextRowId);
      } else {
        setSelectedRow(maxCount - 1);
      }
    }
};

class FormatMoneys {
  constructor(options) {
    this.grouping = options.grouping;
    this.decimals = options.decimals;
  }

  format(value) {
    if (!this.grouping) {
      return value.toFixed(this.decimals);
    }
    // Format number for 'en-IN' locale with specified decimals
    return `${Number(value).toLocaleString('en-IN', {
      minimumFractionDigits: this.decimals,
      maximumFractionDigits: this.decimals,
    })}`;
  }
}

export const fMoney = new FormatMoneys({
  grouping: true,
  decimals: 2,
});

export const NumericFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      thousandsGroupStyle="lakh"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const TableRowsLoader = ({ rowsNum, colNum }) =>
  [...Array(rowsNum)].map((row, index) => (
    <TableRow key={index}>
      {[...Array(colNum)].map((row, index) => (
        <TableCell key={index} component="th" scope="row">
          <Skeleton animation="wave" variant="text" />
        </TableCell>
      ))}
    </TableRow>
  ));

export const UPermission = (key, value) => {
  const { user } = useContext(AuthContext);
  const modules = user?.modules;

  function searchItem(module) {
    if (module[key] === value) {
      return module;
    }

    if (module.children && module.children.length > 0) {
      for (const child of module.children) {
        const found = searchItem(child);
        if (found) {
          return found;
        }
      }
    }

    return null;
  }

  for (const module of modules) {
    const found = searchItem(module);
    if (found) {
      return found?.user_permission;
    }
  }

  return null;
};

export const useDynamicKeyHandler = (keyCombination, action) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      const isKeyCombinationMatched = Object.entries(keyCombination).every(
        ([key, value]) =>
          (key === 'key' && value.toLowerCase() === event.key.toLowerCase()) || event[key] === value
      );

      if (isKeyCombinationMatched) {
        event.preventDefault();
        event.stopPropagation();
        action();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [keyCombination, action]);
};

export function formatDate(date) {
  const d = new Date(date);
  const day = d.getDate().toString().padStart(2, '0');
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const year = d.getFullYear();
  return `${day}/${month}/${year}`;
}

export const useBgColorAutocomplete = () => {
  const theme = useTheme();
  return {
    sx: {
      '& .Mui-focused': {
        bgcolor: `${theme.palette.primary.light} !important`,
        fontWeight: 'bold',
      },
    },
  };
};

export const onScollerAutoComplate = ({ setList, setValue, data, fieldName, meta }) => {
  if (meta.page > 1) {
    setList((pre) => [...pre, ...data.rows.map((x) => ({ ...x, a: 'name' }))]);
  }
  if (data?.rows?.length === 0) {
    setList([]);
  }
  if (meta.page === 1) {
    setList(data.rows.map((x) => ({ ...x, a: 'name' })));
  }
  setValue(`${fieldName}.totalPage`, data.totalPage);
};

export const assignNullToEmptyOrUndefinedValues = (obj) => {
  // Iterate over each key in the object
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      // If the value is an object, recursively process it
      assignNullToEmptyOrUndefinedValues(obj[key]);
    } else if (obj[key] === '' || obj[key] === undefined) {
      // If the value is an empty string or undefined, assign null
      obj[key] = null;
    }
  });
  return obj;
};

export const downloadFile = async (URL, name) => {
  try {
    // Fetch the file data
    const response = await fetch(URL);
    const blob = await response.blob();

    // Create a Blob URL
    const blobUrl = window.URL.createObjectURL(blob);

    // Create a download link
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = name; // Set the desired filename here

    // Append the link to the document
    document.body.appendChild(link);

    // Simulate a click on the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);

    // Clean up the Blob URL
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error(error);
  }
};

export const sanitizeComplexObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (Array.isArray(value)) {
      // If the property is an array, iterate through its elements
      value.forEach((item) => {
        if (typeof item === 'object' && item !== null) {
          sanitizeComplexObject(item); // Recursive call for objects within the array
        }
      });
    } else if (typeof value === 'object' && value !== null) {
      // Recurse if the property is an object
      sanitizeComplexObject(value);
    } else {
      // Assign null if the property is '' or undefined
      // eslint-disable-next-line no-lonely-if
      if (value === '' || value === undefined) obj[key] = null;
    }
  });
  return obj;
};

export const truncateToTwoDecimals = (num) => {
  // Check if the input is a valid number (either integer or decimal)
  if (num === undefined || num === null) {
    return 0;
  }
  const numStr = num.toString();
  const isValidNumber = /^[0-9]+(\.[0-9]+)?$/.test(numStr);

  if (!isValidNumber) {
    return 0;
  }

  // Convert to number if it's a valid number string
  const parsedNum = parseFloat(numStr);

  // Truncate the number to two decimal places
  const truncated = Math.floor(parsedNum * 100) / 100;

  return truncated;
}

export const checkPropertiesTypeIsNumber = (obj, properties) => properties.every(prop => typeof obj[prop] === 'number')


export function calculateNetAmount(mrp, gstRate) {
  // Calculate the divisor for the GST calculation
  const divisor = 1 + gstRate / 100;

  // Calculate the original price before GST
  const originalPrice = mrp / divisor;

  // Return the original price, rounded to two decimal places if needed
  return originalPrice.toFixed(2);
}

export function filterCities(cities, searchTerm) {
  const searchLower = searchTerm.toLowerCase();

  // Cities that exactly match the search term in both content and length
  const exactMatches = cities.filter(city => city.name.toLowerCase() === searchLower);

  // Cities that start with the search term
  const matchingStart = cities.filter(city => city.name.toLowerCase().startsWith(searchLower) && city.name.toLowerCase() !== searchLower);

  // Cities that contain the search term but do not start with it
  const matchingContain = cities.filter(city => city.name.toLowerCase().includes(searchLower) && !city.name.toLowerCase().startsWith(searchLower));

  // Concatenate all arrays
  return [...exactMatches, ...matchingStart, ...matchingContain];
}

export function convertToRound(value) {
  return Math.round(value);
}

export function minmax(e, min, max) {
  let value = parseFloat(e.target.value);
  if (isNaN(value)) {
    value = '';
  } else if (value < min) {
    value = min;
  } else if (value > max) {
    value = max;
  }
  e.target.value = value;
};

export function stringToFunction(fnString) {
  try {
    // Convert the string to a function using the Function constructor
    // This should be used carefully due to potential security risks
    // eslint-disable-next-line no-new-func
    return new Function('row', `return (${fnString})(row);`);
  } catch (error) {
    console.error('Error converting string to function:', error);
    return () => null; // Fallback in case of error
  }
};
