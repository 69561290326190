import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Unstable_Grid2';
import { DateField, DatePicker } from '@mui/x-date-pickers';

import {
  Autocomplete,
  Divider,
  IconButton,
  InputAdornment,
  Tooltip,
  ListItem,
  TextField,
  Toolbar,
  Typography,
  alpha,
  Stack,
  FormControlLabel,
  RadioGroup,
  Radio,
  MenuItem,
} from '@mui/material';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFSelect, RHFTextField, RHFUpload } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { DSA_SELF, GENDER, HPATYPE, INOUTHOUSE, KEY, KMSDRIVEN } from 'src/_mock/constant';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import {
  assignNullToEmptyOrUndefinedValues,
  filterCities,
  fMoney,
  useBgColorAutocomplete,
} from 'src/_mock/constant_funcation';
import { Box } from '@mui/system';
import { Icon } from '@iconify/react';
import { useDebounce } from 'src/hooks/use-debounce';
import { handleScroll } from 'src/layouts/_common/scroller';
import { AuthContext } from 'src/auth/context/jwt';
import { HOST_API } from 'src/config-global';
import axios from 'axios';
import SuccessConfirmDialog from 'src/components/custom-dialog/confirm-dialog';
import { useBoolean } from 'src/hooks/use-boolean';
import { useGetCommon, useGetFinances, useGetZones } from '../work_shop/global';
// ----------------------------------------------------------------------

export default function UserQuickEditForm({
  row,
  open,
  onClose,
  table,
  getAll,
  tableData,
  setPDFViwer,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [pricesData, setPricesData] = useState([]);
  const [productList, setProductList] = useState([]);
  const [rtoList, setRtoList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accountId, setAccountId] = useState(null);

  const [financierBranchMaster, setFinancierBranchMaster] = useState([]);

  const [bookingStatus, setBookingStatus] = useState(false);
  const { user } = useContext(AuthContext);
  const CurreCompany = user.companies?.find((x) => x?.id === user?.companyId);

  const bgColorAutocomplete = useBgColorAutocomplete();

  const printModel = useBoolean();

  const principalRef = useRef(null);
  const schemeRef = useRef(null);
  const occuRef = useRef(null);
  const refrenceRef = useRef(null);

  const BookingSchema = Yup.object().shape({
    aenquiryId: Yup.number().required('required'),
    customer: Yup.object().shape({
      firstname: Yup.string().required('First Name is required'),
      lastname: Yup.string().required('Last Name is required'),

      cityId: Yup.number().required('City is required'),
      gender: Yup.number().required('Gender is required'),
      mobile1: Yup.string()
        .required('Mobile number is required')
        .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits'),
      localityId: Yup.number().when('address1', {
        is: (val) => val === null || val === '',
        then: () => Yup.number().nullable().notRequired(),
        otherwise: () => Yup.number().required('Select Zone is required'),
      }),
    }),

    salesmanId: Yup.number().required('Salesman is required'),
    donebyId: Yup.number().required('donebyId is required'),
    amakerId: Yup.number().when('exchange', {
      is: (val) => val === true,
      then: () => Yup.number().required('Vehicle Maker is required'),
      otherwise: () => Yup.number().nullable().notRequired(),
    }),
    emodelId: Yup.number().when('exchange', {
      is: (val) => val === true,
      then: () => Yup.number().required('Vehicle Model is required'),
      otherwise: () => Yup.number().nullable().notRequired(),
    }),
    yofmfd: Yup.number().when('exchange', {
      is: (val) => val === true,
      then: () => Yup.string().required('Model Year is required'),
      otherwise: () => Yup.string().nullable().notRequired(),
    }),
    kmsdriven: Yup.number().when('exchange', {
      is: (val) => val === true,
      then: () => Yup.number().required('Vehicle Model is required'),
      otherwise: () => Yup.number().nullable().notRequired(),
    }),
    eamt: Yup.number().when('exchange', {
      is: (val) => val === true,
      then: () => Yup.string().required('Model Year is required'),
      otherwise: () => Yup.string().nullable().notRequired(),
    }),
    exchangeId: Yup.number().when('exchange', {
      is: (val) => val === true,
      then: () => Yup.number().required('Vehicle Model is required'),
      otherwise: () => Yup.number().nullable().notRequired(),
    }),
    eregino: Yup.number().when('exchange', {
      is: (val) => val === true,
      then: () => Yup.string().required('Model Year is required'),
      otherwise: () => Yup.string().nullable().notRequired(),
    }),

    amodcategoryId: Yup.number().required('Model Category is required'),
    amodelId: Yup.number().required('Product Model is required'),
    avariantId: Yup.number().required('Product Variant is required'),
    amtocId: Yup.number().required('MTOC (Product) is required'),

    apurtypeId: Yup.number().required('Purchase Type is required'),
    afinanceId: Yup.number().when('apurtypeId', {
      is: (val) => val === 2,
      then: () => Yup.number().required('Financier is required'),
      otherwise: () => Yup.number().nullable().notRequired(),
    }),
    financebrancheId: Yup.number().when('apurtypeId', {
      is: (val) => val === 2,
      then: () => Yup.number().required('Financier Branch is required'),
      otherwise: () => Yup.number().nullable().notRequired(),
    }),
    inouthouse: Yup.number().required('In Out House is required'),
    areasoneId: Yup.number().when('afterday', {
      is: (val) => val === '0',
      then: () => Yup.number().required('Reason is required'),
      otherwise: () => Yup.number().nullable().notRequired(),
    }),

    // images: Yup.array().min(1, 'Minimum 1 image is required'),
  });

  const metalist = useMemo(
    () => ({
      productMeta: {
        URL: 'automobile/products/',
        query: {
          status: true,
          category: 'VEHICLE',
          limit: 20,
          name: '',
        },
        editRow: null,
      },
      aenqcatieMeta: {
        URL: 'automobile/enquiries/categories/',
        query: {
          limit: 10,
          name: '',
        },
        editRow: null,
      },
      aenqtypesMeta: {
        URL: 'automobile/enquiries/types/',
        query: {
          limit: 500,
          name: '',
        },
        editRow: null,
      },
      modelMakersMeta: {
        URL: 'automobile/product/makers/',
        query: {
          limit: 50000,
          name: '',
        },
        editRow: null,
      },
      modelCategoryMeta: {
        URL: 'automobile/product/categories/',
        query: {
          limit: 50000,
          name: '',
        },
        editRow: null,
      },
      productModelMeta: {
        URL: 'automobile/product/models/',
        query: {
          limit: 50000,
          name: '',
        },
        editRow: null,
      },
      productVariantMeta: {
        URL: 'automobile/product/variants/',
        query: {
          limit: 50000,
          name: '',
        },
        editRow: null,
      },
      productMtocMeta: {
        URL: 'automobile/product/mtoc/',
        query: {
          limit: 50000,
          name: '',
        },
        editRow: null,
      },
      aenqsrceMeta: {
        URL: 'automobile/enquiries/sources/',
        query: {
          limit: 10,
          name: '',
        },
        editRow: null,
      },
      acolourMeta: {
        URL: 'automobile/product/colours/',
        query: {
          limit: 200,
          name: '',
        },
        editRow: null,
      },
      afinancesMeta: {
        URL: 'automobile/finances/',
        query: {
          limit: 1000,
          name: '',
        },
        editRow: null,
      },

      apurtypeMeta: {
        URL: 'automobile/purtypes/',
        query: {
          limit: 10,
          name: '',
        },
        editRow: null,
      },
      areasoneMeta: {
        URL: 'automobile/reasones/',
        query: {
          limit: 10,
          name: '',
        },
        editRow: null,
      },
      salesmanMeta: {
        URL: 'users/',
        query: {
          departmentId: 2,
          limit: 1000,
          status: true,
          companyId: user?.companyId,
          name: '',
        },
        editRow: null,
      },
      avehicleMeta: {
        URL: 'automobile/vehicles',
        query: {
          limit: 10,
          name: '',
        },
        editRow: null,
      },
      cityMeta: {
        URL: 'cities/',
        query: {
          limit: 10,
          name: '',
          countryCode: 'IN',
        },
        editRow: null,
      },
      zoneMeta: {
        URL: 'automobile/zones/',
        query: {
          limit: 200,
          name: '',
        },
        editRow: null,
      },
      occupationMeta: {
        URL: 'automobile/occupations/',
        query: {
          limit: 200,
          name: '',
        },
        editRow: null,
      },
      instituteAccountMeta: {
        URL: 'automobile/accounts/',
        query: {
          limit: 1000,
          name: '',
          ac_groupId: 60,
        },
        editRow: null,
      },
      exchangeAgentMeta: {
        URL: 'automobile/accounts/',
        query: {
          limit: 1000,
          name: '',
          ac_groupId: 59,
        },
        editRow: null,
      },
      refrencesMeta: {
        URL: 'automobile/refrences/',
        query: {
          limit: 200,
          name: '',
        },
        editRow: null,
      },
      schemeMeta: {
        URL: 'automobile/offers/',
        query: {
          limit: 200,
          name: '',
        },
        editRow: null,
      },
    }),
    [user?.companyId]
  );

  const resetCustomer = (row) => ({
    firstname: row?.firstname || '',
    lastname: row?.lastname || '',
    gender: row?.gender || null,
    dob: row?.dob || null,
    address1: row?.address1 || '',
    email: row?.email || '',
    pan_no: row?.pan_no || '',
    adno: row?.adno || '',
    mobile1: row?.mobile1 || '',
    mobile2: row?.mobile2 || '',
    adate: row?.adate || null,
    localityId: row?.localityId || null,
    locality: row?.locality || null,
    city: row?.city || {
      id: 58795,
      name: 'Surat',
      countryCode: 'IN',
      stateCode: 'GJ',
      latitude: 21.178,
      longitude: 72.8119,
      status: true,
      createdAt: '2023-11-10 13:12:28',
      updatedAt: '2023-11-10 13:12:28',
      state: {
        id: 1985,
        name: 'Gujarat',
        isoCode: 'GJ',
        countryCode: 'IN',
        latitude: 22.2587,
        longitude: 71.1924,
        status: 1,
        createdAt: '2023-11-10 13:12:27',
        updatedAt: '2023-11-10 13:12:27',
      },
      country: {
        id: 101,
        name: 'India',
        isoCode: 'IN',
        flag: '??',
        phonecode: '91',
        latitude: 20,
        longitude: 77,
        currency: 'INR',
        status: 1,
        createdAt: '2023-11-10 13:13:10',
        updatedAt: '2023-11-10 13:13:10',
      },
    },
    cityId: row?.cityId || 58795,
    state: row?.state || null,
    stateId: row?.stateId || null,
    pincode: row?.pincode || 0,
    occupationId: row?.occupationId || null,
    occupation: row?.occupation || null,
  });

  const values = useMemo(
    () => ({
      code: row?.accountId || null,
      createdAt: row?.createdAt || new Date(),
      updatedAt: row?.updatedAt || new Date(),
      aenquiryId: row?.aenquiryId || '',
      aenquiry: {
        createdAt: row?.aenquiry?.createdAt || new Date(),
      },
      customerId: row?.customerId || null,
      customer: resetCustomer(row?.customer),
      dlno: row?.dlno || null,
      gst_no: row?.gst_no || '',
      gstname: row?.gstname || '',
      tradename: row?.tradename || '',
      prinofbusiness: row?.prinofbusiness || '',
      gstflag: row?.gstflag || false,
      esflag: !!row?.amakerId,

      refrenceId: row?.refrenceId || null,
      refrence: row?.refrence || null,
      corefrence: row?.corefrence || '',
      expdelidate: row?.expdelidate || new Date(),
      institute: row?.institute || null,
      instituteId: row?.instituteId || null,
      dsaself: row?.dsaself || null,
      acolourId: row?.acolourId || null,
      acolour: row?.acolour || null,
      apurtypeId: row?.apurtypeId || null,
      rtoId: row?.rtoId || null,
      inouthouse: row?.inouthouse || null,

      challan: {
        challan_no: row?.challan?.identity || '',
        challandate: row?.challan?.challandate || null,
        chassisNo: row?.challan?.cha_details[0]?.stockdetail?.chassisNo || '',
        enginNo: row?.challan?.cha_details[0]?.stockdetail?.enginNo || '',
      },
      saleinvoice: {
        invoicedate: row?.saleinvoice?.dt || null,
        invoiceno: row?.saleinvoice?.v_no || '',
      },
      amtoc: row?.amtoc || null,
      loanamount: row?.loanamount || null,
      downpayment: row?.downpayment || null,
      disbursement: row?.disbursement || null,
      tenure: row?.tenure || null,
      ld: row?.ld || null,
      emiamount: row?.emiamount || null,
      hypo: row?.hypo || 2,
      remarks: row?.remarks || null,
      yofmfd: row?.yofmfd || null,
      kmsdriven: row?.kmsdriven || null,
      exchangeagent: row?.exchangeagent || null,
      exchangeId: row?.exchangeId || null,
      eamt: row?.eamt || null,
      eregino: row?.eregino || null,
      avariantName: row?.avariant || null,
      amodcategoryId: row?.amodcategoryId || null,
      amodelId: row?.amodelId || null,
      avariantId: row?.avariantId || null,
      amtocId: row?.amtocId || null,
      amakerId: row?.amakerId || null,
      salesmanId: row?.salesmanId || null,
      donebyId: row?.donebyId || null,
      afinanceId: row?.afinanceId || null,
      financebrancheId: row?.financebrancheId || null,

      exshowroom: row?.exshowroom || 0,
      ew: row?.ew || 0,
      rtotax: row?.rtotax || 0,
      coating: row?.coating || 0,
      insurance: row?.insurance || 0,
      other: row?.other || 0,
      smctax: row?.smctax || 0,
      access: row?.access || 0,
      hypothecation: row?.hypothecation || 0,
      discount: row?.discount || 0,
      total: row?.total || 0,
      images: row?.images || [],
      status: row?.status || false,
      branch: '',
      ...metalist,
    }),
    [metalist, row]
  );

  const methods = useForm({
    resolver: yupResolver(BookingSchema),
    values,
  });

  const {
    reset,
    control,
    trigger,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const onSubmit = handleSubmit(async (payload) => {
    payload = assignNullToEmptyOrUndefinedValues(payload);
    try {
      setLoading(true);
      let payload1 = Object.keys(payload).reduce((acc, key) => {
        if (!key.endsWith('Meta')) {
          acc[key] = payload[key];
        }
        return acc;
      }, {});

      let URL = 'automobile/bookings/';

      if (row?.id || allValues?.code) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        URL += row?.id ? row?.id : allValues?.code;
        const { success, show } = await update({ url: URL, payload: payload1 });
        if (success) {
          getAll();
          // onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        payload1 = { ...payload1, id: null };
        const { success, data, show } = await create({ url: URL, payload: payload1 });
        if (success) {
          getAll();
          // onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
          setAccountId(data?.accountId);
        }
      }

      reset();
      setLoading(false);
      printModel.onTrue();
    } catch (error) {
      setLoading(false);
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  const GetBookingId = useCallback(
    async (debouncedCode) => {
      try {
        if (debouncedCode > 0) {
          const URL = `automobile/bookings/${debouncedCode}`;
          const { data } = await fetcher(URL);
          if (data?.id) {
            reset({
              customer: resetCustomer(data?.customer),
              customerId: data?.customerId,
              aenquiryId: data?.aenquiryId,
              // code: data?.id,
              ...data,
              ...metalist,
            });
            setBookingStatus(true);
          } else {
            reset(values);
            enqueueSnackbar('Booking is not created!', {
              variant: 'info',
            });
          }
        } else {
          reset(values);
        }
      } catch (error) {
        console.error(error);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [metalist, reset, enqueueSnackbar, values]
  );

  const debouncedCode = useDebounce(allValues?.code, 500);

  useEffect(() => {
    if (debouncedCode) GetBookingId(debouncedCode);
  }, [GetBookingId, debouncedCode, row?.id]);

  const financierBranchMasterList = useCallback(async () => {
    try {
      let URL = `automobile/financebranches?afinanceId=${allValues?.afinanceId}&`;
      URL += `page=${1}&limit=${10000}&status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setFinancierBranchMaster(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, [allValues?.afinanceId]);

  useEffect(() => {
    if (allValues?.afinanceId) financierBranchMasterList();
  }, [allValues?.afinanceId, financierBranchMasterList]);

  useEffect(() => {
    if (allValues?.hypo === 2) {
      setValue('hypothecation', 0);
    } else if (allValues?.hypo === 1) {
      setValue('hypothecation', 700);
    }
  }, [allValues?.hypo, setValue]);

  const GetEnquiryById = useCallback(
    async (debouncedEnquiry) => {
      try {
        if (debouncedEnquiry > 0) {
          const URL = `automobile/enquiries/${debouncedEnquiry}`;
          const { data } = await fetcher(URL);
          if (data?.id && (data?.enqstatusId === 'E' || data?.enqstatusId === 'Q')) {
            reset({
              ...data,
              remarks: '',
              customer: resetCustomer(data?.customer),
              customerId: data?.customerId,
              aenquiryId: data?.id,
              eamt: null,
              inouthouse: data?.counter ? 1 : 2,
              afinanceId: data?.afinancesId,
              esflag: !!data?.exchange,
              aenquiry: {
                createdAt: data?.date,
              },
              hypo: !!data?.afinancesId,
              hypothecation: data?.afinancesId === null && 0,
              ...metalist,
            });
          } else if (data?.id && data?.enqstatusId === 'B') {
            reset(values);
            enqueueSnackbar('Enquiry is already booked!', {
              variant: 'info',
            });
          } else {
            reset(values);
            enqueueSnackbar('Enquiry is not created!', {
              variant: 'info',
            });
          }
        } else {
          reset(values);
        }
      } catch (error) {
        console.error(error);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [metalist, reset, enqueueSnackbar, values]
  );

  const debouncedEnquiry = useDebounce(allValues?.aenquiryId, 500);
  const debouncedProduct = useDebounce(allValues.productMeta?.query?.name, 500);
  const ProductListData = useCallback(async () => {
    try {
      let URL = 'automobile/products/';
      URL += `?page=${1}&limit=${100}&status=true&category=VEHICLE&name=${debouncedProduct}`;
      const { data } = await fetcher(URL);
      if (data) {
        setProductList(data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [debouncedProduct]);
  const RtoData = useCallback(async () => {
    try {
      let URL = 'automobile/rtos/';
      URL += `?page=${1}&limit=${1000}&status=true`;
      const { data } = await fetcher(URL);
      if (data && Array.isArray(data.rows)) {
        setRtoList(data.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  useEffect(() => {
    ProductListData();
  }, [ProductListData, debouncedProduct])
  // const productList = useGetCommon(debouncedProduct ? allValues.productMeta : allValues.productMeta);
  useEffect(() => {
    if (!row?.id && !bookingStatus && debouncedEnquiry && !allValues?.avariantId) {
      GetEnquiryById(debouncedEnquiry);
    }
  }, [GetEnquiryById, bookingStatus, row?.id, debouncedEnquiry, allValues?.avariantId]);
  useEffect(() => {
    if (allValues.amodcategoryId) {
      setValue('productMeta.query.amodcategoryId', allValues.amodcategoryId);
    }
  }, [allValues.amodcategoryId, setValue])


  useEffect(() => {
    if (Array.isArray(productList.rows) && productList.rows.length) {
      const findproduct = productList.rows.find(x => x.partno === debouncedProduct)
      // setValue("amodcategoryId", null)
      if (findproduct) {
        const { amodcategoryId,
          amodelId,
          avariantId } = findproduct;
        setValue("amodcategoryId", amodcategoryId)
        setValue("amodelId", amodelId)
        setValue("avariantId", avariantId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productList, setValue])

  const PricesMasterList = useCallback(async () => {
    try {
      let URL = `automobile/product/prices/byvariant/${allValues?.avariantId}`;
      URL += `?page=${1}&limit=${10000}&status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setPricesData(data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [allValues?.avariantId]);

  useEffect(() => {
    if (allValues?.avariantId && !bookingStatus) {
      PricesMasterList();
    } else {
      setPricesData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PricesMasterList, bookingStatus, allValues?.avariantId]);

  useEffect(() => {
    if (!row?.id && !bookingStatus) {
      setValue('exshowroom', pricesData?.exshowroomprice);
      setValue('ew', pricesData?.extwar);
      setValue('rtotax', pricesData?.rto);
      setValue('insurance', pricesData?.insurance);
      setValue('other', pricesData?.others);

      const smcTax = (pricesData?.regischarge ?? 0) + (pricesData?.roadtax ?? 0);
      setValue('smctax', smcTax);
      setValue('access', pricesData?.access);
      setValue('hypothecation', allValues?.afinanceId === null ? 0 : pricesData?.hpcharges);
    }
  }, [
    pricesData,
    pricesData?.exshowroomprice,
    row?.id,
    allValues?.afinanceId,
    bookingStatus,
    setValue,
  ]);

  useEffect(() => {
    const total =
      allValues.exshowroom +
      allValues.ew +
      allValues.rtotax +
      allValues.coating +
      allValues.insurance +
      allValues.other +
      allValues.smctax +
      allValues.access +
      allValues.hypothecation -
      allValues.discount;
    setValue('total', total);
  }, [
    allValues.access,
    allValues.coating,
    allValues.discount,
    allValues.ew,
    allValues.exshowroom,
    allValues.hypothecation,
    allValues.insurance,
    allValues.other,
    allValues.rtotax,
    allValues.smctax,
    setValue,
  ]);

  useEffect(() => {
    setValue('customer.state', allValues?.customer?.city?.state);
    setValue('customer.stateId', allValues?.customer?.city?.state?.id);
  }, [
    allValues?.customer,
    allValues?.customer?.city?.state,
    allValues?.customer?.city?.state?.id,
    setValue,
  ]);

  const debouncedCities = useDebounce(allValues.cityMeta?.query?.name, 500);
  const debouncedZones = useDebounce(allValues.zoneMeta?.query?.name, 500);
  const debouncedOccupations = useDebounce(allValues.occupationMeta?.name, 500);
  const debouncedInstituteAccount = useDebounce(allValues.instituteAccountMeta?.name, 500);
  const debouncedExchangeAgentAccount = useDebounce(allValues.exchangeAgentMeta?.name, 500);
  const debouncedRefrences = useDebounce(allValues.refrencesMeta?.name, 500);

  const debouncedMCategory = useDebounce(allValues.modelCategoryMeta?.name, 500);
  const debouncedProductModel = useDebounce(allValues.productModelMeta?.name, 500);
  const debouncedProductVariant = useDebounce(allValues.productVariantMeta?.name, 500);
  const debouncedProductMtoc = useDebounce(allValues.productMtocMeta?.name, 500);

  const debouncedSalesMan = useDebounce(allValues.salesmanMeta?.query?.name, 500);
  const debouncedScheme = useDebounce(allValues.schemeMeta?.name, 500);

  const debouncedPurtypes = useDebounce(allValues.apurtypeMeta?.name, 500);

  const debouncedFinances = useDebounce(allValues.afinancesMeta?.name, 500);

  const debouncedMMakers = useDebounce(allValues.modelMakersMeta?.name, 500);

  const zones = useGetZones(debouncedZones ? allValues.zoneMeta : allValues.zoneMeta);
  const cities = useGetCommon(debouncedCities ? allValues.cityMeta : allValues.cityMeta);
  const occupation = useGetCommon(
    debouncedOccupations ? allValues.occupationMeta : allValues.occupationMeta
  );
  const instituteAccountData = useGetCommon(
    debouncedInstituteAccount ? allValues.instituteAccountMeta : allValues.instituteAccountMeta
  );
  const exchangeAgentData = useGetCommon(
    debouncedExchangeAgentAccount ? allValues.exchangeAgentMeta : allValues.exchangeAgentMeta
  );
  const refrence = useGetCommon(
    debouncedRefrences ? allValues.refrencesMeta : allValues.refrencesMeta
  );

  const modelCategory = useGetCommon(
    debouncedMCategory ? allValues.modelCategoryMeta : allValues.modelCategoryMeta
  );
  const productModel = useGetCommon(
    debouncedProductModel ? allValues.productModelMeta : allValues.productModelMeta
  );
  const productVariant = useGetCommon(
    debouncedProductVariant ? allValues.productVariantMeta : allValues.productVariantMeta
  );
  const productMtoc = useGetCommon(
    debouncedProductMtoc ? allValues.productMtocMeta : allValues.productMtocMeta
  );
  const salesman = useGetCommon(
    debouncedSalesMan ? allValues.salesmanMeta : allValues.salesmanMeta
  );
  const offer = useGetCommon(debouncedScheme ? allValues.schemeMeta : allValues.schemeMeta);

  const purtypes = useGetCommon(
    debouncedPurtypes ? allValues.apurtypeMeta : allValues.apurtypeMeta
  );

  const finances = useGetFinances(
    debouncedFinances ? allValues.afinancesMeta : allValues.afinancesMeta
  );

  const modelMakers = useGetCommon(
    debouncedMMakers ? allValues.modelMakersMeta : allValues.modelMakersMeta
  );

  useEffect(() => {
    reset(values);
    RtoData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, reset]);

  const images = useFieldArray({
    control,
    name: 'images',
  });

  const handleDrop = useCallback(
    async (acceptedFiles) => {
      const URL = `${HOST_API}upload`;

      try {
        await Promise.all(
          acceptedFiles.map(async (file) => {
            const formData = new FormData();
            formData.append('file', file);

            try {
              const response = await axios.post(URL, formData, {
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  // setUploadProgress(percentCompleted); // Uncomment this to update progress if needed
                },
              });

              if (response?.data?.filename) {
                images.append(`${HOST_API}uploads/medium/${response.data.filename}`);
              }
            } catch (error) {
              enqueueSnackbar(`Upload failed for ${file.name}`, {
                variant: 'info',
              });
            }
          })
        );
      } catch (error) {
        enqueueSnackbar('Upload failed', {
          variant: 'error',
        });
      }
    },
    [images, enqueueSnackbar] // Add HOST_API here if it's not a constant
  );

  useEffect(() => {
    setValue('dsaself', allValues.instituteId ? 1 : 2);
  }, [allValues.instituteId, setValue]);

  const handleRemoveFile = useCallback(
    (inputFile) => {
      const filtered = allValues.images && allValues.images?.filter((file) => file !== inputFile);
      setValue('images', filtered);
    },
    [setValue, allValues.images]
  );

  const handleRemoveAllFiles = useCallback(() => {
    setValue('images', []);
  }, [setValue]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.YES && printModel.value) {
      onClose();
      setPDFViwer(allValues?.code || accountId);
    }
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <>
      <Dialog fullScreen open={open} onKeyDown={handleKeyDown}>
        <FormProvider methods={methods}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                top: 0,
                zIndex: 9,
                position: 'sticky',
                bgcolor: (theme) => alpha(theme.palette.background.default, 1),
              }}
            >
              <Toolbar>
                <Typography variant="h5" sx={{ flex: 1 }}>
                  {(row || bookingStatus || allValues?.aenquiryId) && allValues?.code
                    ? 'Update Booking '
                    : 'Add Booking'}
                </Typography>
                <IconButton
                  color="inherit"
                  edge="start"
                  onClick={() => {
                    onClose();
                  }}
                  tabIndex={-1}
                >
                  <Iconify icon="mingcute:close-line" />
                </IconButton>
              </Toolbar>

              <Divider sx={{ borderStyle: 'dashed' }} />
            </Grid>

            {/*  customer and vehicle section */}
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              sx={{ m: 3 }}
              spacing={5}
            >
              <Grid item container xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Customer Details
                  </Typography>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                </Grid>

                <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={5}>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    alignContent="start"
                    spacing={2}
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField
                        name="aenquiryId"
                        label={
                          <span>
                            Enquiry Number <span style={{ color: 'red' }}>*</span>
                          </span>
                        }
                        size="small"
                        error={errors?.aenquiryId && !allValues?.aenquiryId}
                        disabled={row?.id || allValues?.code}
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="code" label="Code" size="small" disabled={row?.id} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Controller
                        name="createdAt"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <DateField
                            value={field.value ? new Date(field.value) : null}
                            timezone="Asia/Kolkata"
                            onChange={(newValue) => {
                              field.onChange(newValue);
                            }}
                            format="dd/MM/yyyy"
                            label="Booking Date"
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                error: !!error,
                                helperText: error?.message,
                                size: 'small',
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Controller
                        name="updatedAt"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <DateField
                            value={field.value ? new Date(field.value) : null}
                            timezone="Asia/Kolkata"
                            onChange={(newValue) => {
                              field.onChange(newValue);
                            }}
                            format="dd/MM/yyyy"
                            label="Update Date"
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                error: !!error,
                                helperText: error?.message,
                                size: 'small',
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Controller
                        name="aenquiry.createdAt"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <DateField
                            value={field.value ? new Date(field.value) : null}
                            timezone="Asia/Kolkata"
                            onChange={(newValue) => {
                              field.onChange(newValue);
                            }}
                            format="dd/MM/yyyy"
                            label="Enquiry Date"
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                error: !!error,
                                helperText: error?.message,
                                size: 'small',
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField
                        name="customer.firstname"
                        label={
                          <span>
                            First Name <span style={{ color: 'red' }}>*</span>
                          </span>
                        }
                        size="small"
                        error={errors?.customer?.firstname && !allValues?.customer?.firstname}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField
                        name="customer.lastname"
                        label={
                          <span>
                            Last Name <span style={{ color: 'red' }}>*</span>
                          </span>
                        }
                        error={errors?.customer?.lastname && !allValues?.customer?.lastname}
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={GENDER.length ? GENDER : [{ name: 'No Options', value: null }]}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('genderMeta.editRow', newValue);
                          setValue('customer.gender', newValue?.value);
                        }}
                        value={(() => {
                          const addRecord = GENDER?.find(
                            (data) => data.value === allValues?.customer?.gender
                          );
                          const editRecord = row?.ac_group;

                          if (addRecord) return addRecord;
                          if (editRecord) return editRecord;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span>
                                Gender <span style={{ color: 'red' }}>*</span>
                              </span>
                            }
                            name="customer.gender"
                            error={errors?.customer?.gender && !allValues?.customer?.gender}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                            <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                              {option?.name}
                            </ListItem>
                          </Box>
                        )}
                        ListboxProps={{
                          ...bgColorAutocomplete,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                      <RHFTextField
                        name="customer.pan_no"
                        label="Pan No."
                        size="small"
                        error={errors?.customer?.pan_no && !allValues?.customer?.pan_no}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                      <RHFTextField
                        name="customer.adno"
                        label="Aadhar No."
                        size="small"
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField
                        name="customer.mobile1"
                        type="number"
                        label={
                          <span>
                            Mobile 1 <span style={{ color: 'red' }}>*</span>
                          </span>
                        }
                        size="small"
                        value={allValues?.customer?.mobile1 || ''}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.length <= 10) setValue('customer.mobile1', e.target.value);
                        }}
                        inputProps={{
                          pattern: '\\d*',
                        }}
                        error={
                          errors?.customer?.mobile1 && allValues?.customer?.mobile1?.length !== 10
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField
                        name="customer.mobile2"
                        type="number"
                        label="Mobile 2"
                        size="small"
                        value={allValues?.customer?.mobile2 || ''}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.length <= 10) setValue('customer.mobile2', e.target.value);
                        }}
                        inputProps={{
                          pattern: '\\d*',
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    alignContent="start"
                    spacing={2}
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="customer.email" label="Email" size="small" />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="dlno" label="Licence No" size="small" />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFTextField
                        name="customer.address1"
                        label="Address"
                        size="small"
                        multiline
                        rows={3.3}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight={allValues.cityMeta?.query?.name?.length > 0}
                        fullWidth
                        size="small"
                        options={cities.rows}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        filterOptions={(options, state) =>
                          cities.rows.length ? filterCities(cities.rows, allValues.cityMeta.query.name) : [{ name: 'No Options', value: null }]
                        }
                        inputValue={allValues.cityMeta?.query?.name}
                        onInputChange={(event, newInputValue) => {
                          setValue('cityMeta.query.name', newInputValue);
                        }}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('cityMeta.editRow', newValue);
                          setValue('customer.cityId', newValue?.id);
                          setValue('customer.city', newValue);
                        }}
                        value={allValues?.customer?.city}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select City"
                            name="customer.cityId"
                            error={errors?.customer?.cityId && !allValues?.customer?.cityId}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            key={option.id}
                            sx={{ minHeight: 'auto !important' }}
                          >
                            <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                          </Box>
                        )}
                        ListboxProps={{
                          ...bgColorAutocomplete,
                          onScroll: (event) =>
                            handleScroll({
                              event,
                              allValues,
                              setValue,
                              fieldName: 'cityMeta',
                              meta: allValues.cityMeta,
                              swr: cities,
                            }),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                      <RHFTextField
                        name="customer.city.state.name"
                        label="State"
                        size="small"
                        disabled="true"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight={allValues?.zoneMeta?.query?.name?.length > 0}
                        fullWidth
                        size="small"
                        options={zones.rows}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        filterOptions={(options, state) => {
                          if (zones.rows.length) {
                            const filteredZones = zones.rows.filter((zone) =>
                              zone.name.toLowerCase().includes(state.inputValue.toLowerCase())
                            );
                            return filteredZones.length
                              ? filteredZones
                              : [{ name: 'No matching options', value: null }];
                          }
                          return [{ name: 'No Options', value: null }];
                        }}
                        inputValue={allValues?.zoneMeta?.query?.name}
                        onInputChange={(event, newInputValue) => {
                          setValue('zoneMeta.query.name', newInputValue);
                        }}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('zoneMeta.editRow', newValue);
                          setValue('customer.localityId', newValue?.id || null);
                          setValue('customer.locality', newValue);
                        }}
                        value={allValues?.customer?.locality}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span>
                                Select Zone{' '}
                                {allValues?.customer?.address1?.length > 0 && <span>*</span>}
                              </span>
                            }
                            name="customer.localityId"
                            error={errors?.customer?.localityId && !allValues?.customer?.localityId}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.id}>
                            <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                          </Box>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                      <RHFTextField
                        name="customer.pincode"
                        type="number"
                        label="Pin Code"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                      <RHFCheckbox name="gstflag" label="G.S.T." disabled={row?.id} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                alignContent="start"
                spacing={2}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Vehicle Information
                  </Typography>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={6} xl={4} xxl={4}>
                  <Controller
                    name="challan.challandate"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <DateField
                        value={field.value ? new Date(field.value) : null}
                        timezone="Asia/Kolkata"
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        format="dd/MM/yyyy"
                        label="Challan Date"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                            size: 'small',
                          },
                        }}
                        disabled
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={6} xl={4} xxl={4}>
                  <RHFTextField
                    name="challan.challan_no"
                    label="Challan No."
                    size="small"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={6} xl={4} xxl={4}>
                  <Controller
                    name="saleinvoice.invoicedate"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <DateField
                        value={field.value ? new Date(field.value) : null}
                        timezone="Asia/Kolkata"
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        format="dd/MM/yyyy"
                        label="Invoice Date"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                            size: 'small',
                          },
                        }}
                        disabled
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={6} xl={6} xxl={6}>
                  <RHFTextField
                    name="saleinvoice.invoiceno"
                    label="Invoice No."
                    size="small"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField
                    name="registration_no"
                    label="Registration No."
                    size="small"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                  <RHFTextField name="challan.chassisNo" label="Chs. No." size="small" disabled />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="challan.enginNo" label="Eng. No." size="small" disabled />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="insurance_no" label="Insurance No." size="small" disabled />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    size="small"
                    options={rtoList}
                    groupBy={(option) => option?.a}
                    getOptionLabel={(option) => option.name}
                    getOptionDisabled={(option) => option?.value === null}
                    openOnFocus
                    onChange={(event, newValue) => {
                      setValue('rtoId', newValue?.id);
                    }}
                    value={(() => {
                      const addRecord = rtoList?.find(
                        (data) => data.id === allValues.rtoId
                      );
                      const editRecord = row?.rto;

                      if (addRecord) return addRecord;
                      if (editRecord) return editRecord;
                      return null;
                    })()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <span>
                            R.T.O
                          </span>
                        }
                        name="rtoId"
                        error={errors?.rtoId && !allValues?.rtoId}

                      />
                    )}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        {...props}
                        key={option.id}
                        sx={{ minHeight: 'auto !important' }}
                      >
                        <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                      </Box>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
              </Grid>

              {/* GST */}
              {allValues?.gstflag && (
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  spacing={2}
                  alignContent="start"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      G.S.T.
                    </Typography>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                  </Grid>
                  <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                      <RHFTextField
                        name="gst_no"
                        label="GST No."
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  window.open(
                                    'https://services.gst.gov.in/services/searchtp',
                                    '_blank'
                                  );
                                }}
                              >
                                <Icon icon="ion:search" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                      <RHFTextField name="gstname" label="Legal Name of Business" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                      <RHFTextField name="tradename" label="Trade Name" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField
                        name="prinofbusiness"
                        label="Principal Place of Business"
                        size="small"
                        multiline
                        rows={1}
                        inputRef={principalRef}
                        onBlur={() => {
                          // Ensure the element is not null
                          if (principalRef.current) {
                            principalRef.current.scrollIntoView({
                              behavior: 'smooth',
                              block: 'start',
                            });
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {/* booking details and product details */}
              <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Booking Details
                  </Typography>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                </Grid>

                <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={6}>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    xxl={6}
                    spacing={2}
                    alignContent="start"
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={occupation.rows}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        filterOptions={(options, state) => {
                          if (occupation.rows.length) {
                            const filteredData = occupation.rows.filter((x) =>
                              x.name?.toLowerCase().includes(state.inputValue?.toLowerCase())
                            );
                            return filteredData.length
                              ? filteredData
                              : [{ name: 'No matching options', value: null }];
                          }
                          return [{ name: 'No Options', value: null }];
                        }}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('customer.occupationId', newValue?.id);
                          setValue('customer.occupation', newValue);
                        }}
                        value={allValues?.customer?.occupation}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Occupation"
                            name="customer.occupationId"
                            error={
                              errors?.customer?.occupationId && !allValues?.customer?.occupationId
                            }
                            inputRef={occuRef}
                            onFocus={() => {
                              if (occuRef.current) {
                                occuRef.current.scrollIntoView({
                                  behavior: 'smooth',
                                  block: 'start',
                                });
                              }
                            }}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            key={option.id}
                            sx={{ minHeight: 'auto !important' }}
                          >
                            <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                          </Box>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                      <Controller
                        name="customer.dob"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <DateField
                            value={field.value ? new Date(field.value) : null}
                            timezone="Asia/Kolkata"
                            onChange={(newValue) => {
                              field.onChange(newValue);
                            }}
                            format="dd/MM/yyyy"
                            label="Birth Date"
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                error: !!error,
                                helperText: error?.message,
                                size: 'small',
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                      <Controller
                        name="customer.adate"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <DateField
                            value={field.value ? new Date(field.value) : null}
                            timezone="Asia/Kolkata"
                            onChange={(newValue) => {
                              field.onChange(newValue);
                            }}
                            format="dd/MM/yyyy"
                            label="Anniversary Date"
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                error: !!error,
                                helperText: error?.message,
                                size: 'small',
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={refrence.rows}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        filterOptions={(options, state) => {
                          if (refrence.rows.length) {
                            const filteredData = refrence.rows.filter((x) =>
                              x.name?.toLowerCase().includes(state.inputValue?.toLowerCase())
                            );
                            return filteredData.length
                              ? filteredData
                              : [{ name: 'No matching options', value: null }];
                          }
                          return [{ name: 'No Options', value: null }];
                        }}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('refrenceId', newValue?.id);
                          setValue('refrence', newValue);
                        }}
                        value={allValues.refrence}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Refrence Name"
                            name="refrenceId"
                            error={errors?.refrenceId && !allValues?.refrenceId}
                            inputRef={refrenceRef}
                            onFocus={() => {
                              if (refrenceRef.current) {
                                refrenceRef.current.scrollIntoView({
                                  behavior: 'smooth',
                                  block: 'start',
                                });
                              }
                            }}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            key={option.id}
                            sx={{ minHeight: 'auto !important' }}
                          >
                            <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                          </Box>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <RHFTextField name="corefrence" label="Co. Reference Name" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Controller
                        name="expdelidate"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <DatePicker
                            value={field.value ? new Date(field.value) : null}
                            timezone="Asia/Kolkata"
                            onChange={(newValue) => {
                              field.onChange(newValue);
                            }}
                            format="dd/MM/yyyy"
                            label="Expected Delivery Date"
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                error: !!error,
                                size: 'small',
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={[{ name: 'No Scheme', value: 'null' }, ...instituteAccountData.rows]}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        filterOptions={(options, state) => {
                          if (instituteAccountData.rows.length) {
                            const filteredData = instituteAccountData.rows.filter((x) =>
                              x.name?.toLowerCase().includes(state.inputValue?.toLowerCase())
                            );
                            return filteredData.length
                              ? filteredData
                              : [{ name: 'No matching options', value: null }];
                          }
                          return [{ name: 'No Options', value: null }];
                        }}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('instituteId', newValue?.id);
                          setValue('institute', newValue);
                        }}
                        value={allValues?.institute}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Institute"
                            name="instituteId"
                            error={errors?.instituteId && !allValues?.instituteId}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            key={option.id}
                            sx={{ minHeight: 'auto !important' }}
                          >
                            <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                          </Box>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="damount" label="Deposit Amount" size="small" disabled />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                      <RHFTextField
                        name="outamount"
                        label="Outstanding Amount"
                        size="small"
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    xxl={6}
                    alignContent="start"
                    spacing={2}
                  >
                    <Grid xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={modelCategory.rows}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        getOptionDisabled={(option) => option?.value === null}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('modelCategoryMeta.editRow', newValue);
                          setValue('amodcategoryId', newValue?.id);
                          setValue('amodelId', null);
                          setValue('avariantId', null);
                          setValue('amtocId', null);
                        }}
                        value={(() => {
                          const addRecord = modelCategory.rows?.find(
                            (data) => data.id === allValues?.amodcategoryId
                          );
                          const editRecord = row?.amodcategory;

                          if (addRecord) return addRecord;
                          if (editRecord) return editRecord;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span>
                                Model Category <span style={{ color: 'red' }}>*</span>
                              </span>
                            }
                            name="amodcategoryId"
                            error={errors?.amodcategoryId && !allValues?.amodcategoryId}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            key={option.id}
                            sx={{ minHeight: 'auto !important' }}
                          >
                            <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                          </Box>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={productModel.rows.filter(
                          (data) => data.amodcategoryId === allValues.amodcategoryId
                        )}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        getOptionDisabled={(option) => option?.value === null}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('productModelMeta.editRow', newValue);
                          setValue('amodelId', newValue?.id);
                          setValue('avariantId', null);
                          setValue('amtocId', null);
                        }}
                        onInputChange={(event, newInputValue) => {
                          setValue('productMeta', {
                            ...allValues.productMeta,
                            query: {
                              ...allValues.productMeta.query,
                              name: newInputValue
                            }
                          });
                        }}
                        value={(() => {
                          const addRecord = productModel.rows?.find(
                            (data) => data.id === allValues.amodelId
                          );
                          const editRecord = row?.amodel;

                          if (addRecord) return addRecord;
                          if (editRecord) return editRecord;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span>
                                Product Model <span style={{ color: 'red' }}>*</span>
                              </span>
                            }
                            name="amodelId"
                            error={errors?.amodelId && !allValues?.amodelId}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            key={option.id}
                            sx={{ minHeight: 'auto !important' }}
                          >
                            <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                          </Box>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>

                    <Grid xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={productVariant.rows.filter(
                          (data) => data.amodelId === allValues.amodelId
                        )}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        getOptionDisabled={(option) => option?.value === null}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('productVariantMeta.editRow', newValue);
                          setValue('avariantId', newValue?.id);
                          setValue('avariantName', newValue);
                          setValue('amtocId', null);
                        }}
                        value={(() => {
                          const addRecord = productVariant.rows?.find(
                            (data) => data.id === allValues.avariantId
                          );
                          const editRecord = row?.avariant;

                          if (addRecord) return addRecord;
                          if (editRecord) return editRecord;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span>
                                Product Variant <span style={{ color: 'red' }}>*</span>
                              </span>
                            }
                            name="avariantId"
                            error={errors?.avariantId && !allValues?.avariantId}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            key={option.id}
                            sx={{ minHeight: 'auto !important' }}
                          >
                            <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                          </Box>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={productMtoc.rows.filter(
                          (data) => data.avariantId === allValues.avariantId
                        )}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.acolour.name}
                        getOptionDisabled={(option) => option?.value === null}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('productMtocMeta.editRow', newValue);
                          setValue('amtocId', newValue?.id);
                          setValue('amtoc', newValue);
                          setValue('acolourId', newValue?.acolour?.id);
                          setValue('acolour', newValue?.acolour);
                        }}
                        value={(() => {
                          const addRecord = productMtoc.rows?.find(
                            (data) => data.id === allValues.amtocId
                          );
                          const editRecord = row?.amtoc.acolour;

                          if (addRecord) return addRecord;
                          if (editRecord) return editRecord;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span>
                                Color (Product) <span style={{ color: 'red' }}>*</span>
                              </span>
                            }
                            name="amtocId"
                            error={errors?.amtocId && !allValues?.amtocId}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            key={option.id}
                            sx={{ minHeight: 'auto !important' }}
                          >
                            <ListItem sx={{ padding: 0, margin: 0 }}>{option?.acolour?.name}</ListItem>
                          </Box>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      {/* <RHFTextField
                        name="acolourId"
                        label="Model Color"
                        size="small"
                        value={allValues.acolour?.name || ''}
                        error={errors?.acolourId && !allValues?.acolourId}
                      /> */}
                      <RHFTextField
                        name="amtocId"
                        label="Model Mtoc"
                        size="small"
                        value={allValues?.amtoc?.name || ''}
                        error={errors?.amtocId && !allValues?.amtocId}
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={
                          DSA_SELF?.length ? DSA_SELF : [{ name: 'No Options', value: null }]
                        }
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('dsaself', newValue?.value);
                        }}
                        value={(() => {
                          const addRecord = DSA_SELF?.find(
                            (data) => data.value === allValues?.dsaself
                          );
                          const editRecord = row?.ac_group;

                          if (addRecord) return addRecord;
                          if (editRecord) return editRecord;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="DSA/Self"
                            name="dsaself"
                            error={errors?.dsaself && !allValues?.dsaself}
                            disabled
                          />
                        )}
                        disabled
                        renderOption={(props, option) => (
                          <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                            <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                              {option?.name}
                            </ListItem>
                          </Box>
                        )}
                        ListboxProps={{
                          ...bgColorAutocomplete,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={salesman.rows}
                        groupBy={(option) => option?.dpartmentId}
                        getOptionLabel={(option) => option.name}
                        getOptionDisabled={(option) => option?.value === null}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('salesmanMeta.editRow', newValue);
                          setValue('salesmanId', newValue?.id);
                        }}
                        value={(() => {
                          const addRecord = salesman.rows?.find(
                            (data) => data.id === allValues.salesmanId
                          );
                          const editRecord = row?.salesman;
                          if (addRecord) return addRecord;
                          if (editRecord) return editRecord;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span>
                                Assigned To <span style={{ color: 'red' }}>*</span>
                              </span>
                            }
                            name="salesmanId"
                            error={errors?.salesmanId && !allValues?.salesmanId}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            key={option.id}
                            sx={{ minHeight: 'auto !important' }}
                          >
                            <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                          </Box>
                        )}
                        ListboxProps={{
                          ...bgColorAutocomplete,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={salesman.rows}
                        groupBy={(option) => option?.dpartmentId}
                        getOptionLabel={(option) => option.name}
                        getOptionDisabled={(option) => option?.value === null}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('salesmanMeta.editRow', newValue);
                          setValue('donebyId', newValue?.id);
                        }}
                        value={(() => {
                          const addRecord = salesman.rows?.find(
                            (data) => data.id === allValues.donebyId
                          );
                          const editRecord = row?.salesman;
                          if (addRecord) return addRecord;
                          if (editRecord) return editRecord;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span>
                                Done By <span style={{ color: 'red' }}>*</span>
                              </span>
                            }
                            name="donebyId"
                            error={errors?.donebyId && !allValues?.donebyId}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            key={option.id}
                            sx={{ minHeight: 'auto !important' }}
                          >
                            <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                          </Box>
                        )}
                        ListboxProps={{
                          ...bgColorAutocomplete,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={[{ name: 'No Scheme', value: 'null' }, ...offer.rows]}
                        getOptionLabel={(option) => option.name}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('offer.editRow', newValue);
                          setValue('offerId', newValue?.id);
                        }}
                        value={(() => {
                          const addRecord = offer.rows?.find(
                            (data) => data.id === allValues.offerId
                          );
                          const editRecord = row?.offer;
                          if (addRecord) return addRecord;
                          if (editRecord) return editRecord;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Scheme"
                            name="offerId"
                            error={errors?.offerId && !allValues?.offerId}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            key={option.id}
                            sx={{ minHeight: 'auto !important' }}
                          >
                            <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                          </Box>
                        )}
                        ListboxProps={{
                          ...bgColorAutocomplete,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={6}>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={4}
                  spacing={2}
                  alignContent="start"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Payments
                    </Typography>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      size="small"
                      options={purtypes.rows}
                      groupBy={(option) => option?.a}
                      getOptionLabel={(option) => option.name}
                      getOptionDisabled={(option) => option?.value === null}
                      openOnFocus
                      onChange={(event, newValue) => {
                        setValue('apurtypeMeta.editRow', newValue);
                        setValue('apurtypeId', newValue?.id);
                      }}
                      value={(() => {
                        const addRecord = purtypes.rows?.find(
                          (data) => data.id === allValues.apurtypeId
                        );
                        const editRecord = row?.apurtype;

                        if (addRecord) return addRecord;
                        if (editRecord) return editRecord;
                        return null;
                      })()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              Purchase Type <span style={{ color: 'red' }}>*</span>
                            </span>
                          }
                          name="apurtypeId"
                          error={errors?.apurtypeId && !allValues?.apurtypeId}
                          inputRef={schemeRef}
                          onFocus={() => {
                            // Ensure the element is not null
                            if (schemeRef.current) {
                              schemeRef.current.scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                              });
                            }
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          {...props}
                          key={option.id}
                          sx={{ minHeight: 'auto !important' }}
                        >
                          <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                        </Box>
                      )}
                      ListboxProps={{ ...bgColorAutocomplete }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      size="small"
                      options={
                        INOUTHOUSE?.length ? INOUTHOUSE : [{ name: 'No Options', value: null }]
                      }
                      groupBy={(option) => option?.a}
                      getOptionLabel={(option) => option.name}
                      openOnFocus
                      onChange={(event, newValue) => {
                        setValue('inouthouse', newValue?.value);
                      }}
                      value={(() => {
                        const addRecord = INOUTHOUSE?.find(
                          (data) => data.value === allValues?.inouthouse
                        );
                        const editRecord = row?.ac_group;

                        if (addRecord) return addRecord;
                        if (editRecord) return editRecord;
                        return null;
                      })()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              In/Out House <span style={{ color: 'red' }}>*</span>
                            </span>
                          }
                          name="inouthouse"
                          error={errors?.inouthouse && !allValues?.inouthouse}
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                          <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                            {option?.name}
                          </ListItem>
                        </Box>
                      )}
                      ListboxProps={{
                        ...bgColorAutocomplete,
                      }}
                    />
                  </Grid>
                  {allValues.apurtypeId === 2 && (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                      <RHFTextField
                        name="loanamount"
                        type="number"
                        label="Loan Amt."
                        size="small"
                      />
                    </Grid>
                  )}

                  {allValues.apurtypeId === 2 && (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                      <RHFTextField
                        name="downpayment"
                        type="number"
                        label="Down Pay."
                        size="small"
                      />
                    </Grid>
                  )}
                  {allValues.apurtypeId === 2 && (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                      <RHFTextField
                        name="disbursement"
                        type="number"
                        label="Disbursement"
                        size="small"
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                    <RHFTextField name="ld" type="number" label="Speed" size="small" />
                  </Grid>

                  {allValues.apurtypeId === 2 && (
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <RHFTextField name="tenure" type="number" label="Tenure" size="small" />
                    </Grid>
                  )}
                  {allValues.apurtypeId === 2 && (
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <RHFTextField name="emiamount" type="number" label="EMI Amt." size="small" />
                    </Grid>
                  )}
                  {allValues.apurtypeId === 2 && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={finances.rows}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('afinancesMeta.editRow', newValue);
                          setValue('afinanceId', newValue?.id);
                        }}
                        value={(() => {
                          const addRecord = finances.rows?.find(
                            (data) => data.id === allValues.afinanceId
                          );
                          const editRecord = row?.afinance;

                          if (addRecord) return addRecord;
                          if (editRecord) return editRecord;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span>
                                Select Financier <span style={{ color: 'red' }}>*</span>
                              </span>
                            }
                            name="afinanceId"
                            error={errors?.afinanceId && !allValues?.afinanceId}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                            <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                              {option?.name}
                            </ListItem>
                          </Box>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                  )}

                  {allValues.apurtypeId === 2 && (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={financierBranchMaster}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        getOptionDisabled={(option) => option?.value === null}
                        openOnFocus={!row?.id}
                        onChange={(event, newValue) => {
                          setValue('financebrancheId', newValue?.id);
                        }}
                        value={(() => {
                          const addRecord = financierBranchMaster?.find(
                            (data) => data.id === allValues.financebrancheId
                          );
                          const editRecord = row?.ac_group;

                          if (addRecord) return addRecord;
                          if (editRecord) return editRecord;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span>
                                Select Branch <span style={{ color: 'red' }}>*</span>
                              </span>
                            }
                            name="financebrancheId"
                            error={errors?.financebrancheId && !allValues?.financebrancheId}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            key={option.id}
                            sx={{ minHeight: 'auto !important' }}
                          >
                            <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                          </Box>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                  )}

                  {allValues.apurtypeId === 2 && (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={HPATYPE?.length ? HPATYPE : [{ name: 'No Options', value: null }]}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('hypo', newValue?.value);
                        }}
                        value={(() => {
                          const addRecord = HPATYPE?.find((data) => data.value === allValues?.hypo);
                          const editRecord = row?.ac_group;

                          if (addRecord) return addRecord;
                          if (editRecord) return editRecord;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="H.P.A. Type"
                            name="hypo"
                            error={errors?.hypo && !allValues?.hypo}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                            <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                              {option?.name}
                            </ListItem>
                          </Box>
                        )}
                        ListboxProps={{
                          ...bgColorAutocomplete,
                        }}
                      />
                    </Grid>
                  )}
                  {/* {allValues.apurtypeId === 2 && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Typography variant="subtitle2">Print Hypo</Typography>

                      <RHFCheckbox name="hypo" label="YES" disabled={row?.id} />
                    </Grid>
                  )} */}

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <RHFTextField name="remarks" label="Remarks" size="small" multiline rows={2} />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={4}
                  spacing={2}
                  alignContent="start"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <RHFCheckbox name="esflag" label="Exchange Section" disabled={row?.id} />
                  </Grid>

                  {allValues.esflag && (
                    <Grid item container xs={12} sm={12} spacing={2} alignContent="start">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Typography variant="subtitle1" gutterBottom>
                          Exchange Vehicle Details
                        </Typography>
                        <Divider sx={{ borderStyle: 'dashed' }} />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={12} xl={6} xxl={6}>
                        <Autocomplete
                          autoHighlight
                          fullWidth
                          size="small"
                          options={modelMakers.rows}
                          groupBy={(option) => option?.a}
                          getOptionLabel={(option) => option.name}
                          getOptionDisabled={(option) => option?.value === null}
                          openOnFocus
                          onChange={(event, newValue) => {
                            setValue('modelMakersMeta.editRow', newValue);
                            setValue('amakerId', newValue?.id);
                          }}
                          value={(() => {
                            const addRecord = modelMakers.rows?.find(
                              (data) => data.id === allValues.amakerId
                            );
                            const editRecord = row?.amaker;

                            if (addRecord) return addRecord;
                            if (editRecord) return editRecord;
                            return null;
                          })()}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <span>
                                  Vehicle Make <span style={{ color: 'red' }}>*</span>
                                </span>
                              }
                              name="amakerId"
                              error={errors?.amakerId && !allValues?.amakerId}
                            />
                          )}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              {...props}
                              key={option.id}
                              sx={{ minHeight: 'auto !important' }}
                            >
                              <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                            </Box>
                          )}
                          ListboxProps={{ ...bgColorAutocomplete }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={12} xl={6} xxl={6}>
                        <Autocomplete
                          autoHighlight
                          fullWidth
                          size="small"
                          options={productModel.rows.filter(
                            (data) => allValues.amakerId === 4 ? [4, null].includes(data.amakerId) : allValues.amakerId === data.amakerId
                          )}
                          groupBy={(option) => option?.a}
                          getOptionLabel={(option) => option.name}
                          getOptionDisabled={(option) => option?.value === null}
                          openOnFocus
                          onChange={(event, newValue) => {
                            setValue('productModelMeta.editRow', newValue);
                            setValue('emodelId', newValue?.id);
                          }}
                          value={(() => {
                            const addRecord = productModel.rows?.find(
                              (data) => data.id === allValues.emodelId
                            );
                            const editRecord = row?.emodel;

                            if (addRecord) return addRecord;
                            if (editRecord) return editRecord;
                            return null;
                          })()}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <span>
                                  Vehicle Model <span style={{ color: 'red' }}>*</span>
                                </span>
                              }
                              name="emodelId"
                              error={errors?.emodelId && !allValues?.emodelId}
                            />
                          )}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              {...props}
                              key={option.id}
                              sx={{ minHeight: 'auto !important' }}
                            >
                              <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                            </Box>
                          )}
                          ListboxProps={{ ...bgColorAutocomplete }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3.1} lg={3.1} xl={3.1} xxl={3.1}>
                        <RHFTextField
                          name="yofmfd"
                          label={
                            <span>
                              Model Year <span style={{ color: 'red' }}>*</span>
                            </span>
                          }
                          size="small"
                          type="number"
                          value={allValues?.yofmfd || ''}
                          error={errors?.yofmfd && !allValues?.yofmfd}
                          onChange={(e) => {
                            const { value } = e.target;
                            if (value.length <= 4) {
                              setValue('yofmfd', value);
                            }
                          }}
                          inputProps={{
                            pattern: '\\d*',
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={5.9} lg={5.9} xl={5.9} xxl={5.9}>
                        <Autocomplete
                          autoHighlight
                          fullWidth
                          size="small"
                          options={
                            KMSDRIVEN.length ? KMSDRIVEN : [{ name: 'No Options', value: null }]
                          }
                          groupBy={(option) => option?.a}
                          getOptionLabel={(option) => option.name}
                          openOnFocus
                          onChange={(event, newValue) => {
                            setValue('kmsdriven', newValue?.value);
                          }}
                          value={(() => {
                            const addRecord = KMSDRIVEN?.find(
                              (data) => data.value === allValues.kmsdriven
                            );
                            const editRecord = row?.ac_group;

                            if (addRecord) return addRecord;
                            if (editRecord) return editRecord;
                            return null;
                          })()}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <span>
                                  Kms Driven <span style={{ color: 'red' }}>*</span>
                                </span>
                              }
                              name="kmsdriven"
                              error={errors?.kmsdriven && !allValues?.kmsdriven}
                            />
                          )}
                          renderOption={(props, option) => (
                            <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                              <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                {option?.name}
                              </ListItem>
                            </Box>
                          )}
                          ListboxProps={{ ...bgColorAutocomplete }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                        <RHFTextField
                          name="eamt"
                          type="number"
                          label={
                            <span>
                              Exc. Amt.<span style={{ color: 'red' }}>*</span>
                            </span>
                          }
                          error={errors?.eamt && !allValues?.eamt}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Autocomplete
                          autoHighlight
                          fullWidth
                          size="small"
                          options={exchangeAgentData.rows}
                          groupBy={(option) => option?.a}
                          getOptionLabel={(option) => option.name}
                          filterOptions={(options, state) => {
                            if (exchangeAgentData.rows.length) {
                              const filteredData = exchangeAgentData.rows.filter((x) =>
                                x.name?.toLowerCase().includes(state.inputValue?.toLowerCase())
                              );
                              return filteredData.length
                                ? filteredData
                                : [{ name: 'No matching options', value: null }];
                            }
                            return [{ name: 'No Options', value: null }];
                          }}
                          openOnFocus
                          onChange={(event, newValue) => {
                            setValue('exchangeId', newValue?.id);
                            setValue('exchangeagent', newValue);
                          }}
                          value={allValues?.exchangeagent}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <span>
                                  Select Exchange Agent<span style={{ color: 'red' }}>*</span>
                                </span>
                              }
                              name="exchangeId"
                              error={errors?.exchangeId && !allValues?.exchangeId}
                            />
                          )}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              {...props}
                              key={option.id}
                              sx={{ minHeight: 'auto !important' }}
                            >
                              <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                            </Box>
                          )}
                          ListboxProps={{ ...bgColorAutocomplete }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <RHFTextField
                          name="eregino"
                          label={
                            <span>
                              Exchange Vehicle Registration No.
                              <span style={{ color: 'red' }}>*</span>
                            </span>
                          }
                          error={errors?.eregino && !allValues?.eregino}
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Stack spacing={1.5}>
                      <Typography variant="subtitle2"> Upload Documents</Typography>
                      <RHFUpload
                        multiple
                        thumbnail
                        name="images"
                        maxSize={3145728}
                        onDrop={handleDrop}
                        onRemove={handleRemoveFile}
                        onRemoveAll={handleRemoveAllFiles}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={4}
                  spacing={2}
                  alignContent="start"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Product details
                    </Typography>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Typography variant="h10" gutterBottom>
                      {allValues?.avariantName?.name}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField
                      name="exshowroom"
                      type="number"
                      label="Ex-Showroom Amt."
                      size="small"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="ew" type="number" label="Extended Warranty" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="rtotax" type="number" label="RTO Tax" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="coating" type="number" label="Coating" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="insurance" type="number" label="Insuarance" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="other" type="number" label="Other Amt." size="small" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="smctax" type="number" label="SMC TAX" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField
                      name="access"
                      type="number"
                      label="Accessories Amt."
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField
                      name="hypothecation"
                      type="number"
                      label="HPA Amt."
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField
                      name="discount"
                      type="number"
                      label="Discount Amt."
                      size="small"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    display="flex"
                    justifyContent="space-between"
                    color={(theme) => theme.palette.text.secondary}
                  >
                    <Typography variant="subtitle1" gutterBottom>
                      Total Amount
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      {allValues?.total ? fMoney.format(allValues?.total, { symbol: '₹ ' }) : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Stack
            direction="row-reverse"
            alignItems="center"
            sx={{
              bottom: 0,
              zIndex: 111111,
              p: 2,
              position: 'sticky',
              justifyContent: 'space-between',
              width: '100%',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Tooltip title="(Ctrl + A)" arrow>
              <LoadingButton
                variant="contained"
                onClick={async () => {
                  if (await trigger()) {
                    onSubmit(allValues);
                  }
                }}
                loading={loading}
              >
                {(row || bookingStatus || allValues?.aenquiryId) && allValues?.code
                  ? 'Update'
                  : 'Add'}
              </LoadingButton>
            </Tooltip>

            <Button variant="outlined" onClick={onClose} sx={{ mr: 2 }}>
              Cancel
            </Button>

            <Grid flexGrow={1} spacing={2} />
          </Stack>
        </FormProvider>
      </Dialog>

      {printModel.value && (
        <SuccessConfirmDialog
          open={printModel.value}
          onClose={() => {
            printModel.onFalse();
            onClose();
          }}
          content="Do you want to print?"
          action={
            <Button
              variant="contained"
              color="inherit"
              onClick={() => {
                onClose();
                setPDFViwer(allValues?.code || accountId);
              }}
            >
              Yes
            </Button>
          }
          action2="No"
        />
      )}
    </>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
  setPDFViwer: PropTypes.number,
};
