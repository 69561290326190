import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect } from 'react';
// @mui
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import queryString from 'query-string';
import { useSnackbar } from 'src/components/snackbar';
import { useDebounce } from 'src/hooks/use-debounce';
import { useTable } from 'src/components/table';
import BankReconciliation from 'src/layouts/_common/bank-reconciliation';
import { fetcher, update } from 'src/utils/axios';
import { Divider, Grid, Typography } from '@mui/material';
import { fMoney, formatDate } from 'src/_mock/constant_funcation';
import { Box, Stack } from '@mui/system';
import Scrollbar from 'src/components/scrollbar';
import moment from 'moment';
import VoucherUserQuickEditForm from 'src/sections/transaction/entries/quick-edit-form';
import { useEventListener } from 'src/hooks/use-event-listener';
import { KEY, TRANSACTION_TYPES } from 'src/_mock/constant';
import { useRouter } from 'src/routes/hooks';
import BankDataGridBasic from '../data-grid-basic';

const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
};

export default function ListView() {
  const table = useTable();
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();

  const [voucherData, setVoucherData] = useState(null);
  const VoucherEdit = useBoolean();

  const [lastBalance, setLastBalance] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);

  const router = useRouter();

  const [cal, setCal] = useState({
    crTotal: 0,
    drTotal: 0,
  });

  const [bankData, setBankData] = useState(null);
  const [bdate, setBDate] = useState({
    date: null,
    id: null,
  });

  const [filters, setFilters] = useState(defaultFilters);

  const debouncedQuery = useDebounce(filters);

  const { page } = table;
  const bankreconciliation = useBoolean();

  const getAll = useCallback(async () => {
    try {
      let URL = 'entries/';
      URL += `?page=${page + 1}&limit=100000&t_type=${bankData?.t_type}&fdate=${
        bankData?.fdate
      }&tdate=${bankData?.tdate}&accountId=${bankData?.bankId}&asc=id&`;
      if (Object.keys(debouncedQuery).length) {
        const nefil = { ...debouncedQuery };
        if (debouncedQuery.status === 'all') {
          delete nefil.status;
        }
        URL += queryString.stringify(nefil);
      }

      const { data } = await fetcher(URL);
      if (data) {
        setCount(data?.count);
        setTableData(data?.rows);
        setLastBalance(data?.last_bal);
      }
    } catch (error) {
      console.error(error);
    }
  }, [bankData?.bankId, bankData?.fdate, bankData?.t_type, bankData?.tdate, debouncedQuery, page]);

  useEffect(() => {
    if (bankData) getAll();
  }, [bankData, getAll]);

  useEffect(() => {
    const ctotal = tableData
      .filter(
        (x) =>
          [2, 6, 7].includes(x.t_type) &&
          x.bankdate !== null &&
          moment(bankData?.fdate).isSameOrBefore(moment(x.bankdate)) &&
          moment(bankData?.tdate).isSameOrAfter(moment(x.bankdate))
      )
      .reduce((acc, cur) => acc + cur.amount, 0);

    const dtotal = tableData
      .filter(
        (x) =>
          [1, 5, 7].includes(x.t_type) &&
          x.bankdate !== null &&
          moment(bankData?.fdate).isSameOrBefore(moment(x.bankdate)) &&
          moment(bankData?.tdate).isSameOrAfter(moment(x.bankdate))
      )
      .reduce((acc, cur) => acc + cur.amount, 0);

    setCal({ crTotal: ctotal, drTotal: dtotal });
  }, [bankData?.fdate, bankData?.tdate, tableData]);

  const openSetting = () => {
    bankreconciliation.onTrue();
  };

  useEffect(() => {
    if (bdate.date && bdate.id) {
      bankDateUpdated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bdate]);

  const bankDateUpdated = async () => {
    let payload = null;
    payload = { bankdate: bdate.date };
    try {
      let URL = 'entries/';

      if (bdate.id && payload) {
        URL += bdate.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      }
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  };

  useEffect(() => {
    if (bankData) {
      cloBalUpdated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankData]);

  useEffect(() => {
    bankreconciliation.onTrue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let lastTransAmount = 0;

  console.log(tableData, 'tableData');

  tableData.forEach((a, b) => {
    if (a.bankdate === null) {
      if (a.cb === 1) lastTransAmount += a.amount;
      if (a.cb === 2) lastTransAmount -= a.amount;
    }
  });

  console.log(lastTransAmount, 'lastTransAmount');

  const cloBalUpdated = async () => {
    let payload = null;
    payload = { clo_bal: parseFloat(bankData?.bal_bank) };

    try {
      let URL = 'accounts/';
      if (bankData.bankId && payload) {
        URL += bankData.bankId;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      }
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  };

  const handleTableCellDoubleClick = useCallback(
    (vId) => {
      console.log(vId, 'DOBARIYA row');

      const vObj = tableData?.find((x) => x.id === vId);

      setVoucherData(vObj);
      VoucherEdit.onTrue();
    },
    [VoucherEdit, tableData]
  );

  const handleBankDateChange = useCallback((id, date) => {
    setBDate({
      id,
      date: moment(date).format('YYYY-MM-DD'), // Ensure the date is in the correct format
    });
  }, []);

  console.log(bankData?.setBank?.op_bal, 'bankData?.setBank?.op_bal');
  console.log(cal.crTotal, 'bankData?.setBank?.op_bal', cal.drTotal);

  const bp_cb = parseFloat(lastBalance) + cal.crTotal - cal.drTotal;
  const reflectedBal = parseFloat(bankData?.bal_bank) - parseFloat(bp_cb);

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      router.push('/');
    }
    if (event.altKey && event.key.toLowerCase() === KEY.BANK_RECONCILIATION) {
      event.stopPropagation();
      bankreconciliation.onTrue();
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          settingIcon="ant-design:reconciliation-twotone"
          settingTitle="Set Bank Reconciliation (Alt + B)"
          openSettingTrue="true"
          openSetting={openSetting}
          heading="Bank Reconciliation"
          links={[{ name: 'Dashboard', href: paths.home }, { name: 'Bank Reconciliation List' }]}
          sx={{
            mb: { xs: 2, md: 0 },
          }}
        />

        <Card>
          {bankData?.setBank && (
            <Grid display="flex" justifyContent="space-between" mt={1} sx={{ padding: '0px 20px' }}>
              <Grid item xs={12} md={4}>
                <Typography variant="body1" sx={{ flex: 1 }}>
                  Branch : {bankData?.setBank?.branch_name || '-'} <br /> A/c No.{' '}
                  {bankData?.setBank?.acc_no || ''}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" sx={{ flex: 1, textAlign: 'center' }}>
                  {bankData?.setBank?.name || ''}
                </Typography>
                <Typography variant="body2" sx={{ flex: 1, textAlign: 'center' }}>
                  {bankData?.fdate &&
                    bankData?.tdate &&
                    `From : ${formatDate(bankData?.fdate)} To :
                  ${formatDate(bankData?.tdate)}`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
                <Typography variant="body1" sx={{ flex: 1 }}>
                  Opening Balance <br />{' '}
                  <strong>{fMoney.format(lastBalance, { symbol: '₹ ' })} DB</strong>
                </Typography>
              </Grid>
            </Grid>
          )}

            <Box sx={{ height: 320 }}>
              <BankDataGridBasic
                data={tableData.map((x) => ({
                  id: x?.id,
                  date: formatDate(x?.dt),
                  particular: x?.entry_details?.find((x) => x.order === 2)?.account?.name,
                  vType: TRANSACTION_TYPES[x.t_type],
                  instNo: x?.entry_details?.find((x) => x.order === 1)?.ac_no,
                  banDate: x?.bankdate ? new Date(x?.bankdate) : null,
                  debit:
                    x.entry_details.find((x) => x.accountId === bankData?.bankId)?.cb === 2
                      ? fMoney.format(x?.amount, { symbol: '₹ ' })
                      : '',
                  credit:
                    x.entry_details.find((x) => x.accountId === bankData?.bankId)?.cb === 1
                      ? fMoney.format(x?.amount, { symbol: '₹ ' })
                      : '',
                }))}
                handleBankDateChange={handleBankDateChange}
                onTableCellDoubleClick={(vId) => {
                  handleTableCellDoubleClick(vId);
                }}
              />
            </Box>

          {bankData?.setBank && (
            <>
              <Stack
                direction="row"
                mt={2}
                sx={{ typography: 'subtitle1', textAlign: 'right', justifyContent: 'end' }}
              >
                <Box sx={{ fontSize: '13px' }}>As per Books Bal :</Box>
                <Box sx={{ width: 160 }}>{fMoney.format(bp_cb, { symbol: '₹ ' })}</Box>
                <Box sx={{ width: 160 }} />
              </Stack>

              <Stack
                direction="row"
                sx={{ typography: 'subtitle1', textAlign: 'right', justifyContent: 'end' }}
              >
                <Box sx={{ fontSize: '13px' }}>As per Bank Bal :</Box>
                <Box sx={{ width: 160 }}>
                  {fMoney.format(parseFloat(bankData?.bal_bank), { symbol: '₹ ' })}
                </Box>
                <Box sx={{ width: 160 }} />
              </Stack>
              <Divider sx={{ borderStyle: 'dashed' }} />
              <Stack
                direction="row"
                sx={{ typography: 'subtitle1', textAlign: 'right', justifyContent: 'end' }}
              >
                <Box sx={{ fontSize: '13px' }}>Reflected Bank :</Box>
                <Box sx={{ width: 160, color: 'red' }}>
                  {fMoney.format(reflectedBal, { symbol: '₹ ' })}
                </Box>
                <Box sx={{ width: 160 }} />
              </Stack>
            </>
          )}
        </Card>
      </Container>

      {VoucherEdit.value && (
        <VoucherUserQuickEditForm
          row={voucherData}
          open={VoucherEdit.value}
          onClose={VoucherEdit.onFalse}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}

      {bankreconciliation.value && (
        <BankReconciliation
          open={bankreconciliation.value}
          bankData={bankData}
          setBankData={setBankData}
          onClose={() => {
            bankreconciliation.onFalse();
          }}
        />
      )}
    </>
  );
}
