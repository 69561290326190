/* eslint-disable no-nested-ternary */
/* eslint-disable new-cap */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-pascal-case */
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import React, { useState, useCallback, useEffect, useContext, useMemo } from 'react';
// @mui
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
// routes
import { paths } from 'src/routes/paths';
// components
import {
  RHFSelect,
} from 'src/components/hook-form';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import queryString from 'query-string';
import { useSnackbar } from 'src/components/snackbar';
import { useDebounce } from 'src/hooks/use-debounce';
import { useTable, TablePaginationCustom } from 'src/components/table';
import { bank_cash_report_formate, report_periods } from 'src/_mock/constant';
import { useEventListener } from 'src/hooks/use-event-listener';
import MenuItem from '@mui/material/MenuItem';
//

import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { fMoney } from 'src/_mock/constant_funcation';
import { AuthContext } from 'src/auth/context/jwt';
import moment from 'moment';
import { DateField, DatePicker } from '@mui/x-date-pickers';
import { fetcher, destroy } from 'src/utils/axios';
import LOGO from 'src/assets/logo/amideep_honda.jpg';
import Scrollbar from 'src/components/scrollbar';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Divider,
  Grid,
  IconButton,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import NotFound from 'src/sections/error/notfound-invoice';
import Iconify from 'src/components/iconify';
import { isNaN } from 'lodash';
import { MaterialReactTable, MRT_ExpandAllButton, useMaterialReactTable } from 'material-react-table';
import { useBoolean } from 'src/hooks/use-boolean';
import SaleInvoicePDF from './sale-invoice-pdf';

const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
};

// ----------------------------------------------------------------------
const based = { 1: 'SALE', 2: 'STOCK' }
const TYP = { 'i': 'INVOICE', 'c': 'CHALLAN' }
export default function ListView({ reportsDetails }) {
  // const table = useTable({ defaultRowsPerPage: 10 });
  const [currentTab, setCurrentTab] = useState('general');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();
  const { user } = useContext(AuthContext);
  const startDate = moment(user?.co_year?.sdate);
  const endDate = moment(user?.co_year?.edate);

  // Format the dates in the desired format
  const formattedStartDate = startDate.format('DD/MM/YYYY');
  const formattedEndDate = endDate.format('DD/MM/YYYY');

  // Convert formattedStartDate and formattedEndDate back to moment objects for comparison
  const startDateMoment = moment();
  const endDateMoment = moment();

  const [information, setData] = useState({
    ac_groupId: null,
    accountId: null,
    t_type: 'i',
    basedon: 1,
    formate: '2',
    period: '1',
    fdate: startDateMoment,
    tdate: endDateMoment,
    accountList: [],
    accountMeta: {
      page: 1,
      name: '',
      totalPage: 1,
      editRow: null,
    },
  });
  const handleExportRows = (rows) => {
    const doc = new jsPDF({ orientation: 'landscape' });
    const tableHeaders = columns.map((c) => c.header);
    const tableHeaderObj = {}
    columns.forEach((c) => {
      tableHeaderObj[c.header] = c.id
    });
    const tableData = rows.map((row) => tableHeaders.map(y => row.original[tableHeaderObj[y]]));
    // Text for the header

    const companyName = user?.companies.find((x) => x.id === user.companyId)?.name;
    const reportTitle = `DAILY ${based[information.basedon]} REPORT ${moment(information.fdate).format('DD-MM-YYYY')} TO ${moment(information.tdate).format('DD-MM-YYYY')}`;
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      styles: { fontSize: 6 },
      margin: { top: 20 },
      didDrawPage: (data) => {
        // Set font to bold and size
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold');

        // Add company name on the left side
        doc.text(`COMPANY : ${companyName}`, data.settings.margin.left, 10);

        // Add the logo on the left side within the header height
        const imgWidth = 15; // Adjust width according to your logo
        const imgHeight = 15; // Adjust height according to your logo
        const logoXPos = data.settings.margin.left + 2; // Adjust X position
        const logoYPos = 10; // Adjust Y position as needed
        // doc.addImage(LOGO, 'PNG', logoXPos, logoYPos, imgWidth, imgHeight);
        // Calculate the center position for the report title
        const pageWidth = doc.internal.pageSize.getWidth();
        const centerPosition = pageWidth / 2;

        // Add the report title at the top center of each page
        doc.text(reportTitle, centerPosition, 10, { align: 'center' });
      },
      didDrawCell: (data) => {
        if (data.cell.section === 'body') {
          const rowData = rows[data.row.index].original;
          console.log(table.getGr)

        }
      }
    });
    doc.save(`${based[information.basedon]}_VEHICLE_${TYP[information.t_type]}_REPORT_${moment().format('DD_MM_YYYY_HH_MM_ss')}.pdf`);
  };
  const [tableData, setTableData] = useState([]);
  const [reportData, setReportData] = useState({});
  const [count, setCount] = useState(0);
  const view = useBoolean();
  const columns = [
    {
      header: 'BRANCH',
      accessorKey: 'branchName',
      id: 'branchName',
      width: 50
    },
    {
      header: 'MODEL',
      accessorKey: 'productName',
      id: 'productName',
      size: 250
    },
    {
      header: 'CUSTOMER NAME',
      accessorKey: 'customerName',
      id: 'customerName',
      size: 250
    },
    {
      header: 'INV NO',
      accessorKey: 'identity',
      id: 'identity',
    },
    {
      header: 'INV DT',
      accessorKey: 'dt',
      id: 'dt',
    },
    {
      header: 'COLOUR',
      accessorKey: 'colourName',
      id: 'colourName',
    },

    {
      header: 'CHASSIS NO',
      accessorKey: 'chassisNo',
      id: 'chassisNo',
    },
    {
      header: 'ENGIN NO',
      accessorKey: 'enginNo',
      id: 'enginNo',
    },
    {
      header: 'AMOUNT',
      accessorKey: 'amount',
      id: 'amount',
    },
    {
      header: 'TOTAL',
      accessorKey: 'sold',
      id: 'sold',
      AggregatedCell: ({ cell, table }) => (
        <>
          Total by {' '}
          {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header} :{' '}
          <Box
            sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
          >
            {' '} &nbsp;{cell.getValue()}
          </Box>
        </>
      ),
      Footer: () => (
        <Stack>
          Total :
          <Box color="warning.main">{totalvehicle}</Box>
        </Stack>
      ),
    }
  ];
  const totalvehicle = useMemo(
    () => {
      if (reportData && reportData.rows && Array.isArray(reportData.rows))
        return reportData.rows.reduce((acc, curr) => acc + curr.sold, 0)
      return 0
    },
    [reportData],
  );
  const table = useMaterialReactTable({
    columns,
    data: reportData && reportData.rows && Array.isArray(reportData.rows) ? reportData.rows : [],
    displayColumnDefOptions: {
      'mrt-row-expand': {
        Header: () => (
          <Stack direction="row" alignItems="center">
            <MRT_ExpandAllButton table={table} />
            <Box>Groups</Box>
          </Stack>
        ),
        GroupedCell: ({ row, table }) => {
          const { grouping } = table.getState();
          return row.getValue(grouping[grouping.length - 1]);
        },
        enableResizing: true,
        muiTableBodyCellProps: ({ row }) => ({
          sx: (theme) => ({
            color:
              row.depth === 0
                ? theme.palette.primary.main
                : row.depth === 1
                  ? theme.palette.secondary.main
                  : undefined,
          }),
        }),
        size: 200,
      },
    },
    muiSelectProps: {
      sx: {
        // Applies styles to rows per page dropdown
        backgroundColor: '#ffffff',
        border: '1px solid #dcdcdc',
        borderRadius: '5px',
        padding: '4px 8px',
        fontSize: '14px',
        color: '#333',
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)',
        },
        '&:active': {
          transform: 'translateY(2px)',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    enableGrouping: true,
    enableColumnResizing: true,
    columnFilterDisplayMode: 'popover',
    groupedColumnMode: 'reorder',
    enableColumnFilters: true,
    enableFilters: true,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    initialState: {
      density: 'compact',
      expanded: true,
      pagination: { pageIndex: 0, pageSize: 20 },
      sorting: [{ id: 'productName', desc: false }],
      grouping: ['branchName', 'productName'],
      // columnPinning: {
      //   left: ['branchName', 'productName'],
      //   right: ['sold'],
      // },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        {/* <PDFDownloadLink
          document={<SaleInvoicePDF />}
          fileName="12321"
          style={{ textDecoration: 'none' }}
        >
          {({ loading }) => (
            <Tooltip title="Download">
              <IconButton>
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <Iconify icon="eva:cloud-download-fill" />
                )}
              </IconButton>
            </Tooltip>
          )}
        </PDFDownloadLink> */}
        <Tooltip title="View">
          <IconButton onClick={view.onTrue}>
            <Iconify icon="solar:eye-bold" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Download PDF File" arrow>
          <IconButton onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          } disabled={table.getPrePaginationRowModel().rows.length === 0}>
            <Iconify icon="vscode-icons:file-type-pdf2" />
          </IconButton>
        </Tooltip>
        <Grid item container xs={12} sm={12} md={11} lg={11} xl={11} xxl={11} spacing={2}>
          <Grid item sm={12} md={6} lg={2} xl={2}>
            <Autocomplete
              autoHighlight
              fullWidth
              freeSolo
              size="small"
              options={throughstock?.map((option) => option.name)}
              getOptionLabel={(option) => option}
              value={
                throughstock?.find((data) => data.value === information.basedon)
                  ?.name || ''
              }
              onChange={(event, newValue) => {
                const label = throughstock?.find(
                  (data) => data.name === newValue
                )?.value;
                if (label) {
                  setData({ ...information, basedon: label });
                }
              }}
              renderInput={(params) => (
                <TextField {...params} name="basedon" label="Type Of Stock" />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
            />
          </Grid>
          <Grid item sm={12} md={6} lg={2} xl={2}>
            <Autocomplete
              autoHighlight
              fullWidth
              freeSolo
              size="small"
              options={typeofstock?.map((option) => option.name)}
              getOptionLabel={(option) => option}
              value={
                typeofstock?.find((data) => data.value === information.t_type)
                  ?.name || ''
              }
              onChange={(event, newValue) => {
                const label = typeofstock?.find(
                  (data) => data.name === newValue
                )?.value;
                if (label) {
                  setData({ ...information, t_type: label });
                }
              }}
              renderInput={(params) => (
                <TextField {...params} name="t_type" label="Based On" />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
            />
          </Grid>

          <Grid item sm={12} md={6} lg={4} xl={2}>
            <Autocomplete
              autoHighlight
              fullWidth
              freeSolo
              size="small"
              options={report_periods?.map((option) => option.label)}
              getOptionLabel={(option) => option}
              value={
                report_periods?.find((data) => data.value === information.period)?.label || ''
              }
              onChange={(event, newValue) => {
                const label = report_periods?.find((data) => data.label === newValue)?.value;
                if (label) {
                  setData({ ...information, period: label });
                }
              }}
              renderInput={(params) => (
                <TextField {...params} name="bookId" label="Select Periods" />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
            />
          </Grid>
          <Grid item sm={12} md={6} lg={4} xl={2}>
            <Autocomplete
              autoHighlight
              fullWidth
              freeSolo
              size="small"
              options={bank_cash_report_formate?.map((option) => option.label)}
              getOptionLabel={(option) => option}
              value={
                bank_cash_report_formate?.find((data) => data.value === information.formate)
                  ?.label || ''
              }
              onChange={(event, newValue) => {
                const label = bank_cash_report_formate?.find(
                  (data) => data.label === newValue
                )?.value;
                console.log(label);
                if (label) {
                  setData({ ...information, formate: label });
                }
              }}
              renderInput={(params) => (
                <TextField {...params} name="formate" label="Select Format" />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
            />
          </Grid>
          <Grid item sm={12} md={6} lg={4} xl={2}>
            <DateField
              value={new Date(information.fdate)}
              onChange={(newValue) => {
                setData({ ...information, fdate: newValue });
              }}
              format="dd/MM/yyyy"
              label="From Date"
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small',
                },
              }}
            />
          </Grid>
          <Grid item sm={12} md={6} lg={4} xl={2}>
            <DateField
              value={new Date(information.tdate)}
              onChange={(newValue) => {
                setData({ ...information, tdate: newValue });
              }}
              format="dd/MM/yyyy"
              label="To Date"
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small',
                },
              }}
            />
          </Grid>
        </Grid>
        {/* <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button> */}
        {/* <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button> */}
      </Box>
    ),
  });
  const [filters, setFilters] = useState(defaultFilters);

  const debouncedQuery = useDebounce(filters);

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = count === 0;

  const { page } = table;
  const limit = table.rowsPerPage;

  const getAll = useCallback(async () => {
    try {
      let URL = 'automobile/reports/sales';

      if (information.t_type && information.fdate && information.tdate) {
        let t_type = '';
        if (information.basedon === 1) {
          if (information.t_type === 'c')
            t_type = 'c'
          if (information.t_type === 'i')
            t_type = 'i'
        }
        if (information.basedon === 2) {
          if (information.t_type === 'c')
            t_type = 'cs'
          if (information.t_type === 'i')
            t_type = 'is'
        }
        URL += `?page=${page + 1}&limit=${limit}&t_type=${t_type}&fdate=${moment(information.fdate).format('YYYY-MM-DD 00:00:00')
          }&tdate=${moment(information.tdate).format('YYYY-MM-DD 23:59:59')}&asc=id&wantbooking=false&isCashbook=true&`;
        if (Object.keys(debouncedQuery).length) {
          const nefil = { ...debouncedQuery };
          if (debouncedQuery.status === 'all') {
            delete nefil.status;
          }
          URL += queryString.stringify(nefil);
        }

        const { data } = await fetcher(URL);
        console.log(data)
        if (data) {
          setReportData(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [debouncedQuery, information.basedon, information.fdate, information.t_type, information.tdate, limit, page]);

  useEffect(() => {
    getAll();
  }, [getAll, information]);
  useEffect(() => {
    console.log(information.t_type)
    getAll();
  }, [getAll, information.t_type]);
  const downloadPdf = async () => {

  };
  const typeofstock = useMemo(() => [{ value: 'i', name: 'invoice' }, { value: 'c', name: 'challan' }], []);
  const throughstock = useMemo(() => [{ value: 1, name: 'invoice' }, { value: 2, name: 'challan' }], []);


  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      table.setIsFullScreen(!table.getState().isFullScreen);
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>

      <>
        <Card sx={{ p: 0, mt: 1, zIndex: table.getState().isFullScreen ? 1111 : undefined, }} id="pdf-content">
          <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
            {based[information.basedon]}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              padding: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Tooltip title="View">
              <IconButton onClick={view.onTrue}>
                <Iconify icon="solar:eye-bold" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download PDF File" arrow>
              <IconButton onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              } disabled={table.getPrePaginationRowModel().rows.length === 0}>
                <Iconify icon="vscode-icons:file-type-pdf2" />
              </IconButton>
            </Tooltip>
            <Grid item container xs={12} sm={12} md={11} lg={11} xl={11} xxl={11} spacing={2}>
              <Grid item sm={12} md={6} lg={2} xl={2}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  freeSolo
                  size="small"
                  options={throughstock?.map((option) => option.name)}
                  getOptionLabel={(option) => option}
                  value={
                    throughstock?.find((data) => data.value === information.basedon)
                      ?.name || ''
                  }
                  onChange={(event, newValue) => {
                    const label = throughstock?.find(
                      (data) => data.name === newValue
                    )?.value;
                    if (label) {
                      setData({ ...information, basedon: label });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} name="basedon" label="Type Of Stock" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={2} xl={2}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  freeSolo
                  size="small"
                  options={typeofstock?.map((option) => option.name)}
                  getOptionLabel={(option) => option}
                  value={
                    typeofstock?.find((data) => data.value === information.t_type)
                      ?.name || ''
                  }
                  onChange={(event, newValue) => {
                    const label = typeofstock?.find(
                      (data) => data.name === newValue
                    )?.value;
                    if (label) {
                      setData({ ...information, t_type: label });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} name="t_type" label="Based On" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                />
              </Grid>

              <Grid item sm={12} md={6} lg={4} xl={2}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  freeSolo
                  size="small"
                  options={report_periods?.map((option) => option.label)}
                  getOptionLabel={(option) => option}
                  value={
                    report_periods?.find((data) => data.value === information.period)?.label || ''
                  }
                  onChange={(event, newValue) => {
                    const label = report_periods?.find((data) => data.label === newValue)?.value;
                    if (label) {
                      setData({ ...information, period: label });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} name="bookId" label="Select Periods" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={4} xl={2}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  freeSolo
                  size="small"
                  options={bank_cash_report_formate?.map((option) => option.label)}
                  getOptionLabel={(option) => option}
                  value={
                    bank_cash_report_formate?.find((data) => data.value === information.formate)
                      ?.label || ''
                  }
                  onChange={(event, newValue) => {
                    const label = bank_cash_report_formate?.find(
                      (data) => data.label === newValue
                    )?.value;
                    console.log(label);
                    if (label) {
                      setData({ ...information, formate: label });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} name="formate" label="Select Format" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={2} xl={2}>
                <DateField
                  value={new Date(information.fdate)}
                  onChange={(newValue) => {
                    setData({ ...information, fdate: newValue });
                  }}
                  format="dd/MM/yyyy"
                  label="From Date"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                    },
                  }}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={2} xl={2}>
                <DateField
                  value={new Date(information.tdate)}
                  onChange={(newValue) => {
                    setData({ ...information, tdate: newValue });
                  }}
                  format="dd/MM/yyyy"
                  label="To Date"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                    },
                  }}
                />
              </Grid>
            </Grid>
            {/* <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button> */}
            {/* <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button> */}
          </Box>
          <Dialog fullScreen open={view.value}>
            <Box sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
              <DialogActions
                sx={{
                  p: 1.5,
                }}
              >
                <Button color="inherit" variant="contained" onClick={view.onFalse}>
                  Close
                </Button>
              </DialogActions>

              {/* <Box sx={{ flexGrow: 1, height: 1, overflow: 'hidden' }}>
                <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
                  <SaleInvoicePDF invoice={}/>
                </PDFViewer>
              </Box> */}
            </Box>
          </Dialog>
        </Card>
        {reportData.length === 0 && <NotFound name="Report" />}
      </>
    </Container >
  );
}

ListView.propTypes = {
  reportsDetails: PropTypes.object,
};
