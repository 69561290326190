import { Helmet } from 'react-helmet-async';
import { COMPANY_NAME } from 'src/_mock/constant';
// sections
import { ListView } from 'src/sections/automobile-reports/sales/view';

// ----------------------------------------------------------------------

const reports = {
  name: 'Cash Book',
  cond: {
    label: 'Cash',
    value: '133,138',
    t_type: '3,4',
  },
};

export default function SaleStockReport() {
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} : Sale Report</title>
      </Helmet>

      <ListView />
    </>
  );
}
