import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Iconify from 'src/components/iconify';
import { ENTRYTYPE, KEY, MODE } from 'src/_mock/constant';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { LoadingButton } from '@mui/lab';
import { fetcher } from 'src/utils/axios';
import { useEventListener } from 'src/hooks/use-event-listener';
import { RHFTextField } from 'src/components/hook-form';
import { isNaN } from 'lodash';
import { AuthContext } from 'src/auth/context/jwt';
import moment from 'moment';
import { Box, Stack } from '@mui/system';
import { useRouter } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import AUserQuickEditForm from 'src/sections/automobile-accounts/account/quick-edit-form';
import CompanyYear from './company-year';

export default function BankReconciliation({ open, onClose, setBankData, bankData }) {
  const { user } = useContext(AuthContext);
  const router = useRouter();

  const quickEdit = useBoolean();

  const [accountEditList, setaccountEditList] = useState({});
  const [bankDetails, setbankDetails] = useState([]);
  const [oplBal, setOplBal] = useState(null);

  const [bankList, setBankList] = useState([]);
  const startDate = moment(user?.co_year?.sdate);
  const endDate = moment(user?.co_year?.edate);

  const values = useMemo(
    () => ({
      bankId: bankData?.bankId || null,
      bal_bank: bankData?.bal_bank || null,
      month: bankData?.month || null,
      fdate: bankData?.fdate || startDate,
      tdate: bankData?.tdate || endDate,
      modeId: bankData?.modeId || null,
      t_type: bankData?.t_type || null,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bankData]
  );

  const methods = useForm({
    values,
  });

  const {
    setValue,
    watch,
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = methods;

  const allValues = watch();

  console.log(allValues, 'allValues');
  useEffect(() => {
    if (allValues.bankId) {
      setValue('modeId', 2);
      setValue('t_type', '1,2,5,6,7');
    }
  }, [allValues.bankId, setValue]);

  const onSubmit = handleSubmit(async (payload) => {
    try {
      payload = { ...payload, setBank: bankDetails };
      await setBankData(payload);
      onClose();
    } catch (error) {
      console.error('Failed to set bank data:', error);
    }
  });

  const { month, fdate, tdate } = allValues;

  // Format the dates in the desired format
  const formattedStartDate = startDate.format('DD/MM/YYYY');
  const formattedEndDate = endDate.format('DD/MM/YYYY');

  // Convert formattedStartDate and formattedEndDate back to moment objects for comparison
  const startDateMoment = moment(formattedStartDate, 'DD/MM/YYYY');
  const endDateMoment = moment(formattedEndDate, 'DD/MM/YYYY');

  useEffect(() => {
    if (open) {
      BankAccountList();

      //   const setupForm = async () => {
      //     await BankAccountList();

      //     setValue('bal_bank', bankData?.bal_bank);
      //     setValue('accno', bankData?.accno);
      //     setValue('month', bankData?.month);
      //     setValue('fdate', bankData?.fdate);
      //     setValue('tdate', bankData?.tdate);
      //     setValue('modeId', bankData?.modeId || 2);
      //     setValue('t_type', bankData?.t_type || '1,2,5,6,7');
      //     setValue('bankId', bankData?.bankId);
      //   };

      //   setupForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, setValue]);
  function getMonthYearAndQuarterRanges(startDate, endDate) {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    const monthYearList = [];
    const quarterList = new Map(); // Use a Map to efficiently manage quarters

    // Generate month-year list based on the start and end dates
    let currentMonth = startDateObj;
    while (currentMonth <= endDateObj) {
      const month = currentMonth.getMonth() + 1;
      const year = currentMonth.getFullYear();
      const formattedMonth = month < 10 ? `0${month}` : `${month}`;
      monthYearList.push({ month: formattedMonth, year, monthYear: `${formattedMonth}-${year}` });

      // Increment month
      currentMonth = new Date(currentMonth.setMonth(currentMonth.getMonth() + 1));
    }

    // Dynamically calculate quarters from monthYearList
    monthYearList.forEach(({ month, year }, index) => {
      // Determine the quarter number based on the index within the year
      const quarterIndex = Math.floor(index / 3);
      const quarterKey = `Q${quarterIndex + 1}-${year}`;

      if (!quarterList.has(quarterKey)) {
        // Calculate start and end dates for this quarter
        const startMonthIndex = quarterIndex * 3;
        const endMonthIndex = startMonthIndex + 2;

        const startMonth = monthYearList[startMonthIndex].month;
        const startYear = monthYearList[startMonthIndex].year;
        const endMonth = monthYearList[Math.min(endMonthIndex, monthYearList.length - 1)].month;
        const endYear = monthYearList[Math.min(endMonthIndex, monthYearList.length - 1)].year;

        const quarterStartDate = `${startYear}-${startMonth}-01`;
        const lastDayOfMonth = new Date(endYear, endMonth, 0).getDate();
        const quarterEndDate = `${endYear}-${endMonth}-${lastDayOfMonth}`;

        quarterList.set(quarterKey, {
          month: -(quarterIndex + 1),
          label: quarterKey,
          startDate: quarterStartDate,
          endDate: quarterEndDate,
        });
      }
    });

    // Convert the Map to an array of its values for the quarter list
    const quarterArray = Array.from(quarterList.values());

    return { monthYearList, quarterList: quarterArray };
  }

  function getMonthYearList(startDate, endDate) {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    const currentDate = startDateObj;
    const monthYearList = [];

    while (currentDate <= endDateObj) {
      // Format month and year (e.g., "MM-YYYY")
      const month = currentDate.getMonth() + 1; // getMonth() returns month from 0 to 11
      const year = currentDate.getFullYear();
      const formattedMonth = month < 10 ? `0${month}` : `${month}`;
      const monthYear = { month: formattedMonth, year, monthYear: `${formattedMonth}-${year}` };

      // Add to list
      monthYearList.push(monthYear);

      // Increment month
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return monthYearList;
  }
  const dateSetBetween = () => {
    console.log(month);

    const { monthYearList, quarterList } = getMonthYearAndQuarterRanges(startDate, endDate);
    console.log('Month-Year List:', monthYearList);
    console.log('Quarter List:', quarterList);
    let year = '';
    let firstDayOfMonth = '';
    let lastDayOfMonth = '';
    if (month >= 1 && month <= 12) {
      year = monthYearList.find((x) => parseInt(x.month, 10) === month).year;
      firstDayOfMonth = month ? new Date(year, month - 1, 1) : new Date(startDate);
      lastDayOfMonth = month ? new Date(year, month, 0) : new Date(endDate);
    }
    console.log([-1, -2, -3, -4].includes(month));
    if ([-1, -2, -3, -4].includes(month)) {
      const { startDate, endDate } = quarterList.find((x) => parseInt(x.month, 10) === month);
      console.log(startDate, endDate);
      firstDayOfMonth = new Date(startDate);
      lastDayOfMonth = new Date(endDate);
    }

    if (firstDayOfMonth && lastDayOfMonth) {
      const fdateMoment = moment(firstDayOfMonth);
      const tdateMoment = moment(lastDayOfMonth);

      const isFdateInRange =
        fdateMoment.startOf('day').isSameOrAfter(startDateMoment.startOf('day')) &&
        fdateMoment.startOf('day').isSameOrBefore(endDateMoment.startOf('day'));

      const isTdateInRange =
        tdateMoment.startOf('day').isSameOrAfter(startDateMoment.startOf('day')) &&
        tdateMoment.startOf('day').isSameOrBefore(endDateMoment.startOf('day'));

      if (isFdateInRange && isTdateInRange) {
        console.log('Both fdate and tdate are within the range.');
        setValue('fdate', firstDayOfMonth);
        setValue('tdate', lastDayOfMonth);
      } else {
        console.log(endDateMoment, tdateMoment);
        console.log(isFdateInRange, isTdateInRange);
        console.log('fdate or tdate is not within the range.');
      }
    }
  };
  const datebetweenchecker = (value) => {
    const firstDayOfMonth = new Date(value);
    const lastDayOfMonth = new Date(value);

    const fdateMoment = moment(firstDayOfMonth);
    const tdateMoment = moment(lastDayOfMonth);

    const isFdateInRange =
      fdateMoment.isSameOrAfter(startDateMoment) && fdateMoment.isSameOrBefore(endDateMoment);
    const isTdateInRange =
      tdateMoment.isSameOrAfter(startDateMoment) && tdateMoment.isSameOrBefore(endDateMoment);

    if (isFdateInRange && isTdateInRange) return true;
    return false;
  };

  const BankAccountList = useCallback(async () => {
    let URL = 'automobile/accounts/';
    URL += `?page=${1}&limit=${10000}&status=true&ac_groupId=22`;
    const { data } = await fetcher(URL);
    setBankList(data?.rows.map((x) => ({ ...x, a: 'name' })));
  }, []);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      await trigger();
      onSubmit();
    }
  };

  const handleCloseAccount = () => {
    quickEdit.onFalse();
    BankAccountList();
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth
        open={open}
        onKeyDown={handleKeyDown}
        PaperProps={{
          sx: { maxWidth: 850 },
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          lg={6}
          xl={6}
          xxl={6}
          sx={{
            top: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Toolbar>
            <Typography variant="h5" sx={{ flex: 1 }}>
              Bank Reconciliation account
            </Typography>

            <CompanyYear />

            <IconButton
              color="inherit"
              edge="start"
              onClick={() => {
                onClose();
              }}
            >
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>
        <FormProvider methods={methods}>
          {console.log(bankList?.find((data) => data.id === allValues.bankId))}
          <DialogContent>
            <Grid container item xs={12} md={12} sx={{ p: 2 }} spacing={4}>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                spacing={2}
                mt={1}
              >
                <Autocomplete
                  autoHighlight
                  fullWidth
                  size="small"
                  disableCloseOnSelect
                  options={bankList}
                  groupBy={(option) => option?.a}
                  getOptionLabel={(option) => option.name}
                  onBlur={(e) => {
                    if (!allValues.bankId) {
                      document.getElementById(e.target.id).focus();
                    }
                  }}
                  openOnFocus={allValues.bankId <= 0}
                  onChange={(event, newValue) => {
                    setaccountEditList(newValue);
                    setbankDetails(bankList?.find((data) => data.name === newValue?.name));
                    setOplBal(bankList?.find((data) => data.name === newValue?.name)?.op_bal);
                    setValue('bankId', newValue?.id);
                  }}
                  value={(() => {
                    const bank = bankList?.find((data) => data.id === allValues.bankId);
                    if (bank) return bank;
                    return null;
                  })()}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: 600,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Bank"
                      error={errors?.bankId && !allValues?.bankId}
                      name="bankId"
                      autoFocus
                    />
                  )}
                  renderGroup={(params) => (
                    <li key={params.key}>
                      <GroupHeader component="li">
                        <ListItem sx={{ padding: 0, margin: 0 }}>Name</ListItem>
                        <ListItem sx={{ padding: 0, margin: 0 }}>Effect On</ListItem>
                      </GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                      <GroupFooter component="li">
                        <Button
                          onClick={() => {
                            setaccountEditList(null);
                            quickEdit.onTrue();
                          }}
                        >
                          Add
                        </Button>
                        {accountEditList?.id && (
                          <Button
                            onClick={() => {
                              quickEdit.onTrue();
                            }}
                          >
                            Edit
                          </Button>
                        )}
                      </GroupFooter>
                    </li>
                  )}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                        {option?.name}
                      </ListItem>
                      <ListItem sx={{ padding: 0, margin: 0 }}>
                        {option.ac_group ? option.ac_group?.name : '-'}
                      </ListItem>
                    </Box>
                  )}
                />
              </Grid>
              <Grid container item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2} spacing={2} mt={1}>
                <RHFTextField
                  name="month"
                  label="month"
                  size="small"
                  type="number"
                  onBlur={(e) => {
                    console.log(e);
                    dateSetBetween();
                  }}
                />
              </Grid>
              <Grid container item xs={12} sm={12} md={5} lg={5} xl={5} xxl={5} spacing={2} mt={1}>
                <Controller
                  name="fdate"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      minDate={new Date(startDate)}
                      maxDate={new Date(endDate)}
                      value={new Date(allValues.fdate)}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (datebetweenchecker(newValue)) field.onChange(newValue);
                      }}
                      format="dd/MM/yyyy"
                      label="From Date"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          size: 'small',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid container item xs={12} sm={12} md={5} lg={5} xl={5} xxl={5} spacing={2} mt={1}>
                <Controller
                  name="tdate"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      minDate={new Date(startDate)}
                      maxDate={new Date(endDate)}
                      value={new Date(field.value)}
                      onChange={(newValue) => {
                        if (datebetweenchecker(newValue)) field.onChange(newValue);
                      }}
                      format="dd/MM/yyyy"
                      label="To Date"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          size: 'small',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} spacing={2} mt={1}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  freeSolo
                  size="small"
                  options={MODE?.map((option) => option.name)}
                  getOptionLabel={(option) => option}
                  openOnFocus
                  value={MODE?.find((data) => data.id === allValues.modeId)?.name || ''}
                  onChange={(event, newValue) => {
                    setValue('modeId', MODE?.find((data) => data.name === newValue)?.id);
                  }}
                  renderInput={(params) => <TextField {...params} name="modeId" label="Mode" />}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                />
              </Grid>
              <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} spacing={2} mt={1}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  freeSolo
                  size="small"
                  options={ENTRYTYPE?.map((option) => option.name)}
                  getOptionLabel={(option) => option}
                  openOnFocus
                  value={ENTRYTYPE?.find((data) => data.value === allValues.t_type)?.name || ''}
                  onChange={(event, newValue) => {
                    setValue('t_type', ENTRYTYPE?.find((data) => data.name === newValue)?.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} name="t_type" label="Entry Type" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                />
              </Grid>
              <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} spacing={2} mt={1}>
                <RHFTextField
                  name="bal_bank"
                  label="Bal As per Bank"
                  size="small"
                  type="number"
                  onBlur={(e) => {
                    let balBank = allValues.bal_bank;
                    if (!isNaN(balBank) && balBank >= 0) {
                      balBank = Number(balBank).toFixed(2);
                      setValue(`bal_bank`, balBank);
                      if (allValues?.bal_bank) {
                        document.getElementById('set-bank').focus();
                      }
                    }
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    inputProps: {
                      style: { textAlign: 'right' },
                    },
                  }}
                />
              </Grid>
              <Grid container item xs={12} md={12} lg={12} xl={12} xxl={12} spacing={2} mt={1}>
                <Typography variant="body1" sx={{ flex: 1 }}>
                  <strong>Bal As per Company Bank</strong>
                </Typography>
                <Typography variant="body1" sx={{ flex: 1, textAlign: 'right' }}>
                  <strong>{oplBal}</strong>
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>

          <Stack
            direction="row"
            alignItems="center"
            sx={{
              bottom: 0,
              zIndex: 111111,
              p: 2,
              justifyContent: 'space-between',
              width: '100%',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Grid flexGrow={1} spacing={2}>
              <Tooltip title="Go to Dashboard" arrow>
                <IconButton color="primary" onClick={(e) => router.push('/')}>
                  <Iconify icon="mdi:monitor-dashboard" />
                </IconButton>
              </Tooltip>
            </Grid>

            {allValues.bankId &&
              allValues.bal_bank &&
              allValues.fdate &&
              allValues.tdate &&
              allValues.t_type && (
                <Tooltip title="(Ctrl + A)" arrow>
                  <LoadingButton
                    variant="contained"
                    id="set-bank"
                    onClick={async () => {
                      if (await trigger()) {
                        onSubmit(allValues);
                      }
                    }}
                  >
                    ok
                  </LoadingButton>
                </Tooltip>
              )}
          </Stack>
        </FormProvider>
      </Dialog>

      {quickEdit.value && (
        <AUserQuickEditForm
          row={null || accountEditList}
          open={quickEdit.value}
          onClose={handleCloseAccount}
        />
      )}
    </>
  );
}

BankReconciliation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setBankData: PropTypes.func,
  bankData: PropTypes.object,
};
