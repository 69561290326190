import { Helmet } from 'react-helmet-async';
import { COMPANY_NAME } from 'src/_mock/constant';
// sections
import { ListView } from 'src/sections/automobile-reports/bank-book/view';

// ----------------------------------------------------------------------

const reports = {
  name: 'Ledger Report',
  cond: {
    label: 'Ledger',
    value: '',
    t_type: '1,2,3,4,5,6,7,8,9',
  },
};

export default function aMLedgerReportList() {
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} : Ledger Report</title>
      </Helmet>

      <ListView reportsDetails={reports} />
    </>
  );
}
