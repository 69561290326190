import PropTypes from 'prop-types';
import { Dialog, Divider, Grid, IconButton, Toolbar, Typography, alpha } from '@mui/material';
import React from 'react';
import Iconify from 'src/components/iconify';

export function PdfViewerScreen({ open, url, onClose }) {
  return (
    <Dialog fullScreen open={open}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            top: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Toolbar>
            <Typography variant="h5" sx={{ flex: 1 }}>
              PDF Viewer
            </Typography>
            <IconButton
              color="inherit"
              edge="start"
              onClick={() => {
                onClose();
              }}
              tabIndex={-1}
            >
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>

          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>
        <Grid container item>
          <iframe title="PDF Viewer" src={url} width="100%" height="600" />
        </Grid>
      </Grid>
    </Dialog>
  );
}

PdfViewerScreen.propTypes = {
  onClose: PropTypes.func,
  url: PropTypes.string,
  open: PropTypes.bool,
};
