import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { GENDER, KEY, PURCHASE_TYPE } from 'src/_mock/constant';
import {
  Autocomplete,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Paper,
  TableFooter,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';

import { useEventListener } from 'src/hooks/use-event-listener';
import { Stack } from '@mui/system';
import {
  assignNullToEmptyOrUndefinedValues,
  useBgColorAutocomplete,
} from 'src/_mock/constant_funcation';
import { GroupHeader, GroupItems } from 'src/_mock/constant_style';
import Iconify from 'src/components/iconify';

export default function AccessoriesGroups({
  row,
  open,
  accesoriesgroup,
  setaccesoriesgroup,
  onClose,
  table,
  getAll,
  tableData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const tableBodyRef = useRef(null);
  const values = useMemo(
    () => ({
      stocks:
        row?.stocks?.map((data) => ({
          id: data?.id || null,
          productId: data?.productId || null,
          pname: data?.pname || null,
          qty: data?.qty || null,
          rate: data?.rate || null,
          amount: data?.amount || null,
          discount: data?.discount || null,
          gst: data?.gst || null,
          netAmount: data?.netAmount || null,
        })) || [],
      stockdetails:
        row?.stockdetails?.map((xdata) => ({
          id: xdata?.id || null,
          pname: xdata?.avariant?.name || null,
          productId: xdata?.productId || null,
          rate: xdata?.rate || null,
          chassisNo: xdata?.chassisNo || '',
          enginNo: xdata?.enginNo || '',
          acolourId: xdata?.acolourId || '',
          keyNo: xdata?.keyNo || '',
          batteryNo: xdata?.batteryNo || '',
        })) ||
        [...Array(5)].map(() => ({
          id: null,
          pname: null,
          productId: null,
          rate: null,
          chassisNo: '',
          enginNo: '',
          acolourId: '',
          keyNo: '',
          batteryNo: '',
        }))
    }),
    [row]
  );

  const methods = useForm({
    values,
    mode: 'onChange',
  });
  useEffect(() => {
    console.log(accesoriesgroup)
    if (accesoriesgroup.length && productData.length) {
      const initialItems = accesoriesgroup.map(item =>
        productData.find(product => product.id === item.amodelId)
      ).filter(Boolean); // Filter out any items that are not found in productData
      console.log(productData)
      console.log(initialItems)
      setSelectedItems(initialItems);
    }
  }, [accesoriesgroup, productData]);
  const {
    reset,
    watch,
    control,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = methods;


  const productVariantList = useCallback(async () => {
    try {
      const URL = 'automobile/product/models/?amakerId=null';
      const { data } = await fetcher(URL);
      if (data) {
        setProductData(data?.rows || []);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onSubmit = handleSubmit(async () => {
    setLoading(true);
    try {
      const URL = '/automobile/accessoriesgroups/';
      const payload = selectedItems.map(item => ({ amodelId: item.id })); // Adjust the payload format as needed

      if (payload.length > 0) {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      onClose();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      setLoading(false);
      console.error(error);
    }
  });



  useEffect(() => {
    reset();
    productVariantList();
  }, [productVariantList, open, reset]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog fullScreen open={open} onKeyDown={handleKeyDown}>
      <FormProvider methods={methods}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update Product group' : 'Add Product group'}
              </Typography>
              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          <DialogContent sx={{ p: 2 }}>
            <Grid container item xs={12} md={12} lg={12} xl={12} xxl={12} sx={{ mt: 1 }}>
              <Autocomplete
                autoFocus
                openOnFocus
                autoHighlight
                fullWidth
                multiple
                options={productData}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                value={selectedItems}
                onChange={(event, newValue) => {
                  setSelectedItems(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Vehicle Models" placeholder="Vehicle Models" autoFocus />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.name}>
                    <Checkbox key={option.name} size="small" disableRipple checked={selected} />
                    {option.name}
                  </li>
                )}
                renderTags={(selected, getTagProps) =>
                  selected.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={option.name}
                      label={option.name}
                      size="small"
                    />
                  ))
                }
              />
            </Grid>
          </DialogContent>
        </Grid>

        <Stack
          direction="row-reverse"
          alignItems="center"
          sx={{
            bottom: 0,
            zIndex: 111111,
            p: 2,
            position: 'fixed',
            justifyContent: 'space-between',
            width: '100%',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
          id="modelfooter"
        >


          <Tooltip title="(Esc)" arrow>
            <Button variant="outlined" onClick={() => {
              setaccesoriesgroup('accessoriesgroups', selectedItems.map(item => ({ amodelId: item.id })));
              onClose();
            }} sx={{ mr: 1 }}>
              Save
            </Button>
          </Tooltip>

        </Stack>
      </FormProvider>
    </Dialog>
  );
}

AccessoriesGroups.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
  accesoriesgroup: PropTypes.array,
  setaccesoriesgroup: PropTypes.func,
};
