import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { create, update } from 'src/utils/axios';
import { GST_TYPE, KEY } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import CompanyYear from 'src/layouts/_common/company-year';
import { Stack } from '@mui/system';
import { useBgColorAutocomplete } from 'src/_mock/constant_funcation';

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const bgColorAutocomplete = useBgColorAutocomplete();

  const GSTSlabSchema = Yup.object().shape({
    description: Yup.string().required('GST Slab is required'),
    type: Yup.number().required('Type is required'),
    SGST: Yup.number().required('SGST is required'),
    CGST: Yup.number().required('CGST is required'),
    IGST: Yup.number().required('IGST is required'),
  });

  const values = useMemo(
    () => ({
      description: row?.description || '',
      isHighligh: '',
      type: row?.type || null,
      SGST: row?.SGST || '',
      CGST: row?.CGST || '',
      IGST: row?.IGST || '',
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(GSTSlabSchema),
    values,
    mode: 'onChange',
  });

  const {
    reset,
    trigger,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      let URL = 'automobile/gst_rates/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
      setLoading(false);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h6" sx={{ flex: 1 }}>
                {row ? 'Update GST Slab' : 'Add GST Slab'}
              </Typography>

              <CompanyYear />

              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          <Grid item container xs={12} md={12} lg={12} xl={12} xxl={12} sx={{ p: 3 }} spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
              <RHFTextField
                name="description"
                label={
                  <span>
                    GST Slab<span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                autoFocus
                error={errors?.description && !allValues?.description}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
              <Autocomplete
                autoHighlight={allValues.isHighligh}
                fullWidth
                freeSolo
                size="small"
                openOnFocus
                onInputChange={(event, newValue) => {
                  setValue('isHighligh', newValue);
                }}
                onBlur={() => {
                  setValue('isHighligh', '');
                }}
                options={GST_TYPE?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={GST_TYPE?.find((data) => data.id === allValues.type)?.name || ''}
                onChange={(event, newValue) => {
                  setValue('type', GST_TYPE?.find((data) => data.name === newValue)?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="type"
                    label={
                      <span>
                        GST Type<span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    error={errors?.type && !allValues?.type}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
                ListboxProps={{ ...bgColorAutocomplete }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <RHFTextField
                name="SGST"
                label={
                  <span>
                    State/UT Tax %<span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                type="number"
                error={errors?.SGST && !allValues?.SGST}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <RHFTextField
                name="CGST"
                label={
                  <span>
                    Central Tax %<span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                type="number"
                error={errors?.CGST && !allValues?.CGST}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <RHFTextField
                name="IGST"
                label={
                  <span>
                    Integrated Tax %<span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                type="number"
                error={errors?.IGST && !allValues?.IGST}
              />
            </Grid>
          </Grid>
        </Grid>

        <Stack
          direction="row-reverse"
          alignItems="center"
          sx={{
            bottom: 0,
            zIndex: 111111,
            p: 3,
            position: 'sticky',
            width: '100%',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
          id="modelfooter"
        >
          <Tooltip title="(Alt + A)" arrow>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={async () => {
                if (await trigger()) {
                  onSubmit();
                }
              }}
            >
              {row ? 'Update' : 'Add'}
            </LoadingButton>
          </Tooltip>

          <Tooltip title="(Esc)" arrow>
            <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
          </Tooltip>

          <Button
            variant="soft"
            onClick={() => {
              reset(values);
            }}
            sx={{ mr: 2 }}
          >
            Reset
          </Button>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
