export const KEY = {
  CLOSE: 'Escape',
  ENQUIRY: 'e',
  BOOKING: 'b',
  BANK_RECONCILIATION: 'b',
  ADD: 'a',
  INSERT: 'Insert',
  YEAR: 'y',
  YES: 'y',
  NO: 'n',
  OK: 'enter',
  DONE: 'enter',
};

export const TRUE_FALSE_DROPDOWN = [
  { id: 1, name: 'Yes' },
  { id: 2, name: 'No' },
];

export const COMPANY_NAME = 'MyBook';

export const TITLES = [
  { id: '1', name: 'Mr.' },
  { id: '2', name: 'Mrs.' },
  { id: '3', name: 'Ms.' },
  { id: '4', name: 'Miss.' },
];

export const COMPANY_STATUS = [
  { id: 1, name: 'Individual/Proprietorship' },
  { id: 2, name: 'Partnership Firm' },
  { id: 3, name: 'HUF' },
  { id: 4, name: 'Company' },
];

export const PARTNER_STATUS = [
  { id: 1, name: 'New' },
  { id: 2, name: 'Incoming' },
  { id: 3, name: 'Retried' },
];

export const TYPE_OF_ACCOUNTS = [
  { id: 1, name: 'With Stock' },
  { id: 2, name: 'Without Stock' },
];

export const paymentStatus = [
  { id: 'All', name: 'All' },
  { id: 'UnPaid', name: 'UnPaid' },
  { id: 'Paid', name: 'Paid' },
];

export const COMPANY_GROUP = [
  { id: 1, name: 'AMIDEEEP' },
  { id: 2, name: 'ROYAL' },
];
export const padWithZeros = (value, totalDigits = 6) => value.toString().padStart(totalDigits, '0')

export const TYPE_OF_BANK_ACCOUNTS = [
  { value: 'saving', label: 'Saving' },
  { value: 'current', label: 'Current' },
  { value: 'cash_credit', label: 'Cash/Credit' },
  { value: 'loan', label: 'Loan' },
];

export const COUNTRY = [
  { value: '1', label: 'India' },
  { value: '2', label: 'UK' },
  { value: '3', label: 'Canada' },
];

export const CITY = [
  { value: '1', label: 'Surat' },
  { value: '2', label: 'baroda' },
  { value: '3', label: 'Vadodara' },
  { value: '4', label: 'Navrasi' },
];

export const STATE = [
  { value: '1', label: 'Gujarat' },
  { value: '2', label: 'Delhi' },
];

export const WORKING_PARTNER = [
  { id: 1, name: 'Yes' },
  { id: 2, name: 'No' },
];

export const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
  { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 },
  { title: 'Casablanca', year: 1942 },
  { title: 'City Lights', year: 1931 },
  { title: 'Psycho', year: 1960 },
  { title: 'The Green Mile', year: 1999 },
  { title: 'The Intouchables', year: 2011 },
  { title: 'Modern Times', year: 1936 },
  { title: 'Raiders of the Lost Ark', year: 1981 },
  { title: 'Rear Window', year: 1954 },
  { title: 'The Pianist', year: 2002 },
  { title: 'The Departed', year: 2006 },
  { title: 'Terminator 2: Judgment Day', year: 1991 },
  { title: 'Back to the Future', year: 1985 },
  { title: 'Whiplash', year: 2014 },
  { title: 'Gladiator', year: 2000 },
  { title: 'Memento', year: 2000 },
  { title: 'The Prestige', year: 2006 },
  { title: 'The Lion King', year: 1994 },
  { title: 'Apocalypse Now', year: 1979 },
  { title: 'Alien', year: 1979 },
  { title: 'Sunset Boulevard', year: 1950 },
  {
    title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
    year: 1964,
  },
  { title: 'The Great Dictator', year: 1940 },
  { title: 'Cinema Paradiso', year: 1988 },
  { title: 'The Lives of Others', year: 2006 },
  { title: 'Grave of the Fireflies', year: 1988 },
  { title: 'Paths of Glory', year: 1957 },
  { title: 'Django Unchained', year: 2012 },
  { title: 'The Shining', year: 1980 },
  { title: 'WALL·E', year: 2008 },
  { title: 'American Beauty', year: 1999 },
  { title: 'The Dark Knight Rises', year: 2012 },
  { title: 'Princess Mononoke', year: 1997 },
  { title: 'Aliens', year: 1986 },
  { title: 'Oldboy', year: 2003 },
  { title: 'Once Upon a Time in America', year: 1984 },
  { title: 'Witness for the Prosecution', year: 1957 },
  { title: 'Das Boot', year: 1981 },
  { title: 'Citizen Kane', year: 1941 },
  { title: 'North by Northwest', year: 1959 },
  { title: 'Vertigo', year: 1958 },
  { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
  { title: 'Reservoir Dogs', year: 1992 },
  { title: 'Braveheart', year: 1995 },
  { title: 'M', year: 1931 },
  { title: 'Requiem for a Dream', year: 2000 },
  { title: 'Amélie', year: 2001 },
  { title: 'A Clockwork Orange', year: 1971 },
  { title: 'Like Stars on Earth', year: 2007 },
  { title: 'Taxi Driver', year: 1976 },
  { title: 'Lawrence of Arabia', year: 1962 },
  { title: 'Double Indemnity', year: 1944 },
  { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
  { title: 'Amadeus', year: 1984 },
  { title: 'To Kill a Mockingbird', year: 1962 },
  { title: 'Toy Story 3', year: 2010 },
  { title: 'Logan', year: 2017 },
  { title: 'Full Metal Jacket', year: 1987 },
  { title: 'Dangal', year: 2016 },
  { title: 'The Sting', year: 1973 },
  { title: '2001: A Space Odyssey', year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: 'Toy Story', year: 1995 },
  { title: 'Bicycle Thieves', year: 1948 },
  { title: 'The Kid', year: 1921 },
  { title: 'Inglourious Basterds', year: 2009 },
  { title: 'Snatch', year: 2000 },
  { title: '3 Idiots', year: 2009 },
  { title: 'Monty Python and the Holy Grail', year: 1975 },
];

export const GST_NUMBER_REGEX =
  /^([0-9]{2})([A-Z]{5})([0-9]{4})([A-Z]{1})([1-9A-Z]{1})Z([0-9A-Z]{1})$/;
export const PAN_CODE_REGEX = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

export const MODULE_STATUS = [
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'Deactive' },
];

export const ADVANCE = [
  { value: '1', label: 'Account' },
  { value: '2', label: 'Auto Mobile' },
  { value: '3', label: 'Jewels' },
];

export const PURCHASE_TYPE = [
  { value: 1, label: 'State', name: 'State' },
  { value: 2, label: 'Out of State', name: 'Out of State' },
];

export const SALES_TYPE = [
  { value: 1, label: 'State', name: 'State' },
  { value: 2, label: 'Out of State', name: 'Out of State' },
  { value: 3, label: 'None', name: 'None' },
];

export const ADD_LESS = [
  { value: '+', label: 'add', name: 'Add (+)' },
  { value: '-', label: 'less', name: 'Less (-)' },
];

export const GENDER = [
  { value: 1, label: 'Male', name: 'Male' },
  { value: 2, label: 'Female', name: 'Female' },
];
export const ACTIVITYSTATUS = [
  { value: 2, label: 'Closed', name: 'Closed' },
  { value: 3, label: 'Done', name: 'Done' },
];

export const DSA_SELF = [
  { value: 1, label: 'DSA', name: 'DSA' },
  { value: 2, label: 'Self', name: 'SELF' },
];

export const HPATYPE = [
  { value: 1, label: 'Hypothecation', name: 'Hypothecation' },
  { value: 2, label: 'No Hypothecation', name: 'No Hypothecation' },
];

export const INOUTHOUSE = [
  { value: 1, label: 'In House', name: 'In House' },
  { value: 2, label: 'Out House', name: 'Out House' },
];

export const KMSDRIVEN = [
  { value: 1, label: 'Less than 20,000', name: 'Less than 20,000' },
  { value: 2, label: 'More than 50,000', name: 'More than 50,000' },
  { value: 3, label: '20,000 - 50,000', name: '20,000 - 50,000' },
];
export const STATUS = [
  { value: 'scheduled', label: 'Scheduled' },
  { value: 'closed', label: 'Closed' },
  { value: 'done', label: 'Done' },
];

export const REASON_FOR_CLOSURE = [
  { value: '1', label: 'Bought HMSI Product on Othr Nm' },
  { value: '2', label: 'Finance_problem' },
];

export const CLOSURE_REMARKS = [{ value: '1', label: 'Bought HMSI Product on Othr Nm' }];

export const ACTIVITY_TYPE = [
  { value: 'follow_up', label: 'Follow-Up' },
  { value: 'test_ride', label: 'Test Ride' },
];

export const CUSTOMERCATEGORY = [
  { value: '1', label: 'Select Customer Category' },
  { value: '2', label: 'Individual' },
  { value: '3', label: 'CPC' },
  { value: '4', label: 'CSD' },
  { value: '5', label: 'Corporate' },
];

export const ENQUIRYCATEGORY = [
  { value: 'individual', label: 'Individual' },
  { value: 'institutional_customer', label: 'Institutional Customer' },
];

export const ENQUIRYTYPE = [
  { value: '1', label: 'Select Enquiry Type' },
  { value: '2', label: 'Internet/E-mail' },
  { value: '3', label: 'Walk-In' },
  { value: '4', label: 'Phone' },
  { value: '5', label: 'Outdoor Activity' },
];

export const ENQUIRYSOURCE = [
  { value: 'online_booking', label: 'Online Booking' },
  { value: 'tv', label: 'TV' },
  { value: '4', label: 'FM Campaign' },
  { value: '5', label: 'Display Activity' },
  { value: '6', label: 'Website' },
  { value: '7', label: 'Cold Calling' },
  { value: '8', label: 'Door 2 Door Activity' },
  { value: '9', label: 'Digital Campaign' },
  { value: '10', label: 'Auto Hood Promotion' },
  { value: '11', label: 'Demo Van' },
  { value: '12', label: 'Inst. Activity' },
  { value: '13', label: 'Canopy' },
  { value: '14', label: 'Bus Back Panel Promo' },
  { value: '15', label: 'Newspaper' },
  { value: '16', label: 'Mall Activity' },
  { value: '17', label: 'Bus Shelter Branding' },
  { value: '18', label: 'Leaflet' },
  { value: '19', label: 'Facebook' },
  { value: '20', label: 'Radio' },
  { value: '21', label: 'Tag' },
  { value: '22', label: 'Roadshow' },
  { value: '23', label: 'Hoarding' },
  { value: '24', label: 'Workshop' },
  { value: '25', label: 'Safety Activity' },
  { value: '26', label: 'Friend' },
  { value: '27', label: 'Mela' },
  { value: '28', label: 'Banner' },
  { value: '29', label: 'Relative' },
  { value: '30', label: 'Wall Wrap' },
  { value: '31', label: 'End Of Life Vehicle' },
];
export const PURCHASETYPE = [
  { value: 'cash', label: 'Cash' },
  { value: 'finance', label: 'Finance' },
];
export const EXCHANGEFLAG = [
  { value: '1', label: 'Select Exchange Flag' },
  { value: '2', label: 'Y' },
  { value: '3', label: 'N' },
];
export const ASSIGNEDTO = [
  { value: 'statish_virwaal', label: 'SATISH VIRWAAL' },
  { value: '2', label: 'VISHAL RAJPUT' },
  { value: '3', label: 'PARESH SONI' },
  { value: '4', label: 'SATISH VIRVAL' },
  { value: '5', label: 'NEEL PATEL' },
  { value: '6', label: 'NIRALI MALAVIYA' },
  { value: '7', label: 'RUSHIKA KANANI' },
  { value: '8', label: 'KEYUR LAKHANI' },
  { value: '9', label: 'KOMAL CHAUHAN' },
  { value: '10', label: 'JENISH SURATI' },
  { value: '11', label: 'MONIKA BHADANI' },
  { value: '12', label: 'POONAM VORA' },
  { value: '13', label: 'PRITI DHOLARIYA' },
  { value: '14', label: 'SUCHI PATEL' },
  { value: '15', label: 'ASHA PETHANI' },
  { value: '16', label: 'HIRAL BODAR' },
  { value: '17', label: 'SWATI BODAR' },
  { value: '18', label: 'DIVYA MAVANI' },
  { value: '19', label: 'HIMANSHU PATEL' },
  { value: '20', label: 'MEERA GAJERA' },
  { value: '21', label: 'FENIL RAJPUT' },
  { value: '22', label: 'KISHAN SAVALIYA' },
  { value: '23', label: 'VAISHALI SIDDHAPARA' },
  { value: '24', label: 'MOHAMOD GAUS' },
  { value: '25', label: 'TARAK SHAH' },
];
export const MODELCATEGORY = [
  { value: 'mc', label: 'MC' },
  { value: 'sc', label: 'SC' },
  { value: 'pm', label: 'PM' },
];

export const MODELNAME = [
  { value: 'shine', label: 'SHINE' },
  { value: 'livo', label: 'LIVO' },
  { value: '4', label: 'UNICORN' },
  { value: '5', label: 'SP125' },
  { value: '6', label: 'HORNET 2.0' },
  { value: '7', label: 'CD 110 DREAM' },
  { value: '8', label: 'X BLADE' },
  { value: '9', label: 'SHINE 125' },
  { value: '10', label: 'CB200X' },
  { value: '11', label: 'SHINE 100' },
  { value: '12', label: 'SP125 OBD2' },
  { value: '13', label: 'UNICORN OBD2' },
  { value: '14', label: 'SHINE 125 OBD2' },
  { value: '15', label: 'SP160 OBD2' },
  { value: '16', label: 'CD110 DREAM OB....' },
  { value: '17', label: 'LIVO OBD2' },
  { value: '18', label: 'HORNET 2.0 ABS O...' },
  { value: '19', label: 'CB200X OBD2' },
  { value: '20', label: 'ACTIVA 6G' },
  { value: '21', label: 'ACTIVA 125' },
  { value: '22', label: 'DIO' },
  { value: '23', label: 'GRAZIA' },
  { value: '24', label: 'ACTIVA' },
  { value: '25', label: 'ACTIVA 125 OBD2' },
  { value: '26', label: 'DIO OBD2' },
  { value: '27', label: 'DIO 125 OBD2' },
  { value: '28', label: 'CBR1000RR-R SP' },
  { value: '29', label: 'AFRICA TWIN' },
  { value: '30', label: 'CBR1000RR-R' },
  { value: '31', label: 'HNESS CB350' },
  { value: '32', label: 'CB350 RS' },
  { value: '33', label: 'CB500X' },
  { value: '34', label: 'CB650R' },
  { value: '35', label: 'CBR650R' },
  { value: '36', label: 'GL1800DA' },
  { value: '37', label: 'CB300R' },
  { value: '38', label: 'CB300F' },
  { value: '39', label: 'CB500F' },
  { value: '40', label: 'CB300F OBD2' },
  { value: '41', label: 'CB300R OBD2' },
];
export const MODELVARIANT = [{ value: '1', label: 'text' }];
export const MTOCPRODUCT = [{ value: '1', label: 'text' }];
export const MODEL_VARIANT = [
  { value: '1', label: 'SP125 DRUM CBS-BSVI' },
  { value: '2', label: 'SP125 DISC CBS-BSVI' },
  { value: '3', label: 'SP125 DLX-BSVI' },
];
export const MTOC_PRODUCT = [
  { value: '1', label: 'CBF125ML8IDNH303' },
  { value: '2', label: 'CBF125ML4IDR355' },
  { value: '3', label: 'CBF125ML4IDNH1Y' },
  { value: '4', label: 'CBF125ML4IDPB123' },
];

export const VEHICLEMAKE = [
  { value: '1', label: 'Vehicle make 1' },
  { value: '2', label: 'Vehicle make 2' },
];
export const VEHICLE_MAKE = [
  { value: '1', label: 'Aprillia' },
  { value: '2', label: 'Bajaj' },
  { value: '3', label: 'Hero' },
  { value: '4', label: 'Honda' },
  { value: '5', label: 'Royal Enfield' },
  { value: '6', label: 'Suzuki' },
  { value: '7', label: 'TVS' },
  { value: '8', label: 'Yamaha' },
  { value: '9', label: 'Revolt' },
  { value: '10', label: 'Ola' },
];

export const VEHICLEMODEL = [
  { value: '1', label: 'Vehicle model 1' },
  { value: '2', label: 'Vehicle model 2' },
];
export const VEHICLE_MODEL = [
  { value: '1', label: 'CB Shine' },
  { value: '2', label: 'CB Trigger' },
  { value: '3', label: 'CB Twister' },
  { value: '4', label: 'CB Unicorn' },
  { value: '5', label: 'CB Unicorn 160' },
  { value: '6', label: 'CBF Stunner' },
  { value: '7', label: 'CBR 150R' },
  { value: '8', label: 'Dream Neo' },
  { value: '9', label: 'Dream Yuga' },
  { value: '10', label: 'Livo' },
];

export const BALANCE_TYPE = [
  { id: 1, name: 'Credit' },
  { id: 2, name: 'Debit' },
];

export const ACCOUNT_TYPES = [
  { id: 1, name: 'Saving' },
  { id: 2, name: 'Current' },
];

export const DEPARTMENT = [
  { value: 'accounts', label: 'Accounts' },
  { value: 'seals', label: 'Seals' },
];

export const BANK_CASH = [
  { value: 'axisbankltd', label: 'Axis Bank Ltd.' },
  { value: 'hdfcbank', label: 'HDFC Bank' },
];
export const COUNTER_NONCOUNTER = [
  { value: 'countersale', label: 'Counter Sale' },
  { value: 'noncountersale', label: 'Non-Counter Sale' },
];

export const RCPT_PYMT = [
  { value: 'payment1', label: 'payment 1' },
  { value: 'payment2', label: 'payment 2' },
  { value: 'payment3', label: 'payment 3' },
];

export const OPP_AC = [
  { value: 'opp1', label: 'opp 1' },
  { value: 'opp2', label: 'opp 2' },
  { value: 'opp3', label: 'opp 3' },
];

export const STOCK_REQUIRED = [
  { id: 1, name: 'Yes' },
  { id: 2, name: 'No' },
];
export const QUANTITY_EFFECT = TRUE_FALSE_DROPDOWN;
export const GST_COMMODITY = [
  { value: '1', label: 'GST 5%' },
  { value: '2', label: 'GST 12%' },
  { value: '3', label: 'GST 18%' },
  { value: '4', label: 'GST 28%' },
  { value: '5', label: 'GST 3%' },
  { value: '6', label: 'GST Nil Rated' },
  { value: '7', label: 'Non GST' },
  { value: '8', label: 'TRANSPORT' },
];
export const GROUP_NAME = [
  { value: '1', label: 'Air Conditioner' },
  { value: '2', label: 'Air Cooler' },
  { value: '3', label: 'Capital Goods' },
  { value: '4', label: 'Color T.V.' },
  { value: '5', label: 'D.V.D. Player' },
  { value: '6', label: 'D.V.D. Combo' },
  { value: '7', label: 'General' },
  { value: '8', label: 'Home Theatre' },
  { value: '9', label: 'Mobile' },
  { value: '10', label: 'Refrigerator' },
  { value: '11', label: 'Washing Machine' },
  { value: '12', label: 'Water Purifier' },
];
export const CATEGORY_NAME = [
  { value: '1', label: 'BPL' },
  { value: '2', label: 'Capital Goods' },
  { value: '3', label: 'General' },
  { value: '4', label: 'Godrej' },
];
export const SALE = [
  { value: '1', label: 'No.' },
  { value: '2', label: 'Set' },
];
export const PURCHASE = [
  { value: '1', label: 'No.' },
  { value: '2', label: 'Set' },
];
export const GST_UNIT = [
  { value: '1', label: 'BAG-BAGS' },
  { value: '2', label: 'BAL-BALE' },
  { value: '3', label: 'BDL-BUNDLES' },
  { value: '4', label: 'BKL-BUCKLES' },
  { value: '5', label: 'BOU-BILLION OF UNITS' },
];
export const PROCESS_NAME = [{ id: 1, name: 'INTEX COMBO' }];

export const C_D_GST_TYPE = [
  { value: 'credit', label: 'Credit' },
  { value: 'debit', label: 'Debit' },
];

export const TRANSPORT = [
  { id: 1, name: 'Yes' },
  { id: 2, name: 'No' },
];

export const MODE = [
  { id: 1, name: 'View' },
  { id: 2, name: 'Edit' },
];

export const ENTRYTYPE = [
  { value: '1,5,7', name: 'Payment' },
  { value: '2,6,7', name: 'Receipt' },
  { value: '1,2,5,6,7', name: 'Both' },
];

export const BALANCE_METHOD = [
  { id: 1, name: 'Normal' },
  { id: 2, name: 'Bill to Bill' },
];
export const Tax_BilOfSupply = [
  { value: '1', label: 'Tax bil supply  1' },
  { value: '2', label: 'Tax bil supply 2' },
];
export const Tax_BilSupply = [
  { id: 1, name: 'Tax Invoice' },
  { id: 2, name: 'Bill of Supply' },
  { id: 3, name: 'Non GST / Composition' },
  { id: 4, name: 'RCM' },
  { id: 5, name: 'Other' },
];
export const GST_TYPE = [
  { id: 1, name: 'GST' },
  { id: 2, name: 'Nil Rated' },
  { id: 3, name: 'Non GST' },
  { id: 4, name: 'Other' },
];
export const COMMODITY_TYPE = [
  { id: 1, name: 'Goods' },
  { id: 2, name: 'Services' },
];
export const REVERSE_CHARGE_APPLICABLE = [
  { id: 1, name: 'Yes' },
  { id: 2, name: 'No' },
];
export const UTILIZATION_TYPE = [{ value: '1', label: 'Normal' }];

export const PERIOD = [
  { value: '1', label: 'MARCH - 2020' },
  { value: '2', label: 'FEBRUARY - 2020' },
  { value: '3', label: 'JANUARY - 2020' },
  { value: '4', label: 'DECEMBER - 2019' },
];

export const GST_BANK_CASH = [{ value: 'cash_account', label: 'Cash Account' }];

export const Effect = [
  { value: '1', label: 'effect 1' },
  { value: '2', label: 'effect 2' },
];

export const CASH_DEBIT = [
  { id: 1, name: 'Cash' },
  { id: 2, name: 'Debit' },
  { id: 3, name: 'Credit Card' },
];

export const VOUCHERTYPE = [
  { value: 1, label: 'Bank Payment' },
  { value: 2, label: 'Bank Receipt' },
  { value: 3, label: 'Cash Payment' },
  { value: 4, label: 'Cash Receipt' },
  { value: 5, label: 'Contra Payment' },
  { value: 6, label: 'Contra Receipt' },
  { value: 7, label: 'Journal' },
  { value: 8, label: 'Purchase' },
  { value: 9, label: 'Sale' },
];

export const RECEIPTTYPE = [
  // { value: 1, label: 'Bank Payment', order: 4 },
  { value: 2, label: 'Bank Receipt', order: 3 },
  // { value: 3, label: 'Cash Payment', order: 2 },
  { value: 4, label: 'Cash Receipt', order: 1 },
];

export const BANK_R_P = [
  { value: 1, label: 'Payment' },
  { value: 2, label: 'Receipt' },
];

export const OPTION = [
  { value: 'yes', label: 'YES' },
  { value: 'no', label: 'NO' },
];

export const PERIOD_OF_UTILIZATION = [
  { value: '1', label: 'OCTOBER - 2019' },
  { value: '2', label: 'NOVEMBER - 2019' },
  { value: '3', label: 'DECEMBER - 2019' },
  { value: '4', label: 'JANUARY - 2020' },
];
export const Journal_TYPE = [
  { value: '1', label: 'Opening' },
  { value: '2', label: 'ITC Increase' },
  { value: '3', label: 'ITC Decrease' },
  { value: '4', label: 'Tax Liability Increase' },
  { value: '5', label: 'Cash Ledger Credit' },
  { value: '5', label: 'Import RCM' },
];
export const Journal_SUBTYPE = [
  { value: '1', label: 'Input Tax Credit' },
  { value: '2', label: 'Cash Ledger' },
  { value: '3', label: 'Tax Liability' },
];
export const RCM_GST_TYPE = [
  { value: '1', label: 'Local State' },
  { value: '2', label: 'Out State' },
];
export const ITC = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];
export const Opp_Account = [
  { value: '1', label: 'Addi. VAT (I/P) 1% (For 4%)' },
  { value: '2', label: 'Addi. VAT (I/P) 2.5% (For 12.5%)' },
  { value: '3', label: 'Addi. VAT (O/P) 1% (For 4%)' },
];

export const OCCUPATION = [
  { value: '1', label: 'Business 1' },
  { value: '2', label: 'Business 2' },
];

export const MODE_OF_SALES = [
  { value: '1', label: 'Business 1' },
  { value: '2', label: 'Business 2' },
];
export const EXCHANGE_AGENT_NAME = [
  { value: '1', label: 'Exchange 1' },
  { value: '2', label: 'Exchange 2' },
];
export const EXPENSE_GST_TYPE = [
  { value: '1', label: 'GST' },
  { value: '2', label: 'IGST' },
  { value: '3', label: 'GST (Inter State)' },
  { value: '4', label: 'IGST (Inter State)' },
  { value: '5', label: 'GST (Cap. Goods)' },
  { value: '6', label: 'IGST (Cap. Goods)' },
];
export const ROUND_OFF = [{ value: '1', label: 'Kasar A/c.' }];

export const INVOI_TYPE = [
  { id: 8, name: 'PURCHASE' },
  { id: 9, name: 'SALE' },
];
export const INVOICE_TYPE = [
  { id: 1, name: 'GST' },
  { id: 2, name: 'IGST' },
];
export const FIN_YEAR = [
  { value: '1', label: '2013-14' },
  { value: '2', label: '2014-15' },
  { value: '3', label: '2015-16' },
  { value: '3', label: '2016-17' },
];
export const JOB_CARD_TYPE = [
  { value: '1', label: 'REGULAR' },
  { value: '2', label: 'AMROLI' },
  { value: '3', label: 'PDI' },
  { value: '3', label: 'HIRISE' },
];
export const JOB_CARD_FORMAT = [
  { value: '1', label: 'Job card format 1' },
  { value: '2', label: 'Job card format 2' },
  { value: '3', label: 'Job card format 3' },
];
export const BILL_TYPE_GRT = [
  { value: '1', label: 'Bill Type 1' },
  { value: '2', label: 'Bill Type 1' },
  { value: '3', label: 'Bill Type 3' },
];
export const CASH_CREDIT = [
  { value: '1', label: 'cash/credit 1' },
  { value: '2', label: 'cash/credit 2' },
  { value: '3', label: 'cash/credit 3' },
];
export const INV_NO_INV_DATE = [
  { value: '1', label: 'Inv no / Inv date 1' },
  { value: '2', label: 'Inv no / Inv date 2' },
  { value: '3', label: 'Inv no / Inv date 3' },
];
export const TYPE_OF_PRINTING = [
  { value: '1', label: 'Type of printing 1' },
  { value: '2', label: 'Type of printing 2' },
  { value: '3', label: 'Type of printing 3' },
];
export const TYPE_OF_CHALLAN = [
  { value: '1', label: 'Type of challan 1' },
  { value: '2', label: 'Type of challan 2' },
  { value: '3', label: 'Type of challan 3' },
];
export const WITH_NDP = [
  { value: '1', label: 'YES' },
  { value: '2', label: 'NO' },
];
export const BT_TYPE = [
  { value: '1', label: 'BT Type 1' },
  { value: '2', label: 'BT Type 2' },
];
export const DC = [
  { value: '1', label: 'DC 1' },
  { value: '2', label: 'DC 2' },
];
export const FORMAT = [
  { value: '1', label: 'Format 1' },
  { value: '2', label: 'Format 2' },
];
export const RETAIL_TAX_GST = [
  { value: '1', label: 'Retail' },
  { value: '2', label: 'Tax' },
  { value: '3', label: 'GST' },
];

export const enquiryData = [
  {
    id: 1,
    name: 'Kishan Jayantibhai Dobariya',
    product_name: 'Active',
    product_title: 'ACTIVA STD-OB...',
    verify: 'Not Verified',
    mobie: '9724974080',
    date: '10/08/2023',
    status: 'Test Ride',
    enquiry_owner: 'Harshad Miroliya',
    product_number: 'VEHENQ-GJ50005-1-2324-17157',
  },
  {
    id: 2,
    name: 'Kishan Jayantibhai Dobariya',
    product_name: 'Active',
    product_title: 'ACTIVA STD-OB...',
    verify: 'Not Verified',
    mobie: '9724974080',
    date: '10/08/2023',
    status: 'Test Ride',
    enquiry_owner: 'Harshad Miroliya',
    product_number: 'VEHENQ-GJ50005-1-2324-17157',
  },
  {
    id: 3,
    name: 'Kishan Jayantibhai Dobariya',
    product_name: 'Active',
    product_title: 'ACTIVA STD-OB...',
    verify: 'Not Verified',
    mobie: '9724974080',
    date: '10/08/2023',
    status: 'Test Ride',
    enquiry_owner: 'Harshad Miroliya',
    product_number: 'VEHENQ-GJ50005-1-2324-17157',
  },
  {
    id: 4,
    name: 'Kishan Jayantibhai Dobariya',
    product_name: 'Active',
    product_title: 'ACTIVA STD-OB...',
    verify: 'Not Verified',
    mobie: '9724974080',
    date: '10/08/2023',
    status: 'Test Ride',
    enquiry_owner: 'Harshad Miroliya',
    product_number: 'VEHENQ-GJ50005-1-2324-17157',
  },
  {
    id: 5,
    name: 'Kishan Jayantibhai Dobariya',
    product_name: 'Active',
    product_title: 'ACTIVA STD-OB...',
    verify: 'Not Verified',
    mobie: '9724974080',
    date: '10/08/2023',
    status: 'Test Ride',
    enquiry_owner: 'Harshad Miroliya',
    product_number: 'VEHENQ-GJ50005-1-2324-17157',
  },
  {
    id: 6,
    name: 'Kishan Jayantibhai Dobariya',
    product_name: 'Active',
    product_title: 'ACTIVA STD-OB...',
    verify: 'Not Verified',
    mobie: '9724974080',
    date: '10/08/2023',
    status: 'Test Ride',
    enquiry_owner: 'Harshad Miroliya',
    product_number: 'VEHENQ-GJ50005-1-2324-17157',
  },
  {
    id: 7,
    name: 'Kishan Jayantibhai Dobariya',
    product_name: 'Active',
    product_title: 'ACTIVA STD-OB...',
    verify: 'Not Verified',
    mobie: '9724974080',
    date: '10/08/2023',
    status: 'Test Ride',
    enquiry_owner: 'Harshad Miroliya',
    product_number: 'VEHENQ-GJ50005-1-2324-17157',
  },
  {
    id: 8,
    name: 'Kishan Jayantibhai Dobariya',
    product_name: 'Active',
    product_title: 'ACTIVA STD-OB...',
    verify: 'Not Verified',
    mobie: '9724974080',
    date: '10/08/2023',
    status: 'Test Ride',
    enquiry_owner: 'Harshad Miroliya',
    product_number: 'VEHENQ-GJ50005-1-2324-17157',
  },
  {
    id: 9,
    name: 'Kishan Jayantibhai Dobariya',
    product_name: 'Active',
    product_title: 'ACTIVA STD-OB...',
    verify: 'Not Verified',
    mobie: '9724974080',
    date: '10/08/2023',
    status: 'Test Ride',
    enquiry_owner: 'Harshad Miroliya',
    product_number: 'VEHENQ-GJ50005-1-2324-17157',
  },
  {
    id: 10,
    name: 'Kishan Jayantibhai Dobariya',
    product_name: 'Active',
    product_title: 'ACTIVA STD-OB...',
    verify: 'Not Verified',
    mobie: '9724974080',
    date: '10/08/2023',
    status: 'Test Ride',
    enquiry_owner: 'Harshad Miroliya',
    product_number: 'VEHENQ-GJ50005-1-2324-17157',
  },
];

export const NONE_ECTRICE_ELEC = [
  { value: '1', label: '001' },
  { value: '2', label: '002' },
];
export const TYPE_OF_BODY = [
  { value: '1', label: 'type of body 1' },
  { value: '2', label: 'type of body 2' },
];
export const TYPE_OF_ITEM = [
  { value: '1', label: 'type of item 1' },
  { value: '2', label: 'type of item 2' },
];
export const STOCK_TYPE = [
  { value: 'FAST', label: 'FAST' },
  { value: 'SLOW', label: 'SLOW' },
  { value: 'DEAD', label: 'DEAD' },
];
export const OPTIONS_VEHICLE_MASTER = [
  { value: '1', label: 'Optipons vehicle master 1' },
  { value: '1', label: 'Optipons vehicle master 2' },
];
export const BHARAT_STAGE = [
  { value: '1', label: 'Bharat stage 1' },
  { value: '1', label: 'Bharat Stage 2' },
];

export const TYPEOFPRINTING = [
  { value: '1', label: 'TYPEOFPRINTING 1' },
  { value: '2', label: 'TYPEOFPRINTING 2' },
  { value: '3', label: 'TYPEOFPRINTING 3' },
  { value: '4', label: 'TYPEOFPRINTING 4' },
];
export const RATETYPE = [
  { value: '1', label: 'RATETYPE 1' },
  { value: '2', label: 'RATETYPE 2' },
  { value: '3', label: 'RATETYPE 3' },
  { value: '4', label: 'RATETYPE 4' },
];
export const TYPEOFITEM = [
  { value: '1', label: 'TYPEOFITEM 1' },
  { value: '2', label: 'TYPEOFITEM 2' },
  { value: '3', label: 'TYPEOFITEM 3' },
  { value: '4', label: 'TYPEOFITEM 4' },
];
export const BRANCH = [
  { value: '1', label: 'BRANCH 1' },
  { value: '2', label: 'BRANCH 2' },
  { value: '3', label: 'BRANCH 3' },
  { value: '4', label: 'BRANCH 4' },
];
export const PERIODITICALTRANSACTIONSONLY = [
  { value: '1', label: 'PERIODITICALTARNSACTIONSONLY 1' },
  { value: '2', label: 'PERIODITICALTARNSACTIONSONLY 2' },
  { value: '3', label: 'PERIODITICALTARNSACTIONSONLY 3' },
  { value: '4', label: 'PERIODITICALTARNSACTIONSONLY 4' },
];
export const REPORTFORMATE = [
  { value: '1', label: 'REPORTFORMATE 1' },
  { value: '2', label: 'REPORTFORMATE 2' },
  { value: '3', label: 'REPORTFORMATE 3' },
  { value: '4', label: 'REPORTFORMATE 4' },
];
export const EACHGROUPONNEWPAGEINTING = [
  { value: '1', label: 'EACHGROUPONNEWPAGEINTING 1' },
  { value: '2', label: 'EACHGROUPONNEWPAGEINTING 2' },
  { value: '3', label: 'EACHGROUPONNEWPAGEINTING 3' },
  { value: '4', label: 'EACHGROUPONNEWPAGEINTING 4' },
];
export const HIDEEOUTQTYRAW = [
  { value: '1', label: 'HIDEEOUTQTYRAW 1' },
  { value: '2', label: 'HIDEEOUTQTYRAW 2' },
  { value: '3', label: 'HIDEEOUTQTYRAW 3' },
  { value: '4', label: 'HIDEEOUTQTYRAW 4' },
];
export const INCLUDEFOCWARRAMT = [
  { value: '1', label: 'INCLUDEFOCWARRAMT 1' },
  { value: '2', label: 'INCLUDEFOCWARRAMT 2' },
  { value: '3', label: 'INCLUDEFOCWARRAMT 3' },
  { value: '4', label: 'INCLUDEFOCWARRAMT 4' },
];
export const INCLUDEBTONLINESTKADJ = [
  { value: '1', label: 'INCLUDEFOCWARRAMT 1' },
  { value: '2', label: 'INCLUDEFOCWARRAMT 2' },
  { value: '3', label: 'INCLUDEFOCWARRAMT 3' },
  { value: '4', label: 'INCLUDEFOCWARRAMT 4' },
];
export const ALLBRANCHCURRENTBRANCE = [
  { value: '1', label: 'ALLBRANCHCURRENTBRANCE 1' },
  { value: '2', label: 'ALLBRANCHCURRENTBRANCE 2' },
  { value: '3', label: 'ALLBRANCHCURRENTBRANCE 3' },
  { value: '4', label: 'ALLBRANCHCURRENTBRANCE 4' },
];
export const INWARDOUTWARAD = [
  { value: '1', label: 'INWARDOUTWARAD 1' },
  { value: '2', label: 'INWARDOUTWARAD 2' },
  { value: '3', label: 'INWARDOUTWARAD 3' },
  { value: '4', label: 'INWARDOUTWARAD 4' },
];
export const JOBTEAYNO = [
  { value: '1', label: 'JOBTEAYNO 1' },
  { value: '2', label: 'JOBTEAYNO 2' },
  { value: '3', label: 'JOBTEAYNO 3' },
  { value: '4', label: 'JOBTEAYNO 4' },
];
export const JOBCARDNO = [
  { value: '1', label: 'JOBCARDNO 1' },
  { value: '2', label: 'JOBCARDNO 2' },
  { value: '3', label: 'JOBCARDNO 3' },
  { value: '4', label: 'JOBCARDNO 4' },
];
export const INSIDEWS = [
  { value: '1', label: 'INSIDEWS 1' },
  { value: '2', label: 'INSIDEWS 2' },
  { value: '3', label: 'INSIDEWS 3' },
  { value: '4', label: 'INSIDEWS 4' },
];
export const INOUT = [
  { value: '1', label: 'IN/OUT 1' },
  { value: '2', label: 'IN/OUT 2' },
  { value: '3', label: 'IN/OUT 3' },
  { value: '4', label: 'IN/OUT 4' },
];
export const DOWNTIMEYN = [
  { value: '1', label: 'DOWNTIMEYN 1' },
  { value: '2', label: 'DOWNTIMEYN 2' },
  { value: '3', label: 'DOWNTIMEYN 3' },
  { value: '4', label: 'DOWNTIMEYN 4' },
];
export const JOBSTATUS = [
  { value: '1', label: 'JOBSTATUS 1' },
  { value: '2', label: 'JOBSTATUS 2' },
  { value: '3', label: 'JOBSTATUS 3' },
  { value: '4', label: 'JOBSTATUS 4' },
];
export const SKILL_LEVEL = [
  { value: '1', label: 'Skill Level 1' },
  { value: '2', label: 'Skill Level 2' },
  { value: '3', label: 'Skill Level 3' },
];
export const LABOUR_TYPE = [
  { value: '1', label: 'Labour Type 1' },
  { value: '2', label: 'Labour Type 2' },
  { value: '3', label: 'Labour Type 3' },
];
export const PAYMENT_BY = [
  { value: '1', label: 'Payment By 1' },
  { value: '2', label: 'Payment By 2' },
  { value: '3', label: 'Payment By 3' },
];
export const SELLING_DEALER = [
  { id: '1', name: 'SELLING_DEALER 1' },
  { id: '2', name: 'SELLING_DEALER 2' },
  { id: '3', name: 'SELLING_DEALER 3' },
];
export const VEHICLE_STATUS = [
  { id: '1', name: 'Vehicle Status 1' },
  { id: '2', name: 'Vehicle Status 2' },
  { id: '3', name: 'Vehicle Status 3' },
];
export const PHYSICALSTATUS = [
  { id: '1', name: 'Physical Status 1' },
  { id: '2', name: 'Physical Status 2' },
  { id: '3', name: 'Physical Status 3' },
];
export const ACCOUNT = [
  { id: '1', name: 'Account 1' },
  { id: '2', name: 'Account 2' },
  { id: '3', name: 'Account 3' },
];
export const HSRPPLATESTATUS = [
  { id: '1', name: 'HSRP Plate Status 1' },
  { id: '2', name: 'HSRP Plate Status 2' },
  { id: '3', name: 'HSRP Plate Status 3' },
];
export const SOURCE = [
  { value: '1', label: 'SOURCE 1' },
  { value: '2', label: 'SOURCE 2' },
  { value: '3', label: 'SOURCE 3' },
];
export const SERVICETYPE = [
  { value: '1', label: 'Service Type 1' },
  { value: '2', label: 'Service Type 2' },
  { value: '3', label: 'Service Type 3' },
];
export const OPERATION = [
  { value: '1', label: 'OPERATION 1' },
  { value: '2', label: 'OPERATION 2' },
  { value: '3', label: 'OPERATION 3' },
];
export const LABOURRATELIST = [
  { value: '1', label: 'LABOURRATELIST 1' },
  { value: '2', label: 'LABOURRATELIST 2' },
  { value: '3', label: 'LABOURRATELIST 3' },
];
export const REPEATCOMPLAINTREASON = [
  { value: '1', label: 'REPEATCOMPLAINTREASON 1' },
  { value: '2', label: 'REPEATCOMPLAINTREASON 2' },
  { value: '3', label: 'REPEATCOMPLAINTREASON 3' },
];
export const TYPEOFCONTACT = [
  { value: '1', label: 'TYPEOFCONTACT 1' },
  { value: '2', label: 'TYPEOFCONTACT 2' },
  { value: '3', label: 'TYPEOFCONTACT 3' },
];
export const AMCSERVICE = [
  { value: '1', label: 'AMCSERVICE 1' },
  { value: '2', label: 'AMCSERVICE 2' },
  { value: '3', label: 'AMCSERVICE 3' },
];
export const FUELLEVEL = [
  { value: '1', label: 'FUELLEVEL 1' },
  { value: '2', label: 'FUELLEVEL 2' },
  { value: '3', label: 'FUELLEVEL 3' },
];
export const PERMIUMAMCTYPE = [
  { value: '1', label: 'PERMIUMAMCTYPE 1' },
  { value: '2', label: 'PERMIUMAMCTYPE 2' },
  { value: '3', label: 'PERMIUMAMCTYPE 3' },
];
export const PERIODICJOB = [
  { value: '1', label: 'PERIODICJOB 1' },
  { value: '2', label: 'PERIODICJOB 2' },
  { value: '3', label: 'PERIODICJOB 3' },
];
export const BILLINGTYPE = [
  { value: '1', label: 'BILLINGTYPE 1' },
  { value: '2', label: 'BILLINGTYPE 2' },
  { value: '3', label: 'BILLINGTYPE 3' },
];
export const BILLINGCATEGORY = [
  { value: '1', label: 'BILLINGCATEGORY 1' },
  { value: '2', label: 'BILLINGCATEGORY 2' },
  { value: '3', label: 'BILLINGCATEGORY 3' },
];
export const RATELLST = [
  { value: '1', label: 'RATELLST 1' },
  { value: '2', label: 'RATELLST 2' },
  { value: '3', label: 'RATELLST 3' },
];

export const JOB_CARD_STATUS = [
  { id: '1', name: 'Cancelled' },
  { id: '2', name: 'Completed' },
  { id: '3', name: 'Closed' },
  { id: '4', name: 'Pending' },
];
export const CANCELLATION_REASON = [
  { id: '1', name: 'Competitor Offers Lower Price' },
  { id: '2', name: 'Fulfillment issue' },
  { id: '3', name: 'Credit issue' },
  { id: '4', name: 'Customer changed mind' },
  { id: '5', name: 'No reason' },
];
export const PENDING_REASON = [
  { id: '1', name: 'Parts not Available' },
  { id: '2', name: 'Accidental repair' },
];

export const PAYMENT_MODE = [
  { id: '1', name: 'Card' },
  { id: '2', name: 'Cash' },
  { id: '3', name: 'Credit' },
];

export const BAY = [
  { id: '1', name: 'BAY 1' },
  { id: '2', name: 'BAY 2' },
  { id: '3', name: 'BAY 3' },
  { id: '4', name: 'BAY 4' },
  { id: '5', name: 'BAY 5' },
  { id: '6', name: 'BAY 6' },
  { id: '7', name: 'BAY 7' },
  { id: '8', name: 'BAY 8' },
  { id: '9', name: 'BAY 9' },
  { id: '10', name: 'BAY 10' },
  { id: '11', name: 'BAY 11' },
  { id: '12', name: 'BAY 12' },
  { id: '13', name: 'BAY 13' },
  { id: '14', name: 'BAY 14' },
  { id: '15', name: 'BAY 15' },
  { id: '16', name: 'BAY 16' },
  { id: '17', name: 'BAY 17' },
  { id: '18', name: 'BAY 18' },
  { id: '19', name: 'BAY 19' },
  { id: '20', name: 'BAY 20' },
  { id: '21', name: 'BAY 21' },
  { id: '22', name: 'BAY 22' },
  { id: '23', name: 'BAY 23' },
  { id: '24', name: 'BAY 24' },
  { id: '25', name: 'BAY 25' },
  { id: '26', name: 'BAY 26' },
  { id: '27', name: 'BAY 27' },
  { id: '28', name: 'BAY 28' },
  { id: '29', name: 'BAY 29' },
  { id: '30', name: 'BAY 30' },
  { id: '31', name: 'BAY 31' },
  { id: '32', name: 'BAY 32' },
  { id: '33', name: 'BAY 33' },
  { id: '34', name: 'BAY 34' },
  { id: '35', name: 'BAY 35' },
  { id: '36', name: 'BAY 36' },
  { id: '37', name: 'BAY 37' },
  { id: '38', name: 'BAY 38' },
  { id: '39', name: 'BAY 39' },
  { id: '40', name: 'BAY 40' },
  { id: '41', name: 'BAY 41' },
  { id: '42', name: 'BAY 42' },
  { id: '43', name: 'BAY 43' },
  { id: '44', name: 'BAY 44' },
  { id: '45', name: 'BAY 45' },
  { id: '46', name: 'BAY 46' },
  { id: '47', name: 'BAY 47' },
  { id: '48', name: 'BAY 48' },
  { id: '49', name: 'BAY 49' },
  { id: '50', name: 'BAY 50' },
];

export const INVOICE_FORMAT = [
  { id: '1', name: 'Retail Invoice' },
  { id: '2', name: 'Tax Invoice' },
  { id: '3', name: 'VAT Invoice' },
];

export const SERVICE_AT = [
  { id: '1', name: 'Workshop' },
  { id: '2', name: 'Outside Camp' },
  { id: '3', name: 'In House Camp' },
  { id: '4', name: 'MSV-Mobile Service Van' },
];
export const LABOUR_RATE_LIST = [
  { id: '1', name: 'HSMI LABOUR RATE LIST - GST' },
  { id: '2', name: 'HSMI LABOUR RATE LIST - JK' },
];
export const SERVICE_TYPE = [
  { id: '1', name: 'AN - CF' },
  { id: '2', name: 'FREE 01' },
  { id: '3', name: 'First Time' },
  { id: '4', name: 'Normal - AN' },
  { id: '5', name: 'Normal - AN - CF' },
  { id: '6', name: 'Normal - CF' },
  { id: '7', name: 'Not Specified' },
  { id: '8', name: 'Repeat First Time' },
  { id: '9', name: 'Repeat More' },
  { id: '10', name: 'FREE 02' },
  { id: '11', name: 'FREE 03' },
  { id: '12', name: 'FREE 04' },
  { id: '13', name: 'ACCIDENT' },
  { id: '14', name: 'GENERAL REPAIRS' },
  { id: '15', name: 'MINOR REPAIRS' },
  { id: '16', name: 'PAID' },
  { id: '17', name: 'PDI' },
  { id: '18', name: 'REFURBISHMENT' },
  { id: '19', name: 'Sales' },
];
export const SERVICE_STATUS = [
  { id: '1', name: 'Status' },
  { id: '2', name: 'Open' },
  { id: '3', name: 'New' },
  { id: '4', name: 'Job Card Completed' },
  { id: '5', name: 'Deferred' },
  { id: '6', name: 'Booked' },
  { id: '7', name: 'Attended by SA' },
  { id: '8', name: 'Pending' },
  { id: '9', name: 'Test' },
  { id: '10', name: 'Closed' },
  { id: '11', name: 'Cancelled' },
  { id: '12', name: 'Exception Handling' },
  { id: '13', name: 'Quoted' },
  { id: '14', name: 'In Progress' },
  { id: '15', name: 'Completed' },
  { id: '16', name: 'Submitted' },
  { id: '17', name: 'Approved' },
  { id: '18', name: 'Revised' },
  { id: '19', name: 'Rejected' },
  { id: '19', name: 'Escalated' },
  { id: '19', name: 'Not Started' },
  { id: '19', name: 'Submitted for Approval' },
  { id: '19', name: 'Reviewed with Comments' },
  { id: '19', name: 'Obsolete' },
  { id: '19', name: 'Draft' },
  { id: '19', name: 'Open CR' },
  { id: '19', name: 'WIP (Work in progress)' },
  { id: '19', name: 'Action Completed' },
  { id: '19', name: 'Closed CR' },
  { id: '19', name: 'Re-Open' },
  { id: '19', name: 'Re-Closed' },
];
export const SERVICE_SOURCE = [
  { id: '1', name: 'Phone' },
  { id: '2', name: 'Email' },
  { id: '3', name: 'Web' },
  { id: '4', name: 'Fax' },
  { id: '5', name: 'User group' },
  { id: '6', name: 'Auto' },
  { id: '7', name: 'Maintanance' },
  { id: '8', name: 'Chat' },
  { id: '9', name: 'Teller Window' },
  { id: '10', name: 'Social Media' },
];
export const REPEAT_COMPLAINT_REASON = [
  { id: '1', name: 'Parts failure' },
  { id: '2', name: 'Repair qulaity' },
  { id: '3', name: 'Customer usage' },
  { id: '4', name: 'Part non availabity' },
];
export const FUEL_LEVEL = [
  { id: '1', name: 'F' },
  { id: '2', name: '3/4' },
  { id: '3', name: '1/4' },
  { id: '4', name: '1/2' },
  { id: '4', name: 'E' },
];
export const SERVICE_OPERATION = [
  { id: '1', name: 'City Road' },
  { id: '2', name: 'Mountain Road' },
  { id: '3', name: 'Village Road' },
];
export const ORDER_TYPE = [
  { id: 'null', name: 'none' },
  { id: '2', name: 'Bulk Request Explode Order' },
  { id: '3', name: 'Bulk Request Template Order' },
  { id: '4', name: 'Refurbished Resale Order' },
  { id: '5', name: 'SPO Return Order' },
  { id: '6', name: 'Sales DO' },
  { id: '7', name: 'Sales Order' },
  { id: '8', name: 'Sales PO' },
  { id: '9', name: 'Service Product Order' },
  { id: '10', name: 'Transportation Order' },
  { id: '11', name: 'Web Order' },
];
export const AMC_SUBTYPE = [{ id: '1', name: 'Basic AMC' }];
export const SALES_CANCELLATION_REASON = [
  { id: '1', name: 'Customer not interested' },
  { id: '2', name: 'Duplicate Chassis No.' },
  { id: '2', name: 'Vehicle invoice cancel' },
  { id: '2', name: 'Wrong period of cover' },
];
export const MODEL_NAME = [
  { id: '1', name: 'Model Name 1' },
  { id: '2', name: 'Model Name 2' },
  { id: '2', name: 'Model Name 3' },
];
export const MODEL_VARIENT = [
  { id: '1', name: 'Model Varient 1' },
  { id: '2', name: 'Model Varient 2' },
  { id: '2', name: 'Model Varient 3' },
];

export const logData = {
  accounts: {
    name: {
      id: 'name',
      label: 'Name',
      minWidth: 300,
      order: 1,
    },
    ac_groupId: {
      id: 'ac_groupId',
      label: 'Account Group Name',
      minWidth: 200,
      valueGetter: (row) => row?.ac_group?.name,
      order: 2,
    },
    performedByUser: {
      id: 'performedByUser',
      label: 'Performed by',
      minWidth: 200,
      valueGetter: (row) => row?.performedByUser?.name,
      image: (row) => row?.performedByUser?.photo,
      order: 3,
    },
    operationType: {
      id: 'operationType',
      label: 'Operation',
      minWidth: 100,
      order: 4,
    },
    operationTimestamp: {
      id: 'operationTimestamp',
      label: 'Date/Time',
      minWidth: 170,
      order: 5,
    },
    s_n: {
      id: 's_n',
      label: 'Short Name',
      minWidth: 120,
      order: 6,
    },
    gst_type: {
      id: 'gst_type',
      label: 'GST Type',
      minWidth: 120,
      valueGetter: (row) => GST_TYPE.find((x) => x.id === row?.gst_type)?.name || '-',
      order: 7,
    },
    tra_type: {
      id: 'tra_type',
      label: 'Transport Type',
      minWidth: 150,
      valueGetter: (row) => TRANSPORT.find((x) => x.id === row?.tra_type)?.name || '-',
      order: 8,
    },
    bal_met: {
      id: 'bal_met',
      label: 'Balance Method',
      minWidth: 150,
      valueGetter: (row) => BALANCE_METHOD.find((x) => x.id === row?.bal_met)?.name || '-',
      order: 9,
    },
    op_bal: {
      id: 'op_bal',
      label: 'Opening Balance',
      minWidth: 150,
      order: 10,
    },
    bal_type: {
      id: 'bal_type',
      label: 'Balance Type',
      minWidth: 150,
      valueGetter: (row) => BALANCE_TYPE.find((x) => x.id === row?.bal_type)?.name || '-',
      order: 11,
    },
    cr_limit: {
      id: 'cr_limit',
      label: 'credit Limit',
      minWidth: 120,
      order: 12,
    },
    cr_day: {
      id: 'cr_day',
      label: 'credit Days',
      minWidth: 120,
      order: 13,
    },
    in_rate: {
      id: 'in_rate',
      label: 'Interest %',
      minWidth: 120,
      order: 14,
    },
    in_ac: {
      id: 'in_ac',
      label: 'Interest A/c No.',
      minWidth: 150,
      order: 15,
    },
    tds_ac: {
      id: 'tds_ac',
      label: 'TDS A/c No.',
      minWidth: 150,
      order: 16,
    },
    in_bal_type: {
      id: 'in_bal_type',
      label: 'Credit/Debit',
      minWidth: 120,
      valueGetter: (row) => BALANCE_TYPE.find((x) => x.id === row?.in_bal_type)?.name || '-',
      order: 17,
    },
    bank_name: {
      id: 'bank_name',
      label: 'Bank Name',
      minWidth: 150,
      order: 18,
    },
    branch_name: {
      id: 'branch_name',
      label: 'Branch Name',
      minWidth: 150,
      order: 19,
    },
    acc_no: {
      id: 'acc_no',
      label: 'Bank A/c No.',
      minWidth: 150,
      order: 20,
    },
    ifsc_code: {
      id: 'ifsc_code',
      label: 'IFSC Code',
      minWidth: 120,
      order: 21,
    },
    b_address: {
      id: 'b_address',
      label: 'Bank Address',
      minWidth: 150,
      order: 22,
    },
    c_name: {
      id: 'c_name',
      label: 'Contact Person',
      minWidth: 150,
      order: 23,
    },
    pincode: {
      id: 'pincode',
      label: 'Pincode',
      minWidth: 120,
      order: 24,
    },
    mobile: {
      id: 'mobile',
      label: 'Mobile',
      minWidth: 120,
      order: 25,
    },
    pan_no: {
      id: 'pan_no',
      label: 'PAN No.',
      minWidth: 120,
      order: 26,
    },
    gst_no: {
      id: 'gst_no',
      label: 'GST No.',
      minWidth: 150,
      order: 27,
    },
    email: {
      id: 'email',
      label: 'Email',
      minWidth: 170,
      order: 28,
    },
    adno: {
      id: 'adno',
      label: 'Addhar No.',
      minWidth: 180,
      order: 29,
    },
    country: {
      id: 'country',
      label: 'Country',
      minWidth: 120,
      valueGetter: (row) => row?.country?.name || '-',
      order: 30,
    },
    state: {
      id: 'state',
      label: 'State',
      minWidth: 120,
      valueGetter: (row) => row?.state?.name || '-',
      order: 31,
    },
    city: {
      id: 'city',
      label: 'City',
      minWidth: 120,
      valueGetter: (row) => row?.city?.name || '-',
      order: 32,
    },
    district: {
      id: 'district',
      label: 'District',
      minWidth: 120,
      order: 33,
    },
    address: {
      id: 'address',
      label: 'Address',
      minWidth: 170,
      order: 34,
    },
    status: {
      id: 'status',
      label: 'Status',
      minWidth: 120,
      valueGetter: (row) => (row?.status ? 'Active' : 'DeActive'),
      order: 35,
    },
  },
  ac_groups: {
    name: {
      id: 'name',
      label: 'Name',
      minWidth: 200,
      order: 1,
    },
    performedByUser: {
      id: 'performedByUser',
      label: 'Performed by',
      minWidth: 200,
      valueGetter: (row) => row?.performedByUser?.name,
      image: (row) => row?.performedByUser?.photo,
      order: 2,
    },
    operationType: {
      id: 'operationType',
      label: 'Operation',
      minWidth: 100,
      order: 3,
    },
    operationTimestamp: {
      id: 'operationTimestamp',
      label: 'Date/Time',
      minWidth: 170,
      order: 4,
    },
    ac_group: {
      id: 'ac_group',
      label: 'Under',
      minWidth: 150,
      valueGetter: (row) => row?.ac_group?.name,
      order: 5,
    },
    hname: {
      id: 'hname',
      label: 'Group Header',
      minWidth: 150,
      order: 6,
    },
    seq: {
      id: 'seq',
      label: 'Sequence',
      minWidth: 100,
      order: 7,
    },
    crdl: {
      id: 'crdl',
      label: 'Credit',
      minWidth: 80,
      valueGetter: (row) => (row?.crdl ? 'Active' : 'DeActive'),
      order: 8,
    },
    inte: {
      id: 'inte',
      label: 'Interest',
      minWidth: 80,
      valueGetter: (row) => (row?.inte ? 'Active' : 'DeActive'),
      order: 9,
    },
    bank: {
      id: 'bank',
      label: 'Bank',
      minWidth: 80,
      valueGetter: (row) => (row?.bank ? 'Active' : 'DeActive'),
      order: 10,
    },
    gst: {
      id: 'gst',
      label: 'GST',
      minWidth: 80,
      valueGetter: (row) => (row?.gst ? 'Active' : 'DeActive'),
      order: 11,
    },
    party: {
      id: 'party',
      label: 'Party Details',
      minWidth: 120,
      valueGetter: (row) => (row?.party ? 'Active' : 'DeActive'),
      order: 12,
    },
    status: {
      id: 'status',
      label: 'Status',
      minWidth: 80,
      valueGetter: (row) => (row?.status ? 'Active' : 'DeActive'),
      order: 13,
    },
  },
  products: {
    name: {
      id: 'name',
      label: 'Name',
      minWidth: 200,
      order: 1,
    },
    performedByUser: {
      id: 'performedByUser',
      label: 'Performed by',
      minWidth: 200,
      valueGetter: (row) => row?.performedByUser?.name,
      image: (row) => row?.performedByUser?.photo,
      order: 2,
    },
    operationType: {
      id: 'operationType',
      label: 'Operation',
      minWidth: 100,
      order: 3,
    },
    operationTimestamp: {
      id: 'operationTimestamp',
      label: 'Date/Time',
      minWidth: 170,
      order: 4,
    },
    s_n: {
      id: 's_n',
      label: 'Alias',
      minWidth: 150,
      order: 5,
    },
    partno: {
      id: 'partno',
      label: 'Product/Part No.',
      minWidth: 150,
      order: 6,
    },
    gst_commodity: {
      id: 'gst_commodity',
      label: 'GST Commodity',
      valueGetter: (row) => row?.gst_commodity?.description,
      minWidth: 150,
      order: 7,
    },
    pgroup: {
      id: 'pgroup',
      label: 'Product Group',
      valueGetter: (row) => row?.pgroup?.name,
      minWidth: 150,
      order: 8,
    },
    pcategory: {
      id: 'pcategory',
      label: 'Product Category',
      valueGetter: (row) => row?.pcategory?.name,
      minWidth: 150,
      order: 9,
    },
    st_req: {
      id: 'st_req',
      label: 'Stock Required',
      valueGetter: (row) => (row?.st_req === 1 ? 'YES' : 'NO'),
      minWidth: 120,
      order: 10,
    },
    qu_eff: {
      id: 'qu_eff',
      label: 'Quantity Effect',
      valueGetter: (row) => (row?.qu_eff === 1 ? 'YES' : 'NO'),
      minWidth: 120,
      order: 11,
    },
    pur_rate: {
      id: 'pur_rate',
      label: 'Purchase Rate',
      minWidth: 120,
      order: 12,
    },
    sal_rate: {
      id: 'sal_rate',
      label: 'Sales Rate',
      minWidth: 120,
      order: 13,
    },
    sunit: {
      id: 'sunit',
      label: 'Sales Unit',
      valueGetter: (row) => row?.sunit?.name,
      minWidth: 120,
      order: 14,
    },
    punit: {
      id: 'punit',
      label: 'Purchase Unit',
      valueGetter: (row) => row?.punit?.name,
      minWidth: 120,
      order: 15,
    },
    ugst_unit: {
      id: 'ugst_unit',
      label: 'GST Unit(UQC)',
      valueGetter: (row) => row?.ugst_unit?.name,
      minWidth: 120,
      order: 16,
    },
    quantity: {
      id: 'quantity',
      label: 'Quantity',
      minWidth: 120,
      order: 17,
    },
    ogst_unit: {
      id: 'ogst_unit',
      label: 'Opening Unit(UQC)',
      valueGetter: (row) => row?.ogst_unit?.name,
      minWidth: 150,
      order: 18,
    },
    rate: {
      id: 'rate',
      label: 'Rate',
      minWidth: 120,
      order: 19,
    },
    amount: {
      id: 'amount',
      label: 'Amount',
      minWidth: 120,
      order: 20,
    },
    alt_stock: {
      id: 'alt_stock',
      label: 'Alternate Stock',
      minWidth: 120,
      order: 21,
    },
    agst_unit: {
      id: 'agst_unit',
      label: 'Alternate Unit(UQC)',
      valueGetter: (row) => row?.agst_unit?.name,
      minWidth: 180,
      order: 22,
    },
    min_stock: {
      id: 'min_stock',
      label: 'Minimum Stock',
      minWidth: 120,
      order: 23,
    },
    reo_stock: {
      id: 'reo_stock',
      label: 'Reorder Stock',
      minWidth: 120,
      order: 24,
    },
    auto_pro: {
      id: 'auto_pro',
      label: 'Auto Production',
      valueGetter: (row) => (row?.auto_pro === 1 ? 'YES' : 'NO'),
      minWidth: 150,
      order: 25,
    },
    pro_id: {
      id: 'pro_id',
      label: 'Process Name',
      valueGetter: (row) => PROCESS_NAME.find((x) => x.id === row?.pro_id)?.name,
      minWidth: 120,
      order: 26,
    },
    srcon: {
      id: 'srcon',
      label: 'Sales Factor',
      minWidth: 120,
      order: 27,
    },
    prcon: {
      id: 'prcon',
      label: 'Purchase Factor',
      minWidth: 150,
      order: 28,
    },
    stcon: {
      id: 'stcon',
      label: 'Stock Factor',
      minWidth: 150,
      order: 29,
    },
  },
  pgroups: {
    name: {
      id: 'name',
      label: 'Name',
      minWidth: 200,
      order: 1,
    },
    performedByUser: {
      id: 'performedByUser',
      label: 'Performed by',
      minWidth: 200,
      valueGetter: (row) => row?.performedByUser?.name,
      image: (row) => row?.performedByUser?.photo,
      order: 2,
    },
    operationType: {
      id: 'operationType',
      label: 'Operation',
      minWidth: 100,
      order: 3,
    },
    operationTimestamp: {
      id: 'operationTimestamp',
      label: 'Date/Time',
      minWidth: 170,
      order: 4,
    },
    status: {
      id: 'status',
      label: 'Status',
      minWidth: 80,
      valueGetter: (row) => (row?.status ? 'Active' : 'DeActive'),
      order: 5,
    },
  },
  punits: {
    name: {
      id: 'name',
      label: 'Name',
      minWidth: 200,
      order: 1,
    },
    performedByUser: {
      id: 'performedByUser',
      label: 'Performed by',
      minWidth: 200,
      valueGetter: (row) => row?.performedByUser?.name,
      image: (row) => row?.performedByUser?.photo,
      order: 2,
    },
    operationType: {
      id: 'operationType',
      label: 'Operation',
      minWidth: 100,
      order: 3,
    },
    operationTimestamp: {
      id: 'operationTimestamp',
      label: 'Date/Time',
      minWidth: 170,
      order: 4,
    },
    status: {
      id: 'status',
      label: 'Status',
      minWidth: 80,
      valueGetter: (row) => (row?.status ? 'Active' : 'DeActive'),
      order: 5,
    },
  },
  pcategories: {
    name: {
      id: 'name',
      label: 'Name',
      minWidth: 200,
      order: 1,
    },
    performedByUser: {
      id: 'performedByUser',
      label: 'Performed by',
      minWidth: 200,
      valueGetter: (row) => row?.performedByUser?.name,
      image: (row) => row?.performedByUser?.photo,
      order: 2,
    },
    operationType: {
      id: 'operationType',
      label: 'Operation',
      minWidth: 100,
      order: 3,
    },
    operationTimestamp: {
      id: 'operationTimestamp',
      label: 'Date/Time',
      minWidth: 170,
      order: 4,
    },
    status: {
      id: 'status',
      label: 'Status',
      minWidth: 80,
      valueGetter: (row) => (row?.status ? 'Active' : 'DeActive'),
      order: 5,
    },
  },
  hsn_sac_code: {
    code: {
      id: 'code',
      label: 'Code',
      minWidth: 120,
      order: 1,
    },
    performedByUser: {
      id: 'performedByUser',
      label: 'Performed by',
      minWidth: 200,
      valueGetter: (row) => row?.performedByUser?.name,
      image: (row) => row?.performedByUser?.photo,
      order: 2,
    },
    operationType: {
      id: 'operationType',
      label: 'Operation',
      minWidth: 100,
      order: 3,
    },
    operationTimestamp: {
      id: 'operationTimestamp',
      label: 'Date/Time',
      minWidth: 170,
      order: 4,
    },
    description: {
      id: 'description',
      label: 'Description',
      minWidth: 220,
      order: 5,
    },
    status: {
      id: 'status',
      label: 'Status',
      minWidth: 80,
      valueGetter: (row) => (row?.status ? 'Active' : 'DeActive'),
      order: 6,
    },
  },
  gst_units: {
    name: {
      id: 'name',
      label: 'Name',
      minWidth: 170,
      order: 1,
    },
    performedByUser: {
      id: 'performedByUser',
      label: 'Performed by',
      minWidth: 200,
      valueGetter: (row) => row?.performedByUser?.name,
      image: (row) => row?.performedByUser?.photo,
      order: 2,
    },
    operationType: {
      id: 'operationType',
      label: 'Operation',
      minWidth: 100,
      order: 3,
    },
    operationTimestamp: {
      id: 'operationTimestamp',
      label: 'Date/Time',
      minWidth: 170,
      order: 4,
    },
    status: {
      id: 'status',
      label: 'Status',
      minWidth: 80,
      valueGetter: (row) => (row?.status ? 'Active' : 'DeActive'),
      order: 6,
    },
  },
  gst_rates: {
    description: {
      id: 'description',
      label: 'GST Slab',
      minWidth: 170,
      order: 1,
    },
    performedByUser: {
      id: 'performedByUser',
      label: 'Performed by',
      minWidth: 200,
      valueGetter: (row) => row?.performedByUser?.name,
      image: (row) => row?.performedByUser?.photo,
      order: 2,
    },
    operationType: {
      id: 'operationType',
      label: 'Operation',
      minWidth: 100,
      order: 3,
    },
    operationTimestamp: {
      id: 'operationTimestamp',
      label: 'Date/Time',
      minWidth: 170,
      order: 4,
    },
    type: {
      id: 'type',
      label: 'Type',
      valueGetter: (row) => GST_TYPE.find((x) => x.id === row?.type)?.name,
      minWidth: 120,
      order: 5,
    },
    SGST: {
      id: 'SGST',
      label: 'SGST',
      minWidth: 120,
      order: 6,
    },
    CGST: {
      id: 'CGST',
      label: 'CGST',
      minWidth: 120,
      order: 7,
    },
    IGST: {
      id: 'IGST',
      label: 'IGST',
      minWidth: 120,
      order: 8,
    },
    status: {
      id: 'status',
      label: 'Status',
      minWidth: 80,
      valueGetter: (row) => (row?.status ? 'Active' : 'DeActive'),
      order: 9,
    },
  },
  gst_commodities: {
    description: {
      id: 'description',
      label: 'Description',
      minWidth: 150,
      order: 1,
    },
    performedByUser: {
      id: 'performedByUser',
      label: 'Performed by',
      minWidth: 200,
      valueGetter: (row) => row?.performedByUser?.name,
      image: (row) => row?.performedByUser?.photo,
      order: 2,
    },
    operationType: {
      id: 'operationType',
      label: 'Operation',
      minWidth: 100,
      order: 3,
    },
    operationTimestamp: {
      id: 'operationTimestamp',
      label: 'Date/Time',
      minWidth: 170,
      order: 4,
    },
    com_type: {
      id: 'com_type',
      label: 'Commodity Type',
      valueGetter: (row) => COMMODITY_TYPE.find((x) => x.id === row?.com_type)?.name,
      minWidth: 120,
      order: 5,
    },
    re_ch_ap: {
      id: 're_ch_ap',
      label: 'Reverse Charge Applicable',
      valueGetter: (row) => REVERSE_CHARGE_APPLICABLE.find((x) => x.id === row?.re_ch_ap)?.name,
      minWidth: 120,
      order: 6,
    },
    re_ch_pa: {
      id: 're_ch_pa',
      label: 'Reverse Charge Payable(%)',
      minWidth: 170,
      order: 7,
    },
    gst_com_details: {
      id: 'gst_com_details',
      label: 'GST Commodity Details',
      minWidth: 170,
      valueGetter: (row) => row?.gst_com_details?.length,
      isNested: true,
      order: 8,
    },
  },
  gst_com_details: {
    gst_rate: {
      id: 'gst_rate',
      label: 'GST Slab',
      valueGetter: (row) => row?.gst_rate?.description,
      minWidth: 150,
      order: 1,
    },
    performedByUser: {
      id: 'performedByUser',
      label: 'Performed by',
      minWidth: 200,
      valueGetter: (row) => row?.performedByUser?.name,
      image: (row) => row?.performedByUser?.photo,
      order: 2,
    },
    operationType: {
      id: 'operationType',
      label: 'Operation',
      minWidth: 100,
      order: 3,
    },
    operationTimestamp: {
      id: 'operationTimestamp',
      label: 'Date/Time',
      minWidth: 170,
      order: 4,
    },
    hsn_sac_code: {
      id: 'hsn_sac_code',
      label: 'HSN/SAC Code',
      valueGetter: (row) => row?.hsn_sac_code?.code,
      minWidth: 170,
      order: 5,
    },
    appdate: {
      id: 'appdate',
      label: 'Applied Date',
      minWidth: 170,
      order: 6,
    },
  },
};
export const TRANSACTION_TYPEARRAY = [
  { value: 1, label: 'Bank Payment' },
  { value: 2, label: 'Bank Receipt' },
  { value: 3, label: 'Cash Payment' },
  { value: 4, label: 'Cash Receipt' },
  { value: 5, label: 'Contra Payment' },
  { value: 6, label: 'Contra Receipt' },
  { value: 7, label: 'JV' },
  { value: 8, label: 'Purchase' },
  { value: 9, label: 'Sale' },
  { value: 10, label: 'DN' },
  { value: 11, label: 'CN' },
  { value: 12, label: 'Disbrusment' },
  { value: 13, label: 'GE' },
  { value: 14, label: 'cheque' },
];
export const INVOICE_TYPEARRAY = [
  { value: 1, label: 'RD', t_type: [8, 9, 10, 11], pcategories: [] },
  { value: 2, label: 'URD', t_type: [8, 9, 10, 11], pcategories: [] },
  { value: 3, label: 'OGS', t_type: [8, 9, 10, 11], pcategories: [] },
  { value: 4, label: 'RCM', t_type: [8, 9, 10, 11], pcategories: [] },
  { value: 5, label: 'SEZWP', t_type: [9, 11], pcategories: [] },
  { value: 6, label: 'SEZWOP', t_type: [9, 11], pcategories: [] }
];
export const DEPARTMENT_ARRAY = [
  { value: 'false', label: 'SHOWROOM' },
  { value: 'true', label: 'WORKSHOP' }
];
export const TRANSACTION_TYPES = {
  1: 'Bank Payment',
  2: 'Bank Receipt',
  3: 'Cash Payment',
  4: 'Cash Receipt',
  5: 'Contra Payment',
  6: 'Contra Receipt',
  7: 'Journal',
  8: 'Purchase',
  9: 'Sale',
  10: 'DN',
  11: 'CN',
  12: 'Disbrusment',
  13: 'GE',
  14: 'CHEQUE',
};

export const report_periods = [
  {
    label: 'Day',
    value: '1',
  },
  {
    label: 'Week',
    value: '2',
  },
  {
    label: 'Month',
    value: '3',
  },
  {
    label: 'Last Month',
    value: '6',
  },
  {
    label: 'Quarter',
    value: '4',
  },
  {
    label: 'Year',
    value: '5',
  },
];
export const automobile_report_periods = [
  {
    label: 'Day',
    value: '1',
  },
  {
    label: 'Month',
    value: '3',
  },
  {
    label: 'Quarter',
    value: '4',
  },
  {
    label: 'Year',
    value: '5',
  },
];
export const bank_cash_report_formate = [
  {
    label: 'Rec / Pay',
    value: '1',
  },
  {
    label: 'With Balance',
    value: '2',
  },
];

export const COLOR = [
  {
    label: 'Black AND Red',
    value: '1',
  },
  {
    label: 'Black AND Green',
    value: '2',
  },
  {
    label: 'Black AND Blue',
    value: '3',
  },
];

export const DEPARTMENTLIST = [{ label: 'BOTH', value: 0 }, { label: 'SHOWROOM', value: 1 }, { label: 'WORKSHOP', value: 2 }];
export const HONDA_CATEGORY = [
  {
    id: 1,
    name: 'Motor Cycle',
    image: 'https://i.pinimg.com/736x/a4/12/69/a41269b4e38505e5f0c3a70433da971f.jpg',
    model: [
      {
        id: 1,
        name: 'Livo',
        image: 'https://www.honda2wheelersindia.com/assets/images/livoBS_6/Bike2_dev_one.png',
        viewLink: 'https://www.honda2wheelersindia.com/assets/pdf/Livo_brochure_24.pdf',
      },
      {
        id: 2,
        name: 'Shine 100CC',
        image: 'https://www.honda2wheelersindia.com/assets/images/shine100/Green_bike.png',
        viewLink: 'https://www.honda2wheelersindia.com/assets/pdf/Shine_100_brochure_24.pdf',
      },
      {
        id: 3,
        name: 'CD 110 Dream',
        image:
          'https://www.honda2wheelersindia.com/assets/images/cd110dreamBS_6/Green_bike_dev_one.png',
        viewLink: 'https://www.honda2wheelersindia.com/assets/pdf/CD110_brochure_24.pdf',
      },
      {
        id: 4,
        name: 'Shine 125',
        image:
          'https://www.honda2wheelersindia.com/assets/images/CBShineBS_6/enquiry_blue_2new1_dev_one.png',
        viewLink: 'https://www.honda2wheelersindia.com/assets/pdf/Shine_125_brochure_24.pdf',
      },
      {
        id: 5,
        name: 'SP-125',
        image:
          'https://imgd.aeplcdn.com/1280x720/n/cw/ec/43482/sp-125-right-front-three-quarter-2.jpeg',
        viewLink: 'https://www.honda2wheelersindia.com/assets/pdf/SP125_brochure_24.pdf',
      },
      {
        id: 6,
        name: 'SP-125 Sports',
        image:
          'https://www.honda2wheelersindia.com/assets/images/sp_125_sports/sp-125-sports-Price_bike.png',
        viewLink:
          'https://www.honda2wheelersindia.com/assets/pdf/SP125_Sports_Brochure_latest_File.pdf',
      },
      {
        id: 7,
        name: 'Unicorn',
        image:
          'https://www.honda2wheelersindia.com/assets/images/unicornBS_6/unicorn_enquiryBike_dev_one.png',
        viewLink: 'https://www.honda2wheelersindia.com/assets/pdf/Unicorn_brochure_24.pdf',
      },
      {
        id: 8,
        name: 'SP-160',
        image:
          'https://stat.overdrive.in/wp-content/uploads/2023/08/Untitled-design-2023-08-08T134023.894-900x506.png',
        viewLink: 'https://www.honda2wheelersindia.com/assets/pdf/SP160_brochure_24.pdf',
      },
      {
        id: 9,
        name: 'Shine 100',
        image: 'https://www.honda2wheelersindia.com/assets/images/shine100/Green_bike.png',
        viewLink: 'https://www.honda2wheelersindia.com/assets/pdf/Shine_100_brochure_24.pdf',
      },
      {
        id: 10,
        name: 'CB200X',
        image: 'https://www.honda2wheelersindia.com/assets/images/cb200x/Black_bike_dev23.png',
        viewLink: 'https://www.honda2wheelersindia.com/assets/pdf/CB200X_brochure_24.pdf',
      },
      {
        id: 11,
        name: 'Hornet 2.0 ABS OBD2',
        image:
          'https://www.honda2wheelersindia.com/assets/images/Repsol_Edition_Dio_Hornet/Hornet_Repsol_common_bike_dev23.png',
        viewLink: 'https://www.honda2wheelersindia.com/assets/pdf/Dio125_and_Hornet2_Repsol.pdf',
      },
      {
        id: 12,
        name: 'CB200X',
        image: 'https://www.honda2wheelersindia.com/assets/images/cb200x/Black_bike_dev23.png',
        viewLink: 'https://www.honda2wheelersindia.com/assets/pdf/CB200X_brochure_24.pdf',
      },
      {
        id: 13,
        name: 'Hornet2-0',
        image:
          'https://www.honda2wheelersindia.com/assets/images/CBHornetBS_6/Spec_Bike_dev_one.png',
        viewLink: 'https://www.honda2wheelersindia.com/assets/pdf/Hornet_2_0_brochure_24.pdf',
      },
    ],
  },
  {
    id: 2,
    name: 'Scooter',
    image:
      'https://bd.gaadicdn.com/processedimages/honda/activa-6g/source/activa-6g65e80a97062a4.jpg',
    model: [
      {
        id: 1,
        name: 'Activa',
        image:
          'https://www.honda2wheelersindia.com/assets/images/activa6g/Feature_Blue_newActive.png',
        viewLink:
          'https://www.honda2wheelersindia.com/assets/pdf/Activa%20_%20Smart%20Key%20_%20Brochure.pdf',
      },
      {
        id: 2,
        name: 'Activa125 BS VI',
        image: 'https://www.honda2wheelersindia.com/assets/images/activa125_BS6/Enquiry_new9.png',
        viewLink:
          'https://www.honda2wheelersindia.com/assets/pdf/Activa125_Brochure2024_Latest.pdf',
      },
      {
        id: 3,
        name: 'Activa Special Edition',
        image:
          'https://www.honda2wheelersindia.com/assets/images/Activa_Special_Edition/Enquiry_scooty_img__dev23.png',
        viewLink: 'https://www.honda2wheelersindia.com/assets/pdf/Activa_Special_Brochure.pdf',
      },
      {
        id: 4,
        name: 'Dio 110',
        image:
          'https://www.honda2wheelersindia.com/assets/images/dioBS_6/dio_gallery_360Image_dev_one/9.png',
        viewLink: 'https://www.honda2wheelersindia.com/assets/pdf/Dio110_%20Brochure_2024.pdf',
      },
      {
        id: 5,
        name: 'Dio Repsol',
        image:
          'https://www.honda2wheelersindia.com/assets/images/Repsol_Edition_Dio_Hornet/Dio_Repsol_common_bike_dev23.png',
        viewLink: 'https://www.honda2wheelersindia.com/assets/pdf/Dio125_and_Hornet2_Repsol.pdf',
      },
      {
        id: 6,
        name: 'Dio 125',
        image: 'https://autoscanhonda.com/wp-content/themes/rvtheme/scooters/360-10178-21.png',
        viewLink: 'https://www.honda2wheelersindia.com/assets/pdf/Dio125_%20Brochure_new_2024.pdf',
      },
    ],
  },
  {
    id: 3,
    name: 'Premium Motorcycle',
    image:
      'https://economictimes.indiatimes.com/thumb/msid-83644566,width-1200,height-900,resizemode-4,imgsize-267318/honda_website.jpg',
    model: [
      {
        id: 1,
        name: `H'ness CB350`,
        image:
          'https://www.hondabigwing.in/Content/images/features/cb350/The_Majestic_Overall_Design_new5.jpg',
        viewLink: `https://www.hondabigwing.in/Content/pdf/H'nessCB350_Brochure.pdf`,
      },
      {
        id: 2,
        name: `CB350RS`,
        image:
          'https://www.hondabigwing.in/Content/images/cb350rs/Features/Advanced/Dual_channel_ABS_new8.jpg',
        viewLink: 'https://www.hondabigwing.in/Content/pdf/CB350RS_brochure.pdf',
      },
      {
        id: 3,
        name: `CB-350`,
        image:
          'https://www.hondabigwing.in/Content/images/CB350_newModel/Color/DLX/Pearl_Igneous_Black_dev1.png',
        viewLink: 'https://www.hondabigwing.in/Content/pdf/cb-350-New-Brochure.pdf',
      },
      {
        id: 4,
        name: `CB300R`,
        image:
          'https://www.hondabigwing.in/Content/images/cb300r/colors/PEARL_SPARTAN_RED_dev22.png',
        viewLink: 'https://www.hondabigwing.in/Content/pdf/CB300R_Brochure2023A.pdf',
      },
      {
        id: 5,
        name: `Honda BigWing`,
        image: 'https://www.hondabigwing.in/Content/images/cb300f/colors/SPORTS_Red5.png',
        viewLink: 'https://www.hondabigwing.in/Content/pdf/CB300F_Brochure_23.pdf',
      },
      {
        id: 6,
        name: `NX-500`,
        image: 'https://www.hondabigwing.in/Content/images/NX500/Color/nx500_red_bike_two.png',
        viewLink: 'https://www.hondabigwing.in/Content/pdf/NX500-brochure-16-02-2024.pdf',
      },
      {
        id: 7,
        name: `TRANSALP`,
        image: 'https://www.hondabigwing.in/Content/images/transalp/colors/Ross_White_dev2.png',
        viewLink: 'https://www.hondabigwing.in/Content/pdf/Transalp_Brochure_2023_01.pdf',
      },
      {
        id: 8,
        name: `GOLDWING`,
        image:
          'https://www.hondabigwing.in/Content/images/Goldwing/Color/Gunmetal-Black-Metallic-%c3%97-Mat-Morion-Black-Goldwing_dev23.png',
        viewLink: 'https://www.hondabigwing.in/Content/pdf/GoldWing-Brochure2.pdf',
      },
    ],
  },
];

export const amideepautomobilebranch = {
  1: "VAR",
  2: "AMR",
  3: "LHR",
  4: "KTM",
  5: "MOT"
}

export const removeNonDigits = (input) => {
  // Remove all non-digit characters
  const digitsOnly = typeof input === "string" ? input.replace(/\D/g, '') : input;
  return digitsOnly || null;
}