import { useContext, useEffect, useState } from 'react';
// @mui
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
// assets
import {
  BookingIllustration,
  CheckInIllustration,
  CheckoutIllustration,
} from 'src/assets/illustrations';
// components
import { useSettingsContext } from 'src/components/settings';
//
import { useLocation } from 'react-router';
import { AuthContext } from 'src/auth/context/jwt';
import BookingWidgetSummary from '../booking-widget-summary';

// ----------------------------------------------------------------------

const SPACING = 3;

export default function OverviewBookingView() {
  const { user } = useContext(AuthContext);

  const settings = useSettingsContext();
  const location = useLocation();

  const [mainBox, setMainBox] = useState();

  console.log(mainBox, 'mainBox');

  useEffect(() => {
    const box = user.modules?.find((x) => x.spath === location?.pathname);
    setMainBox(box?.children);
  }, [location, user]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Grid container spacing={SPACING} disableEqualOverflow>
        {mainBox?.map((box) => (
          <Grid xs={12} md={3}>
            <BookingWidgetSummary
              childBox={box}
              title={box?.title}
              total={1}
              icon={<BookingIllustration />}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
