import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { create, update } from 'src/utils/axios';
import {
  DataGrid,
  GridActionsCellItem,
  GridAddIcon,
  GridCellModes,
  GridRowEditStopReasons,
  GridRowModes,
  GridSaveAltIcon,
} from '@mui/x-data-grid';
import { KEY, LABOUR_TYPE, SKILL_LEVEL } from 'src/_mock/constant';
import { Divider, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { alpha } from '@mui/system';
import { useEventListener } from 'src/hooks/use-event-listener';
// ----------------------------------------------------------------------

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();

  const [openModelName, setOpenModelName] = useState('');

  const dialog = useBoolean();

  const departmentSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const values = useMemo(
    () => ({
      name: row?.name || '',
      status: row?.status,
      description: row?.description || '',
      balance_method: row?.balance_method || 'Bill To Bill',
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(departmentSchema),
    values,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const onSubmit = handleSubmit(async (payload) => {
    try {
      let URL = 'roles/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const { watch, control, setValue } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'company_banks',
  });

  const handleAdd = () => {
    append({
      name: '',
      branch_name: '',
      ifsc_code: '',
      iban_no: '',
      swift_code: '',
      upi_code: '',
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };
  useEventListener('keydown', handleKeyDown);

  const [cellModesModel, setCellModesModel] = useState({});

  const handleCellClick = useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (!event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => ({
      // Revert the mode of the other cells from other rows
      ...Object.keys(prevModel).reduce(
        (acc, id) => ({
          ...acc,
          [id]: Object.keys(prevModel[id]).reduce(
            (acc2, field) => ({
              ...acc2,
              [field]: { mode: GridCellModes.View },
            }),
            {}
          ),
        }),
        {}
      ),
      [params.id]: {
        // Revert the mode of other cells in the same row
        ...Object.keys(prevModel[params.id] || {}).reduce(
          (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
          {}
        ),
        [params.field]: { mode: GridCellModes.Edit },
      },
    }));
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  function ValueParserSetterGrid() {
    return (
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={defaultRows}
          columns={columns}
          rowModesModel={rowModesModel}
          // onRowModesModelChange={handleRowModesModelChange}
          // onRowEditStop={handleRowEditStop}
          // processRowUpdate={processRowUpdate}
          rowHeight={40}
          // slots={{
          //   toolbar: EditToolbar,
          // }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          hideFooterPagination
          hideFooterSelectedRowCount
          disableRowSelectionOnClick
        />
      </div>
    );
  }

  const defaultRows = [
    { id: 1, productName: 'Snow', quantity: 10, rate: 1000, amount: 20000 },
    { id: 2, productName: 'Lannister', quantity: 20, rate: 1000, amount: 20000 },
    { id: 3, productName: 'Lannister', quantity: 10, rate: 1000, amount: 20000 },
    { id: 4, productName: 'Stark', quantity: 100, rate: 1000, amount: 20000 },
    { id: 5, productName: 'Targaryen', quantity: 2000, rate: 1000, amount: 20000 },
  ];

  const [rows, setRows] = useState(defaultRows);
  const [rowModesModel, setRowModesModel] = useState({});
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleDeleteClick = (id) => () => {
    setRows(defaultRows.filter((row) => row.id !== id));
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow = defaultRows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(defaultRows.filter((row) => row.id !== id));
    }
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: 'productName', headerName: 'Product Name', width: 750, editable: true },
    { field: 'quantity', headerName: 'Quantity', width: 120, editable: true },
    {
      field: 'rate',
      headerName: 'Rate',
      width: 120,
      editable: true,
    },
    { field: 'amount', headerName: 'Amount', width: 120, editable: true },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 120,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<GridSaveAltIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<GridSaveAltIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<GridAddIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<GridSaveAltIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 1000 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update Labour Information' : 'Create Labour Information'}
              </Typography>

              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>

            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          <Grid item container xs={12} md={12} sx={{ p: 3 }} columnSpacing={8} spacing={4}>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              spacing={2}
              alignContent="start"
            >
              <Grid item xs={12} sm={3.5} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField name="labour_code" label="Labour Code" size="small" />
              </Grid>
              <Grid item xs={12} sm={8.5} md={8} lg={8} xl={8} xxl={8}>
                <RHFTextField name="description" label="Description" size="small" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField name="labour_rate" label="Labour Rate" size="small" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField name="std_m_hrs" label="Std.M.Hrs" size="small" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <RHFSelect name="skill_level" label="Skill Level" size="small">
                  {SKILL_LEVEL.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField name="maj_opr_grup" label="Maj.Opr.Grup" size="small" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField name="sac_hsn_no" label="SAC/HSN No" size="small" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <RHFSelect name="labour_type" label="Labour Type" size="small">
                  {LABOUR_TYPE.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DialogActions
          sx={{
            bottom: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {row ? 'Update' : 'Add'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
