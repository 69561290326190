import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Page, View, Text, Image, Document, Font, StyleSheet } from '@react-pdf/renderer';
// utils
import { fDate } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';

// ----------------------------------------------------------------------

Font.register({
  family: 'Verdana',
  fonts: [{ src: '/fonts/Verdana/Verdana.ttf' }, { src: '/fonts/Verdana/Verdana_Bold.ttf' }],
});

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        page: {
          padding: 15,
          paddingRight: 20,
          fontFamily: 'Verdana',
        },
        section: {
          margin: 10,
          padding: 10,
        },
        header: {
          textAlign: 'center',
          marginBottom: 20,
          height: '40px'
        },
        boldText: {
          fontWeight: 'bold',
        },
        infoRow: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 10,
        },
        tableHeader: {
          display: 'flex',
          flexDirection: 'row',
          borderBottom: '1px solid black',
          paddingBottom: 5,
          marginBottom: 5,
        },
        table: {
          border: '1px solid black',
          paddingBottom: 5,
          marginBottom: 5,
        },
        tableRow: {
          textAlign: 'left',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 5,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1,
          paddingBottom: 1,
        },
        tableCol: {
          width: '20%',
        },
        footer: {
          marginTop: 30,
          textAlign: 'center',
        },
      }),
    []
  );

// ----------------------------------------------------------------------

export default function SaleInvoicePDF({ invoice }) {
  const {
    items,
    taxes,
    dueDate,
    discount,
    shipping,
    invoiceTo,
    createDate,
    totalAmount,
    invoiceFrom,
    invoiceNumber,
    subTotal,
  } = invoice;
  const styles = useStyles();
  const terms = [
    "Goods ones sold will not be taken back in any circumstances.",
    "No duplicate bill shall be issued.",
    "Check the vehicle properly before taking the delivery.We shall not be responsible for any sort of claims ones the vehicle is delivered.",
    "The customer is advise / informed to get his vehicle fully insured before taking the delivery, as his shall be held, fully responsible for any sort of damage caused or loss occurred any person, vehicle or anything due to any accident, theft, fire or riot etc.",
    "The person who signs as the customer himself / herself or as the authorized representative of the customer is totally responsible for any sort of liabilities such as Octroi, R.T.O.Charges, Municipal Tax, Insurance etc., or any sort of enquiry from any department.",
    "Price ruling at the time of delivery shall be applicable.Payment done through Cheque / DD / PO will be credited on realization of the same.",
    "Warranty as per product manual warranty conditions.",
    "All disputes shall be solved at SURAT jurisdiction only"
  ];
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header Section */}
        <View style={styles.header} />

        <View style={{ ...styles.tableRow, marginBottom: 0, textAlign: 'center' }}>
          <Text style={{ width: '50%', fontSize: '12px', fontWeight: 'bold', textAlign: 'left' }} >
            TAX INVOICE
          </Text>
          <Text style={{ width: '50%', fontSize: '8px', fontWeight: 'bold', textAlign: 'right' }} >
            GSTIN:24AAUFA8368H1ZX Dt :25/06/2017
          </Text>
        </View>
        {/* Invoice Info */}
        <View style={styles.table}>
          <View style={{ ...styles.tableRow, paddingTop: 5 }}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>AC Code</Text>
            <Text style={{ width: '50%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>: e2119663033</Text>
            <Text style={{ width: '15%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>Invoice No</Text>
            <Text style={{ width: '25%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>: 24-25/VAR/000001</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>Name</Text>
            <Text style={{ width: '50%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>: UPENDRABHAI VALLABHBHAI ITALIYA</Text>

            <Text style={{ width: '15%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>Invoice Date</Text>
            <Text style={{ width: '25%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>: 02/04/2024</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>Address</Text>
            <Text style={{ width: '1%', fontWeight: 'bold', fontSize: '9px', textAlign: 'left', alignSelf: 'flex-start' }}>: </Text>
            <Text style={{ width: '45%', fontSize: '9px', textAlign: 'left' }}>B/202, RADHE RESIDENCY,NR, MANISHA GHARNALA,UTRAN</Text>
            <Text style={{ width: '44%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }} />
          </View>
          <View style={styles.tableRow}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>City</Text>
            <Text style={{ width: '25%', fontSize: '10px', textAlign: 'left' }}>: SURAT</Text>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>Dist</Text>
            <Text style={{ width: '15%', fontSize: '10px', textAlign: 'left' }}>: SURAT</Text>
            <Text style={{ width: '15%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>Dealer Code</Text>
            <Text style={{ width: '25%', fontSize: '10px', textAlign: 'left' }}>: GJ050005</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>Pin Code</Text>
            <Text style={{ width: '15%', fontSize: '10px', textAlign: 'left' }}>: 394105</Text>
            <Text style={{ width: '20%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>Place of Supply</Text>
            <Text style={{ width: '15%', fontSize: '10px', textAlign: 'left' }}>: GUJARAT</Text>
            <Text style={{ width: '15%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>Document No</Text>
            <Text style={{ width: '25%', fontSize: '10px', textAlign: 'left' }}>: 016962</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>Mobile No</Text>
            <Text style={{ width: '25%', fontSize: '10px', textAlign: 'left' }}>: 9979452056</Text>
            <Text style={{ width: '25%', fontSize: '10px', textAlign: 'left' }}> 9712551053</Text>
            <Text style={{ width: '40%', fontSize: '10px', textAlign: 'left' }} />
          </View>
          <View style={styles.tableRow}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>HP</Text>
            <Text style={{ width: '60%', fontSize: '10px', textAlign: 'left' }}>: NO HYPO</Text>
            <Text style={{ width: '30%', fontSize: '10px', textAlign: 'left' }} />
          </View>
          <View style={styles.tableRow}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '10px', textAlign: 'left' }}>Branch</Text>
            <Text style={{ width: '60%', fontSize: '10px', textAlign: 'left' }}>: HYPO BRANCH NAME</Text>
            <Text style={{ width: '30%', fontSize: '10px', textAlign: 'left' }} />
          </View>
          <View style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between', borderBottom: '1px solid black', borderTop: '1px solid black', textAlign: 'center'
          }}>
            <Text style={{ width: '100%', fontWeight: 'bold', fontSize: '11px', textAlign: 'center', height: '15px' }}>DESCRIPTION</Text>
          </View>
          <View style={{ ...styles.tableRow, paddingTop: 5 }}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '9px', textAlign: 'left' }}>Make By</Text>
            <Text style={{ width: '50%', fontSize: '9px', textAlign: 'left' }}>: HONDA MOTORCYCLE & SCOOTER INDIA PVT.LTD</Text>
            <Text style={{ width: '15%', fontWeight: 'bold', fontSize: '9px', textAlign: 'left' }}>Colour</Text>
            <Text style={{ width: '25%', fontSize: '9px', textAlign: 'left' }}>: MAT AXIS GRAY METALL</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '9px', textAlign: 'left' }}>Model</Text>
            <Text style={{ width: '50%', fontSize: '9px', textAlign: 'left' }}>: ACTIVA DLX SCV110P8ID BSVI</Text>
            <Text style={{ width: '15%', fontWeight: 'bold', fontSize: '9px', textAlign: 'left' }}>Key No</Text>
            <Text style={{ width: '25%', fontSize: '9px', textAlign: 'left' }}>: MAT AXIS GRAY METALL</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '9px', textAlign: 'left' }}>Frame No</Text>
            <Text style={{ width: '50%', fontSize: '9px', textAlign: 'left' }}>: ME4JK156CRW601637</Text>
            <Text style={{ width: '15%', fontWeight: 'bold', fontSize: '9px', textAlign: 'left' }}>FSC No</Text>
            <Text style={{ width: '25%', fontSize: '9px', textAlign: 'left' }}>: 3434</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '9px', textAlign: 'left' }}>Engine No</Text>
            <Text style={{ width: '50%', fontSize: '9px', textAlign: 'left' }}>: JK15EW5601634</Text>
            <Text style={{ width: '15%', fontWeight: 'bold', fontSize: '9px', textAlign: 'left' }}>Salesman</Text>
            <Text style={{ width: '25%', fontSize: '9px', textAlign: 'left' }}>: PRIYANSHI A.U</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '9px', textAlign: 'left' }}>Battery No</Text>
            <Text style={{ width: '50%', fontSize: '9px', textAlign: 'left' }}>: 50111313C42641</Text>
            <Text style={{ width: '15%', fontWeight: 'bold', fontSize: '9px', textAlign: 'left' }}>Bluetooth</Text>
            <Text style={{ width: '25%', fontSize: '9px', textAlign: 'left' }}>: No</Text>
          </View>
          <View style={{ ...styles.tableRow, marginTop: 5, marginBottom: 0, paddingBottom: 0, paddingTop: 0, borderTop: '1px solid black', textAlign: 'center' }}>
            <Text style={{ width: '3%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', borderRight: '1px solid black', height: '15px', padding: 2 }}>SR.</Text>
            <Text style={{ width: '22%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left', borderRight: '1px solid black', height: '15px', padding: 2 }}>Description</Text>
            <Text style={{ width: '8%', fontWeight: 'bold', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>Basic</Text>
            <Text style={{ width: '6%', fontWeight: 'bold', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>Disc.</Text>
            <Text style={{ width: '8%', fontWeight: 'bold', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>Taxable</Text>
            <Text style={{ width: '6%', fontWeight: 'bold', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>GST%</Text>
            <Text style={{ width: '8%', fontWeight: 'bold', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>CGST</Text>
            <Text style={{ width: '8%', fontWeight: 'bold', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>SGST</Text>
            <Text style={{ width: '8%', fontWeight: 'bold', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>IGST</Text>
            <Text style={{ width: '7%', fontWeight: 'bold', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>Cess%</Text>
            <Text style={{ width: '7%', fontWeight: 'bold', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>CESS</Text>
            <Text style={{ width: '10%', fontWeight: 'bold', fontSize: '8px', textAlign: 'right', height: '15px', padding: 2 }}>Total</Text>
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 0, paddingBottom: 0, paddingTop: 0, borderTop: '1px solid black', textAlign: 'center' }}>
            <Text style={{ width: '3%', fontSize: '8px', textAlign: 'left', borderRight: '1px solid black', height: '15px', padding: 2 }}>1</Text>
            <Text style={{ width: '22%', fontSize: '8px', textAlign: 'left', borderRight: '1px solid black', height: '15px', padding: 2 }}>Veh.HSN : 87112019</Text>
            <Text style={{ width: '8%', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>59464</Text>
            <Text style={{ width: '6%', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }} />
            <Text style={{ width: '8%', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>59464</Text>
            <Text style={{ width: '6%', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>28.00</Text>
            <Text style={{ width: '8%', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>8325.00</Text>
            <Text style={{ width: '8%', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>8325.00</Text>
            <Text style={{ width: '8%', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>8325.00</Text>
            <Text style={{ width: '7%', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }} />
            <Text style={{ width: '7%', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }} />
            <Text style={{ width: '10%', fontSize: '8px', textAlign: 'right', height: '15px', padding: 2 }}>76114</Text>
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 0, paddingBottom: 0, paddingTop: 0, borderTop: '1px solid black', textAlign: 'center' }}>
            <Text style={{ width: '3%', fontSize: '8px', textAlign: 'left', }} />
            <Text style={{ width: '22%', fontSize: '8px', textAlign: 'left', borderRight: '1px solid black', height: '15px', padding: 2 }} />
            <Text style={{ width: '8%', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>59464</Text>
            <Text style={{ width: '6%', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }} />
            <Text style={{ width: '8%', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>59464</Text>
            <Text style={{ width: '6%', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>28.00</Text>
            <Text style={{ width: '8%', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>8325.00</Text>
            <Text style={{ width: '8%', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>8325.00</Text>
            <Text style={{ width: '8%', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }}>8325.00</Text>
            <Text style={{ width: '7%', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }} />
            <Text style={{ width: '7%', fontSize: '8px', textAlign: 'right', borderRight: '1px solid black', height: '15px', padding: 2 }} />
            <Text style={{ width: '10%', fontSize: '8px', textAlign: 'right', height: '15px', padding: 2 }}>76114</Text>
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 0, borderTop: '1px solid black', textAlign: 'center' }}>
            <Text style={{ width: '100%', height: '20px', fontWeight: 'bold', fontSize: '10px', textAlign: 'right' }} >
              WITH MUNICIPAL TAX
            </Text>
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 0, borderBottom: '1px solid black', textAlign: 'center' }}>
            <Text style={{ width: '100%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left' }} >
              Rs. Seventy six thousand one hundred fourteen only
            </Text>
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 0, marginTop: 3, textAlign: 'center' }}>
            <Text style={{ width: '100%', fontWeight: 'bold', fontSize: '8px', textAlign: 'left' }} >
              TERMS & CONDITION :
            </Text>
          </View>
          {
            terms.map((x, index) => <View style={{ ...styles.tableRow, marginBottom: 0, textAlign: 'center', paddingTop: index === 0 ? 3 : 0, paddingBottom: index === terms.length - 1 ? 5 : 0 }}>
              <Text style={{ width: '3%', fontSize: '7px', textAlign: 'left' }} >
                ({index + 1})
              </Text>
              <Text style={{ width: '97%', fontSize: '7px', textAlign: 'left' }} >
                {x}
              </Text>
            </View>)
          }
          <View style={{ ...styles.tableRow, paddingTop: 2, marginBottom: 0, borderTop: '1px solid black', textAlign: 'center' }}>
            <Text style={{ width: '100%', fontWeight: 'bold', fontSize: '10px', textAlign: 'right' }} >
              For AMIDEEP AUTOMOBILES
            </Text>
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 0, textAlign: 'center' }}>
            <Text style={{ width: '80%', fontSize: '7.5px', textAlign: 'right', height: '30px' }} />
            <Text style={{ width: '20%', fontSize: '7.5px', textAlign: 'right', height: '30px', borderBottom: '1px solid black', marginBottom: 5 }} />
          </View>
          <View style={{ ...styles.tableRow, paddingTop: 0, marginBottom: 0, textAlign: 'center' }}>
            <Text style={{ width: '60%', fontSize: '8px', textAlign: 'right' }} >
              02/04/2024  16:51:00
            </Text>
            <Text style={{ width: '40%', fontWeight: 'bold', fontSize: '10px', textAlign: 'right' }} >
              Authorized Signatory
            </Text>
          </View>
          <View style={{ ...styles.tableRow, paddingTop: 2, marginBottom: 0, textAlign: 'center' }}>
            <Text style={{ width: '100%', fontWeight: 'bold', fontSize: '10px', textAlign: 'center' }} >
              STATEMENT OF ACCOUNTS
            </Text>
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 1, marginTop: 1, borderTop: '1px solid black', borderBottom: '1px solid black', textAlign: 'center' }}>
            <Text style={{ width: '10%', fontSize: '9px', textAlign: 'left', }}>BOOK</Text>
            <Text style={{ width: '10%', fontSize: '9px', textAlign: 'left', }}>DATE</Text>
            <Text style={{ width: '40%', fontSize: '9px', textAlign: 'left', }}>DESCRIPTION</Text>
            <Text style={{ width: '15%', fontSize: '9px', textAlign: 'right', }}>DEBIT AMT</Text>
            <Text style={{ width: '15%', fontSize: '9px', textAlign: 'right' }}>CREDIT AMT</Text>
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 1, marginTop: 1, textAlign: 'center' }}>
            <Text style={{ width: '10%', fontSize: '9px', textAlign: 'left', }}>VSALE</Text>
            <Text style={{ width: '10%', fontSize: '9px', textAlign: 'left', }}>02/04/2024</Text>
            <Text style={{ width: '40%', fontSize: '9px', textAlign: 'left', }}>VEHICLE AMOUNT</Text>
            <Text style={{ width: '15%', fontSize: '9px', textAlign: 'right', }}>76,114.00</Text>
            <Text style={{ width: '15%', fontSize: '9px', textAlign: 'right' }} />
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 1, marginTop: 1, textAlign: 'center' }}>
            <Text style={{ width: '10%', fontSize: '9px', textAlign: 'left', }}>VSALE</Text>
            <Text style={{ width: '10%', fontSize: '9px', textAlign: 'left', }}>02/04/2024</Text>
            <Text style={{ width: '40%', fontSize: '9px', textAlign: 'left', }}>VEHICLE AMOUNT</Text>
            <Text style={{ width: '15%', fontSize: '9px', textAlign: 'right', }}>76,114.00</Text>
            <Text style={{ width: '15%', fontSize: '9px', textAlign: 'right' }} />
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 1, marginTop: 1, textAlign: 'center' }}>
            <Text style={{ width: '10%', fontSize: '9px', textAlign: 'left', }}>VSALE</Text>
            <Text style={{ width: '10%', fontSize: '9px', textAlign: 'left', }}>02/04/2024</Text>
            <Text style={{ width: '40%', fontSize: '9px', textAlign: 'left', }}>VEHICLE AMOUNT</Text>
            <Text style={{ width: '15%', fontSize: '9px', textAlign: 'right', }}>76,114.00</Text>
            <Text style={{ width: '15%', fontSize: '9px', textAlign: 'right' }} />
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 1, marginTop: 1, textAlign: 'center' }}>
            <Text style={{ width: '10%', fontSize: '9px', textAlign: 'left', }}>VSALE</Text>
            <Text style={{ width: '10%', fontSize: '9px', textAlign: 'left', }}>02/04/2024</Text>
            <Text style={{ width: '40%', fontSize: '9px', textAlign: 'left', }}>VEHICLE AMOUNT</Text>
            <Text style={{ width: '15%', fontSize: '9px', textAlign: 'right', }}>76,114.00</Text>
            <Text style={{ width: '15%', fontSize: '9px', textAlign: 'right' }} />
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 1, marginTop: 1, textAlign: 'center' }}>
            <Text style={{ width: '10%', fontSize: '9px', textAlign: 'left', }}>VSALE</Text>
            <Text style={{ width: '10%', fontSize: '9px', textAlign: 'left', }}>02/04/2024</Text>
            <Text style={{ width: '40%', fontSize: '9px', textAlign: 'left', }}>VEHICLE AMOUNT</Text>
            <Text style={{ width: '15%', fontSize: '9px', textAlign: 'right', }}>76,114.00</Text>
            <Text style={{ width: '15%', fontSize: '9px', textAlign: 'right' }} />
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 1, marginTop: 1, textAlign: 'center' }}>
            <Text style={{ width: '10%', fontSize: '9px', textAlign: 'left', }}>VSALE</Text>
            <Text style={{ width: '10%', fontSize: '9px', textAlign: 'left', }}>02/04/2024</Text>
            <Text style={{ width: '40%', fontSize: '9px', textAlign: 'left', }}>VEHICLE AMOUNT</Text>
            <Text style={{ width: '15%', fontSize: '9px', textAlign: 'right', }}>76,114.00</Text>
            <Text style={{ width: '15%', fontSize: '9px', textAlign: 'right' }} />
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 1, marginTop: 1, borderBottom: '1px solid black', textAlign: 'center' }}>
            <Text style={{ width: '85%', fontSize: '9px', textAlign: 'left', }}>NET AMOUNT RECEIVED...</Text>
            <Text style={{ width: '15%', fontSize: '9px', textAlign: 'right' }}>89,800.00</Text>
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 1, marginTop: 1, textAlign: 'center' }}>
            <Text style={{ width: '10%', fontSize: '9px', textAlign: 'left' }} />
            <Text style={{ width: '10%', fontSize: '9px', textAlign: 'left' }} />
            <Text style={{ width: '40%', fontSize: '9px', textAlign: 'left' }} />
            <Text style={{ width: '15%', fontSize: '9px', textAlign: 'right' }}>89,800.00</Text>
            <Text style={{ width: '15%', fontSize: '9px', textAlign: 'right' }}>89,800.00</Text>
          </View>
          <View style={{ ...styles.tableRow, marginBottom: 1, marginTop: 1, textAlign: 'center' }}>
            <Text style={{ width: '85%', fontSize: '9px', textAlign: 'center', }}>== BALANCE SETTLED ==</Text>
            <Text style={{ width: '15%', fontSize: '9px', textAlign: 'right' }}> 0.00 </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

SaleInvoicePDF.propTypes = {
  invoice: PropTypes.object,
};