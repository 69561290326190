import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import keyBy from 'lodash/keyBy';
import { useMemo } from 'react';
// utils
import { fetcher, endpoints } from 'src/utils/axios';
import queryString from 'query-string';

// ----------------------------------------------------------------------

export function useGetFuels(query) {
  let URL = 'automobile/fuels?';
  if (query?.value) {
    URL += `name=${query.value}`;
  }
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  const memoizedValue = useMemo(
    () => ({
      rows: data?.data.rows || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.data.count,
      totalPage: data?.data.totalPage,
    }),
    [data?.data.count, data?.data.rows, data?.data.totalPage, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetChasformates() {
  const URL = 'automobile/chasformates';

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  const memoizedValue = useMemo(
    () => ({
      rows: data?.data.rows || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.data.count,
      totalPage: data?.data.totalPage,
    }),
    [data?.data.count, data?.data.rows, data?.data.totalPage, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetSeatings() {
  const URL = 'automobile/seatings';

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  const memoizedValue = useMemo(
    () => ({
      rows: data?.data.rows || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.data.count,
      totalPage: data?.data.totalPage,
    }),
    [data?.data.count, data?.data.rows, data?.data.totalPage, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetAxles() {
  const URL = 'automobile/axles';

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  const memoizedValue = useMemo(
    () => ({
      rows: data?.data.rows || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.data.count,
      totalPage: data?.data.totalPage,
    }),
    [data?.data.count, data?.data.rows, data?.data.totalPage, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetEmistandards() {
  let URL = 'automobile/emistandards';

  URL += `?page=${1}&limit=${10000}&status=true&`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  const memoizedValue = useMemo(
    () => ({
      rows: data?.data.rows || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.data.count,
      totalPage: data?.data.totalPage,
    }),
    [data?.data.count, data?.data.rows, data?.data.totalPage, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetBodies() {
  const URL = 'automobile/bodies';

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  const memoizedValue = useMemo(
    () => ({
      rows: data?.data.rows || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.data.count,
      totalPage: data?.data.totalPage,
    }),
    [data?.data.count, data?.data.rows, data?.data.totalPage, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetClasses() {
  const URL = 'automobile/classes';

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  const memoizedValue = useMemo(
    () => ({
      rows: data?.data.rows || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.data.count,
      totalPage: data?.data.totalPage,
    }),
    [data?.data.count, data?.data.rows, data?.data.totalPage, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetStartes() {
  const URL = 'automobile/startes';

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  const memoizedValue = useMemo(
    () => ({
      rows: data?.data.rows || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.data.count,
      totalPage: data?.data.totalPage,
    }),
    [data?.data.count, data?.data.rows, data?.data.totalPage, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetTovehicle() {
  const URL = 'automobile/tovehicle';

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  const memoizedValue = useMemo(
    () => ({
      rows: data?.data.rows || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.data.count,
      totalPage: data?.data.totalPage,
    }),
    [data?.data.count, data?.data.rows, data?.data.totalPage, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetVehnainrto() {
  const URL = 'automobile/vehnainrto';

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const memoizedValue = useMemo(
    () => ({
      rows: data?.data.rows || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.data.count,
      totalPage: data?.data.totalPage,
    }),
    [data?.data.count, data?.data.rows, data?.data.totalPage, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetPurTypes(query) {
  let URL = 'automobile/purtypes/?';
  if (query) {
    URL += queryString.stringify(query);
  }
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const memoizedValue = useMemo(
    () => ({
      rows: data?.data.rows || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.data.count,
      totalPage: data?.data.totalPage,
    }),
    [data?.data.count, data?.data.rows, data?.data.totalPage, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetSources(query) {
  let URL = 'automobile/enquiries/sources/?';
  if (query) {
    URL += queryString.stringify(query);
  }
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const memoizedValue = useMemo(
    () => ({
      rows: data?.data.rows || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.data.count,
      totalPage: data?.data.totalPage,
    }),
    [data?.data.count, data?.data.rows, data?.data.totalPage, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetCategories(query) {
  let URL = 'automobile/enquiries/categories/?';
  if (query) {
    URL += queryString.stringify(query);
  }
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const memoizedValue = useMemo(
    () => ({
      rows: data?.data.rows || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.data.count,
      totalPage: data?.data.totalPage,
    }),
    [data?.data.count, data?.data.rows, data?.data.totalPage, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetReasones(query) {
  let URL = 'automobile/reasones/?';
  if (query?.value) {
    URL += `name=${query.value}`;
  }
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const memoizedValue = useMemo(
    () => ({
      rows: data?.data.rows || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.data.count,
      totalPage: data?.data.totalPage,
    }),
    [data?.data.count, data?.data.rows, data?.data.totalPage, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetFinances(query) {
  let URL = 'automobile/finances/?';
  if (query?.value) {
    URL += `name=${query.value}`;
  }
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const memoizedValue = useMemo(
    () => ({
      rows: data?.data.rows || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.data.count,
      totalPage: data?.data.totalPage,
    }),
    [data?.data.count, data?.data.rows, data?.data.totalPage, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetZones(query) {
  let URL = 'automobile/zones/?';
  if (query?.value) {
    URL += `name=${query.value}`;
  }
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const memoizedValue = useMemo(
    () => ({
      rows: data?.data.rows || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.data.count,
      totalPage: data?.data.totalPage,
    }),
    [data?.data.count, data?.data.rows, data?.data.totalPage, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetEnquiryById(id) {
  const URL = `automobile/enquiries/${id}`;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateFirstPage: false,
  });
  console.log(data);
  const memoizedValue = useMemo(
    () => ({
      row: data?.data || {},
      isLoading,
      error,
      isValidating,
    }),
    [data?.data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetVehicles(query) {
  let URL = 'automobile/vehicles';
  if (query?.value) {
    URL += `name=${query.value}`;
  }
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const memoizedValue = useMemo(
    () => ({
      rows: data?.data.rows || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.data.count,
      totalPage: data?.data.totalPage,
    }),
    [data?.data.count, data?.data.rows, data?.data.totalPage, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetCommon(payload) {
  // Create a stable getKey function using useMemo
  const getKey = useMemo(
    () => (pageIndex, previousPageData) => {
      if (previousPageData && !previousPageData.data.rows) {
        return null;
      }
      // Stop fetching if we reached the end of the list
      if (previousPageData && !previousPageData.data.rows.length) return null;
      // Generate the API endpoint for the next page
      return `${payload.URL}?${queryString.stringify({
        ...payload.query,
        page: pageIndex + 1, // pageIndex is 0-indexed, API is likely 1-indexed
      })}`;
      // Ensure dependencies are stable to prevent re-creation of the function
    },
    [payload?.URL, payload?.query]
  );
  const { data, error, size, setSize, isValidating } = useSWRInfinite(getKey, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateFirstPage: false,
  });

  // Aggregate and flatten data from all pages
  const rows = data ? data.flatMap((page) => page.data.rows) : [];
  const isLoading = !data && !error;
  const isEmpty = data?.[0]?.data?.rows.length === 0;
  const totalPage = data?.[0]?.data?.totalPage || 0;

  // Load more function
  // const loadMore = () => setSize(size + 1);

  return {
    rows,
    isLoading,
    error,
    isValidating,
    isEmpty,
    totalPage,
    size, // The current page size
    setSize, // Method to manually set the page size
  };
}
