import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import moment from 'moment';
import Iconify from 'src/components/iconify';

export default function YearTableRow({ row, index, selected, setRow, user }) {
  const { sdate, edate } = row;

  const startDate = moment(sdate);
  const endDate = moment(edate);

  // Format the dates in the desired format
  const formattedStartDate = startDate.format('DD/MM/YYYY');
  const formattedEndDate = endDate.format('DD/MM/YYYY');

  const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;

  return (
    <>
      <TableRow
        hover
        selected={selected}
        onClick={() => {
          setRow(row.id);
        }}
        sx={{ cursor: 'pointer' }}
      >
        <TableCell>{index}</TableCell>
        <TableCell>{formattedDateRange}</TableCell>
        <TableCell style={{ padding: '0px' }}>
          {user.co_yearId === row.id && (
            <Iconify icon="eva:checkmark-circle-2-fill" sx={{ color: 'primary.main' }} />
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

YearTableRow.propTypes = {
  row: PropTypes.object,
  index: PropTypes.any,
  selected: PropTypes.bool,
  setRow: PropTypes.func,
  user: PropTypes.object,
};
