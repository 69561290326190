import React, { useState, useCallback, useEffect, useContext } from 'react';
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Importerrors from 'src/layouts/_common/importerrors';
import Importloaders from 'src/layouts/_common/importloaders';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import queryString from 'query-string';
import { useSnackbar } from 'src/components/snackbar';
import { useDebounce } from 'src/hooks/use-debounce';
import NotFound from 'src/sections/error/notfound-invoice';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';
import { TableRowsLoader, UPermission } from 'src/_mock/constant_funcation';
import { AuthContext } from 'src/auth/context/jwt';
import { fetcher, destroy } from 'src/utils/axios';
import { useResponsive } from 'src/hooks/use-responsive';
import { Box, Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { HONDA_CATEGORY } from 'src/_mock/constant';
import Label from 'src/components/label';
import { isEqual } from 'lodash';
import { useEventListener } from 'src/hooks/use-event-listener';
import useSWR from 'swr';
import UserTableRow from '../table-row';
import UserTableToolbar from '../table-toolbar';
import UserTableFiltersResult from '../table-filters-result';
import UserQuickEditForm from '../quick-edit-form';
import JobcardRedyQuickEditForm from '../jobcard-redy-form';
import JobCardList from '../jobcard-list';
// ----------------------------------------------------------------------

const STATUS_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'DeActive' },
];

const TABLE_HEAD = [
  { id: 'no', label: 'Job No.', width: 90 },
  { id: 'name', label: 'Name' },
  { id: 'mobile1', label: 'Mobile' },
  { id: 'regNo', label: 'Reg. No.' },
  { id: 'chassisNo', label: 'Chassis No' },
  { id: 'enginNo', label: 'Engine No' },
  { id: 'status', label: 'Status', width: 100 },
  { id: '', label: 'Action', width: 90 },
];

const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
};

const TABS = [
  {
    value: 'today_jobcards',
    label: `TODAY'S JOB CARDS`,
  },
  {
    value: 'mtd_job_cards',
    label: 'MTD JOB CARDS',
  },
];

// ----------------------------------------------------------------------

export default function ListView() {
  const table = useTable();
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();
  const [progress, setProgress] = useState({ status: 'done' });
  const { user, socket } = useContext(AuthContext);
  const [tableData, setTableData] = useState([]);
  const [jbstatus, setJBStatusData] = useState([]);
  const [serviceTypesData, setServiceTypesData] = useState([]);
  const [servicedealtrData, setServicedealtrData] = useState([]);
  const [rempList, setREMPList] = useState([]);
  const [supervisorList, setSupervisorList] = useState([]);
  const [finalIList, setFinalIList] = useState([]);
  const [mechanicList, setMechanicList] = useState([]);
  const [complaintCode, setComplaintCode] = useState([]);
  const [fuellevels, setfuellevels] = useState([]);
  const [sourcetypes, setsourcetypes] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [zoneDataList, setZoneDataList] = useState([]);
  const [colourList, setColourList] = useState([]);
  const [count, setCount] = useState(0);
  const permission = UPermission('component', 'JobCardEntryListPage');
  const [filters, setFilters] = useState(defaultFilters);

  const debouncedQuery = useDebounce(filters);

  const canReset = !isEqual(defaultFilters, filters);

  const [row, setRow] = useState(null);
  const [enquiryDashboard, setEnquiryDashboard] = useState(null);
  const [mainCurrentTab, setMainCurrentTab] = useState('enquiry');
  const [currentTab, setCurrentTab] = useState('today_jobcards');
  const [enqstatusData, setEnqstatusData] = useState('E,Q');

  const lgUp = useResponsive('down', 'md');

  const navigate = useNavigate();

  const notFoundData = count === 0;

  const { page } = table;
  const limit = table.rowsPerPage;

  const getAll = useCallback(async () => {
    try {
      let URL = '/automobile/jobcards/';
      URL += `?page=${page + 1}&limit=${limit}&`;
      if (Object.keys(debouncedQuery).length) {
        const nefil = { ...debouncedQuery };
        if (debouncedQuery.status === 'all') {
          delete nefil.status;
        }
        if (debouncedQuery.branchId === 'all') {
          delete nefil.branchId;
        }
        URL += queryString.stringify(nefil);
      }

      const { data } = await fetcher(URL);
      if (data) {
        setCount(data?.count);
        setTableData(data?.rows);
      }
    } catch (error) {
      console.error(error);
    }
  }, [debouncedQuery, limit, page]);
  const ServicedealtrModule = useCallback(async () => {
    try {
      let URL = 'automobile/accounts';
      URL += `?page=${1}&limit=${10000}&status=true&ac_groupId=66`;
      const { data } = await fetcher(URL);
      if (data) {
        setServicedealtrData(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  const ServiceTypes = useCallback(async () => {
    try {
      let URL = 'automobile/servicetypes';
      URL += `?page=${1}&limit=${1000}&status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setServiceTypesData(
          data?.rows
            ?.filter((y) => y.childrens.length === 0)
            ?.map((x) => ({
              ...x,
              name: x.servicetype?.name ? `${x.servicetype?.name} > ${x.name}` : x.name,
            }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  const jbcstatuses = useSWR('/automobile/jbcstatuses/?page=1&limit=100', fetcher, {
    revalidateOnFocus: true,
    shouldRetryOnError: true,
  });
  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleDeleteRows = useCallback(
    async (id) => {
      try {
        let URL = '/automobile/jobcards/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          getAll();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [enqueueSnackbar, getAll]
  );
  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);
  const AutomobileUserModule = useCallback(async () => {
    try {
      {

        const URL = `users?page=${1}&limit=${1000}&status=true&companyId=${user?.companyId}&departmentId=13&roleId=33&`;
        // roleId = 7;
        const { data } = await fetcher(URL);
        if (data) {
          setREMPList(data?.rows);
        }
      }
      {

        const URL = `users?page=${1}&limit=${1000}&status=true&companyId=${user?.companyId}&departmentId=13&roleId=34&`;
        // roleId = 7;
        const { data } = await fetcher(URL);
        if (data) {
          setSupervisorList(data?.rows);
        }
      }
      {
        const URL = `users?page=${1}&limit=${1000}&status=true&companyId=${user?.companyId}&departmentId=13&roleId=35&`;
        // roleId = 7;
        const { data } = await fetcher(URL);
        if (data) {
          setMechanicList(data?.rows);
        }
      }
      {
        const URL = `users?page=${1}&limit=${1000}&status=true&companyId=${user?.companyId}&departmentId=13&roleId=36&`;
        // roleId = 7;
        const { data } = await fetcher(URL);
        if (data) {
          setFinalIList(data?.rows);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [user?.companyId]);
  const complaintCodeList = useCallback(async () => {
    try {
      const URL = 'automobile/complaintcodes';
      const { data } = await fetcher(URL);
      if (data) {
        setComplaintCode(data?.rows || []);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  const sourcesList = useCallback(async () => {
    try {
      const URL = 'automobile/sourcetypes';
      const { data } = await fetcher(URL);
      if (data) {
        setsourcetypes(data?.rows || []);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  const fuellevelsList = useCallback(async () => {
    try {
      const URL = 'automobile/fuellevels';
      const { data } = await fetcher(URL);
      if (data) {
        setfuellevels(data?.rows || []);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  const YearList = useCallback(async () => {
    try {
      let URL = 'co_years';
      URL += `?page=${1}&limit=${10000}&status=true&companyId=${user?.companyId}&`;
      const { data } = await fetcher(URL);
      if (data) {
        setYearData(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, [user?.companyId]);

  const colourListData = useCallback(async () => {
    try {
      let URL = 'automobile/product/colours/';
      URL += `?page=${1}&limit=${10000}&status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setColourList(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  const ZoneListData = useCallback(async () => {
    try {
      let URL = 'automobile/zones';
      URL += `?page=${1}&limit=${10000}&status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setZoneDataList(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  useEffect(() => {
    getAll();
    ServiceTypes();
    AutomobileUserModule();
    ServicedealtrModule();
    complaintCodeList();
    sourcesList();
    fuellevelsList();
    YearList();
    colourListData();
    ZoneListData();
  }, [AutomobileUserModule, ServiceTypes, ServicedealtrModule, YearList, ZoneListData, colourListData, complaintCodeList, fuellevelsList, getAll, handleDeleteRows, sourcesList]);

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const quickEdit = useBoolean();
  const quickJBEdit = useBoolean();

  const handleKeyDown = (event) => {
    if (event.altKey && event.key.toLowerCase() === 'c') {
      event.stopPropagation();
      setRow(null);
      quickEdit.onTrue();
    }
  };
  const onImportError = useBoolean();
  const so = useCallback(() => {
    socket?.on('progress', (data) => {
      console.log('jobcards', data)
      if ('jobcards' in data && data.jobcards.status === 'error') {
        setProgress(data.jobcards);
        onImportError.setValue(true);
      }
      if ('jobcards' in data && data.jobcards.status === 'done') {
        setProgress(data.jobcards);
      } else if ('jobcards' in data && data.jobcards.error) {

        // setProgress(data.error);
      } else if ('jobcards' in data) {
        setProgress(data.jobcards);

      }
    });
  }, [onImportError, socket])
  useEffect(() => so(), [so, socket])
  useEventListener('keydown', handleKeyDown);

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {!lgUp && (
          <>
            <CustomBreadcrumbs
              heading="Job Card List"
              links={[{ name: 'Dashboard', href: paths.home }, { name: 'List' }]}
              action={
                <Tooltip title="(Alt + C)" arrow>
                  <Button
                    component={RouterLink}
                    variant="contained"
                    startIcon={<Iconify icon="mingcute:add-line" />}
                    onClick={quickEdit.onTrue}
                  >
                    Job Card
                  </Button>
                </Tooltip>
              }
              sx={{
                mb: { xs: 3, md: 5 },
              }}
            />
            <Card>
              <Tabs
                value={filters.status}
                onChange={handleFilterStatus}
                sx={{
                  px: 2.5,
                  boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {STATUS_OPTIONS.map((tab) => (
                  <Tab key={tab.value} iconPosition="end" value={tab.value} label={tab.label} />
                ))}
              </Tabs>

              <UserTableToolbar filters={filters} onFilters={handleFilters} getAll={getAll} permission={permission} branches={user.branches || []} />

              {canReset && (
                <UserTableFiltersResult
                  filters={filters}
                  onFilters={handleFilters}
                  //
                  onResetFilters={handleResetFilters}
                  //
                  results={count}
                  sx={{ p: 2.5, pt: 0 }}
                />
              )}

              <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                <Scrollbar sx={{ maxHeight: 400 }}>
                  <Table
                    stickyHeader
                    size={table.dense ? 'small' : 'medium'}
                    sx={{ minWidth: 960 }}
                  >
                    <TableHeadCustom
                      order={table.order}
                      orderBy={table.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData.length}
                      numSelected={table.selected.length}
                      onSort={table.onSort}
                      onSelectAllRows={(checked) =>
                        table.onSelectAllRows(
                          checked,
                          tableData.map((row) => row.id)
                        )
                      }
                    />

                    <TableBody>
                      {tableData.map((row, index) => (
                        <UserTableRow
                          finalIList={finalIList || []}
                          servicedealtrData={servicedealtrData || []}
                          serviceTypesData={serviceTypesData || []}
                          mechanicList={mechanicList || []}
                          complaintCode={complaintCode || []}
                          fuellevels={fuellevels || []}
                          yearData={yearData || []}
                          zoneDataList={zoneDataList || []}
                          colourList={colourList || []}
                          sourcetypes={sourcetypes || []}
                          supervisorList={supervisorList || []}
                          rempList={rempList || []}
                          jbcstatuses={jbcstatuses?.data?.data?.rows || []}
                          tableData={tableData}
                          key={row.id}
                          row={row}
                          index={page * limit + index + 1}
                          selected={table.selected.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRows(row.id)}
                          table={{ table }}
                          getAll={getAll}
                        />
                      ))}

                      <TableNoData notFound={notFoundData} />
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>

              <TablePaginationCustom
                count={count}
                page={table.page}
                rowsPerPage={table.rowsPerPage}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
                dense={table.dense}
                onChangeDense={table.onChangeDense}
              />
            </Card>
          </>
        )}

        {lgUp && (
          <>
            {' '}
            <JobCardList
              enquiryDashboard={enquiryDashboard}
              setEnqstatusData={setEnqstatusData}
              enqstatusData={enqstatusData}
            />
            <Tabs value={currentTab} onChange={handleChangeTab} variant="fullWidth">
              {TABS.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
            {currentTab === 'today_jobcards' && (
              <Grid container xs={12} sx={{ mt: 2 }}>
                {tableData.length ? (
                  tableData.map((data, index) => (
                    <Grid container item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} mt={2}>
                      <Paper
                        sx={{
                          borderRadius: 2,
                          position: 'relative',
                          bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
                          boxShadow: 2,
                        }}
                      >
                        <Grid container item xs={12} sm={12} sx={{ p: 1 }}>
                          <Grid container item xs={12} sm={12}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              sx={{
                                cursor: 'pointer',
                                justifyContent: 'space-between',
                                display: 'flex',
                              }}
                            >
                              <Stack
                                direction="column"
                                alignItems="right"
                                spacing={1}
                                sx={{ color: 'text.secondary', typography: 'caption' }}
                              >
                                {data?.regNo}
                              </Stack>

                              <Stack
                                direction="column"
                                alignItems="right"
                                spacing={1}
                                sx={{
                                  color: 'text.secondary',
                                  typography: 'caption',
                                  bgcolor: 'background.neutral',
                                  px: 1,
                                  borderRadius: 2,
                                }}
                              >
                                Job No. {data?.identity}
                              </Stack>
                            </Grid>
                          </Grid>
                          <Grid container item xs={12} sm={12}>
                            <Stack
                              direction="column"
                              alignItems="right"
                              spacing={1}
                              sx={{ color: 'text.secondary', typography: 'caption' }}
                            >
                              {data?.chassisNo}
                            </Stack>
                          </Grid>
                          <Grid container item xs={12} sm={12}>
                            <Typography variant="subtitle2">
                              {data?.customer?.firstname} {data?.customer?.lastname}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            sx={{ justifyContent: 'space-between' }}
                          >
                            <Grid item xs={6} sm={6} md={6} sx={{ cursor: 'pointer' }}>
                              <Typography variant="subtitle2" color="primary.dark">
                                {data?.customer?.mobile1}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={6}
                              sx={{ cursor: 'pointer', textAlign: 'end' }}
                            >
                              <Stack
                                direction="column"
                                alignItems="right"
                                spacing={1}
                                sx={{ color: 'text.secondary', typography: 'caption' }}
                              >
                                {data?.product?.name}
                              </Stack>
                            </Grid>
                          </Grid>
                          <Grid container item xs={12} sm={12}>
                            <Grid item xs={6} sm={6} md={6} sx={{ cursor: 'pointer' }}>
                              <Stack
                                direction="column"
                                alignItems="right"
                                spacing={1}
                                sx={{ color: 'text.secondary', typography: 'caption' }}
                              >
                                {data?.servicetype?.name}
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={6}
                              sx={{ cursor: 'pointer', textAlign: 'end' }}
                            >
                              <Stack
                                direction="column"
                                alignItems="right"
                                spacing={1}
                                sx={{ color: 'text.secondary', typography: 'caption' }}
                              >
                                {data?.expdelidate}
                              </Stack>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12} sm={12}>
                            <Stack
                              direction="row"
                              alignItems="right"
                              spacing={1}
                              mt={1}
                              sx={{ color: 'text.secondary', typography: 'caption' }}
                            >
                              {false && '-'}
                            </Stack>
                          </Grid>

                          <Grid container item xs={12} sm={12}>
                            <Grid item xs={9}>
                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{ color: 'text.secondary', typography: 'caption' }}
                              >
                                <Stack direction="row" alignItems="center">
                                  <Iconify
                                    icon="solar:calendar-date-bold"
                                    width={16}
                                    sx={{ mr: 0.5 }}
                                  />
                                  {data?.createdAt}
                                </Stack>
                              </Stack>
                            </Grid>
                            <Grid item xs={3}>
                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                  color: 'text.secondary',
                                  typography: 'caption',
                                  display: 'block',
                                  textAlign: 'end',
                                }}
                              >
                                <Label variant="soft" color="warning">
                                  {data?.currentkm} Km
                                </Label>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={6}
                    xxl={6}
                    sx={{ display: 'block' }}
                  >
                    <NotFound name="Data" />
                  </Grid>
                )}
              </Grid>
            )}
            {currentTab === 'mtd_job_cards' && (
              <Grid container xs={12} sx={{ mt: 2 }}>
                {tableData.length ? (
                  tableData.map((data, index) => (
                    <Grid container item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} mt={2}>
                      <Paper
                        sx={{
                          borderRadius: 2,
                          position: 'relative',
                          bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
                          boxShadow: 2,
                        }}
                      >
                        <Grid container item xs={12} sm={12} sx={{ p: 1 }}>
                          <Grid container item xs={12} sm={12}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              sx={{
                                cursor: 'pointer',
                                justifyContent: 'space-between',
                                display: 'flex',
                              }}
                            >
                              <Stack
                                direction="column"
                                alignItems="right"
                                spacing={1}
                                sx={{ color: 'text.secondary', typography: 'caption' }}
                              >
                                {data?.regNo}
                              </Stack>

                              <Stack
                                direction="column"
                                alignItems="right"
                                spacing={1}
                                sx={{
                                  color: 'text.secondary',
                                  typography: 'caption',
                                  bgcolor: 'background.neutral',
                                  px: 1,
                                  borderRadius: 2,
                                }}
                              >
                                Job No. {data?.identity}
                              </Stack>
                            </Grid>
                          </Grid>
                          <Grid container item xs={12} sm={12}>
                            <Stack
                              direction="column"
                              alignItems="right"
                              spacing={1}
                              sx={{ color: 'text.secondary', typography: 'caption' }}
                            >
                              {data?.chassisNo}
                            </Stack>
                          </Grid>
                          <Grid container item xs={12} sm={12}>
                            <Typography variant="subtitle2">
                              {data?.customer?.firstname} {data?.customer?.lastname}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            sx={{ justifyContent: 'space-between' }}
                          >
                            <Grid item xs={6} sm={6} md={6} sx={{ cursor: 'pointer' }}>
                              <Typography variant="subtitle2" color="primary.dark">
                                {data?.customer?.mobile1}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={6}
                              sx={{ cursor: 'pointer', textAlign: 'end' }}
                            >
                              <Stack
                                direction="column"
                                alignItems="right"
                                spacing={1}
                                sx={{ color: 'text.secondary', typography: 'caption' }}
                              >
                                {data?.product?.name}
                              </Stack>
                            </Grid>
                          </Grid>
                          <Grid container item xs={12} sm={12}>
                            <Grid item xs={6} sm={6} md={6} sx={{ cursor: 'pointer' }}>
                              <Stack
                                direction="column"
                                alignItems="right"
                                spacing={1}
                                sx={{ color: 'text.secondary', typography: 'caption' }}
                              >
                                {data?.servicetype?.name}
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={6}
                              sx={{ cursor: 'pointer', textAlign: 'end' }}
                            >
                              <Stack
                                direction="column"
                                alignItems="right"
                                spacing={1}
                                sx={{ color: 'text.secondary', typography: 'caption' }}
                              >
                                {data?.expdelidate}
                              </Stack>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12} sm={12}>
                            <Stack
                              direction="row"
                              alignItems="right"
                              spacing={1}
                              mt={1}
                              sx={{ color: 'text.secondary', typography: 'caption' }}
                            >
                              {false && '-'}
                            </Stack>
                          </Grid>

                          <Grid container item xs={12} sm={12}>
                            <Grid item xs={9}>
                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{ color: 'text.secondary', typography: 'caption' }}
                              >
                                <Stack direction="row" alignItems="center">
                                  <Iconify
                                    icon="solar:calendar-date-bold"
                                    width={16}
                                    sx={{ mr: 0.5 }}
                                  />
                                  {data?.createdAt}
                                </Stack>
                              </Stack>
                            </Grid>
                            <Grid item xs={3}>
                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                  color: 'text.secondary',
                                  typography: 'caption',
                                  display: 'block',
                                  textAlign: 'end',
                                }}
                              >
                                <Label variant="soft" color="warning">
                                  {data?.currentkm} Km
                                </Label>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={6}
                    xxl={6}
                    sx={{ display: 'block' }}
                  >
                    <NotFound name="Data" />
                  </Grid>
                )}
              </Grid>
            )}
          </>
        )}

        {lgUp && (
          <Box
            sx={{
              position: 'fixed',
              bottom: 25,
              right: 20,
              borderRadius: '50%',
              width: 56,
              height: 56,
              backgroundColor: 'primary.dark',
              color: 'common.white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: 3,
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
              cursor: 'pointer',
            }}
            onClick={() => {
              quickEdit.onTrue();
              setRow(null);
            }}
          >
            <Iconify icon="mingcute:add-line" width={24} height={24} />
          </Box>
        )}
      </Container>

      {quickEdit.value && (
        <UserQuickEditForm
          row={null}
          open={quickEdit.value}
          onClose={quickEdit.onFalse}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}

      {onImportError.value && progress?.status === "error" && (
        <Importerrors
          row={progress}
          open={onImportError.value}
          onClose={onImportError.onFalse}
        />
      )}
      {!['error', 'done'].includes(progress.status) && <Importloaders progress={progress} />}
    </>
  );
}
