import PropTypes from 'prop-types';
// @mui
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { Grid } from '@mui/material';
import SummaryTable from './SummaryTable';
import ReactDataTable from './ReactDataTable';

export default function ListView({ reportsDetails }) {
  const settings = useSettingsContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={reportsDetails?.name}
        links={[
          { name: 'Dashboard', href: paths.home },
          {
            name: reportsDetails?.name || 'Report',
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Card sx={{ p: 2 }}>
        <Grid container>
          KISHAN
          {/* <ReactDataTable /> */}
          <SummaryTable />
        </Grid>
      </Card>
    </Container>
  );
}

ListView.propTypes = {
  reportsDetails: PropTypes.object,
};
