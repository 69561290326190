import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { KEY } from 'src/_mock/constant';
import { Divider, Grid, IconButton, Toolbar, Tooltip, Typography, alpha } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import { Stack } from '@mui/system';
// ----------------------------------------------------------------------

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();

  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(false);

  const StateSchema = Yup.object().shape({
    countryCode: Yup.string().required('Country is required'),
    name: Yup.string().required('Name is required'),
    isoCode: Yup.string().required('Country Code is required'),
    latitude: Yup.number().required('Latitude is required'),
    longitude: Yup.number().required('Longitude is required'),
  });

  const values = useMemo(
    () => ({
      name: row?.name || '',
      countryCode: row?.countryCode || '',
      isoCode: row?.isoCode || '',
      latitude: row?.latitude || '',
      longitude: row?.longitude || '',
      status: row?.status || false,
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(StateSchema),
    values,
  });

  const { reset, trigger, handleSubmit } = methods;

  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      let URL = 'states/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
      setLoading(false);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const HandleCountryList = async () => {
    let URL = 'countries/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setCountryList(data?.rows);
  };

  useEffect(() => {
    if (open) HandleCountryList();
  }, [open]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 850 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            top: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Toolbar>
            <Typography variant="h5" sx={{ flex: 1 }}>
              {row ? 'Update State' : 'Add State'}
            </Typography>

            <IconButton color="inherit" edge="start" onClick={onClose} tabIndex={-1}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>

        <Grid container spacing={2} sx={{ p: 3 }}>
          <Grid item container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
              <RHFSelect name="countryCode" label="Select Country" size="small">
                {countryList?.map((data) => (
                  <MenuItem key={data.name} value={data.isoCode}>
                    {data.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
              <RHFTextField name="name" label="State Name" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
              <RHFTextField name="isoCode" label="ISO Code" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
              <RHFTextField name="latitude" label="Latitude" size="small" type="number" />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
              <RHFTextField name="longitude" label="Longitude" size="small" type="number" />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFCheckbox name="status" label="Active" size="small" />
            </Grid>
          </Grid>
        </Grid>

        <Stack
          direction="row-reverse"
          alignItems="center"
          sx={{
            bottom: 0,
            zIndex: 111111,
            p: 3,
            position: 'sticky',
            width: '100%',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
          id="modelfooter"
        >
          <Tooltip title="(Alt + A)" arrow>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={async () => {
                if (await trigger()) {
                  onSubmit();
                }
              }}
            >
              {row ? 'Update' : 'Add'}
            </LoadingButton>
          </Tooltip>

          <Tooltip title="(Esc)" arrow>
            <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
          </Tooltip>

          <Button
            variant="soft"
            onClick={() => {
              reset(values);
            }}
            sx={{ mr: 2 }}
          >
            Reset
          </Button>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
