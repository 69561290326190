import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { handleOnWheelScroll, handleRowClick } from 'src/_mock/constant_funcation';

export default function UserTableRow({
  popover,
  selectedRow,
  setSelectedRow,
  quickEdit,
  setEditRow,
  tableData,
  row,
  index,
}) {
  console.log(row, 'row');
  return (
    <TableRow
      selected={selectedRow === index}
      onDoubleClick={() => {
        window.$accLedList = 'In';
        setEditRow(row);
        quickEdit.onTrue();
        popover.onClose();
      }}
      key={index}
      rowIndex={index}
      onClick={() => handleRowClick(index, setSelectedRow, selectedRow)}
      tabIndex={0}
      onWheel={(e) =>
        handleOnWheelScroll(e, setSelectedRow, selectedRow, tableData.length + 1, 1, 'out')
      }
    >
      <TableCell>{index}</TableCell>
      <TableCell>{row?.name || '-'}</TableCell>
      <TableCell sx={{ textAlign: 'right' }}>{row?.openingBalance ? row?.openingBalance?.toFixed(2) : '-'}</TableCell>
      <TableCell sx={{ textAlign: 'right' }}>{row?.openingBalance ? row?.creditTotal?.toFixed(2) || 0 : '-'}</TableCell>
      <TableCell sx={{ textAlign: 'right' }}>{row?.openingBalance ? row?.debitTotal?.toFixed(2) || 0 : '-'}</TableCell>
      <TableCell sx={{ textAlign: 'right' }}>{row?.closingBalance ? row?.closingBalance?.toFixed(2) : '-'}</TableCell>
    </TableRow>
  );
}

UserTableRow.propTypes = {
  popover: PropTypes.object,
  selectedRow: PropTypes.object,
  setSelectedRow: PropTypes.func,
  setEditRow: PropTypes.func,
  quickEdit: PropTypes.object,
  row: PropTypes.object,
  index: PropTypes.number,
  tableData: PropTypes.array,
};
