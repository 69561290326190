import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { fMoney, formatDate } from 'src/_mock/constant_funcation';
import { CASH_DEBIT, INVOI_TYPE, Tax_BilSupply } from 'src/_mock/constant';

export default function UserTableRow({
  setEditRow,
  tableData,
  row,
  index,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  table,
  getAll,
  quickEdit,
  quickView,
  permission,
}) {
  const confirm = useBoolean();

  const popover = usePopover();

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ textAlign: 'right' }}>{row?.v_no ? row.v_no : '-'}</TableCell>
        <TableCell sx={{ textAlign: 'right' }}>{row?.jobcardNo ? row.jobcardNo : '-'}</TableCell>
        <TableCell sx={{ textAlign: 'right' }}>{row?.branch?.short_name ? row.branch?.short_name : '-'}</TableCell>
        <TableCell sx={{ textAlign: 'right' }}>{row?.trtype ? row.trtype : '-'}</TableCell>
        <TableCell sx={{ textAlign: 'right' }}>{row?.department ? row.department : '-'}</TableCell>
        <TableCell>{row?.dt ? formatDate(row.dt) : '-'}</TableCell>
        <TableCell>{INVOI_TYPE?.find((x) => x.id === row?.t_type)?.name || '-'}</TableCell>
        <TableCell>{row?.c_d ? CASH_DEBIT.find((x) => x.id === row?.c_d).name : '-'}</TableCell>
        <TableCell>{row.entry_details.find((x) => x.order === 1)?.account?.name || '-'}</TableCell>
        <TableCell>{row.entry_details.find((x) => x.order === 2)?.account?.name || '-'}</TableCell>
        <TableCell>
          {row?.tax_bi_Id ? Tax_BilSupply.find((x) => x.id === row.tax_bi_Id)?.name : '-'}
        </TableCell>
        <TableCell sx={{ textAlign: 'right' }}>
          {row?.amount ? fMoney.format(row?.amount, { symbol: '₹ ' }) : '-'}
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          {permission?.u && (
            <Tooltip title="Quick Edit" placement="top" arrow>
              <IconButton
                color={quickEdit.value ? 'inherit' : 'default'}
                onClick={() => {
                  setEditRow(row);
                  quickEdit.setValue(true);
                }}
              >
                <Iconify icon="solar:pen-bold" />
              </IconButton>
            </Tooltip>
          )}
          {(permission?.u || permission?.d) && (
            <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          )}
        </TableCell>
      </TableRow>

      {/* <UserQuickEditForm
        tableData={tableData}
        row={row}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
        table={table}
        getAll={getAll}
      /> */}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {permission?.d && (
          <MenuItem
            onClick={() => {
              confirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon="solar:trash-bin-trash-bold" />
            Delete
          </MenuItem>
        )}

        {permission?.u && (
          <MenuItem
            onClick={() => {
              setEditRow(row);
              quickEdit.onTrue();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:pen-bold" />
            Edit
          </MenuItem>
        )}
        {permission?.u && (
          <MenuItem
            onClick={() => {
              setEditRow(row);
              quickView.onTrue();
              popover.onClose();
            }}
          >
            <Iconify icon="zondicons:view-show" />
            View
          </MenuItem>
        )}
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

UserTableRow.propTypes = {
  quickEdit: PropTypes.any,
  quickView: PropTypes.any,
  setEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  index: PropTypes.number,
  selected: PropTypes.bool,
  table: PropTypes.object,
  permission: PropTypes.object,
  getAll: PropTypes.func,
  tableData: PropTypes.array,
};
