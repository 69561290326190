import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import {
  BALANCE_TYPE,
  BANK_CASH,
  BANK_R_P,
  CITY,
  KEY,
  MODULE_STATUS,
  OPP_AC,
  RCPT_PYMT,
  STATE,
  TITLES,
} from 'src/_mock/constant';
import {
  Autocomplete,
  Card,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { alpha, spacing } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import { useEventListener } from 'src/hooks/use-event-listener';
// ----------------------------------------------------------------------

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();

  const [paymentOptionList, setPaymentOptionList] = useState([]);
  const [accountList, setAccountList] = useState([]);

  const BankCashPaymentSchema = Yup.object().shape({
    v_no: Yup.number().nullable().min(0),
    amount: Yup.number().nullable().min(0),
    narr: Yup.string().required('Narration is required'),
  });

  const values = useMemo(
    () => ({
      v_no: row?.v_no || null,
      f_ac: row?.f_ac || null,
      s_ac: row?.s_ac || null,
      rp: row?.rp || 2,
      amount: row?.amount || null,
      dt: row?.dt || new Date(),
      t_date: row?.t_date || new Date(),
      bpoptionId: row?.bpoptionId || null,
      ac_no: row?.ac_no || null,
      narr: row?.narr || '',
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(BankCashPaymentSchema),
    values,
  });

  const {
    reset,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isSubmitting },
  } = methods;

  const allValues = watch();

  const onSubmit = handleSubmit(async (payload) => {
    try {
      let URL = 'cbentries/';
      payload = { ...payload, t_type: 1 };
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };
  useEventListener('keydown', handleKeyDown);

  const HandlePaymentOptionList = async () => {
    let URL = 'bpoptions/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setPaymentOptionList(data?.rows);
  };

  const HandleAccountList = async () => {
    let URL = 'accounts/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setAccountList(data?.rows);
  };

  useEffect(() => {
    HandlePaymentOptionList();
    HandleAccountList();
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 1120 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid
          container
          sx={{
            top: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Grid item xs={12} md={12}>
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row
                  ? `Update Bank ${allValues.rp === 2 ? 'Receipt' : 'Payment'} `
                  : `Create  Bank ${allValues.rp === 2 ? 'Receipt' : 'Payment'} `}
              </Typography>

              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>

            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>
        </Grid>

        <Grid item container xs={12} md={12} sx={{ p: 3 }} columnSpacing={8} spacing={4}>
          <Grid item container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={accountList?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={accountList?.find((data) => data.id === allValues.f_ac)?.name || ''}
                onChange={(event, newValue) => {
                  setValue('f_ac', accountList?.find((data) => data.name === newValue)?.id);
                }}
                renderInput={(params) => (
                  <TextField {...params} name="f_ac" label="Bank A/C." autoFocus />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                display="flex"
                justifyContent="space-between"
              >
                <h5>Balance</h5>
                <h5>{accountList?.find((data) => data.id === allValues.f_ac)?.op_bal} DB</h5>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} spacing={1}>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              spacing={2}
              display="flex"
              justifyContent="end"
              alignItems="center"
              alignContent="start"
            >
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField name="v_no" label="Voucher No." size="small" />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <Controller
                  name="dt"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      defaultValue={new Date(field.value)}
                      timezone="Asia/Kolkata"
                      label="Voucher Date"
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      format="dd/MM/yyyy"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                          size: 'small',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <RHFSelect name="rp" label="Rcpt/Pymt" size="small">
                  {BANK_R_P.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={accountList?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={accountList?.find((data) => data.id === allValues.s_ac)?.name || ''}
                onChange={(event, newValue) => {
                  setValue('s_ac', accountList?.find((data) => data.name === newValue)?.id);
                }}
                renderInput={(params) => <TextField {...params} name="s_ac" label="Opp. A/c" />}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                display="flex"
                justifyContent="space-between"
              >
                <h5>Balance</h5>
                <h5>{accountList?.find((data) => data.id === allValues.s_ac)?.op_bal} DB</h5>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
            spacing={2}
            display="flex"
            justifyContent="end"
            alignItems="center"
            alignContent="start"
          >
            <Grid item xs={10.9} sm={10.9} md={5} lg={5} xl={5} xxl={5}>
              <RHFTextField name="amount" label="Bank Amount" size="small" />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
              <Typography fontSize={14} variant="button" sx={{ flex: 1 }}>
                CR
              </Typography>
            </Grid>
          </Grid>

          <Grid item container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} spacing={1}>
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={4} xxl={4}>
                <RHFSelect name="bpoptionId" label="Payment Option" size="small">
                  {paymentOptionList.map((status) => (
                    <MenuItem key={status.id} value={status.id}>
                      {status.name}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={4} xxl={4}>
                <Controller
                  name="t_date"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      defaultValue={new Date(field.value)}
                      timezone="Asia/Kolkata"
                      label="Payment Date"
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      format="dd/MM/yyyy"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                          size: 'small',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={8} xl={8} xxl={8}>
                <RHFTextField
                  name="ac_no"
                  label={`${paymentOptionList?.find((data) => data.id === allValues.bpoptionId)?.name || ''
                    } No.`}
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
            spacing={1}
            justifyContent="end"
            alignItems="flex-start"
          >
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
              <RHFTextField name="narr" label="Narration" size="small" multiline rows={3} />
            </Grid>
          </Grid>
        </Grid>

        <DialogActions
          sx={{
            bottom: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {row ? 'Update' : 'Add'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
