// @mui
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
// components
import Scrollbar from 'src/components/scrollbar';
import { TableNoData, TablePaginationCustom } from 'src/components/table';
import { useEffect, useState } from 'react';
import { logData } from 'src/_mock/constant';
import { Avatar, Button } from '@mui/material';
import { HOST_API } from 'src/config-global';
import { useBoolean } from 'src/hooks/use-boolean';
import { TableRowsLoader } from 'src/_mock/constant_funcation';

export default function LogTableSearch({
  tableData,
  tableName,
  table,
  count,
  notFound,
  originalId,
  logDetails,
  isLoading,
}) {
  const [COLUMNS, setColumns] = useState([]);

  useEffect(() => {
    if (tableData.length > 0) {
      const columns = Object.keys(tableData[0])
        .filter((x) => logData[tableName][x]?.label)
        .map((x) => logData[tableName][x])
        .sort((a, b) => a.order - b.order);
      setColumns(columns);
    }
  }, [tableData, tableName]);

  return (
    <>
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar sx={{ maxHeight: '60vh' }}>
          <Table stickyHeader sx={{ minWidth: 800 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ p: 0.5, textAlign: 'center' }}>No.</TableCell>
                {COLUMNS.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ top: 0, minWidth: column.minWidth }}
                    sx={{ p: 0.5 }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRowsLoader rowsNum={table.rowsPerPage} colNum={COLUMNS.length + 1} />
              ) : (
                <>
                  {tableData.map((row, index) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      <TableCell sx={{ p: 0.3, minWidth: 40, textAlign: 'center' }}>
                        {table.page * table.rowsPerPage + index + 1}
                      </TableCell>
                      {COLUMNS.map((column) => {
                        const value = row[column.id];

                        const isChange = () => {
                          if (!tableData[index + 1]) return false;
                          const va1 = column?.valueGetter ? column?.valueGetter(row) : value || '-';
                          const va2 = column?.valueGetter
                            ? column.valueGetter(tableData[index + 1])
                            : tableData[index + 1][column.id] || '-';

                          return va1 !== va2;
                        };

                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              p: 0.3,
                              display: column?.image && 'inline-flex',
                              alignItems: column?.image && 'center',
                              color:
                                originalId &&
                                column?.id !== 'operationTimestamp' &&
                                isChange() &&
                                'red',
                            }}
                          >
                            {column?.image && (
                              <Avatar
                                alt="Remy Sharp"
                                src={`${HOST_API}uploads/medium/${column.image(row)}`}
                                sx={{ marginRight: '10px', width: '20px', height: '20px' }}
                              />
                            )}
                            {column?.isNested ? (
                              <Button
                                variant="outlined"
                                color="inherit"
                                onClick={() => {
                                  console.log(logDetails);
                                  logDetails?.setLogId(row?.id);
                                }}
                              >
                                Open
                              </Button>
                            ) : (
                              <>{column.valueGetter ? column.valueGetter(row) : value || '-'}</>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                  <TableNoData notFound={notFound} />
                </>
              )}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={count}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
      />
    </>
  );
}

LogTableSearch.propTypes = {
  tableData: PropTypes.array,
  table: PropTypes.any,
  notFound: PropTypes.any,
  originalId: PropTypes.any,
  logDetails: PropTypes.any,
  count: PropTypes.number,
  tableName: PropTypes.string,
  isLoading: PropTypes.bool,
};
