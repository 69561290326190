import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { KEY, RECEIPTTYPE } from 'src/_mock/constant';
import {
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Paper,
  TableFooter,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import { Stack } from '@mui/system';
import { DateField } from '@mui/x-date-pickers';
import {
  fMoney,
  formatDate,
  onScollerAutoComplate,
  useBgColorAutocomplete,
} from 'src/_mock/constant_funcation';
import { useDebounce } from 'src/hooks/use-debounce';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { handleScroll } from 'src/layouts/_common/scroller';
import { CashManagementModal } from '../../../hooks/use-cashcounter';

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const userInitiatedFocusRef = useRef(true); // ref to track focus source

  const [accountList, setAccountList] = useState([]);

  const [paymentOptionList, setPaymentOptionList] = useState([]);
  const [banksOptionList, setBanksOptionList] = useState([]);

  const [customerAllData, setCustomerAllData] = useState({});

  const otherReceiptAmount = customerAllData?.others
    ?.map((x) => x.amount)
    ?.reduce((a, b) => a + b, 0);

  const [isLoading, setisLoading] = useState(false);

  const bgColorAutocomplete = useBgColorAutocomplete();

  const ReceiptsSchema = Yup.object().shape({
    t_type: Yup.number().required(),
    accountId: Yup.number().required('Name is required'),
    amount: Yup.number().min(1).required('Amount is required'),
  });

  const values = useMemo(
    () => ({
      id: row?.id || null,
      name: row?.name || '',
      date: row?.date || new Date(),
      t_type: row?.t_type || null,
      accountId: row?.entrydetails?.find((x) => x.cd === 2).accountId || 0,
      amount: row?.amount || 0,
      narr: row?.narr || '',
      accountMeta: {
        page: 1,
        name: '',
        totalPage: 1,
        editRow: null,
      },
      entrydetails: row?.entrydetails?.map((x) => ({
        cd: x.cd,
        accountId: x.accountId,
        amount: x.amount,
        order: x.order,
      })) || [
        {
          cd: 1,
          accountId: 14,
          amount: null,
          order: 1,
        },
        {
          cd: 2,
          accountId: null,
          amount: null,
          order: 2,
        },
      ],
      cashcounters: [
        { cash: 500, Payment: 0, Refund: 0 },
        { cash: 200, Payment: 0, Refund: 0 },
        { cash: 100, Payment: 0, Refund: 0 },
        { cash: 50, Payment: 0, Refund: 0 },
        { cash: 20, Payment: 0, Refund: 0 },
        { cash: 10, Payment: 0, Refund: 0 },
        { cash: 5, Payment: 0, Refund: 0 },
        { cash: 2, Payment: 0, Refund: 0 },
        { cash: 1, Payment: 0, Refund: 0 },
      ].map((x) => {
        const data = row?.cashcounters?.find((y) => y.cash === x.cash);
        return {
          cash: x.cash,
          Payment: data?.Payment || 0,
          Refund: data?.Refund || 0,
        };
      }) || [
        { cash: 500, Payment: 0, Refund: 0 },
        { cash: 200, Payment: 0, Refund: 0 },
        { cash: 100, Payment: 0, Refund: 0 },
        { cash: 50, Payment: 0, Refund: 0 },
        { cash: 20, Payment: 0, Refund: 0 },
        { cash: 10, Payment: 0, Refund: 0 },
        { cash: 5, Payment: 0, Refund: 0 },
        { cash: 2, Payment: 0, Refund: 0 },
        { cash: 1, Payment: 0, Refund: 0 },
      ],
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(ReceiptsSchema),
    values,
    mode: 'onChange',
  });

  const {
    reset,
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    formState: { isSubmitting, errors },
  } = methods;

  const allValues = watch();

  const onSubmit = handleSubmit(async (payload) => {
    payload.cashcounters = payload.cashcounters.filter(
      (item) => item.Payment !== 0 || item.Refund !== 0
    );

    setLoading(true);
    try {
      let URL = 'automobile/entries/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
      setLoading(false);
    }
  });

  const handleFocus = () => {
    if (userInitiatedFocusRef.current) {
      setModalOpen(true);
    }
    // Reset ref after checking
    userInitiatedFocusRef.current = false;
  };

  const GetCustomerData = useCallback(
    async (debouncedCode) => {
      try {
        if (debouncedCode > 0) {
          setisLoading(true);
          const URL = `automobile/accounts/${debouncedCode}`;
          const { data } = await fetcher(URL);
          if (data?.id) {
            if (data?.bookingId) {
              setCustomerAllData(data?.booking);
            } else {
              setCustomerAllData(data);
            }
            setValue('name', data?.name);
            setValue('accountMeta.name', data?.name);
          } else {
            enqueueSnackbar('Incorrect Code!', {
              variant: 'info',
            });
            setCustomerAllData([]);
            setValue('name', '');
          }
          setisLoading(false);
        } else {
          setCustomerAllData([]);
          setValue('name', '');
        }
        setisLoading(false);
      } catch (error) {
        console.error(error);
        setisLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [enqueueSnackbar, setValue]
  );

  useEffect(() => {
    setValue(`entrydetails[1].accountId`, allValues?.accountId);
  }, [allValues?.accountId, setValue]);

  const debouncedCode = useDebounce(allValues?.accountId, 500);

  useEffect(() => {
    if (debouncedCode) {
      GetCustomerData(debouncedCode);
    } else {
      setCustomerAllData([]);
      setValue('name', '');
      setValue('accountMeta.name', '');
    }
  }, [GetCustomerData, debouncedCode, setValue]);

  const HandlePaymentOptionList = async () => {
    let URL = 'bpoptions/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setPaymentOptionList(data?.rows);
  };

  const HandleBanksList = async () => {
    let URL = 'banks/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setBanksOptionList(data?.rows);
  };

  const debouncedPartyAccount = useDebounce(allValues.accountMeta.name);

  const HandleAccountList = useCallback(
    async (partyAccount) => {
      let URL = 'automobile/accounts/';
      URL += `?page=${allValues.accountMeta.page}&limit=${15}&status=true&creditcard=false&`;
      if (partyAccount) URL += `name=${partyAccount}&`;
      const { data } = await fetcher(URL);
      if (data) {
        setValue('name', data?.name);
        onScollerAutoComplate({
          data,
          fieldName: 'accountMeta',
          meta: allValues.accountMeta,
          setList: setAccountList,
          setValue,
        });
      }
    },
    [allValues.accountMeta, setValue]
  );

  useEffect(() => {
    if (!allValues.accountId && debouncedPartyAccount) HandleAccountList(debouncedPartyAccount);
  }, [HandleAccountList, allValues.accountId, allValues.accountMeta.page, debouncedPartyAccount]);

  useEffect(() => {
    HandlePaymentOptionList();
    HandleBanksList();
    HandleAccountList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset();
  }, [open, reset]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  const handleUpdateCashCounter = (updatedCashCounter) => {
    setValue('cashcounters', updatedCashCounter);
    setModalOpen(false);
  };

  return (
    <>
      <Dialog fullScreen open={open} onKeyDown={handleKeyDown}>
        <FormProvider methods={methods}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update Receipt' : 'Add Receipt'}
              </Typography>

              <Grid container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    id="receipt"
                    size="small"
                    options={RECEIPTTYPE?.filter((x) => x.value <= 6)
                      ?.sort((a, b) => a.order - b.order)
                      .map((option) => option.label)}
                    onBlur={(e) => {
                      if (!allValues.t_type) {
                        document.getElementById(e.target.id).focus();
                      }
                    }}
                    autoFocus
                    openOnFocus
                    getOptionLabel={(option) => option}
                    value={
                      RECEIPTTYPE?.find((data) => data.value === allValues.t_type)?.label || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        't_type',
                        RECEIPTTYPE?.find((data) => data.label === newValue)?.value
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="t_type"
                        label="Select Receipt"
                        autoFocus
                        error={errors?.t_type && !allValues?.t_type}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    disabled={row?.id}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
              </Grid>

              <IconButton color="inherit" edge="start" onClick={onClose} tabIndex={-1}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          <Grid container xs={12} md={12} lg={12} xl={12} xxl={12} sx={{ p: 3 }} spacing={4}>
            <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} alignContent="start">
              <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <RHFTextField
                    name="id"
                    label="Receipt No."
                    size="small"
                    type="number"
                    disabled={row?.id}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <Controller
                    name="date"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <DateField
                        defaultValue={new Date(field.value)}
                        timezone="Asia/Kolkata"
                        label="Receipt Date"
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        format="dd/MM/yyyy"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                            size: 'small',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <RHFTextField
                    name="accountId"
                    label="A/c Code"
                    size="small"
                    type="number"
                    disabled={row?.id}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    textAlign="right"
                    mt={-2}
                  >
                    <h5>
                      {fMoney.format(otherReceiptAmount || 0, {
                        symbol: '₹ ',
                      })}
                    </h5>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <Typography
                    variant="body2"
                    sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                  >
                    Account Name :
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                  <Typography variant="body2" sx={{ flex: 1, fontWeight: '600' }}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      size="small"
                      disableCloseOnSelect
                      options={
                        accountList.length ? accountList : [{ name: 'No Options', value: null }]
                      }
                      groupBy={(option) => option?.a}
                      getOptionLabel={(option) => option.name}
                      filterOptions={(options, state) =>
                        accountList.length ? accountList : [{ name: 'No Options', value: null }]
                      }
                      inputValue={allValues.accountMeta.name}
                      onInputChange={(event, newInputValue) => {
                        setValue('accountMeta', {
                          ...allValues.accountMeta,
                          page: 1,
                          name: newInputValue,
                        });
                      }}
                      onChange={(event, newValue) => {
                        setValue('accountId', newValue?.id);
                        setValue('entrydetails.1.accountId', newValue?.id);
                      }}
                      value={(() => {
                        const addRecord = accountList?.find(
                          (data) => data.id === allValues.accountId
                        );
                        const editRecord = row?.account;

                        if (addRecord) return addRecord;
                        if (editRecord) return editRecord;
                        return null;
                      })()}
                      openOnFocus={!allValues?.accountId}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search Account Name"
                          name="name"
                          variant="standard"
                          disabled={row?.id}
                        />
                      )}
                      disabled={row?.id}
                      renderGroup={(params) => (
                        <li key={params.key}>
                          <GroupHeader component="li">
                            <ListItem sx={{ padding: 0, margin: 0 }}>Account Name</ListItem>
                            <ListItem
                              sx={{ padding: 0, margin: 0, justifyContent: 'end', width: '30%' }}
                            >
                              Mobile
                            </ListItem>
                          </GroupHeader>
                          <GroupItems>{params.children}</GroupItems>
                        </li>
                      )}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                            {option?.name}
                          </ListItem>
                          <ListItem
                            sx={{ padding: 0, margin: 0, justifyContent: 'end', width: '30%' }}
                          >
                            {option?.mobile}
                          </ListItem>
                        </Box>
                      )}
                      componentsProps={{
                        paper: {
                          sx: {
                            width: 600,
                          },
                        },
                      }}
                      ListboxProps={{
                        ...bgColorAutocomplete,
                        style: { maxHeight: '70vh' },
                        onScroll: (event) =>
                          handleScroll({
                            event,
                            allValues,
                            setValue,
                            fieldName: 'entrydetails.0.accountMeta',
                            meta: allValues.accountMeta,
                          }),
                      }}
                    />
                    {/* <RHFTextField
                      name="name"
                      placeholder="Name"
                      size="small"
                      variant="standard"
                      disabled={allValues.accountId}
                    /> */}
                  </Typography>
                </Grid>

                {customerAllData?.customer ? (
                  <>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Typography
                        variant="body2"
                        sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                      >
                        Address :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                      <Typography variant="body2" sx={{ flex: 1, fontWeight: '600' }}>
                        {customerAllData?.customer?.address1 || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Typography
                        variant="body2"
                        sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                      >
                        City :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                      <Typography variant="body2" sx={{ flex: 1, fontWeight: '600' }}>
                        {customerAllData?.customer?.city?.name.toUpperCase() || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Typography
                        variant="body2"
                        sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                      >
                        GST IN :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                      <Typography variant="body2" sx={{ flex: 1, fontWeight: '600' }}>
                        {customerAllData?.gst_no || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Typography
                        variant="body2"
                        sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                      >
                        PAN No. :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                      <Typography variant="body2" sx={{ flex: 1, fontWeight: '600' }}>
                        {customerAllData?.customer?.pan_no || '-'}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Typography
                        variant="body2"
                        sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                      >
                        Address :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                      <Typography variant="body2" sx={{ flex: 1, fontWeight: '600' }}>
                        {customerAllData?.address || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Typography
                        variant="body2"
                        sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                      >
                        City :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                      <Typography variant="body2" sx={{ flex: 1, fontWeight: '600' }}>
                        {customerAllData?.city?.name.toUpperCase() || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Typography
                        variant="body2"
                        sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                      >
                        GST IN :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                      <Typography variant="body2" sx={{ flex: 1, fontWeight: '600' }}>
                        {customerAllData?.gst_no || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Typography
                        variant="body2"
                        sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                      >
                        PAN No. :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                      <Typography variant="body2" sx={{ flex: 1, fontWeight: '600' }}>
                        {customerAllData?.pan_no || '-'}
                      </Typography>
                    </Grid>
                  </>
                )}

                {customerAllData?.customer && (
                  <>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Typography
                        variant="body2"
                        sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                      >
                        Institute :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                      <Typography variant="body2" sx={{ flex: 1, fontWeight: '600' }}>
                        {customerAllData?.institutename || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Typography
                        variant="body2"
                        sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                      >
                        Finances :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                      <Typography variant="body2" sx={{ flex: 1, fontWeight: '600' }}>
                        {customerAllData?.afinance?.name || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Typography
                        variant="body2"
                        sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                      >
                        Branch :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                      <Typography variant="body2" sx={{ flex: 1, fontWeight: '600' }}>
                        {customerAllData?.financebranch?.name || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Typography
                        variant="body2"
                        sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                      >
                        Product Model :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                      <Typography variant="body2" sx={{ flex: 1, fontWeight: '600' }}>
                        {customerAllData?.amodel?.name || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Typography
                        variant="body2"
                        sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                      >
                        Product Variant :
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                      <Typography variant="body2" sx={{ flex: 1, fontWeight: '600' }}>
                        {customerAllData?.avariant?.name || '-'}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} alignContent="start">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                sx={{ visibility: !customerAllData?.others?.length && 'hidden' }}
              >
                <Typography variant="body2" sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}>
                  Other Receipts
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                sx={{ visibility: !customerAllData?.others?.length && 'hidden' }}
              >
                <TableContainer
                  component={Paper}
                  sx={{ overflow: 'auto', minHeight: '20vh', width: '100%', margin: 'auto' }}
                >
                  <Table
                    stickyHeader
                    aria-label="simple table"
                    sx={{
                      '& .MuiTableCell-sizeMedium': {
                        padding: '0px !important',
                      },
                      '& fieldset': { borderRadius: '0px', border: 'none' },
                      '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                      '& .MuiOutlinedInput-root': { padding: '0px !important' },
                      '& .autoComplete>div': { padding: '0px !important' },
                      '& .MuiTableCell-root': {
                        border: '0.01px solid #e9ecee',
                      },
                      '& .MuiTableCell-head': {
                        padding: '5px !important',
                        borderRight: '1px solid #d0d1d2 !important',
                      },
                      '& .MuiTableCell-footer': {
                        padding: '5px !important',
                      },
                      '& .MuiTableCell-head:last-child': {
                        borderRight: '0px !important',
                      },
                      '&:last-child td': {
                        borderColor: '#e9ecee !important',
                      },
                    }}
                  >
                    <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
                      <TableRow>
                        <TableCell sx={{ width: 20, textAlign: 'center' }}>sr.</TableCell>
                        <TableCell sx={{ width: 40, textAlign: 'center' }}>Date</TableCell>
                        <TableCell sx={{ width: 40, textAlign: 'center' }}>Receipt No.</TableCell>
                        <TableCell sx={{ width: 140, textAlign: 'center' }}>A/c</TableCell>
                        <TableCell sx={{ width: 80, textAlign: 'center' }}>Voucher Type</TableCell>
                        <TableCell sx={{ width: 80, textAlign: 'center' }}>Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customerAllData?.others?.map((item, index) => (
                        <TableRow key={item.id}>
                          <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            {formatDate(item.date)}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{item?.id}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            {item.entrydetails?.find((x) => x.cd === 1)?.account?.name || '-'}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            {RECEIPTTYPE.find((x) => x.value === item?.t_type)?.label || '-'}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            {fMoney.format(item?.amount, {
                              symbol: '₹ ',
                            })}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter
                      sx={{
                        bottom: '0',
                        position: 'sticky',
                        zIndex: '11111',
                      }}
                    >
                      <TableRow>
                        <TableCell sx={{ textAlign: 'center' }} />
                        <TableCell sx={{ textAlign: 'center' }} colSpan="2">
                          <Typography
                            variant="body2"
                            sx={{ flex: 1, fontWeight: '600', textAlign: 'center' }}
                          >
                            Total:{' '}
                            {fMoney.format(customerAllData?.total, {
                              symbol: '₹ ',
                            })}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }} />
                        <TableCell sx={{ textAlign: 'center' }} />

                        <TableCell
                          sx={{
                            position: 'sticky',
                            right: 0,
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ flex: 1, fontWeight: '600', textAlign: 'end' }}
                          >
                            {fMoney.format(otherReceiptAmount || 0, {
                              symbol: '₹ ',
                            })}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                mt={2}
                sx={{ visibility: 'hidden' }}
              >
                <Typography variant="body2" sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}>
                  Vehicle Information
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                mt={1}
                sx={{ visibility: 'hidden' }}
              >
                <Paper
                  variant="outlined"
                  sx={{
                    borderRadius: 2,
                    borderColor: 'divider',
                    borderStyle: 'dashed',
                    bgcolor: 'background.neutral',
                    padding: 1,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item container xs={12} sm={12} md={7} lg={7} xl={7} xxl={7}>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                        <Typography
                          variant="body2"
                          sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                        >
                          Challan No. 000242
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                        <Typography
                          variant="body2"
                          sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                        >
                          Bill No. 000101
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                        <Typography
                          variant="body2"
                          sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                        >
                          Date : 01/01/2024
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                        <Typography
                          variant="body2"
                          sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                        >
                          Date : 01/01/2024
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Typography
                          variant="body2"
                          sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                        >
                          Chassis No. ME4JC85JCRD200913
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={5} lg={5} xl={5} xxl={5}>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Typography
                          variant="body2"
                          sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                        >
                          Product No.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Typography
                          variant="body2"
                          sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                        >
                          LIVO STD-BSVI
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Typography
                          variant="body2"
                          sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                        >
                          Colour :
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Typography
                          variant="body2"
                          sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                        >
                          BLACK
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Typography
                          variant="body2"
                          sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                        >
                          Engine No.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Typography
                          variant="body2"
                          sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                        >
                          JC85ED3290155
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                mt={2}
                spacing={1}
              >
                {allValues?.t_type === 2 && (
                  <Grid item container xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Autocomplete
                        autoHighlight={allValues.isHighligh}
                        fullWidth
                        freeSolo
                        size="small"
                        options={paymentOptionList?.map((option) => option.name)}
                        getOptionLabel={(option) => option}
                        onInputChange={(event, newValue) => {
                          setValue('isHighligh', newValue);
                        }}
                        onBlur={() => {
                          setValue('isHighligh', '');
                        }}
                        openOnFocus
                        // value={
                        //   paymentOptionList?.find(
                        //     (data) => data.id === allValues?.entrydetails[0]?.bankId
                        //   )?.name || ''
                        // }
                        onChange={(event, newValue) => {
                          setValue(
                            'entrydetails[0].accountId',
                            paymentOptionList?.find((data) => data.name === newValue)?.id
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="entrydetails[0].accountId"
                            label="Payment Option"
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight={allValues.isHighligh}
                        fullWidth
                        freeSolo
                        size="small"
                        options={banksOptionList?.map((option) => option.name)}
                        getOptionLabel={(option) => option}
                        onInputChange={(event, newValue) => {
                          setValue('isHighligh', newValue);
                        }}
                        onBlur={() => {
                          setValue('isHighligh', '');
                        }}
                        openOnFocus
                        // value={
                        //   banksOptionList?.find(
                        //     (data) => data.id === allValues?.entrydetails[0]?.bankId
                        //   )?.name || ''
                        // }
                        onChange={(event, newValue) => {
                          setValue(
                            'entrydetails[0].accountId',
                            banksOptionList?.find((data) => data.name === newValue)?.id
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="entrydetails[0].accountId"
                            label="Select Bank"
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <RHFTextField name="no" label="No." size="small" type="number" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Controller
                        name="paymentDate"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <DateField
                            defaultValue={new Date(field.value)}
                            timezone="Asia/Kolkata"
                            label="Payment Date"
                            onChange={(newValue) => {
                              field.onChange(newValue);
                            }}
                            format="dd/MM/yyyy"
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                error: !!error,
                                helperText: error?.message,
                                size: 'small',
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                )}

                {allValues?.t_type !== 2 && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    sx={{ visibility: 'hidden' }}
                  >
                    -
                  </Grid>
                )}

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <Controller
                    name="amount"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Amount"
                        variant="outlined"
                        fullWidth
                        onFocus={allValues?.t_type === 4 && handleFocus}
                        onMouseDown={() => {
                          userInitiatedFocusRef.current = true;
                        }}
                        onKeyDown={() => {
                          userInitiatedFocusRef.current = true;
                        }}
                        onChange={(e) => {
                          if (false || allValues?.t_type === 2) {
                            setValue('amount', e.target.value);
                          }
                        }}
                        error={errors?.amount && !allValues?.amount}
                        size="small"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    textAlign="right"
                    mt={-2}
                  >
                    <h5>
                      {otherReceiptAmount
                        ? // eslint-disable-next-line no-unsafe-optional-chaining
                          fMoney.format(customerAllData?.total - otherReceiptAmount, {
                            symbol: '₹ ',
                          })
                        : fMoney.format(customerAllData?.total || 0, {
                            symbol: '₹ ',
                          })}
                    </h5>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} mt={2}>
                <Typography variant="subtitle2" sx={{ ml: '10px' }}>
                  Remarks
                </Typography>
                <RHFTextField name="narr" size="small" multiline rows={2} />
              </Grid>
            </Grid>
          </Grid>

          <Stack
            direction="row-reverse"
            alignItems="center"
            sx={{
              bottom: 0,
              zIndex: 111111,
              p: 3,
              position: 'fixed',
              width: '100%',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
            id="modelfooter"
          >
            <Tooltip title="(Alt + A)" arrow>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={async () => {
                  if (await trigger()) {
                    onSubmit();
                  }
                }}
              >
                {row ? 'Update' : 'Add'}
              </LoadingButton>
            </Tooltip>

            <Tooltip title="(Esc)" arrow>
              <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
                Cancel
              </Button>
            </Tooltip>

            <Button
              variant="soft"
              onClick={() => {
                reset(values);
                document.getElementById('receipt')?.focus();
              }}
              sx={{ mr: 2 }}
            >
              Reset
            </Button>
          </Stack>
        </FormProvider>
      </Dialog>

      {isLoading && (
        <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}>
          <CircularProgress />
        </Backdrop>
      )}

      {modalOpen && (
        <CashManagementModal
          open={modalOpen}
          cashCounter={values.cashcounters}
          onClose={() => setModalOpen(false)}
          updateCashCounter={handleUpdateCashCounter}
          setNetTotal={(data) => {
            setValue('amount', data);
            setValue(`entrydetails[0].amount`, data);
            setValue(`entrydetails[1].amount`, data);
          }}
        />
      )}
    </>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
