import PropTypes from 'prop-types';
// @mui
import Dialog from '@mui/material/Dialog';

import { Button, Divider, Grid, IconButton, TextField, Toolbar, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import { Icon } from '@iconify/react';
import { useState } from 'react';

export default function SingleCategoryModel({ open, onClose, value }) {
  const [mobileNumber, setMobileNumber] = useState(null);

  return (
    <Dialog fullScreen open={open}>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          top: 0,
          zIndex: 9,
          position: 'sticky',
        }}
      >
        <Toolbar>
          <Typography variant="h5" sx={{ flex: 1 }}>
            {value?.name}
          </Typography>
          <IconButton
            color="inherit"
            edge="start"
            tabIndex={-1}
            onClick={() => {
              onClose();
            }}
          >
            <Iconify icon="mingcute:close-line" />
          </IconButton>
        </Toolbar>
        <Divider sx={{ borderStyle: 'dashed' }} />
      </Grid>

      <Grid item xs={12} md={12} sx={{ p: 3 }}>
        <img src={value?.image} alt="Images" loading="lazy" />
      </Grid>

      <Grid item xs={12} sm={12} md={12} sx={{ p: 1, textAlign: 'center' }}>
        <TextField
          name="mobile"
          placeholder="Mobile no."
          size="small"
          type="text"
          autoFocus
          onChange={(e) => {
            setMobileNumber(e.target.value);
          }}
          variant="standard"
        />

        <Button
          variant="contained"
          color="primary"
          sx={{ ml: 1 }}
          href={`https://wa.me/${mobileNumber}?text=*AMIDEEP HONDA*   Mo.972497XXXX    Check out our brochure: ${value?.viewLink}`}
          target="_blank"
          disabled={mobileNumber?.length !== 10}
        >
          <Icon icon="logos:whatsapp-icon" /> &nbsp; Share
        </Button>
      </Grid>

      {value?.viewLink && (
        <Button href={value?.viewLink} target="_blank" rel="noreferrer">
          Download Brochure
        </Button>
      )}
    </Dialog>
  );
}

SingleCategoryModel.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  value: PropTypes.object,
};
