import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { KEY } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  ListItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import CompanyYear from 'src/layouts/_common/company-year';
import { Stack } from '@mui/system';
import { useBgColorAutocomplete } from 'src/_mock/constant_funcation';

export default function ProductCategoryQuickEditForm({
  row,
  open,
  onClose,
  table,
  getAll,
  tableData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [productVariant, setProductVariant] = useState([]);
  const [productColor, setProductColor] = useState([]);

  const bgColorAutocomplete = useBgColorAutocomplete();

  const mtocProductSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    avariantId: Yup.number().required('Product Model is required'),
    acolourId: Yup.number().required('acolourId is required'),
  });

  const values = useMemo(
    () => ({
      name: row?.name || '',
      avariantId: row?.avariantId || null,
      acolourId: row?.acolourId || null,
      status: row?.status || false,
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(mtocProductSchema),
    values,
    mode: 'onChange',
  });

  const {
    reset,
    trigger,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      let URL = 'automobile/product/mtoc/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      setLoading(false);
      console.error(error);
    }
  });

  const productVariantList = useCallback(async () => {
    try {
      let URL = 'automobile/product/variants/';
      URL += `?page=${1}&limit=${10000}&status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setProductVariant(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const productColorList = useCallback(async () => {
    try {
      let URL = 'automobile/product/colours/';
      URL += `?page=${1}&limit=${10000}&status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setProductColor(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    reset();
  }, [open, reset]);

  useEffect(() => {
    if (open) {
      productColorList();
      productVariantList();
    }
  }, [open, productColorList, productVariantList]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            top: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Toolbar>
            <Typography variant="h5" sx={{ flex: 1 }}>
              {row ? 'Update MTOC (Product)' : 'Add MTOC (Product)'}
            </Typography>
            <IconButton color="inherit" edge="start" onClick={onClose}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>

        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            pt={3}
            pb={3}
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField
              name="name"
              label={
                <span>
                  Name <span style={{ color: 'red' }}>*</span>
                </span>
              }
              size="small"
              autoFocus
            />
            <Autocomplete
              autoHighlight
              fullWidth
              size="small"
              options={productVariant}
              groupBy={(option) => option?.a}
              getOptionLabel={(option) => option.name}
              getOptionDisabled={(option) => option?.value === null}
              openOnFocus
              onChange={(event, newValue) => {
                setValue('avariantId', newValue?.id);
              }}
              value={(() => {
                const addRecord = productVariant?.find((data) => data.id === allValues.avariantId);
                const editRecord = row?.ac_group;

                if (addRecord) return addRecord;
                if (editRecord) return editRecord;
                return null;
              })()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <span>
                      Product Variant <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  name="avariantId"
                  error={errors?.avariantId && !allValues?.avariantId}
                />
              )}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  {...props}
                  key={option.id}
                  sx={{ minHeight: 'auto !important' }}
                >
                  <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                </Box>
              )}
              ListboxProps={{ ...bgColorAutocomplete }}
            />
            <Autocomplete
              autoHighlight
              fullWidth
              size="small"
              options={productColor}
              groupBy={(option) => option?.a}
              getOptionLabel={(option) => option.name}
              getOptionDisabled={(option) => option?.value === null}
              openOnFocus
              onChange={(event, newValue) => {
                setValue('acolourId', newValue?.id);
              }}
              value={(() => {
                const addRecord = productColor?.find((data) => data.id === allValues.acolourId);
                const editRecord = row?.ac_group;

                if (addRecord) return addRecord;
                if (editRecord) return editRecord;
                return null;
              })()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <span>
                      Select Color <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  name="acolourId"
                  error={errors?.acolourId && !allValues?.acolourId}
                />
              )}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  {...props}
                  key={option.id}
                  sx={{ minHeight: 'auto !important' }}
                >
                  <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                </Box>
              )}
              ListboxProps={{ ...bgColorAutocomplete }}
            />
            <RHFCheckbox name="status" label="Active" size="small" />
          </Box>
        </DialogContent>

        <Stack
          direction="row-reverse"
          alignItems="center"
          sx={{
            bottom: 0,
            zIndex: 111111,
            p: 3,
            position: 'sticky',
            width: '100%',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
          id="modelfooter"
        >
          <Tooltip title="(Alt + A)" arrow>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={async () => {
                if (await trigger()) {
                  onSubmit();
                }
              }}
            >
              {row ? 'Update' : 'Add'}
            </LoadingButton>
          </Tooltip>

          <Tooltip title="(Esc)" arrow>
            <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
          </Tooltip>

          <Button
            variant="soft"
            onClick={() => {
              reset(values);
            }}
            sx={{ mr: 2 }}
          >
            Reset
          </Button>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}

ProductCategoryQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
