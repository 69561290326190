import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { create, update } from 'src/utils/axios';

import { KEY, STATUS } from 'src/_mock/constant';
import {
  Card,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { alpha, spacing } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import { useEventListener } from 'src/hooks/use-event-listener';
import { MenuItem } from '@mui/base';
// ----------------------------------------------------------------------

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();

  const [openModelName, setOpenModelName] = useState('');

  const dialog = useBoolean();

  const departmentSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const values = useMemo(
    () => ({
      name: row?.name || '',
      status: row?.status,
      description: row?.description || '',
      balance_method: row?.balance_method || 'Bill To Bill',
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(departmentSchema),
    values,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const onSubmit = handleSubmit(async (payload) => {
    try {
      let URL = 'roles/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const { watch, control, setValue } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'company_banks',
  });

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };
  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 900 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update Labour Rate Information' : 'Create Labour Rate Information'}
              </Typography>
              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>
          <Grid item container xs={12} md={12} sx={{ p: 3 }} columnSpacing={8} spacing={4}>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              spacing={2}
              alignContent="start"
            >
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField name="model_no" label="Model No." size="small" />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField name="labour" label="Labour" size="small" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <DatePicker
                  label="Effective"
                  value={new Date()}
                  // onChange={handleFilterStartDate}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField name="hours" label="Hours" size="small" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField name="warranty" label="Warranty" size="small" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField name="charge" label="Charge" size="small" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DialogActions
          sx={{
            bottom: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {row ? 'Update' : 'Add'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
