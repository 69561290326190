import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers';
import {
  Autocomplete,
  Divider,
  IconButton,
  TextField,
  Toolbar,
  Typography,
  alpha,
} from '@mui/material';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFCheckbox,
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form';
import { create, update } from 'src/utils/axios';
import {
  ASSIGNEDTO,
  BAY,
  CANCELLATION_REASON,
  CITY,
  CUSTOMERCATEGORY,
  DSA_SELF,
  ENQUIRYCATEGORY,
  ENQUIRYSOURCE,
  ENQUIRYTYPE,
  EXCHANGE_AGENT_NAME,
  GENDER,
  INVOICE_FORMAT,
  JOB_CARD_STATUS,
  KEY,
  LABOUR_RATE_LIST,
  MODELCATEGORY,
  MODELNAME,
  MODELVARIANT,
  MODEL_VARIANT,
  MODE_OF_SALES,
  MODULE_STATUS,
  MTOCPRODUCT,
  MTOC_PRODUCT,
  OCCUPATION,
  PAYMENT_MODE,
  PENDING_REASON,
  PURCHASETYPE,
  SERVICE_AT,
  STATE,
  TITLES,
  TRUE_FALSE_DROPDOWN,
  VEHICLEMAKE,
  VEHICLEMODEL,
} from 'src/_mock/constant';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import { useBoolean } from 'src/hooks/use-boolean';
// ----------------------------------------------------------------------

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();

  const [exchangeField, setExchangeField] = useState('');
  const dialog = useBoolean();

  const departmentSchema = Yup.object().shape({
    frame: Yup.string().required('Frame Number is required'),
    engineNo: Yup.string().required('Engine Number is required'),
    current_kms: Yup.string().required('Current KMs is required'),
    vehicle_registration_no: Yup.string().required('Vehicle Register Number is required'),
    job_card: Yup.string().required('Job Card Number is required'),
    contact_phone_number: Yup.string().required('Contact Phone Number is required'),
  });

  const values = useMemo(
    () => ({
      gender: row?.name || '',
      status: row?.status || false,
      description: row?.description || '',
      exchange: row?.exchange || 'false',
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(departmentSchema),
    values,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const allfields = watch();

  const onSubmit = handleSubmit(async (payload) => {
    try {
      let URL = 'roles/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };

  useEventListener('keydown', handleKeyDown);
  const allValues = watch();

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 1300 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update Job Card Detail' : 'Create Job Card Detail'}
              </Typography>

              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>

            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          {/*  customer and vehicle section */}
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            sx={{ m: 1 }}
            spacing={5}
          >
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              spacing={2}
              alignContent="start"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Typography variant="h6" gutterBottom>
                  Job Card Details
                </Typography>
                <Divider sx={{ borderStyle: 'dashed' }} />
              </Grid>

              <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={5}>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  spacing={2}
                  alignContent="start"
                >
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="job_card" label="Job Card #" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="contact_no" label="Contact No." size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      freeSolo
                      size="small"
                      options={JOB_CARD_STATUS?.map((option) => option.name)}
                      getOptionLabel={(option) => option}
                      value={
                        JOB_CARD_STATUS.find((data) => data.id === allValues.JOB_CARD_STATUS) || ''
                      }
                      onChange={(event, newValue) => {
                        setValue(
                          'job_card_status',
                          JOB_CARD_STATUS.find((data) => data.name === newValue)?.id
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} name="job_card_status" label="Job Card Status" />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="fsc_code" label="FSC Code" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <DatePicker
                      label="Created Date"
                      value={new Date()}
                      // onChange={handleFilterStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="created_time" label="Created Time" size="small" />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <DatePicker
                      label="Completed Date"
                      value={new Date()}
                      // onChange={handleFilterStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="completed_time" label="Completed Time" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      freeSolo
                      size="small"
                      options={BAY?.map((option) => option.name)}
                      getOptionLabel={(option) => option}
                      value={BAY.find((data) => data.id === allValues.BAY) || ''}
                      onChange={(event, newValue) => {
                        setValue('bay', BAY.find((data) => data.name === newValue)?.id);
                      }}
                      renderInput={(params) => <TextField {...params} name="bay" label="Bay #" />}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <DatePicker
                      label="Closed Date"
                      value={new Date()}
                      // onChange={handleFilterStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="closed_time" label="Closed Time" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFSelect
                      name="technician"
                      label="Technician"
                      size="small"
                      onClick={() => {
                        dialog.onTrue();
                      }}
                    >
                      {STATE.map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <DatePicker
                      label="Cancellation Date"
                      value={new Date()}
                      // onChange={handleFilterStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="cancellation_time" label="Cancellation Time" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFSelect
                      name="final_inspector_name"
                      label="Final Inspector Name"
                      size="small"
                      onClick={() => {
                        dialog.onTrue();
                      }}
                    >
                      {STATE.map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      freeSolo
                      size="small"
                      options={CANCELLATION_REASON?.map((option) => option.name)}
                      getOptionLabel={(option) => option}
                      value={
                        CANCELLATION_REASON.find(
                          (data) => data.id === allValues.CANCELLATION_REASON
                        ) || ''
                      }
                      onChange={(event, newValue) => {
                        setValue(
                          'cancellation_reason',
                          CANCELLATION_REASON.find((data) => data.name === newValue)?.id
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="cancellation_reason"
                          label="Cancellation Reason"
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      freeSolo
                      size="small"
                      options={SERVICE_AT?.map((option) => option.name)}
                      getOptionLabel={(option) => option}
                      value={SERVICE_AT.find((data) => data.id === allValues.SERVICE_AT) || ''}
                      onChange={(event, newValue) => {
                        setValue(
                          'service_at',
                          SERVICE_AT.find((data) => data.name === newValue)?.id
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} name="service_at" label="Service At" />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <DatePicker
                      label="Vehicle Delivery Date"
                      value={new Date()}
                      // onChange={handleFilterStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField
                      name="vehicle_delivery_time"
                      label="Vehicle Delivery Time"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField
                      name="final_inspector_remarks"
                      label="Final Inspector Remarks"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      freeSolo
                      size="small"
                      options={PENDING_REASON?.map((option) => option.name)}
                      getOptionLabel={(option) => option}
                      value={
                        PENDING_REASON.find((data) => data.id === allValues.PENDING_REASON) || ''
                      }
                      onChange={(event, newValue) => {
                        setValue(
                          'pending_reason',
                          PENDING_REASON.find((data) => data.name === newValue)?.id
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} name="pending_reason" label="Job Card Status" />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="open_for_days" label="Open For Days" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      freeSolo
                      size="small"
                      options={PENDING_REASON?.map((option) => option.name)}
                      getOptionLabel={(option) => option}
                      value={
                        PENDING_REASON.find((data) => data.id === allValues.PENDING_REASON) || ''
                      }
                      onChange={(event, newValue) => {
                        setValue(
                          'price_list',
                          PENDING_REASON.find((data) => data.name === newValue)?.id
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} name="price_list" label="Job Card Status" />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFSelect
                      name="source_inventory_location"
                      label="Source Inventory Location"
                      size="small"
                    >
                      {STATE.map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFCheckbox
                      name="jobs_entry_complete"
                      label="Jobs Entry Complete?"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField
                      name="investigation_details"
                      label="Investigation Details"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFCheckbox
                      name="parts_entry_complete"
                      label="Parts Entry Complete?"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="remarks" label="Remarks" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFCheckbox name="invoiced" label="Invoiced?" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFCheckbox
                      name="final_inspection_completed"
                      label="Final Inspection Complete?"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFCheckbox name="test_ride_taken" label="Test Ride Taken?" size="small" />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  spacing={5}
                  alignContent="start"
                >
                  <Grid
                    item
                    container
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    spacing={2}
                    alignContent="start"
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Typography variant="h6" gutterBottom>
                        Service Request Details
                      </Typography>
                      <Divider sx={{ borderStyle: 'dashed' }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFTextField name="service_type" label="Service Type" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        freeSolo
                        size="small"
                        options={LABOUR_RATE_LIST?.map((option) => option.name)}
                        getOptionLabel={(option) => option}
                        value={
                          LABOUR_RATE_LIST.find((data) => data.id === allValues.LABOUR_RATE_LIST) ||
                          ''
                        }
                        onChange={(event, newValue) => {
                          setValue(
                            'labour_rate_list',
                            LABOUR_RATE_LIST.find((data) => data.name === newValue)?.id
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} name="labour_rate_list" label="Labour Rate List" />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFSelect
                        name="parts_price_list"
                        label="Parts Price List"
                        size="small"
                        onClick={() => {
                          dialog.onTrue();
                        }}
                      >
                        {STATE.map((status) => (
                          <MenuItem key={status.value} value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </RHFSelect>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFTextField name="sr_assigned_to" label="SR Assigned To" size="small" />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    spacing={2}
                    alignContent="start"
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Typography variant="h6" gutterBottom>
                        Customer Detail
                      </Typography>
                      <Divider sx={{ borderStyle: 'dashed' }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFSelect
                        name="customer_last_name"
                        label="Customer Last Name"
                        size="small"
                        onClick={() => {
                          dialog.onTrue();
                        }}
                      >
                        {STATE.map((status) => (
                          <MenuItem key={status.value} value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </RHFSelect>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFTextField
                        name="customer_first_name"
                        label="Customer First Name"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFTextField
                        name="contact_phone_number"
                        label="Contact Phone Number"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFTextField name="email" label="Email" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFSelect
                        name="account_name"
                        label="Account Name"
                        size="small"
                        onClick={() => {
                          dialog.onTrue();
                        }}
                      >
                        {STATE.map((status) => (
                          <MenuItem key={status.value} value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </RHFSelect>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFTextField name="account_phone" label="Account Phone" size="small" />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    spacing={2}
                    alignContent="start"
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Typography variant="h6" gutterBottom>
                        Premium AMC Details
                      </Typography>
                      <Divider sx={{ borderStyle: 'dashed' }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFTextField
                        name="premium_amc_product"
                        label="Premium AMC Product"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFTextField
                        name="premium_amc_duration_days"
                        label="Premium AMC Duration (Days)"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    spacing={2}
                    alignContent="start"
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Typography variant="h6" gutterBottom>
                        EW PLUS Detail
                      </Typography>
                      <Divider sx={{ borderStyle: 'dashed' }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFCheckbox name="ew_plus_active" label="EW PLUS Active" size="small" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              xxl={3}
              alignContent="start"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Typography variant="h6" gutterBottom>
                  Frame Details
                </Typography>
                <Divider sx={{ borderStyle: 'dashed' }} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="frame" label="Frame #" size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="engineNo" label="Engine No." size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField
                  name="vehicle_registration_no"
                  label="Vehicle Registration No."
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="current_kms" label="Current Kms" size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="model_category" label="Model Category" size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="model_name" label="Model Name" size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="model_variant" label="Model Variant" size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="model_code" label="Model Code" size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="order_type" label="Order Type" size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFSelect
                  name="division_name"
                  label="Division Name"
                  size="small"
                  onClick={() => {
                    dialog.onTrue();
                  }}
                >
                  {STATE.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="billing_state" label="Billing State" size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="billing_city" label="Billing City" size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFSelect
                  name="agreement"
                  label="Agreement"
                  size="small"
                  onClick={() => {
                    dialog.onTrue();
                  }}
                >
                  {STATE.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="technician2" label="Technician 2" size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFSelect
                  name="insurance_name"
                  label="Insurance Name"
                  size="small"
                  onClick={() => {
                    dialog.onTrue();
                  }}
                >
                  {STATE.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField
                  name="total_insurance_liability"
                  label="Total Insurance Liability"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField
                  name="total_customer_liability"
                  label="Total Customer Liability"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFSelect
                  name="recall_pud_activity_not_conducted"
                  label="Recall/PUD Activity Not Conducted"
                  size="small"
                >
                  {STATE.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFCheckbox name="fulfill_flag" label="Fulfill Flag" size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFCheckbox name="igst_tax" label="IGST Tax" size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFCheckbox name="tcs_tax" label="TCS Tax" size="small" />
              </Grid>
            </Grid>

            <Grid item container xs={12} sm={6} md={3} lg={3} xl={3} xxl={3} alignContent="start">
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                spacing={2}
                alignContent="start"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="h6" gutterBottom>
                    Invoice
                  </Typography>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    options={INVOICE_FORMAT?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      INVOICE_FORMAT.find((data) => data.id === allValues.INVOICE_FORMAT) || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'invoice_format',
                        INVOICE_FORMAT.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} name="invoice_format" label="Invoice Format" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    options={PAYMENT_MODE?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={PAYMENT_MODE.find((data) => data.id === allValues.PAYMENT_MODE) || ''}
                    onChange={(event, newValue) => {
                      setValue(
                        'payment_mode',
                        PAYMENT_MODE.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} name="payment_mode" label="Payment Mode" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField
                    name="final_labour_amount"
                    label="Final Labour Amount"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="final_parts_amount" label="Final Parts Amount" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="final_amount" label="Final Amount" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <DatePicker
                    label="Estimated Delivery Date"
                    value={new Date()}
                    // onChange={handleFilterStartDate}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: 'small',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField
                    name="estimated_delivery_time"
                    label="Estimated Delivery Time"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="final_lubes_amount" label="Final Lubes Amount" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField
                    name="final_accessories_amount"
                    label="Final Accessories Amount"
                    size="small"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                spacing={2}
                alignContent="start"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="h6" gutterBottom>
                    Contract Details
                  </Typography>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFCheckbox name="ew_active" label="EW Active" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFCheckbox name="amc_active" label="AMC Active" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFCheckbox name="eha_active" label="EHA Active" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFCheckbox name="rsa_active" label="RSA Active" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFCheckbox name="warranty_active" label="Warranty Active" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFCheckbox
                    name="pick_and_drop_active"
                    label="Pick & Drop Active"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    options={TRUE_FALSE_DROPDOWN.map((option) => option.label)}
                    getOptionLabel={(option) => option}
                    value={
                      TRUE_FALSE_DROPDOWN.find(
                        (data) => data.value === allValues.TRUE_FALSE_DROPDOWN
                      ) || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'pick_and_drop_availed',
                        TRUE_FALSE_DROPDOWN.find((data) => data.label === newValue)?.value
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="pick_and_drop_availed"
                        label="Pick & Drop Availed"
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                spacing={2}
                alignContent="start"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="h6" gutterBottom>
                    Loyalty Details
                  </Typography>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="loyalty_id" label="Loyalty ID" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField
                    name="loyalty_registered_ph_no"
                    label="Loyalty Registered Ph No."
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFCheckbox name="loyalty_flag" label="Loyalty Flag" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="tcs_amount" label="TCS Amount" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="tcs_percent" label="TCS Percent" size="small" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={dialog.value}
          onClose={dialog.onTrue}
          PaperProps={{
            sx: { maxWidth: 820, maxHeight: 840 },
          }}
        >
          <Toolbar>
            <Typography variant="h6" sx={{ flex: 1 }}>
              Job Card Detail
            </Typography>

            <IconButton color="inherit" edge="start" onClick={dialog.onFalse}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>

          <Divider sx={{ borderStyle: 'dashed' }} />
          {/* <DialogContent>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                columnSpacing={4}
                rowSpacing={2}
                sx={{ mt: 2 }}
              >
                <Grid item container xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    spacing={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Iconify icon="formkit:dollar" sx={{ mr: 1 }} />
                    <Typography fontSize={16} fontWeight={600} sx={{ flex: 1 }}>
                      Payment Denomination
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <RHFTextField name="job_card" label="Job Card #" size="small" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent> */}

          <DialogActions>
            <Button onClick={dialog.onFalse} variant="outlined" color="inherit">
              Cancel
            </Button>
            <Button
              onClick={() => {
                dialog.onFalse();
              }}
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <DialogActions
          sx={{
            bottom: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {row ? 'Update' : 'Add'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
