import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';
// @mui

import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { create, fetcher } from 'src/utils/axios';
import { HOST_API } from 'src/config-global';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Box } from '@mui/material';
// ----------------------------------------------------------------------

export default function CustomToolbar({ permission, getAll }) {
  const popover = usePopover();
  const fileRef = useRef(null);

  const handleAttach = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };
  const downloadFile = async () => {
    try {
      const URL = `automobile/enquiries/export/?token=${localStorage.getItem('token')}`;
      const data = await fetcher(URL, true);
      if (data) {
        window.open(`${HOST_API}${URL}}`, 'blank');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const uploadFile = async (data) => {
    try {
      const URL = 'automobile/enquiries/import/';
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formdata = new FormData();
      formdata.append('file', data);
      const response = await create({ url: URL, payload: formdata, config });
      if (response) {
        getAll();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const Importsync = async (data) => {
    try {
      const URL = 'automobile/enquiries/importsync/';
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const response = await create({ url: URL, payload: { id: 1 }, config });
    } catch (error) {
      console.error(error);
    }
  };
  return (

    <GridToolbarContainer>
      <input
        ref={fileRef}
        type="file"
        style={{ display: 'none' }}
        multiple={false}
        onChange={async (e) => {
          if (e.target.files['0']) {
            await uploadFile(e.target.files['0']);
            e.target.value = '';
          }
        }}
      />
      <GridToolbarQuickFilter />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarColumnsButton />
      {/* <GridToolbarFilterButton /> */}
      <GridToolbarDensitySelector />
      {(permission?.p || permission?.i || permission?.e) && (
        <IconButton onClick={popover.onOpen}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      )}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {permission?.p && (
          <MenuItem
            onClick={() => {
              popover.onClose();
            }}
          >
            <Iconify icon="solar:printer-minimalistic-bold" />
            Print
          </MenuItem>
        )}

        {permission?.i && (
          <MenuItem
            onClick={() => {
              handleAttach();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:import-bold" />
            Import
          </MenuItem>
        )}
        {permission?.e && (
          <MenuItem
            onClick={() => {
              Importsync();
              popover.onClose();
            }}
          >
            <Iconify icon="material-symbols:sync" />
            RR Sync
          </MenuItem>
        )}
        {permission?.e && (
          <MenuItem
            onClick={() => {
              downloadFile();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:export-bold" />
            Export
          </MenuItem>
        )}
      </CustomPopover>
    </GridToolbarContainer>
  );
}

CustomToolbar.propTypes = {
  permission: PropTypes.object,
  getAll: PropTypes.func
};
