import { styled } from '@mui/system';
import { bgBlur } from 'src/theme/css';

export const GroupItems = styled('ul')({
  padding: 0,
});

export const GroupHeader = styled('li')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  position: 'sticky',
  color: theme.palette.primary.main,
  ...bgBlur({ opacity: 0.48, color: theme.palette.background.default }),
  display: 'flex',
  padding: 4,
  justifyContent: 'space-around',
}));

export const GroupFooter = styled('li')(({ theme }) => ({
  bottom: 0,
  zIndex: 9,
  position: 'sticky',
  color: theme.palette.primary.main,
  ...bgBlur({ opacity: 0.48, color: theme.palette.background.default }),
  display: 'flex',
  padding: 0.5,
  justifyContent: 'space-around',
}));
