import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
// components
import Label from 'src/components/label';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSwitch, RHFTextField, RHFCheckbox } from 'src/components/hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { UploadAvatar, UploadBox } from 'src/components/upload';
import { AuthContext } from 'src/auth/context/jwt';
import axios from 'axios';
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

export default function AccountGeneral({ currentUser }) {
  const { user, updateUser } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [uploadProgress, setUploadProgress] = useState(0);

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    userName: Yup.string().required('Username is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: user?.name,
      userName: user?.userName,
      dob: user?.dob,
      photo: user?.photo,
      adno: user?.adno,
      roleId: user?.role.name,
      departmentId: user?.department?.name,
      mobile: user?.mobile,
      phoneVerified: user?.phoneVerified,
      email: user?.email,
      emailVerified: user?.emailVerified,
      address: user?.address,
    }),
    [user]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (payload) => {
    try {
      const p1 = {
        ...payload,
      };
      delete p1.roleId;
      delete p1.departmentId;
      const data = updateUser(p1);
      if (data.success && data.show) {
        enqueueSnackbar('Update success!');
      }
    } catch (error) {
      console.error(error);
    }
  });

  const handleDropAvatar = useCallback(
    async (acceptedFiles, fieldName) => {
      const newFile = acceptedFiles[0];

      try {
        const URL = `${HOST_API}upload`;

        if (newFile) {
          setValue(fieldName, null);
        }

        const formData = new FormData();
        formData.append('file', newFile);

        const response = await axios.post(URL, formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(percentCompleted, 'progressEvent');
            setUploadProgress(percentCompleted);
          },
        });
        setValue(fieldName, response.data.filename);
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
      }
    },
    [enqueueSnackbar, setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={4}>
          <Card sx={{ pt: 10, pb: 5, px: 3 }}>
            {currentUser && (
              <Label
                color={
                  (values.status === 'active' && 'success') ||
                  (values.status === 'banned' && 'error') ||
                  'warning'
                }
                sx={{ position: 'absolute', top: 24, right: 24 }}
              >
                {values.status}
              </Label>
            )}

            <Box sx={{ mb: 5 }}>
              <UploadAvatar
                file={`${HOST_API}uploads/medium/${values.photo}`}
                name="photo"
                onDrop={(e) => handleDropAvatar(e, `photo`)}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 1,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.disabled',
                    }}
                  >
                    Upload Image
                  </Typography>
                }
                error={errors?.photo && !values?.photo}
                uploadProgress={uploadProgress}
              />
            </Box>

            {currentUser && (
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={field.value !== 'active'}
                        onChange={(event) =>
                          field.onChange(event.target.checked ? 'banned' : 'active')
                        }
                      />
                    )}
                  />
                }
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Banned
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Apply disable account
                    </Typography>
                  </>
                }
                sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
              />
            )}

            <RHFSwitch
              name="isVerified"
              labelPlacement="start"
              label={
                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                  Active User
                </Typography>
              }
              sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
            />

            {currentUser && (
              <Stack justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
                <Button variant="soft" color="error">
                  Delete User
                </Button>
              </Stack>
            )}
          </Card>
        </Grid>

        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <RHFTextField name="name" label="Name" size="small" />
              </Grid>

              <Grid xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                <RHFTextField name="userName" label="Username" size="small" />
              </Grid>

              <Grid xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                <Controller
                  name="dob"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      defaultValue={new Date(field.value)}
                      timezone="Asia/Kolkata"
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      label="Date of Birth"
                      format="dd/MM/yyyy"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                          size: 'small',
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField name="adno" label="Aadhar Card No." size="small" type="number" />
              </Grid>

              <Grid xs={12} sm={12} md={1} lg={1} xl={1} xxl={1}>
                <UploadBox />
              </Grid>

              <Grid xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                <RHFTextField name="roleId" label="Roles" size="small" disabled />
              </Grid>

              <Grid xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField name="departmentId" label="Department" size="small" disabled />
              </Grid>

              <Grid xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                <RHFTextField name="mobile" label="Mobile No." size="small" type="number" />
              </Grid>

              <Grid xs={12} sm={12} md={1.5} lg={1.5} xl={1.5} xxl={1.5}>
                <RHFCheckbox name="phoneVerified" label="Verified" size="small" />
              </Grid>

              <Grid xs={12} sm={12} md={5.8} lg={5.8} xl={5.8} xxl={5.8}>
                <RHFTextField name="email" label="Email ID" size="small" disabled />
              </Grid>

              <Grid xs={12} sm={12} md={1.5} lg={1.5} xl={1.5} xxl={1.5}>
                <RHFCheckbox name="emailVerified" label="Verified" size="small" />
              </Grid>

              <Grid xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="address" label="Address" multiline rows={3} size="small" />
              </Grid>
            </Grid>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!currentUser ? 'Save Changes' : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

AccountGeneral.propTypes = {
  currentUser: PropTypes.object,
};
