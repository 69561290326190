import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
//
import { useResponsive } from 'src/hooks/use-responsive';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { Button } from '@mui/base';
import LinkItem from './link-item';
import Iconify from '../iconify';

// ----------------------------------------------------------------------

export default function CustomBreadcrumbs({
  links,
  action,
  heading,
  settingTitle,
  moreLink,
  openSettingTrue = false,
  openSetting,
  settingIcon,
  activeLast,
  sx,
  ...other
}) {
  const lastLink = links[links.length - 1].name;

  const lgUp = useResponsive('up', 'lg');

  return (
    <Box sx={{ ...sx }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box sx={{ flexShrink: 0 }}>
          {/* HEADING */}
          {!lgUp && (
            <Box sx={{ flexShrink: 1 }}>
              {heading && (
                <Grid display="flex" alignItems="start">
                  <Typography variant="h4" gutterBottom sx={{ justifyContent: 'center' }}>
                    {heading}
                  </Typography>
                  {openSettingTrue && (
                    <Tooltip title={settingTitle} arrow sx={{ ml: '5px' }}>
                      <IconButton color="primary" onClick={openSetting}>
                        <Iconify icon={settingIcon} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              )}
            </Box>
          )}

          {/* BREADCRUMBS */}
          {!!links.length && (
            <Breadcrumbs separator={<Separator />} {...other}>
              {links.map((link) => (
                <LinkItem
                  key={link.name || ''}
                  link={link}
                  activeLast={activeLast}
                  disabled={link.name === lastLink}
                />
              ))}
            </Breadcrumbs>
          )}
        </Box>

        {/* HEADING */}
        {lgUp && (
          <Box sx={{ flexShrink: 1, textAlign: 'center' }}>
            {heading && (
              <Grid display="flex" alignItems="start">
                <Typography variant="h4" gutterBottom sx={{ justifyContent: 'center' }}>
                  {heading}
                </Typography>
                {openSettingTrue && (
                  <Tooltip title={settingTitle} arrow sx={{ ml: '5px' }}>
                    <IconButton color="primary" onClick={openSetting}>
                      <Iconify icon={settingIcon} />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            )}
          </Box>
        )}

        {action && <Box sx={{ flexShrink: 0 }}> {action} </Box>}
      </Stack>

      {/* MORE LINK */}
      {!!moreLink && (
        <Box sx={{ mt: 2 }}>
          {moreLink.map((href) => (
            <Link
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))}
        </Box>
      )}
    </Box>
  );
}

CustomBreadcrumbs.propTypes = {
  sx: PropTypes.object,
  action: PropTypes.node,
  links: PropTypes.array,
  heading: PropTypes.string,
  moreLink: PropTypes.array,
  activeLast: PropTypes.bool,
  openSettingTrue: PropTypes.bool,
  settingTitle: PropTypes.string,
  settingIcon: PropTypes.string,
  openSetting: PropTypes.bool,
};

// ----------------------------------------------------------------------

function Separator() {
  return (
    <Box
      component="span"
      sx={{
        width: 4,
        height: 4,
        borderRadius: '50%',
        bgcolor: 'text.disabled',
      }}
    />
  );
}
