/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
// @mui
import Dialog from '@mui/material/Dialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fetcher } from 'src/utils/axios';
import { KEY, TRANSACTION_TYPES } from 'src/_mock/constant';
import { Divider, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import { alpha } from '@mui/system';
import CompanyYear from 'src/layouts/_common/company-year';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';

export default function ViewTransactions({
  row,
  open,
  onClose,
  table,
  getAll,
  tableData,
  qickview,
}) {
  const [entriesList, setEntries] = useState([]);

  const getEntries = useCallback(async () => {
    let URL = 'entries/';
    URL += `?page=${1}&limit=${10000}&status=true&entryId=${row?.id}`;
    const { data } = await fetcher(URL);
    setEntries(data?.rows);
  }, [row?.id]);

  const handleKeyDown = (event) => {
    if (event.altKey && event.key === 'a') {
      event.stopPropagation();
    }
    if (event.altKey && event.key === 'r') {
      event.stopPropagation();
    }
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      getEntries();
    }
  }, [getEntries, open]);

  const handleCloseProduct = () => {
    qickview.onFalse();
  };

  return (
    <Dialog fullScreen onKeyDown={handleKeyDown} open={open}>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          top: 0,
          zIndex: 9,
          position: 'sticky',
          bgcolor: (theme) => alpha(theme.palette.background.default, 1),
        }}
      >
        <Toolbar>
          <Typography variant="h5" sx={{ flex: 1 }}>
            View Transactions
          </Typography>

          <CompanyYear />

          <IconButton color="inherit" edge="start" onClick={onClose}>
            <Iconify icon="mingcute:close-line" />
          </IconButton>
        </Toolbar>
        <Divider sx={{ borderStyle: 'dashed' }} />
      </Grid>
      <TableContainer
        component={Paper}
        sx={{ overflow: 'auto', maxHeight: '80vh', padding: '10px' }}
      >
        <Table
          stickyHeader
          aria-label="simple table"
          sx={{
            '& .MuiTableCell-sizeMedium': {
              padding: '5px ',
            },
            '& fieldset': { borderRadius: '0px', border: 'none' },
            '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
            '& .MuiOutlinedInput-root': { padding: '0px !important' },
            '& .autoComplete>div': { padding: '0px !important' },
            '& .MuiTableCell-root': {
              border: '0.01px solid #e9ecee',
            },
            '& .MuiTableCell-head': {
              padding: '5px !important',
              borderRight: '1px solid #d0d1d2 !important',
            },
            '& .MuiTableCell-footer': {
              padding: '5px !important',
            },
            '& .MuiTableCell-head:last-child': {
              borderRight: '0px !important',
            },
            '&:last-child td': {
              borderColor: '#e9ecee !important',
            },
          }}
        >
          <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
            <TableRow>
              <TableCell sx={{ width: 50, textAlign: 'center' }}>Date</TableCell>
              <TableCell sx={{ width: 20, textAlign: 'center' }}>No</TableCell>
              <TableCell sx={{ width: 20, textAlign: 'center' }}>Transaction Type</TableCell>
              <TableCell sx={{ width: 400, textAlign: 'center' }}>Descriptions</TableCell>
              <TableCell sx={{ width: 70, textAlign: 'center' }}>PR</TableCell>
              <TableCell sx={{ width: 70, textAlign: 'center' }}>Payment (Dr.)</TableCell>
              <TableCell sx={{ width: 70, textAlign: 'center' }}>Receipt (Cr.)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {row?.entry_details
              .sort((a, b) => a.order - b.order)
              .sort((a, b) => b.cb - a.cb)
              .map((x, index) => (
                <TableRow key={x.id}>
                  <TableCell>
                    {index === 0 ? moment(x?.createdAt, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''}
                  </TableCell>
                  <TableCell>{x?.v_no}</TableCell>
                  <TableCell>
                    {index === 0 && row?.t_type ? TRANSACTION_TYPES[row.t_type] : ''}
                  </TableCell>
                  <TableCell sx={x.cb === 1 ? { paddingLeft: '50px !important' } : {}}>
                    {x.cb === 1 ? `To ${x?.account.name}` : x?.account.name}
                  </TableCell>
                  <TableCell />
                  <TableCell sx={{ textAlign: 'right' }}>{x.cb === 2 ? x?.amount : ''}</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>{x.cb === 1 ? x?.amount : ''}</TableCell>
                </TableRow>
              ))} */}
            {/* {row?.narr && (
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell>{`(${row.narr})`}</TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>
            )} */}
            {entriesList
              ?.sort((a, b) => a.id - b.id)
              ?.map((y) => {
                let debitAmount = 0;
                if ([1, 2, 3, 4].includes(y.t_type)) {
                  debitAmount = y.entry_details.find(
                    (x) => x.entryId === row?.id || x.invoiceId === row?.id
                  ).amount;
                }

                return (
                  <>
                    <TableRow key={`${y.id}122`} sx={{ height: '25px !important' }}>
                      <TableCell sx={{ border: '0px !important' }} colSpan={6} />
                    </TableRow>
                    {y.entry_details
                      .filter(
                        (x) =>
                          x.entryId === row?.id ||
                          x.invoiceId === row?.id ||
                          x.accountId !== row.entry_details[0].accountId
                      )
                      .sort((a, b) => a.order - b.order)
                      .sort((a, b) => b.cb - a.cb)
                      .map((x, index) => (
                        <TableRow key={x.id}>
                          <TableCell>
                            {index === 0
                              ? moment(x?.createdAt, 'YYYY-MM-DD HH:mm:ss').format(
                                'DD-MM-YYYY HH:mm:ss'
                              )
                              : ''}
                          </TableCell>
                          <TableCell>{x?.v_no}</TableCell>
                          <TableCell>
                            {index === 0 && y?.t_type ? TRANSACTION_TYPES[y.t_type] : ''}
                          </TableCell>
                          <TableCell sx={x.cb === 1 ? { paddingLeft: '50px !important' } : {}}>
                            {x.cb === 1 ? `To ${x?.account.name}` : x?.account.name}
                          </TableCell>
                          <TableCell />
                          <TableCell sx={{ textAlign: 'right' }}>
                            {x.cb === 2
                              ? debitAmount
                                ? debitAmount.toFixed(2)
                                : x?.amount.toFixed(2)
                              : ''}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'right' }}>
                            {x.cb === 1 ? x?.amount.toFixed(2) : ''}
                          </TableCell>
                        </TableRow>
                      ))}
                    {y?.narr && (
                      <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell>{`[${y.narr}]`}</TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                      </TableRow>
                    )}
                  </>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
}

ViewTransactions.propTypes = {
  row: PropTypes.object,
  qickview: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
