import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { KEY } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import { Stack } from '@mui/system';
import { useBgColorAutocomplete } from 'src/_mock/constant_funcation';
import { useGetEmistandards, useGetSeatings } from 'src/sections/work_shop/global';

export default function ProductModelQuickEditForm({
  row,
  open,
  onClose,
  table,
  getAll,
  tableData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [modelCategory, setModelCategory] = useState([]);

  const bgColorAutocomplete = useBgColorAutocomplete();

  const ModelProductSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    amodcategoryId: Yup.number().required('Product Category is required'),
    wheelbase: Yup.string().required('wheelbase is required'),
    aseatingId: Yup.number().required('aseatingId is required'),
    aemistandardId: Yup.number().required('aemistandardId is required'),
    fronttiersize: Yup.string().required('fronttiersize is required'),
    reartiersize: Yup.string().required('reartiersize is required'),
    bhp: Yup.string().required('bhp is required'),
    cc: Yup.string().required('cc is required'),
    carmono: Yup.string().required('carmono is required'),
    hydrocarbon: Yup.string().required('hydrocarbon is required'),
    nonmethane: Yup.string().required('nonmethane is required'),
    oxidesnitro: Yup.string().required('oxidesnitro is required'),
    hornvehicle: Yup.string().required('hornvehicle is required'),
    bystander: Yup.string().required('bystander is required'),
    unladenweight: Yup.string().required('unladenweight is required'),
    grossweight: Yup.string().required('grossweight is required'),
    cylinder: Yup.string().required('cylinder is required'),
  });

  const values = useMemo(
    () => ({
      name: row?.name || '',
      amodcategoryId: row?.amodcategoryId || null,
      wheelbase: row?.wheelbase || '',
      aseatingId: row?.aseatingId || null,
      aemistandardId: row?.aemistandardId || null,
      fronttiersize: row?.fronttiersize || '',
      reartiersize: row?.reartiersize || '',
      bhp: row?.bhp || '',
      cc: row?.cc || '',
      carmono: row?.carmono || '',
      hydrocarbon: row?.hydrocarbon || '',
      nonmethane: row?.nonmethane || '',
      oxidesnitro: row?.oxidesnitro || '',
      hornvehicle: row?.hornvehicle || '',
      bystander: row?.bystander || '',
      unladenweight: row?.unladenweight || '',
      grossweight: row?.grossweight || '',
      cylinder: row?.cylinder || '',
      activeinsales: row?.activeinsales,
      status: row?.status,
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(ModelProductSchema),
    values,
    mode: 'onChange',
  });

  const {
    reset,
    trigger,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const onSubmit = handleSubmit(async (payload) => {
    payload = {
      ...payload,
      bhp: parseFloat(payload.bhp),
      cc: parseFloat(payload.cc),
      carmono: parseFloat(payload.carmono),
      hydrocarbon: parseFloat(payload.hydrocarbon),
      nonmethane: parseFloat(payload.nonmethane),
      oxidesnitro: parseFloat(payload.oxidesnitro),
      hornvehicle: parseFloat(payload.hornvehicle),
      bystander: parseFloat(payload.bystander),
      unladenweight: parseFloat(payload.unladenweight),
      grossweight: parseFloat(payload.grossweight),
      cylinder: parseFloat(payload.cylinder),
      fronttiersize: parseFloat(payload.fronttiersize),
      reartiersize: parseFloat(payload.reartiersize),
      wheelbase: parseFloat(payload.wheelbase),
    };
    setLoading(true);
    try {
      let URL = 'automobile/product/models/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      setLoading(false);
      console.error(error);
    }
  });

  const seatings = useGetSeatings();
  const emistandards = useGetEmistandards();

  const ModelCategoryList = useCallback(async () => {
    try {
      let URL = 'automobile/product/categories/';
      URL += `?page=${1}&limit=${10000}&status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setModelCategory(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    reset();
  }, [open, reset]);

  useEffect(() => {
    if (open) ModelCategoryList();
  }, [open, ModelCategoryList]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 1220 },
      }}
    >
      <FormProvider methods={methods}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            top: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Toolbar>
            <Typography variant="h5" sx={{ flex: 1 }}>
              {row ? 'Update Product Model' : 'Add Product Model'}
            </Typography>
            <IconButton color="inherit" edge="start" onClick={onClose}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>

        <DialogContent>
          <Grid
            spacing={2}
            container
            sx={{
              px: 2,
              py: 3,
            }}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
              <RHFTextField
                name="name"
                label={
                  <span>
                    Name <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
              <Autocomplete
                autoHighlight
                fullWidth
                size="small"
                options={modelCategory}
                groupBy={(option) => option?.a}
                getOptionLabel={(option) => option.name}
                getOptionDisabled={(option) => option?.value === null}
                openOnFocus
                onChange={(event, newValue) => {
                  setValue('amodcategoryId', newValue?.id);
                }}
                value={(() => {
                  const addRecord = modelCategory?.find(
                    (data) => data.id === allValues.amodcategoryId
                  );
                  const editRecord = row?.ac_group;

                  if (addRecord) return addRecord;
                  if (editRecord) return editRecord;
                  return null;
                })()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        Model Category <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    name="amodcategoryId"
                    error={errors?.amodcategoryId && !allValues?.amodcategoryId}
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                    key={option.id}
                    sx={{ minHeight: 'auto !important' }}
                  >
                    <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                  </Box>
                )}
                ListboxProps={{ ...bgColorAutocomplete }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFTextField
                name="wheelbase"
                label={
                  <span>
                    Wheel Base <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">MM</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={seatings.rows?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={seatings.rows?.find((data) => data.id === allValues.aseatingId)?.name || ''}
                onChange={(event, newValue) => {
                  setValue('aseatingId', seatings.rows?.find((data) => data.name === newValue)?.id);
                }}
                openOnFocus
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="aseatingId"
                    label={
                      <span>
                        Select Seating <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    error={errors?.aseatingId && !allValues?.aseatingId}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
                ListboxProps={{
                  ...bgColorAutocomplete,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={emistandards.rows?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={
                  emistandards.rows?.find((data) => data.id === allValues.aemistandardId)?.name ||
                  ''
                }
                openOnFocus
                onChange={(event, newValue) => {
                  setValue(
                    'aemistandardId',
                    emistandards.rows?.find((data) => data.name === newValue)?.id
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="aemistandardId"
                    label={
                      <span>
                        Select Emission <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    error={errors?.aemistandardId && !allValues?.aemistandardId}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
                ListboxProps={{
                  ...bgColorAutocomplete,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFTextField
                name="fronttiersize"
                label={
                  <span>
                    Front Tier Size <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFTextField
                name="reartiersize"
                label={
                  <span>
                    Rear Tier Size <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFTextField
                name="bhp"
                label={
                  <span>
                    BHP <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">BHP</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFTextField
                name="cc"
                label={
                  <span>
                    C.C <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">CC</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2.7} lg={2.7} xl={2.7} xxl={2.7}>
              <RHFTextField
                name="carmono"
                label={
                  <span>
                    Carbon Monoxide<span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">mg/ km</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
              <RHFTextField
                name="hydrocarbon"
                label={
                  <span>
                    Hydrocarbon <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">mg/ km</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3.3} lg={3.3} xl={3.3} xxl={3.3}>
              <RHFTextField
                name="nonmethane"
                label={
                  <span>
                    Non-Methane Hydrocarbon <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">mg/ km</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
              <RHFTextField
                name="oxidesnitro"
                label={
                  <span>
                    Oxides of Nitrogen <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">mg/ km</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFTextField
                name="hornvehicle"
                label={
                  <span>
                    Horn Noise <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">dB(A)</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFTextField
                name="bystander"
                label={
                  <span>
                    Bystander <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">dB(A)</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFTextField
                name="unladenweight"
                label={
                  <span>
                    Unladen Weight <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFTextField
                name="grossweight"
                label={
                  <span>
                    Gross Weight <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFTextField
                name="cylinder"
                label={
                  <span>
                    Cylinder <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFCheckbox name="activeinsales" label="Active In Sales" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFCheckbox name="status" label="Active" size="small" />
            </Grid>
          </Grid>
        </DialogContent>

        <Stack
          direction="row-reverse"
          alignItems="center"
          sx={{
            bottom: 0,
            zIndex: 111111,
            p: 3,
            position: 'sticky',
            width: '100%',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
          id="modelfooter"
        >
          <Tooltip title="(Alt + A)" arrow>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={async () => {
                if (await trigger()) {
                  onSubmit();
                }
              }}
            >
              {row ? 'Update' : 'Add'}
            </LoadingButton>
          </Tooltip>

          <Tooltip title="(Esc)" arrow>
            <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
          </Tooltip>

          <Button
            variant="soft"
            onClick={() => {
              reset(values);
            }}
            sx={{ mr: 2 }}
          >
            Reset
          </Button>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}

ProductModelQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
