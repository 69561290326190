import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { KEY, ADD_LESS } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  ListItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import CompanyYear from 'src/layouts/_common/company-year';
import { Stack } from '@mui/system';
import { useBgColorAutocomplete } from 'src/_mock/constant_funcation';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { useBoolean } from 'src/hooks/use-boolean';
import AUserQuickEditForm from 'src/sections/automobile-accounts/account/quick-edit-form';

export default function ProductCategoryQuickEditForm({
  row,
  open,
  onClose,
  table,
  getAll,
  tableData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const bgColorAutocomplete = useBgColorAutocomplete();

  const quickEditAccount = useBoolean();

  const [accountsList, setAccountsList] = useState([]);
  const [accountEditList, setaccountEditList] = useState({});

  const TaxatationMasterSchema = Yup.object().shape({
    accountId: Yup.number().required('accountId is required'),
    name: Yup.string().required('name is required'),
    operation: Yup.string().required('operation is required'),
    screen: Yup.string().required('screen is required'),
    order: Yup.number().required('order is required'),
  });

  const values = useMemo(
    () => ({
      accountId: row?.accountId || null,
      order: row?.order || null,
      description: row?.description || '',
      operation: row?.operation || '',
      name: row?.name || '',
      screen: row?.screen || '',
      status: row?.status || false,
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(TaxatationMasterSchema),
    values,
    mode: 'onChange',
  });

  const {
    reset,
    trigger,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      let URL = 'automobile/bifurcations/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      setLoading(false);
      console.error(error);
    }
  });

  const partyAccountList = useCallback(async () => {
    try {
      let URL = 'automobile/accounts/';
      URL += `?page=${1}&limit=${10000}&status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setAccountsList(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    reset();
    partyAccountList();
  }, [open, partyAccountList, reset]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={false}
        open={open}
        onKeyDown={handleKeyDown}
        PaperProps={{
          sx: { maxWidth: 720 },
        }}
      >
        <FormProvider methods={methods}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update Taxation' : 'Add Taxation'}
              </Typography>
              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          <DialogContent>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              pt={3}
              pb={3}
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <Autocomplete
                autoHighlight
                fullWidth
                size="small"
                options={accountsList.length ? accountsList : [{ name: 'No Options', value: null }]}
                groupBy={(option) => option?.a}
                getOptionLabel={(option) => option.name}
                openOnFocus={!row?.id}
                onChange={(event, newValue) => {
                  setValue('accountId', newValue?.id);
                }}
                onHighlightChange={(event, newValue) => {
                  setaccountEditList(newValue);
                }}
                value={(() => {
                  const addRecord = accountsList?.find((data) => data.id === allValues?.accountId);
                  const editRecord = row?.ac_group;

                  if (addRecord) return addRecord;
                  if (editRecord) return editRecord;
                  return null;
                })()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        Select Account <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    name="accountId"
                    error={errors?.accountId && !allValues?.accountId}
                    autoFocus
                  />
                )}
                autoFocus
                renderGroup={(params) => (
                  <li key={params.key}>
                    <GroupHeader component="li">
                      <ListItem sx={{ padding: 0, margin: 0, width: '30%' }}>Code</ListItem>
                      <ListItem sx={{ padding: 0, margin: 0 }}>Account Name</ListItem>
                    </GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                    <GroupFooter component="li" sx={{ display: 'block' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-around',
                          bgcolor: (theme) => alpha(theme.palette.primary.lighter, 1),
                        }}
                      >
                        <Button
                          onClick={() => {
                            setaccountEditList(null);
                            quickEditAccount.onTrue();
                          }}
                        >
                          Add
                        </Button>
                        {accountEditList?.id && (
                          <Button
                            onClick={() => {
                              quickEditAccount.onTrue();
                            }}
                          >
                            Edit
                          </Button>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          color: 'black',
                          px: 2,
                          bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
                        }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={7} md={7}>
                            {accountEditList?.address && (
                              <>
                                <Typography variant="body2">
                                  <strong>Address</strong>
                                </Typography>
                                <Typography variant="body2">
                                  {accountEditList?.address || '-'}, <br />
                                  {accountEditList?.district && `${accountEditList?.district}, `}
                                  {accountEditList?.city?.name &&
                                    `${accountEditList?.city?.name}, `}
                                  {accountEditList?.state?.name &&
                                    `${accountEditList?.state?.name}, `}
                                  {accountEditList?.country?.name &&
                                    `${accountEditList?.country?.name}.`}
                                </Typography>
                              </>
                            )}
                          </Grid>
                          <Grid item xs={5} md={5} sx={{ textAlign: 'left' }}>
                            {accountEditList?.mobile && (
                              <Typography variant="body2">
                                Mobile No. {accountEditList?.mobile || '-'}
                              </Typography>
                            )}

                            {accountEditList?.gst_no && (
                              <Typography variant="body2">
                                GST No. {accountEditList?.gst_no || '-'}
                              </Typography>
                            )}

                            {accountEditList?.pan_no && (
                              <Typography variant="body2">
                                PAN No. {accountEditList?.pan_no || '-'}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </GroupFooter>
                  </li>
                )}
                componentsProps={{
                  paper: {
                    sx: {
                      width: 600,
                    },
                  },
                }}
                renderOption={(props, option) => (
                  <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                    <ListItem key={option.id} sx={{ padding: 0, margin: 0, width: '30%' }}>
                      {option?.id}
                    </ListItem>
                    <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                      {option?.name}
                    </ListItem>
                  </Box>
                )}
                ListboxProps={{
                  ...bgColorAutocomplete,
                }}
              />
              <RHFTextField
                name="name"
                label={
                  <span>
                    Display Name <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
              />
              <Autocomplete
                autoHighlight
                fullWidth
                size="small"
                options={ADD_LESS.length ? ADD_LESS : [{ name: 'No Options', value: null }]}
                groupBy={(option) => option?.a}
                getOptionLabel={(option) => option.name}
                openOnFocus
                onChange={(event, newValue) => {
                  setValue('operation', newValue?.value);
                }}
                value={(() => {
                  const addRecord = ADD_LESS?.find((data) => data.value === allValues?.operation);
                  const editRecord = row?.ac_group;

                  if (addRecord) return addRecord;
                  if (editRecord) return editRecord;
                  return null;
                })()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        operation <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    name="operation"
                    error={errors?.operation && !allValues?.operation}
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                    <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                      {option?.name}
                    </ListItem>
                  </Box>
                )}
                ListboxProps={{
                  ...bgColorAutocomplete,
                }}
              />
              <RHFTextField
                name="order"
                label={
                  <span>
                    Order <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                type="number"
              />
              <RHFTextField
                name="screen"
                label={
                  <span>
                    screen <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
              />
              <RHFCheckbox name="status" label="Active" size="small" />
              <RHFTextField
                name="description"
                label="Description"
                size="small"
                multiline
                rows={2}
              />
            </Box>
          </DialogContent>

          <Stack
            direction="row-reverse"
            alignItems="center"
            sx={{
              bottom: 0,
              zIndex: 111111,
              p: 3,
              position: 'sticky',
              width: '100%',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
            id="modelfooter"
          >
            <Tooltip title="(Alt + A)" arrow>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={async () => {
                  if (await trigger()) {
                    onSubmit();
                  }
                }}
              >
                {row ? 'Update' : 'Add'}
              </LoadingButton>
            </Tooltip>

            <Tooltip title="(Esc)" arrow>
              <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
                Cancel
              </Button>
            </Tooltip>

            <Button
              variant="soft"
              onClick={() => {
                reset(values);
              }}
              sx={{ mr: 2 }}
            >
              Reset
            </Button>
          </Stack>
        </FormProvider>
      </Dialog>
      {quickEditAccount && (
        <AUserQuickEditForm
          row={null || accountEditList}
          open={quickEditAccount.value}
          onClose={() => {
            quickEditAccount.onFalse();
            partyAccountList();
          }}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
    </>
  );
}

ProductCategoryQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
