import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect, useContext } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import queryString from 'query-string';
import { useSnackbar } from 'src/components/snackbar';
import { useDebounce } from 'src/hooks/use-debounce';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';
//
import { fetcher, destroy } from 'src/utils/axios';
import { useEventListener } from 'src/hooks/use-event-listener';
import { Tooltip } from '@mui/material';
import { TableRowsLoader, UPermission } from 'src/_mock/constant_funcation';
import { AuthContext } from 'src/auth/context/jwt';

import ViewTransactions from 'src/sections/transaction/view-transactions';
import InvoiceSettings from 'src/layouts/_common/automobile-invoice-setting';
import UserTableRow from '../table-row';
import UserTableToolbar from '../table-toolbar';
import UserTableFiltersResult from '../table-filters-result';
import UserQuickEditForm from '../quick-edit-form';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = [{ value: 'all', label: 'All' }];

const TABLE_HEAD = [
  { id: 'id', label: 'V No.', width: 70, align: 'left' },
  { id: 'jobcardNo', label: 'J No.', width: 70, align: 'left' },
  { id: 'branchId', label: 'branch', width: 70, align: 'left' },
  { id: 'workshop', label: 'department', width: 70, align: 'left' },
  { id: 'type', label: 'TYPE', width: 70, align: 'left' },
  { id: 'dt', label: 'Vou. Date' },
  { id: 'invo_type', label: 'Invoice Type' },
  { id: 'c_d', label: 'Cash/Debit' },
  { id: 'ac', label: 'Party A/c.' },
  { id: 'opp', label: 'Opp A/c.' },
  { id: 'tax_bill', label: 'Tax/Bill of Supply' },
  { id: 'amount', label: 'Amount', align: 'right' },
  { id: '', label: 'Action', width: 90 },
];

const defaultFilters = {
  name: '',
  invoices: [],
  status: 'all',
};

// ----------------------------------------------------------------------

export default function ListView() {
  const table = useTable();
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();
  const { user } = useContext(AuthContext);
  const permission = UPermission('component', 'autoMobileInvoiceListPage');

  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [editRow, setEditRow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [orderBy, setOrderBy] = useState({});
  const [filters, setFilters] = useState(defaultFilters);

  const debouncedQuery = useDebounce(filters);

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = count === 0;

  const { page } = table;
  const limit = table.rowsPerPage;

  const getAll = useCallback(async () => {
    setIsLoading(true);
    try {
      let URL = 'automobile/entries/';
      URL += `?page=${page + 1}&limit=${limit}&workshop=true&saleinvoice=true&isjobcard=true&`;
      if (Object.keys(debouncedQuery).length) {
        const nefil = { ...debouncedQuery };
        if (debouncedQuery.status === 'all') {
          delete nefil.status;
        }
        URL += queryString.stringify(nefil);
      }
      if (!URL.includes('t_type')) URL += '&t_type=8,9';
      const orderByKeys = Object.keys(orderBy);
      if (orderByKeys.length) {
        const objectToArray = [];
        orderByKeys.filter((y) => y !== 'name').map((x) => objectToArray.push([x, orderBy[x]]));
        if (objectToArray.length) URL += `&orderBy=${JSON.stringify(objectToArray)}`;
      }
      const { data } = await fetcher(URL);
      if (data) {
        setCount(data?.count);
        setTableData(data?.rows);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, [debouncedQuery, limit, orderBy, page]);

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleDeleteRows = useCallback(
    async (id) => {
      try {
        let URL = 'automobile/entries/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          getAll();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [enqueueSnackbar, getAll]
  );

  useEffect(() => {
    getAll();
  }, [getAll, handleDeleteRows, user?.co_year?.id]);

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const invoiceSettings = useBoolean();
  const quickEdit = useBoolean();
  const quickView = useBoolean();

  const handleKeyDown = (event) => {
    if (event.altKey && event.key.toLowerCase() === 'c') {
      event.stopPropagation();
      setEditRow(null);
      quickEdit.onTrue();
    }
  };

  useEventListener('keydown', handleKeyDown);

  const openSetting = () => {
    invoiceSettings.onTrue();
  };
  useEffect(() => {
    setOrderBy((prevState) => ({
      [table.orderBy]: table.order,
    }));
  }, [table.orderBy, table.order]);
  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          settingIcon="uiw:setting"
          settingTitle="Invoice Setting"
          openSettingTrue="true"
          openSetting={openSetting}
          heading="Invoice List"
          links={[{ name: 'Dashboard', href: paths.home }, { name: 'List' }]}
          action={
            permission?.c && (
              <Tooltip title="(Alt + C)" arrow>
                <Button
                  component={RouterLink}
                  variant="contained"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                  onClick={() => {
                    setEditRow(null);
                    quickEdit.onTrue();
                  }}
                >
                  Invoice
                </Button>
              </Tooltip>
            )
          }
          sx={{
            mb: { xs: 2, md: 2 },
          }}
        />

        <Card>
          <Tabs
            value={filters.status}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab key={tab.value} iconPosition="end" value={tab.value} label={tab.label} />
            ))}
          </Tabs>

          <UserTableToolbar filters={filters} onFilters={handleFilters} permission={permission} getAll={getAll} />

          {canReset && (
            <UserTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              onResetFilters={handleResetFilters}
              //
              results={count}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar sx={{ maxHeight: 400 }}>
              <Table stickyHeader size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {isLoading ? (
                    <TableRowsLoader rowsNum={table.rowsPerPage} colNum={TABLE_HEAD.length} />
                  ) : (
                    <>
                      {tableData.map((row, index) => (
                        <UserTableRow
                          setEditRow={setEditRow}
                          tableData={tableData}
                          quickEdit={quickEdit}
                          quickView={quickView}
                          key={row.id}
                          row={row}
                          index={page * limit + index + 1}
                          selected={table.selected.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRows(row.id)}
                          // onEditRow={() => handleEditRow(row.id)}
                          table={{ table }}
                          getAll={getAll}
                          permission={permission}
                        />
                      ))}

                      <TableNoData notFound={notFound} />
                    </>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>

      {quickEdit.value && (
        <UserQuickEditForm
          row={null || editRow}
          open={quickEdit.value}
          onClose={quickEdit.onFalse}
          invoiceSettings={invoiceSettings}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
      {quickView.value && (
        <ViewTransactions
          row={null || editRow}
          open={quickView.value}
          onClose={quickView.onFalse}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
      {invoiceSettings.value && (
        <InvoiceSettings
          open={invoiceSettings.value}
          onClose={() => {
            invoiceSettings.onFalse();
          }}
        />
      )}
    </>
  );
}
