import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Autocomplete, Chip, Grid, TextField } from '@mui/material';
import { top100Films } from 'src/_mock/constant';

export default function ImportTableRow({
  row,
  index,
  selected,
  setRow,
  user,
  allValue,
  uploadedLabel,
  enterWithValue,
  setEnterWithValue,
  setValue,
}) {
  const { c_alias } = row;
  return (
    <TableRow
      hover
      selected={selected}
      onClick={() => {
        setRow(row.id);
      }}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell>{index + 1}</TableCell>
      <TableCell>{c_alias}</TableCell>
      <TableCell style={{ padding: '10px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} >
            <Autocomplete
              autoHighlight
              fullWidth
              multiple
              limitTags={3}
              sx={{ zIndex: 0 }}
              size="small"
              options={uploadedLabel}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  name={`uploadFields[${index}].right`}
                  {...params}
                  placeholder="Selected Label"
                  inputProps={{
                    ...params.inputProps,
                    id: `${params.id}${enterWithValue}`,
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.title}>
                  {option.title}
                </li>
              )}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && e.target.value.length === 0) {
                  setEnterWithValue('');
                } else {
                  setEnterWithValue('-ef');
                }
              }}
              renderTags={(selected, getTagProps) => {
                selected.map((x, index) => { x.order = index + 1; return x; })
                if (JSON.stringify(allValue.uploadFields[index]?.right) !== JSON.stringify(selected)) {
                  setValue(`uploadFields[${index}].right`, selected);
                }
                return selected.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={option.title}
                    label={option.title}
                    size="small"
                    variant="soft"
                  />
                ));
              }}
            />
          </Grid>
          {
            allValue.uploadFields[index]?.col_options?.map((x, ind) => <>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <Autocomplete
                  autoHighlight
                  name={`uploadFields[${index}].col_options[${ind}].leftName`}
                  fullWidth
                  size="small"
                  options={top100Films}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => <TextField {...params} label="options" margin="none" />}
                  renderOption={(props, option) => (
                    <li {...props} key={option.title}>
                      {option.title}
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <Autocomplete
                  autoHighlight
                  name={`uploadFields[${index}].col_options[${ind}].rightName`}
                  fullWidth
                  size="small"
                  options={top100Films}
                  getOptionLabel={(option) => option.title}
                  onChange={(event, newValue) => {
                    if (newValue)
                      setValue('v_name', newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label="options" margin="none" />}
                  renderOption={(props, option) => (
                    <li {...props} key={option.title}>
                      {option.title}
                    </li>
                  )}
                />
              </Grid></>)
          }

        </Grid>

        {/* <Autocomplete
     autoHighlight
          fullWidth
          freeSolo
          size="small"
          options={allValue?.col_options?.find(x => x.name === allValue.s_name)?.sof_vers?.map((option) => option.name) || []}
          getOptionLabel={(option) => option}
          onChange={(event, newValue) => {
            if (newValue)
              setValue('v_name', newValue);
          }}
          value={allValue?.col_options?.find((data) => data.name === allValue.s_name)?.sof_vers?.find((data) => data.name === allValue.v_name)?.name || allValue.v_name}
          renderInput={(params) => (
            <TextField {...params} label="Version Name" name="v_name" onChange={(event, newValue) => {
              if (event.target.value)
                setValue('v_name', event.target.value);
            }} />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              {option}
            </li>
          )}
        /> */}
      </TableCell>
    </TableRow>
  );
}

ImportTableRow.propTypes = {
  row: PropTypes.object,
  index: PropTypes.any,
  selected: PropTypes.bool,
  setRow: PropTypes.func,
  allValue: PropTypes.object,
  user: PropTypes.object,
  uploadedLabel: PropTypes.array,
  setEnterWithValue: PropTypes.func,
  setValue: PropTypes.func,
  enterWithValue: PropTypes.string,
};
