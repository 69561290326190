import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { create, update } from 'src/utils/axios';
import {
  FIN_YEAR,
  GST_TYPE,
  JOB_CARD_FORMAT,
  JOB_CARD_TYPE,
  KEY,
  MODULE_STATUS,
  PROCESS_NAME,
  TYPE_OF_CHALLAN,
  TYPE_OF_PRINTING,
  WITH_NDP,
} from 'src/_mock/constant';
import {
  Card,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Toolbar,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
// ----------------------------------------------------------------------

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();

  const groupSchema = Yup.object().shape({
    group_name: Yup.string().required('Group Name is required'),
  });

  const values = useMemo(
    () => ({
      name: row?.name || '',
      status: row?.status || false,
      description: row?.description || '',
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(groupSchema),
    values,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const onSubmit = handleSubmit(async (payload) => {
    try {
      let URL = 'roles/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h6" sx={{ flex: 1 }}>
                {row
                  ? 'Update Challan Printing (Accessories)'
                  : 'Create Challan Printing (Accessories)'}
              </Typography>

              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          <Grid item container xs={12} md={12} lg={12} xl={12} xxl={12} sx={{ p: 3 }} spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
              <RHFSelect name="type_of_challan" label="Type of Challan" size="small">
                {TYPE_OF_CHALLAN.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
              <RHFSelect name="challan_year" label="Challan Year" size="small">
                {FIN_YEAR.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
              <RHFTextField name="from_challan_no" label="From Challan No." size="small" />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
              <RHFTextField name="to_challan_no" label="To Challan No." size="small" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
              <RHFSelect name="with_ndp" label="With NDP" size="small">
                {WITH_NDP.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
              <RHFSelect name="type_of_printing" label="Type of Printing" size="small">
                {TYPE_OF_PRINTING.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
          </Grid>
        </Grid>
        <DialogActions
          sx={{
            bottom: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {row ? 'Update' : 'Add'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
