/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
/* eslint-disable new-cap */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-pascal-case */
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import React, { useState, useCallback, useEffect, useContext, useMemo } from 'react';
import { PDFDownloadLink, Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';

// @mui
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import 'src/components/pivottable/pivottable.css';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import TableRenderers from 'react-pivottable/TableRenderers';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import Plot from 'react-plotly.js';
// routes
import { paths } from 'src/routes/paths';
// components
import {
  RHFSelect,
} from 'src/components/hook-form';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import queryString from 'query-string';
import { useSnackbar } from 'src/components/snackbar';
import { useDebounce } from 'src/hooks/use-debounce';
import { useTable, TablePaginationCustom } from 'src/components/table';
import { bank_cash_report_formate, report_periods } from 'src/_mock/constant';
import { useEventListener } from 'src/hooks/use-event-listener';
import MenuItem from '@mui/material/MenuItem';
//

import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { fMoney } from 'src/_mock/constant_funcation';
import { AuthContext } from 'src/auth/context/jwt';
import moment from 'moment';
import { DateField, DatePicker } from '@mui/x-date-pickers';
import { fetcher, destroy } from 'src/utils/axios';
import LOGO from 'src/assets/logo/amideep_honda.jpg';
import Scrollbar from 'src/components/scrollbar';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Divider,
  Grid,
  IconButton,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import NotFound from 'src/sections/error/notfound-invoice';
import Iconify from 'src/components/iconify';
import { isNaN } from 'lodash';
import { MaterialReactTable, MRT_ExpandAllButton, useMaterialReactTable } from 'material-react-table';
import { useBoolean } from 'src/hooks/use-boolean';
import { aggregatorTemplates } from 'react-pivottable/Utilities';
import useSWR from 'swr';
import ReportFilters from './report-filters';
import CreateEdit from './report-new-edit-form';
import ReportSearch from './report-search';
import SaleInvoicePDF from './sale-invoice-pdf';

export function useGetReports(URL) {

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, { keepPreviousData: false });

  const memoizedValue = useMemo(
    () => ({
      reports: data?.data?.rows || [],
      allreadyusedkeys: data?.data?.allreadyusedkeys || [],
      reportLoading: isLoading,
      reportError: error,
      reportValidating: isValidating,
      reportEmpty: !isLoading && !data?.data?.rows?.length,
    }),
    [data?.data?.allreadyusedkeys, data?.data?.rows, error, isLoading, isValidating]
  );

  return memoizedValue;
}
const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
  experience: '',
};

const TABS = [
  {
    value: 'general',
    label: 'General',
    icon: <Iconify icon="solar:user-id-bold" width={24} />,
  },
  {
    value: 'matrix',
    label: 'Matrix',
    icon: <Iconify icon="solar:bill-list-bold" width={24} />,
  },
];
// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);


// see documentation for supported input formats
const data = [['attribute', 'attribute2'], ['value1', 'value2']];
// ----------------------------------------------------------------------
const based = { 1: 'SALE', 2: 'STOCK' }
const TYP = { 'i': 'INVOICE', 'c': 'CHALLAN' }

// Styles for the PDF
const styles = StyleSheet.create({
  page: { padding: 10 },
  table: { display: 'table', width: 'auto', borderCollapse: 'collapse' },
  tableRow: { flexDirection: 'row' },
  tableCell: {
    borderWidth: 1,
    borderColor: '#000', // Border color for the cells
    borderStyle: 'solid',
    padding: 5,
    fontSize: 10,
    textAlign: 'center',
  },
  headerCell: {
    backgroundColor: '#f0f0f0',
    fontWeight: 'bold',
    borderWidth: 1,
    borderColor: '#000', // Border color for header cells
    borderStyle: 'solid',
    padding: 5,
    fontSize: 10,
    textAlign: 'center',
  },
});


// Function to convert the HTML table to an array structure
const parseTableHTML = (pivotTableElement) => {
  const rows = Array.from(pivotTableElement.querySelectorAll('tr'));
  return rows.map((row) => {
    const cells = Array.from(row.querySelectorAll('th, td'));
    return cells.map((cell) => ({
      content: cell.textContent,
      colspan: cell.colSpan,
      rowspan: cell.rowSpan,
      isHeader: cell.tagName === 'TH',
    }));
  });
};

// Function to convert parsed table data to react-pdf components
const generateTable = (tableData) => {
  return (
    <View style={styles.table}>
      {tableData.map((row, rowIndex) => (
        <View key={rowIndex} style={styles.tableRow}>
          {row.map((cell, cellIndex) => (
            <View
              key={cellIndex}
              style={[
                styles.tableCell,
                cell.isHeader && styles.headerCell,
                { flexBasis: cell.colspan ? `${cell.colspan * 100}%` : 'auto' },
              ]}
              colSpan={cell.colspan}
              rowSpan={cell.rowspan}
            >
              <Text>{cell.content}</Text>
            </View>
          ))}
        </View>
      ))}
    </View>
  );
};

// Custom aggregator function for summing integers
// eslint-disable-next-line arrow-body-style
const sumIntegerAggregator = function (attributeArray) {
  const attribute1 = attributeArray[0]; // First attribute
  const attribute2 = attributeArray[1]; // Second attribute
  return function (data, rowKey, colKey) {
    let sum1 = 0;
    let sum2 = 0;
    return {
      push: (record) => {
        const value1 = parseInt(record[attribute1], 10);
        const value2 = parseInt(record[attribute2], 10);
        if (!isNaN(value1)) {
          sum1 += value1;
        }
        if (!isNaN(value2)) {
          sum2 += value2;
        }
      },
      value: () => [sum1, sum2], // Return an array of sums
      format: (x) => x.map((v) => v.toLocaleString()).join(' | '), // Format the two sums
      numInputs: 2, // Specifies that the aggregator expects two input attributes
    };
  };
};
// Custom aggregator function to sum integers
// const sumIntegerAggregator = function (attribute) {
//   return function () {
//     return {
//       sum: 0,
//       push: (record) => {
//         const value = parseInt(record[attribute], 10);
//         if (!isNaN(value)) {
//           this.sum += value;
//         }
//       },
//       value: () => this.sum,
//       format: (x) => x.toLocaleString(),
//     };
//   };
// };

export default function ListView({ reportsDetails }) {
  // const table = useTable({ defaultRowsPerPage: 10 });
  const aggregators = {
    ...aggregatorTemplates, // default aggregators provided by PivotTable
    "integersumqty": sumIntegerAggregator,
  };
  const customRenderer = (pivotData) => {
    console.log(pivotData)
    const { rows, cols } = pivotData;

    return (
      <table className="pvtTable">
        <thead>
          <tr>
            <th className="pvtAxisLabel">BRANCH</th>
            {cols.map((col) => (
              <th key={`${col}-qty`} className="pvtColLabel" colSpan="1">
                {col.join(' ')} QTY
              </th>
            ))}
            {cols.map((col) => (
              <th key={`${col}-amount`} className="pvtColLabel" colSpan="1">
                {col.join(' ')} AMOUNT
              </th>
            ))}
            <th className="pvtTotalLabel">Totals</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((rowKey) => (
            <tr key={rowKey.join('-')}>
              <th className="pvtRowLabel">{rowKey.join(' - ')}</th>
              {cols.map((colKey) => {
                const aggregator = pivotData.getAggregator(rowKey, colKey);
                const values = aggregator.value();
                return (
                  <>
                    <td className="pvtVal">{values.qty}</td>
                    <td className="pvtVal">{values.amount}</td>
                  </>
                );
              })}
              <td className="pvtRowTotal">
                {pivotData.getAggregator(rowKey, []).value().qty}
              </td>
            </tr>
          ))}
          <tr>
            <th className="pvtTotalLabel" colSpan="1">Totals</th>
            {cols.map((colKey) => {
              const totalAggregator = pivotData.getAggregator([], colKey);
              const totalValues = totalAggregator.value();
              return (
                <>
                  <td className="pvtTotal">{totalValues.qty}</td>
                  <td className="pvtTotal">{totalValues.amount}</td>
                </>
              );
            })}
            <td className="pvtGrandTotal">
              {pivotData.getAggregator([], []).value().qty}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };
  const users = useSWR(
    `users?page=${1}&limit=${1000}&status=true&forpermission=true&`,
    fetcher,
    { keepPreviousData: false }
  );

  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();
  const { user } = useContext(AuthContext);
  const reports = useGetReports(`automobile/reports/report?page=${1}&limit=${100}&status=true&userId=${user.id}&`)

  const startDate = moment(user?.co_year?.sdate);
  const endDate = moment(user?.co_year?.edate);

  // Format the dates in the desired format
  const formattedStartDate = startDate.format('DD/MM/YYYY');
  const formattedEndDate = endDate.format('DD/MM/YYYY');

  // Convert formattedStartDate and formattedEndDate back to moment objects for comparison
  const startDateMoment = moment();
  const endDateMoment = moment();

  const [reactpdfData, setReactpdfData] = useState([]);
  const [pivotData, setPivotData] = useState({});
  const [currentReport, setCurrentReport] = useState({});

  const [information, setData] = useState({
    ac_groupId: null,
    accountId: null,
    t_type: 'i',
    basedon: 1,
    formate: '2',
    period: '1',
    fdate: startDateMoment,
    tdate: endDateMoment,
    accountList: [],
    accountMeta: {
      page: 1,
      name: '',
      totalPage: 1,
      editRow: null,
    },
  });
  const [pageDimensions, setPageDimensions] = useState({ width: 600, height: 400 }); // Default size
  const handleExportRows = (rows) => {
    const doc = new jsPDF({ orientation: 'landscape' });
    const tableHeaders = columns.map((c) => c.header);
    const tableHeaderObj = {}
    columns.forEach((c) => {
      tableHeaderObj[c.header] = c.id
    });
    const tableData = rows.map((row) => tableHeaders.map(y => row.original[tableHeaderObj[y]]));
    // Text for the header

    const companyName = user?.companies.find((x) => x.id === user.companyId)?.name;
    const reportTitle = `DAILY ${based[information.basedon]} REPORT ${moment(information.fdate).format('DD-MM-YYYY')} TO ${moment(information.tdate).format('DD-MM-YYYY')}`;

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      styles: { fontSize: 6 },
      margin: { top: 20 },
      didDrawPage: (data) => {
        // Set font to bold and size
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold');

        // Add company name on the left side
        doc.text(`COMPANY : ${companyName}`, data.settings.margin.left, 10);

        // Add the logo on the left side within the header height
        const imgWidth = 15; // Adjust width according to your logo
        const imgHeight = 15; // Adjust height according to your logo
        const logoXPos = data.settings.margin.left + 2; // Adjust X position
        const logoYPos = 10; // Adjust Y position as needed
        // doc.addImage(LOGO, 'PNG', logoXPos, logoYPos, imgWidth, imgHeight);
        // Calculate the center position for the report title
        const pageWidth = doc.internal.pageSize.getWidth();
        const centerPosition = pageWidth / 2;

        // Add the report title at the top center of each page
        doc.text(reportTitle, centerPosition, 10, { align: 'center' });
      },
      didDrawCell: (data) => {
        if (data.cell.section === 'body') {
          const rowData = rows[data.row.index].original;
          console.log(table.getGr)

        }
      }
    });
    doc.save(`${based[information.basedon]}_VEHICLE_${TYP[information.t_type]}_REPORT_${moment().format('DD_MM_YYYY_HH_MM_ss')}.pdf`);
  };
  const exportHTMLTableToPDF = () => {
    const pixelToPointRatio = 96 / 72; // Conversion ratio from pixels to points
    const widthInPoints = 2000 / pixelToPointRatio; // Convert 2000px to points
    const pivotTableElement = document.getElementsByClassName('pvtTable')[0];
    if (pivotTableElement) {
      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'pt', // Set the unit to points
        format: [widthInPoints, 595.28], // 595.28 is the default height for A4 landscape in points
        compressPdf: true
      });
      const tableHeaders = columns.map((c) => c.header);
      const tableHeaderObj = {}
      columns.forEach((c) => {
        tableHeaderObj[c.header] = c.id
      });

      const companyName = user?.companies.find((x) => x.id === user.companyId)?.name;
      const reportTitle = `DAILY ${based[information.basedon]} REPORT ${moment(information.fdate).format('DD-MM-YYYY')} TO ${moment(information.tdate).format('DD-MM-YYYY')}`;

      // Use the html method of autoTable to directly convert the HTML table to a PDF
      autoTable(doc, {
        html: pivotTableElement,
        margin: { top: 20 },
        styles: { fontSize: 8 },
        didDrawPage: (data) => {
          // Set font to bold and size
          doc.setFontSize(10);
          doc.setFont('helvetica', 'bold');

          // Add company name on the left side
          doc.text(`COMPANY : ${companyName}`, data.settings.margin.left, 10);

          // Add the logo on the left side within the header height
          const imgWidth = 15; // Adjust width according to your logo
          const imgHeight = 15; // Adjust height according to your logo
          const logoXPos = data.settings.margin.left + 2; // Adjust X position
          const logoYPos = 10; // Adjust Y position as needed
          // doc.addImage(LOGO, 'PNG', logoXPos, logoYPos, imgWidth, imgHeight);
          // Calculate the center position for the report title
          const pageWidth = doc.internal.pageSize.getWidth();
          const centerPosition = pageWidth / 2;

          // Add the report title at the top center of each page
          doc.text(reportTitle, centerPosition, 10, { align: 'center' });
        },
      });

      doc.save('pivot-table-export.pdf');
    } else {
      console.error('Pivot table element not found.');
    }
  };

  const exportHTMLTOREACtPDF = () => {
    const pivotTableElement = document.getElementsByClassName('pvtTable')[0];
    if (pivotTableElement) {
      const parsedData = parseTableHTML(pivotTableElement);
      setTableData(parsedData);
      // Get the dimensions of the pivot table element
      const { offsetWidth: width, offsetHeight: height } = pivotTableElement;
      setPageDimensions({ width, height });
    }
  }


  // eslint-disable-next-line react/no-unstable-nested-components
  const MyDocument = () => (
    <Document>
      <Page size={[pageDimensions.width, pageDimensions.height + 1000]} style={styles.page}>
        {generateTable(tableData)}
      </Page>
    </Document>
  );
  const openFilters = useBoolean();
  const createedit = useBoolean();
  const creatreport = useBoolean();
  const [filters, setFilters] = useState(defaultFilters);
  const handleFilters = useCallback((name, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);
  const canReset = !isEqual(defaultFilters, filters);
  const [tableData, setTableData] = useState([]);
  const [reportData, setReportData] = useState({});
  const [count, setCount] = useState(0);
  const view = useBoolean();
  const [search, setSearch] = useState({
    query: '',
    results: [],
  });
  const handleSearch = useCallback(
    (inputValue) => {
      setSearch((prevState) => ({
        ...prevState,
        query: inputValue,
      }));

      if (inputValue) {
        const results = reports.reports.filter(
          (job) => job.name?.toLowerCase().indexOf(search.query.toLowerCase()) !== -1
        );

        setSearch((prevState) => ({
          ...prevState,
          results,
        }));
      }
    },
    [reports.reports, search.query]
  );
  useEffect(() => {
    exportHTMLTOREACtPDF()
  }, [reports.reports])

  useEffect(() => {
    if (currentReport.setting)
      setPivotData(pre => ({ ...pre, ...currentReport.setting }))
  }, [currentReport])
  useEffect((s) => {
    const temp = {};

    const findIndex = reports.reports.findIndex(x => x.id === filters.experience);
    console.log(filters.experience, findIndex, reports.reports)
    if (findIndex >= 0) {
      const updatedBCDATA = [...reports.reports]; // Create a new array to maintain immutability
      updatedBCDATA[findIndex] = {
        ...updatedBCDATA[findIndex],
        setting: {
          ...updatedBCDATA[findIndex].setting,
          ...temp
        }
      };

      setCurrentReport(updatedBCDATA[findIndex])
    }
    else {
      setCurrentReport({})
    }

  }, [reports.reports, filters.experience]); // Added excludepviot dependency if it changes


  const [currentTab, setCurrentTab] = useState('general');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);
  const columns = [
    {
      header: 'BRANCH',
      accessorKey: 'branchName',
      id: 'branchName',
      width: 50
    },
    {
      header: 'MODEL',
      accessorKey: 'productName',
      id: 'productName',
      size: 250
    },
    {
      header: 'CUSTOMER NAME',
      accessorKey: 'customerName',
      id: 'customerName',
      size: 250
    },
    {
      header: 'INV NO',
      accessorKey: 'identity',
      id: 'identity',
    },
    {
      header: 'INV DT',
      accessorKey: 'dt',
      id: 'dt',
    },
    {
      header: 'COLOUR',
      accessorKey: 'colourName',
      id: 'colourName',
    },

    {
      header: 'CHASSIS NO',
      accessorKey: 'chassisNo',
      id: 'chassisNo',
    },
    {
      header: 'ENGIN NO',
      accessorKey: 'enginNo',
      id: 'enginNo',
    },
    {
      header: 'AMOUNT',
      accessorKey: 'amount',
      id: 'amount',
    },
    {
      header: 'TOTAL',
      accessorKey: 'sold',
      id: 'sold',
      AggregatedCell: ({ cell, table }) => (
        <>
          Total by {' '}
          {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header} :{' '}
          <Box
            sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
          >
            {' '} &nbsp;{cell.getValue()}
          </Box>
        </>
      ),
      Footer: () => (
        <Stack>
          Total :
          <Box color="warning.main">{totalvehicle}</Box>
        </Stack>
      ),
    }
  ];
  const totalvehicle = useMemo(
    () => {
      if (reportData && reportData.rows && Array.isArray(reportData.rows))
        return reportData.rows.reduce((acc, curr) => acc + curr.sold, 0)
      return 0
    },
    [reportData],
  );
  const table = useMaterialReactTable({
    columns,
    data: reportData && reportData.rows && Array.isArray(reportData.rows) ? reportData.rows : [],
    displayColumnDefOptions: {
      'mrt-row-expand': {
        Header: () => (
          <Stack direction="row" alignItems="center">
            <MRT_ExpandAllButton table={table} />
            <Box>Groups</Box>
          </Stack>
        ),
        GroupedCell: ({ row, table }) => {
          const { grouping } = table.getState();
          return row.getValue(grouping[grouping.length - 1]);
        },
        enableResizing: true,
        muiTableBodyCellProps: ({ row }) => ({
          sx: (theme) => ({
            color:
              row.depth === 0
                ? theme.palette.primary.main
                : row.depth === 1
                  ? theme.palette.secondary.main
                  : undefined,
          }),
        }),
        size: 200,
      },
    },
    muiSelectProps: {
      sx: {
        // Applies styles to rows per page dropdown
        backgroundColor: '#ffffff',
        border: '1px solid #dcdcdc',
        borderRadius: '5px',
        padding: '4px 8px',
        fontSize: '14px',
        color: '#333',
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)',
        },
        '&:active': {
          transform: 'translateY(2px)',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    enableGrouping: true,
    enableColumnResizing: true,
    columnFilterDisplayMode: 'popover',
    groupedColumnMode: 'reorder',
    enableColumnFilters: true,
    enableFilters: true,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    initialState: {
      density: 'compact',
      expanded: true,
      pagination: { pageIndex: 0, pageSize: 20 },
      sorting: [{ id: 'productName', desc: false }],
      grouping: ['branchName', 'productName'],
      // columnPinning: {
      //   left: ['branchName', 'productName'],
      //   right: ['sold'],
      // },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        {/* <PDFDownloadLink
          document={<SaleInvoicePDF />}
          fileName="12321"
          style={{ textDecoration: 'none' }}
        >
          {({ loading }) => (
            <Tooltip title="Download">
              <IconButton>
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <Iconify icon="eva:cloud-download-fill" />
                )}
              </IconButton>
            </Tooltip>
          )}
        </PDFDownloadLink> */}
        <Tooltip title="View">
          <IconButton onClick={view.onTrue}>
            <Iconify icon="solar:eye-bold" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Download PDF File" arrow>
          <IconButton onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          } disabled={table.getPrePaginationRowModel().rows.length === 0}>
            <Iconify icon="vscode-icons:file-type-pdf2" />
          </IconButton>
        </Tooltip>

        {/* <Grid item container xs={12} sm={12} md={11} lg={11} xl={11} xxl={11} spacing={2}>
          <Grid item sm={12} md={6} lg={2} xl={2}>
            <Autocomplete
              autoHighlight
              fullWidth
              freeSolo
              size="small"
              options={throughstock?.map((option) => option.name)}
              getOptionLabel={(option) => option}
              value={
                throughstock?.find((data) => data.value === information.basedon)
                  ?.name || ''
              }
              onChange={(event, newValue) => {
                const label = throughstock?.find(
                  (data) => data.name === newValue
                )?.value;
                if (label) {
                  setData({ ...information, basedon: label });
                }
              }}
              renderInput={(params) => (
                <TextField {...params} name="basedon" label="Type Of Stock" />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
            />
          </Grid>
          <Grid item sm={12} md={6} lg={2} xl={2}>
            <Autocomplete
              autoHighlight
              fullWidth
              freeSolo
              size="small"
              options={typeofstock?.map((option) => option.name)}
              getOptionLabel={(option) => option}
              value={
                typeofstock?.find((data) => data.value === information.t_type)
                  ?.name || ''
              }
              onChange={(event, newValue) => {
                const label = typeofstock?.find(
                  (data) => data.name === newValue
                )?.value;
                if (label) {
                  setData({ ...information, t_type: label });
                }
              }}
              renderInput={(params) => (
                <TextField {...params} name="t_type" label="Based On" />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
            />
          </Grid>
          <Grid item sm={12} md={6} lg={4} xl={2}>
            <Autocomplete
              autoHighlight
              fullWidth
              freeSolo
              size="small"
              options={report_periods?.map((option) => option.label)}
              getOptionLabel={(option) => option}
              value={
                report_periods?.find((data) => data.value === information.period)?.label || ''
              }
              onChange={(event, newValue) => {
                const label = report_periods?.find((data) => data.label === newValue)?.value;
                if (label) {
                  setData({ ...information, period: label });
                }
              }}
              renderInput={(params) => (
                <TextField {...params} name="bookId" label="Select Periods" />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
            />
          </Grid>
          <Grid item sm={12} md={6} lg={4} xl={2}>
            <Autocomplete
              autoHighlight
              fullWidth
              freeSolo
              size="small"
              options={bank_cash_report_formate?.map((option) => option.label)}
              getOptionLabel={(option) => option}
              value={
                bank_cash_report_formate?.find((data) => data.value === information.formate)
                  ?.label || ''
              }
              onChange={(event, newValue) => {
                const label = bank_cash_report_formate?.find(
                  (data) => data.label === newValue
                )?.value;
                console.log(label);
                if (label) {
                  setData({ ...information, formate: label });
                }
              }}
              renderInput={(params) => (
                <TextField {...params} name="formate" label="Select Format" />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
            />
          </Grid>
          <Grid item sm={12} md={6} lg={4} xl={2}>
            <DateField
              value={new Date(information.fdate)}
              onChange={(newValue) => {
                setData({ ...information, fdate: newValue });
              }}
              format="dd/MM/yyyy"
              label="From Date"
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small',
                },
              }}
            />
          </Grid>
          <Grid item sm={12} md={6} lg={4} xl={2}>
            <DateField
              value={new Date(information.tdate)}
              onChange={(newValue) => {
                setData({ ...information, tdate: newValue });
              }}
              format="dd/MM/yyyy"
              label="To Date"
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small',
                },
              }}
            />
          </Grid>
        </Grid> */}
        {/* <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button> */}
        {/* <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button> */}
      </Box>
    ),
  });


  const debouncedQuery = useDebounce(filters);



  const notFound = count === 0;

  const { page } = table;
  const limit = table.rowsPerPage;

  const getAll = useCallback(async () => {
    try {
      let URL = 'automobile/reports/sales';

      if (information.t_type && information.fdate && information.tdate) {
        let t_type = '';
        if (information.basedon === 1) {
          if (information.t_type === 'c')
            t_type = 'c'
          if (information.t_type === 'i')
            t_type = 'i'
        }
        if (information.basedon === 2) {
          if (information.t_type === 'c')
            t_type = 'cs'
          if (information.t_type === 'i')
            t_type = 'is'
        }
        URL += `?page=${page + 1}&limit=${limit}&t_type=${t_type}&fdate=${moment(information.fdate).format('YYYY-MM-DD 00:00:00')
          }&tdate=${moment(information.tdate).format('YYYY-MM-DD 23:59:59')}&asc=id&wantbooking=false&isCashbook=true&reportType=${currentTab}&`;
        if (Object.keys(debouncedQuery).length) {
          const nefil = { ...debouncedQuery };
          if (debouncedQuery.status === 'all') {
            delete nefil.status;
          }
          URL += queryString.stringify(nefil);
        }

        const { data } = await fetcher(URL);
        console.log(data)
        if (data) {
          setReportData(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [currentTab, debouncedQuery, information.basedon, information.fdate, information.t_type, information.tdate, limit, page]);

  const handleDeleteRows = useCallback(
    async (id, confirm) => {
      try {
        let URL = 'automobile/reports/report/';
        if (id) {
          URL += id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          console.log(confirm)
          confirm.onFalse();
          getAll();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [enqueueSnackbar, getAll]
  );

  useEffect(() => {
    getAll();
  }, [getAll, information]);
  useEffect(() => {
    console.log(information.t_type)
    getAll();
  }, [getAll, information.t_type]);
  const downloadPdf = async () => {

  };
  const typeofstock = useMemo(() => [{ value: 'i', name: 'invoice' }, { value: 'c', name: 'challan' }], []);
  const throughstock = useMemo(() => [{ value: 1, name: 'SALES' }, { value: 2, name: 'STOCK' }], []);


  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      table.setIsFullScreen(!table.getState().isFullScreen);
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      {/* <CustomBreadcrumbs
        heading={reportsDetails?.name}
        links={[
          { name: 'Dashboard', href: paths.home },
          {
            name: reportsDetails?.name || 'Report',
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      /> */}
      {/* <Card sx={{ p: 2 }}>
        <Grid container>
          <Grid item container xs={12} sm={12} md={11} lg={11} xl={11} xxl={11} spacing={2}>
            <Grid item sm={12} md={6} lg={2} xl={2}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={throughstock?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={
                  throughstock?.find((data) => data.value === information.basedon)
                    ?.name || ''
                }
                onChange={(event, newValue) => {
                  const label = throughstock?.find(
                    (data) => data.name === newValue
                  )?.value;
                  if (label) {
                    setData({ ...information, basedon: label });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} name="basedon" label="Type Of Stock" />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={2} xl={2}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={typeofstock?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={
                  typeofstock?.find((data) => data.value === information.t_type)
                    ?.name || ''
                }
                onChange={(event, newValue) => {
                  const label = typeofstock?.find(
                    (data) => data.name === newValue
                  )?.value;
                  if (label) {
                    setData({ ...information, t_type: label });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} name="t_type" label="Based On" />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />
            </Grid>

            <Grid item sm={12} md={6} lg={4} xl={2}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={report_periods?.map((option) => option.label)}
                getOptionLabel={(option) => option}
                value={
                  report_periods?.find((data) => data.value === information.period)?.label || ''
                }
                onChange={(event, newValue) => {
                  const label = report_periods?.find((data) => data.label === newValue)?.value;
                  if (label) {
                    setData({ ...information, period: label });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} name="bookId" label="Select Periods" />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={4} xl={2}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={bank_cash_report_formate?.map((option) => option.label)}
                getOptionLabel={(option) => option}
                value={
                  bank_cash_report_formate?.find((data) => data.value === information.formate)
                    ?.label || ''
                }
                onChange={(event, newValue) => {
                  const label = bank_cash_report_formate?.find(
                    (data) => data.label === newValue
                  )?.value;
                  console.log(label);
                  if (label) {
                    setData({ ...information, formate: label });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} name="formate" label="Select Format" />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={4} xl={2}>
              <DatePicker
                value={new Date(information.fdate)}
                onChange={(newValue) => {
                  setData({ ...information, fdate: newValue });
                }}
                format="dd/MM/yyyy"
                label="From Date"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                  },
                }}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={4} xl={2}>
              <DatePicker
                value={new Date(information.tdate)}
                onChange={(newValue) => {
                  setData({ ...information, tdate: newValue });
                }}
                format="dd/MM/yyyy"
                label="To Date"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} md={1} lg={1} xl={1} xxl={1}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'right' }}>
                <Tooltip title="Download PDF File" arrow>
                  <IconButton onClick={downloadPdf}>
                    <Iconify icon="vscode-icons:file-type-pdf2" />
                  </IconButton>
                </Tooltip>

              <Tooltip title="Download Excel File" arrow>
                  <IconButton >
                    <Iconify icon="vscode-icons:file-type-excel" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card> */}
      <>
        <Card sx={{ p: 0, mt: 1, zIndex: table.getState().isFullScreen ? 1111 : undefined, }} id="pdf-content">
          <Grid container spacing={5} sx={{ p: 5 }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
              DAILY {based[information.basedon]} BRANCH / INSTITUTE
            </Typography>
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={2}>
              <Grid item sm={12} md={6} lg={2} xl={1.5}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  freeSolo
                  size="small"
                  options={throughstock?.map((option) => option.name)}
                  getOptionLabel={(option) => option}
                  value={
                    throughstock?.find((data) => data.value === information.basedon)
                      ?.name || ''
                  }
                  onChange={(event, newValue) => {
                    const label = throughstock?.find(
                      (data) => data.name === newValue
                    )?.value;
                    if (label) {
                      setData({ ...information, basedon: label });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} name="basedon" label="Type Of Stock" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={2} xl={1.5}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  freeSolo
                  size="small"
                  options={typeofstock?.map((option) => option.name)}
                  getOptionLabel={(option) => option}
                  value={
                    typeofstock?.find((data) => data.value === information.t_type)
                      ?.name || ''
                  }
                  onChange={(event, newValue) => {
                    const label = typeofstock?.find(
                      (data) => data.name === newValue
                    )?.value;
                    if (label) {
                      setData({ ...information, t_type: label });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} name="t_type" label="Based On" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={2} xl={1.5}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  freeSolo
                  size="small"
                  options={report_periods?.map((option) => option.label)}
                  getOptionLabel={(option) => option}
                  value={
                    report_periods?.find((data) => data.value === information.period)?.label || ''
                  }
                  onChange={(event, newValue) => {
                    const label = report_periods?.find((data) => data.label === newValue)?.value;
                    if (label) {
                      setData({ ...information, period: label });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} name="bookId" label="Select Periods" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={2} xl={1.5}>
                <DateField
                  value={new Date(information.fdate)}
                  onChange={(newValue) => {
                    setData({ ...information, fdate: newValue });
                  }}
                  format="dd/MM/yyyy"
                  label="From Date"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                    },
                  }}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={2} xl={1.5}>
                <DateField
                  value={new Date(information.tdate)}
                  onChange={(newValue) => {
                    setData({ ...information, tdate: newValue });
                  }}
                  format="dd/MM/yyyy"
                  label="To Date"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                    },
                  }}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={2} xl={1.5}>
                <ReportSearch
                  query={search.query}
                  results={search.results}
                  onSearch={handleSearch}
                  hrefItem={(id) => paths.dashboard.job.details(id)}
                />

              </Grid>
              <Grid item sm={12} md={6} lg={2} xl={1.5}>
                <ReportFilters
                  open={openFilters.value}
                  onOpen={openFilters.onTrue}
                  onClose={openFilters.onFalse}
                  handleDeleteRows={handleDeleteRows}
                  //
                  data={reports.reports}
                  filters={filters}
                  onFilters={handleFilters}
                  //
                  canReset={canReset}
                />

              </Grid>

              <Grid item sm={12} md={6} lg={2} xl={1.5}>
                <Tooltip title="Download PDF File" arrow>
                  <IconButton onClick={() =>
                    exportHTMLTableToPDF()
                  } disabled={table.getPrePaginationRowModel().rows.length === 0}>
                    <Iconify icon="vscode-icons:file-type-pdf2" />
                  </IconButton>
                </Tooltip>

              </Grid>
              {/* <Grid item sm={12} md={6} lg={2} xl={1.5}>
                <Tooltip title="Download REACT PDF File" arrow>
                  <PDFDownloadLink document={<MyDocument />} fileName="react_pdf_pivot_table.pdf">
                    {({ loading }) => (loading ? 'Generating PDF...' : 'Download PDF')}
                  </PDFDownloadLink>
                </Tooltip>
              </Grid> */}
              <Grid item xs={1} />
              <Grid item sm={12} md={6} lg={2} xl={1.5}>

                <CreateEdit users={users?.data?.data?.rows.filter(x => x.id !== user.id)} open={creatreport.value}
                  onOpen={creatreport.onTrue}
                  onClose={creatreport.onFalse}
                  reportlist={reports.reports.filter(x => x.userId === user.id)}
                  currentData={{
                    name: '',
                    reportcategoryId: null,
                    perrecuser: {},
                    reportpermissions: [{ perrecusId: null, edit: false, share: false }],
                    reportshortcutkey: { key: null }
                  }} pivotData={pivotData} alreadyUsedKeyList={reports.allreadyusedkeys.filter(y => y !== currentReport?.reportshortcutkey?.key)} />
                {currentReport?.id && <CreateEdit users={users?.data?.data?.rows.filter(x => x.id !== user.id)} open={createedit.value}
                  onOpen={createedit.onTrue}
                  onClose={createedit.onFalse}
                  reportlist={reports.reports.filter(x => x.userId === user.id)}
                  currentData={currentReport} pivotData={pivotData} alreadyUsedKeyList={reports.allreadyusedkeys.filter(y => y !== currentReport?.reportshortcutkey?.key)} />}

              </Grid>
              <Grid item sm={12} md={6} lg={2} xl={5}>
                <h2>{currentReport?.name}</h2>
              </Grid>

            </Grid>
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={2}>
              <Tabs
                value={currentTab}
                onChange={handleChangeTab}
                sx={{
                  mx: { xs: 3 },
                  mb: { xs: 3, md: 5 },
                }}
              >
                {TABS.map((tab) => (
                  <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
                ))}
              </Tabs>

            </Grid>
            {/* <TableContainer sx={{ overflow: 'unset', mt: 5 }}>
            <Scrollbar>
              <Table sx={{ minWidth: 960 }}>
                <TableHead>
                  <TableRow>
                    <TableCell rowSpan="2">Model</TableCell>
                    <TableCell rowSpan="2">Color</TableCell>
                    {reportData?.branches?.length && <TableCell colSpan={reportData.branches.length} >Branch</TableCell>}
                    <TableCell rowSpan="2">Total</TableCell>
                  </TableRow>
                  <TableRow>
                    {reportData?.branches?.map((branch) => (
                      <TableCell key={branch}>{branch}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {reportData?.rows?.map((row, rowIndex) => {
                    const bd = {}
                    reportData.branches.forEach(x => {
                      bd[x] = 0
                    })
                    return (
                      <React.Fragment key={rowIndex}>
                        <TableRow>
                          <TableCell rowSpan={row.colors.length + 1} style={{ verticalAlign: 'top' }}>
                            {row.model}
                          </TableCell>
                        </TableRow>
                        {row?.colors?.map((color, colorIndex) => (
                          <TableRow key={colorIndex}>
                            <TableCell>{color.name}</TableCell>
                            {reportData.branches.map((branch) => {
                              bd[branch] += color[branch];
                              return (
                                <TableCell key={branch}>{color[branch]}</TableCell>
                              )
                            })}
                            <TableCell>{color.total}</TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell style={{ fontWeight: '400', textAlign: 'right' }} />
                          <TableCell sx={{ typography: 'subtitle1' }} style={{ fontWeight: '400', textAlign: 'center' }}>
                            Total
                          </TableCell>
                          {reportData.branches.map((branch) => (
                            <TableCell key={branch}>{bd[branch]}</TableCell>
                          ))}
                          <TableCell style={{ fontWeight: '400' }}>
                            {row.colors.reduce((acc, color) => acc + color.total, 0)}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )
                  })}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer> */}
            {/* <table border="1" style={{ borderCollapse: 'collapse', width: '70%', fontFamily: 'Poppins, sans-serif' }}>
            <thead>
              <tr>
                <th rowSpan="2">Model</th>
                <th rowSpan="2">Color</th>
                {reportData?.branches?.length && <th colSpan={reportData.branches.length} >Branch</th>}
                <th rowSpan="2">Total</th>
              </tr>
              <tr>
                {reportData?.branches?.map((branch) => (
                  <th key={branch}>{branch}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {reportData?.rows?.map((row, rowIndex) => {
                const bd = {}
                reportData.branches.forEach(x => {
                  bd[x] = 0
                })
                return (
                  <React.Fragment key={rowIndex}>
                    <tr>
                      <td rowSpan={row.colors.length + 1} style={{ verticalAlign: 'top' }}>
                        {row.model}
                      </td>
                    </tr>
                    {row?.colors?.map((color, colorIndex) => (
                      <tr key={colorIndex}>
                        <td>{color.name}</td>
                        {reportData.branches.map((branch) => {
                          bd[branch] += color[branch];
                          return (
                            <td key={branch}>{color[branch]}</td>
                          )
                        })}
                        <td>{color.total}</td>
                      </tr>
                    ))}
                    <tr>
                      <td style={{ fontWeight: '400', textAlign: 'right' }} />
                      <td style={{ fontWeight: '400', textAlign: 'right' }}>
                        Total
                      </td>
                      {reportData.branches.map((branch) => (
                        <td key={branch}>{bd[branch]}</td>
                      ))}
                      <td style={{ fontWeight: '400' }}>
                        {row.colors.reduce((acc, color) => acc + color.total, 0)}
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table> */}
            {/* <TableContainer sx={{ overflow: 'unset' }}>
            <Scrollbar sx={{ maxHeight: 2000 }}>
              <Table stickyHeader sx={{ minWidth: 800 }}>
                <TableHead>
                  <TableRow>
                    <TableCell rowSpan="2" >Model</TableCell>
                    <TableCell rowSpan="2">Color</TableCell>
                    {reportData?.branches?.length && <TableCell colSpan={reportData.branches.length} align="center">Branch</TableCell>}
                    <TableCell rowSpan="2">Total</TableCell>
                  </TableRow>
                  <TableRow>
                    {reportData?.branches?.map((branch) => (
                      <TableCell key={branch} style={{ top: 57 }}>{branch}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData?.rows?.slice(
                    table.page * table.rowsPerPage,
                    table.page * table.rowsPerPage + table.rowsPerPage
                  )?.map((row, rowIndex) => {
                    const bd = {}
                    reportData.branches.forEach(x => {
                      bd[x] = 0
                    })
                    return (
                      <React.Fragment key={rowIndex}>
                        <TableRow>
                          <TableCell rowSpan={row.colors.length + 1} style={{ verticalAlign: 'top' }}>
                            {row.model}
                          </TableCell>
                        </TableRow>
                        {row?.colors?.map((color, colorIndex) => (
                          <TableRow key={colorIndex}>
                            <TableCell>{color.name}</TableCell>
                            {reportData.branches.map((branch) => {
                              bd[branch] += color[branch];
                              return (
                                <TableCell key={branch}>{color[branch]}</TableCell>
                              )
                            })}
                            <TableCell>{color.total}</TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell style={{ fontWeight: '400', textAlign: 'right' }} />
                          <TableCell sx={{ typography: 'subtitle1' }} style={{ fontWeight: '400', textAlign: 'center' }}>
                            Total
                          </TableCell>
                          {reportData.branches.map((branch) => (
                            <TableCell key={branch}>{bd[branch]}</TableCell>
                          ))}
                          <TableCell style={{ fontWeight: '400' }}>
                            {row.colors.reduce((acc, color) => acc + color.total, 0)}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )
                  })}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={reportData?.rows?.length || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          /> */}
            {currentTab === "matrix" && <Scrollbar >
              <Box sx={{ width: '100%' }}>
                <PivotTableUI
                  data={reportData && reportData.rows && Array.isArray(reportData.rows) ? reportData.rows : []}
                  onChange={s => {
                    delete s.data;
                    setPivotData(s)
                  }}
                  unusedOrientationCutoff="Infinity"
                  renderers={({ ...TableRenderers, ...PlotlyRenderers })}
                  // {...reports.reports.findIndex(x => x.value === filters.experience) >= 0 ? reports.reports[reports.reports.findIndex(x => x.value === filters.experience)].pivotfilter : {}}
                  {...pivotData}
                />
              </Box></Scrollbar>}
            {currentTab === "general" && <><MaterialReactTable table={table} muiTablePaperProps={({ table }) => ({
              style: {
                zIndex: table.getState().isFullScreen ? 333333 : undefined,
              }
            })} />
              <Dialog fullScreen open={view.value}>
                <Box sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
                  <DialogActions
                    sx={{
                      p: 1.5,
                    }}
                  >
                    <Button color="inherit" variant="contained" onClick={view.onFalse}>
                      Close
                    </Button>
                  </DialogActions>

                  {/* <Box sx={{ flexGrow: 1, height: 1, overflow: 'hidden' }}>
                <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
                  <SaleInvoicePDF invoice={}/>
                </PDFViewer>
              </Box> */}
                </Box>
              </Dialog></>}
          </Grid>
        </Card>
        {reportData.length === 0 && <NotFound name="Report" />}
      </>
    </Container >
  );
}

ListView.propTypes = {
  reportsDetails: PropTypes.object,
};
