import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { RHFSelect } from 'src/components/hook-form';
import { alpha, Divider } from '@mui/material';
import { create, fetcher } from 'src/utils/axios';
import { HOST_API } from 'src/config-global';
import { DEPARTMENT_ARRAY, TRANSACTION_TYPEARRAY } from 'src/_mock/constant';
// ----------------------------------------------------------------------

export default function UserTableToolbar({
  filters,
  onFilters,
  //
  branches,
  yearOptions,
  getAll,
  permission,
}) {
  const popover = usePopover();
  const fileRef = useRef(null);

  const handleAttach = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };
  const handleFilterName = useCallback(
    (event) => {
      onFilters('name', event.target.value);
    },
    [onFilters]
  );

  const handleFilterRole = useCallback(
    (event) => {
      onFilters(
        't_type',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );
  const handleFilter = useCallback(
    (event, fieldname) => {
      onFilters(
        fieldname,
        event.target.value
      );
    },
    [onFilters]
  );
  const downloadFile = async () => {
    try {
      const URL = `automobile/entries/export/?t_type=7,13&token=${localStorage.getItem('token')}`;
      const data = await fetcher(URL, true);
      if (data) {
        window.open(`${HOST_API}${URL}}`, 'blank');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const uploadFile = async (data) => {
    try {
      const URL = 'entries/import/';
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formdata = new FormData();
      formdata.append('file', data);
      const response = await create({ url: URL, payload: formdata, config });
      if (response) {
        getAll();
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <input
        ref={fileRef}
        type="file"
        style={{ display: 'none' }}
        multiple={false}
        onChange={async (e) => {
          if (e.target.files['0']) {
            await uploadFile(e.target.files['0']);
            e.target.value = '';
          }
        }}
      />
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 100 },
          }}
        >
          <InputLabel sx={{ backgroundColor: (theme) => alpha(theme.palette.background.paper, 1) }}>
            Branch
          </InputLabel>

          <Select
            value={filters.branchId}
            onChange={(e) => handleFilter(e, 'branchId')}
            input={<OutlinedInput label="Branch" />}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            <MenuItem key='all' value='all'>
              All
            </MenuItem>
            {branches.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.short_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 100 },
          }}
        >
          <InputLabel sx={{ backgroundColor: (theme) => alpha(theme.palette.background.paper, 1) }}>
            Doc Type
          </InputLabel>

          <Select
            value={filters.t_type}
            onChange={(e) => handleFilter(e, 't_type')}
            input={<OutlinedInput label="t_type" />}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            <MenuItem key='7,13' value='7,13'>
              All
            </MenuItem>
            {TRANSACTION_TYPEARRAY.filter(x => [7, 13].includes(x.value)).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 130 },
          }}
        >
          <InputLabel sx={{ backgroundColor: (theme) => alpha(theme.palette.background.paper, 1) }}>
            Department
          </InputLabel>

          <Select
            value={filters.workshop}
            onChange={(e) => handleFilter(e, 'workshop')}
            input={<OutlinedInput label="workshop" />}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            <MenuItem key='true,false' value='true,false'>
              All
            </MenuItem>
            {DEPARTMENT_ARRAY.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <TextField
            fullWidth
            value={filters.name}
            onChange={handleFilterName}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />

          {(permission?.p || permission?.i || permission?.e) && (
            <IconButton onClick={popover.onOpen}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          )}
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {permission?.p && (
          <MenuItem
            onClick={() => {
              popover.onClose();
            }}
          >
            <Iconify icon="solar:printer-minimalistic-bold" />
            Print
          </MenuItem>
        )}

        {permission?.i && (
          <MenuItem
            onClick={() => {
              handleAttach();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:import-bold" />
            Import
          </MenuItem>
        )}

        {permission?.e && (
          <MenuItem
            onClick={() => {
              downloadFile();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:export-bold" />
            Export
          </MenuItem>
        )}
      </CustomPopover>
    </>
  );
}

UserTableToolbar.propTypes = {
  filters: PropTypes.object,
  permission: PropTypes.object,
  onFilters: PropTypes.func,
  getAll: PropTypes.func,
  yearOptions: PropTypes.array,
  branches: PropTypes.array,
};
