import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Autocomplete,
  Button,
  DialogActions,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { KEY, PARTNER_STATUS, TYPE_OF_ACCOUNTS, WORKING_PARTNER } from 'src/_mock/constant';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import { LoadingButton } from '@mui/lab';
import { UploadAvatar } from 'src/components/upload';
import { DatePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import { HOST_API } from 'src/config-global';
import { useDebounce } from 'src/hooks/use-debounce';
import { AuthContext } from 'src/auth/context/jwt';
import CompanyYear from 'src/layouts/_common/company-year';
import { Stack } from '@mui/system';
import { useBgColorAutocomplete } from 'src/_mock/constant_funcation';

export default function UserQuickEditForm({
  row,
  open,
  onClose,
  table,
  getAll,
  tableData,
  setComLoader,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [cityValue, setCityValue] = useState({ key: '', search: '' });

  const { user, initialize } = useContext(AuthContext);

  const bgColorAutocomplete = useBgColorAutocomplete();



  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required('Name is required').max(50, 'Name must be at most 50 characters'),
    })
    .defined();

  const values = useMemo(
    () => ({
      state: row?.state || null,
      isHighligh: '',
      isHighlighBranch: '',
      country: row?.country || null,
      cityList: row?.cityList || [],
      phone_no: row?.phone_no || '',
      name: row?.name || null,
      short_name: row?.short_name || null,
      status: row?.status || false,
      countryId: row?.countryId || null,
      stateId: row?.stateId || null,
      cityId: row?.cityId || null,
      district: row?.district || null,
      address: row?.address || null,
      pincode: row?.pincode || null,
      companyId: row?.companyId || null,
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    values,
  });

  const {
    reset,
    control,
    watch,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = methods;

  const allValues = watch();


  useEffect(() => {
    if (allValues?.gst_no && allValues?.gst_no.length === 15) {
      const panNumber = allValues?.gst_no.substring(2, 12);
      setValue('pan_no', panNumber);
      if (allValues.co_ownId === 1) setValue(`com_mems[0].pan_no`, panNumber);
      else setValue(`com_mems[0].pan_no`, null);
    } else {
      setValue('pan_no', null);
    }
  }, [allValues.co_ownId, allValues?.gst_no, setValue]);

  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      if (!payload.companyId) {
        payload.companyId = user.companyId;
      }
      let URL = 'companybranches/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
          setComLoader(true);
        }
      }
      setLoading(false);
      reset();
      initialize();
    } catch (error) {
      setLoading(false);
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if (!loading) {
        await trigger();
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  const searchCity = useDebounce(cityValue);


  const HandleCityList = useCallback(async () => {
    let URL = 'cities/';
    URL += `?page=${1}&limit=${100}&name=${searchCity.search}&status=true`;

    const { data } = await fetcher(URL);
    if (searchCity.key) {
      setValue(searchCity.key, data?.rows);
    }
  }, [searchCity, setValue]);

  useEffect(() => {
    if (open) {
      HandleCityList();
    }
  }, [HandleCityList, open]);


  return (
    <Dialog fullScreen open={open} onKeyDown={handleKeyDown}>
      <FormProvider methods={methods}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 111111,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update Company Branch' : 'Add Company Branch'}
              </Typography>

              <CompanyYear />

              <IconButton
                color="inherit"
                edge="start"
                onClick={() => {
                  onClose();
                }}
              >
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>

            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          <Grid container item xs={12} md={12} justifyContent="center" sx={{ p: 1 }}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              spacing={2}
              alignContent="flex-start"
              sx={{ p: 2 }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Typography variant="subtitle1">Main Details</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                <RHFTextField
                  name="name"
                  label={
                    <span>
                      Branch Name <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  size="small"
                  autoFocus
                  value={allValues.name}
                  onChange={(e) => {
                    setValue('name', e.target.value.toUpperCase());
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField
                  name="short_name"
                  label="Short Name"
                  size="small"
                  value={allValues.short_name}
                  onChange={(e) => {
                    setValue('short_name', e.target.value.toUpperCase());
                  }}
                />
              </Grid>


              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="address" label="Address" size="small" multiline rows={3} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="district" label="Area" size="small" />
              </Grid>

              <Grid item xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
                <Autocomplete
                  autoHighlight={allValues.isHighligh}
                  fullWidth
                  size="small"
                  openOnFocus
                  onBlur={() => {
                    setValue('isHighligh', '');
                  }}
                  options={allValues.cityList?.map((option) => option.name)}
                  value={allValues.cityList?.find((x) => x.id === allValues.cityId)?.name || ''}
                  onInputChange={(event, newInputValue) => {
                    const city = allValues.cityList?.find((x) => x.name === newInputValue);
                    setValue('isHighligh', newInputValue);
                    if (newInputValue) {
                      setCityValue({ key: 'cityList', search: newInputValue });
                      if (city) {
                        reset({
                          ...allValues,
                          cityId: city.id,
                          stateId: city.state.id,
                          countryId: city.country.id,
                          state: city.state,
                          country: city.country,
                        });
                      }
                    }
                  }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} name="cityId" label="Select City" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                  ListboxProps={{ ...bgColorAutocomplete }}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField name="pincode" label="Pincode" size="small" type="number" />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  freeSolo
                  size="small"
                  options={[allValues?.state]?.map((option) => option?.name)}
                  getOptionLabel={(option) => option}
                  value={allValues?.state?.name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="stateId"
                      label="Select State"
                      error={errors?.stateId && !allValues?.stateId}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                  disabled="true"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  freeSolo
                  size="small"
                  options={[allValues?.country]?.map((option) => option?.name)}
                  getOptionLabel={(option) => option}
                  value={allValues?.country?.name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="countryId"
                      label="Select Country"
                      error={errors?.countryId && !allValues?.countryId}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                  disabled="true"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <RHFTextField name="email" label="Email" size="small" />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <RHFTextField name="phone_no" label="Phone no" size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                <RHFCheckbox name="status" label="Active" size="small" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <DialogActions
          sx={{
            bottom: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Stack
            direction="row-reverse"
            alignItems="center"
            sx={{
              width: '100%',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Tooltip title="(Alt + A)" arrow>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={async () => {
                  await trigger();
                  onSubmit();
                }}
              >
                {row ? 'Update' : 'Add'}
              </LoadingButton>
            </Tooltip>

            <Tooltip title="(Esc)" arrow>
              <Button variant="outlined" onClick={onClose} sx={{ mr: 2 }}>
                Cancel
              </Button>
            </Tooltip>

            <Tooltip title="(Reset)" arrow>
              <Button variant="soft" onClick={() => reset(values)} sx={{ mr: 2 }}>
                Reset
              </Button>
            </Tooltip>
          </Stack>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
  setComLoader: PropTypes.bool,
};
