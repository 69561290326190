import { useState, useCallback, useContext } from 'react';
import useSWR from 'swr';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FormatMoney } from 'format-money-js';

// components
import { useSettingsContext } from 'src/components/settings';
import Divider from '@mui/material/Divider';
import { Backdrop, Card, CircularProgress, Grid } from '@mui/material';
import { fetcher } from 'src/utils/axios';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { AuthContext } from 'src/auth/context/jwt';
import Page500 from 'src/sections/error/500-view';

// ----------------------------------------------------------------------

export default function View() {
  const settings = useSettingsContext();
  const { user } = useContext(AuthContext);

  const [selectedRow, setSelectedRow] = useState(null);
  const [role, setRole] = useState('1');
  const [startDate, setStartDate] = useState(moment(user?.co_year?.sdate));
  const [endDate, setEndDate] = useState(moment(user?.co_year?.edate));
  const { data, isLoading, error } = useSWR(
    `transaction/reports/?ac_groupId=${role}&startDate=${moment(startDate).format(
      'YYYY-MM-DD'
    )}&endDate=${moment(endDate).format('YYYY-MM-DD')}`,
    fetcher,
    { keepPreviousData: false }
  );

  // const ma = (data, iscredit) => {
  //   const Total = iscredit ? "creditTotal" : "debitTotal";
  //   const report = [];
  //   data.children
  //     .filter((x) => (iscredit ? x.creditTotal > 0 : x.debitTotal > 0))
  //     .forEach((x, index) => {
  //       report.push({ value: '', label: x.name, gt: 'G' });
  //       x.accounts
  //         .filter((x) => (iscredit ? x.creditTotal > 0 : x.debitTotal > 0))
  //         .forEach((y) => {
  //           report.push({
  //             value: iscredit ? y.creditTotal : y.debitTotal,
  //             label: y.name,
  //             gt: 'S',
  //             id: y.id,
  //           });
  //         });
  //       report.push({ value: iscredit ? x.creditTotal : x.debitTotal, label: '', gt: 'T' });
  //     });
  //   return report;
  // };
  const ma = (data, iscredit) => {
    const report = [];
    const Total = iscredit ? "creditTotal" : "debitTotal";
    const acd = iscredit ? 1 : 2;
    console.log(data)
    data.children
      .filter((x) => (x.acd === acd))
      .forEach((x, index) => {
        report.push({ value: '', label: x.name, gt: 'G' });
        x.accounts
          .filter((x) => (x.creditTotal > 0 || x.debitTotal > 0)).sort((a, b) => a.name.localeCompare(b.name))
          .forEach((y) => {
            report.push({
              value: iscredit ? (y.debitTotal - y.creditTotal) : (y.creditTotal - y.debitTotal), // Modified line
              label: y.name,
              gt: 'S',
              id: y.id,
            });
          });
        report.push({
          value: iscredit ? (x.debitTotal - x.creditTotal) : (x.creditTotal - x.debitTotal), // Modified line
          label: '',
          gt: 'T'
        });
      });
    return report;
  };

  const fm = new FormatMoney({
    grouping: true,
    decimals: 2,
  });

  const saleAmount =
    data && Array.isArray(data?.data) && data?.data[0].children?.find((x) => x.id === 11);

  const handleChangeRole = useCallback((event, newRole) => {
    if (newRole !== null) {
      setRole(newRole);
    }
  }, []);

  const debitTotal = data?.data?.[0]?.debitTotal || 0;
  const grossTotal = data?.data?.[0]?.grossTotal || 0;

  const result = data?.data?.length ? debitTotal + grossTotal : 0;

  const handleRowSelect = useCallback((rowId) => {
    setSelectedRow(rowId);
  }, []);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'} height="70vh !important">
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
          <ToggleButtonGroup
            exclusive
            value={role}
            size="small"
            onChange={handleChangeRole}
            sx={{ mb: 5 }}
          >
            <ToggleButton value="1" aria-label="Trading">
              Trading
            </ToggleButton>
            <ToggleButton value="2" aria-label="P & L A/C">
              P & L A/C
            </ToggleButton>
            <ToggleButton value="3" aria-label="Balance Sheet">
              Balance Sheet
            </ToggleButton>

            <ToggleButton value="1,2" aria-label="Trading + P&L">
              Trading + P&L
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item container xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
            <DatePicker
              label="Start date"
              format="dd/MM/yyyy"
              onChange={(e) => {
                setStartDate(moment(e).format('YYYY-MM-DD'));
              }}
              value={new Date(startDate)}
              slotProps={{
                textField: {
                  fullWidth: false,
                  size: 'small',
                },
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
            <DatePicker
              label="End date"
              format="dd/MM/yyyy"
              onChange={(e) => {
                setEndDate(moment(e).format('YYYY-MM-DD'));
              }}
              value={new Date(endDate)}
              slotProps={{
                textField: {
                  fullWidth: false,
                  size: 'small',
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      {
        // eslint-disable-next-line no-nested-ternary
        isLoading ? (
          <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}>
            <CircularProgress color="primary" />
          </Backdrop>
        ) : error ? (
          <Typography variant="h5">
            <Page500 />
          </Typography>
        ) : (
          <Card container sx={{ display: 'flex' }}>

            <Grid
              container
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              borderLeft="1px solid black"
            >
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                sx={{
                  bgcolor: (theme) => theme.palette.primary.light,
                  padding: '2px 0px',
                  color: 'white',
                }}
              >
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={5}
                  lg={5}
                  xl={5}
                  xxl={5}
                  textAlign="right"
                  paddingRight="20px"
                >
                  <b>Amount</b>
                </Grid>
                <Grid item xs={6} sm={6} md={7} lg={7} xl={7} xxl={7} textAlign="left">
                  <b>{{ 1: 'Debit', 2: 'Expense', 3: 'Asset', '1,2': 'Debit / Expense' }[role]}</b>
                </Grid>
              </Grid>
              {data?.data?.length &&
                data?.data.map((y1) => (
                  <>
                    <Grid
                      container
                      alignContent="baseline"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      height={{ xs: 'auto', md: '55vh' }}
                      overflow="auto"
                    >
                      {ma(y1, false).map((x, index) => (
                        <>
                          {index !== 0 && x.gt === 'G' && (
                            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                              <Divider
                                sx={{ borderStyle: 'dashed', width: '80%', margin: 'auto' }}
                              />
                            </Grid>
                          )}

                          <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            key={index}
                            tabIndex={0}
                            onClick={() => handleRowSelect(index)}
                            style={{
                              backgroundColor: selectedRow === index ? 'lightgray' : 'transparent',
                            }}
                          >
                            {x.gt === 'G' ? (
                              <>
                                <Grid
                                  item
                                  xs={6}
                                  sm={6}
                                  md={5}
                                  lg={5}
                                  xl={5}
                                  xxl={5}
                                  textAlign="right"
                                  paddingRight="20px"
                                />
                                <Grid
                                  item
                                  xs={6}
                                  sm={6}
                                  md={7}
                                  lg={7}
                                  xl={7}
                                  xxl={7}
                                  textAlign="left"
                                  color="red"
                                >
                                  <b>{x.label} </b>
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid
                                  item
                                  xs={6}
                                  sm={6}
                                  md={5}
                                  lg={5}
                                  xl={5}
                                  xxl={5}
                                  textAlign="right"
                                  paddingRight="20px"
                                >
                                  {x.gt === 'T' ? (
                                    <strong>{fm.from(x.value)}</strong>
                                  ) : (
                                    fm.from(x.value)
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  sm={6}
                                  md={7}
                                  lg={7}
                                  xl={7}
                                  xxl={7}
                                  textAlign="left"
                                >
                                  {x.label}
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </>
                      ))}
                    </Grid>
                    {(() => {
                      const to = y1?.grossTotal;
                      // eslint-disable-next-line no-unused-expressions

                      if (to === 0 || to > 0) {
                        if (to > 0) {
                          return (
                            <Grid
                              container
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              sx={{ backgroundColor: '#c2edc2' }}
                            >
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={5}
                                lg={5}
                                xl={5}
                                xxl={5}
                                textAlign="right"
                                paddingRight="20px"
                              >
                                <b>{fm.from(to)}</b>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={7}
                                lg={7}
                                xl={7}
                                xxl={7}
                                textAlign="left"
                              >
                                <b>
                                  {
                                    {
                                      1: 'Gross Profit GP ',
                                      2: 'Net Profit NP ',
                                      3: 'Difference ',
                                    }[role > 3 || y1.id]
                                  }
                                  : &nbsp; &nbsp;
                                  {saleAmount?.creditTotal &&
                                    ((to / saleAmount.creditTotal) * 100).toFixed(2)}{' '}
                                  %
                                </b>
                              </Grid>
                            </Grid>
                          );
                        }
                        return (
                          <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            sx={{ backgroundColor: '#c2edc2' }}
                          >
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              textAlign="right"
                              paddingRight="20px"
                              borderTop="1px solid black"
                            >
                              <b>{fm.from(to)}</b>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={7}
                              lg={7}
                              xl={7}
                              xxl={7}
                              textAlign="left"
                              borderTop="1px solid black"
                            >
                              <b>
                                {
                                  {
                                    1: 'Gross Profit GP ',
                                    2: 'Net Profit NP ',
                                    3: 'Difference ',
                                  }[role > 3 || y1.id]
                                }
                                {saleAmount?.creditTotal &&
                                  ((to / saleAmount.creditTotal) * 100).toFixed(2)}
                                %
                              </b>
                            </Grid>
                          </Grid>
                        );
                      }
                      return (
                        <Grid
                          container
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          sx={{ visibility: 'hidden' }}
                        >
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={5}
                            lg={5}
                            xl={5}
                            xxl={5}
                            textAlign="right"
                            paddingRight="20px"
                          />
                          -
                        </Grid>
                      );
                    })()}
                  </>
                ))}

              {role !== '4' && (
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  sx={{
                    bgcolor: (theme) => theme.palette.primary.main,
                    padding: '2px 0px',
                    color: 'white',
                  }}
                >
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={5}
                    lg={5}
                    xl={5}
                    xxl={5}
                    textAlign="right"
                    paddingRight="20px"
                  >
                    <b>{fm.from(result)}</b>
                  </Grid>
                  <Grid item xs={6} sm={6} md={7} lg={7} xl={7} xxl={7} textAlign="left">
                    <b>Total</b>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              borderRight="1px solid black"
            >
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                sx={{
                  bgcolor: (theme) => theme.palette.primary.light,
                  padding: '2px 0px',
                  color: 'white',
                }}
              >
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={5}
                  lg={5}
                  xl={5}
                  xxl={5}
                  textAlign="right"
                  paddingRight="20px"
                >
                  <b>Amount</b>
                </Grid>
                <Grid item xs={6} sm={6} md={7} lg={7} xl={7} xxl={7} textAlign="left">
                  <b>
                    {{ 1: 'Credit', 2: 'Income', 3: 'Liability', '1,2': 'Credit / Income' }[role]}
                  </b>
                </Grid>
              </Grid>

              {data?.data?.length &&
                data?.data.map((x1) => (
                  <>
                    <Grid
                      container
                      alignContent="baseline"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      height={{ xs: 'auto', md: '55vh' }}
                      overflow="auto"
                    >
                      {ma(x1, true).map((x, index) => (
                        <>
                          {index !== 0 && x.gt === 'G' && (
                            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                              <Divider
                                sx={{ borderStyle: 'dashed', width: '80%', margin: 'auto' }}
                              />
                            </Grid>
                          )}
                          {x.gt === 'G' ? (
                            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={5}
                                lg={5}
                                xl={5}
                                xxl={5}
                                textAlign="right"
                                paddingRight="20px"
                              />

                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={7}
                                lg={7}
                                xl={7}
                                xxl={7}
                                textAlign="left"
                                color="red"
                              >
                                <b>{x.label} </b>
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={5}
                                lg={5}
                                xl={5}
                                xxl={5}
                                textAlign="right"
                                paddingRight="20px"
                              >
                                {x.gt === 'T' ? (
                                  <strong>{fm.from(x.value)}</strong>
                                ) : (
                                  fm.from(x.value)
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={7}
                                lg={7}
                                xl={7}
                                xxl={7}
                                textAlign="left"
                              >
                                {x.label}
                              </Grid>
                            </Grid>
                          )}
                        </>
                      ))}
                    </Grid>
                    {(() => {
                      const to = x1?.grossTotal;
                      // eslint-disable-next-line no-unused-expressions
                      if (to === 0 || to < 0) {
                        if (to < 0) {
                          return (
                            <Grid
                              container
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              sx={{ backgroundColor: '#fbc7c7' }}
                            >
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={5}
                                lg={5}
                                xl={5}
                                xxl={5}
                                textAlign="right"
                                paddingRight="20px"
                              >
                                <b>{fm.from(Math.abs(to))}</b>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={7}
                                lg={7}
                                xl={7}
                                xxl={7}
                                textAlign="left"
                              >
                                <b>
                                  {
                                    { 1: 'Gross Loss GL ', 2: 'Net Loss NP ', 3: 'Difference ' }[
                                    role > 3 || x1.id
                                    ]
                                  }
                                  {saleAmount?.creditTotal &&
                                    Math.abs((to / saleAmount.creditTotal) * 100).toFixed(2)}{' '}
                                  %
                                </b>
                              </Grid>
                            </Grid>
                          );
                        }
                        return (
                          <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            sx={{ backgroundColor: '#fbc7c7' }}
                          >
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              textAlign="right"
                              paddingRight="20px"
                              borderTop="1px solid black"
                            >
                              <b>{fm.from(to)}</b>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={7}
                              lg={7}
                              xl={7}
                              xxl={7}
                              textAlign="left"
                              borderTop="1px solid black"
                            >
                              <b>
                                {
                                  { 1: 'Gross Loss GL ', 2: 'Net Loss NP ', 3: 'Difference ' }[
                                  role > 3 || x1.id
                                  ]
                                }
                                {saleAmount?.creditTotal &&
                                  Math.abs((to / saleAmount.creditTotal) * 100).toFixed(2)}{' '}
                                %
                              </b>
                            </Grid>
                          </Grid>
                        );
                      }
                      return (
                        <Grid
                          container
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          sx={{ visibility: 'hidden' }}
                        >
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={5}
                            lg={5}
                            xl={5}
                            xxl={5}
                            textAlign="right"
                            paddingRight="20px"
                          />
                          -
                        </Grid>
                      );
                    })()}

                    {role !== '4' && (
                      <Grid
                        container
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        sx={{
                          bgcolor: (theme) => theme.palette.primary.main,
                          padding: '2px 0px',
                          color: 'white',
                        }}
                      >
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={5}
                          lg={5}
                          xl={5}
                          xxl={5}
                          textAlign="right"
                          paddingRight="20px"
                        >
                          <b>{fm.from(x1.creditTotal)}</b>
                        </Grid>
                        <Grid item xs={6} sm={6} md={7} lg={7} xl={7} xxl={7} textAlign="left">
                          <b>Total</b>
                        </Grid>
                      </Grid>
                    )}
                  </>
                ))}
            </Grid>

          </Card>
        )
      }
    </Container>
  );
}
