import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { COMMODITY_TYPE, KEY, REVERSE_CHARGE_APPLICABLE } from 'src/_mock/constant';
import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  IconButton,
  ListItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import { DatePicker } from '@mui/x-date-pickers';
import CompanyYear from 'src/layouts/_common/company-year';
import { Stack } from '@mui/system';
import { GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { useBgColorAutocomplete } from 'src/_mock/constant_funcation';
import { useDebounce } from 'src/hooks/use-debounce';

export default function GSTCommodityQuickEditForm({
  row,
  open,
  onClose,
  table,
  getAll,
  tableData,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [gstSlabList, setGSTSlabList] = useState([]);
  const [hsncode, setHsncode] = useState(null);
  const [hsnSACList, setHSNSACList] = useState([]);
  const [loading, setLoading] = useState(false);

  const bgColorAutocomplete = useBgColorAutocomplete();

  const GSTCommoditySchema = Yup.object().shape({
    description: Yup.string().required('Description is required'),
    com_type: Yup.string().required('Commodity Type is required'),
    re_ch_ap: Yup.string().required('Reverse Charge Applicable  is required'),
    re_ch_pa: Yup.number().nullable().notRequired().min(0),
  });

  const values = useMemo(
    () => ({
      description: row?.description || '',
      com_type: row?.com_type || '',
      re_ch_ap: row?.re_ch_ap || '',
      re_ch_pa: row?.re_ch_pa || '',
      gst_com_details: row?.gst_com_details?.map((data) => ({
        id: data?.id || null,
        gst_rateId: data?.gst_rateId || '',
        hsn_sac_codeId: data?.hsn_sac_codeId || '',
        hsn_sac_code: data?.hsn_sac_code || null,
        appdate: data?.appdate || new Date(),
      })) || [
        {
          id: null,
          gst_rateId: '',
          hsn_sac_codeId: '',
          hsn_sac_code: null,
          appdate: new Date(),
        },
      ],
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(GSTCommoditySchema),
    values,
    mode: 'onChange',
  });

  const {
    reset,
    trigger,
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const gst_com_details = useFieldArray({
    control,
    name: 'gst_com_details',
  });

  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      let URL = 'automobile/gst_commodities/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({
          url: URL,
          payload: { ...payload, productId: row?.productId },
        });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({
          url: URL,
          payload: { ...payload, productId: row?.productId },
        });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
      setLoading(false);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  const handleAdd = () => {
    gst_com_details?.append({
      id: null,
      gst_rateId: '',
      hsn_sac_codeId: '',
      hsn_sac_code: null,
      appdate: new Date(),
    });
  };

  const handleRemove = (index) => {
    gst_com_details?.remove(index);
  };

  const HandleGSTSlabList = async () => {
    let URL = 'automobile/gst_rates/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setGSTSlabList(data?.rows);
  };

  const debouncedQuery = useDebounce(hsncode);

  const HandleHSNSACList = useCallback(async (codeId) => {
    try {
      let URL = 'automobile/hsn_sac_code/';
      URL += `?page=${1}&limit=${50}&status=true&`;
      if (codeId) URL += `code=${codeId}`;
      const { data } = await fetcher(URL);
      if (data?.rows) {
        setHSNSACList(data?.rows);
      } else {
        setHSNSACList([]);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (debouncedQuery) HandleHSNSACList(debouncedQuery);
  }, [HandleHSNSACList, debouncedQuery]);

  useEffect(() => {
    if (open) {
      HandleGSTSlabList();
      HandleHSNSACList();
    }
  }, [HandleHSNSACList, open]);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 740 },
      }}
    >
      <FormProvider methods={methods}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h6" sx={{ flex: 1 }}>
                {row ? 'Update GST Commodity' : 'Add GST Commodity'}
              </Typography>

              <CompanyYear />

              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          <Grid item container xs={12} md={12} lg={12} xl={12} xxl={12} sx={{ p: 3 }} spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <RHFTextField
                name="description"
                label={
                  <span>
                    Description<span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={COMMODITY_TYPE?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={COMMODITY_TYPE?.find((data) => data.id === allValues.com_type)?.name || ''}
                onChange={(event, newValue) => {
                  setValue('com_type', COMMODITY_TYPE?.find((data) => data.name === newValue)?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="com_type"
                    label={
                      <span>
                        Commodity Type<span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    error={errors?.com_type && !allValues?.com_type}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={REVERSE_CHARGE_APPLICABLE?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={
                  REVERSE_CHARGE_APPLICABLE?.find((data) => data.id === allValues.re_ch_ap)?.name ||
                  ''
                }
                onChange={(event, newValue) => {
                  setValue(
                    're_ch_ap',
                    REVERSE_CHARGE_APPLICABLE?.find((data) => data.name === newValue)?.id
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="re_ch_ap"
                    label={
                      <span>
                        Revs Charge Applicable<span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    error={errors?.re_ch_ap && !allValues?.re_ch_ap}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
              <RHFTextField
                name="re_ch_pa"
                label={
                  <span>
                    Revs Charge Payable (%)<span style={{ color: 'red' }}>*</span>
                  </span>
                }
                type="number"
                size="small"
                min={0}
              />
            </Grid>

            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              spacing={2}
              sx={{ mt: 1 }}
            >
              {gst_com_details?.fields?.map((fieldName, index) => (
                <Grid item container spacing={2} key={fieldName.id}>
                  <Grid item xs={12} sm={12} md={3.8} lg={3.8} xl={3.8} xxl={3.8}>
                    <RHFSelect
                      name={`gst_com_details[${index}].gst_rateId`}
                      label="GST Slab"
                      size="small"
                    >
                      {gstSlabList?.map((data) => (
                        <MenuItem key={data.description} value={data.id}>
                          {data.description}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3.8} lg={3.8} xl={3.8} xxl={3.8}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      size="small"
                      options={
                        hsnSACList?.length ? hsnSACList : [{ name: 'No Options', value: null }]
                      }
                      groupBy={(option) => option?.a}
                      getOptionLabel={(option) => option.code}
                      openOnFocus
                      onInputChange={(event) => {
                        setHsncode(event?.target?.value);
                      }}
                      onChange={(event, newValue) => {
                        setValue(`gst_com_details[${index}].hsn_sac_codeId`, newValue?.id);
                        setValue(`gst_com_details[${index}].hsn_sac_code`, newValue);
                      }}
                      value={allValues.gst_com_details[index].hsn_sac_code}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="HSN/SAC"
                          name={`gst_com_details[${index}].hsn_sac_codeId`}
                        />
                      )}
                      renderGroup={(params) => (
                        <li key={params.key}>
                          <GroupHeader component="li">
                            <ListItem
                              sx={{
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              Code
                            </ListItem>
                            <ListItem
                              sx={{
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              Description
                            </ListItem>
                          </GroupHeader>
                          <GroupItems>{params.children}</GroupItems>
                        </li>
                      )}
                      componentsProps={{
                        paper: {
                          sx: {
                            width: 600,
                          },
                        },
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                          <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                            {option?.code}
                          </ListItem>
                          <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                            {option?.description}
                          </ListItem>
                        </Box>
                      )}
                      ListboxProps={{
                        ...bgColorAutocomplete,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3.8} lg={3.8} xl={3.8} xxl={3.8}>
                    <Controller
                      name={`gst_com_details[${index}].appdate`}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DatePicker
                          defaultValue={new Date(field.value)}
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          format="dd/MM/yyyy"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!error,
                              helperText: error?.message,
                              size: 'small',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {index !== 0 && (
                    <Grid item xs={12} sm={12} md={0.6} lg={0.6} xl={0.6} xxl={0.6}>
                      <Tooltip title="Remove" arrow>
                        <IconButton color="error" onClick={() => handleRemove(index)}>
                          <Iconify icon="solar:trash-bin-trash-bold" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              ))}
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} sx={{ m: 3 }}>
                <Button
                  size="small"
                  color="primary"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                  onClick={handleAdd}
                  sx={{ flexShrink: 0 }}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Stack
          direction="row-reverse"
          alignItems="center"
          sx={{
            bottom: 0,
            zIndex: 111111,
            p: 3,
            position: 'sticky',
            width: '100%',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
          id="modelfooter"
        >
          <Tooltip title="(Alt + A)" arrow>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={async () => {
                if (await trigger()) {
                  onSubmit();
                }
              }}
            >
              {row ? 'Update' : 'Add'}
            </LoadingButton>
          </Tooltip>

          <Tooltip title="(Esc)" arrow>
            <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
          </Tooltip>

          <Button
            variant="soft"
            onClick={() => {
              reset(values);
            }}
            sx={{ mr: 2 }}
          >
            Reset
          </Button>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}

GSTCommodityQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
