import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import { Icon } from '@iconify/react';
import { Autocomplete, Checkbox, Grid, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { fetcher } from 'src/utils/axios';
import { AuthContext } from 'src/auth/context/jwt';
import { DateField, DatePicker, DatePickerToolbar } from '@mui/x-date-pickers';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function FileManagerFileDetails({
  open,
  onClose,
  productFilter,
  setProductFilter,
  getAll,
}) {
  const { user } = useContext(AuthContext);

  const HandleModelCategoryList = useCallback(async () => {
    let URL = 'automobile/product/categories/';
    URL += `?page=${1}&limit=${10000}&status=true&`;
    const { data } = await fetcher(URL);
    setProductFilter((prevState) => ({
      ...prevState,
      // eslint-disable-next-line no-unsafe-optional-chaining
      modelCategory: [{ name: 'Select All', id: '0' }, ...data?.rows],
    }));
  }, [setProductFilter]);

  const HandleModelNameList = useCallback(
    async (sCategory) => {
      let URL = 'automobile/product/models/';
      URL += `?page=${1}&limit=${10000}&status=true&amodcategoryId=${sCategory
        .map((x) => x.id)
        .join(',')}&`;
      const { data } = await fetcher(URL);
      setProductFilter((prevState) => ({
        ...prevState,
        // eslint-disable-next-line no-unsafe-optional-chaining
        modelName: [{ name: 'Select All', id: '0' }, ...data?.rows],
      }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [setProductFilter]
  );

  useEffect(() => {
    HandleModelNameList(productFilter?.selectedCategories);
  }, [HandleModelNameList, productFilter?.selectedCategories]);

  const HandleModelVariantList = useCallback(async () => {
    let URL = 'automobile/product/variants/';
    URL += `?page=${1}&limit=${10000}&status=true&amodcategoryId=${productFilter?.selectedModelName
      .map((x) => x.id)
      .join(',')}&`;
    const { data } = await fetcher(URL);
    setProductFilter((prevState) => ({
      ...prevState,
      // eslint-disable-next-line no-unsafe-optional-chaining
      ModelVariant: [{ name: 'Select All', id: '0' }, ...data?.rows],
    }));
  }, [productFilter?.selectedModelName, setProductFilter]);

  useEffect(() => {
    HandleModelVariantList(productFilter?.selectedModelName);
  }, [HandleModelVariantList, productFilter?.selectedModelName]);

  const HandleModelColorList = useCallback(async () => {
    let URL = 'automobile/product/colours/';
    URL += `?page=${1}&limit=${10000}&status=true&amodcategoryId=${productFilter?.selectedModelVariant
      .map((x) => x.id)
      .join(',')}&`;
    const { data } = await fetcher(URL);
    setProductFilter((prevState) => ({
      ...prevState,
      // eslint-disable-next-line no-unsafe-optional-chaining
      modelColor: [{ name: 'Select All', id: '0' }, ...data?.rows],
    }));
  }, [productFilter?.selectedModelVariant, setProductFilter]);

  useEffect(() => {
    HandleModelColorList(productFilter?.selectedModelVariant);
  }, [HandleModelColorList, productFilter?.selectedModelVariant]);

  const HandleDSEList = async () => {
    let URL = 'users/';
    URL += `?page=${1}&limit=${10000}&status=true&departmentId=2&companyId=${user?.companyId}&`;
    const { data } = await fetcher(URL);
    setProductFilter((prevState) => ({
      ...prevState,
      // eslint-disable-next-line no-unsafe-optional-chaining
      dseName: [{ name: 'Select All', id: '0' }, ...data?.rows],
    }));
  };

  useEffect(() => {
    if (open && productFilter?.selectedCategories.length === 0) {
      console.log('HELLO');
      HandleModelCategoryList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HandleModelCategoryList, open, productFilter?.selectedCategories]);

  useEffect(() => {
    if (open && productFilter?.dseName.length === 0) {
      HandleDSEList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, productFilter?.dseName]);

  return (
    <Drawer
      open={open}
      // onClose={onClose}
      anchor="right"
      slotProps={{
        backdrop: { invisible: true },
      }}
      sx={{ zIndex: 1300 }}
      PaperProps={{
        sx: { width: 320 },
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2 }}>
        <Typography variant="h6"> FILTER BY </Typography>

        <Icon
          icon="carbon:close-filled"
          color="primary"
          fontSize="large"
          onClick={onClose}
          style={{ cursor: 'pointer' }}
        />
      </Stack>

      <Stack
        spacing={2}
        justifyContent="center"
        sx={{
          p: 2,
          bgcolor: 'background.neutral',
          zIndex: 100,
        }}
      >
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          sx={{ p: 1 }}
          spacing={1}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Autocomplete
              multiple
              options={productFilter?.modelCategory}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              value={productFilter?.selectedCategories}
              onChange={(event, newValue) => {
                const selectAllOptionId = '0'; // ID for "Select All" option
                const isSelectAllOptionSelected = newValue.some((v) => v.id === selectAllOptionId);
                const wasSelectAllOptionPreviouslySelected = productFilter.selectedCategories.some(
                  (v) => v.id === selectAllOptionId
                );

                // If "Select All" is being unselected, clear all selections
                if (wasSelectAllOptionPreviouslySelected && !isSelectAllOptionSelected) {
                  setProductFilter((prevState) => ({
                    ...prevState,
                    selectedCategories: [],
                    selectedModelName: [],
                    selectedModelVariant: [],
                    selectedModelColor: [],
                  }));
                } else if (isSelectAllOptionSelected) {
                  // If "Select All" is selected, select all options
                  if (
                    productFilter.selectedCategories.length <
                    productFilter.modelCategory.length - 1
                  ) {
                    setProductFilter((prevState) => ({
                      ...prevState,
                      selectedCategories: prevState.modelCategory,
                    }));
                  }
                } else {
                  // Regular selection/deselection of options
                  setProductFilter((prevState) => ({
                    ...prevState,
                    selectedCategories: newValue,
                    selectedModelName: [],
                    selectedModelVariant: [],
                    selectedModelColor: [],
                  }));
                }
              }}
              renderTags={(value, getTagProps) =>
                value?.length > 0
                  ? [
                      <span {...getTagProps({ index: 0 })} key={0}>
                        {value[0]?.name}
                        {value?.length > 1 ? ', ...' : ''}
                      </span>,
                    ]
                  : []
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Model Category" placeholder="Select Model Category" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Autocomplete
              multiple
              options={productFilter?.modelName}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              value={productFilter?.selectedModelName}
              onChange={(event, newValue) => {
                const selectAllOptionId = '0'; // ID for "Select All" option
                const isSelectAllOptionSelected = newValue.some((v) => v.id === selectAllOptionId);
                const wasSelectAllOptionPreviouslySelected = productFilter.selectedModelName.some(
                  (v) => v.id === selectAllOptionId
                );

                // If "Select All" is being unselected, clear all selections
                if (wasSelectAllOptionPreviouslySelected && !isSelectAllOptionSelected) {
                  setProductFilter((prevState) => ({
                    ...prevState,
                    selectedModelName: [],
                    selectedModelVariant: [],
                    selectedModelColor: [],
                  }));
                } else if (isSelectAllOptionSelected) {
                  // If "Select All" is selected, select all options
                  if (productFilter.selectedModelName.length < productFilter.modelName.length - 1) {
                    setProductFilter((prevState) => ({
                      ...prevState,
                      selectedModelName: prevState.modelName,
                    }));
                  }
                } else {
                  // Regular selection/deselection of options
                  setProductFilter((prevState) => ({
                    ...prevState,
                    selectedModelName: newValue,
                    selectedModelVariant: [],
                    selectedModelColor: [],
                  }));
                }
              }}
              renderTags={(value, getTagProps) =>
                value?.length > 0
                  ? [
                      <span {...getTagProps({ index: 0 })} key={0}>
                        {value[0]?.name}
                        {value?.length > 1 ? ', ...' : ''}
                      </span>,
                    ]
                  : []
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Model Name" placeholder="Select Model Name" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Autocomplete
              multiple
              options={productFilter?.ModelVariant}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              value={productFilter?.selectedModelVariant}
              onChange={(event, newValue) => {
                const selectAllOptionId = '0'; // ID for "Select All" option
                const isSelectAllOptionSelected = newValue.some((v) => v.id === selectAllOptionId);
                const wasSelectAllOptionPreviouslySelected =
                  productFilter.selectedModelVariant.some((v) => v.id === selectAllOptionId);

                // If "Select All" is being unselected, clear all selections
                if (wasSelectAllOptionPreviouslySelected && !isSelectAllOptionSelected) {
                  setProductFilter((prevState) => ({
                    ...prevState,
                    selectedModelVariant: [],
                    selectedModelColor: [],
                  }));
                } else if (isSelectAllOptionSelected) {
                  // If "Select All" is selected, select all options
                  if (
                    productFilter.selectedModelVariant.length <
                    productFilter.ModelVariant.length - 1
                  ) {
                    setProductFilter((prevState) => ({
                      ...prevState,
                      selectedModelVariant: prevState.ModelVariant,
                    }));
                  }
                } else {
                  // Regular selection/deselection of options
                  setProductFilter((prevState) => ({
                    ...prevState,
                    selectedModelVariant: newValue,
                    selectedModelColor: [],
                  }));
                }
              }}
              renderTags={(value, getTagProps) =>
                value?.length > 0
                  ? [
                      <span {...getTagProps({ index: 0 })} key={0}>
                        {value[0]?.name}
                        {value?.length > 1 ? ', ...' : ''}
                      </span>,
                    ]
                  : []
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Model Variant" placeholder="Select Model Variant" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Autocomplete
              multiple
              options={productFilter?.modelColor}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              value={productFilter?.selectedModelColor}
              onChange={(event, newValue) => {
                const selectAllOptionId = '0'; // ID for "Select All" option
                const isSelectAllOptionSelected = newValue.some((v) => v.id === selectAllOptionId);
                const wasSelectAllOptionPreviouslySelected = productFilter.selectedModelColor.some(
                  (v) => v.id === selectAllOptionId
                );

                // If "Select All" is being unselected, clear all selections
                if (wasSelectAllOptionPreviouslySelected && !isSelectAllOptionSelected) {
                  setProductFilter((prevState) => ({
                    ...prevState,
                    selectedModelColor: [],
                  }));
                } else if (isSelectAllOptionSelected) {
                  // If "Select All" is selected, select all options
                  if (
                    productFilter.selectedModelColor.length <
                    productFilter.modelColor.length - 1
                  ) {
                    setProductFilter((prevState) => ({
                      ...prevState,
                      selectedModelColor: prevState.modelColor,
                    }));
                  }
                } else {
                  // Regular selection/deselection of options
                  setProductFilter((prevState) => ({
                    ...prevState,
                    selectedModelColor: newValue,
                  }));
                }
              }}
              renderTags={(value, getTagProps) =>
                value?.length > 0
                  ? [
                      <span {...getTagProps({ index: 0 })} key={0}>
                        {value[0]?.name}
                        {value?.length > 1 ? ', ...' : ''}
                      </span>,
                    ]
                  : []
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Model Color" placeholder="Select Model Color" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Autocomplete
              multiple
              options={productFilter?.dseName}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              value={productFilter?.selectedDSEName}
              onChange={(event, value) => {
                if (value?.some((v) => v.id === '0')) {
                  // Select all is either being added or is already part of the selection
                  if (productFilter.selectedDSEName.length < productFilter.dseName.length - 1) {
                    // If not all were previously selected, select all
                    setProductFilter((prevState) => ({
                      ...prevState,
                      selectedDSEName: prevState.dseName,
                    }));
                  } else {
                    // If all were previously selected, deselect all
                    setProductFilter((prevState) => ({
                      ...prevState,
                      selectedDSEName: value,
                    }));
                  }
                } else {
                  setProductFilter((prevState) => ({
                    ...prevState,
                    selectedDSEName: value,
                  }));
                }
              }}
              renderTags={(value, getTagProps) =>
                value?.length > 0
                  ? [
                      <span {...getTagProps({ index: 0 })} key={0}>
                        {value[0]?.name}
                        {value?.length > 1 ? ', ...' : ''}
                      </span>,
                    ]
                  : []
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Select DSE" placeholder="Select DSE" />
              )}
            />
          </Grid>
        </Grid>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Typography variant="subtitle1" sx={{ wordBreak: 'break-all' }}>
          Select Date
        </Typography>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          sx={{ p: 1 }}
          spacing={2}
        >
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
            <DatePicker
              name="fromDate"
              label="From Date"
              type="text"
              format="dd/MM/yyyy"
              value={productFilter?.fromDate}
              onChange={(newValue) => setProductFilter({ ...productFilter, fromDate: newValue })}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
            <DatePicker
              name="toDate"
              label="To Date"
              value={productFilter?.toDate}
              format="dd/MM/yyyy"
              onChange={(newValue) => setProductFilter({ ...productFilter, toDate: newValue })}
            />
          </Grid>
        </Grid>
      </Stack>

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        xxl={12}
        sx={{ p: 2 }}
        spacing={1}
      >
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <Button
            fullWidth
            variant="soft"
            color="warning"
            size="large"
            startIcon={<Icon icon="ant-design:clear-outlined" />}
            onClick={() =>
              setProductFilter({
                ...productFilter,
                selectedCategories: [],
                selectedModelName: [],
                selectedModelVariant: [],
                selectedModelColor: [],
                selectedDSEName: [],
                fromDate: new Date(),
                toDate: new Date(),
              })
            }
          >
            CLEAR
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <Button
            fullWidth
            variant="soft"
            color="success"
            size="large"
            startIcon={<Icon icon="mdi:success" />}
            onClick={() => {
              if (
                productFilter?.selectedCategories?.length ||
                productFilter?.selectedModelName.length ||
                productFilter?.selectedModelVariant.length ||
                productFilter?.selectedModelColor.length ||
                productFilter?.selectedDSEName.length ||
                productFilter?.fromDate ||
                productFilter?.toDate
              ) {
                getAll(true);
                onClose();
              }
            }}
          >
            APPLY
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  );
}

FileManagerFileDetails.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  productFilter: PropTypes.object,
  setProductFilter: PropTypes.func,
  getAll: PropTypes.func,
};
