import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { KEY, TRANSACTION_TYPES } from 'src/_mock/constant';
import {
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { useEventListener } from 'src/hooks/use-event-listener';
import { DatePicker } from '@mui/x-date-pickers';
import VoucherUserQuickEditForm from 'src/sections/automobile-transaction/entries/quick-edit-form';
import JournalQuickEditForm from 'src/sections/automobile-transaction/journal/quick-edit-form';
import InvoiceQuickEditForm from 'src/sections/automobile-transaction/invoice/quick-edit-form';
import { useDebounce } from 'src/hooks/use-debounce';
import {
  fMoney,
  formatDate,
  handleOnWheelScroll,
  handleRowClick,
} from 'src/_mock/constant_funcation';
import CompanyYear from 'src/layouts/_common/company-year';
import { TablePaginationCustom, useTable } from 'src/components/table';
// ----------------------------------------------------------------------

export default function AUserQuickEditForm({ row, open, onClose, getAll, tableData }) {
  const table = useTable();
  const { enqueueSnackbar } = useSnackbar();
  const [count, setCount] = useState(0);
  const [groupList, setGroupList] = useState([]);

  const quickVoucher = useBoolean();

  const [singleRowTableData, setSingleRowTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [last_bal, setLast_bal] = useState(0);

  const defaultFilters = {
    name: '',
    role: [],
    status: 'all',
  };

  const [voucherList, setVoucherList] = useState(null);
  console.log(voucherList)
  const [filters, setFilters] = useState(defaultFilters);

  const debouncedQuery = useDebounce(filters);

  const { page } = table;
  const limit = table.rowsPerPage;

  const getAllSingleTypeData = useCallback(
    async (aId) => {
      try {
        let URL = `automobile/transaction/ledgers/${aId}`;
        URL += `?page=${page + 1}&limit=${limit}&`;

        const { data } = await fetcher(URL);
        if (data) {
          setLast_bal(data?.last_bal);
          setCount(data?.count);
          setSingleRowTableData(data?.rows);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [page, limit]
  );

  useEffect(() => {
    if (row?.id) {
      const aId = row?.id;
      getAllSingleTypeData(aId);
    }
  }, [getAllSingleTypeData, row?.id]);

  const departmentSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    ac_groupId: Yup.number().required('Name is required'),
    op_bal: Yup.number(),
    bal_type: Yup.number().when('op_bal', {
      is: (opBal) => opBal > 0,
      then: () =>
        Yup.number().min(1).max(2).required('When op_bal is greater than 0, bal_type is required'),
      otherwise: () => Yup.number().nullable(),
    }),
    email: Yup.string().nullable(),
  });

  const values = useMemo(
    () => ({
      name: row?.name || '',
      s_n: row?.s_n || '',
      account_ledger: [],
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(departmentSchema),
    values,
  });

  const {
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset();
  }, [open, reset]);
  const allValues = watch();


  const onSubmit = handleSubmit(async (payload) => {
    try {
      let URL = 'automobile/accounts/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
    setSelectedRow(0);
  }, [open, reset]);

  const handleCloseAccount = () => {
    quickVoucher.onFalse();
    setVoucherList(null);
  };

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
      window.$accLedList = 'out';
    }
  };

  useEventListener('keydown', handleKeyDown);


  const closing_balance = parseFloat(last_bal);

  return (
    <>
      <Dialog fullScreen open={open} onKeyDown={handleKeyDown}>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                top: 0,
                zIndex: 9,
                position: 'sticky',
                bgcolor: (theme) => alpha(theme.palette.background.default, 1),
              }}
            >
              <Toolbar>
                <Typography variant="h5" sx={{ flex: 1 }}>
                  Ledger Account
                </Typography>

                <CompanyYear />

                <IconButton
                  color="inherit"
                  edge="start"
                  onClick={() => {
                    onClose();
                    window.$accLedList = 'out';
                  }}
                >
                  <Iconify icon="mingcute:close-line" />
                </IconButton>
              </Toolbar>

              <Divider sx={{ borderStyle: 'dashed' }} />
            </Grid>

            <Grid item container xs={12} md={12} sx={{ p: 2 }} spacing={2}>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                spacing={1}
                alignContent="flex-start"
              >
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                  {/* <Autocomplete
     autoHighlight
                    fullWidth
                    size="small"
                    disableCloseOnSelect
                    options={groupList}
                    groupBy={(option) => option.a}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setValue('ac_groupId', newValue?.id);
                    }}
                    value={
                      groupList?.find((data) => data.id === allValues.ac_groupId) || row?.ac_group
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Ledger"
                        name="ledger"
                        error={errors?.ac_groupId}
                      />
                    )}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader component="li">
                          <ListItem sx={{ padding: 0, margin: 0 }}>Name</ListItem>
                          <ListItem sx={{ padding: 0, margin: 0 }}>Effect On</ListItem>
                        </GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                        <GroupFooter component="li">
                          <Button onClick={handleGroupOpen}>Add</Button>
                          <Button>Edit</Button>
                          <Button>Delete</Button>
                        </GroupFooter>
                      </li>
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                          {option?.name}
                        </ListItem>
                        <ListItem sx={{ padding: 0, margin: 0 }}>
                          {option.ac_group ? option.ac_group?.name : ''}
                        </ListItem>
                      </Box>
                    )}
                  /> */}

                  <RHFTextField
                    name="ledger"
                    label="Ledger"
                    size="small"
                    value={row != null ? row?.name : ''}
                    disabled="true"
                  />

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    display="flex"
                    marginTop={1}
                    justifyContent="space-between"
                  >
                    <Typography fontWeight={400} variant="subtitle2">
                      Group
                    </Typography>
                    <Typography variant="subtitle2">{row?.ac_group?.name}</Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  sx={{ p: 2 }}
                  spacing={2}
                  justifyContent="flex-end"
                >
                  <Grid item xs={12} sm={6} md={4} lg={3.1} xl={3.1} xxl={3.1}>
                    <DatePicker
                      label="From"
                      value={new Date()}
                      // onChange={handleFilterStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3.1} xl={3.1} xxl={3.1}>
                    <DatePicker
                      label="To"
                      format="dd/MM/yyyy"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <TableContainer
                    component={Paper}
                    sx={{ overflow: 'auto', maxHeight: '50vh', width: '98%', margin: 'auto' }}
                  >
                    <Table stickyHeader aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.5,
                              textAlign: 'center',
                              width: 20,
                              position: 'sticky',
                              left: 0,
                            }}
                          />
                          <TableCell sx={{ p: 0.5, width: 100 }} />
                          <TableCell sx={{ p: 0.5, width: 100 }} />
                          <TableCell sx={{ p: 0.5, width: 100 }} />
                          <TableCell sx={{ p: 0.5, width: 100 }} />
                          <TableCell sx={{ p: 0.5, width: 100 }} />
                          <TableCell sx={{ p: 0.5, width: 400 }} />
                          <TableCell sx={{ p: 0.5, width: 120, textAlign: 'end' }} />
                          <TableCell sx={{ p: 0.5, width: 120, textAlign: 'end' }}>
                            Opening Balance
                          </TableCell>
                          <TableCell sx={{ p: 0.5, width: 120, textAlign: 'end' }}>
                            {`${fMoney.format(Math.abs(last_bal), { symbol: '₹ ' })} ${closing_balance < 0 ? 'CR' : 'DB'
                              }`}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.5,
                              textAlign: 'center',
                              width: 20,
                              position: 'sticky',
                              left: 0,
                            }}
                          >
                            No
                          </TableCell>
                          <TableCell sx={{ p: 0.5, width: 100 }}>Date</TableCell>
                          <TableCell sx={{ p: 0.5, width: 100 }}>Branch</TableCell>
                          <TableCell sx={{ p: 0.5, width: 100 }}>Pcategory</TableCell>
                          <TableCell sx={{ p: 0.5, width: 100 }}>Tra. Type</TableCell>
                          <TableCell sx={{ p: 0.5, width: 100 }}>No.</TableCell>
                          <TableCell sx={{ p: 0.5, width: 400 }}>Account Name</TableCell>
                          <TableCell sx={{ p: 0.5, width: 120, textAlign: 'end' }}>
                            Debit
                          </TableCell>
                          <TableCell sx={{ p: 0.5, width: 120, textAlign: 'end' }}>
                            Credit
                          </TableCell>
                          <TableCell sx={{ p: 0.5, width: 120, textAlign: 'end' }}>
                            Balance
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {singleRowTableData?.map((field, index) => <TableRow
                          onDoubleClick={(e) => {
                            setVoucherList(field);
                            setSelectedRow(index);
                            quickVoucher.onTrue();
                          }}
                          key={index}
                          rowIndex={index}
                          onClick={() => handleRowClick(index, setSelectedRow, selectedRow)}
                          // onKeyDown={(event) => handleKeyDownInTable(event, index)}
                          tabIndex={0}
                          selected={selectedRow === index}
                          onWheel={(e) =>
                            handleOnWheelScroll(
                              e,
                              setSelectedRow,
                              selectedRow,
                              singleRowTableData.length,
                              0,
                              'In'
                            )
                          }
                        >
                          <TableCell
                            sx={{
                              textAlign: 'center',
                              position: 'sticky',
                              left: 0,
                              p: 0.3,
                            }}
                          >
                            {index + 1}
                          </TableCell>

                          <TableCell
                            sx={{
                              p: 0.3,
                            }}
                          >
                            {formatDate(field?.dt)}
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0.3,
                            }}
                          >
                            {field?.branch.short_name}
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0.3,
                            }}
                          >
                            {field?.pcategory?.name}
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0.3,
                            }}
                          >
                            {TRANSACTION_TYPES[field?.t_type]}
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0.3,
                            }}
                          >
                            {field?.identity}
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0.3,
                            }}
                          >
                            {field?.name}
                          </TableCell>
                          <TableCell sx={{ p: 0.3, textAlign: 'end' }}>
                            {field.debitAmount
                              ? fMoney.format(field.debitAmount, { symbol: '₹ ' })
                              : ''}
                          </TableCell>
                          <TableCell sx={{ p: 0.3, textAlign: 'end' }}>
                            {field.creditAmount
                              ? fMoney.format(field.creditAmount, { symbol: '₹ ' })
                              : ''}
                          </TableCell>

                          <TableCell sx={{ p: 0.3, textAlign: 'end' }}>
                            {fMoney.format(Math.abs(field.closingBalance), { symbol: '₹ ' })}{' '}
                            {field.closingBalance < 0 ? 'CR' : 'DB'}
                          </TableCell>
                        </TableRow>)}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePaginationCustom
                    count={count}
                    page={table.page}
                    rowsPerPage={table.rowsPerPage}
                    onPageChange={table.onChangePage}
                    onRowsPerPageChange={table.onChangeRowsPerPage}
                    dense={table.dense}
                    onChangeDense={table.onChangeDense}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <DialogActions
            sx={{
              bottom: 0,
              zIndex: 11111,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                window.$accLedList = 'out';
              }}
            >
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              {row ? 'Update' : 'Add'}
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>

      {quickVoucher?.value && voucherList && [1, 2, 3, 4, 5, 6].includes(voucherList.t_type) && (
        <VoucherUserQuickEditForm
          row={voucherList}
          open={quickVoucher.value}
          onClose={handleCloseAccount}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
      {quickVoucher?.value && voucherList && [7, 13].includes(voucherList.t_type) && (
        <JournalQuickEditForm
          row={voucherList}
          open={quickVoucher.value}
          onClose={handleCloseAccount}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
      {quickVoucher?.value && voucherList && [8, 9, 10, 11].includes(voucherList.t_type) && (
        <InvoiceQuickEditForm
          row={voucherList}
          open={quickVoucher.value}
          onClose={handleCloseAccount}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
    </>
  );
}

AUserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  tableData: PropTypes.array,
};
