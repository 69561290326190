import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import React, { useContext } from 'react';
import { AuthContext } from 'src/auth/context/jwt';
import { useResponsive } from 'src/hooks/use-responsive';

function CompanyYear() {
  const { user } = useContext(AuthContext);

  const lgUp = useResponsive('up', 'sm');

  const startDate = moment(user?.co_year?.sdate);
  const endDate = moment(user?.co_year?.edate);

  // Format the dates in the desired format
  const formattedStartDate = startDate.format('DD/MM/YYYY');
  const formattedEndDate = endDate.format('DD/MM/YYYY');

  const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;

  return (
    lgUp && (
      <Grid sx={{ textAlign: 'right', mr: '30px' }}>
        <Grid sx={{ display: 'flex' }}>
          <Typography variant="h6" color="primary">
            {user?.companies?.find((x) => x.id === user?.companyId)?.name
              ? user?.companies?.find((x) => x.id === user?.companyId)?.name.toUpperCase()
              : ''}

          </Typography>
          &nbsp;&bull;&nbsp;
          {user?.companies?.find((x) => x.id === user?.companyId)?.gst_no && (
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              GST NO: {user?.companies?.find((x) => x.id === user?.companyId)?.gst_no}
            </span>
          )}
        </Grid>
        <Typography variant="body2">{user?.branches?.find((x) => x.id === user?.branchId)?.name
          ? user?.branches?.find((x) => x.id === user?.branchId)?.name.toUpperCase()
          : ''} {formattedDateRange}</Typography>
      </Grid>
    )
  );
}

export default CompanyYear;
