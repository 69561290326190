import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
//
import { fMoney, formatDate } from 'src/_mock/constant_funcation';
import LogTable from 'src/layouts/_common/log/log-manage';
import { PDFViewer } from '@react-pdf/renderer';
import { Box, Dialog, DialogActions } from '@mui/material';
import ProductCategoryQuickEditForm from './quick-edit-form';
import ChallanPDF from './challan-pdf';

// ----------------------------------------------------------------------

export default function UserTableRow({
  tableData,
  row,
  index,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  table,
  setRow,
  setChallanId,
  quickEdit,
  quickInvoiceEdit,
  getAll,
  permission,
}) {
  const { identity, challandate, party, billamount, cha_details, status } = row;
  const view = useBoolean();
  const confirm = useBoolean();

  const quickLog = useBoolean();

  const popover = usePopover();


  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell>{identity}</TableCell>
        <TableCell>{party?.identity}</TableCell>
        <TableCell>{challandate ? formatDate(challandate) : '-'}</TableCell>
        <TableCell>{party?.name}</TableCell>
        <TableCell>{row?.party?.booking?.avariant?.name}</TableCell>
        <TableCell>{cha_details[0]?.stockdetail?.chassisNo}</TableCell>
        <TableCell>{cha_details[0]?.stockdetail?.enginNo}</TableCell>
        <TableCell>{fMoney.format(billamount, { symbol: '₹ ' })}</TableCell>
        <TableCell>
          <Label variant="soft" color={status ? 'success' : 'warning'}>
            {status ? 'Active' : 'Cancelled'}
          </Label>
        </TableCell>
        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          {/* <Tooltip title="Quick Invoice" placement="top" arrow>
            <IconButton
              color="default"
              onClick={() => {
                setChallanId(row?.v_no);
                quickInvoiceEdit.onTrue();
              }}
              disabled={!status}
            >
              <Iconify icon="iconamoon:invoice" />
            </IconButton>
          </Tooltip> */}
          <Tooltip title="View">
            <IconButton onClick={view.onTrue}>
              <Iconify icon="solar:eye-bold" />
            </IconButton>
          </Tooltip>
          {permission?.u && (
            <Tooltip title="Quick Edit" placement="top" arrow>
              <IconButton
                color={quickEdit.value ? 'inherit' : 'default'}
                onClick={() => {
                  quickEdit.onTrue();
                  setRow(row);
                }}
                disabled={!status}
              >
                <Iconify icon="solar:pen-bold" />
              </IconButton>
            </Tooltip>
          )}

          {(permission?.u || permission?.d) && (
            <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <Dialog fullScreen open={view.value} key={row.id}>
        <Box sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              p: 1.5,
            }}
          >
            <Button color="inherit" variant="contained" onClick={view.onFalse}>
              Close
            </Button>
          </DialogActions>

          <Box sx={{ flexGrow: 1, height: 1, overflow: 'hidden' }}>
            <ChallanPDF invoice={row} />
          </Box>
        </Box>
      </Dialog>
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {permission?.d && status && (
          <MenuItem
            onClick={() => {
              confirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: 'warning.main' }}
          >
            <Iconify icon="solar:trash-bin-trash-bold" />
            Cancel
          </MenuItem>
        )}

        {permission?.u && (
          <MenuItem
            onClick={() => {
              quickEdit.onTrue();
              setRow(row);
              popover.onClose();
            }}
            disabled={!status}
          >
            <Iconify icon="solar:pen-bold" />
            Edit
          </MenuItem>
        )}

        {/* {permission?.l && (
          <MenuItem
            onClick={() => {
              quickLog.onTrue();
              popover.onClose();
            }}
          >
            <Iconify icon="mdi:refresh" />
            Log
          </MenuItem>
        )} */}
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Cancel"
        content="Are you sure want to cancel challan?"
        action={
          <Button variant="contained" color="warning" onClick={onDeleteRow}>
            Yes
          </Button>
        }
      />

      {quickLog.value && (
        <LogTable
          open={quickLog.value}
          logDetails={{
            name: 'Vehicle Purchases Master Log',
            API: 'automobile/challans',
            originalId: row?.id,
          }}
          onClose={() => {
            quickLog.onFalse();
          }}
        />
      )}
    </>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  index: PropTypes.number,
  selected: PropTypes.bool,
  table: PropTypes.object,
  permission: PropTypes.object,
  setRow: PropTypes.func,
  setChallanId: PropTypes.func,
  quickEdit: PropTypes.any,
  quickInvoiceEdit: PropTypes.any,
  getAll: PropTypes.func,
  tableData: PropTypes.array,
};
