import { Helmet } from 'react-helmet-async';
import { COMPANY_NAME } from 'src/_mock/constant';
// sections
import { ListView } from 'src/sections/work_shop/information/spare_information/chassis_code/view';

// ----------------------------------------------------------------------

export default function ChassisCodeListPage() {
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} : Chassis Code List</title>
      </Helmet>

      <ListView />
    </>
  );
}
