import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Autocomplete,
  Button,
  DialogActions,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import OrganizationalChart from 'src/components/organizational-chart';
import { create, fetcher, update } from 'src/utils/axios';
import { KEY, PARTNER_STATUS, TYPE_OF_ACCOUNTS, WORKING_PARTNER } from 'src/_mock/constant';

import { HOST_API } from 'src/config-global';
import CompanyYear from 'src/layouts/_common/company-year';
import Iconify from 'src/components/iconify';
import ComponentBlock from '../_examples/component-block';

export default function BranchView({
  row,
  open,
  onClose
}) {

  const [company, setCompany] = useState(null)
  const [chart, setChart] = useState(null)
  const getCompany = useCallback(async (name) => {
    try {
      const URL = `company/${row.id}`;
      const { data } = await fetcher(URL);
      if (data) {
        if (row?.id)
          setCompany(data)
        else
          setCompany(null);

      }
    } catch (error) {
      console.error(error);
    }
  }, [row?.id]);
  useEffect(() => {
    getCompany(row.id)
  }, [getCompany, open, row.id]);

  useEffect(() => {
    if (company) {
      const char = {
        group: 'root',
        name: company.name,
        avatarUrl: `${HOST_API}uploads/medium/${company.logo}`,
        children: company.branches.map(x => ({
          group: 'root',
          name: x.name,
          avatarUrl: `${HOST_API}uploads/medium/${x.logo}`,
          children: []
        }))
      }
      console.log(char)
      setChart(char)
    }
  }, [company])
  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 1320 },
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            top: 0,
            zIndex: 111111,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Toolbar>
            <Typography variant="h5" sx={{ flex: 1 }}>
              {row.name} Branches
            </Typography>

            <CompanyYear />

            <IconButton
              color="inherit"
              edge="start"
              onClick={() => {
                onClose();
              }}
            >
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>

          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>
      </Grid>

      <ComponentBlock sx={{ overflow: 'scroll' }}>
        {chart && <OrganizationalChart data={chart} variant="standard" lineHeight="64px" />}
      </ComponentBlock>
    </Dialog>
  );
}

BranchView.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
