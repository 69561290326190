import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { KEY } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  ListItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { GroupHeader, GroupItems } from 'src/_mock/constant_style';
import CompanyYear from 'src/layouts/_common/company-year';
import { Stack } from '@mui/system';
import { useBgColorAutocomplete } from 'src/_mock/constant_funcation';
// ----------------------------------------------------------------------

export default function GroupAccountForm({ row, open, onClose, table, getAll, tableData, set }) {
  const { enqueueSnackbar } = useSnackbar();

  const [groupList, setGroupList] = useState([]);
  const [loading, setLoading] = useState(false);

  const bgColorAutocomplete = useBgColorAutocomplete();

  const groupSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    ac_groupId: Yup.number().required('Group Name is required'),
    seq: Yup.number().required('seq Name is required'),
  });

  const values = useMemo(
    () => ({
      name: row?.name || '',
      ac_groupId: row?.ac_groupId == null ? null : row?.ac_groupId,
      hname: row?.hname || '',
      seq: row?.seq || '',
      isHighligh: '',
      crdl: row?.crdl || false,
      inte: row?.inte || false,
      bank: row?.bank || false,
      gst: row?.gst || false,
      party: row?.party || false,
      status: row?.status || false,
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(groupSchema),
    values,
    mode: 'onChange',
  });

  const {
    reset,
    trigger,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const GroupID = groupList?.find((data) => data?.id === allValues?.ac_groupId);

  useEffect(() => {
    if (GroupID && row === null) {
      setValue('crdl', GroupID?.crdl);
      setValue('inte', GroupID?.inte);
      setValue('bank', GroupID?.bank);
      setValue('gst', GroupID?.gst);
      setValue('party', GroupID?.party);
    }
  }, [GroupID, setValue, row]);

  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      let URL = 'automobile/ac_groups/';
      if (row?.id) {
        URL += row.id;
        if (payload.ac_groupId === 'null') {
          payload.ac_groupId = null;
        }
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show, data } = await create({ url: URL, payload });
        if (show) {
          enqueueSnackbar('Create success!');
        }
        if (success) {
          getAll();
          onClose();
          if (set) {
            console.log(set);
            set.setValue(set.fieldName, data.id);
            set.list((pre) => [...pre, data]);
          }
        }
      }
      reset();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      setLoading(false);
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const HandleGroupList = async () => {
    let URL = 'automobile/ac_groups/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setGroupList(data?.rows);
  };

  useEffect(() => {
    if (open) HandleGroupList();
  }, [open]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            top: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Toolbar>
            <Typography variant="h5" sx={{ flex: 1 }}>
              {row ? 'Update Account Group' : 'Add Account Group'}
            </Typography>

            <CompanyYear />

            <IconButton color="inherit" edge="start" onClick={onClose}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>

        <Grid container spacing={2} sx={{ p: 3 }}>
          <Grid item container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <RHFTextField
                name="name"
                label={
                  <span>
                    Group Name<span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <Autocomplete
                autoHighlight={allValues.isHighligh}
                fullWidth
                size="small"
                disableCloseOnSelect
                options={groupList}
                openOnFocus
                onInputChange={(event, newValue) => {
                  setValue('isHighligh', newValue);
                }}
                onBlur={() => {
                  setValue('isHighligh', '');
                }}
                groupBy={(option) => option?.a}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  setValue('ac_groupId', newValue?.id);
                }}
                value={groupList?.find((data) => data.id === allValues.ac_groupId) || row?.ac_group}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        Under<span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    name="ac_groupId"
                    error={errors?.ac_groupId && !allValues?.ac_groupId}
                  />
                )}
                renderGroup={(params) => (
                  <li key={params.key}>
                    <GroupHeader component="li">
                      <ListItem sx={{ padding: 0, margin: 0 }}>Name</ListItem>
                      <ListItem sx={{ padding: 0, margin: 0 }}>Effect On</ListItem>
                    </GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                  </li>
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                      {option?.name}
                    </ListItem>
                    <ListItem sx={{ padding: 0, margin: 0 }}>
                      {option.ac_group ? option.ac_group?.name : '-'}
                    </ListItem>
                  </Box>
                )}
                ListboxProps={{ ...bgColorAutocomplete }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <RHFTextField name="hname" label="Group Header" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <RHFTextField
                name="seq"
                label={
                  <span>
                    Sequence<span style={{ color: 'red' }}>*</span>
                  </span>
                }
                size="small"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5} xxl={2.5}>
              <RHFCheckbox name="crdl" label="Credit Limit" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFCheckbox name="inte" label="Interest" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFCheckbox name="bank" label="Bank" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFCheckbox name="gst" label="GSTIN" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5} xxl={2.5}>
              <RHFCheckbox name="party" label="Party Details" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5} xxl={2.5}>
              <RHFCheckbox name="status" label="Active" size="small" />
            </Grid>
          </Grid>
        </Grid>

        <Stack
          direction="row-reverse"
          alignItems="center"
          sx={{
            bottom: 0,
            zIndex: 111111,
            p: 3,
            position: 'sticky',
            width: '100%',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
          id="modelfooter"
        >
          <Tooltip title="(Alt + A)" arrow>
            <LoadingButton
              id="add"
              variant="contained"
              loading={loading}
              onClick={async () => {
                if (await trigger()) {
                  onSubmit();
                }
              }}
            >
              {row ? 'Update' : 'Add'}
            </LoadingButton>
          </Tooltip>

          <Tooltip title="(Esc)" arrow>
            <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
          </Tooltip>

          <Button
            variant="soft"
            onClick={() => {
              reset(values);
            }}
            sx={{ mr: 2 }}
          >
            Reset
          </Button>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}

GroupAccountForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  set: PropTypes.object,
  tableData: PropTypes.array,
};
