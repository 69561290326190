import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { create, destroy, fetcher, update } from 'src/utils/axios';
import {
  CASH_DEBIT,
  INVOI_TYPE,
  KEY,
  PURCHASE_TYPE,
  SALES_TYPE,
  Tax_BilSupply,
} from 'src/_mock/constant';
import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  ListItem,
  MenuItem,
  Select,
  TableFooter,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { Box, Stack, alpha, display } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import { useEventListener } from 'src/hooks/use-event-listener';
import {
  calculateNetAmount,
  checkPropertiesTypeIsNumber,
  convertToRound,
  fMoney,
  minmax,
  nearestDateList,
  onScollerAutoComplate,
  sanitizeComplexObject,
  truncateToTwoDecimals,
  useBgColorAutocomplete,
} from 'src/_mock/constant_funcation';
import { isNaN } from 'lodash';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { useBoolean } from 'src/hooks/use-boolean';
import AUserQuickEditForm from 'src/sections/automobile-accounts/account/quick-edit-form';
import PUserQuickEditForm from 'src/sections/automobile-products/product-list/quick-edit-form';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CompanyYear from 'src/layouts/_common/company-year';
import { AuthContext } from 'src/auth/context/jwt';
// eslint-disable-next-line import/no-extraneous-dependencies
import { makeStyles } from '@mui/styles';
import GSTCommodityQuickEditForm from 'src/sections/automobile-gst/commodity/quick-edit-form';
import QuickEditForm from 'src/sections/credit-card/quick-edit-form';
import { useDebounce } from 'src/hooks/use-debounce';
import { handleScroll } from 'src/layouts/_common/scroller';
import moment from 'moment/moment';
import ComponentBlock from 'src/sections/_examples/component-block';
import { all } from 'axios';

const tranType = 9;
const va = {
  1: {
    cb: 1,
    t_type: 1,
    name: 'Bank',
    label: 'Bank',
    rp: 'Payment',
    ac_groupId: '22',
  },
  2: {
    cb: 2,
    t_type: 1,
    name: 'Bank',
    label: 'Bank',
    rp: 'receipt',
    ac_groupId: '22',
  },
  3: {
    cb: 1,
    t_type: 2,
    name: 'Cash',
    label: 'Cash',
    rp: 'Payment',
    ac_groupId: '26',
  },
  4: {
    cb: 2,
    t_type: 2,
    name: 'Cash',
    label: 'Cash',
    rp: 'receipt',
    ac_groupId: '26',
  },
  5: {
    cb: 1,
    t_type: 1,
    name: 'Contra',
    label: 'Contra',
    rp: 'Payment',
    ac_groupId: '22',
  },
  6: {
    cb: 2,
    t_type: 1,
    name: 'Contra',
    label: 'Contra',
    rp: 'receipt',
    ac_groupId: '22',
  },
  8: {
    cb: 2,
    t_type: 1,
    name: 'Contra',
    label: 'Contra',
    rp: 'receipt',
    ac_groupId: '10',
  },
  9: {
    cb: 2,
    t_type: 1,
    name: 'Contra',
    label: 'Contra',
    rp: 'receipt',
    ac_groupId: '11',
  },
};
const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: theme.palette.primary.main,
  },
}));

export default function UserQuickEditForm({
  quickjobcardId,
  row,
  open,
  onClose,
  table,
  getAll,
  tableData,
  invoiceSettings,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const fileRef = useRef(null);

  const handleAttach = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };
  const [entry, setEntry] = useState(null);
  const classes = useStyles();

  const bgColorAutocomplete = useBgColorAutocomplete(); // Use the hook here

  const theme = useTheme();

  const tableBodyRef = useRef(null);

  const quickEditAccount = useBoolean();
  const quickEditProduct = useBoolean();
  const quickEditGSTCommdity = useBoolean();
  const confirm = useBoolean();
  const quickEdit = useBoolean();

  let gTotal = 0;

  const { user } = useContext(AuthContext);
  const [jobcardNo, setJobcardNo] = useState(row?.jobcardNo || null);
  const [invoiceId, setInvoiceId] = useState(row?.id || null);
  const [importLoading, setImportLoading] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [trasaction, setTrasaction] = useState([]);
  const [productEditValues, setProductEditValues] = useState({});
  const [GSTCommodity, setGSTCommodity] = useState({});
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [yearData, setYearData] = useState([]);

  const [challanData, setChallanData] = useState({});
  const [challanLoading, setChallanLoading] = useState(false);
  const [importDisabled, setImportDisabled] = useState(false);

  const [total, setTotal] = useState({
    CGST: 0,
    SGST: 0,
    IGST: 0,
    ItemAmount: 0,
    TotalAmount: 0,
    BillAmount: 0,
  });

  const PurchaseOrderSchema = Yup.object().shape({
    c_d: Yup.number().nullable().min(0).required('Cash/Dabit is required'),
    in_type: Yup.number().integer().required(' is required'),
    bill_no: Yup.number()
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    inv_details: Yup.array().of(
      Yup.object().shape({
        productId: Yup.number().nullable().required('Product is required'),
        qty: Yup.number()
          .nullable()
          .min(0)
          .required('Qty is required')
          .transform((value) => (isNaN(value) ? null : value)),
        rate: Yup.number()
          .nullable()
          .min(0)
          .required('Rate is required')
          .transform((value) => (isNaN(value) ? null : value)),
        dprice: Yup.number()
          .nullable()
          .min(0)
          .notRequired()
          .transform((value) => (isNaN(value) ? null : value)),
        drate: Yup.number()
          .nullable()
          .min(0)
          .notRequired()
          .transform((value) => (isNaN(value) ? null : value)),
      })
    ),
    entry_details: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().integer().nullable(),
        cb: Yup.number().integer().required(),
        accountId: Yup.number().integer().required(),
        bentryId: Yup.number().integer().nullable(),
        entry_detailId: Yup.number().integer().nullable(),
        bpoptionId: Yup.number()
          .integer()
          .nullable()
          .transform((value, originalValue) => (originalValue === null ? null : originalValue)),
        ac_no: Yup.string().nullable(),
        // amount: Yup.number().required(),
      })
    ),
  });

  const cyearId = user?.co_year?.id;
  const sedata = useCallback((row) => ({
    yearId: row?.yearId || cyearId,
    jobcardId: row?.jobcardId || null,
    chassisNo: null,
    enginNo: null,
    regNo: row?.regNo || '',
    productId: row?.productId || '',
    product: row?.product || '',
    servicetype: row?.servicetype || '',
    servicetypeId: row?.servicetypeId || '',
    servicetypeName: row?.jobcard?.servicetype?.name || '',
    productName: row?.jobcard?.product?.name || '',
    colourName: row?.jobcard?.acolour?.name || '',
    currentkm: row?.jobcard?.currentkm || '',
    deliverykm: row?.jobcard?.deliverykm || '',
    colourId: row?.jobcard?.acolourId || '',
    acolour: row?.jobcard?.acolour || '',
    account: row?.jobcard?.account || '',
    jobcarddate: row?.createdAt || moment().format("DD-MM-YYYY"),
    c_d: row?.c_d || 2,
    in_type: 1,
    otheraccountId: row?.otheraccountId || null,
    invo_type: row?.invo_type || 1,
    pcategoryId: row?.pcategoryId || null,
    t_type: row?.t_type || 9,
    bill_no: row?.bill_no || null,
    LRefNo: row?.LRefNo || null,
    v_no: row?.v_no || '',
    gst_no: row?.entry_details?.find((x) => x.order === 1)?.account?.gst_no || null,
    id: row?.id || null,
    tax_bi_Id: 1,
    docNo: row?.docNo || null,
    docDate: row?.docDate || new Date(),
    invNo: row?.invNo || '',
    invDate: row?.invDate || new Date(),
    covernoteno: row?.covernoteno || '',
    covernotedate: row?.covernotedate || null,
    covernoteexpiredate: row?.covernoteexpiredate || null,
    insuranceagentId: row?.insuranceagentId || null,
    truckNo: row?.truckNo || null,
    transportName: row?.transportName || null,
    receivedDate: row?.receivedDate || '',
    amount: row?.amount || '',
    billamount: row?.amount || '',
    godownId: row?.godownId || null,
    inTras: !row?.receivedDate,
    dt: row?.dt || moment().format("DD-MM-YYYY"),
    b_date: row?.b_date || new Date(),
    narr: row?.narr || '',
    inv_discounts: [],
    inv_details:
      row?.inv_details?.map((data, index) => ({
        id: data?.id || null,
        productId: data?.productId || null,
        productName: data?.product?.name,
        product: data?.product,
        index,
        qty: data?.qty || null,
        rate: data?.rate || null,
        dprice: data?.dprice || null,
        drate: data?.drate || null,
        CGST: data?.CGST || null,
        SGST: data?.SGST || null,
        IGST: data?.IGST || null,
      })) ||
      [...Array(5)].map((x, index) => ({
        id: null,
        productId: null,
        index,
        productName: null,
        product: null,
        qty: null,
        rate: null,
        dprice: null,
        drate: null,
        CGST: null,
        SGST: null,
        IGST: null,
      })),
    entry_details: (row?.entry_details &&
      Array.isArray(row?.entry_details) &&
      row?.entry_details
        ?.sort((a, b) => a.order - b.order)
        ?.map((x) => {
          const cgstId = trasaction[tranType]?.tfields
            .filter((x) => ['CGST', 'SGST', 'IGST'].includes(x.name))
            ?.map((x) => x.accountId);
          if (cgstId?.includes(x.accountId)) {
            if (
              trasaction[tranType]?.tfields.find((x) => x.name === 'IGST')?.accountId ===
              x.accountId
            ) {
              x.in_type = 2;
            } else {
              x.in_type = 1;
            }
          }
          return {
            ...(x ? { id: x.id } : {}),
            ...(x.in_type ? { in_type: x?.in_type } : {}),
            cb: x?.cb || 1,
            cd: x?.cd || 1,
            accountId: x?.accountId || null,
            account: x?.account || null,
            entryId: x?.entryId || null,
            bentryId: x?.bentryId || null,
            entry_detailId: x?.entry_detailId || null,
            bpoptionId: x?.bpoptionId || null,
            ac_no: x?.ac_no || null,
            amount: x?.amount || null,
            order: x?.order || null,
            accountlabel: x?.account?.name?.split(' ')[0] || null,
            accountMeta: {
              page: 1,
              name: '',
              totalPage: 1,
              editRow: null,
            },
          };
        })) || [
        {
          "id": null,
          "cb": 2,
          "cd": 2,
          "accountId": null,
          "account": null,
          "entryId": null,
          "bentryId": null,
          "entry_detailId": null,
          "bpoptionId": null,
          "ac_no": null,
          "amount": null,
          "order": 1,
          "accountlabel": null,
          "accountMeta": {
            "page": 1,
            "name": null,
            "totalPage": 1,
            "editRow": null
          }
        },
        {
          "id": null,
          "cb": 1,
          "cd": 1,
          "accountId": 73,
          "account": null,
          "entryId": null,
          "bentryId": null,
          "entry_detailId": null,
          "bpoptionId": null,
          "ac_no": null,
          "amount": null,
          "order": 2,
          "accountlabel": null,
          "accountMeta": {
            "page": 1,
            "name": null,
            "totalPage": 1,
            "editRow": null
          }
        },
        {
          "id": null,
          "cb": 1,
          "cd": 1,
          "accountId": 3,
          "account": {
            "ac_groupId": 32,
            "status": true,
            "id": null,
            "identity": null,
            "name": "SGST ACCOUNT",
            "s_n": "SGST",
            "gst_type": null,
            "tra_type": null,
            "bal_met": null,
            "op_bal": null,
            "clo_bal": null,
            "bal_type": 3,
            "cr_limit": null,
            "cr_day": null,
            "in_rate": null,
            "in_ac": null,
            "tds_ac": null,
            "in_bal_type": null,
            "bank_name": null,
            "branch_name": null,
            "acc_no": null,
            "ifsc_code": null,
            "b_address": null,
            "c_name": null,
            "pincode": null,
            "mobile": null,
            "pan_no": null,
            "gst_no": null,
            "email": null,
            "adno": null,
            "countryId": null,
            "stateId": null,
            "cityId": null,
            "district": null,
            "address": null,
            "userId": null,
            "accounttypeId": null,
            "bookingId": null,
            "createdAt": "2024-01-10 18:23:39",
            "updatedAt": "2024-06-11 13:30:53"
          },
          "entryId": null,
          "bentryId": null,
          "entry_detailId": null,
          "bpoptionId": null,
          "ac_no": null,
          "amount": 8771.02,
          "order": 3,
          "accountlabel": "SGST",
          "accountMeta": {
            "page": 1,
            "name": null,
            "totalPage": 1,
            "editRow": null
          }
        },
        {
          "id": null,
          "cb": 1,
          "cd": 1,
          "accountId": 2,
          "account": {
            "ac_groupId": 32,
            "status": true,
            "id": null,
            "identity": null,
            "name": "CGST ACCOUNT",
            "s_n": "CGST",
            "gst_type": null,
            "tra_type": null,
            "bal_met": null,
            "op_bal": null,
            "clo_bal": null,
            "bal_type": 3,
            "cr_limit": null,
            "cr_day": null,
            "in_rate": null,
            "in_ac": null,
            "tds_ac": null,
            "in_bal_type": null,
            "bank_name": null,
            "branch_name": null,
            "acc_no": null,
            "ifsc_code": null,
            "b_address": null,
            "c_name": null,
            "pincode": null,
            "mobile": null,
            "pan_no": null,
            "gst_no": null,
            "email": null,
            "adno": null,
            "countryId": null,
            "stateId": null,
            "cityId": null,
            "district": null,
            "address": null,
            "userId": null,
            "accounttypeId": null,
            "bookingId": null,
            "createdAt": "2024-01-10 18:23:38",
            "updatedAt": "2024-06-11 13:31:06"
          },
          "entryId": null,
          "bentryId": null,
          "entry_detailId": null,
          "bpoptionId": null,
          "ac_no": null,
          "amount": 8771.02,
          "order": 4,
          "accountlabel": "CGST",
          "accountMeta": {
            "page": 1,
            "name": null,
            "totalPage": 1,
            "editRow": null
          }
        },
        {
          "id": null,
          "cb": 2,
          "cd": 2,
          "accountId": 56,
          "account": {
            "ac_groupId": 4,
            "status": true,
            "id": null,
            "identity": null,
            "name": "DISCOUNT A/C",
            "s_n": null,
            "gst_type": null,
            "tra_type": null,
            "bal_met": null,
            "op_bal": null,
            "clo_bal": null,
            "bal_type": null,
            "cr_limit": null,
            "cr_day": null,
            "in_rate": null,
            "in_ac": null,
            "tds_ac": null,
            "in_bal_type": null,
            "bank_name": null,
            "branch_name": null,
            "acc_no": null,
            "ifsc_code": null,
            "b_address": null,
            "c_name": null,
            "pincode": null,
            "mobile": null,
            "pan_no": null,
            "gst_no": null,
            "email": null,
            "adno": null,
            "countryId": null,
            "stateId": null,
            "cityId": null,
            "district": null,
            "address": null,
            "userId": null,
            "accounttypeId": null,
            "bookingId": null,
            "createdAt": "2024-06-07 15:02:52",
            "updatedAt": "2024-06-07 15:02:52"
          },
          "entryId": null,
          "bentryId": null,
          "entry_detailId": null,
          "bpoptionId": null,
          "ac_no": null,
          "amount": 0,
          "order": 5,
          "accountlabel": "DISCOUNT",
          "accountMeta": {
            "page": 1,
            "name": null,
            "totalPage": 1,
            "editRow": null
          }
        }
      ],
  }), [cyearId, trasaction])
  const values = useMemo(
    () => ({
      yearId: entry?.yearId || cyearId,
      jobcardId: entry?.jobcardId || null,
      jobcardNo: entry?.jobcardNo || '',
      chassisNo: null,
      iscreate: !!entry?.id,
      enginNo: null,
      regNo: entry?.regNo || '',
      productId: entry?.productId || '',
      product: entry?.product || '',
      servicetype: entry?.servicetype || '',
      servicetypeId: entry?.servicetypeId || '',
      servicetypeName: entry?.jobcard?.servicetype?.name || '',
      productName: entry?.jobcard?.product?.name || '',
      colourName: entry?.jobcard?.acolour?.name || '',
      currentkm: entry?.jobcard?.currentkm || '',
      deliverykm: entry?.jobcard?.deliverykm || '',
      colourId: entry?.jobcard?.acolourId || '',
      acolour: entry?.jobcard?.acolour || '',
      account: entry?.jobcard?.account || '',
      jobcarddate: entry?.createdAt || moment().format("DD-MM-YYYY"),
      c_d: entry?.c_d || 2,
      in_type: 1,
      otheraccountId: entry?.otheraccountId || null,
      invo_type: entry?.invo_type || 1,
      pcategoryId: entry?.pcategoryId || null,
      t_type: entry?.t_type || 9,
      bill_no: entry?.bill_no || null,
      LRefNo: entry?.LRefNo || null,
      v_no: entry?.v_no || '',
      gst_no: entry?.entry_details?.find((x) => x.order === 1)?.account?.gst_no || null,
      id: entry?.id || null,
      tax_bi_Id: 1,
      docNo: entry?.docNo || null,
      docDate: entry?.docDate || new Date(),
      invNo: entry?.invNo || '',
      invDate: entry?.invDate || new Date(),
      covernoteno: entry?.covernoteno || '',
      covernotedate: entry?.covernotedate || null,
      covernoteexpiredate: entry?.covernoteexpiredate || null,
      insuranceagentId: entry?.insuranceagentId || null,
      truckNo: entry?.truckNo || null,
      transportName: entry?.transportName || null,
      receivedDate: entry?.receivedDate || '',
      amount: entry?.amount || '',
      billamount: entry?.amount || '',
      godownId: entry?.godownId || null,
      inTras: !entry?.receivedDate,
      dt: entry?.dt || moment().format("DD-MM-YYYY"),
      b_date: entry?.b_date || new Date(),
      narr: entry?.narr || '',
      inv_discounts: [],
      inv_details:
        entry?.inv_details?.map((data, index) => ({
          id: data?.id || null,
          productId: data?.productId || null,
          productName: data?.product?.name,
          product: data?.product,
          index,
          qty: data?.qty || null,
          rate: data?.rate || null,
          dprice: data?.dprice || null,
          drate: data?.drate || null,
          CGST: data?.CGST || null,
          SGST: data?.SGST || null,
          IGST: data?.IGST || null,
        })) ||
        [...Array(5)].map((x, index) => ({
          id: null,
          productId: null,
          index,
          productName: null,
          product: null,
          qty: null,
          rate: null,
          dprice: null,
          drate: null,
          CGST: null,
          SGST: null,
          IGST: null,
        })),
      entry_details: (entry?.entry_details &&
        Array.isArray(entry?.entry_details) &&
        entry?.entry_details
          ?.sort((a, b) => a.order - b.order)
          ?.map((x) => {
            const cgstId = trasaction[tranType]?.tfields
              .filter((x) => ['CGST', 'SGST', 'IGST'].includes(x.name))
              ?.map((x) => x.accountId);
            if (cgstId?.includes(x.accountId)) {
              if (
                trasaction[tranType]?.tfields.find((x) => x.name === 'IGST')?.accountId ===
                x.accountId
              ) {
                x.in_type = 2;
              } else {
                x.in_type = 1;
              }
            }
            return {
              ...(x ? { id: x.id } : {}),
              ...(x.in_type ? { in_type: x?.in_type } : {}),
              cb: x?.cb || 1,
              cd: x?.cd || 1,
              accountId: x?.accountId || null,
              account: x?.account || null,
              entryId: x?.entryId || null,
              bentryId: x?.bentryId || null,
              entry_detailId: x?.entry_detailId || null,
              bpoptionId: x?.bpoptionId || null,
              ac_no: x?.ac_no || null,
              amount: x?.amount || null,
              order: x?.order || null,
              accountlabel: x?.account?.name?.split(' ')[0] || null,
              accountMeta: {
                page: 1,
                name: '',
                totalPage: 1,
                editRow: null,
              },
            };
          })) || [
          {
            "id": null,
            "cb": 2,
            "cd": 2,
            "accountId": null,
            "account": null,
            "entryId": null,
            "bentryId": null,
            "entry_detailId": null,
            "bpoptionId": null,
            "ac_no": null,
            "amount": null,
            "order": 1,
            "accountlabel": null,
            "accountMeta": {
              "page": 1,
              "name": null,
              "totalPage": 1,
              "editRow": null
            }
          },
          {
            "id": null,
            "cb": 1,
            "cd": 1,
            "accountId": 73,
            "account": null,
            "entryId": null,
            "bentryId": null,
            "entry_detailId": null,
            "bpoptionId": null,
            "ac_no": null,
            "amount": null,
            "order": 2,
            "accountlabel": null,
            "accountMeta": {
              "page": 1,
              "name": null,
              "totalPage": 1,
              "editRow": null
            }
          },
          {
            "id": null,
            "cb": 1,
            "cd": 1,
            "accountId": 3,
            "account": {
              "ac_groupId": 32,
              "status": true,
              "id": null,
              "identity": null,
              "name": "SGST ACCOUNT",
              "s_n": "SGST",
              "gst_type": null,
              "tra_type": null,
              "bal_met": null,
              "op_bal": null,
              "clo_bal": null,
              "bal_type": 3,
              "cr_limit": null,
              "cr_day": null,
              "in_rate": null,
              "in_ac": null,
              "tds_ac": null,
              "in_bal_type": null,
              "bank_name": null,
              "branch_name": null,
              "acc_no": null,
              "ifsc_code": null,
              "b_address": null,
              "c_name": null,
              "pincode": null,
              "mobile": null,
              "pan_no": null,
              "gst_no": null,
              "email": null,
              "adno": null,
              "countryId": null,
              "stateId": null,
              "cityId": null,
              "district": null,
              "address": null,
              "userId": null,
              "accounttypeId": null,
              "bookingId": null,
              "createdAt": "2024-01-10 18:23:39",
              "updatedAt": "2024-06-11 13:30:53"
            },
            "entryId": null,
            "bentryId": null,
            "entry_detailId": null,
            "bpoptionId": null,
            "ac_no": null,
            "amount": 8771.02,
            "order": 3,
            "accountlabel": "SGST",
            "accountMeta": {
              "page": 1,
              "name": null,
              "totalPage": 1,
              "editRow": null
            }
          },
          {
            "id": null,
            "cb": 1,
            "cd": 1,
            "accountId": 2,
            "account": {
              "ac_groupId": 32,
              "status": true,
              "id": null,
              "identity": null,
              "name": "CGST ACCOUNT",
              "s_n": "CGST",
              "gst_type": null,
              "tra_type": null,
              "bal_met": null,
              "op_bal": null,
              "clo_bal": null,
              "bal_type": 3,
              "cr_limit": null,
              "cr_day": null,
              "in_rate": null,
              "in_ac": null,
              "tds_ac": null,
              "in_bal_type": null,
              "bank_name": null,
              "branch_name": null,
              "acc_no": null,
              "ifsc_code": null,
              "b_address": null,
              "c_name": null,
              "pincode": null,
              "mobile": null,
              "pan_no": null,
              "gst_no": null,
              "email": null,
              "adno": null,
              "countryId": null,
              "stateId": null,
              "cityId": null,
              "district": null,
              "address": null,
              "userId": null,
              "accounttypeId": null,
              "bookingId": null,
              "createdAt": "2024-01-10 18:23:38",
              "updatedAt": "2024-06-11 13:31:06"
            },
            "entryId": null,
            "bentryId": null,
            "entry_detailId": null,
            "bpoptionId": null,
            "ac_no": null,
            "amount": 8771.02,
            "order": 4,
            "accountlabel": "CGST",
            "accountMeta": {
              "page": 1,
              "name": null,
              "totalPage": 1,
              "editRow": null
            }
          },
          {
            "id": null,
            "cb": 2,
            "cd": 2,
            "accountId": 56,
            "account": {
              "ac_groupId": 4,
              "status": true,
              "id": null,
              "identity": null,
              "name": "DISCOUNT A/C",
              "s_n": null,
              "gst_type": null,
              "tra_type": null,
              "bal_met": null,
              "op_bal": null,
              "clo_bal": null,
              "bal_type": null,
              "cr_limit": null,
              "cr_day": null,
              "in_rate": null,
              "in_ac": null,
              "tds_ac": null,
              "in_bal_type": null,
              "bank_name": null,
              "branch_name": null,
              "acc_no": null,
              "ifsc_code": null,
              "b_address": null,
              "c_name": null,
              "pincode": null,
              "mobile": null,
              "pan_no": null,
              "gst_no": null,
              "email": null,
              "adno": null,
              "countryId": null,
              "stateId": null,
              "cityId": null,
              "district": null,
              "address": null,
              "userId": null,
              "accounttypeId": null,
              "bookingId": null,
              "createdAt": "2024-06-07 15:02:52",
              "updatedAt": "2024-06-07 15:02:52"
            },
            "entryId": null,
            "bentryId": null,
            "entry_detailId": null,
            "bpoptionId": null,
            "ac_no": null,
            "amount": 0,
            "order": 5,
            "accountlabel": "DISCOUNT",
            "accountMeta": {
              "page": 1,
              "name": null,
              "totalPage": 1,
              "editRow": null
            }
          }
        ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [row, trasaction]
  );

  const methods = useForm({
    resolver: yupResolver(PurchaseOrderSchema),
    values,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    trigger,
    handleSubmit,
    getFieldState,
    formState: { isSubmitting, errors },
  } = methods;


  const stockdetails = useFieldArray({
    control,
    name: 'stockdetails',
  });
  const handlestockdetailsAdd = useCallback(() => {
    stockdetails?.append({
      id: null,
      pname: null,
      productId: null,
      rate: null,
      instock: null,
      chassisNo: '',
      enginNo: '',
      acolourId: '',
      keyNo: '',
      batteryNo: '',
    });
    // Scroll to the bottom of the table body
    setTimeout(() => {
      tableBodyRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, 100);
  }, [stockdetails]);
  const allValues = watch();
  const GETBYID = async (id) => {
    try {
      let URL = `automobile/entries/${id}`;
      URL += `?status=true&`;
      const { data } = await fetcher(URL);
      if (data)
        setEntry(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    GETBYID(row?.id);
  }, [row])
  const inputRefs = useRef(allValues?.inv_details?.map(() => React.createRef()));

  const GST_NUMBER = user?.companies?.find((x) => x.id === user?.companyId)?.gst_no;
  const GST_Company = GST_NUMBER?.substring(0, 2);
  const GST_PARTY = allValues?.gst_no?.substring(0, 2);

  const CStateId = user?.companies?.find((x) => x.id === user?.companyId)?.stateId;
  const stateId = accountList?.find(
    (data) => data.id === allValues.entry_details[0].accountId
  )?.stateId;

  useEffect(() => {
    if (!GST_Company || (!GST_PARTY && allValues.tax_bi_Id !== 1)) {
      setValue('in_type', 3);
    } else if ((GST_NUMBER && GST_PARTY && GST_Company === GST_PARTY) || stateId === CStateId) {
      setValue('in_type', 1);
    } else if ((GST_NUMBER && GST_PARTY && GST_Company !== GST_PARTY) || stateId !== CStateId) {
      setValue('in_type', 2);
    }
  }, [CStateId, GST_Company, GST_NUMBER, GST_PARTY, allValues.tax_bi_Id, setValue, stateId]);

  const inv_details_live = useWatch({ control, name: "inv_details" });
  const inv_discounts_live = useWatch({ control, name: "inv_discounts" });
  const entry_details_live = useWatch({ control, name: "entry_details" });
  const prevDetailsRef = useRef();
  const prevEntryDetailsRef = useRef();
  const prevInvDiscountsRef = useRef();
  const calculateTotals = useCallback(() => {
    let newSubtotal = 0;
    let newBillAmount = 0;
    const propertiesToCheck = ['qty', 'rate'];
    inv_details_live?.filter(x => checkPropertiesTypeIsNumber(x, propertiesToCheck)).forEach((item, index) => {
      const itemTotal = item.qty * item.rate;
      const amountTotal = item.amount;
      newSubtotal += itemTotal;
      newBillAmount += amountTotal; // Assuming bill amount is just the subtotal for now
      const netRate = item.qty * item.rate;
      const gstAmt = ((netRate / 100) * item.IGST); // assuming 18% GST
      const netAmt = netRate + gstAmt;
      const qty = parseInt(item.qty, 10);
      const amount = item.amount;
      const IGSTPER = item.IGST || 0
      const rate = item.rate;
      if (prevDetailsRef.current?.[index]?.rate !== rate)
        setValue(`inv_details[${index}].rate`, rate);
      if (prevDetailsRef.current?.[index]?.amount !== amount)
        setValue(`inv_details[${index}].amount`, amount);
      if (prevDetailsRef.current?.[index]?.rate !== rate)
        setValue(`inv_details[${index}].rate`, rate);


    });

    const gstamt = inv_details_live.reduce((a, b) => a + b.GST, 0);
    if (!isNaN(gstamt)) {
      if (allValues.in_type === 1) {
        const CGSTINDEX = allValues.entry_details.findIndex(x => x.accountlabel === "CGST");
        if (CGSTINDEX >= 0) {
          if (prevEntryDetailsRef.current?.[CGSTINDEX]?.amount !== parseFloat((gstamt / 2).toFixed(2)))
            setValue(`entry_details[${CGSTINDEX}].amount`, parseFloat((gstamt / 2).toFixed(2)))
        }
        const SGSTINDEX = allValues.entry_details.findIndex(x => x.accountlabel === "SGST");
        if (SGSTINDEX >= 0) {
          if (prevEntryDetailsRef.current?.[SGSTINDEX]?.amount !== parseFloat((gstamt / 2).toFixed(2)))
            setValue(`entry_details[${SGSTINDEX}].amount`, parseFloat((gstamt / 2).toFixed(2)))
        }
      } else {
        const IGSTINDEX = allValues.entry_details.findIndex(x => x.accountlabel === "IGST");
        if (IGSTINDEX >= 0) {
          if (prevEntryDetailsRef.current?.[IGSTINDEX]?.amount !== parseFloat((gstamt).toFixed(2)))
            setValue(`entry_details[${IGSTINDEX}].amount`, parseFloat((gstamt).toFixed(2)))
        }
      }
    }
    let restTotal = 0;
    const DISCOUNTINDEX = allValues.entry_details.findIndex(x => [
      'DISCOUNT',
    ].includes(x?.accountlabel?.trim()));
    if (DISCOUNTINDEX >= 0) {
      restTotal = truncateToTwoDecimals(allValues.entry_details[DISCOUNTINDEX].amount)
    }
    const totamm = truncateToTwoDecimals(allValues.inv_details.reduce((acc, next) => acc + next.finalTotal, 0))
    if (truncateToTwoDecimals(newSubtotal) !== allValues.subtotal)
      setValue('subtotal', truncateToTwoDecimals(newSubtotal));
    if (truncateToTwoDecimals(totamm - restTotal)) {
      const partyIndex = allValues.entry_details.findIndex(x => x.order === 1);
      if (partyIndex >= 0) {
        if (prevEntryDetailsRef.current?.[partyIndex]?.amount !== truncateToTwoDecimals(totamm - restTotal))
          setValue(`entry_details[${partyIndex}].amount`, truncateToTwoDecimals(totamm - restTotal))
      }
      const SaleIndex = allValues.entry_details.findIndex(x => x.order === 2);
      const saleAC = allValues.entry_details
        .filter((x) => x.cb === 1 && x.accountlabel?.includes('GST'))
        ?.reduce((pre, next) => pre + next.amount || 0, 0);

      if (SaleIndex >= 0) {
        if (prevEntryDetailsRef.current?.[SaleIndex]?.amount !== truncateToTwoDecimals(totamm - saleAC))
          setValue(`entry_details[${SaleIndex}].amount`, truncateToTwoDecimals(totamm - saleAC))
      }
      if (truncateToTwoDecimals(totamm - restTotal) !== allValues.billamount)
        setValue('billamount', truncateToTwoDecimals(totamm - restTotal));
    }

    prevDetailsRef.current = inv_details_live;
    prevEntryDetailsRef.current = entry_details_live;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inv_details_live, setValue, entry_details_live]);
  useEffect(() => {
    calculateTotals();
  }, [calculateTotals, inv_details_live]);

  const calculatediscount = useCallback(() => {
    const objectDiscount = {};
    inv_discounts_live.forEach(x => { objectDiscount[x.pcategoryId] = x.rate })
    inv_details_live?.forEach((item, index) => {
      if (item?.product?.pcategoryId && item?.product?.pcategoryId in objectDiscount)
        if (prevDetailsRef.current?.[index]?.drate !== objectDiscount[item.product.pcategoryId]) {
          setValue(`inv_details[${index}].drate`, objectDiscount[item.product.pcategoryId]);
        }
    });


    prevInvDiscountsRef.current = inv_discounts_live;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inv_discounts_live, setValue, inv_details_live]);

  useEffect(() => {
    calculatediscount();
  }, [calculatediscount, inv_discounts_live]);

  const getbysale = useCallback(async (jobCardNumber, getinvoice) => {
    try {
      let URL = 'automobile/entries/';
      URL += `?page=${1}&limit=${1}&workshop=true&t_type=9&jobcardNo=${jobCardNumber}`;
      const { data } = await fetcher(URL);
      if (data.rows) {
        getinvoice.data = data.rows
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const onSubmit = handleSubmit(async (payload) => {
    try {
      payload = sanitizeComplexObject(allValues);
      payload.amount = payload.billamount;
      payload.jobcardNo = jobcardNo;
      let URL = 'automobile/entries/';
      // const cd = allValues.c_d;
      const resetValues = { ...values };
      // resetValues.c_d = cd;

      if (allValues?.tax_bi_Id === 3) {
        const unique = new Set();
        trasaction.forEach((x) => {
          x.tfields.forEach((y) => {
            unique.add(y.accountId);
          });
        });
        payload.entry_details = allValues.entry_details.filter((x) => !unique.has(x.accountId));
      }
      const ct = parseFloat(Math.round(
        allValues.entry_details
          .filter((x) => x.cb === 1)
          ?.reduce((pre, next) => pre + next.amount || 0, 0)
      )?.toFixed(2));
      const dt = parseFloat(Math.round(
        allValues.entry_details
          .filter((x) => x.cb === 2)
          ?.reduce((pre, next) => pre + next.amount || 0, 0))?.toFixed(2))
      if (allValues.iscreate === false && ct === dt) {
        URL += invoiceId;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          if (show) {
            enqueueSnackbar('Update success!');
          }
          getAll();
          reset(resetValues);
          onClose();
        }
      } else if (ct === dt) {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          if (show) {
            enqueueSnackbar('Create success!');
          }
          getAll();
          reset(resetValues);
          document.getElementById('invoice_type').focus();
        }
      }
      setImportDisabled(false);
    } catch (error) {
      reset(payload);
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });
  const setdiscount = useCallback((row, data) => {
    const onlyparticular = ["parts", "accessories", "labour", "oil", "other"];
    let dat = [];
    if (row) {

      const getamount = (id) => {
        const fin = row.inv_discounts.find(x => x.pcategoryId === id)
        return fin ? fin.rate : 0
      }
      dat = data?.rows.filter(y => onlyparticular.includes(y.name.toLowerCase())).map((x) => ({ pcategoryId: x.id, rate: getamount(x.id), name: x.name }));
    }
    else
      dat = data?.rows.filter(y => onlyparticular.includes(y.name.toLowerCase())).map((x) => ({ pcategoryId: x.id, rate: 0, name: x.name }));
    return dat;
  }, []);
  const HandleProductCategoryList = useCallback(async (row) => {
    try {
      let URL = 'automobile/pcategories/';
      URL += `?page=${1}&limit=${100}&status=true`;
      const { data } = await fetcher(URL);

      const dat = setdiscount(row, data);
      setProductCategoryList(dat);
      setValue('inv_discounts', dat)
    } catch (error) {
      console.error(error);
    }
  }, [setValue, setdiscount])
  useEffect(() => {
    reset();
    HandleProductCategoryList();
  }, [HandleProductCategoryList, open, reset]);

  const inv_details = useFieldArray({
    control,
    name: 'inv_details',
  });
  const entry_details = useFieldArray({
    control,
    name: 'entry_details',
  });

  const TotalQty = allValues.inv_details?.reduce((a, b) => a + (parseFloat(b.qty) || 0), 0);
  const TotalDPrice = allValues.inv_details?.reduce(
    (a, b) =>
      a +
      parseFloat(
        parseFloat(b?.dprice) ||
        (b?.drate > 0 &&
          b?.rate > 0 &&
          ((parseFloat(b?.rate) * parseFloat(b?.drate)) / 100)?.toFixed(2)) ||
        0
      ),
    0
  );

  const handleAdd = useCallback(() => {
    inv_details?.append({
      id: null,
      productId: null,
      product: null,
      qty: null,
      rate: null,
      dprice: null,
      drate: null,
      CGST: null,
      SGST: null,
      IGST: null,
    });
    inputRefs.current = [...inputRefs.current, React.createRef()];
    // Scroll to the bottom of the table body
    setTimeout(() => {
      tableBodyRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, 100);
  }, [inv_details]);

  const handleRemove = useCallback(
    (index) => {
      if (index >= 0 && index !== null) {
        inv_details?.remove(index);
      }
    },
    [inv_details]
  );

  const HandleTransactionList = async () => {
    let URL = 'automobile/tsettings/';
    URL += `?page=${1}&limit=${10000}&status=true&`;
    const { data } = await fetcher(URL);
    setTrasaction(data?.rows);
  };

  const JobCardData = useCallback(
    async (jobCardNumber, primary) => {
      try {
        let URL = `automobile/jobcards/${jobCardNumber}`;
        URL += `?page=${1}&limit=${10000}&status=true&`;
        if (primary)
          URL += `&primary=true&`;

        const { data } = await fetcher(URL);

        if (data) {
          const getinvoice = { data: [] }
          // await getbysale(jobCardNumber, getinvoice);
          if (getinvoice.data.length === 0) {
            if (!primary) {
              reset({
                ...sedata({}),
                dt: moment().format("DD-MM-YYYY"),
                iscreate: true,
                jobcardId: data?.id,
                jobcarddate: moment(data?.createdAt).format("DD-MM-YYYY"),
                account: data?.account,
                customer: data?.customer,
                chassisNo: data?.chassisNo || '',
                regNo: data?.regNo || '',
                enginNo: data?.enginNo || '',
                productId: data?.productId || '',
                product: data?.product || '',
                servicetype: data?.servicetype || '',
                servicetypeId: data?.servicetypeId || '',
                servicetypeName: data?.servicetype.name || '',
                productName: data?.product.name || '',
                colourName: data?.acolour?.name || '',
                currentkm: data?.currentkm || '',
                deliverykm: data?.deliverykm || '',
                colourId: data?.acolourId || '',
                acolour: data?.acolour || '',
                entry_details: allValues.entry_details.map(x => ({ ...x, amount: 0 }))
              });
              await JobCardDataList(jobCardNumber);
              HandleProductCategoryList({ inv_discounts: [] });
              setInvoiceId(null)
            }
          } else {
            reset({
              ...sedata(getinvoice.data[0]),
              dt: moment(getinvoice.data[0].dt).format("DD-MM-YYYY"),
              iscreate: false,
              jobcardId: data?.id,
              jobcarddate: moment(data?.createdAt).format("DD-MM-YYYY"),
              account: data?.account,
              customer: data?.customer,
              chassisNo: data?.chassisNo || '',
              regNo: data?.regNo || '',
              enginNo: data?.enginNo || '',
              productId: data?.productId || '',
              product: data?.product || '',
              servicetype: data?.servicetype || '',
              servicetypeId: data?.servicetypeId || '',
              servicetypeName: data?.servicetype.name || '',
              productName: data?.product.name || '',
              colourName: data?.acolour?.name || '',
              currentkm: data?.currentkm || '',
              deliverykm: data?.deliverykm || '',
              colourId: data?.acolourId || '',
              acolour: data?.acolour || '',
            });
            setInvoiceId(getinvoice.data[0]?.id)
            HandleProductCategoryList(getinvoice.data[0]);
          }
        } else {
          reset({ ...sedata({}), iscreate: true })
        }
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reset, HandleProductCategoryList]
  );
  const JobCardDataList = useCallback(
    async (jobCardNumber) => {
      try {
        let URL = `automobile/issues/jobcardNo/${jobCardNumber}`;
        URL += `?page=${1}&limit=${10000}&status=true&`;
        const { data } = await fetcher(URL);

        if (data) {
          const or1 = allValues.entry_details.findIndex(x => x.order === 1);
          if (or1 >= 0 && Array.isArray(data) && data.length) {
            setValue(`entry_details[${or1}].accountId`, data[0]?.jobcard?.accountId)
            setValue(`entry_details[${or1}].account`, data[0]?.jobcard?.account)
          }
          const in_details = [];
          let ind = -1;
          data.forEach(x => {
            x.issue_details.forEach((y, index) => {
              ind += 1;
              in_details.push({
                id: null,
                index: ind,
                productId: y?.productId || null,
                productName: y?.product?.name || '',
                product: y.product,
                qty: y?.qty || null,
                rate: y?.rate || null,
                dprice: null,
                drate: null,
                CGST: y?.CGST || null,
                SGST: y?.SGST || null,
                IGST: y?.IGST || null,
              })
            })
          })
          setValue('inv_details', in_details);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reset]
  );

  const jobCardNumber = useDebounce(jobcardNo);

  useEffect(() => {

    // if (jobCardNumber) JobCardData(jobCardNumber);
  }, [jobCardNumber, JobCardData]);


  const lastentry = useCallback(async () => {
    const URL = `automobile/entries/last/?t_type=${allValues.t_type}`;
    const { data } = await fetcher(URL);
    if (!row?.id && data?.v_no)
      setValue('v_no', data?.v_no);
  }, [allValues.t_type, row?.id, setValue]);

  useEffect(() => {
    if (allValues.t_type)
      lastentry();
  }, [allValues.t_type, lastentry])

  const YearList = useCallback(async () => {
    try {
      let URL = 'co_years';
      URL += `?page=${1}&limit=${10000}&status=true&companyId=${user?.companyId}&`;
      const { data } = await fetcher(URL);
      if (data) {
        setYearData(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, [user?.companyId]);

  useEffect(() => {
    if (open) {
      HandleTransactionList();
      YearList();
      if (row && row.jobcardId) {
        JobCardData(row.jobcardId, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, row]);

  // useEffect(() => {
  //   HandleProductList();
  // }, [HandleProductList]);

  useEffect(() => {
    if (invoiceSettings?.value) {
      HandleTransactionList();
    }
  }, [invoiceSettings?.value]);

  const handleKeyDown = (event) => {
    if (event.altKey && event.key === 'a') {
      event.stopPropagation();
      handleAdd();
    }
    if (event.altKey && event.key === 'r') {
      event.stopPropagation();
      handleRemove(currentRowIndex);
    }
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };
  useEventListener('keydown', handleKeyDown);


  const handleCloseProduct = () => {
    quickEditProduct.onFalse();
    quickEditGSTCommdity.onFalse();
    // HandleProductList();
  };

  const uploadFile = async (data) => {
    try {
      setImportLoading(true);
      const URL = 'automobile/stockdetails/import/';
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formdata = new FormData();
      formdata.append('file', data);
      const response = await create({ url: URL, payload: formdata, config });
      if (response) {
        setValue('stockdetails', response?.stockdetails);
        setValue('truckNo', response?.truckNo);
        setValue('transportName', response?.transportName);
        setValue('bill_no', response?.bill_no);
        setValue('b_date', new Date(response?.b_date));
        setValue('LRefNo', response?.LRefNo);
        setImportDisabled(true);
      }
      setImportLoading(false);
    } catch (error) {
      setImportLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (allValues?.b_date) document.getElementById('purchase_ac')?.focus();
  }, [allValues?.b_date]);

  // This effect adjusts the cursor position
  useEffect(() => {
    allValues.inv_details.forEach((detail, index) => {
      const input = inputRefs?.current[index]?.current;
      if (input) {
        const { value } = input;
        if (value.endsWith('.00')) {
          const position = value.length - 3; // Position before '.00'
          input.setSelectionRange(position, position);
        }
      }
    });
  }, [allValues.inv_details]); //
  const itemTotal = allValues?.inv_details.filter((x) => x.rate);

  return (
    <>
      <input
        ref={fileRef}
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv"
        style={{ display: 'none' }}
        multiple={false}
        onChange={async (e) => {
          if (e.target.files['0']) {
            await uploadFile(e.target.files['0']);
            e.target.value = '';
          }
        }}
      />
      <Dialog fullScreen onKeyDown={handleKeyDown} open={open}>
        {importLoading || challanLoading ? (
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              sx={{ margin: 'auto', textAlign: 'center' }}
            >
              <CircularProgress />
              <br />
              Loading...
            </Grid>
          </Grid>
        ) : (
          <FormProvider methods={methods}>
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  top: 0,
                  zIndex: 9,
                  position: 'sticky',
                  bgcolor: (theme) => alpha(theme.palette.background.default, 1),
                }}
              >
                <Toolbar>
                  <Typography variant="h6" sx={{ flex: 1 }}>
                    {row ? `Edit Jobcard Invoice` : `Add Jobcard Invoice`}
                  </Typography>

                  <CompanyYear />

                  <IconButton color="inherit" edge="start" onClick={onClose} tabIndex={-1}>
                    <Iconify icon="mingcute:close-line" />
                  </IconButton>
                </Toolbar>
                <Divider sx={{ borderStyle: 'dashed' }} />
              </Grid>

              <Grid item container xs={12} md={12} sx={{ px: 2, pt: 2, pb: 1 }} columnSpacing={5}>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  xl={3}
                  xxl={3}
                  spacing={2}
                  alignContent="start"
                  justifyContent="space-between"
                >
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      size="small"
                      options={yearData.length ? yearData : [{ name: 'No Options', value: null }]}
                      groupBy={(option) => option?.a}
                      getOptionLabel={(option) => {
                        const startDate = new Date(option.sdate);
                        const endDate = new Date(option.edate);
                        return `${startDate.getFullYear()}-${endDate.getFullYear()}`;
                      }}
                      openOnFocus
                      onChange={(event, newValue) => {
                        setValue('yearId', newValue?.id);
                      }}
                      value={(() => {
                        const addRecord = yearData?.find((data) => data.id === allValues?.yearId);
                        const editRecord = row?.godown;

                        if (addRecord) return addRecord;
                        if (editRecord) return editRecord;
                        return null;
                      })()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Year"
                          name="yearId"
                          error={errors?.yearId && !allValues?.yearId}
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                          <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                            {`${new Date(option.sdate).getFullYear()}-${new Date(
                              option.edate
                            ).getFullYear()}`}
                          </ListItem>
                        </Box>
                      )}
                      ListboxProps={{
                        ...bgColorAutocomplete,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField
                      name="jobcardNo"
                      label="Jobcard No."
                      size="small"
                      value={jobcardNo}
                      onChange={(e) => setJobcardNo(e.target.value)}
                      autoFocus
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField
                      name="v_no"
                      label="Invo No."
                      size="small"

                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <table style={{ fontSize: '11px' }}>
                      <tr>
                        <th align='left'>Jobcard Date</th>
                        <th>:</th>
                        <td>{allValues.jobcarddate || ''}</td>
                      </tr>
                      <tr>
                        <th align='left'>invoice Date</th>
                        <th>:</th>
                        <td>{allValues.dt || ''}</td>
                      </tr>
                    </table>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={4}
                  spacing={2}
                  alignContent="start"
                  alignItems="start"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={2}>
                    <table style={{ fontSize: '11px' }}>
                      <tr>
                        <th align='left'>Name</th>
                        <td> : </td>
                        <td>{allValues?.account?.name || ''}</td>
                      </tr>
                      <tr>
                        <th align='left' valign="top">Address</th>
                        <td valign="top"> : </td>
                        <td>{allValues?.customer?.address1 || ''}</td>
                      </tr>
                      <tr>
                        <th align='left' />
                        <td />
                        <td>{allValues?.customer?.pincode || ''} {allValues?.customer?.locality?.name ? `, ${allValues?.customer?.locality?.name}` : ''} {allValues?.customer?.city?.name ? `, ${allValues?.customer?.city?.name}` : ''}{allValues?.customer?.state?.name ? `, ${allValues?.customer?.state?.name}` : ''}</td>
                      </tr>

                    </table>
                  </Grid>

                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={4}
                  spacing={2}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} >
                    <table style={{ fontSize: '11px' }}>
                      <tr>
                        <th align='left'>Chassis No</th>
                        <td> : </td>
                        <td>{allValues.chassisNo || ''}</td>
                      </tr>
                      <tr>
                        <th align='left'>Engin No</th>
                        <td> : </td>
                        <td>{allValues.enginNo || ''}</td>
                      </tr>
                      <tr>
                        <th align='left'>Service Type</th>
                        <td> : </td>
                        <td>{allValues?.servicetype?.name || ''}</td>
                      </tr>
                    </table>

                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} >
                    <table style={{ fontSize: '11px' }}>
                      <tr>
                        <th align='left'>Model</th>
                        <td> : </td>
                        <td>{allValues?.product?.name || ''}</td>
                      </tr>
                      <tr>
                        <th align='left'>Reg No</th>
                        <td> : </td>
                        <td>{allValues?.regNo || ''}</td>
                      </tr>
                      <tr>
                        <th align='left'>Colour</th>
                        <td> : </td>
                        <td>{allValues?.acolour?.name || ''}</td>
                      </tr>
                    </table>

                  </Grid>
                </Grid>
              </Grid>

              <Grid item container sx={{ mt: 0 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} mt={0}>
                  <Typography variant="subtitle2" sx={{ ml: '10px' }}>
                    Parts
                  </Typography>
                  <TableContainer
                    component={Paper}
                    sx={{ overflow: 'auto', maxHeight: '20vh', width: '98%', margin: 'auto' }}
                  >

                    <Table
                      stickyHeader
                      aria-label="simple table"
                      sx={{
                        '& .MuiTableCell-sizeMedium': {
                          padding: '0px !important',
                        },
                        '& fieldset': { borderRadius: '0px', border: 'none' },
                        '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                        '& .MuiOutlinedInput-root': { padding: '0px !important' },
                        '& .autoComplete>div': { padding: '0px !important' },
                        '& .MuiTableCell-root': {
                          border: '0.01px solid #e9ecee',
                        },
                        '& .MuiTableCell-head': {
                          padding: '5px !important',
                          borderRight: '1px solid #d0d1d2 !important',
                        },
                        '& .MuiTableCell-footer': {
                          padding: '5px !important',
                        },
                        '& .MuiTableCell-head:last-child': {
                          borderRight: '0px !important',
                        },
                        '&:last-child td': {
                          borderColor: '#e9ecee !important',
                        },
                      }}
                    >
                      <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
                        <TableRow>
                          <TableCell sx={{ width: 20, textAlign: 'center' }}>No</TableCell>
                          <TableCell sx={{ width: 400, textAlign: 'center' }}>
                            Product Name
                          </TableCell>
                          <TableCell sx={{ width: 70, textAlign: 'center' }}>Unit</TableCell>
                          <TableCell sx={{ width: 70, textAlign: 'center' }}>Qty</TableCell>

                          <TableCell
                            sx={{ width: 130, textAlign: 'center', position: 'sticky', right: 0 }}
                          >
                            MRP
                          </TableCell>
                          {[1, 2, 4, 5].includes(allValues.tax_bi_Id) && (
                            <>

                              <TableCell sx={{ width: 40, textAlign: 'center' }}>GST(%)</TableCell>

                              {allValues.in_type === 1 && (
                                <>
                                  <TableCell sx={{ width: 80, textAlign: 'center' }}>
                                    CGST
                                  </TableCell>
                                  <TableCell sx={{ width: 80, textAlign: 'center' }}>
                                    SGST
                                  </TableCell>
                                </>
                              )}
                              {allValues.in_type === 2 && (
                                <TableCell sx={{ width: 80, textAlign: 'center' }}>IGST</TableCell>
                              )}
                            </>
                          )}
                          <TableCell sx={{ width: 70, textAlign: 'center' }}>Rate</TableCell>
                          <TableCell sx={{ width: 70, textAlign: 'center' }}>Disc.(₹)</TableCell>
                          <TableCell sx={{ width: 70, textAlign: 'center' }}>Disc.(%)</TableCell>
                          <TableCell sx={{ width: 100, textAlign: 'center' }}>
                            Gross Amount
                          </TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {inv_details?.fields?.filter(x => !x.product?.pcategory?.name.toLowerCase().includes('labour')).map((field, index) => {

                          let DPtotalAmount = 0;
                          let DRtotalAmount = 0;
                          let GrossAmount = 0;
                          let finalTotal = 0;
                          let totalAmount = 0;
                          let CGST = 0;
                          let SGST = 0;
                          let IGST = 0;

                          if (
                            allValues.inv_details[field.index]?.sal_rate &&
                            allValues.inv_details[field.index]?.qty
                          ) {
                            totalAmount =
                              allValues.inv_details[field.index].sal_rate * allValues.inv_details[field.index].qty;
                          }
                          if (allValues.inv_details[field.index]?.dprice) {
                            DPtotalAmount =
                              (allValues.inv_details[field.index].rate -
                                allValues.inv_details[field.index].dprice) *
                              allValues.inv_details[field.index].qty;
                          }
                          if (allValues.inv_details[field.index]?.drate) {
                            const rq =
                              allValues.inv_details[field.index].rate * allValues.inv_details[field.index].qty;
                            DRtotalAmount = rq - (rq / 100) * allValues.inv_details[field.index].drate;
                          }

                          if (DPtotalAmount || allValues.inv_details[field.index]?.dprice) {
                            finalTotal = DPtotalAmount;
                          } else if (DRtotalAmount || allValues.inv_details[field.index]?.drate) {
                            finalTotal = DRtotalAmount;
                          } else {
                            finalTotal = totalAmount;
                          }
                          GrossAmount = finalTotal;
                          if (allValues?.in_type === 1 && [1, 4].includes(allValues.tax_bi_Id)) {
                            SGST = (finalTotal / 100 || 0) * (allValues.inv_details[field.index]?.SGST || 0);
                            CGST = (finalTotal / 100 || 0) * (allValues.inv_details[field.index]?.CGST || 0);
                            allValues.inv_details[field.index].GST = (CGST + SGST) || 0
                            if (allValues?.tax_bi_Id === 1) finalTotal += CGST + SGST;
                          } else if (
                            allValues?.in_type === 2 &&
                            [1, 4].includes(allValues.tax_bi_Id)
                          ) {
                            IGST = (finalTotal / 100 || 0) * (allValues.inv_details[field.index]?.IGST || 0);
                            allValues.inv_details[field.index].GST = IGST || 0
                            if (allValues?.tax_bi_Id === 1) finalTotal += IGST;
                          }
                          allValues.inv_details[field.index].finalTotal = finalTotal;
                          gTotal += GrossAmount;

                          return (
                            <TableRow key={index} ref={tableBodyRef}>
                              <TableCell
                                sx={{
                                  textAlign: 'center',
                                  position: 'sticky',
                                  left: 0,
                                }}
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                sx={
                                  index >= 0 &&
                                  errors &&
                                  errors?.inv_details &&
                                  errors?.inv_details[index] &&
                                  errors?.inv_details[index]?.productId && {
                                    border: '0.01px solid #F90635 !important',
                                  }
                                }
                              >
                                {`${allValues.inv_details[field.index]?.productName}`}
                              </TableCell>

                              <TableCell sx={{ textAlign: 'end' }}>
                                {allValues.inv_details[field.index]?.product?.punit?.name || ''}
                              </TableCell>

                              <TableCell
                                sx={
                                  index >= 0 &&
                                    errors &&
                                    errors?.inv_details &&
                                    errors?.inv_details[index] &&
                                    errors?.inv_details[index]?.qty
                                    ? {
                                      border: '0.01px solid #F90635 !important',
                                      textAlign: 'end',
                                    }
                                    : { textAlign: 'end' }
                                }
                              >
                                {allValues.inv_details[field.index]?.qty}
                              </TableCell>

                              <TableCell
                                sx={
                                  index >= 0 &&
                                    errors &&
                                    errors?.inv_details &&
                                    errors?.inv_details[index] &&
                                    errors?.inv_details[index]?.rate
                                    ? {
                                      border: '0.01px solid #F90635 !important',
                                      textAlign: 'end',
                                    }
                                    : { textAlign: 'end' }
                                }
                              >
                                <RHFTextField
                                  inputProps={{
                                    style: { textAlign: 'right' },
                                  }}
                                  onFocus={(e) => {
                                    setCurrentRowIndex(index);
                                  }}
                                  onBlur={(e) => {
                                    if (!allValues.inv_details[field.index]?.productId) {
                                      document.getElementById(e.target.id).focus();
                                    }
                                    let { rate } = allValues.inv_details[field.index];
                                    if (!isNaN(rate) && rate >= 0) {
                                      rate = Number(rate).toFixed(2);
                                      setValue(`inv_details[${field.index}].rate`, rate);
                                    }
                                    setCurrentRowIndex(null);
                                  }}
                                  name={`inv_details[${field.index}].rate`}
                                  size="small"
                                  type="number"
                                  value={
                                    convertToRound(allValues.inv_details[field.index]?.rate)?.toFixed(
                                      2
                                    ) || ''
                                  }
                                  disabled
                                  onChange={(e) => {
                                    let rate = e.target.value;
                                    rate = parseFloat(rate);
                                    setValue(`inv_details[${field.index}].rate`, rate);
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <RHFTextField
                                  onFocus={(e) => {
                                    setCurrentRowIndex(index);
                                  }}
                                  onBlur={(e) => {
                                    setCurrentRowIndex(null);
                                  }}
                                  inputProps={{
                                    style: { textAlign: 'right' },
                                  }}
                                  name={`inv_details[${field.index}].dprice`}
                                  size="small"
                                  type="number"
                                  value={
                                    parseFloat(allValues.inv_details[field.index]?.dprice) ||
                                    (allValues.inv_details[field.index]?.drate > 0 &&
                                      allValues.inv_details[field.index]?.rate > 0 &&
                                      convertToRound(
                                        (parseFloat(allValues.inv_details[field.index]?.rate) *
                                          parseFloat(allValues.inv_details[field.index]?.drate)) /
                                        100
                                      )?.toFixed(2))
                                  }
                                  onChange={(e) => {
                                    if (
                                      !isNaN(parseFloat(e.target.value)) &&
                                      !isNaN(parseFloat(allValues.inv_details[field.index]?.rate))
                                    ) {
                                      const disPrice =
                                        parseFloat(e.target.value) >= 0 &&
                                        parseFloat(e.target.value) <=
                                        parseFloat(allValues.inv_details[field.index]?.rate) &&
                                        parseFloat(e.target.value);
                                      setValue(
                                        `inv_details[${field.index}].dprice`,
                                        parseFloat(disPrice)
                                      );
                                    } else {
                                      setValue(`inv_details[${field.index}].dprice`, null);
                                    }
                                  }}
                                  disabled={
                                    !(allValues.inv_details[field.index]?.rate > 0) ||
                                    allValues.inv_details[field.index]?.drate
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <RHFTextField
                                  onFocus={(e) => {
                                    setCurrentRowIndex(index);
                                  }}
                                  onBlur={(e) => {
                                    setCurrentRowIndex(null);
                                  }}
                                  inputProps={{
                                    style: { textAlign: 'right' },
                                  }}
                                  name={`inv_details[${field.index}].drate`}
                                  size="small"
                                  type="number"
                                  value={
                                    parseFloat(allValues.inv_details[field.index]?.drate) ||
                                    (allValues.inv_details[field.index]?.dprice > 0 &&
                                      allValues.inv_details[field.index]?.rate > 0 &&
                                      (
                                        (parseFloat(allValues.inv_details[field.index]?.dprice) /
                                          parseFloat(allValues.inv_details[field.index]?.rate)) *
                                        100
                                      )?.toFixed(2))
                                  }
                                  onChange={(e) => {
                                    const disRate =
                                      e.target.value >= 0 &&
                                      e.target.value <= 100 &&
                                      e.target.value;
                                    setValue(`inv_details[${field.index}].drate`, parseFloat(disRate));
                                  }}
                                  disabled={
                                    !(allValues.inv_details[field.index]?.rate > 0) ||
                                    allValues.inv_details[field.index]?.dprice
                                  }
                                />
                              </TableCell>

                              {[1, 2, 4, 5].includes(allValues.tax_bi_Id) && (
                                <>
                                  <TableCell sx={{ textAlign: 'end' }}>
                                    {GrossAmount ? GrossAmount?.toFixed(2) : ''}
                                  </TableCell>
                                  <TableCell
                                    sx={{ textAlign: 'end' }}
                                    onClick={() => {
                                      if (allValues.inv_details[field.index]?.productId) {
                                        setGSTCommodity({
                                          ...GSTCommodity,
                                          productId: allValues.inv_details[field.index]?.productId,
                                        });
                                        quickEditGSTCommdity.setValue(true);
                                      }
                                    }}
                                  >
                                    {allValues.inv_details[field.index]?.IGST ? `${allValues.inv_details[field.index]?.IGST} %` : ''}
                                  </TableCell>
                                  {allValues.in_type === 1 && (
                                    <>
                                      <TableCell sx={{ textAlign: 'end' }}>
                                        {CGST?.toFixed(2) || ''}
                                      </TableCell>
                                      <TableCell sx={{ textAlign: 'end' }}>
                                        {SGST?.toFixed(2) || ''}
                                      </TableCell>
                                    </>
                                  )}
                                  {allValues.in_type === 2 && (
                                    <TableCell sx={{ textAlign: 'end' }}>
                                      {IGST?.toFixed(2) || ''}
                                    </TableCell>
                                  )}
                                </>
                              )}

                              <TableCell
                                sx={{ textAlign: 'end', position: 'sticky', right: 0, index: 9 }}
                              >
                                {convertToRound(finalTotal).toFixed(2) || ''}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                      <TableFooter
                        sx={{
                          bottom: '0',
                          position: 'sticky',
                          zIndex: '11111',
                          bgcolor: (theme) => alpha(theme.palette.background.default, 1),
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '15px' }}
                            colSpan={2}
                          >
                            Total Entry : {allValues.inv_details.filter(x => x.product?.pcategory?.name.toLowerCase().includes('part'))?.length || '0'}
                          </TableCell>

                          <TableCell sx={{ textAlign: 'center' }} />
                          <TableCell
                            sx={{ textAlign: 'end', fontWeight: 'bold', fontSize: '15px' }}
                          >
                            {allValues.inv_details.filter(x => x.product?.pcategory?.name.toLowerCase().includes('part')).reduce((acc, next) => acc + next.qty, 0)?.toFixed(2) || ''}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }} />
                          <TableCell
                            sx={{ textAlign: 'end', fontWeight: 'bold', fontSize: '15px' }}
                          >
                            {allValues.inv_details.filter(x => x.product?.pcategory?.name.toLowerCase().includes('part')).reduce(
                              (a, b) =>
                                a +
                                parseFloat(
                                  parseFloat(b?.dprice) ||
                                  (b?.drate > 0 &&
                                    b?.rate > 0 &&
                                    ((parseFloat(b?.rate) * parseFloat(b?.drate)) / 100)?.toFixed(2)) ||
                                  0
                                ),
                              0
                            )?.toFixed(2) || ''}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }} />
                          {[1, 2, 4, 5].includes(allValues.tax_bi_Id) && (
                            <>
                              <TableCell
                                sx={{ textAlign: 'end', fontWeight: 'bold', fontSize: '15px' }}
                              >
                                {gTotal?.toFixed(2) || ''}
                              </TableCell>
                              <TableCell sx={{ textAlign: 'center' }} />
                              {allValues.in_type === 1 && (
                                <>
                                  <TableCell sx={{ textAlign: 'end' }}>
                                    {allValues.inv_details.filter(x => x.product?.pcategory?.name.toLowerCase().includes('part')).reduce(
                                      (a, b) =>
                                        a +
                                        (parseFloat(b.GST / 2) ||
                                          0),
                                      0
                                    )?.toFixed(2) || ''}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: 'end' }}>
                                    {allValues.inv_details.filter(x => x.product?.pcategory?.name.toLowerCase().includes('part')).reduce(
                                      (a, b) =>
                                        a +
                                        (parseFloat(b.GST / 2) ||
                                          0),
                                      0
                                    )?.toFixed(2) || ''}
                                  </TableCell>
                                </>
                              )}
                              {allValues.in_type === 2 && (
                                <TableCell sx={{ textAlign: 'end' }}>
                                  {allValues.inv_details.filter(x => x.product?.pcategory?.name.toLowerCase().includes('part')).reduce(
                                    (a, b) =>
                                      a +
                                      (parseFloat(b.GST) ||
                                        0),
                                    0
                                  )?.toFixed(2) || ''}
                                </TableCell>
                              )}
                            </>
                          )}

                          <TableCell
                            sx={{
                              textAlign: 'end',
                              position: 'sticky',
                              right: 0,
                              fontWeight: 'bold',
                              fontSize: '15px',
                            }}
                          >
                            {convertToRound(allValues.inv_details.filter(x => x.product?.pcategory?.name.toLowerCase().includes('part')).reduce((acc, next) => acc + next.finalTotal, 0))?.toFixed(2)}
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} mt={0}>
                  <Typography variant="subtitle2" sx={{ ml: '10px' }}>
                    Labour
                  </Typography>
                  <TableContainer
                    component={Paper}
                    sx={{ overflow: 'auto', maxHeight: '20vh', width: '98%', margin: 'auto' }}
                  >
                    <Table
                      stickyHeader
                      aria-label="simple table"
                      sx={{
                        '& .MuiTableCell-sizeMedium': {
                          padding: '0px !important',
                        },
                        '& fieldset': { borderRadius: '0px', border: 'none' },
                        '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                        '& .MuiOutlinedInput-root': { padding: '0px !important' },
                        '& .autoComplete>div': { padding: '0px !important' },
                        '& .MuiTableCell-root': {
                          border: '0.01px solid #e9ecee',
                        },
                        '& .MuiTableCell-head': {
                          padding: '5px !important',
                          borderRight: '1px solid #d0d1d2 !important',
                        },
                        '& .MuiTableCell-footer': {
                          padding: '5px !important',
                        },
                        '& .MuiTableCell-head:last-child': {
                          borderRight: '0px !important',
                        },
                        '&:last-child td': {
                          borderColor: '#e9ecee !important',
                        },
                      }}
                    >
                      <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
                        <TableRow>
                          <TableCell sx={{ width: 20, textAlign: 'center' }}>No</TableCell>
                          <TableCell sx={{ width: 400, textAlign: 'center' }}>
                            Product Name
                          </TableCell>
                          <TableCell sx={{ width: 70, textAlign: 'center' }}>Unit</TableCell>
                          <TableCell sx={{ width: 70, textAlign: 'center' }}>Qty</TableCell>
                          <TableCell sx={{ width: 70, textAlign: 'center' }}>Rate</TableCell>
                          <TableCell sx={{ width: 70, textAlign: 'center' }}>Disc.(₹)</TableCell>
                          <TableCell sx={{ width: 70, textAlign: 'center' }}>Disc.(%)</TableCell>
                          {[1, 2, 4, 5].includes(allValues.tax_bi_Id) && (
                            <>
                              <TableCell sx={{ width: 100, textAlign: 'center' }}>
                                Gross Amount
                              </TableCell>
                              <TableCell sx={{ width: 40, textAlign: 'center' }}>GST(%)</TableCell>

                              {allValues.in_type === 1 && (
                                <>
                                  <TableCell sx={{ width: 80, textAlign: 'center' }}>
                                    CGST
                                  </TableCell>
                                  <TableCell sx={{ width: 80, textAlign: 'center' }}>
                                    SGST
                                  </TableCell>
                                </>
                              )}
                              {allValues.in_type === 2 && (
                                <TableCell sx={{ width: 80, textAlign: 'center' }}>IGST</TableCell>
                              )}
                            </>
                          )}

                          <TableCell
                            sx={{ width: 130, textAlign: 'center', position: 'sticky', right: 0 }}
                          >
                            Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {inv_details?.fields?.filter(x => x.product?.pcategory?.name.toLowerCase().includes('labour')).map((field, index) => {

                          const gstRate = allValues.inv_details[field.index].product?.gst_commodity?.gst_com_detail;
                          let DPtotalAmount = 0;
                          let DRtotalAmount = 0;
                          let GrossAmount = 0;
                          let finalTotal = 0;
                          let totalAmount = 0;
                          let CGST = 0;
                          let SGST = 0;
                          let IGST = 0;

                          if (
                            allValues.inv_details[field.index]?.rate &&
                            allValues.inv_details[field.index]?.qty
                          ) {
                            totalAmount =
                              allValues.inv_details[field.index].rate * allValues.inv_details[field.index].qty;
                          }
                          if (allValues.inv_details[field.index]?.dprice) {
                            DPtotalAmount =
                              (allValues.inv_details[field.index].rate -
                                allValues.inv_details[field.index].dprice) *
                              allValues.inv_details[field.index].qty;
                          }
                          if (allValues.inv_details[field.index]?.drate) {
                            const rq =
                              allValues.inv_details[field.index].rate * allValues.inv_details[field.index].qty;
                            DRtotalAmount = rq - (rq / 100) * allValues.inv_details[field.index].drate;
                          }

                          if (DPtotalAmount || allValues.inv_details[field.index]?.dprice) {
                            finalTotal = DPtotalAmount;
                          } else if (DRtotalAmount || allValues.inv_details[field.index]?.drate) {
                            finalTotal = DRtotalAmount;
                          } else {
                            finalTotal = totalAmount;
                          }
                          GrossAmount = finalTotal;
                          if (allValues?.in_type === 1 && [1, 4].includes(allValues.tax_bi_Id)) {
                            SGST = (finalTotal / 100 || 0) * (allValues.inv_details[field.index]?.SGST || 0);
                            CGST = (finalTotal / 100 || 0) * (allValues.inv_details[field.index]?.CGST || 0);
                            allValues.inv_details[field.index].GST = (CGST + SGST) || 0
                            if (allValues?.tax_bi_Id === 1) finalTotal += CGST + SGST;
                          } else if (
                            allValues?.in_type === 2 &&
                            [1, 4].includes(allValues.tax_bi_Id)
                          ) {
                            IGST = (finalTotal / 100 || 0) * (allValues.inv_details[field.index]?.IGST || 0);
                            allValues.inv_details[field.index].GST = IGST || 0
                            if (allValues?.tax_bi_Id === 1) finalTotal += IGST;
                          }
                          allValues.inv_details[field.index].finalTotal = finalTotal;
                          gTotal += GrossAmount;

                          return (
                            <TableRow key={index} ref={tableBodyRef}>
                              <TableCell
                                sx={{
                                  textAlign: 'center',
                                  position: 'sticky',
                                  left: 0,
                                }}
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                sx={
                                  index >= 0 &&
                                  errors &&
                                  errors?.inv_details &&
                                  errors?.inv_details[index] &&
                                  errors?.inv_details[index]?.productId && {
                                    border: '0.01px solid #F90635 !important',
                                  }
                                }
                              >
                                {`${allValues.inv_details[field.index]?.productName}`}
                              </TableCell>

                              <TableCell sx={{ textAlign: 'end' }}>
                                {allValues.inv_details[field.index]?.product?.punit?.name || ''}
                              </TableCell>

                              <TableCell
                                sx={
                                  index >= 0 &&
                                    errors &&
                                    errors?.inv_details &&
                                    errors?.inv_details[index] &&
                                    errors?.inv_details[index]?.qty
                                    ? {
                                      border: '0.01px solid #F90635 !important',
                                      textAlign: 'end',
                                    }
                                    : { textAlign: 'end' }
                                }
                              >
                                {allValues.inv_details[field.index]?.qty}
                              </TableCell>

                              <TableCell
                                sx={
                                  index >= 0 &&
                                    errors &&
                                    errors?.inv_details &&
                                    errors?.inv_details[index] &&
                                    errors?.inv_details[index]?.rate
                                    ? {
                                      border: '0.01px solid #F90635 !important',
                                      textAlign: 'end',
                                    }
                                    : { textAlign: 'end' }
                                }
                              >
                                <RHFTextField
                                  inputProps={{
                                    style: { textAlign: 'right' },
                                  }}
                                  onFocus={(e) => {
                                    setCurrentRowIndex(index);
                                  }}
                                  onBlur={(e) => {
                                    if (!allValues.inv_details[field.index]?.productId) {
                                      document.getElementById(e.target.id).focus();
                                    }
                                    let { rate } = allValues.inv_details[field.index];
                                    if (!isNaN(rate) && rate >= 0) {
                                      rate = Number(rate).toFixed(2);
                                      setValue(`inv_details[${field.index}].rate`, rate);
                                    }
                                    setCurrentRowIndex(null);
                                  }}
                                  name={`inv_details[${field.index}].rate`}
                                  size="small"
                                  type="number"
                                  value={
                                    convertToRound(allValues.inv_details[field.index]?.rate)?.toFixed(
                                      2
                                    ) || ''
                                  }
                                  disabled
                                  onChange={(e) => {
                                    let rate = e.target.value;
                                    rate = parseFloat(rate);
                                    setValue(`inv_details[${field.index}].rate`, rate);
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <RHFTextField
                                  onFocus={(e) => {
                                    setCurrentRowIndex(index);
                                  }}
                                  onBlur={(e) => {
                                    setCurrentRowIndex(null);
                                  }}
                                  inputProps={{
                                    style: { textAlign: 'right' },
                                  }}
                                  name={`inv_details[${field.index}].dprice`}
                                  size="small"
                                  type="number"
                                  value={
                                    parseFloat(allValues.inv_details[field.index]?.dprice) ||
                                    (allValues.inv_details[field.index]?.drate > 0 &&
                                      allValues.inv_details[field.index]?.rate > 0 &&
                                      convertToRound(
                                        (parseFloat(allValues.inv_details[field.index]?.rate) *
                                          parseFloat(allValues.inv_details[field.index]?.drate)) /
                                        100
                                      )?.toFixed(2))
                                  }
                                  onChange={(e) => {
                                    if (
                                      !isNaN(parseFloat(e.target.value)) &&
                                      !isNaN(parseFloat(allValues.inv_details[field.index]?.rate))
                                    ) {
                                      const disPrice =
                                        parseFloat(e.target.value) >= 0 &&
                                        parseFloat(e.target.value) <=
                                        parseFloat(allValues.inv_details[field.index]?.rate) &&
                                        parseFloat(e.target.value);
                                      setValue(
                                        `inv_details[${field.index}].dprice`,
                                        parseFloat(disPrice)
                                      );
                                    } else {
                                      setValue(`inv_details[${field.index}].dprice`, null);
                                    }
                                  }}
                                  disabled={
                                    !(allValues.inv_details[field.index]?.rate > 0) ||
                                    allValues.inv_details[field.index]?.drate
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <RHFTextField
                                  onFocus={(e) => {
                                    setCurrentRowIndex(index);
                                  }}
                                  onBlur={(e) => {
                                    setCurrentRowIndex(null);
                                  }}
                                  inputProps={{
                                    style: { textAlign: 'right' },
                                  }}
                                  name={`inv_details[${field.index}].drate`}
                                  size="small"
                                  type="number"
                                  value={
                                    parseFloat(allValues.inv_details[field.index]?.drate) ||
                                    (allValues.inv_details[field.index]?.dprice > 0 &&
                                      allValues.inv_details[field.index]?.rate > 0 &&
                                      (
                                        (parseFloat(allValues.inv_details[field.index]?.dprice) /
                                          parseFloat(allValues.inv_details[field.index]?.rate)) *
                                        100
                                      )?.toFixed(2))
                                  }
                                  onChange={(e) => {
                                    const disRate =
                                      e.target.value >= 0 &&
                                      e.target.value <= 100 &&
                                      e.target.value;
                                    setValue(`inv_details[${field.index}].drate`, parseFloat(disRate));
                                  }}
                                  disabled={
                                    !(allValues.inv_details[field.index]?.rate > 0) ||
                                    allValues.inv_details[field.index]?.dprice
                                  }
                                />
                              </TableCell>

                              {[1, 2, 4, 5].includes(allValues.tax_bi_Id) && (
                                <>
                                  <TableCell sx={{ textAlign: 'end' }}>
                                    {GrossAmount ? GrossAmount?.toFixed(2) : ''}
                                  </TableCell>
                                  <TableCell
                                    sx={{ textAlign: 'end' }}
                                    onClick={() => {
                                      if (allValues.inv_details[field.index]?.productId) {
                                        setGSTCommodity({
                                          ...GSTCommodity,
                                          productId: allValues.inv_details[field.index]?.productId,
                                        });
                                        quickEditGSTCommdity.setValue(true);
                                      }
                                    }}
                                  >
                                    {allValues.inv_details[field.index]?.IGST ? `${allValues.inv_details[field.index]?.IGST} %` : ''}
                                  </TableCell>
                                  {allValues.in_type === 1 && (
                                    <>
                                      <TableCell sx={{ textAlign: 'end' }}>
                                        {CGST?.toFixed(2) || ''}
                                      </TableCell>
                                      <TableCell sx={{ textAlign: 'end' }}>
                                        {SGST?.toFixed(2) || ''}
                                      </TableCell>
                                    </>
                                  )}
                                  {allValues.in_type === 2 && (
                                    <TableCell sx={{ textAlign: 'end' }}>
                                      {IGST?.toFixed(2) || ''}
                                    </TableCell>
                                  )}
                                </>
                              )}

                              <TableCell
                                sx={{ textAlign: 'end', position: 'sticky', right: 0, index: 9 }}
                              >
                                {convertToRound(finalTotal).toFixed(2) || ''}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                      <TableFooter
                        sx={{
                          bottom: '0',
                          position: 'sticky',
                          zIndex: '11111',
                          bgcolor: (theme) => alpha(theme.palette.background.default, 1),
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '15px' }}
                            colSpan={2}
                          >
                            Total Entry : {allValues.inv_details.filter(x => x.product?.pcategory?.name.toLowerCase().includes('labour'))?.length || '0'}
                          </TableCell>

                          <TableCell sx={{ textAlign: 'center' }} />
                          <TableCell
                            sx={{ textAlign: 'end', fontWeight: 'bold', fontSize: '15px' }}
                          >
                            {allValues.inv_details.filter(x => x.product?.pcategory?.name.toLowerCase().includes('labour')).reduce((acc, next) => acc + next.qty, 0)?.toFixed(2) || ''}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }} />
                          <TableCell
                            sx={{ textAlign: 'end', fontWeight: 'bold', fontSize: '15px' }}
                          >
                            {allValues.inv_details.filter(x => x.product?.pcategory?.name.toLowerCase().includes('labour')).reduce(
                              (a, b) =>
                                a +
                                parseFloat(
                                  parseFloat(b?.dprice) ||
                                  (b?.drate > 0 &&
                                    b?.rate > 0 &&
                                    ((parseFloat(b?.rate) * parseFloat(b?.drate)) / 100)?.toFixed(2)) ||
                                  0
                                ),
                              0
                            )?.toFixed(2) || ''}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }} />
                          {[1, 2, 4, 5].includes(allValues.tax_bi_Id) && (
                            <>
                              <TableCell
                                sx={{ textAlign: 'end', fontWeight: 'bold', fontSize: '15px' }}
                              >
                                {gTotal?.toFixed(2) || ''}
                              </TableCell>
                              <TableCell sx={{ textAlign: 'center' }} />
                              {allValues.in_type === 1 && (
                                <>
                                  <TableCell sx={{ textAlign: 'end' }}>
                                    {allValues.inv_details.filter(x => x.product?.pcategory?.name.toLowerCase().includes('labour')).reduce(
                                      (a, b) =>
                                        a +
                                        (parseFloat(b.GST / 2) ||
                                          0),
                                      0
                                    )?.toFixed(2) || ''}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: 'end' }}>
                                    {allValues.inv_details.filter(x => x.product?.pcategory?.name.toLowerCase().includes('labour')).reduce(
                                      (a, b) =>
                                        a +
                                        (parseFloat(b.GST / 2) ||
                                          0),
                                      0
                                    )?.toFixed(2) || ''}
                                  </TableCell>
                                </>
                              )}
                              {allValues.in_type === 2 && (
                                <TableCell sx={{ textAlign: 'end' }}>
                                  {allValues.inv_details.filter(x => x.product?.pcategory?.name.toLowerCase().includes('labour')).reduce(
                                    (a, b) =>
                                      a +
                                      (parseFloat(b.GST) ||
                                        0),
                                    0
                                  )?.toFixed(2) || ''}
                                </TableCell>
                              )}
                            </>
                          )}

                          <TableCell
                            sx={{
                              textAlign: 'end',
                              position: 'sticky',
                              right: 0,
                              fontWeight: 'bold',
                              fontSize: '15px',
                            }}
                          >
                            {convertToRound(allValues.inv_details.filter(x => x.product?.pcategory?.name.toLowerCase().includes('labour')).reduce((acc, next) => acc + next.finalTotal, 0))?.toFixed(2)}
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Grid>

              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              md={12}
              sx={{
                bottom: 60,
                zIndex: 111111,
                p: 0,
                position: "fixed",
                justifyContent: 'space-between',
                bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
              }}
              spacing={2}
            >
              <Grid item Container xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Typography variant="subtitle2" sx={{ ml: '10px' }}>
                  Narration
                </Typography>
                <RHFTextField id="narration-field" name="narr" size="small" multiline rows={3} />
              </Grid>
              <Grid Grid item Container xs={0} sm={0} md={0} lg={1} xl={2} xxl={2} />
              <Grid item Container xs={12} sm={12} md={4} lg={2} xl={2} xxl={2}>
                <Typography variant="subtitle2" sx={{ margin: 'auto', textAlign: 'center' }}>
                  Discount %
                </Typography>
                <Container sx={{
                  my: 0, maxHeight: '100px',
                  overflow: 'auto',
                }}>
                  <table>

                    {
                      productCategoryList.map((x, index) => (
                        <tr>
                          <td align="left">{x.name}</td>
                          <td>
                            <RHFTextField
                              variant="standard"
                              max="100"
                              size='small'
                              name={`inv_discounts.${index}.rate`}
                              inputProps={{
                                max: 100,
                                min: 0,
                                style: { textAlign: 'right' },
                              }}
                              onChange={(e) => {
                                minmax(e, 0, 100);
                                setValue(`inv_discounts[${index}].rate`, parseFloat(e.target.value) || 0)
                              }}
                              value={allValues.inv_discounts[index]?.rate || 0}
                              type="number"
                            />
                            <RHFTextField
                              variant="standard"
                              max="100"
                              size='small'
                              name={`inv_discounts.${index}.pcategoryId`}
                              value={x.pcategoryId}
                              inputProps={{
                                max: 100,
                                min: 0,
                                style: { textAlign: 'right' },
                              }}
                              style={{ display: 'none' }}
                              type="number"
                            />

                          </td>
                        </tr>
                      ))
                    }
                  </table>
                </Container>
              </Grid>
              <Grid Grid item Container xs={0} sm={0} md={0} lg={1} xl={2} xxl={2} />
              <Grid
                item
                Container
                xs={12}
                sm={12}
                md={4}
                lg={3}
                xl={2}
                xxl={2}
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-end"
              >

                <Box
                  sx={{
                    maxHeight: '100px',
                    padding: '5px',
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '8px', // Adjust width of the scrollbar here
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: '#f1f1f1', // Color of the track
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#888', // Color of the scrollbar itself
                      borderRadius: '10px', // Round the edges of the scrollbar
                      border: '2px solid transparent', // Optional: adds border around the scrollbar
                      backgroundClip: 'content-box', // Ensures the border doesn't overlap the background
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      backgroundColor: '#555', // Color of the scrollbar when hovered
                    },
                  }}
                >
                  {entry_details?.fields
                    ?.filter((x) => x.order > 2 && !x?.accountlabel?.includes('GST'))
                    .map((field, index) => (
                      <Stack direction="row" justifyContent="space-between" key={index}>
                        <Typography variant="subtitle1">
                          {field.accountlabel || field.account?.name}
                        </Typography>
                        <Typography variant="subtitle1">
                          {field?.readOnly ? (
                            field?.amount
                          ) : (
                            <RHFTextField
                              key={index}
                              inputProps={{
                                style: { textAlign: 'right' },
                              }}
                              name={`entry_details.${allValues.in_type === 1 ? index + 4 : index + 3
                                }.amount`}
                              onChange={(e) => {
                                entry_details.update(
                                  allValues.in_type === 1 ? index + 4 : index + 3,
                                  {
                                    ...allValues.entry_details[
                                    allValues.in_type === 1 ? index + 4 : index + 3
                                    ],
                                    amount: parseFloat(e.target.value),
                                  }
                                );
                              }}
                              value={
                                allValues.entry_details[
                                  allValues.in_type === 1 ? index + 4 : index + 3
                                ].amount
                              }
                              size="small"
                              type="number"
                              variant="standard"
                            />
                          )}
                        </Typography>
                      </Stack>
                    ))}
                </Box>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ backgroundColor: '#F3F6F8', padding: '5px' }}
                >
                  <Typography variant="subtitle1">Bill Amount</Typography>
                  <Box sx={{ textAlign: 'right' }}>
                    <Typography variant="subtitle1" sx={{ color: 'error.main' }}>
                      <strong>{allValues.billamount}</strong>
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            </Grid>

            <Stack
              direction="row-reverse"
              alignItems="center"
              sx={{
                bottom: 0,
                zIndex: 111111,
                p: 2,
                position: 'fixed',
                justifyContent: 'space-between',
                width: '100%',
                bgcolor: (theme) => alpha(theme.palette.background.default, 1),
              }}
              id="modelfooter"
            >
              <Tooltip title="(Alt + A)" arrow>
                <LoadingButton
                  type="button"
                  variant="contained"
                  loading={isSubmitting}
                  onClick={() => {
                    onSubmit(allValues);
                  }}
                >
                  {allValues.iscreate ? 'Add' : 'Update'}
                </LoadingButton>
              </Tooltip>

              <Tooltip title="(Esc)" arrow>
                <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
                  Cancel
                </Button>
              </Tooltip>

              <Button
                variant="soft"
                onClick={() => {
                  reset(values);
                  document.getElementById('invoice_type').focus();
                }}
                sx={{ mr: 2 }}
              >
                Reset
              </Button>

              <Stack direction="row" alignItems="center" flexGrow={1} spacing={2} />
            </Stack>
          </FormProvider>
        )}
      </Dialog>

      {quickEditProduct.value && (
        <PUserQuickEditForm
          row={productEditValues || null}
          open={quickEditProduct.value}
          onClose={handleCloseProduct}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
      {quickEditGSTCommdity.value && (
        <GSTCommodityQuickEditForm
          row={GSTCommodity || null}
          open={quickEditGSTCommdity.value}
          onClose={handleCloseProduct}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
    </>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
  invoiceSettings: PropTypes.object,
  quickjobcardId: PropTypes.number,
};  
