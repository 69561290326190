/**
 * Handles scroll events for autocomplete components, fetching more data as needed.
 *
 * @param {Event} event - The scroll event object.
 * @param {Array} countries - The current list of options in the autocomplete.
 * @param {number} page - The current page number.
 * @param {Function} setPage - Function to update the page number.
 * @param {Function} setOptions - Function to update the autocomplete options.
 * @param {Function} fetchData - Function to fetch new data based on the page number.
 */
export const handleScroll = async ({ event, setValue, fieldName, meta, swr }) => {
  const { target } = event;
  if (event && setValue && fieldName) {
    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 10) {
      // Check if we're near the bottom of the element
      // Prevent duplicate calls or update as necessary for your use case
      const listboxNode = event.currentTarget;
      const position = listboxNode.scrollTop + listboxNode.clientHeight;
      if (listboxNode.scrollHeight - position <= 2) {
        if (swr && 'setSize' in swr) {
          swr.setSize(swr.size + 1);
        } else if (meta.page < meta.totalPage) {
          const newPage = meta.page + 1;
          setValue(fieldName, { ...meta, page: newPage });
        }
      }
    }
  }
};
