import { Helmet } from 'react-helmet-async';
import { COMPANY_NAME } from 'src/_mock/constant';
import CategoryList from 'src/sections/hondacategory/CategoryList';

// ----------------------------------------------------------------------
export default function HondaCategory() {
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} : Honda Category List</title>
      </Helmet>

      <CategoryList />
    </>
  );
}
