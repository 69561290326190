/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import { useDebounce } from 'src/hooks/use-debounce';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { create, destroy, fetcher, update } from 'src/utils/axios';
import {
  ACCOUNT_TYPES,
  CASH_DEBIT,
  KEY,
  paymentStatus,
  RECEIPTTYPE,
  VOUCHERTYPE,
} from 'src/_mock/constant';
import {
  Autocomplete,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { alpha } from '@mui/system';
import { DateField, DatePicker } from '@mui/x-date-pickers';
import { useEventListener } from 'src/hooks/use-event-listener';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { includes, isNaN } from 'lodash';
import AUserQuickEditForm from 'src/sections/accounts/account/quick-edit-form';
import {
  NumericFormatCustom,
  fMoney,
  formatDate,
  useBgColorAutocomplete,
} from 'src/_mock/constant_funcation';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CompanyYear from 'src/layouts/_common/company-year';
import NotFound from 'src/sections/error/notfound-invoice';
import moment from 'moment';
import { AuthContext } from 'src/auth/context/jwt';
import { CashManagementModal } from 'src/hooks/use-cashcounter';
import SuccessConfirmDialog from 'src/components/custom-dialog/confirm-dialog';

const va = {
  1: {
    cb: 1,
    t_type: 1,
    name: 'Bank',
    label: 'Bank',
    rp: 'Payment',
    ac_groupId: '22',
    ac_groupId2: '46,47',
  },
  2: {
    cb: 2,
    t_type: 1,
    name: 'Bank',
    label: 'Bank',
    rp: 'receipt',
    ac_groupId: '133',
    startwidth: '98',
    ac_groupId2: '46,47',
  },
  3: {
    cb: 1,
    t_type: 2,
    name: 'Cash',
    label: 'Cash',
    rp: 'Payment',
    ac_groupId: '26',
    ac_groupId2: '46,47',
  },
  4: {
    cb: 2,
    t_type: 2,
    name: 'Cash',
    label: 'Cash',
    rp: 'receipt',
    ac_groupId: '26,138,133',
    ac_groupId2: '46,47',
  },
  5: {
    cb: 1,
    t_type: 1,
    name: 'Contra',
    label: 'Contra',
    rp: 'Payment',
    ac_groupId: '22,26',
    ac_groupId2: '26,22',
  },
  6: {
    cb: 2,
    t_type: 1,
    name: 'Contra',
    label: 'Contra',
    rp: 'receipt',
    ac_groupId: '22,26',
    ac_groupId2: '26,22',
  },
};

const initialInvoiceState = {
  1: '8,10', // Bank Payment, 8 =  purchase and 10 = purchase return
  2: '9,11', // Bank Receipt, 9 = sales and 11 = sales return
  3: '8,10', // Bank Payment, 8 =  purchase and 10 = purchase return
  4: '9,11', // Bank Receipt, 9 = sales and 11 = sales return
};

export default function VoucherUserQuickEditForm({ row, open, onClose, table, getAll, tableData, setPDFViwer }) {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(AuthContext);

  const userInitiatedFocusRef = useRef(true);

  const invoice_State = useMemo(() => initialInvoiceState, []);
  let totalBillAmount = 0;
  let totalPaidAmount = 0;
  let totalDueAmount = 0;
  const bgColorAutocomplete = useBgColorAutocomplete();

  const printModel = useBoolean();

  const [vId, setvId] = useState(null)
  const [entry, setEntry] = useState(null);
  const [paymentOptionList, setPaymentOptionList] = useState([]);
  const [banksOptionList, setBanksOptionList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [invoices, setInvocies] = useState([]);
  const [invociesBooking, setInvociesBooking] = useState(entry?.previousReceipts || []);
  const [bookingData, setBookingData] = useState(entry?.booking || null);
  const [accountEditList, setAccountEditList] = useState({});
  const [accountOppEditList, setAccountOppEditList] = useState({});
  const [loading, setLoading] = useState(false);
  const [firstAccount, setFirstAccount] = useState('');
  const [fieldName, setFieldName] = useState('');
  const [secondAccount, setSecondAccount] = useState('');
  const [invoiceACId, setInvoiceACId] = useState(false);
  const [loader, setLoader] = useState(false);
  const [paymentStatusData, setPaymentStatusData] = useState('UnPaid');
  const [invoiceList, setInvoiceList] = useState([]);
  const [addAmounts, setAddAmounts] = useState({});
  const [enterPressCount, setEnterPressCount] = useState(0);
  const [enterPressCountInvoice, setEnterPressCountInvoice] = useState(0);


  const [modalOpen, setModalOpen] = useState(false);

  const otherReceiptAmount = invociesBooking?.map((x) => x.amount)?.reduce((a, b) => a + b, 0);

  const BankCashPaymentSchema = Yup.object().shape({
    cb: Yup.number().integer().nullable(),
    t_type: Yup.number().integer().required(),
    amount: Yup.number().min(1).required('Amount is required'),
    narr: Yup.string().nullable(),
    entry_details: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().integer().nullable(),
        cb: Yup.number().integer().required(),
        accountId: Yup.number().integer().required(),
        bentryId: Yup.number().integer().nullable(),
        entry_detailId: Yup.number().integer().nullable(),
        bpoptionId: Yup.number()
          .integer()
          .nullable()
          .transform((value, originalValue) => (originalValue === 'null' ? null : originalValue)),
        bankId: Yup.number()
          .integer()
          .nullable()
          .transform((value, originalValue) => (originalValue === 'null' ? null : originalValue)),
        ac_no: Yup.string().nullable(),
        amount: Yup.number().required(),
      })
    ),
  });
  const list = {
    'entry_details[0].accountId': setBankList,
    'entry_details[1].accountId': setAccountList,
  };

  const values = useMemo(
    () => ({
      t_type: entry?.t_type || null,
      v_no: entry?.v_no || '',
      cb: entry?.cb || 1,
      amount: entry?.amount || 0,
      dt: entry?.dt || new Date(),
      t_date: entry?.t_date || new Date(),
      narr: entry?.narr || '',
      isHighligh: '',
      entry_details: (entry?.entry_details &&
        Array.isArray(entry?.entry_details) &&
        entry?.entry_details
          ?.sort((a, b) => a.order - b.order)
          ?.map((x, index) => ({
            ...(x ? { id: x?.id } : {}),
            cb: x?.cb || 1,
            accountId: x?.accountId || null,
            entryId: x?.entryId || null,
            bentryId: x?.bentryId || null,
            entry_detailId: x?.entry_detailId || null,
            bpoptionId: x?.bpoptionId || null,
            bankId: x?.bankId || null,
            ac_no: x?.ac_no || '',
            amount: x?.amount || 0,
            order: x?.order || index + 1,
            accountMeta: {
              page: 1,
              name: '',
              totalPage: 1,
              editRow: null,
            },
          }))) || [
          {
            cb: 2,
            accountId: null,
            entryId: null,
            bentryId: null,
            entry_detailId: null,
            bpoptionId: null,
            bankId: null,
            ac_no: '',
            amount: 0,
            order: 1,
            accountMeta: {
              page: 1,
              name: '',
              totalPage: 1,
              editRow: null,
            },
          },
          {
            cb: 1,
            accountId: null,
            entryId: null,
            bentryId: null,
            entry_detailId: null,
            bpoptionId: null,
            bankId: null,
            ac_no: '',
            amount: 0,
            order: 2,
            accountMeta: {
              page: 1,
              name: '',
              totalPage: 1,
              editRow: null,
            },
          },
        ],
      cashcounters: [
        { cash: 500, Payment: null, Refund: null },
        { cash: 200, Payment: null, Refund: null },
        { cash: 100, Payment: null, Refund: null },
        { cash: 50, Payment: null, Refund: null },
        { cash: 20, Payment: null, Refund: null },
        { cash: 10, Payment: null, Refund: null },
        { cash: 5, Payment: null, Refund: null },
        { cash: 2, Payment: null, Refund: null },
        { cash: 1, Payment: null, Refund: null },
      ].map((x) => {
        const data = entry?.cashcounters?.find((y) => y.cash === x.cash);
        return {
          cash: x.cash,
          Payment: data?.Payment || 0,
          Refund: data?.Refund || 0,
        };
      }),
    }),
    [entry]
  );

  const methods = useForm({
    resolver: yupResolver(BankCashPaymentSchema),
    values,
  });

  const {
    reset,
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    formState: { isSubmitting, errors },
  } = methods;

  const allValues = watch();
  const debouncedQuery = useDebounce(allValues.entry_details[0].accountMeta?.name);
  const debouncedQuerysecond = useDebounce(allValues.entry_details[1].accountMeta?.name);
  const quickEditAccount = useBoolean();
  const confirm = useBoolean();

  useEffect(() => {
    if ([1, 3, 5].includes(allValues.t_type)) {
      setValue('cb', 2);
      setValue('entry_details[0].cb', 2);
      allValues.entry_details.forEach((x, index) => {
        if (index > 0) {
          setValue(`entry_details[${index}].cb`, 1);
        }
      });
    }
    if ([2, 4, 6].includes(allValues.t_type)) {
      setValue('cb', 1);
      setValue('entry_details[0].cb', 1);
      allValues.entry_details.forEach((x, index) => {
        if (index > 0) {
          setValue(`entry_details[${index}].cb`, 2);
        }
      });
    }
  }, [allValues.entry_details, allValues.t_type, setValue]);

  useEffect(() => {
    if (allValues.amount) {
      setValue('entry_details[0].amount', allValues.amount);
      setValue('entry_details[1].amount', allValues.amount);
    }
  }, [allValues.amount, setValue]);

  const bal_met =
    allValues?.entry_details?.find((x) => x.order === 2)?.accountId &&
      accountList.length &&
      (accountList
        ?.find((y) => y.id === allValues?.entry_details?.find((x) => x?.order === 2).accountId)
        ?.ac_group?.name?.toLowerCase()
        ?.includes('sundry') ||
        accountList
          ?.find((y) => y.id === allValues?.entry_details?.find((x) => x?.order === 2).accountId)
          ?.ac_group?.name?.toLowerCase()
          ?.includes('booking'))
      ? 2
      : 1;

  const onSubmit = handleSubmit(async (payload) => {
    payload.cashcounters = payload.cashcounters.filter(
      (item) => item.Payment !== 0 || item.Refund !== 0
    );

    const amount = invoiceList.reduce((acc, cur) => acc + parseFloat(cur.amount), 0);
    const pAmounts = allValues.amount - amount;
    payload.entry_details = [...payload.entry_details, ...invoiceList];

    payload.entry_details = payload.entry_details.filter((x) => x.amount);
    try {
      let URL = 'automobile/entries/';
      setLoading(true);
      const resetValues = { ...values };
      resetValues.t_type = payload.t_type;
      if (payload?.entry_details?.length) {
        payload.entry_details[0].amount = payload.amount;
        payload.entry_details[1].amount = pAmounts;
      }
      if (entry?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          if (show) {
            enqueueSnackbar('Update success!');
            reset();
            setvId(row.id)
          }
          setInvoiceList([]);
        }
      } else {
        const { success, data, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          if (show) {
            enqueueSnackbar('Create success!');
            reset(resetValues);
            setvId(data?.id)
          }
          setInvoiceList([]);
          document.getElementById('rept_pymt').focus();
        }
      }
      printModel.onTrue();
      callAccountFuncation();
      setLoading(false);
      setAccountEditList(null);
      setAccountOppEditList(null);
      setInvociesBooking([]);
      setBookingData(null);
    } catch (error) {
      setLoading(false);
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  const handleUpdateCashCounter = (updatedCashCounter) => {
    setValue('cashcounters', updatedCashCounter);
  };

  const handleFocus = () => {
    if (userInitiatedFocusRef.current) {
      setModalOpen(true);
    }

    // Reset ref after checking
    userInitiatedFocusRef.current = false;
  };

  const aID = allValues?.entry_details[1]?.accountId;

  const getInvoice = useCallback(async () => {
    setLoader(true);
    try {
      let URL = 'automobile/entries/';

      URL += `?page=${1}&limit=${100000}&t_type=${invoice_State[allValues?.t_type]
        }&orderId=1&accountId=${aID}&paymentStatus=${paymentStatusData}&asc=true&`;

      const { data } = await fetcher(URL);
      if (data?.booking) {
        setBookingData(data?.booking);
        setInvociesBooking(data?.previousReceipts);
        setInvocies([]);
      } else {
        setInvocies(data?.rows);
        setInvociesBooking([]);
      }
      setInvoiceACId(false);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setInvoiceACId(false);
      setLoader(false);
    }
  }, [aID, allValues?.t_type, invoice_State, paymentStatusData]);

  useEffect(() => {
    if ((invoiceACId || paymentStatusData) && aID) getInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aID]);

  useEffect(() => {
    if (paymentStatusData && aID) getInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatusData]);

  useEffect(() => {
    reset();
  }, [open, reset]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if (!quickEditAccount.value && (await trigger()) && !loading && open) {
        onSubmit(allValues);
      }
    } else if (event.key === KEY.INSERT) {
      event.stopPropagation();
      setAccountEditList(null);
      setAccountOppEditList(null);
      quickEditAccount.onTrue();
    } else if (event.altKey && /^[1-6]$/.test(event.key)) {
      const rcptKey = parseInt(event.key, 10) || 1;
      setValue('t_type', rcptKey);
    }
  };
  useEventListener('keydown', handleKeyDown);

  const HandleBanksList = async () => {
    let URL = 'banks/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setBanksOptionList(data?.rows);
  };

  const HandlePaymentOptionList = async () => {
    let URL = 'bpoptions/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setPaymentOptionList(data?.rows);
  };

  const BankAccountList = useCallback(async () => {
    let URL = 'automobile/accounts/';
    URL += `?page=${1}&limit=${20}&status=true&ac_groupId=${va[allValues.t_type]?.ac_groupId
      }&name=${debouncedQuery}`;
    const { data } = await fetcher(URL);
    setBankList(data?.rows?.filter(x => !x.identity.toString().startsWith("99")));
  }, [allValues.t_type, debouncedQuery]);

  const HandleAccountList = useCallback(async () => {
    let URL = 'automobile/accounts/';
    URL += `?page=${1}&limit=${10}&creditcard=false&status=true&ac_groupId=${va[allValues.t_type]?.ac_groupId2
      }&name=${debouncedQuerysecond}`;
    const { data } = await fetcher(URL);
    if (data?.rows)
      setAccountList(pre => [...pre, ...data.rows]);
  }, [allValues.t_type, debouncedQuerysecond]);
  const lastentry = useCallback(async () => {
    const URL = `automobile/entries/last/?t_type=${allValues.t_type}`;
    const { data } = await fetcher(URL);
    if (!entry?.id && data?.v_no)
      setValue('v_no', data?.v_no);
  }, [allValues.t_type, entry?.id, setValue]);

  useEffect(() => {
    if (allValues.t_type)
      lastentry();
  }, [allValues.t_type, lastentry])
  const HandleGroupList = async () => {
    let URL = 'automobile/ac_groups/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setGroupList(data?.rows);
  };

  useEffect(() => {
    const initialAddAmounts = {};
    invoices.forEach((invoice) => {
      initialAddAmounts[invoice.v_no] = invoice.addAmount || 0;
    });
    setAddAmounts(initialAddAmounts);
  }, [invoices]);

  const handleInvoiceDetails = (value, index) => {
    const { id, addAmount } = value;
    if (id > 0) {
      if (Array.isArray(invoiceList)) {
        invoiceList[index] = {
          ...allValues.entry_details[1],
          amount: addAmount ? parseFloat(addAmount).toFixed(2) : 0,
          order: index + 3,
          invoiceId: id,
        };
      }
      console.log(invoiceList, 'invoiceList');
      setInvoiceList([...invoiceList]);
    }
  };
  const GETBYID = async (id) => {
    try {
      let URL = `automobile/entries/${id}`;
      URL += `?status=true&`;
      const { data } = await fetcher(URL);
      console.log(id)
      if (data) {
        const addoppsiteaccount = data.entry_details.find(x => x.order === 2)?.account;
        const addpartyaccount = data.entry_details.find(x => x.order === 1)?.account;
        if (addpartyaccount) {
          setBankList(pre => [...pre, addpartyaccount])
        }
        if (addoppsiteaccount) {
          setAccountList(pre => [...pre, addoppsiteaccount])
        }

        setEntry(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GETBYID(row?.id);
  }, [row])

  const callAccountFuncation = () => {
    HandleBanksList();
    HandlePaymentOptionList();
    HandleAccountList();
    BankAccountList();
    HandleGroupList();
  };

  useEffect(() => {
    if (open) {
      HandleBanksList();
      HandlePaymentOptionList();
      HandleGroupList();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      HandleAccountList();
      BankAccountList();
    }
  }, [BankAccountList, HandleAccountList, open]);

  const handleCloseAccount = () => {
    quickEditAccount.onFalse();
    HandleAccountList();
    BankAccountList();
  };

  const deleteAccountModel = useCallback(
    async (id) => {
      try {
        let URL = 'automobile/accounts/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          HandleAccountList();
          BankAccountList();
          confirm.onFalse();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [HandleAccountList, BankAccountList, confirm, enqueueSnackbar]
  );

  return (
    <>
      <Dialog fullScreen open={open} onKeyDown={handleKeyDown}>
        <FormProvider methods={methods}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? `Edit Voucher` : `Add Voucher`}
              </Typography>

              <Grid container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    options={VOUCHERTYPE?.filter((x) => x.value <= 6)?.map(
                      (option) => option.label
                    )}
                    onBlur={(e) => {
                      if (!allValues.t_type) {
                        document.getElementById(e.target.id).focus();
                      }
                    }}
                    autoFocus
                    openOnFocus
                    getOptionLabel={(option) => option}
                    value={
                      VOUCHERTYPE?.find((data) => data.value === allValues.t_type)?.label || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        't_type',
                        VOUCHERTYPE?.find((data) => data.label === newValue)?.value
                      );
                      setInvociesBooking([]);
                      setBookingData(null);
                    }}
                    id="rept_pymt"
                    renderInput={(params) => (
                      <TextField {...params} name="t_type" label="Rcpt/Pymt" autoFocus />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    disabled={entry?.id}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
              </Grid>

              <CompanyYear />

              <IconButton color="inherit" edge="start" tabIndex={-1} onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>
          {allValues.t_type && (
            <Grid container xs={12} md={12} lg={12} xl={12} xxl={12} sx={{ p: 3 }} spacing={4}>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                alignContent="start"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    size="small"
                    disableCloseOnSelect
                    options={bankList.length ? bankList : [{ name: 'Select Option', value: null }]}
                    getOptionDisabled={(option) => option?.value === null}
                    groupBy={(option) => option?.a}
                    getOptionLabel={(option) => option?.name}
                    onBlur={(e) => {
                      if (!allValues.entry_details[0].accountId && !quickEditAccount.value) {
                        document.getElementById(e.target.id).focus();
                      }
                    }}
                    filterOptions={(options, state) => {
                      if (bankList.length) {
                        const filtered = bankList.filter((x) =>
                          x.name.toLowerCase().includes(state.inputValue.toLowerCase())
                        );
                        return filtered.length
                          ? filtered
                          : [{ name: 'No matching options', value: null }];
                      }
                      return [{ name: 'No Options', value: null }];
                    }}
                    openOnFocus={!quickEditAccount.value}
                    onChange={(event, newValue) => {
                      setAccountEditList(newValue);
                      setValue('entry_details[0].accountId', newValue?.id);
                      setBookingData(null);
                    }}
                    inputValue={allValues.entry_details[0].accountMeta.name}
                    onInputChange={(event, newInputValue) => {
                      setValue('entry_details.0.accountMeta', {
                        ...allValues.entry_details[0].accountMeta,
                        page: 1,
                        name: newInputValue,
                      });
                    }}
                    value={(() => {
                      const bank = bankList?.find(
                        (data) => data?.id === allValues?.entry_details[0]?.accountId
                      );
                      // const editbank = entry?.entry_details[0]?.account;

                      if (bank) return bank;
                      // if (editbank) return editbank;
                      return null;
                    })()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${VOUCHERTYPE.find((x) => x.value === allValues.t_type)
                          ?.label.replace('Payment', '')
                          .replace('Receipt', '')} A/c`}
                        name="entry_details[0].accountId"
                        error={
                          errors?.entry_details?.length >= 0 &&
                          errors?.entry_details[0]?.accountId &&
                          !allValues?.entry_details[0]?.accountId
                        }
                      />
                    )}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader component="li">
                          <ListItem sx={{ padding: 0, margin: 0 }}>Account Name</ListItem>
                          <ListItem
                            sx={{ padding: 0, margin: 0, justifyContent: 'end', width: '30%' }}
                          >
                            Balance
                          </ListItem>
                        </GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                        <GroupFooter component="li" sx={{ display: 'block' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-around',
                              bgcolor: (theme) => alpha(theme.palette.primary.lighter, 1),
                            }}
                          >
                            <Tooltip title="(Insert)" arrow>
                              <Button
                                onClick={() => {
                                  setFieldName('entry_details[0].accountId');
                                  setAccountEditList(null);
                                  quickEditAccount.onTrue();
                                }}
                              >
                                Add
                              </Button>
                            </Tooltip>
                            {accountEditList?.id && (
                              <>
                                <Button
                                  onClick={() => {
                                    quickEditAccount.onTrue();
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  onClick={() => {
                                    confirm.onTrue();
                                  }}
                                >
                                  Delete
                                </Button>
                              </>
                            )}
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              color: 'black',
                              px: 2,
                              bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={6} md={6}>
                                {accountEditList?.address && (
                                  <>
                                    <Typography variant="body2">
                                      <strong>Address</strong>
                                    </Typography>
                                    <Typography variant="body2">
                                      {accountEditList?.address || '-'}
                                      {accountEditList?.district &&
                                        `${accountEditList?.district}, `}
                                      {accountEditList?.city?.name &&
                                        `${accountEditList?.city?.name}, `}
                                      {accountEditList?.state?.name &&
                                        `${accountEditList?.state?.name}, `}
                                      {accountEditList?.country?.name &&
                                        `${accountEditList?.country?.name}.`}
                                    </Typography>
                                  </>
                                )}
                                {accountEditList?.b_address && (
                                  <>
                                    <Typography variant="body2">
                                      <strong>Address</strong>
                                    </Typography>
                                    <Typography variant="body2">
                                      {accountEditList?.b_address || '-'}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                              <Grid item xs={6} md={6} sx={{ textAlign: 'left' }}>
                                {accountEditList?.mobile && (
                                  <Typography variant="body2">
                                    Mobile No. {accountEditList?.mobile || '-'}
                                  </Typography>
                                )}

                                {accountEditList?.gst_no && (
                                  <Typography variant="body2">
                                    GST No. {accountEditList?.gst_no || '-'}
                                  </Typography>
                                )}

                                {accountEditList?.pan_no && (
                                  <Typography variant="body2">
                                    PAN No. {accountEditList?.pan_no || '-'}
                                  </Typography>
                                )}

                                {accountEditList?.acc_no && (
                                  <Typography variant="body2">
                                    A/c No : {accountEditList?.acc_no || '-'}
                                  </Typography>
                                )}
                                {accountEditList?.branch_name && (
                                  <Typography variant="body2">
                                    Branch Name : {accountEditList?.branch_name || '-'}
                                  </Typography>
                                )}

                                {accountEditList?.ifsc_code && (
                                  <Typography variant="body2">
                                    IFSC Code : {accountEditList?.ifsc_code || '-'}
                                  </Typography>
                                )}
                                {accountEditList?.accounttypeId && (
                                  <Typography variant="body2">
                                    Type :{' '}
                                    {ACCOUNT_TYPES.find(
                                      (x) => x.id === accountEditList?.accounttypeId
                                    )?.name || '-'}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        </GroupFooter>
                      </li>
                    )}
                    onHighlightChange={(event, newValue) => {
                      setAccountOppEditList(null);
                      setAccountEditList(newValue);
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                          {option?.identity} -
                          {option?.name}
                        </ListItem>
                        <ListItem
                          sx={{
                            padding: 0,
                            margin: 0,
                            justifyContent: 'end',
                            width: '30%',
                          }}
                        >
                          {option?.closingBalance
                            ? fMoney.format(option?.closingBalance, { symbol: '₹ ' })
                            : '-'}
                        </ListItem>
                      </Box>
                    )}
                    componentsProps={{
                      paper: {
                        sx: {
                          width: 550,
                          '@media (min-width: 1200px)': {
                            // This corresponds to the 'lg' breakpoint in MUI's default theme
                            position: 'fixed',
                            width: '750px !important',
                            left: '500px !important',
                            top: '-37px !important',
                          },
                        },
                      },
                    }}
                    ListboxProps={{
                      ...bgColorAutocomplete,
                      style: {
                        maxHeight: '70vh',
                      },
                    }}
                  />

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    display="flex"
                    justifyContent="space-between"
                    mt={-2}
                  >
                    <h5>Balance</h5>
                    <h5>
                      {bankList?.find((data) => data.id === allValues.entry_details[0].accountId)
                        ?.closingBalance ? (
                        <>
                          {fMoney.format(
                            bankList?.find(
                              (data) => data.id === allValues.entry_details[0].accountId
                            )?.closingBalance,
                            { symbol: '₹ ' }
                          )}{' '}
                          DB
                        </>
                      ) : (
                        '-'
                      )}
                    </h5>
                  </Grid>
                </Grid>

                <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={3}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="v_no" label="Voucher No." size="small" type="number" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Controller
                      name="dt"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DateField
                          defaultValue={new Date(field.value)}
                          timezone="Asia/Kolkata"
                          label="Date"
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          format="dd/MM/yyyy"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!error,
                              helperText: error?.message,
                              size: 'small',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {[1, 2, 5, 6].includes(allValues.t_type) && (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight={allValues.isHighligh}
                        fullWidth
                        freeSolo
                        size="small"
                        openOnFocus
                        onInputChange={(event, newValue) => {
                          setValue('isHighligh', newValue);
                        }}
                        onBlur={() => {
                          setValue('isHighligh', '');
                        }}
                        options={paymentOptionList
                          ?.filter((y) => {
                            const selectedVouchers = y?.voucherId
                              ?.split('')
                              .map((x) => parseInt(x, 10));
                            return selectedVouchers?.includes(allValues.t_type);
                          })
                          .map((option) => option.name)}
                        getOptionLabel={(option) => option}
                        value={
                          paymentOptionList?.find(
                            (data) => data.id === allValues?.entry_details[0]?.bpoptionId
                          )?.name || ''
                        }
                        onChange={(event, newValue) => {
                          setValue(
                            'entry_details[0].bpoptionId',
                            paymentOptionList?.find((data) => data.name === newValue)?.id
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="entry_details[0].bpoptionId"
                            label="Payment Option"
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                  )}

                  {allValues?.t_type === 2 && (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight={allValues.isHighligh}
                        fullWidth
                        freeSolo
                        size="small"
                        options={banksOptionList?.map((option) => option.name)}
                        getOptionLabel={(option) => option}
                        onInputChange={(event, newValue) => {
                          setValue('isHighligh', newValue);
                        }}
                        onBlur={() => {
                          setValue('isHighligh', '');
                        }}
                        openOnFocus
                        value={
                          banksOptionList?.find(
                            (data) => data.id === allValues?.entry_details[0]?.bankId
                          )?.name || ''
                        }
                        onChange={(event, newValue) => {
                          setValue(
                            'entry_details[0].bankId',
                            banksOptionList?.find((data) => data.name === newValue)?.id
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="entry_details[0].bankId"
                            label="Select Bank"
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                  )}

                  {[1, 2, 5, 6].includes(allValues.t_type) && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFTextField
                        name="entry_details[0].ac_no"
                        label={`${paymentOptionList?.find(
                          (data) => data.id === allValues?.entry_details[0]?.bpoptionId
                        )?.name || ''
                          } No.`}
                        size="small"
                        type="text"
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} mt={3}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    size="small"
                    disableCloseOnSelect
                    options={
                      accountList.length
                        ? accountList.filter((x) => x.id !== allValues.entry_details[0].accountId)
                        : [{ name: 'No Option', value: null }]
                    }
                    groupBy={(option) => option?.a}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setAccountOppEditList(newValue);
                      setValue('entry_details[1].accountId', newValue?.id);
                      setInvoiceACId(true);
                      setBookingData(null);
                    }}
                    onBlur={(e) => {
                      if (!allValues.entry_details[1].accountId && !quickEditAccount.value) {
                        document.getElementById(e.target.id).focus();
                      }
                    }}
                    inputValue={allValues.entry_details[1].accountMeta.name}
                    onInputChange={(event, newInputValue) => {
                      setValue('entry_details.1.accountMeta', {
                        ...allValues.entry_details[1].accountMeta,
                        page: 1,
                        name: newInputValue,
                      });
                    }}
                    openOnFocus={!quickEditAccount.value}
                    filterOptions={(options, state) => {
                      if (accountList.length) {
                        console.log(accountList, "accountList", state.inputValue.toLowerCase());
                        const filtered = accountList.filter((x) =>
                          x.name.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                          x.identity?.toString().includes((state.inputValue))
                        );
                        return filtered.length
                          ? filtered
                          : [{ name: 'No matching options', value: null }];
                      }
                      return [{ name: 'No Options', value: null }];
                    }}
                    onHighlightChange={(event, newValue) => {
                      setAccountEditList(null);
                      setAccountOppEditList(newValue);
                    }}
                    value={(() => {
                      const bank = accountList?.find(
                        (data) => data?.id === allValues?.entry_details[1]?.accountId
                      );
                      // const editbank = entry?.entry_details[1]?.account;
                      // console.log(bank, editbank);
                      if (bank) return bank;
                      // if (editbank) return editbank;
                      return null;
                    })()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Opp. A/c"
                        name="entry_details[1].accountId"
                        error={
                          errors?.entry_details?.length >= 0 &&
                          errors?.entry_details[1]?.accountId &&
                          !allValues?.entry_details[1]?.accountId
                        }
                      />
                    )}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader component="li">
                          <ListItem sx={{ padding: 0, margin: 0 }}>Account Name</ListItem>
                          <ListItem
                            sx={{ padding: 0, margin: 0, justifyContent: 'end', width: '30%' }}
                          >
                            Balance
                          </ListItem>
                        </GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                        <GroupFooter component="li" sx={{ display: 'block' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-around',
                              bgcolor: (theme) => alpha(theme.palette.primary.lighter, 1),
                            }}
                          >
                            <Tooltip title="(Insert)" arrow>
                              <Button
                                onClick={() => {
                                  setFieldName('entry_details[1].accountId');
                                  setAccountOppEditList(null);
                                  quickEditAccount.onTrue();
                                }}
                              >
                                Add
                              </Button>
                            </Tooltip>
                            {accountOppEditList?.id && (
                              <>
                                <Button
                                  onClick={() => {
                                    quickEditAccount.onTrue();
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  onClick={() => {
                                    confirm.onTrue();
                                  }}
                                >
                                  Delete
                                </Button>
                              </>
                            )}
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              color: 'black',
                              px: 2,
                              bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={6} md={6}>
                                {console.log(accountOppEditList, 'accountOppEditList')}
                                {accountOppEditList?.address && (
                                  <>
                                    <Typography variant="body2">
                                      <strong>Address</strong>
                                    </Typography>
                                    <Typography variant="body2">
                                      {accountOppEditList?.address || '-'}
                                      {accountOppEditList?.district &&
                                        `${accountOppEditList?.district}, `}
                                      {accountOppEditList?.city?.name &&
                                        `${accountOppEditList?.city?.name}, `}
                                      {accountOppEditList?.state?.name &&
                                        `${accountOppEditList?.state?.name}, `}
                                      {accountOppEditList?.country?.name &&
                                        `${accountOppEditList?.country?.name}.`}
                                    </Typography>
                                  </>
                                )}
                                {accountOppEditList?.b_address && (
                                  <>
                                    <Typography variant="body2">
                                      <strong>Address</strong>
                                    </Typography>
                                    <Typography variant="body2">
                                      {accountOppEditList?.b_address || '-'}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                              <Grid item xs={6} md={6} sx={{ textAlign: 'left' }}>
                                {accountOppEditList?.mobile && (
                                  <Typography variant="body2">
                                    Mobile No. {accountOppEditList?.mobile || '-'}
                                  </Typography>
                                )}

                                {accountOppEditList?.gst_no && (
                                  <Typography variant="body2">
                                    GST No. {accountOppEditList?.gst_no || '-'}
                                  </Typography>
                                )}

                                {accountOppEditList?.pan_no && (
                                  <Typography variant="body2">
                                    PAN No. {accountOppEditList?.pan_no || '-'}
                                  </Typography>
                                )}

                                {accountOppEditList?.acc_no && (
                                  <Typography variant="body2">
                                    A/c No : {accountOppEditList?.acc_no || '-'}
                                  </Typography>
                                )}
                                {accountOppEditList?.branch_name && (
                                  <Typography variant="body2">
                                    Branch Name : {accountOppEditList?.branch_name || '-'}
                                  </Typography>
                                )}

                                {accountOppEditList?.ifsc_code && (
                                  <Typography variant="body2">
                                    IFSC Code : {accountOppEditList?.ifsc_code || '-'}
                                  </Typography>
                                )}
                                {accountOppEditList?.accounttypeId && (
                                  <Typography variant="body2">
                                    Type :{' '}
                                    {ACCOUNT_TYPES.find(
                                      (x) => x.id === accountOppEditList?.accounttypeId
                                    )?.name || '-'}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        </GroupFooter>
                      </li>
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                          {option?.identity} -
                          {option?.name}
                        </ListItem>
                        <ListItem
                          sx={{ padding: 0, margin: 0, justifyContent: 'end', width: '30%' }}
                        >
                          {option?.closingBalance
                            ? fMoney.format(option?.closingBalance, { symbol: '₹ ' })
                            : '-'}
                        </ListItem>
                      </Box>
                    )}
                    componentsProps={{
                      paper: {
                        sx: {
                          width: 550,
                          '@media (min-width: 1200px)': {
                            // This corresponds to the 'lg' breakpoint in MUI's default theme
                            position: 'fixed',
                            width: '750px !important',
                            left: '500px !important',
                            top: [3, 4].includes(allValues.t_type)
                              ? '-195px !important'
                              : '-305px !important',
                          },
                        },
                      },
                    }}
                    ListboxProps={{
                      ...bgColorAutocomplete,
                      style: {
                        maxHeight: '70vh',
                      },
                    }}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    display="flex"
                    justifyContent="space-between"
                    mt={-2}
                  >
                    <h5>Balance</h5>
                    <h5>
                      {accountList?.find(
                        (data) => data?.id === allValues?.entry_details[1]?.accountId
                      )?.closingBalance ? (
                        <>
                          {fMoney.format(
                            accountList?.find(
                              (data) => data?.id === allValues?.entry_details[1]?.accountId
                            )?.closingBalance,
                            { symbol: '₹ ' }
                          )}{' '}
                          DB
                        </>
                      ) : (
                        '-'
                      )}
                    </h5>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} />
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                    <Controller
                      name="amount"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Amount"
                          variant="outlined"
                          fullWidth
                          onFocus={[3, 4].includes(allValues?.t_type) && handleFocus}
                          onBlur={(e) => {
                            let mainAmount = allValues.amount;

                            if (!isNaN(mainAmount) && mainAmount >= 0) {
                              mainAmount = Number(mainAmount).toFixed(2);
                              setValue(`amount`, mainAmount);
                            }
                          }}
                          onMouseDown={() => {
                            userInitiatedFocusRef.current = true;
                          }}
                          onKeyDown={() => {
                            userInitiatedFocusRef.current = true;
                          }}
                          onChange={(e) => {
                            if (false || [1, 2, 5, 6].includes(allValues?.t_type)) {
                              setValue('amount', e.target.value);
                            }
                          }}
                          error={errors?.amount && allValues?.amount <= 0}
                          size="small"
                          type="number"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} mt={1}>
                  <Typography variant="subtitle2" sx={{ ml: '10px' }}>
                    Narration
                  </Typography>
                  <RHFTextField
                    name="narr"
                    size="small"
                    multiline
                    rows={3}
                    onBlur={(e) => {
                      setEnterPressCount((prevCount) => prevCount + 1);
                      if (enterPressCount === 1 && !invoices?.length) {
                        document.getElementById('add-voucher').focus();
                        setEnterPressCount(0);
                      }
                    }}
                  />
                </Grid>
              </Grid>

              {bookingData?.id ? (
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  alignContent="start"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} mt={1}>
                    <Typography
                      variant="body2"
                      sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                    >
                      Booking Details
                      {console.log(bookingData, 'bookingData')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Paper
                      variant="outlined"
                      sx={{
                        borderRadius: 2,
                        borderColor: 'divider',
                        borderStyle: 'dashed',
                        bgcolor: 'background.neutral',
                        padding: 1,
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Typography
                              variant="body2"
                              sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                            >
                              Product Model : {bookingData?.amodel?.name || '-'}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Typography
                              variant="body2"
                              sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                            >
                              Product Variant : {bookingData?.avariant?.name || '-'}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Typography
                              variant="body2"
                              sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                            >
                              Finances : {bookingData?.apurtype?.name || '-'}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Typography
                              variant="body2"
                              sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                            >
                              Branch : {bookingData?.financebranch?.name || '-'}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Typography
                              variant="body2"
                              sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                            >
                              GST IN :{bookingData?.gst_no}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Typography
                              variant="body2"
                              sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                            >
                              Institute : {bookingData?.institute?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} mt={1}>
                    <Typography
                      variant="body2"
                      sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                    >
                      Previous Receipts
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <TableContainer
                      component={Paper}
                      sx={{
                        overflow: 'auto',
                        maxHeight: '30vh',
                        minHeight: '30vh',
                        width: '100%',
                        margin: 'auto',
                      }}
                    >
                      <Table
                        stickyHeader
                        aria-label="simple table"
                        sx={{
                          '& .MuiTableCell-sizeMedium': {
                            padding: '0px !important',
                          },
                          '& fieldset': { borderRadius: '0px', border: 'none' },
                          '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                          '& .MuiOutlinedInput-root': { padding: '0px !important' },
                          '& .autoComplete>div': { padding: '0px !important' },
                          '& .MuiTableCell-root': {
                            border: '0.01px solid #e9ecee',
                          },
                          '& .MuiTableCell-head': {
                            padding: '5px !important',
                            borderRight: '1px solid #d0d1d2 !important',
                          },
                          '& .MuiTableCell-footer': {
                            padding: '5px !important',
                          },
                          '& .MuiTableCell-head:last-child': {
                            borderRight: '0px !important',
                          },
                          '&:last-child td': {
                            borderColor: '#e9ecee !important',
                          },
                        }}
                      >
                        <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
                          <TableRow>
                            <TableCell sx={{ width: 20, textAlign: 'center' }}>sr.</TableCell>
                            <TableCell sx={{ width: 40, textAlign: 'center' }}>Date</TableCell>
                            <TableCell sx={{ width: 40, textAlign: 'center' }}>
                              Voucher No.
                            </TableCell>
                            <TableCell sx={{ width: 140, textAlign: 'center' }}>A/c</TableCell>
                            <TableCell sx={{ width: 80, textAlign: 'center' }}>
                              Voucher Type
                            </TableCell>
                            <TableCell sx={{ width: 80, textAlign: 'center' }}>Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {invociesBooking?.map((item, index) => (
                            <TableRow key={item.id}>
                              <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>
                                {formatDate(item.dt)}
                              </TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>{item?.id}</TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>
                                {item.entry_details?.find((x) => x.cd === 1)?.account?.name || '-'}
                              </TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>
                                {VOUCHERTYPE.find((x) => x.value === item?.t_type)?.label || '-'}
                              </TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>
                                {fMoney.format(item?.amount, {
                                  symbol: '₹ ',
                                })}
                              </TableCell>
                            </TableRow>
                          ))}
                          {invociesBooking?.length === 0 && (
                            <TableCell sx={{ textAlign: 'center' }} colSpan="6">
                              No Data
                            </TableCell>
                          )}
                        </TableBody>
                        <TableFooter
                          sx={{
                            bottom: '0',
                            position: 'sticky',
                            zIndex: '11111',
                            bgcolor: 'background.paper',
                          }}
                        >
                          <TableRow>
                            <TableCell sx={{ textAlign: 'center' }} />
                            <TableCell sx={{ textAlign: 'center' }} colSpan="2">
                              <Typography
                                variant="body2"
                                sx={{ flex: 1, fontWeight: '600', textAlign: 'center' }}
                              >
                                Total:{' '}
                                {fMoney.format(bookingData?.total, {
                                  symbol: '₹ ',
                                })}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }} />
                            <TableCell sx={{ textAlign: 'center' }} />

                            <TableCell
                              sx={{
                                position: 'sticky',
                                right: 0,
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ flex: 1, fontWeight: '600', textAlign: 'end' }}
                              >
                                {fMoney.format(otherReceiptAmount || 0, {
                                  symbol: '₹ ',
                                })}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    mt={1}
                    sx={{ visibility: 'hidden' }}
                  >
                    <Paper
                      variant="outlined"
                      sx={{
                        borderRadius: 2,
                        borderColor: 'divider',
                        borderStyle: 'dashed',
                        bgcolor: 'background.neutral',
                        padding: 1,
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item container xs={12} sm={12} md={7} lg={7} xl={7} xxl={7}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                            <Typography
                              variant="body2"
                              sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                            >
                              Challan No. 000242
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                            <Typography
                              variant="body2"
                              sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                            >
                              Bill No. 000101
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                            <Typography
                              variant="body2"
                              sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                            >
                              Date : 01/01/2024
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                            <Typography
                              variant="body2"
                              sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                            >
                              Date : 01/01/2024
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Typography
                              variant="body2"
                              sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                            >
                              Chassis No. ME4JC85JCRD200913
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={12} md={5} lg={5} xl={5} xxl={5}>
                          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                            <Typography
                              variant="body2"
                              sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                            >
                              Product No.
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                            <Typography
                              variant="body2"
                              sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                            >
                              LIVO STD-BSVI
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                            <Typography
                              variant="body2"
                              sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                            >
                              Colour :
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                            <Typography
                              variant="body2"
                              sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                            >
                              BLACK
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                            <Typography
                              variant="body2"
                              sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                            >
                              Engine No.
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                            <Typography
                              variant="body2"
                              sx={{ flex: 1, fontWeight: '600', textAlign: 'left' }}
                            >
                              JC85ED3290155
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              ) : loader ? (
                <Grid container item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    sx={{ margin: 'auto', textAlign: 'center' }}
                  >
                    <CircularProgress />
                  </Grid>
                </Grid>
              ) : (
                bal_met === 2 && (
                  <>
                    {accountList?.find(
                      (data) => data?.id === allValues?.entry_details[1]?.accountId
                    )?.name ? (
                      <Grid item container xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 'bold',
                              marginLeft: '10px',
                              marginBottom: '20px',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            {`${accountList?.find(
                              (data) => data?.id === allValues?.entry_details[1]?.accountId
                            )?.name
                              }`}

                            <Autocomplete
                              autoHighlight
                              freeSolo
                              sx={{ width: 300 }}
                              size="small"
                              options={paymentStatus?.map((option) => option.name)}
                              getOptionLabel={(option) => option}
                              value={
                                paymentStatus.find((x) => x.id === paymentStatusData)?.name || ''
                              }
                              onChange={(event, newValue) => {
                                setPaymentStatusData(
                                  paymentStatus.find((x) => x.name === newValue)?.id || ''
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="payment_status"
                                  label="Payment Status"
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={option}>
                                  {option}
                                </li>
                              )}
                            />
                          </Typography>

                          {invoices?.length ? (
                            <TableContainer
                              component={Paper}
                              sx={{
                                overflow: 'auto',
                                maxHeight: '30vh',
                                width: '98%',
                                margin: 'auto',
                              }}
                            >
                              <Table
                                stickyHeader
                                aria-label="simple table"
                                sx={{
                                  '& .MuiTableCell-sizeMedium': {
                                    padding: '0px !important',
                                  },
                                  '& fieldset': { borderRadius: '0px', border: 'none' },
                                  '& .MuiInputBase-input': {
                                    padding: '0px !important',
                                    border: 'none',
                                  },
                                  '& .MuiOutlinedInput-root': { padding: '0px !important' },
                                  '& .autoComplete>div': { padding: '0px !important' },
                                  '& .MuiTableCell-root': {
                                    border: '0.01px solid #e9ecee',
                                  },
                                  '& .MuiTableCell-head': {
                                    padding: '5px !important',
                                    borderRight: '1px solid #d0d1d2 !important',
                                  },
                                  '& .MuiTableCell-footer': {
                                    padding: '5px !important',
                                  },
                                  '& .MuiTableCell-head:last-child': {
                                    borderRight: '0px !important',
                                  },
                                  '&:last-child td': {
                                    borderColor: '#e9ecee !important',
                                  },
                                }}
                              >
                                <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
                                  <TableRow>
                                    <TableCell sx={{ textAlign: 'center' }}>Date</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>Invoice No.</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>Days</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>Bill Amount</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>Paid Amount</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>Due Amount</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>Amount</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {invoices?.map((row, index) => {
                                    const dueAmount = row.amount - row.paidAmount;
                                    const tAmount = row.amount;
                                    totalBillAmount += tAmount;
                                    totalPaidAmount += row.paidAmount;
                                    totalDueAmount = totalBillAmount - totalPaidAmount;

                                    invoices.reduce((acc, cur) => {
                                      if (cur.v_no === row.v_no) {
                                        acc += cur.amount;
                                      }
                                      return acc;
                                    }, 0);

                                    const currentDate = moment();
                                    const startMoment = moment(entry?.dt);
                                    const totalDays = currentDate.diff(startMoment, 'days');

                                    return (
                                      <TableRow>
                                        <TableCell
                                          key={index}
                                          sx={{
                                            textAlign: 'center',
                                            position: 'sticky',
                                            left: 0,
                                          }}
                                        >
                                          {formatDate(entry?.dt)}
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            textAlign: 'center',
                                            position: 'sticky',
                                            left: 0,
                                          }}
                                        >
                                          {entry?.v_no}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: 'center',
                                            position: 'sticky',
                                            left: 0,
                                          }}
                                        >
                                          {totalDays}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: 'end',
                                            position: 'sticky',
                                            left: 0,
                                          }}
                                        >
                                          {parseFloat(entry?.amount)?.toFixed(2)}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: 'end',
                                            position: 'sticky',
                                            left: 0,
                                          }}
                                        >
                                          {parseFloat(entry?.paidAmount)?.toFixed(2)}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: 'end',
                                            position: 'sticky',
                                            left: 0,
                                          }}
                                        >
                                          {parseFloat(dueAmount)?.toFixed(2)}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: 'end',
                                            position: 'sticky',
                                            left: 0,
                                          }}
                                        >
                                          <TextField
                                            name={`invoiceList[${index}].amount`}
                                            type="number"
                                            size="small"
                                            value={addAmounts[row.v_no] || ''}
                                            InputProps={{
                                              min: 0,
                                              inputProps: {
                                                style: { textAlign: 'right' },
                                              },
                                            }}
                                            disabled={dueAmount === 0}
                                            onBlur={(e) => {
                                              setEnterPressCountInvoice(
                                                (prevCount) => prevCount + 1
                                              );

                                              let mainAmount = invoiceList[index]?.amount;
                                              if (!isNaN(mainAmount) && mainAmount >= 0) {
                                                mainAmount = Number(mainAmount).toFixed(2);

                                                setAddAmounts({
                                                  ...addAmounts,
                                                  [row.v_no]: mainAmount,
                                                });
                                                handleInvoiceDetails(
                                                  {
                                                    ...row,
                                                    addAmount: mainAmount,
                                                  },
                                                  index
                                                );
                                              }

                                              const tot = parseFloat(
                                                invoiceList.reduce((pre, next) => {
                                                  console.log(next?.amount);
                                                  return (
                                                    pre +
                                                    (next?.amount && parseFloat(next?.amount)
                                                      ? parseFloat(next?.amount)
                                                      : 0)
                                                  );
                                                }, 0)
                                              )?.toFixed(2);

                                              const tot1 = parseFloat(
                                                allValues?.entry_details[0]?.amount
                                              );

                                              console.log(tot, tot1);
                                              if (
                                                invoices.length - 1 === index ||
                                                tot === tot1.toFixed(2)
                                              ) {
                                                document.getElementById('add-voucher').focus();
                                                setEnterPressCountInvoice(0);
                                              } else if (!invoiceList[index]?.amount) {
                                                document.getElementById(e.target.id).focus();
                                              }
                                            }}
                                            onFocus={(e) => {
                                              if (!addAmounts[row.v_no]) {
                                                // Only auto-fill if not already set
                                                const totalAllocated = Object.values(
                                                  addAmounts
                                                ).reduce(
                                                  (total, value) => total + parseFloat(value || 0),
                                                  0
                                                );
                                                const remainingAllocation =
                                                  parseFloat(allValues.amount) - totalAllocated;
                                                const allocatableAmount = Math.min(
                                                  dueAmount,
                                                  remainingAllocation
                                                );

                                                if (
                                                  allocatableAmount > 0 &&
                                                  remainingAllocation > 0
                                                ) {
                                                  setAddAmounts({
                                                    ...addAmounts,
                                                    [row.v_no]: allocatableAmount,
                                                  });
                                                  handleInvoiceDetails(
                                                    {
                                                      ...row,
                                                      addAmount: allocatableAmount,
                                                    },
                                                    index
                                                  );
                                                }
                                              }
                                            }}
                                            onChange={(e) => {
                                              let newValue =
                                                e.target.value === ''
                                                  ? ''
                                                  : parseFloat(e.target.value);
                                              if (newValue !== '') {
                                                newValue = newValue.toFixed(2);
                                                newValue = parseFloat(newValue);
                                              }
                                              const totalAllocatedToOthers = Object.keys(
                                                addAmounts
                                              ).reduce((total, v_no) => {
                                                if (v_no !== row.v_no) {
                                                  total += parseFloat(addAmounts[v_no] || 0);
                                                }
                                                return total;
                                              }, 0);

                                              const maxAllocatableBasedOnVoucher =
                                                parseFloat(allValues.amount) -
                                                totalAllocatedToOthers;

                                              const maxAllocatableBasedOnDueAmount = dueAmount;

                                              const actualMaxAllocatable = Math.min(
                                                maxAllocatableBasedOnVoucher,
                                                maxAllocatableBasedOnDueAmount
                                              );

                                              if (newValue > actualMaxAllocatable) {
                                                setAddAmounts({
                                                  ...addAmounts,
                                                  [row.v_no]: actualMaxAllocatable,
                                                });
                                                handleInvoiceDetails(
                                                  {
                                                    ...row,
                                                    addAmount: actualMaxAllocatable,
                                                  },
                                                  index
                                                );
                                              } else {
                                                setAddAmounts({
                                                  ...addAmounts,
                                                  [row.v_no]: newValue,
                                                });
                                                handleInvoiceDetails(
                                                  {
                                                    ...row,
                                                    addAmount: newValue,
                                                  },
                                                  index
                                                );
                                              }
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                                <TableFooter
                                  sx={{
                                    bottom: '0',
                                    position: 'sticky',
                                    zIndex: '11111',
                                    bgcolor: (theme) => alpha(theme.palette.background.default, 1),
                                  }}
                                >
                                  <TableRow>
                                    <TableCell
                                      sx={{
                                        fontWeight: 'bold',
                                        fontSize: '15px',
                                        textAlign: 'center',
                                      }}
                                      colSpan="3"
                                    >
                                      Total
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontWeight: 'bold',
                                        fontSize: '15px',
                                        textAlign: 'end',
                                      }}
                                    >
                                      {parseFloat(totalBillAmount)?.toFixed(2)}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontWeight: 'bold',
                                        fontSize: '15px',
                                        textAlign: 'end',
                                      }}
                                    >
                                      {parseFloat(totalPaidAmount)?.toFixed(2)}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontWeight: 'bold',
                                        fontSize: '15px',
                                        textAlign: 'end',
                                      }}
                                    >
                                      {parseFloat(totalDueAmount)?.toFixed(2)}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontWeight: 'bold',
                                        fontSize: '15px',
                                        textAlign: 'end',
                                      }}
                                    >
                                      {parseFloat(
                                        invoiceList.reduce((pre, next) => {
                                          console.log(next?.amount);
                                          return (
                                            pre +
                                            (next?.amount && parseFloat(next?.amount)
                                              ? parseFloat(next?.amount)
                                              : 0)
                                          );
                                        }, 0)
                                      )?.toFixed(2)}
                                    </TableCell>
                                  </TableRow>
                                </TableFooter>
                              </Table>
                            </TableContainer>
                          ) : (
                            bal_met && <NotFound name="Invoice" />
                          )}
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item container xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                          {bal_met && <NotFound name="Invoice" />}
                        </Grid>
                      </Grid>
                    )}
                  </>
                )
              )}
            </Grid>
          )}

          <Stack
            direction="row-reverse"
            alignItems="center"
            sx={{
              bottom: 0,
              zIndex: 111111,
              p: 3,
              position: 'absolute',
              justifyContent: 'space-between',
              width: '100%',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Tooltip title="(Ctrl + A)" arrow>
              <LoadingButton
                variant="contained"
                loading={loading}
                id="add-voucher"
                onClick={async () => {
                  console.log(errors, 'errors');
                  if (await trigger()) {
                    onSubmit(allValues);
                  }
                }}
              >
                {row ? 'Update' : 'Add'}
              </LoadingButton>
            </Tooltip>
            <Tooltip title="(Esc)" arrow>
              <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
                Cancel
              </Button>
            </Tooltip>

            <Button
              variant="soft"
              onClick={() => {
                reset(values);
                document.getElementById('rept_pymt').focus();
              }}
              sx={{ mr: 2 }}
            >
              Reset
            </Button>

            <Grid flexGrow={1} spacing={2}>
              <Typography variant="subtitle2">
                Bank Payment (Alt+1) &nbsp; &nbsp; | &nbsp; &nbsp; Cash Payment (Alt+3) &nbsp;
                &nbsp; | &nbsp; &nbsp; &nbsp; Contra Payment (Alt+5)
              </Typography>
              <Typography variant="subtitle2">
                Bank Receipt (Alt+2) &nbsp; &nbsp; &nbsp; | &nbsp; &nbsp; Cash Receipt (Alt+4)
                &nbsp; &nbsp; &nbsp; &nbsp;| &nbsp; &nbsp; &nbsp; Contra Receipt (Alt+6)
              </Typography>
            </Grid>
          </Stack>
        </FormProvider>
      </Dialog>

      {quickEditAccount.value && (
        <AUserQuickEditForm
          row={accountEditList || accountOppEditList || null}
          open={quickEditAccount.value}
          onClose={handleCloseAccount}
          table={table}
          getAll={getAll}
          tableData={tableData}
          set={{
            fieldName,
            setValue,
            list: list[fieldName],
          }}
        />
      )}

      {modalOpen && (
        <CashManagementModal
          open={modalOpen}
          cashCounter={allValues.cashcounters}
          onClose={() => setModalOpen(false)}
          updateCashCounter={handleUpdateCashCounter}
          setNetTotal={(data) => {
            setValue('amount', data);
            setValue(`entry_details[0].amount`, data);
            setValue(`entry_details[1].amount`, data);
          }}
        />
      )}

      {confirm.value && (
        <ConfirmDialog
          open={confirm.value}
          onClose={confirm.onFalse}
          title="Delete"
          content="Are you sure want to delete?"
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() =>
                (accountEditList?.id && deleteAccountModel(accountEditList?.id)) ||
                (accountOppEditList?.id && deleteAccountModel(accountOppEditList?.id))
              }
            >
              Delete
            </Button>
          }
        />
      )}

      {printModel.value && (
        <SuccessConfirmDialog
          open={printModel.value}
          onClose={() => {
            printModel.onFalse();
            onClose();
          }}
          content="Do you want to print?"
          action={
            <Button
              variant="contained"
              color="inherit"
              onClick={() => {
                onClose();
                setPDFViwer(vId);
              }}
            >
              Yes
            </Button>
          }
          action2="No"
        />
      )}
    </>
  );
}

VoucherUserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
  setPDFViwer: PropTypes.number,
};
