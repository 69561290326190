import PropTypes from 'prop-types';
import * as Yup from 'yup';
// @mui
import Dialog from '@mui/material/Dialog';

import { Box, Divider, Grid, IconButton, InputAdornment, Toolbar, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { create, fetcher } from 'src/utils/axios';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { downloadFile } from 'src/_mock/constant_funcation';

export default function QuotationModel({ row, open, onClose }) {
  const [pricesData, setPricesData] = useState([]);
  const [loading, setLoading] = useState(false);

  const PricesMasterSchema = Yup.object().shape({
    discount: Yup.number().required('discount is required'),
  });

  const values = useMemo(
    () => ({
      customerId: row?.customerId || null,
      aenquiryId: row?.id || null,
      dealprice: row?.dealprice || null,
      discount: row?.discount || 0,
      taxable: row?.taxable || null,
      gstper: row?.gstper || null,
      gst: row?.gst || null,
      exshowroom: row?.exshowroom || null,
      rtotax: row?.rtotax || null,
      smctax: row?.smctax || null,
      hypothecation: row?.hypothecation || null,
      insurance: row?.insurance || null,
      ew: row?.ew || null,
      amc: row?.amc || null,
      accessories: row?.accessories || null,
      othercharges: row?.othercharges || null,
      remark: row?.remark || '',
      concession: row?.concession || null,
      finalinvoiceamount: row?.finalinvoiceamount || null,
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(PricesMasterSchema),
    values,
    mode: 'onChange',
  });

  const {
    reset,
    trigger,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      const URL = '/automobile/enquiries/quotations/';
      const { success, data } = await create({ url: URL, payload });

      if (success) {
        const qrl = data.url;
        downloadFile(qrl, `${row?.customer?.firstname}-QUOTATION-${row?.id}.pdf`);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  });

  const PricesMasterList = useCallback(
    async (vId) => {
      try {
        let URL = `automobile/product/prices/byvariant/${vId}`;
        URL += `?page=${1}&limit=${10000}&status=true&`;
        const { data } = await fetcher(URL);
        if (data) {
          setPricesData(data);
          setValue('dealprice', data?.exshowroomprice);
          setValue('gstper', (data?.cgst_per || 0) + (data?.sgst_per || 0));
        }
      } catch (error) {
        console.log(error);
      }
    },
    [setValue]
  );

  useEffect(() => {
    if (row?.avariant?.id) PricesMasterList(row?.avariant?.id);
  }, [row?.avariant?.id, PricesMasterList]);

  useEffect(() => {
    setValue('taxable', (allValues?.dealprice || 0) - (allValues?.discount || 0));
  }, [allValues?.dealprice, allValues?.discount, setValue]);

  useEffect(() => {
    setValue('gst', (allValues.taxable / 100) * allValues.gstper);
  }, [allValues.gstper, allValues.taxable, setValue]);

  useEffect(() => {
    // setValue('exshowroom', allValues.gst + allValues.taxable);
    setValue('exshowroom', allValues.taxable);
  }, [allValues.gst, allValues.taxable, setValue]);

  useEffect(() => {
    setValue(
      'finalinvoiceamount',
      allValues.exshowroom +
        allValues.rtotax +
        allValues.smctax +
        allValues.hypothecation +
        allValues.insurance +
        allValues.ew +
        allValues.amc +
        allValues.accessories +
        allValues.othercharges +
        allValues.concession
    );
  }, [
    allValues.accessories,
    allValues.amc,
    allValues.concession,
    allValues.ew,
    allValues.exshowroom,
    allValues.rtotax,
    allValues.hypothecation,
    allValues.insurance,
    allValues.othercharges,
    allValues.smctax,
    setValue,
  ]);

  useEffect(() => {
    setValue('rtotax', pricesData?.rtotax);
    // eslint-disable-next-line no-unsafe-optional-chaining
    const smcTax = pricesData?.regischarge + pricesData?.roadtax;

    setValue('smctax', smcTax);
    setValue('hypothecation', pricesData?.hpcharges);
    setValue('insurance', pricesData?.insurance);
    setValue('ew', pricesData?.extwar);
    setValue('accessories', pricesData?.access);
    setValue('othercharges', pricesData?.others);
  }, [
    pricesData?.access,
    pricesData?.extwar,
    pricesData?.hpcharges,
    pricesData?.insurance,
    pricesData?.others,
    pricesData?.regischarge,
    pricesData?.roadtax,
    pricesData?.rtotax,
    setValue,
  ]);

  return (
    <Dialog fullScreen open={open}>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          top: 0,
          zIndex: 9,
          position: 'sticky',
          bgcolor: 'primary.dark',
          color: 'white',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            tabIndex={-1}
            onClick={() => {
              onClose();
            }}
          >
            <Iconify icon="material-symbols:arrow-back" />
          </IconButton>
          <Typography variant="h5" sx={{ flex: 1 }}>
            Quotation
          </Typography>
        </Toolbar>
        <Divider sx={{ borderStyle: 'dashed' }} />
      </Grid>

      <Grid item xs={12} sm={12} md={12} sx={{ p: 1, textAlign: 'center' }}>
        <Typography variant="h5" sx={{ flex: 1 }}>
          {row?.avariant?.name}
        </Typography>
      </Grid>
      <FormProvider methods={methods}>
        <Grid item xs={12} sm={12} md={12} sx={{ p: 1 }}>
          <Grid item xs={12} sm={12} md={12} sx={{ p: 0.5, textAlign: 'center' }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  Customer Name
                </Typography>
              </Grid>
              <Grid item xs={0.5} />
              <Grid item xs={5.5}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  {row?.customer?.firstname} {row?.customer?.lastname}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ p: 0.5, textAlign: 'center' }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  Enquiry Id
                </Typography>
              </Grid>
              <Grid item xs={0.5} />
              <Grid item xs={5.5}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  {row?.id}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} sx={{ p: 0.5, textAlign: 'center' }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  Dealer Billing Price
                </Typography>
              </Grid>
              <Grid item xs={0.5} />
              <Grid item xs={5.5}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  ₹ {allValues.dealprice?.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ p: 0.5, textAlign: 'center' }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  Discount
                </Typography>
              </Grid>
              <Grid item xs={0.5} />
              <Grid item xs={5.5}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  <RHFTextField
                    type="number"
                    name="discount"
                    variant="standard"
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} sx={{ p: 0.5, textAlign: 'center' }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  Taxable
                </Typography>
              </Grid>
              <Grid item xs={0.5} />
              <Grid item xs={5.5}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  ₹ {allValues.taxable}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ p: 0.5, textAlign: 'center' }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  GST
                </Typography>
              </Grid>
              <Grid item xs={0.5} />
              <Grid item xs={5.5}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  ₹ {allValues?.gst?.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ p: 0.5, textAlign: 'center' }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                  Ex-Showroom
                </Typography>
              </Grid>
              <Grid item xs={0.5} />
              <Grid item xs={5.5}>
                <Typography variant="body2" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                  ₹ {allValues.exshowroom}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ p: 0.5, textAlign: 'center' }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  RTO Taxes
                </Typography>
              </Grid>
              <Grid item xs={0.5} />
              <Grid item xs={5.5}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  <RHFTextField
                    type="number"
                    name="rtotax"
                    variant="standard"
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ p: 0.5, textAlign: 'center' }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  SMC Tax
                </Typography>
              </Grid>
              <Grid item xs={0.5} />
              <Grid item xs={5.5}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  <RHFTextField
                    type="number"
                    name="smctax"
                    variant="standard"
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ p: 0.5, textAlign: 'center' }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  Hypothecation
                </Typography>
              </Grid>
              <Grid item xs={0.5} />
              <Grid item xs={5.5}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  <RHFTextField
                    type="number"
                    name="hypothecation"
                    variant="standard"
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ p: 0.5, textAlign: 'center' }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  Insurance
                </Typography>
              </Grid>
              <Grid item xs={0.5} />
              <Grid item xs={5.5}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  <RHFTextField
                    type="number"
                    name="insurance"
                    variant="standard"
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ p: 0.5, textAlign: 'center' }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  Extended Warranty
                </Typography>
              </Grid>
              <Grid item xs={0.5} />
              <Grid item xs={5.5}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  <RHFTextField
                    type="number"
                    name="ew"
                    variant="standard"
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ p: 0.5, textAlign: 'center' }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  AMC
                </Typography>
              </Grid>
              <Grid item xs={0.5} />
              <Grid item xs={5.5}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  <RHFTextField
                    type="number"
                    name="amc"
                    variant="standard"
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ p: 0.5, textAlign: 'center' }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  Accessories
                </Typography>
              </Grid>
              <Grid item xs={0.5} />
              <Grid item xs={5.5}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  <RHFTextField
                    type="number"
                    name="accessories"
                    variant="standard"
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ p: 0.5, textAlign: 'center' }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  Other Charges
                </Typography>
              </Grid>
              <Grid item xs={0.5} />
              <Grid item xs={5.5}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  <RHFTextField
                    type="number"
                    name="othercharges"
                    variant="standard"
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {allValues.othercharges >= 1 && (
            <Grid item xs={12} sm={12} md={12} sx={{ p: 2, textAlign: 'center' }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ textAlign: 'left' }}>
                    <RHFTextField
                      type="text"
                      name="remark"
                      variant="standard"
                      size="small"
                      placeholder="Enter other charges remark"
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} sx={{ p: 0.5, textAlign: 'center' }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  Concession
                </Typography>
              </Grid>
              <Grid item xs={0.5} />
              <Grid item xs={5.5}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>
                  <RHFTextField
                    type="number"
                    name="concession"
                    variant="standard"
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ p: 0.5, textAlign: 'center' }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                  Final Invoice Amount
                </Typography>
              </Grid>
              <Grid item xs={0.5} />
              <Grid item xs={5.5}>
                <Typography variant="body2" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                  ₹ {allValues?.finalinvoiceamount?.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Iconify
            icon="logos:whatsapp-icon"
            width={45}
            height={45}
            sx={{
              position: 'fixed',
              bottom: 25,
              right: 20,
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: 3,
              zIndex: 99999999,
              cursor: 'pointer',
            }}
            onClick={async () => {
              if (await trigger()) {
                onSubmit();
              }
            }}
          />
        </Grid>
      </FormProvider>
    </Dialog>
  );
}

QuotationModel.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  row: PropTypes.object,
};
