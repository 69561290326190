import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import { AuthContext } from 'src/auth/context/jwt';
import { useContext } from 'react';
import MainLayout from 'src/layouts/main';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { mainRoutes, HomePage } from './main';
import { authDemoRoutes } from './auth-demo';
import { dashboardRoutes } from './dashboard';
import { componentsRoutes } from './components';

// ----------------------------------------------------------------------

export default function Router() {
  const { user } = useContext(AuthContext);
  const token = localStorage.getItem('token');
  const db = dashboardRoutes(user);
  return useRoutes(
    user?.modules?.length
      ? [
        {
          path: '/',
          element: (
            <MainLayout>
              <HomePage />
            </MainLayout>
          ),
        },
        ...(token ? [] : [...authDemoRoutes]),
        ...db,
        ...mainRoutes,
        // ...componentsRoutes,
        { path: '*', element: <Navigate to="/404" replace /> },
      ]
      : [
        {
          path: '/',
          element: (
            <MainLayout>
              <HomePage />
            </MainLayout>
          ),
        },
        ...(token ? [] : [...authDemoRoutes]),

        ...mainRoutes,
        ...componentsRoutes,
        { path: '*', element: <Navigate to="/404" replace /> },
      ]
  );
}
