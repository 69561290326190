import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect, useContext } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Importerrors from 'src/layouts/_common/importerrors';
import Importloaders from 'src/layouts/_common/importloaders';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import queryString from 'query-string';
import { useSnackbar } from 'src/components/snackbar';
import { useDebounce } from 'src/hooks/use-debounce';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';
//
import { fetcher, destroy } from 'src/utils/axios';
import { useEventListener } from 'src/hooks/use-event-listener';
import { Tooltip } from '@mui/material';
import { TableRowsLoader, UPermission } from 'src/_mock/constant_funcation';
import { AuthContext } from 'src/auth/context/jwt';
import UserTableRow from '../table-row';
import UserTableToolbar from '../table-toolbar';
import UserTableFiltersResult from '../table-filters-result';
import GroupAccountForm from '../quick-edit-form';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'DeActive' },
];

const TABLE_HEAD = [
  { id: 'no', label: 'No.', width: 90 },
  { id: 'id', label: 'Group No.', width: 120 },
  { id: 'name', label: 'Group Name' },
  { id: 'ac_groupId', label: 'Under' },
  { id: 'hname', label: 'Group Header' },
  { id: 'seq', label: 'Sequence' },
  { id: 'status', label: 'Status', width: 100 },
  { id: '', label: 'Action', width: 90 },
];

const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
};

// ----------------------------------------------------------------------

export default function ListView() {
  const table = useTable();
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();
  const permission = UPermission('component', 'AutoMobileAccountsGroupListPage');
  const [progress, setProgress] = useState({ status: 'done' });
  const { user, socket } = useContext(AuthContext);
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [row, setRow] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [filters, setFilters] = useState(defaultFilters);

  const debouncedQuery = useDebounce(filters);

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = count === 0;

  const { page } = table;
  const limit = table.rowsPerPage;

  const getAll = useCallback(async () => {
    setIsLoading(true);
    try {
      let URL = 'automobile/ac_groups/';
      URL += `?page=${page + 1}&limit=${limit}&allGroups=false&`;
      if (Object.keys(debouncedQuery).length) {
        const nefil = { ...debouncedQuery };
        if (debouncedQuery.status === 'all') {
          delete nefil.status;
        }
        URL += queryString.stringify(nefil);
      }

      const { data } = await fetcher(URL);
      if (data) {
        setCount(data?.count);
        setTableData(data?.rows);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, [debouncedQuery, limit, page]);

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleDeleteRows = useCallback(
    async (id) => {
      try {
        let URL = 'automobile/ac_groups/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          getAll();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [enqueueSnackbar, getAll]
  );

  useEffect(() => {
    getAll();
  }, [getAll, handleDeleteRows, user?.co_year?.id]);

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const quickEdit = useBoolean();

  const handleKeyDown = (event) => {
    if (event.altKey && event.key.toLowerCase() === 'c') {
      event.stopPropagation();
      setRow(null);
      quickEdit.onTrue();
    }
  };
  const onImportError = useBoolean();
  const so = useCallback(() => {
    socket?.on('progress', (data) => {
      if ('ac_groups' in data && data.ac_groups.status === 'error') {
        setProgress(data.ac_groups);
        onImportError.setValue(true);
      }
      if ('ac_groups' in data && data.ac_groups.status === 'done') {
        setProgress(data.ac_groups);
      } else if ('ac_groups' in data && data.ac_groups.error) {
        // setError(data.error);
      } else if ('ac_groups' in data) {
        setProgress(data.ac_groups);

      }
    });
  }, [onImportError, socket])
  useEffect(() => so(), [so, socket])
  useEventListener('keydown', handleKeyDown);

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Account Group List"
          links={[{ name: 'Dashboard', href: paths.home }, { name: 'List' }]}
          action={
            permission?.c && (
              <Tooltip title="(Alt + C)" arrow>
                <Button
                  component={RouterLink}
                  variant="contained"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                  onClick={() => {
                    setRow(null);
                    quickEdit.onTrue();
                  }}
                >
                  Account Group
                </Button>
              </Tooltip>
            )
          }
          sx={{
            mb: { xs: 2, md: 2 },
          }}
        />

        <Card>
          <Tabs
            value={filters.status}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab key={tab.value} iconPosition="end" value={tab.value} label={tab.label} />
            ))}
          </Tabs>

          <UserTableToolbar
            filters={filters}
            onFilters={handleFilters}
            getAll={getAll}
            setIsLoading={setIsLoading}
            permission={permission}
          />

          {canReset && (
            <UserTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              onResetFilters={handleResetFilters}
              results={count}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar sx={{ maxHeight: 400 }}>
              <Table stickyHeader size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {isLoading ? (
                    <TableRowsLoader rowsNum={table.rowsPerPage} colNum={TABLE_HEAD.length} />
                  ) : (
                    <>
                      {tableData.map((row, index) => (
                        <UserTableRow
                          key={row.id}
                          row={row}
                          index={page * limit + index + 1}
                          selected={table.selected.includes(row.id)}
                          onDeleteRow={() => handleDeleteRows(row.id)}
                          setRow={setRow}
                          quickEdit={quickEdit}
                          permission={permission}
                        />
                      ))}

                      <TableNoData notFound={notFound} />
                    </>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>

      <GroupAccountForm
        row={row || null}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
        table={table}
        getAll={getAll}
        tableData={tableData}
      />
      {onImportError.value && progress?.status === "error" && (
        <Importerrors
          row={progress}
          open={onImportError.value}
          onClose={onImportError.onFalse}
        />
      )}
      {!['error', 'done'].includes(progress.status) && <Importloaders progress={progress} />}
    </>
  );
}
