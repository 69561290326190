import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { KEY } from 'src/_mock/constant';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom, useTable } from 'src/components/table';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'src/components/hook-form/form-provider';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { create, fetcher } from 'src/utils/axios';
import { TableRowsLoader } from 'src/_mock/constant_funcation';
import EmptyContent from 'src/components/empty-content/empty-content';
import { AuthContext } from 'src/auth/context/jwt';
import { useEventListener } from 'src/hooks/use-event-listener';
import YearTableRow from './year-table';
import CompanyYear from './company-year';

const TABLE_HEAD = [
  { id: 'no', label: 'No.', width: 90 },
  { id: 'name', label: 'Date Range' },
  { id: '', label: 'Active' },
];

export default function YearManage({ open, onClose }) {
  const table = useTable();
  const { user, changeAdvance, initialize } = useContext(AuthContext);

  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [setrow, setRow] = useState(null);

  const [tableData, setTableData] = useState([]);

  const validationSchema = Yup.object().shape({
    sdate: Yup.date().required('Required'),
    edate: Yup.date().required('Required'),
  });

  const values = useMemo(
    () => ({
      sdate: '',
      edate: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    values,
  });

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = methods;

  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      const URL = 'co_years/';

      const companyId = user?.companyId;
      payload = { ...payload, companyId };

      const { success, show } = await create({ url: URL, payload });
      if (success) {
        getAll();
        if (show) {
          enqueueSnackbar('Create success!');
        }
      }

      setLoading(false);
      reset();
    } catch (error) {
      setLoading(false);
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      reset();
      console.error(error);
    }
  });

  const getAll = useCallback(async () => {
    setLoader(true);
    try {
      const URL = `co_years/?companyId=${user?.companyId}&`;

      const { data } = await fetcher(URL);
      setCount(data?.count);
      setTableData(data?.rows);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  }, [user?.companyId]);

  const isPermission = user?.modules?.find((x) => x.title === 'Financial Year')?.user_permission;

  useEffect(() => {
    getAll();
  }, [getAll, open]);

  useEffect(() => {
    if (setrow && user?.co_yearId !== setrow)
      changeAdvance({
        co_yearId: setrow,
      });
  }, [setrow, changeAdvance, user?.co_yearId]);

  const notFound = count === 0;

  const handleKeyDown = async (event) => {
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedRow((prevRow) => (prevRow === null ? 0 : Math.max(0, prevRow - 1)));
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedRow((prevRow) =>
        prevRow === null
          ? Math.min(tableData.length - 1, 0)
          : Math.min(tableData.length - 1, prevRow + 1)
      );
    } else if (event.key === 'Enter' && selectedRow !== null) {
      event.preventDefault();
      const selectedRowData = tableData[selectedRow];
      setRow(selectedRowData.id);
    }

    if (event.key === KEY.CLOSE) {
      onClose();
      initialize();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if (!loading) {
        await trigger();
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 1024, minHeight: 500 },
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          top: 0,
          zIndex: 9,
          position: 'sticky',
          bgcolor: (theme) => alpha(theme.palette.background.default, 1),
        }}
      >
        <Toolbar>
          <Typography variant="h5" sx={{ flex: 1 }}>
            Financial Year
          </Typography>

          <CompanyYear />

          <IconButton
            color="inherit"
            edge="start"
            onClick={() => {
              onClose();
              reset();
              initialize();
            }}
          >
            <Iconify icon="mingcute:close-line" />
          </IconButton>
        </Toolbar>
        <Divider sx={{ borderStyle: 'dashed' }} />
      </Grid>

      <DialogContent sx={{ p: 2 }}>
        {isPermission?.c && (
          <FormProvider methods={methods}>
            <Grid container>
              <Grid container item xs={12} md={12} sx={{ p: 1 }} spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <Controller
                    name="sdate"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <DatePicker
                        value={new Date(field.value)}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        format="dd/MM/yyyy"
                        label="Start Date"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            size: 'small',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <Controller
                    name="edate"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <DatePicker
                        value={new Date(field.value)}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        format="dd/MM/yyyy"
                        label="End Date"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            size: 'small',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <LoadingButton
                    variant="contained"
                    loading={loading}
                    onClick={async () => {
                      console.log(errors);
                      await trigger();
                      onSubmit();
                    }}
                  >
                    Add
                  </LoadingButton>
                  <Button
                    variant="soft"
                    sx={{ ml: 2 }}
                    loading={loading}
                    onClick={() => {
                      reset();
                    }}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </FormProvider>
        )}

        <TableContainer sx={{ position: 'relative', overflow: 'unset', mt: 2 }}>
          <Scrollbar sx={{ maxHeight: 500 }}>
            <Table stickyHeader size={table.dense ? 'small' : 'medium'}>
              <TableHeadCustom headLabel={TABLE_HEAD} />

              <TableBody>
                {loader ? (
                  <TableRowsLoader rowsNum={5} colNum={3} />
                ) : (
                  <>
                    {tableData.map((row, index) => (
                      <YearTableRow
                        tableData={tableData}
                        key={row.id}
                        row={row}
                        index={index + 1}
                        table={{ table }}
                        getAll={getAll}
                        setRow={setRow}
                        user={user}
                        selected={index === selectedRow}
                      />
                    ))}
                    <TableRow>
                      {notFound && (
                        <TableCell colSpan={4}>
                          <EmptyContent filled title="No Data" />
                        </TableCell>
                      )}
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </DialogContent>
      <DialogActions
        sx={{
          bottom: 0,
          zIndex: 9,
          position: 'sticky',
          bgcolor: (theme) => alpha(theme.palette.background.default, 1),
        }}
      >
        <LoadingButton
          variant="contained"
          onClick={() => {
            onClose();
            reset();
            initialize();
          }}
        >
          ok
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

YearManage.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
