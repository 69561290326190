import { Helmet } from 'react-helmet-async';
import { COMPANY_NAME } from 'src/_mock/constant';
// sections
import { ListView } from 'src/sections/work_shop/reports/general-printing/bt-printing-accessories-to-showroom/view';

// ----------------------------------------------------------------------

export default function BTPrintingAccToShowRoomListPage() {
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} : BT Printing (Accessories) To Showroom List</title>
      </Helmet>

      <ListView />
    </>
  );
}
