import { Helmet } from 'react-helmet-async';
import { COMPANY_NAME } from 'src/_mock/constant';
// sections
import { ListView } from 'src/sections/honda/challan/view';

// ----------------------------------------------------------------------

export default function ChallanPageList() {
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} : Delivery Challan (Tax) GST </title>
      </Helmet>

      <ListView />
    </>
  );
}
