/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
// @mui
import * as Yup from 'yup';
import { useCallback, useMemo, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
// @mui
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// routes
import { paths } from 'src/routes/paths';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { useRouter } from 'src/routes/hooks';
import FormProvider, {
  RHFEditor,
  RHFUpload,
  RHFTextField,
  RHFAutocomplete,
  RHFMultiCheckbox,
  RHFCheckbox,
} from 'src/components/hook-form';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
// components
import Scrollbar from 'src/components/scrollbar';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { Box, ListItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { HOST_API } from 'src/config-global';
import { TableHeadCustom } from 'src/components/table';
import { update } from 'src/utils/axios';

const excludepviot = ['aggregators', 'data', 'onChange', 'renderers', 'tableColorScaleGenerator'];
const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'center', width: '40%' },
  { id: 'department', label: 'Department', align: 'center', width: '30%' },
  { id: 'edit', label: 'Edit', align: 'center', width: '5%' },
  { id: 'share', label: 'Share', align: 'center', width: '5%' },
  { id: 'delete', label: 'Delete', align: 'center', width: '10%' },
];
// ----------------------------------------------------------------------

export default function CreateEdit({
  open,
  onOpen,
  onClose,
  currentData,
  users,
  pivotData,
  alreadyUsedKeyList,
  reportlist,
  //
  filters,
  onFilters,
  //
  canReset,
  onResetFilters,
  //
  destinationOptions,
  userOptions,
  serviceOptions,
  //
  dateError,
}) {


  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        CREATE / UPDATE REPORT
      </Typography>

      <Tooltip title="Reset">
        <IconButton onClick={() => {
          resetform();
        }}>
          <Badge color="error" variant="dot" invisible={!canReset}>
            <Iconify icon="solar:restart-bold" />
          </Badge>
        </IconButton>
      </Tooltip>

      <IconButton onClick={onClose}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();
  const NewReportSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, 'Name must be at least 1 character')
      .max(100, 'Name cannot exceed 100 characters')
      .required('Name is required'),
    reportpermissions: Yup.array().of(
      Yup.object().shape({
        perrecusId: Yup.number().integer().positive().nullable(true),
        edit: Yup.boolean().required(),
        share: Yup.boolean().required(),
        reportId: Yup.number().integer().nullable(true), // Allow null values
      })
    ),
    reportshortcutkey: Yup.object({
      key: Yup.string().nullable(true)
        .test(
          'is-unique',
          'This shortcut key is already in use. Please choose another one.',
          (value) => !alreadyUsedKeyList.includes(value?.toLowerCase())
        ),
    }).nullable(true), // Allow the shortcutkey to be null
  });

  const defaultValues = useMemo(
    () => ({
      name: currentData?.name || '',
      reportcategoryId: currentData?.reportcategoryId || null,
      perrecuser: currentData?.perrecuser || {},
      reportpermissions: currentData?.reportpermissions || [{ perrecusId: null, edit: false, share: false }],
      reportshortcutkey: currentData?.reportshortcutkey || { key: null }
    }),
    [currentData]
  );

  const [keys, setKeys] = useState([]);
  const methods = useForm({
    resolver: yupResolver(NewReportSchema),
    defaultValues,
  });

  const handleAdd = () => {
    append({ perrecusId: null, edit: false, share: false });
  };
  const handleUserChange = (index, user) => {
    const newRows = [...fields];
    newRows[index].perrecuser = user || {};
    newRows[index].perrecusId = user ? user.id : null;

    if (index === fields.length - 1 && user) {
      handleAdd(); // Automatically add a new row when the last row's perrecusId is selected
    }
  };
  const {
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    formState: { isSubmitting, errors },
  } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'reportpermissions',
  });
  const values = watch();
  const resetform = () => {
    reset();
    resetNestedFields([['reportshortcutkey', 'key']]); // Resets to empty string
  }
  const resetNestedFields = (fieldPaths, resetValue = '') => {
    if (!Array.isArray(fieldPaths)) {
      console.warn('Expected an array of field paths');
      return;
    }

    fieldPaths.forEach((fieldPath) => {
      // Convert array path to dot notation string if the path is an array
      const path = Array.isArray(fieldPath) ? fieldPath.join('.') : fieldPath;

      // Check if the field exists in the form
      const currentFieldValue = getValues(path);
      if (currentFieldValue !== undefined) {
        // Reset the field value
        setValue(path, resetValue, { shouldDirty: true, shouldTouch: true });
      }
    });
  };

  const handleKeyDown = (event) => {
    if (document.activeElement.name !== 'reportshortcutkey.key') {
      return; // Do nothing if not focused on "shortcutkey"
    }
    const key = event.key.toLowerCase();
    // Allow Tab to retain its default behavior
    if (key === 'tab') {
      return;
    }

    event.preventDefault();
    // Exclude the Backspace and Enter keys
    if (['enter', 'backspace', 'capslock', 'esc', 'delete', 'prtsc', 'insert', 'arrowup', 'arrowdown', 'arrowleft', 'arrowright', 'numlock'].includes(key)) {
      setValue(document.activeElement.name, '');
      setKeys([]);
      return;
    }

    // Check if the first key is not a number or alphabet character
    const isAlphabetOrNumber = /^[a-z0-9]$/i.test(key);
    if (keys.length === 0 && isAlphabetOrNumber) {
      // Prevent adding if the first key is a number or an alphabet
      return;
    }

    // Check if the key is not already in the keys array
    if (!keys.includes(key)) {
      setKeys((prevKeys) => [...prevKeys, key]);
    }
  };

  const handleKeyUp = () => {
    if (document.activeElement.name !== 'reportshortcutkey.key') {
      return; // Do nothing if not focused on "shortcutkey"
    }
    if (keys.length > 0) {
      const shortcut = keys.join(' + ');

      // Set the shortcut key value
      setValue(document.activeElement.name, shortcut);

      // Check if the shortcut is already used
      if (alreadyUsedKeyList.includes(shortcut.toLowerCase())) {
        setError(document.activeElement.name, {
          type: 'manual',
          message: 'This shortcut key is already in use. Please choose another one.',
        });
      } else {
        clearErrors(document.activeElement.name); // Clear any existing errors
      }
      setKeys([]); // Clear keys array
    }
  };
  const getAvailableOptions = (index) => {
    // Get the current selected IDs for all reportpermissions (excluding the current index)
    const selectedIds = fields
      .map((item, idx) => (idx !== index ? item.perrecusId : null))
      .filter((id) => id !== null);

    // Return the filtered users that are not in the selectedIds or match the current value
    return users.filter(
      (user) => !selectedIds.includes(user.id) || user.id === fields[index].perrecusId
    );
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp, keys]);


  useEffect(() => {
    if (currentData) {
      reset(defaultValues);
    }
  }, [currentData, defaultValues, reset]);

  const checkDuplicateReport = (list, current) => {
    // Extract the relevant keys from the current report's setting
    const currentSettingSubset = ["cols", "rows", "colOrder", "rowOrder"].reduce((subset, key) => {
      subset[key] = current.setting[key];
      return subset;
    }, {});

    // Check if there is a duplicate in the list
    return list.find((report) => {
      // Extract the relevant keys from the report's setting
      const reportSettingSubset = ["cols", "rows", "colOrder", "rowOrder"].reduce((subset, key) => {
        subset[key] = report.setting[key];
        return subset;
      }, {});

      // Compare the subsets for equality
      return JSON.stringify(reportSettingSubset) === JSON.stringify(currentSettingSubset);
    });
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const temp = {};

      Object.keys(pivotData).forEach(x => {
        if (!excludepviot.includes(x)) {
          temp[x] = pivotData[x];
        }
      });

      const filteredData = {
        ...data,
        reportpermissions: data.reportpermissions?.filter((permission) => permission.perrecusId !== null),
        reportshortcutkey: JSON.parse(JSON.stringify(data.reportshortcutkey)),
        setting: temp
      };
      const duplicatereport = checkDuplicateReport(reportlist, filteredData)
      if (duplicatereport && duplicatereport.id !== currentData.id) {
        enqueueSnackbar(`This Report Already Exists Name: ${duplicatereport?.name}`);
      } else {

        if (!filteredData.reportshortcutkey.key) {
          delete filteredData.reportshortcutkey;
        }
        const URL = `automobile/reports/report/${currentData?.id || 0}`;
        const { success, show } = await update({ url: URL, payload: filteredData });
        if (success) {
          if (show) {
            enqueueSnackbar(`${(currentData?.id || 0) === 0 ? 'Created' : 'Updated'} Successfully`);
            resetform()
          }
        }
      }
      onClose();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const files = values.images || [];

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setValue('images', [...files, ...newFiles], { shouldValidate: true });
    },
    [setValue, values.images]
  );

  const handleRemoveFile = useCallback(
    (inputFile) => {
      const filtered = values.images && values.images?.filter((file) => file !== inputFile);
      setValue('images', filtered);
    },
    [setValue, values.images]
  );

  const handleRemoveAllFiles = useCallback(() => {
    setValue('images', []);
  }, [setValue]);


  const renderDetails = (
    <Grid xs={12}>
      <Card sx={{ maxHeight: '80vh', height: '73vh', py: 3, m: 3 }}>
        {!mdUp && <CardHeader title="Details" />}

        <Scrollbar sx={{ px: 2.5, py: 0 }}>
          {mdUp && (
            <Grid md={4}>
              <Typography variant="subtitle2" sx={{ mb: 3 }}>
                Report Information
              </Typography>
            </Grid>
          )}
          <Stack spacing={3} >
            <Box
              columnGap={2}
              rowGap={1}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                md: '70% 30%',
              }}
            > <RHFTextField name="name" size="small" label="Name" variant="standard" isUpperCase placeholder="Ex: Sales Report..." InputLabelProps={{ shrink: true }} />
              <RHFTextField name="reportshortcutkey.key" size="samll" variant="standard" label='Shortcutkey' placeholder="Ex: Press shortcut key" InputLabelProps={{ shrink: true }} /></Box>

            {
              Array.isArray(users) && users.length && <Stack spacing={1.5}>
                <Typography variant="subtitle2" sx={{ mb: 1.5 }}>
                  Report Permission
                </Typography>
                <TableContainer sx={{ mt: 0, overflow: 'unset', p: 0 }}>
                  <Scrollbar>
                    <Table sx={{ minWidth: 500, p: 0 }}>
                      <TableHead>


                        <TableRow>
                          {TABLE_HEAD.map((headCell) => (
                            <TableCell
                              key={headCell.id}
                              align={headCell.align}
                              sx={{ p: 0, width: headCell.width }} // Remove padding
                            >
                              {headCell.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fields.map((item, index) => (
                          <TableRow key={index} sx={{ p: 0 }}>
                            <TableCell sx={{ p: 0 }}>
                              <Controller
                                name={`reportpermissions.${index}.perrecusId`}
                                control={control}
                                render={({ field, formState }) => (
                                  <Autocomplete
                                    size="small"
                                    fullWidth
                                    options={getAvailableOptions(index)} // Use the filtered options
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                      field.onChange(newValue ? newValue.id : null);
                                      // Update another field value using setValue
                                      setValue(`reportpermissions.${index}.perrecuser`, newValue);
                                      handleUserChange(index, newValue || null);
                                    }}
                                    groupBy={(option) => option?.department?.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        error={!!errors?.reportpermissions?.[index]?.perrecusId}
                                        helperText={errors?.reportpermissions?.[index]?.perrecusId?.message}
                                      />
                                    )}
                                    value={users.find(user => user.id === field.value) || null}
                                    isOptionEqualToValue={(option, value) => option.id === value}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell align="left" sx={{ p: 0, pl: 5 }}>{item?.perrecuser?.department?.name || '-'}</TableCell>
                            <TableCell align="right" sx={{ p: 0 }}>
                              <RHFCheckbox name={`reportpermissions.${index}.edit`} />
                            </TableCell>
                            <TableCell align="right" sx={{ p: 0 }}>
                              <RHFCheckbox name={`reportpermissions.${index}.share`} />
                            </TableCell>
                            <TableCell align="right" sx={{ p: 0 }}>
                              <Button
                                size="small"
                                color="error"
                                startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                                onClick={() => remove(index)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Scrollbar>
                </TableContainer>


              </Stack>
            }
          </Stack>
        </Scrollbar>
      </Card>
    </Grid>
  );


  const renderActions = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="right"
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >

      <LoadingButton
        type="submit"
        variant="contained"
        size="medium"
        loading={isSubmitting}
        sx={{ ml: 2 }}
      >
        {!currentData?.id ? 'Create Report' : 'Save Changes'}
      </LoadingButton>
    </Stack>
  );

  return (
    <>
      <Tooltip title={`Quick ${currentData?.id ? 'Edit' : 'Add'}`} placement="top" arrow>
        <IconButton
          color="inherit"
          onClick={onOpen}

        >
          <Iconify icon={currentData?.id ? "solar:pen-bold" : "mingcute:add-line"} />
        </IconButton>
      </Tooltip>

      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: '50%' },
        }}
      >
        <FormProvider methods={methods} onSubmit={onSubmit}>
          {renderHead}

          <Divider />

          <Grid container >
            {renderDetails}
          </Grid>
          {renderActions}
        </FormProvider>
      </Drawer>
    </>
  );
}

CreateEdit.propTypes = {
  canReset: PropTypes.bool,
  dateError: PropTypes.bool,
  destinationOptions: PropTypes.array,
  users: PropTypes.array,
  filters: PropTypes.object,
  pivotData: PropTypes.object,
  currentData: PropTypes.object,
  onClose: PropTypes.func,
  onFilters: PropTypes.func,
  onOpen: PropTypes.func,
  onResetFilters: PropTypes.func,
  open: PropTypes.bool,
  serviceOptions: PropTypes.array,
  alreadyUsedKeyList: PropTypes.array,
  reportlist: PropTypes.array,
  userOptions: PropTypes.array,
};
