import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { create, destroy, fetcher, update } from 'src/utils/axios';
import { BALANCE_TYPE, KEY } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  ListItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { Box, Stack, alpha } from '@mui/system';
import { DateField, DatePicker } from '@mui/x-date-pickers';
import { useEventListener } from 'src/hooks/use-event-listener';
import { NumericFormatCustom, fMoney, useBgColorAutocomplete } from 'src/_mock/constant_funcation';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { useBoolean } from 'src/hooks/use-boolean';
import AUserQuickEditForm from 'src/sections/accounts/account/quick-edit-form';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CompanyYear from 'src/layouts/_common/company-year';

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();

  const quickEditAccount = useBoolean();
  const confirm = useBoolean();

  const bgColorAutocomplete = useBgColorAutocomplete();

  const [accountList, setAccountList] = useState([]);
  const [accountEditList, setaccountEditList] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);

  const JournalEntrySchema = Yup.object().shape({
    v_no: Yup.string().nullable(),
    bill_no: Yup.string().nullable(),
    cb: Yup.number().integer().nullable(),
    t_type: Yup.number().integer().required(),
    amount: Yup.number().nullable().min(0),
    narr: Yup.string().nullable(),
    entry_details: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().integer().nullable(),
        cb: Yup.number().integer().required(),
        accountId: Yup.number().integer().required(),
        bentryId: Yup.number().integer().nullable(),
        entry_detailId: Yup.number().integer().nullable(),
        bpoptionId: Yup.number()
          .integer()
          .nullable()
          .transform((value, originalValue) => (originalValue === 'null' ? null : originalValue)),
        ac_no: Yup.string().nullable(),
        amount: Yup.number().required(),
      })
    ),
  });

  console.log(row, 'row');

  const values = useMemo(
    () => ({
      t_type: row?.t_type || 7,
      v_no: row?.v_no || null,
      bill_no: row?.bill_no || null,
      cb: row?.cb || null,
      dt: row?.dt || new Date(),
      t_date: row?.t_date || new Date(),
      b_date: row?.b_date || new Date(),
      narr: row?.narr || null,
      isHighligh: '',
      entry_details: row?.entry_details
        ? row?.entry_details.map((x) => ({
            ...(row ? { id: x?.id } : {}),
            cb: x?.cb || 1,
            accountId: x?.accountId || null,
            entryId: x?.entryId || null,
            bentryId: x?.bentryId || null,
            entry_detailId: x?.entry_detailId || null,
            bpoptionId: x?.bpoptionId || null,
            ac_no: x?.ac_no || null,
            amount: x?.amount || null,
          }))
        : [
            {
              ...(row ? { id: row?.entry_details[0]?.id } : {}),
              cb: row?.entry_details[0]?.cb || 1,
              accountId: row?.entry_details[0]?.accountId || null,
              entryId: row?.entry_details[0]?.entryId || null,
              bentryId: row?.entry_details[0]?.bentryId || null,
              entry_detailId: row?.entry_details[0]?.entry_detailId || null,
              bpoptionId: row?.entry_details[0]?.bpoptionId || null,
              ac_no: row?.entry_details[0]?.ac_no || null,
              amount: row?.entry_details[0]?.amount || null,
            },
            {
              ...(row ? { id: row?.entry_details[1]?.id } : {}),
              cb: row?.entry_details[1]?.cb || 2,
              accountId: row?.entry_details[1]?.accountId || null,
              entryId: row?.entry_details[1]?.entryId || null,
              bentryId: row?.entry_details[1]?.bentryId || null,
              entry_detailId: row?.entry_details[1]?.entry_detailId || null,
              bpoptionId: row?.entry_details[1]?.bpoptionId || null,
              ac_no: row?.entry_details[1]?.ac_no || null,
              amount: row?.entry_details[1]?.amount || null,
            },
          ],
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(JournalEntrySchema),
    values,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    trigger,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const allValues = watch();

  const onSubmit = handleSubmit(async (payload) => {
    try {
      payload.invo_type = 1;
      payload.amount = payload.entry_details
        ?.filter((x) => x.cb === 1)
        ?.reduce((pre, next) => pre + next.amount, 0);

      payload.entry_details = payload.entry_details?.map((x, index) => ({
        ...x,
        order: index + 1,
      }));

      let URL = 'entries/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const entry_details = useFieldArray({
    control,
    name: 'entry_details',
  });

  const handleAdd = useCallback(() => {
    entry_details?.append({
      ...(row ? { id: null } : {}),
      productId: null,
      qty: null,
      rate: null,
      dprice: null,
      drate: null,
      CGST: null,
      SGST: null,
      IGST: null,
    });
  }, [entry_details, row]);

  const handleRemove = useCallback(
    (index) => {
      if (index >= 0 && index !== null) {
        entry_details?.remove(index);
      }
    },
    [entry_details]
  );

  const HandleAccountList = async () => {
    let URL = 'accounts/';
    URL += `?page=${1}&limit=${10000}&status=true&`;
    const { data } = await fetcher(URL);
    setAccountList(data?.rows.map((x) => ({ ...x, a: 'name' })));
  };

  useEffect(() => {
    if (open) {
      HandleAccountList();
    }
  }, [open]);

  const handleKeyDown = (event) => {
    if (event.altKey && event.key === 'a') {
      event.stopPropagation();
      handleAdd();
    }
    if (event.altKey && event.key === 'r') {
      event.stopPropagation();
      handleRemove(currentRowIndex);
    }
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };
  useEventListener('keydown', handleKeyDown);

  const handleCloseAccount = () => {
    quickEditAccount.onFalse();
    HandleAccountList();
    setaccountEditList(null);
  };

  const deleteAccountModel = useCallback(
    async (id) => {
      try {
        let URL = 'accounts/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          HandleAccountList();
          confirm.onFalse();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [enqueueSnackbar, confirm]
  );

  return (
    <>
      <Dialog fullScreen maxWidth={false} onKeyDown={handleKeyDown} open={open}>
        <FormProvider methods={methods}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                top: 0,
                zIndex: 9,
                position: 'sticky',
                bgcolor: (theme) => alpha(theme.palette.background.default, 1),
              }}
            >
              <Toolbar>
                <Typography variant="h5" sx={{ flex: 1 }}>
                  {row ? 'Update Journal Entry' : 'Add Journal Entry'}
                </Typography>

                <CompanyYear />

                <IconButton color="inherit" edge="start" onClick={onClose} tabIndex={-1}>
                  <Iconify icon="mingcute:close-line" />
                </IconButton>
              </Toolbar>

              <Divider sx={{ borderStyle: 'dashed' }} />
            </Grid>

            <Grid
              item
              container
              xs={12}
              md={12}
              sx={{ p: 2, justifyContent: 'space-between' }}
              columnSpacing={8}
              spacing={4}
            >
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                spacing={1}
                alignContent="start"
              >
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="v_no" label="Voucher No." size="small" autoFocus />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Controller
                      name="t_date"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DateField
                          defaultValue={new Date(field.value)}
                          timezone="Asia/Kolkata"
                          label="Voucher Date"
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          format="dd/MM/yyyy"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!error,
                              helperText: error?.message,
                              size: 'small',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                spacing={1}
                alignContent="start"
              >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="bill_no" label="Bill No." size="small" />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Controller
                    name="b_date"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <DateField
                        defaultValue={new Date(field.value)}
                        timezone="Asia/Kolkata"
                        label="Bill Date"
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        format="dd/MM/yyyy"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                            size: 'small',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item container xs={12} md={12} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <TableContainer
                  component={Paper}
                  sx={{ overflow: 'auto', maxHeight: '50vh', width: '98%', margin: 'auto' }}
                >
                  <Table
                    stickyHeader
                    aria-label="simple table"
                    sx={{
                      '& .MuiTableCell-sizeMedium': {
                        padding: '0px !important',
                      },
                      '& fieldset': { borderRadius: '0px', border: 'none' },
                      '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                      '& .MuiOutlinedInput-root': { padding: '0px !important' },
                      '& .autoComplete>div': { padding: '0px !important' },
                      '& .MuiTableCell-root': {
                        border: '0.01px solid #e9ecee',
                      },
                      '& .MuiTableCell-head': {
                        padding: '5px !important',
                        borderRight: '1px solid #d0d1d2 !important',
                      },
                      '& .MuiTableCell-head:last-child': {
                        borderRight: '0px !important',
                      },
                      '&:last-child td': {
                        borderColor: '#e9ecee !important',
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: 20, position: 'sticky', left: 0 }}>No</TableCell>
                        <TableCell sx={{ width: 60 }}>Credit / Debit</TableCell>
                        <TableCell sx={{ width: 400 }}>Account Name</TableCell>

                        <TableCell
                          sx={{ width: 100, textAlign: 'end', position: 'sticky', right: 0 }}
                        >
                          Credit
                        </TableCell>
                        <TableCell
                          sx={{ width: 100, textAlign: 'end', position: 'sticky', right: 0 }}
                        >
                          Debit
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {entry_details?.fields?.map((field, index) => (
                        <TableRow key={index}>
                          <TableCell
                            sx={{
                              textAlign: 'center',
                              position: 'sticky',
                              left: 0,
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.entry_details &&
                              errors?.entry_details[index] &&
                              errors?.entry_details[index]?.cb && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
                              autoHighlight={allValues.isHighligh}
                              fullWidth
                              freeSolo
                              size="small"
                              openOnFocus
                              onInputChange={(event, newValue) => {
                                setValue('isHighligh', newValue);
                              }}
                              onBlur={() => {
                                setValue('isHighligh', '');
                              }}
                              options={BALANCE_TYPE?.map((option) => option.name)}
                              getOptionLabel={(option) => option}
                              value={
                                BALANCE_TYPE?.find(
                                  (data) => data.id === allValues?.entry_details[index]?.cb
                                )?.name || ''
                              }
                              onChange={(event, newValue) => {
                                setValue(
                                  `entry_details[${index}].cb`,
                                  BALANCE_TYPE?.find((data) => data.name === newValue)?.id
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} name={`entry_details[${index}].cb`} />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={option}>
                                  {option}
                                </li>
                              )}
                              ListboxProps={{ ...bgColorAutocomplete }}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.entry_details &&
                              errors?.entry_details[index] &&
                              errors?.entry_details[index]?.accountId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
                              autoHighlight={allValues.isHighligh}
                              key={`${field.accountId}-${index}`}
                              openOnFocus
                              onInputChange={(event, newValue) => {
                                setValue('isHighligh', newValue);
                              }}
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                                setValue('isHighligh', '');
                                if (
                                  !allValues.entry_details[index].accountId &&
                                  !quickEditAccount.value
                                ) {
                                  document.getElementById(e.target.id).focus();
                                }
                              }}
                              fullWidth
                              freeSolo
                              size="small"
                              disableCloseOnSelect
                              options={
                                accountList.length
                                  ? accountList
                                  : [{ name: 'No Option', value: null }]
                              }
                              getOptionDisabled={(option) => option?.value === null}
                              groupBy={(option) => option?.a}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, newValue) => {
                                setaccountEditList(newValue);
                                setValue(`entry_details[${index}].accountId`, newValue?.id);
                              }}
                              value={
                                accountList?.find(
                                  (data) => data.id === allValues.entry_details[index].accountId
                                ) || row?.entry_details[index]?.account
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`entry_details[${index}].accountId`}
                                  className="autoComplete"
                                />
                              )}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem sx={{ padding: 0, margin: 0 }}>Account Name</ListItem>
                                    <ListItem
                                      sx={{
                                        padding: 0,
                                        margin: 0,
                                        justifyContent: 'end',
                                        width: '30%',
                                      }}
                                    >
                                      Balance
                                    </ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                  <GroupFooter component="li">
                                    <Button
                                      onClick={() => {
                                        setaccountEditList(null);
                                        console.log('setaccountEditList', accountEditList);
                                        quickEditAccount.onTrue();
                                      }}
                                    >
                                      Add
                                    </Button>

                                    {accountEditList?.id && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            quickEditAccount.onTrue();
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            confirm.onTrue();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    )}
                                  </GroupFooter>
                                </li>
                              )}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                  <ListItem
                                    sx={{
                                      padding: 0,
                                      margin: 0,
                                      justifyContent: 'end',
                                      width: '30%',
                                    }}
                                  >
                                    {option?.closingBalance
                                      ? fMoney.format(option?.closingBalance, { symbol: '₹ ' })
                                      : '-'}
                                  </ListItem>
                                </Box>
                              )}
                              ListboxProps={{ ...bgColorAutocomplete }}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.entry_details &&
                              errors?.entry_details[index] &&
                              errors?.entry_details[index]?.amount
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            {allValues.entry_details[index].cb === 1 && (
                              <RHFTextField
                                inputProps={{
                                  style: { textAlign: 'right' },
                                }}
                                onFocus={(e) => {
                                  setCurrentRowIndex(index);
                                }}
                                onBlur={(e) => {
                                  setCurrentRowIndex(null);
                                }}
                                name={`entry_details[${index}].amount`}
                                size="small"
                                type="text"
                                onChange={(e) => {
                                  const amount = e.target.value >= 0 && e.target.value;
                                  if (amount)
                                    setValue(`entry_details[${index}].amount`, parseFloat(amount));
                                  else setValue(`entry_details[${index}].amount`, null);
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.entry_details &&
                              errors?.entry_details[index] &&
                              errors?.entry_details[index]?.amount
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            {allValues.entry_details[index].cb === 2 && (
                              <RHFTextField
                                inputProps={{
                                  style: { textAlign: 'right' },
                                }}
                                onFocus={(e) => {
                                  setCurrentRowIndex(index);
                                }}
                                onBlur={(e) => {
                                  setCurrentRowIndex(null);
                                }}
                                name={`entry_details[${index}].amount`}
                                size="small"
                                type="text"
                                onChange={(e) => {
                                  const amount = e.target.value >= 0 && e.target.value;
                                  if (amount)
                                    setValue(`entry_details[${index}].amount`, parseFloat(amount));
                                  else setValue(`entry_details[${index}].amount`, null);
                                }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell align="center" colSpan={3}>
                          <b>Total</b>
                        </TableCell>
                        <TableCell
                          sx={{ width: 100, textAlign: 'end', position: 'sticky', right: 0 }}
                        >
                          <b>
                            {fMoney.format(
                              allValues.entry_details
                                .filter((x) => x.cb === 1)
                                ?.reduce((pre, next) => pre + next.amount || 0, 0),
                              { symbol: '₹ ' }
                            )}
                          </b>
                        </TableCell>
                        <TableCell
                          sx={{ width: 100, textAlign: 'end', position: 'sticky', right: 0 }}
                        >
                          <b>
                            {fMoney.format(
                              allValues.entry_details
                                .filter((x) => x.cb === 2)
                                ?.reduce((pre, next) => pre + next.amount || 0, 0),
                              { symbol: '₹ ' }
                            )}
                          </b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              md={12}
              sx={{
                bottom: 80,
                zIndex: 111111,
                p: 2,
                position: 'absolute',
                justifyContent: 'space-between',
                bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
              }}
              spacing={1}
            >
              <Grid item Container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <Grid item xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
                  <Typography variant="subtitle2" sx={{ ml: '10px' }}>
                    Narration
                  </Typography>
                  <RHFTextField name="narr" size="small" multiline rows={3} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Stack
            direction="row-reverse"
            alignItems="center"
            sx={{
              bottom: 0,
              zIndex: 111111,
              p: 3,
              position: 'absolute',
              justifyContent: 'space-between',
              width: '100%',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
            id="modelfooter"
          >
            <Tooltip title="(Ctrl + A)" arrow>
              <LoadingButton
                type="button"
                variant="contained"
                onClick={async () => {
                  const ct = allValues.entry_details
                    .filter((x) => x.cb === 1)
                    ?.reduce((pre, next) => pre + next.amount || 0, 0);
                  const dt = allValues.entry_details
                    .filter((x) => x.cb === 2)
                    ?.reduce((pre, next) => pre + next.amount || 0, 0);
                  if (ct > 0 && dt > 0 && ct === dt) {
                    setValue(
                      'entry_details',
                      allValues.entry_details.filter((x, index) => x.accountId && x.amount && x.cb)
                    );
                    await trigger();
                    onSubmit();
                  } else {
                    alert('Credit and debit amounts should be the same.');
                  }
                }}
                loading={isSubmitting}
              >
                {row ? 'Update' : 'Add'}
              </LoadingButton>
            </Tooltip>

            <Tooltip title="(Esc)" arrow>
              <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
                Cancel
              </Button>
            </Tooltip>

            <Button variant="soft" onClick={() => reset(values)} sx={{ mr: 2 }}>
              Reset
            </Button>

            <Stack direction="row" alignItems="center" flexGrow={1} spacing={2}>
              <Tooltip title="Add Product (Alt+A)" arrow>
                <IconButton color="primary" onClick={handleAdd}>
                  <Iconify icon="mingcute:add-line" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </FormProvider>
      </Dialog>

      {quickEditAccount.value && (
        <AUserQuickEditForm
          row={accountEditList || null}
          open={quickEditAccount.value}
          onClose={handleCloseAccount}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => accountEditList?.id && deleteAccountModel(accountEditList?.id)}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
