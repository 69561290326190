import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Unstable_Grid2';
import { DateField } from '@mui/x-date-pickers';
import {
  Autocomplete,
  Box,
  Divider,
  IconButton,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { create, fetcher } from 'src/utils/axios';
import { KEY } from 'src/_mock/constant';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import {
  assignNullToEmptyOrUndefinedValues,
  formatDate,
  useBgColorAutocomplete,
} from 'src/_mock/constant_funcation';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import FilterEnquiry from 'src/sections/work_shop/enquiry/FilterEnquiry';
import { useDebounce } from 'src/hooks/use-debounce';
import queryString from 'query-string';
import { useGetReasones } from '../work_shop/global';

// ----------------------------------------------------------------------

export default function FollowUpQuickEditForm({
  row,
  open,
  onClose,
  table,
  tableData,
  onlydisplay = 'true',
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [folloupData, setFolloupData] = useState([]);
  const [resonIndex, setResonIndex] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);

  const [secondReasonData, setsecondReasonData] = useState(null);

  const [focusedRowIndex, setFocusedRowIndex] = useState(null);
  const [isFileManagerVisible, setIsFileManagerVisible] = useState(false);

  const areasoneRef = useRef(null);
  const closeremarkRef = useRef(null);
  const saveButtonRef = useRef(null);

  const [productFilter, setProductFilter] = useState({
    modelCategory: [],
    selectedCategories: [],
    modelName: [],
    selectedModelName: [],
    ModelVariant: [],
    selectedModelVariant: [],
    modelColor: [],
    selectedModelColor: [],
    dseName: [],
    selectedDSEName: [],
    fromDate: new Date(),
    toDate: new Date(),
    search: null,
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    console.log(secondReasonData, 'secondReasonData');
  }, [secondReasonData]);

  const bgColorAutocomplete = useBgColorAutocomplete();

  const values = useMemo(
    () => ({
      fromDate: new Date(),
      toDate: new Date(),
      followsUp: [],
      areasoneMeta: {
        URL: 'automobile/reasones/',
        query: {
          limit: 10,
          name: '',
        },
        editRow: null,
      },
    }),
    []
  );

  const methods = useForm({
    values,
  });

  const {
    reset,
    watch,
    control,
    trigger,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  const allValues = watch();

  useEffect(() => {
    if (
      currentRowIndex >= 0 &&
      (allValues?.followsUp[currentRowIndex]?.afterday === 0 ||
        allValues?.followsUp[currentRowIndex]?.afterday === '0')
    ) {
      setResonIndex(currentRowIndex);
    } else {
      setResonIndex(null);
    }
  }, [currentRowIndex, allValues]);

  const debouncedReasones = useDebounce(allValues.areasoneMeta.name, 500);

  const reasones = useGetReasones(
    debouncedReasones ? allValues.areasoneMeta : allValues.areasoneMeta
  );

  const followsUpDetails = useFieldArray({
    control,
    name: 'followsUp',
  });

  const onSubmit = handleSubmit(async (payload) => {
    let followUpData = payload.followsUp;
    followUpData = assignNullToEmptyOrUndefinedValues(followUpData);

    try {
      const URL = 'automobile/followups/';
      const { success, show } = await create({
        url: URL,
        payload: followUpData.filter((x) => x.nextdate && x.afterday !== '' && x.afterday !== null),
      });
      if (success) {
        onClose();
        if (show) {
          enqueueSnackbar('Follow Up success!');
        }
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  const getAll = useCallback(
    async (apply = false) => {
      // if ((apply && isFileManagerVisible) || (!isFileManagerVisible && !apply)) {
      if (!isFileManagerVisible) {
        setIsLoading(true);

        try {
          let URL = 'automobile/followups/?';

          const query = {
            page: paginationModel.page + 1,
            limit: paginationModel.pageSize,
          };
          const categoryId = productFilter?.selectedCategories.map((x) => x.id).join(',');
          const modelId = productFilter?.selectedModelName.map((x) => x.id).join(',');
          const variantId = productFilter?.selectedModelVariant.map((x) => x.id).join(',');
          const colourId = productFilter?.selectedModelColor.map((x) => x.id).join(',');
          const DSEId = productFilter?.selectedDSEName.map((x) => x.id).join(',');
          const fromDate = productFilter?.fromDate;
          const toDate = productFilter?.toDate;
          if (categoryId) {
            // eslint-disable-next-line dot-notation
            query['amodcategoryId'] = categoryId;
          }
          if (modelId) {
            // eslint-disable-next-line dot-notation
            query['amodelId'] = modelId;
          }
          if (variantId) {
            // eslint-disable-next-line dot-notation
            query['avariantId'] = variantId;
          }
          if (colourId) {
            // eslint-disable-next-line dot-notation
            query['acolourId'] = colourId;
          }
          if (DSEId) {
            // eslint-disable-next-line dot-notation
            query['salesmanId'] = DSEId;
          }
          if (fromDate) {
            // eslint-disable-next-line dot-notation
            query['fromDate'] = formatDate(fromDate);
          }
          if (toDate) {
            // eslint-disable-next-line dot-notation
            query['toDate'] = formatDate(toDate);
          }

          if (row?.id) {
            URL += `aenquiryId=${row?.id}`;
          }

          URL += `${queryString.stringify(query)}&`;

          const { data } = await fetcher(URL);

          if (data) {
            setValue(
              'followsUp',
              data?.rows.map((x) => {
                if (x.followup) {
                  return {
                    id: x.followup.id,
                    aenquiryId: x.followup.aenquiryId,
                    afterday: x.afterday,
                    nextdate: x.followup.nextdate,
                    remarks: x.remarks,
                    followupId: x.id || null,
                    areasoneId: null,
                    closeremarkId: null,
                    closeremark: null,
                    areasone: null,
                  };
                }
                if (x.afterday === 0 || x.afterday === '0')
                  return {
                    id: null,
                    aenquiryId: x.aenquiryId,
                    afterday: x.afterday || 0,
                    nextdate: x.nextdate || null,
                    remarks: x.remarks || '',
                    followupId: x.id,
                    areasoneId: x?.aenquiry?.areasoneId,
                    closeremarkId: x?.aenquiry?.closeremarkId,
                    closeremark: x?.aenquiry?.closurremark,
                    areasone: x?.aenquiry?.areasone,
                  };
                return {
                  id: null,
                  aenquiryId: x.aenquiryId,
                  afterday: null,
                  nextdate: null,
                  remarks: '',
                  followupId: x.id,
                  areasoneId: null,
                  closeremarkId: null,
                  closeremark: null,
                  areasone: null,
                };
              })
            );
            setFolloupData(data?.rows);
            setIsLoading(false);
          }
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      }
    },
    [
      isFileManagerVisible,
      paginationModel.page,
      paginationModel.pageSize,
      productFilter?.fromDate,
      productFilter?.selectedCategories,
      productFilter?.selectedDSEName,
      productFilter?.selectedModelColor,
      productFilter?.selectedModelName,
      productFilter?.selectedModelVariant,
      productFilter?.toDate,
      row?.id,
      setValue,
    ]
  );

  // const dads = useCallback(
  //   async (fData, tData) => {
  //     setIsLoading(true);
  //     try {
  //       let URL = 'automobile/followups/?page=1&limit=50000&';
  //       if (row?.id) {
  //         URL += `aenquiryId=${row?.id}`;
  //       } else {
  //         URL += `&fromDate=${moment(fData).format('DD/MM/YYYY')}&toDate=${moment(tData).format(
  //           'DD/MM/YYYY'
  //         )}&`;
  //       }
  //       const { data } = await fetcher(URL);
  //       if (data) {
  //         setValue(
  //           'followsUp',
  //           data?.rows.map((x) => {
  //             if (x.followup) {
  //               return {
  //                 id: x.followup.id,
  //                 aenquiryId: x.followup.aenquiryId,
  //                 afterday: x.afterday,
  //                 nextdate: x.followup.nextdate,
  //                 remarks: x.remarks,
  //                 followupId: x.id || null,
  //                 areasoneId: null,
  //                 closeremarkId: null,
  //                 closeremark: null,
  //                 areasone: null,
  //               };
  //             }
  //             if (x.afterday === 0 || x.afterday === '0')
  //               return {
  //                 id: null,
  //                 aenquiryId: x.aenquiryId,
  //                 afterday: x.afterday || 0,
  //                 nextdate: x.nextdate || null,
  //                 remarks: x.remarks || '',
  //                 followupId: x.id,
  //                 areasoneId: x?.aenquiry?.areasoneId,
  //                 closeremarkId: x?.aenquiry?.closeremarkId,
  //                 closeremark: x?.aenquiry?.closurremark,
  //                 areasone: x?.aenquiry?.areasone,
  //               };
  //             return {
  //               id: null,
  //               aenquiryId: x.aenquiryId,
  //               afterday: null,
  //               nextdate: null,
  //               remarks: '',
  //               followupId: x.id,
  //               areasoneId: null,
  //               closeremarkId: null,
  //               closeremark: null,
  //               areasone: null,
  //             };
  //           })
  //         );
  //         setFolloupData(data?.rows);
  //       }
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.error(error);
  //       setIsLoading(false);
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   },
  //   [setValue, row?.id]
  // );

  useEffect(() => {
    if (row?.id) getAll();
  }, [getAll, row?.id]);

  useEffect(() => {
    if (resonIndex !== null) {
      if (allValues.followsUp[resonIndex]?.afterday === '0') {
        areasoneRef.current.focus(); // Focus the areasoneId field when afterday is 0
      }
    }
  }, [resonIndex, allValues.followsUp]);

  useEffect(() => {
    // Check if the closeremarkId needs focus, i.e., after areasoneId is selected
    if (resonIndex !== null && allValues.followsUp[resonIndex]?.areasoneId) {
      closeremarkRef.current.focus();
    }
  }, [allValues.followsUp, resonIndex]);

  // Optionally, manage moving focus to the Save button after closeremarkId is filled
  useEffect(() => {
    if (resonIndex !== null && allValues.followsUp[resonIndex]?.closeremarkId) {
      saveButtonRef.current.focus();
    }
  }, [allValues.followsUp, resonIndex]);

  useEffect(() => {
    if (!row?.id && (allValues.fromDate || allValues.toDate)) {
      const fData = new Date(allValues.fromDate);
      const tData = new Date(allValues.toDate);
      getAll(fData, tData);
    }
  }, [allValues.fromDate, allValues.toDate, getAll, row?.id]);

  useEffect(() => {
    if (!row?.id) getAll();
    reset();
  }, [getAll, open, reset, row?.id]);

  useEffect(() => {
    if (resonIndex === null) {
      document.getElementById(`close-reasons_${resonIndex}`)?.focus();
    }
  }, [resonIndex]);

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };

  useEventListener('keydown', handleKeyDown);

  const toggleFileManager = () => {
    setIsFileManagerVisible(true);
  };

  return (
    <>
      <Dialog fullScreen open={open} onKeyDown={handleKeyDown}>
        <FormProvider methods={methods}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                Enquiry Followup Entry
              </Typography>
              <IconButton color="inherit" edge="start" tabIndex={-1} onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>
          <DialogContent>
            <Grid
              container
              sx={{
                marginTop: 2,
                mb: 2,
              }}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              {/* {onlydisplay && (
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  spacing={2}
                  alignContent="start"
                >
                  <Grid item xs={12} sm={4} md={3} lg={3} xl={3} xxl={3}>
                    <Controller
                      name="fromDate"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DateField
                          value={field.value ? new Date(field.value) : null}
                          timezone="Asia/Kolkata"
                          onChange={(newValue) => {
                            field.onChange(newValue);
                            // setValue('toDate', newValue);
                          }}
                          autoFocus
                          format="dd/MM/yyyy"
                          label="From Date"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!error,
                              helperText: error?.message,
                              size: 'small',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={3} xl={3} xxl={3}>
                    <Controller
                      name="toDate"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DateField
                          value={field.value ? new Date(field.value) : null}
                          timezone="Asia/Kolkata"
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          format="dd/MM/yyyy"
                          label="To Date"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!error,
                              helperText: error?.message,
                              size: 'small',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )} */}

              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                xxl={3}
                alignContent="start"
                sx={{ visibility: resonIndex === null && 'hidden' }}
              >
                <Autocomplete
                  autoHighlight
                  fullWidth
                  size="small"
                  options={Array.isArray(reasones?.rows) ? reasones?.rows : []}
                  groupBy={(option) => option?.a}
                  getOptionLabel={(option) => option.name}
                  disabled={resonIndex === null}
                  // openOnFocus
                  // onBlur={() => {
                  //   if (resonIndex === folloupData.length - 1)
                  //     document.getElementById(`save`)?.focus();
                  //   else document.getElementById(`remarks_${resonIndex + 1}`)?.focus();
                  // }}
                  filterOptions={(options, state) => {
                    if (reasones?.rows?.length) {
                      const filteredZones = reasones?.rows?.filter((zone) =>
                        zone.name?.toLowerCase().includes(state.inputValue?.toLowerCase())
                      );
                      return filteredZones?.length
                        ? filteredZones
                        : [{ name: 'No matching options', value: null }];
                    }
                    return [{ name: 'No Options', value: null }];
                  }}
                  onChange={(event, newValue) => {
                    setsecondReasonData(newValue);
                    setValue(`followsUp[${resonIndex}].areasoneId`, newValue?.id);
                    setValue(`followsUp[${resonIndex}].areasone`, newValue);
                  }}
                  value={resonIndex !== null ? allValues?.followsUp[resonIndex]?.areasone : null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={areasoneRef}
                      label="Select Close Reason"
                      name={`followsUp[${resonIndex}].areasoneId`}
                      error={errors?.areasoneId && !allValues?.areasoneId}
                    />
                  )}
                  id={`close-reasons_${resonIndex}`}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.id}>
                      <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                    </Box>
                  )}
                  ListboxProps={{ ...bgColorAutocomplete }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2.8}
                lg={2.8}
                xl={2.8}
                xxl={2.8}
                alignContent="start"
                sx={{ ml: 1, visibility: resonIndex === null && 'hidden' }}
              >
                <Autocomplete
                  autoHighlight
                  fullWidth
                  size="small"
                  options={
                    Array.isArray(secondReasonData?.closurremarks)
                      ? secondReasonData?.closurremarks
                      : []
                  }
                  groupBy={(option) => option?.a}
                  getOptionLabel={(option) => option.name}
                  disabled={resonIndex === null}
                  // openOnFocus
                  // onBlur={() => {
                  //   if (resonIndex === folloupData.length - 1)
                  //     document.getElementById(`save`)?.focus();
                  //   else document.getElementById(`remarks_${resonIndex + 1}`)?.focus();
                  // }}
                  filterOptions={(options, state) => {
                    if (secondReasonData?.closurremarks?.length) {
                      const filteredZones = secondReasonData?.closurremarks?.filter((zone) =>
                        zone.name?.toLowerCase().includes(state.inputValue?.toLowerCase())
                      );
                      return filteredZones?.length
                        ? filteredZones
                        : [{ name: 'No matching options', value: null }];
                    }
                    return [{ name: 'No Options', value: null }];
                  }}
                  onChange={(event, newValue) => {
                    setValue(`followsUp[${resonIndex}].closeremarkId`, newValue?.id);
                    setValue(`followsUp[${resonIndex}].closeremark`, newValue);
                  }}
                  value={resonIndex !== null ? allValues?.followsUp[resonIndex]?.closeremark : null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={closeremarkRef}
                      label="Reason for closure"
                      name={`followsUp[${resonIndex}].closeremarkId`}
                      error={errors?.closeremarkId && !allValues?.closeremarkId}
                    />
                  )}
                  id={`close-reasons_${resonIndex}`}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.id}>
                      <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                    </Box>
                  )}
                  ListboxProps={{ ...bgColorAutocomplete }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} sx={{ textAlign: 'right' }}>
                <Tooltip title="FollowUp Filter" arrow>
                  <IconButton
                    color="inherit"
                    edge="start"
                    tabIndex={-1}
                    onClick={toggleFileManager}
                  >
                    <Iconify icon="fluent:search-12-filled" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <TableContainer
                component={Paper}
                sx={{ overflow: 'auto', minHeight: '55vh', width: '100%', margin: 'auto' }}
              >
                <Table
                  stickyHeader
                  aria-label="simple table"
                  sx={{
                    '& .MuiTableCell-sizeMedium': {
                      padding: '0px !important',
                    },
                    '& fieldset': { borderRadius: '0px', border: 'none' },
                    '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                    '& .MuiOutlinedInput-root': { padding: '0px !important' },
                    '& .autoComplete>div': { padding: '0px !important' },
                    '& .MuiTableCell-root': {
                      border: '0.01px solid #e9ecee',
                    },
                    '& .MuiTableCell-head': {
                      padding: '5px !important',
                      borderRight: '1px solid #d0d1d2 !important',
                    },
                    '& .MuiTableCell-footer': {
                      padding: '5px !important',
                    },
                    '& .MuiTableCell-head:last-child': {
                      borderRight: '0px !important',
                    },
                    '&:last-child td': {
                      borderColor: '#e9ecee !important',
                    },
                  }}
                >
                  <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
                    <TableRow>
                      <TableCell sx={{ width: 20, textAlign: 'center' }}>No</TableCell>
                      <TableCell sx={{ width: 50, textAlign: 'center' }}>Enq. No</TableCell>
                      <TableCell sx={{ width: 50, textAlign: 'center' }}>Enq. Date</TableCell>
                      <TableCell sx={{ width: 50, textAlign: 'center' }}>FollowUp Date</TableCell>
                      <TableCell sx={{ width: 150, textAlign: 'center' }}>Name</TableCell>
                      <TableCell sx={{ width: 70, textAlign: 'center' }}>Mobile No.</TableCell>
                      <TableCell sx={{ width: 150, textAlign: 'center' }}>Remarks</TableCell>
                      <TableCell sx={{ width: 5, textAlign: 'center' }}>After Day</TableCell>
                      <TableCell sx={{ width: 70, textAlign: 'center' }}>
                        Next Followup date
                      </TableCell>
                      <TableCell sx={{ width: 70, textAlign: 'center' }}>Salesman</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {followsUpDetails?.fields?.length > 0 ? (
                      followsUpDetails.fields?.map((field, index) => (
                        <TableRow
                          key={index}
                          onMouseEnter={() => setFocusedRowIndex(index)}
                          onMouseLeave={() => setFocusedRowIndex(null)}
                          onFocus={() => setFocusedRowIndex(index)}
                          tabIndex={0}
                          onClick={() => {
                            setCurrentRowIndex(index);
                          }}
                          sx={{ bgcolor: focusedRowIndex === index && 'primary.light' }}
                        >
                          <TableCell
                            sx={{
                              textAlign: 'center',
                              position: 'sticky',
                              left: 0,
                            }}
                          >
                            {index + 1}
                          </TableCell>

                          <TableCell
                            sx={{ textAlign: 'center', position: 'sticky', right: 0, index: 9 }}
                          >
                            {folloupData[index]?.aenquiry.id || '-'}
                          </TableCell>
                          <TableCell
                            sx={{ textAlign: 'center', position: 'sticky', right: 0, index: 9 }}
                          >
                            {formatDate(folloupData[index]?.aenquiry.date) || '-'}
                          </TableCell>
                          <TableCell
                            sx={{ textAlign: 'center', position: 'sticky', right: 0, index: 9 }}
                          >
                            {formatDate(folloupData[index]?.nextdate) || '-'}
                          </TableCell>
                          <TableCell
                            sx={{ textAlign: 'center', position: 'sticky', right: 0, index: 9 }}
                          >
                            {folloupData[index]?.aenquiry.customer?.firstname || '-'}
                          </TableCell>
                          <TableCell
                            sx={{ textAlign: 'center', position: 'sticky', right: 0, index: 9 }}
                          >
                            {folloupData[index]?.aenquiry?.customer?.mobile1 || '-'}
                          </TableCell>
                          <TableCell
                            sx={{ textAlign: 'center', position: 'sticky', right: 0, index: 9 }}
                          >
                            <RHFTextField
                              name={`followsUp[${index}].remarks`}
                              type="text"
                              size="small"
                              onFocus={() => {
                                setCurrentRowIndex(index);
                              }}
                              value={allValues?.followsUp[index]?.remarks || ''}
                              onChange={(e) => {
                                setValue(`followsUp.${index}.remarks`, e.target.value);
                              }}
                              id={`remarks_${index}`}
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  size: 'small',
                                  variant: 'standard',
                                },
                              }}
                              disabled={!onlydisplay}
                            />
                          </TableCell>
                          <TableCell
                            sx={{ textAlign: 'center', position: 'sticky', right: 0, index: 9 }}
                          >
                            <RHFTextField
                              name={`followsUp[${index}].afterday`}
                              type="text"
                              size="small"
                              onFocus={() => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={() => {
                                if (
                                  allValues.followsUp[index].areasoneId != null &&
                                  allValues.followsUp[index].afterday === '0'
                                )
                                  setResonIndex(null);
                              }}
                              value={allValues.followsUp[index]?.afterday}
                              onChange={(e) => {
                                setValue(`followsUp.${index}.areasoneId`, null);
                                if (e.target.value === 0 || e.target.value === '0') {
                                  setResonIndex(index);
                                } else {
                                  setResonIndex(null);
                                }
                                if (e.target.value !== allValues?.afterday) {
                                  setValue(`followsUp[${index}].afterday`, e.target.value);
                                  if (e.target.value)
                                    setValue(
                                      `followsUp[${index}].nextdate`,
                                      moment().add('day', e.target.value)
                                    );
                                  else setValue(`followsUp[${index}].nextdate`, moment());
                                }
                              }}
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  size: 'small',
                                  variant: 'standard',
                                },
                              }}
                              disabled={!onlydisplay}
                            />
                          </TableCell>
                          <TableCell
                            sx={{ textAlign: 'center', position: 'sticky', right: 0, index: 9 }}
                          >
                            <Controller
                              name={`followsUp[${index}].nextdate`}
                              control={control}
                              onFocus={() => {
                                setCurrentRowIndex(index);
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <DateField
                                  value={field.value ? new Date(field.value) : null}
                                  timezone="Asia/Kolkata"
                                  onChange={(newValue) => {
                                    field.onChange(newValue);
                                    // Calculate the difference in days between newValue and the current date.
                                    const currentDate = moment(); // This gets the current date.
                                    const selectedDate = moment(newValue); // Convert the new value to a Moment.js object.
                                    const differenceInDays = selectedDate.diff(currentDate, 'days');

                                    // Now, set the value of "afterday" to the calculated difference in days.
                                    // Assume `setValue` is available in your component's props or context.
                                    if (differenceInDays !== allValues.afterday)
                                      setValue(
                                        `followsUp[${index}].afterday`,
                                        differenceInDays + 1
                                      );
                                  }}
                                  format="dd/MM/yyyy"
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      error: !!error,
                                      helperText: error?.message,
                                      size: 'small',
                                    },
                                  }}
                                  disabled={!onlydisplay}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={{ textAlign: 'center', position: 'sticky', right: 0, index: 9 }}
                          >
                            {folloupData[index]?.aenquiry?.salesman?.name || '-'}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          sx={{
                            textAlign: 'center',
                            position: 'sticky',
                            left: 0,
                          }}
                          colSpan={10}
                        >
                          Data not found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {onlydisplay && (
              <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Grid item container xs={6}>
                  {folloupData[currentRowIndex]?.aenquiry?.afinancesId && (
                    <Grid item xs={12}>
                      Financier Bank :{' '}
                      <strong>{folloupData[currentRowIndex]?.aenquiry?.afinances?.name}</strong>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    Product Name :{' '}
                    <strong>
                      {console.log(folloupData[currentRowIndex], 'KISAHNNNNNNNNNNN')}{' '}
                      {folloupData[currentRowIndex]?.aenquiry?.avariant?.name &&
                        folloupData[currentRowIndex]?.aenquiry?.avariant?.name}
                    </strong>
                  </Grid>
                  <Grid item xs={12}>
                    Remarks :{' '}
                    <strong>
                      {' '}
                      {folloupData[currentRowIndex]?.aenquiry?.remarks &&
                        folloupData[currentRowIndex]?.aenquiry?.remarks}
                    </strong>
                  </Grid>
                </Grid>
                <Grid item container xs={6}>
                  <Grid item xs={3}>
                    Previous Date :
                  </Grid>
                  <Grid item xs={9}>
                    <strong>
                      {' '}
                      {folloupData[currentRowIndex]?.preFollowup?.nextdate &&
                        formatDate(folloupData[currentRowIndex]?.preFollowup?.nextdate)}
                    </strong>
                  </Grid>
                  <Grid item xs={3}>
                    Previous Remarks :
                  </Grid>
                  <Grid item xs={9}>
                    <strong>{folloupData[currentRowIndex]?.preFollowup?.remarks}</strong>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </DialogContent>

          {onlydisplay && (
            <Stack
              direction="row-reverse"
              alignItems="center"
              sx={{
                bottom: 0,
                zIndex: 111111,
                p: 3,
                position: 'fixed',
                justifyContent: 'space-between',
                width: '100%',
                bgcolor: (theme) => alpha(theme.palette.background.default, 1),
              }}
            >
              <Tooltip title="(Ctrl + A)" arrow>
                <LoadingButton
                  ref={saveButtonRef}
                  type="button"
                  variant="contained"
                  loading={isLoading}
                  onClick={async () => {
                    onSubmit(allValues);
                  }}
                >
                  Save
                </LoadingButton>
              </Tooltip>

              <Tooltip title="(Esc)" arrow>
                <Button variant="outlined" sx={{ mr: '10px' }} onClick={onClose}>
                  Cancel
                </Button>
              </Tooltip>

              <Grid flexGrow={1} spacing={2} />
            </Stack>
          )}
        </FormProvider>
      </Dialog>

      {isFileManagerVisible && (
        <FilterEnquiry
          isFileManagerVisible={isFileManagerVisible}
          setIsFileManagerVisible={setIsFileManagerVisible}
          productFilter={productFilter}
          setProductFilter={setProductFilter}
          getAll={getAll}
        />
      )}
    </>
  );
}

FollowUpQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
  onlydisplay: PropTypes.bool,
};
