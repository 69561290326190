import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect, useContext, useMemo } from 'react';
// @mui
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import queryString from 'query-string';
import { useSnackbar } from 'src/components/snackbar';
import { useDebounce } from 'src/hooks/use-debounce';
import { useTable } from 'src/components/table';
import { bank_cash_report_formate, report_periods } from 'src/_mock/constant';
//
import { fMoney } from 'src/_mock/constant_funcation';
import { AuthContext } from 'src/auth/context/jwt';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import { fetcher, destroy } from 'src/utils/axios';
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import NotFound from 'src/sections/error/notfound-invoice';
import Iconify from 'src/components/iconify';
// eslint-disable-next-line import/no-extraneous-dependencies
import html2canvas from 'html2canvas';
// eslint-disable-next-line import/no-extraneous-dependencies
import { jsPDF } from 'jspdf';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as XLSX from 'xlsx';

const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
};

// ----------------------------------------------------------------------

export default function ListView({ reportsDetails }) {
  const table = useTable();
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();
  const { user } = useContext(AuthContext);
  const startDate = moment(user?.co_year?.sdate);
  const endDate = moment(user?.co_year?.edate);

  // Format the dates in the desired format
  const formattedStartDate = startDate.format('DD/MM/YYYY');
  const formattedEndDate = endDate.format('DD/MM/YYYY');

  // Convert formattedStartDate and formattedEndDate back to moment objects for comparison
  const startDateMoment = moment(formattedStartDate, 'DD/MM/YYYY');
  const endDateMoment = moment(formattedEndDate, 'DD/MM/YYYY');

  const [information, setData] = useState({
    ac_groupId: null,
    accountId: null,
    formate: '2',
    period: '1',
    fdate: startDateMoment,
    tdate: endDateMoment,
    accountList: [],
  });
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [columns, setColumns] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [ac_groupId, setAc_groupId] = useState(0);
  const [last_bal, setLast_bal] = useState(0);

  const [filters, setFilters] = useState(defaultFilters);

  const debouncedQuery = useDebounce(filters);

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = count === 0;

  const { page } = table;
  const limit = table.rowsPerPage;

  useEffect(() => {
    if (reportsDetails) {
      setAc_groupId(reportsDetails?.cond?.value);
    }
  }, [reportsDetails]);

  useEffect(() => {
    if (information.formate === '1') setColumns([1, 2, 3, 4, 5, 6, 7]);
    if (information.formate === '2') setColumns([1, 2, 3, 4, 5, 6, 7, 8, 9]);
  }, [information.formate]);
  const cash_ban = reportsDetails?.cond?.label;
  const isCash = cash_ban?.toLowerCase() === 'cash';
  const addReportRecoed = useCallback(
    (finalData, Balancedate, op_bal, Transactions) => {
      finalData.push(
        <tr>
          <td>{moment(Balancedate, 'YYYY-MM-DD').format('DD-MM-YYYY ddd').toUpperCase()}</td>
          <td />
          <td style={{ textAlign: 'left', width: '50%' }}>Balance B/f</td>
          {information.formate === '1' ? (
            <>
              <td style={{ textAlign: 'right' }}>{fMoney.format(op_bal, { symbol: '₹ ' })}</td>
              <td>DB </td>
            </>
          ) : (
            <>
              <td />
              <td />
            </>
          )}

          <td />
          <td />
          {information.formate === '2' && (
            <>
              {' '}
              <td style={{ textAlign: 'right' }}>{fMoney.format(op_bal, { symbol: '₹ ' })}</td>
              <td>DB </td>
            </>
          )}
        </tr>
      );
      let totalCredit = 0;
      let totalDebit = 0;
      let restBalance = op_bal;
      // eslint-disable-next-line no-loop-func
      Transactions.forEach((x) => {
        if (reportsDetails?.name === 'Ledger Report') {
          x.entry_details.forEach((detail) => {
            if (detail.accountId === information.accountId) {
              // For t_type = 1,2,3,4,5,6 and cb = 2, add to totalDebit
              // For t_type = 8,9 and cb = 1, also add to totalDebit
              if (
                ([1, 2, 3, 4, 5, 6].includes(x.t_type) && detail.cb === 2) ||
                ([8, 9].includes(x.t_type) && detail.cb === 1)
              ) {
                totalDebit += x.amount;
              }
              // For t_type = 1,2,3,4,5,6 and cb = 1, add to totalCredit
              // For t_type = 8,9 and cb = 2, also add to totalCredit
              if (
                ([1, 2, 3, 4, 5, 6].includes(x.t_type) && detail.cb === 1) ||
                ([8, 9].includes(x.t_type) && detail.cb === 2)
              ) {
                totalCredit += x.amount;
              }
            }
          });
        } else {
          if (x.entry_details.find((x) => x.accountId === information.accountId)?.cb === 2) {
            totalDebit += x.amount;
          }
          if (x.entry_details.find((x) => x.accountId === information.accountId)?.cb === 1) {
            totalCredit += x.amount;
          }
        }
        let receiptAmount = 0;
        let paymentAmount = 0;
        if (reportsDetails?.name === 'Ledger Report') {
          receiptAmount =
            ([1, 2, 3, 4, 5, 6].includes(x.t_type) &&
              x.entry_details.find((detail) => detail.accountId === information.accountId)?.cb ===
              1) ||
              ([8, 9].includes(x.t_type) &&
                x.entry_details.find((detail) => detail.accountId === information.accountId)?.cb ===
                2)
              ? x?.amount
              : 0;
          paymentAmount =
            ([1, 2, 3, 4, 5, 6].includes(x.t_type) &&
              x.entry_details.find((detail) => detail.accountId === information.accountId)?.cb ===
              2) ||
              ([8, 9].includes(x.t_type) &&
                x.entry_details.find((detail) => detail.accountId === information.accountId)?.cb ===
                1)
              ? x?.amount
              : 0;
        } else {
          receiptAmount =
            x.entry_details.find((x) => x.accountId === information.accountId)?.cb === 2
              ? x?.amount
              : 0;
          paymentAmount =
            x.entry_details.find((x) => x.accountId === information.accountId)?.cb === 1
              ? x?.amount
              : 0;
        }
        restBalance += paymentAmount - receiptAmount;
        finalData.push(
          <tr>
            <td>{moment(x?.dt, 'YYYY-MM-DD').format('DD-MM-YYYY ddd').toUpperCase()}</td>
            <td style={{ textAlign: 'right', padding: '0px 20px' }}>{x.v_no}</td>
            <td style={{ textAlign: 'left', width: '50%' }}>
              <Stack direction="row" justifyContent="space-between">
                <div>
                  {
                    x.entry_details?.find((x) => x.accountId !== information.accountId)?.account
                      ?.name
                  }
                </div>
                <div style={{ marginRight: '40px' }}>
                  {x.entry_details?.find((x) => x.ac_no)?.ac_no}
                </div>
              </Stack>
            </td>
            {reportsDetails?.name === 'Ledger Report' ? (
              <>
                {' '}
                <td style={{ textAlign: 'right' }}>
                  {receiptAmount ? fMoney.format(receiptAmount, { symbol: '₹ ' }) : ''}
                </td>
                <td> </td>
                <td style={{ textAlign: 'right' }}>
                  {paymentAmount ? fMoney.format(paymentAmount, { symbol: '₹ ' }) : ''}
                </td>
              </>
            ) : (
              <>
                <td style={{ textAlign: 'right' }}>
                  {' '}
                  {x.entry_details.find((x) => x.accountId === information.accountId)?.cb === 1
                    ? fMoney.format(x?.amount, { symbol: '₹ ' })
                    : ''}
                </td>
                <td> </td>
                <td style={{ textAlign: 'right' }}>
                  {x.entry_details.find((x) => x.accountId === information.accountId)?.cb === 2
                    ? fMoney.format(x?.amount, { symbol: '₹ ' })
                    : ''}
                </td>
              </>
            )}

            <td> </td>
            {information.formate === '2' ? (
              <>
                <td style={{ textAlign: 'right' }}>
                  {fMoney.format(restBalance, { symbol: '₹ ' })}
                </td>
                <td>DB </td>
              </>
            ) : (
              <>
                <td />
                <td />
              </>
            )}
          </tr>
        );
        finalData.push(
          <tr>
            <td />
            <td />
            <td style={{ textAlign: 'left', width: '50%' }}>{x.narr}</td>
            <td />
            <td />
            <td />
            <td />
            {information.formate === '2' && (
              <>
                <td />
                <td />
              </>
            )}
          </tr>
        );

        Balancedate = x?.dt;
      });
      finalData.push(
        <tr>
          <td />
          <td />
          <td />
          <td style={{ borderBottom: '1px dashed black' }} />
          <td style={{ borderBottom: '1px dashed black' }} />
          <td style={{ borderBottom: '1px dashed black' }} />
          <td style={{ borderBottom: '1px dashed black' }} />
          {information.formate === '2' && (
            <>
              <td style={{ borderBottom: '1px dashed black' }} />
              <td style={{ borderBottom: '1px dashed black' }} />
            </>
          )}
        </tr>
      );
      finalData.push(
        <tr>
          <td />
          <td />
          <td />
          <td style={{ textAlign: 'right' }}>
            {fMoney.format(op_bal + totalCredit, { symbol: '₹ ' })}
          </td>
          <td />
          <td style={{ textAlign: 'right' }}>{fMoney.format(totalDebit, { symbol: '₹ ' })}</td>
          <td />
          {information.formate === '2' && (
            <>
              <td />
              <td />
            </>
          )}
        </tr>
      );
      finalData.push(
        <tr>
          <td />
          <td />
          <td style={{ textAlign: 'left', width: '50%' }}>Closing Balance</td>
          <td />
          <td />
          <td style={{ textAlign: 'right' }}>
            {fMoney.format(op_bal + totalCredit - totalDebit, { symbol: '₹ ' })}
          </td>
          <td>{op_bal + totalCredit - totalDebit >= 0 ? 'DB' : 'CR'}</td>
          {information.formate === '2' && (
            <>
              <td />
              <td />
            </>
          )}
        </tr>
      );

      finalData.push(
        <tr>
          <td style={{ borderBottom: '1px dashed black' }}>
            {moment(Balancedate, 'YYYY-MM-DD').format('DD-MM-YYYY ddd').toUpperCase()}
          </td>
          <td style={{ borderBottom: '1px dashed black' }} />
          <td style={{ textAlign: 'left', width: '50%', borderBottom: '1px dashed black' }} />
          <td style={{ borderBottom: '1px dashed black', textAlign: 'right' }}>
            {fMoney.format(op_bal + totalCredit - totalDebit, { symbol: '₹ ' })}
          </td>
          <td style={{ borderBottom: '1px dashed black' }} />
          <td style={{ borderBottom: '1px dashed black', textAlign: 'right' }}>
            {fMoney.format(op_bal + totalCredit - totalDebit, { symbol: '₹ ' })}
          </td>
          <td style={{ borderBottom: '1px dashed black' }} />
          {information.formate === '2' && (
            <>
              <td style={{ borderBottom: '1px dashed black' }} />
              <td style={{ borderBottom: '1px dashed black' }} />
            </>
          )}
        </tr>
      );
      op_bal = op_bal + totalCredit - totalDebit;
      return { op_bal, Balancedate };
    },
    [information.accountId, information.formate, reportsDetails?.name]
  );
  const RecPayments = useCallback(() => {
    const tableData1 = tableData.sort((a, b) => a - b);
    const dateMap = new Set();

    tableData1.map((x) => dateMap.add(x?.dt));

    const datArray = dateMap.values;
    const finalData = [];

    let op_bal = last_bal;
    let Balancedate = information.fdate;

    switch (information.period) {
      case '1': {
        op_bal = last_bal;
        dateMap.forEach((z) => {
          const Transactions = tableData1.filter((y) => y?.dt === z);
          const data = addReportRecoed(finalData, Balancedate, op_bal, Transactions);
          op_bal = data.op_bal;
          Balancedate = data.Balancedate;
        });
        return finalData;
      }
      case '2': {
        op_bal = last_bal;
        let Balancedate = information.fdate;
        const startOfWeek = moment(information.fdate).startOf('isoWeek');
        const endOfWeek = moment(startOfWeek).endOf('isoWeek');

        while (startOfWeek <= moment(tableData1[tableData1.length - 1]?.dt)) {
          const Transactions = tableData1.filter(
            (x) =>
              moment(x?.dt).isSameOrAfter(startOfWeek) && moment(x?.dt).isSameOrBefore(endOfWeek)
          );
          if (Transactions.length) {
            const data = addReportRecoed(finalData, Balancedate, op_bal, Transactions);
            op_bal = data.op_bal;
            Balancedate = data.Balancedate;
          }
          startOfWeek.add(1, 'weeks');
          endOfWeek.add(1, 'weeks');
        }

        return finalData;
      }
      case '3': {
        op_bal = last_bal;
        let Balancedate = information.fdate;
        const startOfMonth = moment(information.fdate).startOf('month');
        const endOfMonth = moment(startOfMonth).endOf('month');

        while (startOfMonth <= moment(tableData1[tableData1.length - 1]?.dt)) {
          const Transactions = tableData1.filter(
            (x) =>
              moment(x?.dt).isSameOrAfter(startOfMonth) && moment(x?.dt).isSameOrBefore(endOfMonth)
          );
          if (Transactions.length) {
            const data = addReportRecoed(finalData, Balancedate, op_bal, Transactions);
            op_bal = data.op_bal;
            Balancedate = data.Balancedate;
          }
          startOfMonth.add(1, 'months');
          endOfMonth.add(1, 'months');
        }

        return finalData;
      }
      case '4': {
        op_bal = last_bal;
        let Balancedate = information.fdate;
        const startOfQuarter = moment(information.fdate).startOf('quarter');
        const endOfQuarter = moment(startOfQuarter).endOf('quarter');

        while (startOfQuarter <= moment(tableData1[tableData1.length - 1]?.dt)) {
          const Transactions = tableData1.filter(
            (x) =>
              moment(x?.dt).isSameOrAfter(startOfQuarter) &&
              moment(x?.dt).isSameOrBefore(endOfQuarter)
          );
          if (Transactions.length) {
            const data = addReportRecoed(finalData, Balancedate, op_bal, Transactions);
            op_bal = data.op_bal;
            Balancedate = data.Balancedate;
          }
          startOfQuarter.add(1, 'quarters');
          endOfQuarter.add(1, 'quarters');
        }

        return finalData;
      }
      case '5': {
        op_bal = last_bal;
        let Balancedate = information.fdate;
        const startOfYear = moment(information.fdate).startOf('year');
        const endOfYear = moment(startOfYear).endOf('year');

        while (startOfYear <= moment(tableData1[tableData1.length - 1]?.dt)) {
          const Transactions = tableData1.filter(
            (x) =>
              moment(x?.dt).isSameOrAfter(startOfYear) && moment(x?.dt).isSameOrBefore(endOfYear)
          );
          if (Transactions.length) {
            const data = addReportRecoed(finalData, Balancedate, op_bal, Transactions);
            op_bal = data.op_bal;
            Balancedate = data.Balancedate;
          }
          startOfYear.add(1, 'years');
          endOfYear.add(1, 'years');
        }

        return finalData;
      }
      default:
        tableData1.forEach((x) => {
          finalData.push(
            <tr>
              <td>{moment(x?.dt, 'YYYY-MM-DD').format('DD-MM-YYYY ddd').toUpperCase()}</td>
              <td style={{ textAlign: 'right', width: '6%' }}>{x.v_no}</td>
              <td style={{ textAlign: 'left', width: '50%' }}>
                {x.entry_details?.find((x) => x.order === 2)?.account?.name}
              </td>
              <td style={{ textAlign: 'right' }}>
                {' '}
                {x.entry_details.find((x) => x.accountId === information.accountId)?.cb === 1
                  ? fMoney.format(x?.amount, { symbol: '₹ ' })
                  : ''}
              </td>
              <td> </td>
              <td style={{ textAlign: 'right' }}>
                {x.entry_details.find((x) => x.accountId === information.accountId)?.cb === 2
                  ? fMoney.format(x?.amount, { symbol: '₹ ' })
                  : ''}
              </td>
              <td> </td>
            </tr>
          );
        });
        return finalData;
    }
  }, [
    addReportRecoed,
    information.accountId,
    information.fdate,
    information.period,
    last_bal,
    tableData,
  ]);

  const getAll = useCallback(async () => {
    try {
      let URL = 'entries/';

      const t_type = reportsDetails?.cond?.t_type;
      if (t_type && information.fdate && information.tdate && information.accountId) {
        URL += `?page=${page + 1}&limit=${limit}&t_type=${t_type}&fdate=${information.fdate
          }&tdate=${information.tdate}&accountId=${information.accountId}&asc=id&`;
        if (Object.keys(debouncedQuery).length) {
          const nefil = { ...debouncedQuery };
          if (debouncedQuery.status === 'all') {
            delete nefil.status;
          }
          URL += queryString.stringify(nefil);
        }

        const { data } = await fetcher(URL);
        if (data) {
          setCount(data?.count);
          setTableData(data?.rows);
          setLast_bal(data?.last_bal);
        }
      }
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ac_groupId,
    debouncedQuery,
    information.accountId,
    information.fdate,
    information.tdate,
    limit,
    page,
  ]);

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const downloadPdf = async () => {
    const input = document.getElementById('pdf-content'); // Element that wraps your table
    if (!input) return; // Guard clause if the element is not found

    const canvas = await html2canvas(input, {
      // scale: 1, // Adjust scale as needed
      useCORS: true, // If you have images that come from different origins
    });

    const imgData = canvas.toDataURL('image/png');

    // Create an A4 portrait PDF
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF({
      orientation: 'portrait', // Changed to 'portrait'
      unit: 'mm',
      format: 'a4',
    });

    // Calculate the width and height to maintain aspect ratio
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const marginLeft = pdfWidth * 0.07; // 10% of PDF width
    const contentWidth = pdfWidth * 0.9; // 80% of PDF width to account for left margin

    const imgProps = pdf.getImageProperties(imgData);
    const imgHeight = (imgProps.height * contentWidth) / imgProps.width;

    // Add the image to the PDF
    pdf.addImage(imgData, 'PNG', marginLeft, 0, contentWidth, imgHeight);

    const accountName = information.accountList?.find((x) => x.id === information?.accountId)?.name;
    const startDate = moment(information.fdate).format('DD-MM-YYYY');
    const endDate = moment(information.tdate).format('DD-MM-YYYY');
    const fileName = `${accountName}_${startDate}-${endDate}.pdf`;

    pdf.save(fileName);
  };

  const downloadExcel = () => {
    // Convert your data to a workbook
    const ws = XLSX.utils.json_to_sheet(
      tableData.map((data) =>
      // Transform your data here to match the Excel format you need
      ({
        Date: moment(data.dt, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        'V. No': data.v_no,
        'Account Particulars': data.entry_details?.find((x) => x.order === 2)?.account?.name,
        Receipts:
          data.entry_details.find((x) => x.accountId === information.accountId)?.cb === 1
            ? data.amount
            : '',
        Payments:
          data.entry_details.find((x) => x.accountId === information.accountId)?.cb === 2
            ? data.amount
            : '',
        // Add other fields as needed
      })
      )
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Report');

    // Create file name
    const fileName = `${information.accountList?.find((x) => x.id === information?.accountId)?.name
      }_${moment(information.fdate).format('DD-MM-YYYY')}-${moment(information.tdate).format(
        'DD-MM-YYYY'
      )}.xlsx`;

    // Write the file
    XLSX.writeFile(wb, fileName);
  };

  const handleDeleteRows = useCallback(
    async (id) => {
      try {
        let URL = 'roles/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          getAll();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [enqueueSnackbar, getAll]
  );
  const getAccountList = useCallback(async () => {
    let URL = 'accounts/';
    if (ac_groupId) {
      URL += `?page=${1}&limit=${10000}&status=true&ac_groupId=${ac_groupId}`;
      const { data } = await fetcher(URL);
      let accountId = null;
      if (reportsDetails.name.toLowerCase().includes('cash')) {
        const findCash = data.rows.find((x) => x.name.toLowerCase() === 'cash')?.id;
        accountId = findCash;
      }
      setData({ ...information, accountList: data.rows, accountId });
    } else {
      URL += `?page=${1}&limit=${10000}&status=true&`;
      const { data } = await fetcher(URL);
      setData({ ...information, accountList: data.rows, accountId: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ac_groupId]);
  useEffect(() => {
    getAccountList();
  }, [getAccountList, ac_groupId]);
  useEffect(() => {
    getAll();
  }, [getAll, handleDeleteRows]);

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={reportsDetails?.name}
        links={[
          { name: 'Dashboard', href: paths.home },
          {
            name: reportsDetails?.name || 'Report',
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Card sx={{ p: 2 }}>
        <Grid container>
          <Grid item container xs={12} sm={12} md={11} lg={11} xl={11} xxl={11} spacing={2}>
            <Grid item sm={12} md={6} lg={6} xl={4}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={information.accountList?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={
                  information.accountList?.find((data) => data.id === information.accountId)
                    ?.name || ''
                }
                onChange={(event, newValue) => {
                  const name = information.accountList?.find((data) => data.name === newValue)?.id;
                  if (name) {
                    setData({ ...information, accountId: name });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} name="bookId" label="Select Account" />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={4} xl={2}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={report_periods?.map((option) => option.label)}
                getOptionLabel={(option) => option}
                value={
                  report_periods?.find((data) => data.value === information.period)?.label || ''
                }
                onChange={(event, newValue) => {
                  const label = report_periods?.find((data) => data.label === newValue)?.value;
                  if (label) {
                    setData({ ...information, period: label });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} name="bookId" label="Select Periods" />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={4} xl={2}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={bank_cash_report_formate?.map((option) => option.label)}
                getOptionLabel={(option) => option}
                value={
                  bank_cash_report_formate?.find((data) => data.value === information.formate)
                    ?.label || ''
                }
                onChange={(event, newValue) => {
                  const label = bank_cash_report_formate?.find(
                    (data) => data.label === newValue
                  )?.value;
                  console.log(label);
                  if (label) {
                    setData({ ...information, formate: label });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} name="formate" label="Select Format" />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={4} xl={2}>
              <DatePicker
                value={new Date(information.fdate)}
                onChange={(newValue) => {
                  setData({ ...information, fdate: newValue });
                }}
                format="dd/MM/yyyy"
                label="From Date"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                  },
                }}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={4} xl={2}>
              <DatePicker
                value={new Date(information.tdate)}
                onChange={(newValue) => {
                  setData({ ...information, tdate: newValue });
                }}
                format="dd/MM/yyyy"
                label="To Date"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} md={1} lg={1} xl={1} xxl={1}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'right' }}>
                <Tooltip title="Download PDF File" arrow>
                  <IconButton onClick={downloadPdf}>
                    <Iconify icon="vscode-icons:file-type-pdf2" />
                  </IconButton>
                </Tooltip>

                {/* <Tooltip title="Download Excel File" arrow>
                  <IconButton onClick={downloadExcel}>
                    <Iconify icon="vscode-icons:file-type-excel" />
                  </IconButton>
                </Tooltip> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      {information.accountId && (
        <>
          <Card sx={{ p: 4, mt: 3 }} id="pdf-content">
            <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
              {user?.companies?.find((x) => x.id === user?.companyId)?.name
                ? user?.companies?.find((x) => x.id === user?.companyId)?.name.toUpperCase()
                : ''}
            </Typography>

            <table cellPadding={1} cellSpacing={3} style={{ width: '100%' }}>
              <tr>
                <th style={{ textAlign: 'left' }}>
                  {information.accountList?.find((x) => x.id === information?.accountId)?.name}
                </th>
                <th />
                <th />
                <th />
                <th style={{ textAlign: 'right' }}>
                  FROM : {moment(information.fdate).format('DD-MM-YYYY')}
                </th>
              </tr>
              <tr>
                <th style={{ textAlign: 'left' }}>
                  {reportsDetails?.cond?.label?.toUpperCase()} BOOK
                </th>
                <th />
                <th />
                <th />
                <th style={{ textAlign: 'right' }}>
                  TO : {moment(information.tdate).format('DD-MM-YYYY')}
                </th>
              </tr>
            </table>
            <table cellPadding={1} cellSpacing={3} style={{ width: '100%' }}>
              <tr>
                {columns.map((x) => (
                  <td style={{ borderBottom: '1px dashed black' }} />
                ))}
              </tr>
              <tr>
                <th style={{ textAlign: 'left' }}>Date</th>
                <th style={{ textAlign: 'center' }}>No</th>
                <th style={{ textAlign: 'left' }}>Account Particulars</th>

                <th style={{ textAlign: 'right' }}>Receipts (Cr.)</th>
                <th />
                <th style={{ textAlign: 'right' }}>Payments (Dr.)</th>
                <th />

                {information.formate === '2' && (
                  <>
                    <th style={{ textAlign: 'right' }}>Balance</th>
                    <th />
                  </>
                )}
              </tr>
              <tr>
                {columns.map((x) => (
                  <td style={{ borderBottom: '1px dashed black' }} />
                ))}
              </tr>
              {RecPayments()}
              <tr>
                {columns.map((x) => (
                  <td style={{ borderBottom: '1px dashed black', padding: '7px' }} />
                ))}
              </tr>
            </table>
          </Card>
          {!tableData.length && <NotFound name="Report" />}
        </>
      )}
    </Container>
  );
}

ListView.propTypes = {
  reportsDetails: PropTypes.object,
};
