import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { KEY } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import { Stack } from '@mui/system';
import { useBgColorAutocomplete } from 'src/_mock/constant_funcation';
import { DateField, DatePicker } from '@mui/x-date-pickers';

export default function ServiceQuickEditForm({
  productId,
  row,
  open,
  onClose,
  table,
  getAll,
  view,
  tableData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [modelVariant, setModelVariant] = useState([]);
  const [serviceTypeData, setServiceTypeData] = useState([]);

  const bgColorAutocomplete = useBgColorAutocomplete();
  const tableBodyRef = useRef(null);
  
  const ServiceMasterSchema = Yup.object().shape({
    productId: Yup.number().required('Product Variant is required'),
    effectiveDate: Yup.date().required('effectiveDate is required'),
    servicedetails: 
      Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Name is required'),
          servicetypeId: Yup.number().required('Type is required'),
          fromDays: Yup.number().required('From Days is required'),
          toDays: Yup.number().required('To Days is required'),
          fromKms: Yup.number().required('From Kms is required'),
          toKms: Yup.number().required('To Kms is required'),
          rate: Yup.number().required('Rate is required'),
        })
      ),
  });

  const values = useMemo(
    () => ({
      productId: row?.productId || null,
      effectiveDate: row?.effectiveDate || new Date(),
      servicedetails:
        row?.servicedetails?.map((data) => ({
          id: data?.id || null,
          name: data?.name || '',
          servicetypeId: data?.servicetypeId || null,
          fromDays: data?.fromDays || null,
          toDays: data?.toDays || null,
          fromKms: data?.fromKms || null,
          toKms: data?.toKms || null,
          rate: data?.rate || null,
        })) ||
        [...Array(1)].map(() => ({
          id: null,
          name: '',
          servicetypeId: null,
          fromDays: null,
          toDays: null,
          fromKms: null,
          toKms: null,
          rate: null,
        })),
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(ServiceMasterSchema),
    values,
    mode: 'onChange',
  });

  const {
    reset,
    control,
    trigger,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const onSubmit = handleSubmit(async (payload) => {

    setLoading(true);
    try {
      let URL = 'automobile/services/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      setLoading(false);
      console.error(error);
    }
  });

  const serviceTypesList = async () => {
    let URL = 'automobile/servicetypes/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setServiceTypeData(data?.rows);
  };


  useEffect(() => {
    serviceTypesList();
  }, [open]);

  const ModelVariantList = useCallback(async () => {
    try {
      let URL = 'automobile/products/';
      URL += `?page=${1}&limit=${10000}&status=true&category=vehicle&`;
      const { data } = await fetcher(URL);
      if (data) {
        setModelVariant(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const serviceByID = useCallback(
    async (id) => {
      try {
        let URL = `automobile/services/${id}`;
        URL += `?page=${1}&limit=${10}&status=true&`;
        const { data } = await fetcher(URL);
        if (data) {
          reset(data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [reset]
  );

  useEffect(() => {
    reset();
  }, [open, reset]);

  useEffect(() => {
    if (productId)
      serviceByID(productId);
  }, [productId, serviceByID, setValue]);

  useEffect(() => {
    if (open) ModelVariantList();
  }, [open, ModelVariantList]);

  const servicedetails = useFieldArray({
    control,
    name: 'servicedetails',
  });

  const inputRefs = useRef(allValues.servicedetails.map(() => React.createRef()));

  const handleAdd = useCallback(() => {
    servicedetails?.append({
      id: null,
      name: '',
      servicetypeId: null,
      fromDays: null,
      toDays: null,
      fromKms: null,
      toKms: null,
      rate: null,
    });
    inputRefs.current = [...inputRefs.current, React.createRef()];
    // Scroll to the bottom of the table body
    setTimeout(() => {
      tableBodyRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, 100);
    
  }, [servicedetails]);

   const handleRemove = useCallback(
     (index) => {
       if (index >= 0 && index !== null) {
         servicedetails?.remove(index);
       }
     },
     [servicedetails]
   );

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog fullScreen open={open} onKeyDown={handleKeyDown}>
      <FormProvider methods={methods}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            top: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Toolbar>
            {view && (
              <Typography variant="h5" sx={{ flex: 1 }}>
                View Service Master
              </Typography>
            )}
            {!view && (
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update Service Master' : 'Add Service Master'}
              </Typography>
            )}
            <IconButton color="inherit" edge="start" onClick={onClose} tabIndex="-1">
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>

        <DialogContent>
          <Grid
            container
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            p={2}
            sx={{ justifyContent: 'space-between' }}
          >
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
              <Autocomplete
                autoHighlight
                fullWidth
                size="small"
                options={modelVariant}
                groupBy={(option) => option?.a}
                getOptionLabel={(option) => option.name}
                getOptionDisabled={(option) => option?.value === null}
                openOnFocus
                onChange={(event, newValue) => {
                  setValue('productId', newValue?.id);
                }}
                value={(() => {
                  const addRecord = modelVariant?.find((data) => data.id === allValues.productId);
                  const editRecord = row?.ac_group;

                  if (addRecord) return addRecord;
                  if (editRecord) return editRecord;
                  return null;
                })()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        Select Product Variant <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    name="productId"
                    error={errors?.productId && !allValues?.productId}
                    autoFocus={!row}
                    disabled={row?.id || productId}
                  />
                )}
                disabled={view || row?.id || productId}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                    key={option.id}
                    sx={{ minHeight: 'auto !important' }}
                  >
                    <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                  </Box>
                )}
                ListboxProps={{ ...bgColorAutocomplete }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <Controller
                name="effectiveDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    value={new Date(field.value)}
                    timezone="Asia/Kolkata"
                    label="Effective Date"
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    format="dd/MM/yyyy"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                        size: 'small',
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={12} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <TableContainer
                component={Paper}
                sx={{ overflow: 'auto', maxHeight: '60vh', width: '98%', margin: 'auto' }}
              >
                <Table
                  stickyHeader
                  aria-label="simple table"
                  sx={{
                    '& .MuiTableCell-sizeMedium': {
                      padding: '0px !important',
                    },
                    '& fieldset': { borderRadius: '0px', border: 'none' },
                    '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                    '& .MuiOutlinedInput-root': { padding: '0px !important' },
                    '& .autoComplete>div': { padding: '0px !important' },
                    '& .MuiTableCell-root': {
                      border: '0.01px solid #e9ecee',
                    },
                    '& .MuiTableCell-head': {
                      padding: '5px !important',
                      borderRight: '1px solid #d0d1d2 !important',
                    },
                    '& .MuiTableCell-footer': {
                      padding: '5px !important',
                    },
                    '& .MuiTableCell-head:last-child': {
                      borderRight: '0px !important',
                    },
                    '&:last-child td': {
                      borderColor: '#e9ecee !important',
                    },
                  }}
                >
                  <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
                    <TableRow>
                      <TableCell sx={{ width: 20, textAlign: 'center' }}>SR.</TableCell>
                      <TableCell sx={{ width: 400, textAlign: 'center' }}>Name</TableCell>
                      <TableCell sx={{ width: 150, textAlign: 'center' }}>Type</TableCell>
                      <TableCell sx={{ width: 70, textAlign: 'center' }}>From Days</TableCell>
                      <TableCell sx={{ width: 70, textAlign: 'center' }}>To Days</TableCell>
                      <TableCell sx={{ width: 70, textAlign: 'center' }}>From Kms</TableCell>
                      <TableCell sx={{ width: 70, textAlign: 'center' }}>To Kms</TableCell>
                      <TableCell sx={{ width: 70, textAlign: 'center' }}>Rate</TableCell>
                      <TableCell
                        sx={{ width: 10, textAlign: 'center', position: 'sticky', right: 0 }}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {servicedetails?.fields?.map((field, index) => {
                      console.log('index', index);
                      return (
                        <TableRow key={index} ref={tableBodyRef}>
                          <TableCell
                            sx={{
                              textAlign: 'center',
                              position: 'sticky',
                              left: 0,
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.servicedetails &&
                              errors?.servicedetails[index] &&
                              errors?.servicedetails[index]?.productId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <RHFTextField
                              name={`servicedetails[${index}].name`}
                              size="small"
                              value={allValues.servicedetails[index]?.name || ''}
                              onChange={(e) => {
                                setValue(`servicedetails[${index}].name`, e.target.value);
                              }}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.servicedetails &&
                              errors?.servicedetails[index] &&
                              errors?.servicedetails[index]?.servicetypeId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
                              autoHighlight
                              fullWidth
                              size="small"
                              options={
                                serviceTypeData?.length
                                  ? serviceTypeData.filter(
                                      (x) =>
                                        !allValues?.servicedetails
                                          ?.map((y) => y.servicetypeId)
                                          .includes(x.id)
                                    )
                                  : [{ name: 'No Options', value: null }]
                              }
                              groupBy={(option) => option?.a}
                              getOptionLabel={(option) => option.name}
                              getOptionDisabled={(option) => option?.value === null}
                              openOnFocus
                              onChange={(event, newValue) => {
                                setValue(`servicedetails[${index}].servicetypeId`, newValue?.id);
                              }}
                              value={(() => {
                                const addRecord = serviceTypeData?.find(
                                  (data) =>
                                    data.id === allValues.servicedetails[index]?.servicetypeId
                                );
                                const editRecord = row?.ac_group;

                                if (addRecord) return addRecord;
                                if (editRecord) return editRecord;
                                return null;
                              })()}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`servicedetails[${index}].servicetypeId`}
                                  variant="standard"
                                />
                              )}
                              disabled={view}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  {...props}
                                  key={option.id}
                                  sx={{ minHeight: 'auto !important' }}
                                >
                                  <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                                </Box>
                              )}
                              ListboxProps={{ ...bgColorAutocomplete }}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.servicedetails &&
                              errors?.servicedetails[index] &&
                              errors?.servicedetails[index]?.fromDays && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <RHFTextField
                              name={`servicedetails[${index}].fromDays`}
                              size="small"
                              value={allValues.servicedetails[index]?.fromDays || ''}
                              onChange={(e) => {
                                setValue(`servicedetails[${index}].fromDays`, e.target.value);
                              }}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.servicedetails &&
                              errors?.servicedetails[index] &&
                              errors?.servicedetails[index]?.toDays && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <RHFTextField
                              name={`servicedetails[${index}].toDays`}
                              size="small"
                              value={allValues.servicedetails[index]?.toDays || ''}
                              onChange={(e) => {
                                setValue(`servicedetails[${index}].toDays`, e.target.value);
                              }}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.servicedetails &&
                              errors?.servicedetails[index] &&
                              errors?.servicedetails[index]?.fromKms && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <RHFTextField
                              name={`servicedetails[${index}].fromKms`}
                              size="small"
                              value={allValues.servicedetails[index]?.fromKms || ''}
                              onChange={(e) => {
                                setValue(`servicedetails[${index}].fromKms`, e.target.value);
                              }}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.servicedetails &&
                              errors?.servicedetails[index] &&
                              errors?.servicedetails[index]?.toKms && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <RHFTextField
                              name={`servicedetails[${index}].toKms`}
                              size="small"
                              value={allValues.servicedetails[index]?.toKms || ''}
                              onChange={(e) => {
                                setValue(`servicedetails[${index}].toKms`, e.target.value);
                              }}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.servicedetails &&
                              errors?.servicedetails[index] &&
                              errors?.servicedetails[index]?.rate && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <RHFTextField
                              name={`servicedetails[${index}].rate`}
                              size="small"
                              value={allValues.servicedetails[index]?.rate || ''}
                              onChange={(e) => {
                                setValue(`servicedetails[${index}].rate`, e.target.value);
                              }}
                            />
                          </TableCell>

                          {allValues.servicedetails.length > 1 ? (
                            <TableCell
                              sx={{
                                textAlign: 'center',
                                position: 'sticky',
                                right: 0,
                                index: 9,
                                cursor: 'pointer',
                              }}
                              onClick={() => handleRemove(index)}
                            >
                              <Iconify icon="solar:trash-bin-trash-bold" color="red" />
                            </TableCell>
                          ) : (
                            <TableCell sx={{ textAlign: 'end' }} />
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>

        {!view && (
          <Stack
            direction="row-reverse"
            alignItems="center"
            sx={{
              bottom: 0,
              zIndex: 111111,
              p: 3,
              position: 'fixed',
              width: '100%',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
            id="modelfooter"
          >
            <Tooltip title="(Alt + A)" arrow>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={async () => {
                  if (await trigger()) {
                    onSubmit();
                  }
                }}
              >
                {row ? 'Update' : 'Add'}
              </LoadingButton>
            </Tooltip>

            <Tooltip title="(Esc)" arrow>
              <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
                Cancel
              </Button>
            </Tooltip>

            <Button
              variant="soft"
              onClick={() => {
                reset(values);
              }}
              sx={{ mr: 2 }}
            >
              Reset
            </Button>
            <Stack direction="row" alignItems="center" flexGrow={1} spacing={2}>
              <Tooltip title="Add Row" arrow>
                <IconButton color="primary" onClick={handleAdd}>
                  <Iconify icon="mingcute:add-line" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        )}
      </FormProvider>
    </Dialog>
  );
}

ServiceQuickEditForm.propTypes = {
  productId: PropTypes.any,
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  view: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
