import { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Grid } from '@mui/material';

const SummaryTable = () => {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'gstrgrouping',
        header: 'GSTR Grouping',
        size: 450,
      },
      {
        accessorKey: 'taxableamount',
        header: 'Taxable Amount',
        size: 200,
      },
      {
        accessorKey: 'centraltax',
        header: 'Central Tax',
        size: 200,
      },
      {
        accessorKey: 'stateuttax',
        header: 'State/UT Tax',
        size: 200,
      },
      {
        accessorKey: 'integreatedtax',
        header: 'Integreated Tax',
        size: 200,
      },
    ],
    []
  );

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setData([
      {
        id: '1',
        gstrgrouping: 'Opening',
        taxableamount: '',
        centraltax: '',
        stateuttax: '',
        integreatedtax: '',
      },
      {
        id: '2',
        gstrgrouping: 'B2B',
        taxableamount: '7489533.19',
        centraltax: '283918.63',
        stateuttax: '283918.63',
        integreatedtax: '349589.91',
      },
      {
        id: '3',
        gstrgrouping: 'B2CS',
        taxableamount: '193670.00',
        centraltax: '16832.16',
        stateuttax: '16832.16',
        integreatedtax: '',
      },
      {
        id: '4',
        gstrgrouping: 'CDNR',
        taxableamount: '-91430.00',
        centraltax: '-2306.70',
        stateuttax: '-2306.70',
        integreatedtax: '-11844.00',
      },
      {
        id: '5',
        gstrgrouping: 'Nill Rated Supplies',
        taxableamount: '750.00',
        centraltax: '',
        stateuttax: '',
        integreatedtax: '',
      },
      {
        id: '6',
        gstrgrouping: 'B2CS (NRCM)',
        taxableamount: '5230.00',
        centraltax: '130.75',
        stateuttax: '130.75',
        integreatedtax: '',
      },
      {
        id: '1',
        gstrgrouping: 'Opening',
        taxableamount: '',
        centraltax: '',
        stateuttax: '',
        integreatedtax: '',
      },
      {
        id: '2',
        gstrgrouping: 'B2B',
        taxableamount: '7489533.19',
        centraltax: '283918.63',
        stateuttax: '283918.63',
        integreatedtax: '349589.91',
      },
      {
        id: '3',
        gstrgrouping: 'B2CS',
        taxableamount: '193670.00',
        centraltax: '16832.16',
        stateuttax: '16832.16',
        integreatedtax: '',
      },
      {
        id: '4',
        gstrgrouping: 'CDNR',
        taxableamount: '-91430.00',
        centraltax: '-2306.70',
        stateuttax: '-2306.70',
        integreatedtax: '-11844.00',
      },
      {
        id: '5',
        gstrgrouping: 'Nill Rated Supplies',
        taxableamount: '750.00',
        centraltax: '',
        stateuttax: '',
        integreatedtax: '',
      },
      {
        id: '6',
        gstrgrouping: 'B2CS (NRCM)',
        taxableamount: '5230.00',
        centraltax: '130.75',
        stateuttax: '130.75',
        integreatedtax: '',
      },
      {
        id: '1',
        gstrgrouping: 'Opening',
        taxableamount: '',
        centraltax: '',
        stateuttax: '',
        integreatedtax: '',
      },
      {
        id: '2',
        gstrgrouping: 'B2B',
        taxableamount: '7489533.19',
        centraltax: '283918.63',
        stateuttax: '283918.63',
        integreatedtax: '349589.91',
      },
      {
        id: '3',
        gstrgrouping: 'B2CS',
        taxableamount: '193670.00',
        centraltax: '16832.16',
        stateuttax: '16832.16',
        integreatedtax: '',
      },
      {
        id: '4',
        gstrgrouping: 'CDNR',
        taxableamount: '-91430.00',
        centraltax: '-2306.70',
        stateuttax: '-2306.70',
        integreatedtax: '-11844.00',
      },
      {
        id: '5',
        gstrgrouping: 'Nill Rated Supplies',
        taxableamount: '750.00',
        centraltax: '',
        stateuttax: '',
        integreatedtax: '',
      },
      {
        id: '6',
        gstrgrouping: 'B2CS (NRCM)',
        taxableamount: '5230.00',
        centraltax: '130.75',
        stateuttax: '130.75',
        integreatedtax: '',
      },
    ]);
    setIsLoading(false);
  }, []);

  const handleRowFocus = (event) => {
    const element = event.target;
    const container = element.closest('.MuiTableContainer-root');
    if (container && element) {
      // Ensure the focused row is scrolled into view
      container.scrollTop =
        element.offsetTop -
        container.offsetTop -
        container.clientHeight / 2 +
        element.clientHeight / 2;
    }
  };

  const handleRowKeyDown = (event) => {
    const element = event.target;
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        // eslint-disable-next-line no-case-declarations
        const nextRow = element.nextSibling;
        if (nextRow) {
          nextRow.focus();
        }
        break;
      case 'ArrowUp':
        event.preventDefault();
        // eslint-disable-next-line no-case-declarations
        const previousRow = element.previousSibling;
        if (previousRow) {
          previousRow.focus();
        }
        break;
    }
  };

  const table = useMaterialReactTable({
    columns,
    data,
    state: {
      isLoading,
    },
    getRowId: (row) => row.id,
    enableRowVirtualization: false,
    renderRow: ({ row, table }) => (
      <Grid
        {...row.getRowProps()}
        tabIndex={0}
        onKeyDown={handleRowKeyDown}
        onFocus={handleRowFocus}
        style={{ outline: 'none' }}
      >
        {table.getRowCells(row)}
      </Grid>
    ),
    muiTableContainerProps: { sx: { maxHeight: '300px' }, className: 'MuiTableContainer-root' },
  });

  return <MaterialReactTable table={table} />;
};

export default SummaryTable;
