import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import AppWidget from 'src/sections/overview/app/app-widget';

function ActivityList({ followUpDashboard, enqstatusData, setFollowUpstatusData }) {
  console.log(enqstatusData, 'enqstatusData');
  return (
    <Grid container spacing={1} sx={{ pb: 1 }}>
      <Grid
        item
        xs={3}
        sm={3}
        md={3}
        lg={3}
        xl={3}
        xxl={3}
        onClick={() => setFollowUpstatusData('')}
      >
        <AppWidget
          title="Total"
          total={followUpDashboard?.total || 0}
          icon="solar:user-rounded-bold"
          // chart={{
          //   series: 48,
          // }}
          enquiryActive={!enqstatusData}
        />
      </Grid>
      <Grid
        item
        xs={3}
        sm={3}
        md={3}
        lg={3}
        xl={3}
        xxl={3}
        onClick={() => setFollowUpstatusData(1)}
      >
        <AppWidget
          title="Pending"
          total={followUpDashboard?.pending || 0}
          icon="solar:user-rounded-bold"
          // chart={{
          //   series: 48,
          // }}
          enquiryActive={enqstatusData === 1 && 'true'}
        />
      </Grid>
      <Grid
        item
        xs={3}
        sm={3}
        md={3}
        lg={3}
        xl={3}
        xxl={3}
        onClick={() => setFollowUpstatusData(3)}
      >
        <AppWidget
          title="Done"
          total={followUpDashboard?.done || 0}
          icon="solar:user-rounded-bold"
          // chart={{
          //   series: 48,
          // }}
          enquiryActive={enqstatusData === 3 && 'true'}
        />
      </Grid>
      <Grid
        item
        xs={3}
        sm={3}
        md={3}
        lg={3}
        xl={3}
        xxl={3}
        onClick={() => setFollowUpstatusData(2)}
      >
        <AppWidget
          title="Closed"
          total={followUpDashboard?.close || 0}
          icon="solar:user-rounded-bold"
          // chart={{
          //   series: 2,
          // }}
          enquiryActive={enqstatusData === 2 && 'true'}
        />
      </Grid>
    </Grid>
  );
}

export default ActivityList;

ActivityList.propTypes = {
  followUpDashboard: PropTypes.object,
  enqstatusData: PropTypes.string,
  setFollowUpstatusData: PropTypes.func,
};
