import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import {
  Card,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { KEY } from 'src/_mock/constant';
import { useCallback, useEffect, useState } from 'react';
import { useTable } from 'src/components/table';
import { useDebounce } from 'src/hooks/use-debounce';
import queryString from 'query-string';
import { fetcher } from 'src/utils/axios';
import LogTableSearch from './log-table';

const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
};

export default function LogTable({ open, onClose, logDetails }) {
  const table = useTable();

  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState(defaultFilters);
  const [isLoading, setIsLoading] = useState(false);

  const { page } = table;
  const limit = table.rowsPerPage;
  const originalId = logDetails?.originalId;

  const debouncedQuery = useDebounce(filters);

  const notFound = count === 0;

  const getAll = useCallback(async () => {
    setIsLoading(true);
    try {
      let URL = `${logDetails.API}/`;
      if (originalId || logDetails?.logId)
        URL += `?page=${page + 1}&limit=${limit}&log=true&originalId=${originalId}&logId=${
          logDetails?.logId
        }&`;
      else URL += `?page=${page + 1}&limit=${limit}&log=true&`;
      if (Object.keys(debouncedQuery).length) {
        const nefil = { ...debouncedQuery };
        if (debouncedQuery.status === 'all') {
          delete nefil.status;
        }
        URL += queryString.stringify(nefil);
      }

      const { data } = await fetcher(URL);
      if (data) {
        setCount(data?.count);
        setTableData(data?.rows);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, [debouncedQuery, limit, logDetails.API, logDetails.logId, originalId, page]);

  useEffect(() => {
    if (open) getAll();
  }, [open, getAll]);

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
      if (logDetails?.setLogId) logDetails?.setLogId(null);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: '100vw', height: '100vh' },
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          top: 0,
          zIndex: 9,
          position: 'sticky',
          bgcolor: (theme) => alpha(theme.palette.background.default, 1),
        }}
      >
        <Toolbar>
          <Typography variant="h5" sx={{ flex: 1 }}>
            {logDetails.name}
          </Typography>

          <IconButton
            color="inherit"
            edge="start"
            onClick={() => {
              onClose();
              if (logDetails?.setLogId) logDetails?.setLogId(null);
            }}
          >
            <Iconify icon="mingcute:close-line" />
          </IconButton>
        </Toolbar>
        <Divider sx={{ borderStyle: 'dashed' }} />
      </Grid>

      <DialogContent sx={{ p: 2 }}>
        <Card sx={{ width: 1 }}>
          <Grid sx={{ p: 2 }}>
            <TextField
              value={filters.name}
              variant="standard"
              onChange={(event) => handleFilters('name', event.target.value)}
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <LogTableSearch
            tableData={tableData}
            tableName={logDetails.API}
            insideName={logDetails?.name}
            table={table}
            count={count}
            notFound={notFound}
            originalId={originalId}
            logDetails={logDetails}
            isLoading={isLoading}
          />
        </Card>
      </DialogContent>
    </Dialog>
  );
}

LogTable.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  logDetails: PropTypes.object,
};
