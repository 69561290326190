import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
import LogTable from 'src/layouts/_common/log/log-manage';
import { fMoney } from 'src/_mock/constant_funcation';

// ----------------------------------------------------------------------

export default function UserTableRow({
  tableData,
  row,
  index,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  table,
  setRow,
  quickEdit,
  getAll,
  permission,
}) {
  const confirm = useBoolean();
  const quickLog = useBoolean();

  const popover = usePopover();

  // Function to mask credit card number except for the last 4 digits
  const maskCreditCardNumber = (number) => {
    if (!number) return '';
    const visibleDigits = 4;
    const maskedLength = number.length - visibleDigits;
    const maskedSection = 'X'.repeat(maskedLength);
    return `${maskedSection}${number.substring(maskedLength)}`;
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell>{index}</TableCell>
        <TableCell>{maskCreditCardNumber(row?.creditcard?.no)}</TableCell>
        <TableCell>{row?.creditcard?.bank?.name}</TableCell>
        <TableCell>{row?.creditcard?.holder}</TableCell>
        <TableCell>{selected ? row?.creditcard?.cvv : 'XXX'}</TableCell>
        <TableCell>{row?.creditcard?.card_issuer?.name}</TableCell>

        <TableCell sx={{ textAlign: 'right' }}>
          {row?.creditcard?.limit ? fMoney.format(row?.creditcard?.limit, { symbol: '₹ ' }) : '-'}
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          {permission?.u && (
            <Tooltip title="Quick Edit" placement="top" arrow>
              <IconButton
                color={quickEdit.value ? 'inherit' : 'default'}
                onClick={() => {
                  quickEdit.onTrue();
                  setRow(row);
                }}
              >
                <Iconify icon="solar:pen-bold" />
              </IconButton>
            </Tooltip>
          )}
          {(permission?.u || permission?.d) && (
            <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          )}
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {permission?.d && (
          <MenuItem
            onClick={() => {
              confirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon="solar:trash-bin-trash-bold" />
            Delete
          </MenuItem>
        )}

        {permission?.u && (
          <MenuItem
            onClick={() => {
              setRow(row);
              quickEdit.onTrue();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:pen-bold" />
            Edit
          </MenuItem>
        )}

        {permission?.l && (
          <MenuItem
            onClick={() => {
              quickLog.onTrue();
              popover.onClose();
            }}
          >
            <Iconify icon="mdi:refresh" />
            Log
          </MenuItem>
        )}
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />

      {quickLog.value && (
        <LogTable
          open={quickLog.value}
          logDetails={{
            name: 'Account Log',
            API: 'automobile/accounts/',
            originalId: row.id,
          }}
          onClose={() => {
            quickLog.onFalse();
          }}
        />
      )}
    </>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  index: PropTypes.number,
  selected: PropTypes.bool,
  table: PropTypes.object,
  setRow: PropTypes.func,
  quickEdit: PropTypes.object,
  permission: PropTypes.object,
  getAll: PropTypes.func,
  tableData: PropTypes.array,
};
