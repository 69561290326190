import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { create, destroy, fetcher, update } from 'src/utils/axios';
import { CASH_DEBIT, INVOI_TYPE, KEY, Tax_BilSupply } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  ListItem,
  TableFooter,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { Box, Stack, alpha } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import { useEventListener } from 'src/hooks/use-event-listener';
import {
  fMoney,
  nearestDateList,
  onScollerAutoComplate,
  useBgColorAutocomplete,
} from 'src/_mock/constant_funcation';
import { isNaN } from 'lodash';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { useBoolean } from 'src/hooks/use-boolean';
import AUserQuickEditForm from 'src/sections/accounts/account/quick-edit-form';
import PUserQuickEditForm from 'src/sections/products/product-list/quick-edit-form';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CompanyYear from 'src/layouts/_common/company-year';
import { AuthContext } from 'src/auth/context/jwt';
// eslint-disable-next-line import/no-extraneous-dependencies
import { makeStyles } from '@mui/styles';
import GSTCommodityQuickEditForm from 'src/sections/gst/master/commodity/quick-edit-form';
import QuickEditForm from 'src/sections/credit-card/quick-edit-form';
import { useDebounce } from 'src/hooks/use-debounce';
import { handleScroll } from 'src/layouts/_common/scroller';

let tranType = null;
const va = {
  1: {
    cb: 1,
    t_type: 1,
    name: 'Bank',
    label: 'Bank',
    rp: 'Payment',
    ac_groupId: '22',
  },
  2: {
    cb: 2,
    t_type: 1,
    name: 'Bank',
    label: 'Bank',
    rp: 'receipt',
    ac_groupId: '22',
  },
  3: {
    cb: 1,
    t_type: 2,
    name: 'Cash',
    label: 'Cash',
    rp: 'Payment',
    ac_groupId: '26',
  },
  4: {
    cb: 2,
    t_type: 2,
    name: 'Cash',
    label: 'Cash',
    rp: 'receipt',
    ac_groupId: '26',
  },
  5: {
    cb: 1,
    t_type: 1,
    name: 'Contra',
    label: 'Contra',
    rp: 'Payment',
    ac_groupId: '22',
  },
  6: {
    cb: 2,
    t_type: 1,
    name: 'Contra',
    label: 'Contra',
    rp: 'receipt',
    ac_groupId: '22',
  },
  8: {
    cb: 2,
    t_type: 1,
    name: 'Purchase',
    label: 'Purchase',
    rp: 'receipt',
    ac_groupId: '10',
  },
  9: {
    cb: 2,
    t_type: 1,
    name: 'Sale',
    label: 'Sale',
    rp: 'receipt',
    ac_groupId: '73',
  },
};
const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: theme.palette.primary.main,
  },
}));

export default function UserQuickEditForm({
  row,
  open,
  onClose,
  table,
  getAll,
  tableData,
  invoiceSettings,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const bgColorAutocomplete = useBgColorAutocomplete(); // Use the hook here

  const theme = useTheme();

  const tableBodyRef = useRef(null);

  const quickEditAccount = useBoolean();
  const quickEditProduct = useBoolean();
  const quickEditGSTCommdity = useBoolean();
  const confirm = useBoolean();
  const quickEdit = useBoolean();

  let gTotal = 0;

  const { user } = useContext(AuthContext);
  const [partySearch, setPartySearch] = useState(null);
  const [purchaseSearch, setPurchaseSearch] = useState(null);
  const [productList, setProductList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [creditAccountList, setCreditAccountList] = useState([]);
  const [credit, setCredit] = useState({});

  const [pAccountList, setPAccountList] = useState([]);
  const [trasaction, setTrasaction] = useState([]);
  const [isSuccessful, setisSuccessful] = useState(false);
  const [accountEditList, setaccountEditList] = useState({});
  const [PaccountEditList, setPAccountEditList] = useState({});
  const [productEditValues, setProductEditValues] = useState({});
  const [GSTCommodity, setGSTCommodity] = useState({});
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [total, setTotal] = useState({
    CGST: 0,
    SGST: 0,
    IGST: 0,
    ItemAmount: 0,
    TotalAmount: 0,
    BillAmount: 0,
  });

  const PurchaseOrderSchema = Yup.object().shape({
    c_d: Yup.number().nullable().min(0).required('Cash/Dabit is required'),
    in_type: Yup.number().integer().required(' is required'),
    bill_no: Yup.number()
      .nullable()
      .transform((value) => (isNaN(value) ? null : value)),
    inv_details: Yup.array().of(
      Yup.object().shape({
        productId: Yup.number().nullable().required('Product is required'),
        qty: Yup.number()
          .nullable()
          .min(0)
          .required('Qty is required')
          .transform((value) => (isNaN(value) ? null : value)),
        rate: Yup.number()
          .nullable()
          .min(0)
          .required('Rate is required')
          .transform((value) => (isNaN(value) ? null : value)),
        dprice: Yup.number()
          .nullable()
          .min(0)
          .notRequired()
          .transform((value) => (isNaN(value) ? null : value)),
        drate: Yup.number()
          .nullable()
          .min(0)
          .notRequired()
          .transform((value) => (isNaN(value) ? null : value)),
      })
    ),
    entry_details: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().integer().nullable(),
        cb: Yup.number().integer().required(),
        accountId: Yup.number().integer().required(),
        bentryId: Yup.number().integer().nullable(),
        entry_detailId: Yup.number().integer().nullable(),
        bpoptionId: Yup.number()
          .integer()
          .nullable()
          .transform((value, originalValue) => (originalValue === null ? null : originalValue)),
        ac_no: Yup.string().nullable(),
        amount: Yup.number().required(),
      })
    ),
  });

  const values = useMemo(
    () => ({
      c_d: row?.c_d || null,
      in_type: row?.in_type || 3,
      otheraccountId: row?.otheraccountId || null,
      invo_type: row?.invo_type || 1,
      t_type: row?.t_type || null,
      bill_no: row?.bill_no || null,
      v_no: row?.v_no || null,
      gst_no: row?.entry_details?.find((x) => x.order === 1)?.account?.gst_no || null,
      id: row?.id || null,
      tax_bi_Id: row?.tax_bi_Id || null,
      dt: row?.dt || new Date(),
      b_date: row?.b_date || new Date(),
      narr: row?.narr || '',
      inv_details:
        row?.inv_details?.map((data) => ({
          id: data?.id || null,
          productId: data?.productId || null,
          productName: null,
          qty: data?.qty || null,
          rate: data?.rate || null,
          dprice: data?.dprice || null,
          drate: data?.drate || null,
          CGST: data?.CGST || null,
          SGST: data?.SGST || null,
          IGST: data?.IGST || null,
        })) ||
        [...Array(5)].map(() => ({
          id: null,
          productId: null,
          productName: null,
          qty: null,
          rate: null,
          dprice: null,
          drate: null,
          CGST: null,
          SGST: null,
          IGST: null,
        })),
      entry_details: (row?.entry_details &&
        Array.isArray(row?.entry_details) &&
        row?.entry_details
          ?.sort((a, b) => a.order - b.order)
          ?.map((x) => {
            const cgstId = trasaction[tranType]?.tfields
              .filter((x) => ['CGST', 'SGST', 'IGST'].includes(x.name))
              ?.map((x) => x.accountId);
            if (cgstId?.includes(x.accountId)) {
              if (
                trasaction[tranType]?.tfields.find((x) => x.name === 'IGST')?.accountId ===
                x.accountId
              ) {
                x.in_type = 2;
              } else {
                x.in_type = 1;
              }
            }
            return {
              ...(x ? { id: x?.id } : {}),
              ...(x.in_type ? { in_type: x?.in_type } : {}),
              cb: x?.cb || 1,
              cd: x?.cd || 1,
              accountId: x?.accountId || null,
              entryId: x?.entryId || null,
              bentryId: x?.bentryId || null,
              entry_detailId: x?.entry_detailId || null,
              bpoptionId: x?.bpoptionId || null,
              ac_no: x?.ac_no || null,
              amount: x?.amount || null,
              order: x?.order || null,
              accountMeta: {
                page: 1,
                name: '',
                totalPage: 1,
                editRow: null,
              },
            };
          })) || [
          {
            cb: 1,
            cd: 1,
            accountId: null,
            entryId: null,
            bentryId: null,
            entry_detailId: null,
            bpoptionId: null,
            ac_no: null,
            amount: null,
            order: 1,
            accountMeta: {
              page: 1,
              name: '',
              totalPage: 1,
              editRow: null,
            },
          },
          {
            cb: 1,
            cd: 1,
            accountId: null,
            entryId: null,
            bentryId: null,
            entry_detailId: null,
            bpoptionId: null,
            ac_no: null,
            amount: null,
            order: 2,
            accountMeta: {
              page: 1,
              name: '',
              totalPage: 1,
              editRow: null,
            },
          },
        ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [row, trasaction]
  );

  const methods = useForm({
    resolver: yupResolver(PurchaseOrderSchema),
    values,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    trigger,
    handleSubmit,
    getFieldState,
    formState: { isSubmitting, errors },
  } = methods;

  const allValues = watch();

  const debouncedPartyAccount = useDebounce(allValues.entry_details[0].accountMeta?.name);
  const debouncedOppAccount = useDebounce(allValues.entry_details[1].accountMeta?.name);

  const inputRefs = useRef(allValues.inv_details.map(() => React.createRef()));

  const GST_NUMBER = user?.companies?.find((x) => x.id === user?.companyId)?.gst_no;
  const GST_Company = GST_NUMBER?.substring(0, 2);
  const GST_PARTY = allValues?.gst_no?.substring(0, 2);

  useEffect(() => {
    if (!GST_Company || !GST_PARTY) {
      setValue('in_type', 3);
    } else if (GST_NUMBER && GST_PARTY && GST_Company === GST_PARTY) {
      setValue('in_type', 1);
    } else if (GST_NUMBER && GST_PARTY && GST_Company !== GST_PARTY) {
      setValue('in_type', 2);
    }
  }, [GST_Company, GST_NUMBER, GST_PARTY, allValues.gst_no, allValues.entry_details, setValue]);

  const onSubmit = handleSubmit(async (payload) => {
    try {
      payload.inv_details = allValues.inv_details
        .filter((x, index) => x.rate && x.qty)
        .map((y) => {
          const GSTRATE = nearestDateList(
            productList.find((x) => x.id === y.productId)?.gst_commodity?.gst_com_details
          );
          return {
            ...y,
            CGST: GSTRATE?.gst_rate?.CGST,
            SGST: GSTRATE?.gst_rate?.SGST,
            IGST: GSTRATE?.gst_rate?.IGST,
          };
        });
      payload.amount = total.BillAmount;

      let URL = 'entries/';

      const pursaleaccount = allValues.entry_details.find((x) => x.order === 2)?.accountId;
      // const cd = allValues.c_d;
      const resetValues = { ...values };
      resetValues.entry_details[1].accountId = pursaleaccount;
      // resetValues.c_d = cd;

      if (allValues?.tax_bi_Id === 3) {
        const unique = new Set();
        trasaction.forEach((x) => {
          x.tfields.forEach((y) => {
            unique.add(y.accountId);
          });
        });
        payload.entry_details = allValues.entry_details.filter((x) => !unique.has(x.accountId));
      }

      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          if (show) {
            enqueueSnackbar('Update success!');
          }
          getAll();
          reset(resetValues);
          onClose();
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          if (show) {
            enqueueSnackbar('Create success!');
          }
          getAll();
          reset(resetValues);
          document.getElementById('invoice_type').focus();
        }
      }
    } catch (error) {
      reset(payload);
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const inv_details = useFieldArray({
    control,
    name: 'inv_details',
  });
  const entry_details = useFieldArray({
    control,
    name: 'entry_details',
  });

  const TotalQty = allValues.inv_details?.reduce((a, b) => a + (parseFloat(b.qty) || 0), 0);
  const TotalDPrice = allValues.inv_details?.reduce(
    (a, b) =>
      a +
      parseFloat(
        parseFloat(b?.dprice) ||
        (b?.drate > 0 &&
          b?.rate > 0 &&
          ((parseFloat(b?.rate) * parseFloat(b?.drate)) / 100)?.toFixed(2)) ||
        0
      ),
    0
  );

  useEffect(() => {
    let itemAmount = 0;
    let BillAmount = 0;
    let TDPtotalAmount = 0;
    let TDRtotalAmount = 0;
    let TfinalTotal = 0;
    let TtotalAmount = 0;
    let TCGST = 0;
    let TSGST = 0;
    let TIGST = 0;
    let TotalAmount = 0;

    allValues.inv_details?.forEach((field, index) => {
      const gstRate = nearestDateList(
        productList.find((x) => x.id === allValues.inv_details[index]?.productId)?.gst_commodity
          ?.gst_com_details
      );

      let DPtotalAmount = 0;
      let DRtotalAmount = 0;
      let GrossAmount = 0;
      let finalTotal = 0;
      let totalAmount = 0;
      let CGST = 0;
      let SGST = 0;
      let IGST = 0;

      if (allValues.inv_details[index]?.rate && allValues.inv_details[index]?.qty) {
        totalAmount = allValues.inv_details[index].rate * allValues.inv_details[index].qty;
      }
      if (allValues.inv_details[index]?.dprice) {
        DPtotalAmount =
          (allValues.inv_details[index].rate - allValues.inv_details[index].dprice) *
          allValues.inv_details[index].qty;
      }
      if (allValues.inv_details[index]?.drate) {
        const rq = allValues.inv_details[index].rate * allValues.inv_details[index].qty;
        DRtotalAmount = rq - (rq / 100) * allValues.inv_details[index].drate;
      }

      if (DPtotalAmount || allValues.inv_details[index]?.dprice) {
        finalTotal = DPtotalAmount;
      } else if (DRtotalAmount || allValues.inv_details[index]?.drate) {
        finalTotal = DRtotalAmount;
      } else {
        finalTotal = totalAmount;
      }

      GrossAmount = finalTotal;
      itemAmount += GrossAmount;
      TotalAmount += GrossAmount;
      if (allValues?.in_type === 1 && [1, 4].includes(allValues.tax_bi_Id)) {
        SGST = (finalTotal / 100 || 0) * (gstRate?.gst_rate?.SGST || 0);
        CGST = (finalTotal / 100 || 0) * (gstRate?.gst_rate?.CGST || 0);
        if (allValues.tax_bi_Id === 1) {
          finalTotal += CGST + SGST;
          TotalAmount += CGST + SGST;
        }
      } else if (allValues?.in_type === 2 && [1, 4].includes(allValues.tax_bi_Id)) {
        IGST = (finalTotal / 100 || 0) * (gstRate?.gst_rate?.IGST || 0);
        if (allValues.tax_bi_Id === 1) {
          finalTotal += IGST;
          TotalAmount += IGST;
        }
      }

      TDPtotalAmount += DPtotalAmount;
      TDRtotalAmount += DRtotalAmount;
      TfinalTotal += finalTotal;
      TtotalAmount += totalAmount;
      TCGST += CGST;
      TSGST += SGST;
      TIGST += IGST;
      BillAmount += finalTotal;
    });

    const newtotal = {
      CGST: TCGST,
      SGST: TSGST,
      IGST: TIGST,
      ItemAmount: itemAmount,
      TotalAmount,
      BillAmount,
    };

    if (JSON.stringify(total) !== JSON.stringify(newtotal)) {
      setTotal(newtotal);
    }
  }, [allValues, productList, total, setTotal, allValues.tax_bi_Id]);

  const setGST = (allValues) => {
    if (allValues.tax_bi_Id === 1) {
      if (allValues.in_type === 1) {
        if (total.CGST) {
          const cgstId = !row
            ? trasaction
              .find((x) => x.t_type === allValues.t_type)
              ?.tfields?.find((x) => x?.name === 'CGST')?.accountId
            : row?.entry_details?.find((x) => x?.order === 3)?.accountId;

          const cgst = allValues.entry_details?.findIndex((x) => x.accountId === cgstId);
          if (cgst >= 0) {
            allValues.entry_details[cgst] = {
              ...allValues.entry_details[cgst],
              amount: total.CGST,
              cb: allValues?.t_type === 8 ? 2 : 1,
            };
          } else if (cgstId) {
            allValues.entry_details?.push({
              cb: allValues?.t_type === 8 ? 2 : 1,
              accountId: cgstId,
              entryId: null,
              bentryId: null,
              entry_detailId: null,
              bpoptionId: null,
              ac_no: null,
              amount: total.CGST,
              order: 3,
              in_type: allValues.in_type,
            });
          }
          const sgstId = !row
            ? trasaction
              .find((x) => x.t_type === allValues.t_type)
              ?.tfields.find((x) => x.name === 'SGST')?.accountId
            : row?.entry_details?.find((x) => x?.order === 4)?.accountId;
          const sgst = allValues.entry_details.findIndex((x) => x.accountId === sgstId);
          if (sgst >= 0) {
            allValues.entry_details[sgst] = {
              ...allValues.entry_details[sgst],
              amount: total.SGST,
              cb: allValues?.t_type === 8 ? 2 : 1,
            };
          } else if (sgstId) {
            allValues.entry_details?.push({
              cb: allValues?.t_type === 8 ? 2 : 1,
              accountId: sgstId,
              entryId: null,
              bentryId: null,
              entry_detailId: null,
              bpoptionId: null,
              ac_no: null,
              amount: total.SGST,
              order: 4,
              in_type: allValues.in_type,
            });
          }
          allValues.entry_details.forEach((x, index) => {
            if (x.in_type && x.in_type === 2) allValues.entry_details.splice(index, 1);
          });
        }
      } else if (allValues.in_type === 2) {
        const igstId = !row
          ? trasaction
            .find((x) => x.t_type === allValues.t_type)
            ?.tfields.find((x) => x.name === 'IGST')?.accountId
          : row?.entry_details?.find((x) => x?.order === 3)?.accountId;

        const igst = allValues.entry_details.findIndex((x) => x.accountId === igstId);
        if (igst >= 0) {
          allValues.entry_details[igst] = {
            ...allValues.entry_details[igst],
            accountId: igstId,
            amount: total.IGST,
            cb: allValues?.t_type === 8 ? 2 : 1,
          };
        } else if (igstId) {
          allValues.entry_details?.push({
            cb: allValues?.t_type === 8 ? 2 : 1,
            accountId: igstId,
            entryId: null,
            bentryId: null,
            entry_detailId: null,
            bpoptionId: null,
            ac_no: null,
            amount: total.IGST,
            order: 3,
            in_type: allValues.in_type,
          });
        }
        allValues.entry_details.forEach((x, index) => {
          if (x.in_type && x.in_type === 1) allValues.entry_details.splice(index, 1);
        });
      } else {
        allValues.entry_details.forEach((x, index) => {
          if (x.in_type) allValues.entry_details.splice(index, 1);
        });
      }
    }

    allValues.entry_details.forEach((x, index) => {
      if (x.order === 1) {
        allValues.entry_details[index] = {
          ...x,
          amount: total.BillAmount,
          cb: allValues?.t_type === 8 ? 1 : 2,
        };
      }
      if (x.order === 2) {
        allValues.entry_details[index] = {
          ...x,
          amount: allValues.tax_bi_Id === 1 ? total.ItemAmount : total.BillAmount,
          cb: allValues?.t_type === 8 ? 2 : 1,
        };
      }
    });
    handleSubmitClick(allValues);
  };

  const handleAdd = useCallback(() => {
    inv_details?.append({
      id: null,
      productId: null,
      qty: null,
      rate: null,
      dprice: null,
      drate: null,
      CGST: null,
      SGST: null,
      IGST: null,
    });
    inputRefs.current = [...inputRefs.current, React.createRef()];
    // Scroll to the bottom of the table body
    setTimeout(() => {
      tableBodyRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, 100);
  }, [inv_details]);

  const handleRemove = useCallback(
    (index) => {
      if (index >= 0 && index !== null) {
        inv_details?.remove(index);
      }
    },
    [inv_details]
  );

  const [enterPressCount, setEnterPressCount] = useState(0);

  const HandleProductList = async () => {
    let URL = 'products/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setProductList(data?.rows.map((x) => ({ ...x, a: 'name' })));
  };

  const HandleAccountList = useCallback(async () => {
    let URL = 'accounts/';
    URL += `?page=${allValues.entry_details[0].accountMeta.page
      }&limit=${15}&status=true&creditcard=false&ac_groupId=46,47&name=${debouncedPartyAccount}&`;
    const { data } = await fetcher(URL);
    onScollerAutoComplate({
      data,
      fieldName: 'entry_details.0.accountMeta',
      meta: allValues.entry_details[0].accountMeta,
      setList: setAccountList,
      setValue,
    });
  }, [allValues.entry_details, debouncedPartyAccount, setValue]);

  useEffect(() => {
    HandleAccountList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HandleAccountList, allValues.entry_details[0].accountMeta.page, debouncedPartyAccount]);

  const HandlePAccountList = useCallback(async () => {
    let URL = 'accounts/';
    URL += `?page=${allValues.entry_details[1].accountMeta.page
      }&limit=${15}&status=true&creditcard=false&ac_groupId=${va[allValues.t_type]?.ac_groupId
      }&name=${debouncedOppAccount}&`;

    const { data } = await fetcher(URL);

    onScollerAutoComplate({
      data,
      fieldName: 'entry_details.1.accountMeta',
      meta: allValues.entry_details[1].accountMeta,
      setList: setPAccountList,
      setValue,
    });
  }, [allValues.entry_details, allValues.t_type, debouncedOppAccount, setValue]);

  useEffect(() => {
    HandlePAccountList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HandlePAccountList, allValues.entry_details[1].accountMeta.page, debouncedOppAccount]);

  const HandleCreditAccountList = useCallback(async () => {
    let URL = 'accounts/';
    URL += `?page=${1}&limit=${10000}&status=true&${allValues.c_d === 3 ? 'creditcard=true' : 'ac_groupId=26'
      }&`;
    const { data } = await fetcher(URL);
    setCreditAccountList(data?.rows.map((x) => ({ ...x, a: 'name' })));
  }, [allValues.c_d]);

  useEffect(() => {
    if (allValues.c_d) {
      HandleCreditAccountList();
      HandlePAccountList();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues.c_d]);

  const HandleTransactionList = async () => {
    let URL = 'tsettings/';
    URL += `?page=${1}&limit=${10000}&status=true&`;
    const { data } = await fetcher(URL);
    setTrasaction(data?.rows);
  };

  const handleSubmitClick = async (allValues) => {
    if (allValues?.tax_bi_Id === 3) {
      const unique = new Set();
      trasaction.forEach((x) => {
        x.tfields.forEach((y) => {
          unique.add(y.accountId);
        });
      });
      allValues.entry_details = allValues.entry_details.filter((x) => !unique.has(x.accountId));
    }

    const ct = allValues.entry_details
      .filter((x) => x.cb === 1)
      ?.reduce((pre, next) => pre + next.amount || 0, 0)
      ?.toFixed(2);
    const dt = allValues.entry_details
      .filter((x) => x.cb === 2)
      ?.reduce((pre, next) => pre + next.amount || 0, 0)
      ?.toFixed(2);

    if (ct > 0 && dt > 0 && ct === dt) {
      setValue(
        'inv_details',
        allValues.inv_details.filter((x, index) => x.rate && x.qty)
      );
      checkTranscription();
      if (isSuccessful) {
        await trigger();
        onSubmit();
      }
    } else {
      alert(`Credit ${ct} and debit ${dt} amounts should be the same.`);
    }
  };

  const checkTranscription = useCallback(() => {
    if (allValues.tax_bi_Id === 1 && [8, 9].includes(allValues.t_type)) {
      const a = ['SGST', 'CGST', 'IGST'];
      const findtr = trasaction.find((x) => x.t_type === allValues.t_type);
      if (findtr) {
        const f = findtr.tfields.filter((x) => a.includes(x.name) && x.accountId).length === 3;
        if (f) {
          setisSuccessful(true);
        } else {
          invoiceSettings.onTrue();
          setisSuccessful(false);
        }
      } else if (row === null) {
        invoiceSettings.onTrue();
      }
    } else {
      setisSuccessful(true);
    }
  }, [allValues.t_type, allValues.tax_bi_Id, invoiceSettings, row, trasaction]);

  const lastentry = useCallback(async () => {
    const URL = `entries/last/?t_type=${allValues.t_type}`;
    const { data } = await fetcher(URL);
    if (data?.v_no)
      setValue('v_no', data?.v_no);
  }, [allValues.t_type, setValue]);

  useEffect(() => {
    if (allValues.t_type)
      lastentry();
  }, [allValues.t_type, lastentry])

  useEffect(() => {
    if (trasaction) checkTranscription();
  }, [checkTranscription, trasaction]);

  useEffect(() => {
    if (open) {
      HandleProductList();
      HandleAccountList();
      HandleCreditAccountList();
      HandlePAccountList();
      HandleTransactionList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (invoiceSettings.value) {
      HandleTransactionList();
    }
  }, [invoiceSettings.value]);

  const handleKeyDown = (event) => {
    if (event.altKey && event.key === 'a') {
      event.stopPropagation();
      handleAdd();
    }
    if (event.altKey && event.key === 'r') {
      event.stopPropagation();
      handleRemove(currentRowIndex);
    }
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };
  useEventListener('keydown', handleKeyDown);

  const handleCloseAccount = () => {
    quickEditAccount.onFalse();
    HandleAccountList();
    HandleProductList();
  };

  const handleCloseProduct = () => {
    quickEditProduct.onFalse();
    quickEditGSTCommdity.onFalse();
    HandleProductList();
  };

  const handleCloseCredit = () => {
    quickEdit.onFalse();
    HandleCreditAccountList();
  };

  const deleteProductModel = useCallback(
    async (id) => {
      try {
        let URL = 'products/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          HandleProductList();
          confirm.onFalse();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [enqueueSnackbar, confirm]
  );

  const deleteAccountModel = useCallback(
    async (id) => {
      try {
        let URL = 'accounts/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          HandleAccountList();
          HandlePAccountList();
          confirm.onFalse();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [HandleAccountList, HandlePAccountList, confirm, enqueueSnackbar]
  );
  // This effect adjusts the cursor position
  useEffect(() => {
    allValues.inv_details.forEach((detail, index) => {
      const input = inputRefs?.current[index]?.current;
      if (input) {
        const { value } = input;
        if (value.endsWith('.00')) {
          const position = value.length - 3; // Position before '.00'
          input.setSelectionRange(position, position);
        }
      }
    });
  }, [allValues.inv_details]); //
  const itemTotal = allValues?.inv_details.filter((x) => x.rate);

  return (
    <>
      <Dialog fullScreen onKeyDown={handleKeyDown} open={open}>
        <FormProvider methods={methods}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                top: 0,
                zIndex: 9,
                position: 'sticky',
                bgcolor: (theme) => alpha(theme.palette.background.default, 1),
              }}
            >
              <Toolbar>
                <Typography variant="h5" sx={{ flex: 1 }}>
                  {row ? `Edit Invoice` : `Add Invoice`}
                </Typography>

                <Grid container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      freeSolo
                      size="small"
                      options={INVOI_TYPE?.map((option) => option.name)}
                      onBlur={(e) => {
                        if (!allValues.t_type) {
                          document.getElementById(e.target.id).focus();
                        }
                      }}
                      getOptionLabel={(option) => option}
                      value={INVOI_TYPE?.find((data) => data.id === allValues.t_type)?.name || ''}
                      openOnFocus={!quickEditAccount.value && !row?.id}
                      onChange={(event, newValue) => {
                        tranType = INVOI_TYPE?.find((data) => data.name === newValue)?.id;
                        setValue('t_type', INVOI_TYPE?.find((data) => data.name === newValue)?.id);
                      }}
                      id="invoice_type"
                      renderInput={(params) => (
                        <TextField {...params} name="t_type" label="Invoice Type" autoFocus />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                      ListboxProps={{ ...bgColorAutocomplete }}
                      disabled={row}
                    />
                  </Grid>
                </Grid>

                <CompanyYear />

                <IconButton color="inherit" edge="start" onClick={onClose} tabIndex={-1}>
                  <Iconify icon="mingcute:close-line" />
                </IconButton>
              </Toolbar>
              <Divider sx={{ borderStyle: 'dashed' }} />
            </Grid>

            <Grid item container xs={12} md={12} sx={{ p: 2 }} columnSpacing={10}>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                spacing={2}
                alignContent="start"
                justifyContent="space-between"
              >
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    options={CASH_DEBIT?.map((option) => option.name)}
                    onBlur={(e) => {
                      if (!allValues.c_d && pAccountList.length) {
                        document.getElementById(e.target.id).focus();
                      }
                    }}
                    disabled={!allValues.t_type || row}
                    openOnFocus={!quickEditAccount.value}
                    getOptionLabel={(option) => option}
                    value={CASH_DEBIT?.find((data) => data.id === allValues.c_d)?.name || ''}
                    onChange={(event, newValue) => {
                      setValue('c_d', CASH_DEBIT?.find((data) => data.name === newValue)?.id);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} name="c_d" label="Cash/Debit" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                  {(allValues?.c_d === 1 || allValues?.c_d === 3) && (
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      size="small"
                      disableCloseOnSelect
                      options={
                        creditAccountList.length
                          ? creditAccountList
                          : [{ name: 'No Options', value: null }]
                      }
                      filterOptions={(options, state) =>
                        creditAccountList.length
                          ? creditAccountList
                          : [{ name: 'No Options', value: null }]
                      }
                      groupBy={(option) => option?.a}
                      getOptionLabel={(option) => option.name}
                      onBlur={(e) => {
                        if (!allValues.otheraccountId) {
                          document.getElementById(e.target.id).focus();
                        }
                      }}
                      disabled={!allValues.c_d}
                      openOnFocus={!quickEdit.value}
                      onChange={(event, newValue) => {
                        setCredit(newValue);
                        setValue('otheraccountId', newValue?.id);
                      }}
                      value={
                        creditAccountList?.find((data) => data.id === allValues.otheraccountId) ||
                        row?.otheraccount
                      }
                      componentsProps={{
                        paper: {
                          sx: {
                            width: 400,
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${allValues.c_d === 1 ? 'Cash' : 'Credit Card'} A/c.`}
                          name="otheraccountId"
                          error={errors?.otheraccountId && !allValues?.otheraccountId}
                        />
                      )}
                      renderGroup={(params) => (
                        <li key={params.key}>
                          <GroupHeader component="li">
                            <ListItem sx={{ padding: 0, margin: 0 }}>Account Name</ListItem>
                            <ListItem sx={{ padding: 0, margin: 0 }}>Effect on</ListItem>
                          </GroupHeader>
                          <GroupItems>{params.children}</GroupItems>
                          <GroupFooter component="li">
                            <Button
                              onClick={() => {
                                setCredit(null);
                                quickEdit.onTrue();
                              }}
                            >
                              Add
                            </Button>
                            {credit && (
                              <Button
                                onClick={() => {
                                  quickEdit.onTrue();
                                }}
                              >
                                Edit
                              </Button>
                            )}
                          </GroupFooter>
                        </li>
                      )}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                            {option?.name}
                          </ListItem>
                          <ListItem sx={{ padding: 0, margin: 0 }}>
                            {option.ac_group ? option.ac_group?.name : '-'}
                          </ListItem>
                        </Box>
                      )}
                      ListboxProps={{ ...bgColorAutocomplete }}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    size="small"
                    disableCloseOnSelect
                    options={
                      accountList.length ? accountList : [{ name: 'No Options', value: null }]
                    }
                    groupBy={(option) => option?.a}
                    getOptionLabel={(option) => option.name}
                    filterOptions={(options, state) =>
                      accountList.length ? accountList : [{ name: 'No Options', value: null }]
                    }
                    onFocus={() => {
                      setaccountEditList(
                        accountList?.find(
                          (data) => data.id === allValues.entry_details[0].accountId
                        )
                      );
                    }}
                    inputValue={allValues.entry_details[0].accountMeta.name}
                    onInputChange={(event, newInputValue) => {
                      setValue('entry_details.0.accountMeta', {
                        ...allValues.entry_details[0].accountMeta,
                        page: 1,
                        name: newInputValue,
                      });
                    }}
                    onBlur={(e) => {
                      if (
                        !allValues.entry_details.find((x) => x.order === 1)?.accountId &&
                        accountList.length &&
                        !quickEditAccount.value
                      ) {
                        document.getElementById(e.target.id).focus();
                      }
                    }}
                    disabled={
                      (allValues.c_d !== 2 ? !allValues.otheraccountId : !allValues.c_d) || row
                    }
                    openOnFocus={!quickEditAccount.value}
                    onChange={(event, newValue) => {
                      setaccountEditList(newValue);
                      setPAccountEditList(null);
                      setValue('gst_no', newValue?.gst_no);
                      setValue('entry_details.0.accountId', newValue?.id);
                    }}
                    value={(() => {
                      const addRecord = accountList?.find(
                        (data) => data.id === allValues.entry_details[0].accountId
                      );
                      const editRecord = row?.entry_details[0]?.account;

                      if (addRecord) return addRecord;
                      if (editRecord) return editRecord;
                      return null;
                    })()}
                    onHighlightChange={(event, newValue) => {
                      setaccountEditList(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Party A/C."
                        name="entry_details[0].accountId"
                        error={
                          errors && entry_details.length > 0 && errors?.entry_details[0].accountId
                        }
                      />
                    )}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader component="li">
                          <ListItem sx={{ padding: 0, margin: 0 }}>Account Name</ListItem>
                          <ListItem
                            sx={{ padding: 0, margin: 0, justifyContent: 'end', width: '30%' }}
                          >
                            Balance
                          </ListItem>
                        </GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                        <GroupFooter component="li" sx={{ display: 'block' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-around',
                              bgcolor: (theme) => alpha(theme.palette.primary.lighter, 1),
                            }}
                          >
                            <Button
                              onClick={() => {
                                setaccountEditList(null);
                                setPAccountEditList(null);
                                quickEditAccount.onTrue();
                              }}
                            >
                              Add
                            </Button>
                            {accountEditList?.id && (
                              <>
                                <Button
                                  onClick={() => {
                                    setPAccountEditList(null);
                                    quickEditAccount.onTrue();
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  onClick={() => {
                                    confirm.onTrue();
                                  }}
                                >
                                  Delete
                                </Button>
                              </>
                            )}
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              color: 'black',
                              px: 2,
                              bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={7} md={7}>
                                {accountEditList?.address && (
                                  <>
                                    <Typography variant="body2">
                                      <strong>Address</strong>
                                    </Typography>
                                    <Typography variant="body2">
                                      {accountEditList?.address || '-'}, <br />
                                      {accountEditList?.district &&
                                        `${accountEditList?.district}, `}
                                      {accountEditList?.city?.name &&
                                        `${accountEditList?.city?.name}, `}
                                      {accountEditList?.state?.name &&
                                        `${accountEditList?.state?.name}, `}
                                      {accountEditList?.country?.name &&
                                        `${accountEditList?.country?.name}.`}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                              <Grid item xs={5} md={5} sx={{ textAlign: 'left' }}>
                                {accountEditList?.mobile && (
                                  <Typography variant="body2">
                                    Mobile No. {accountEditList?.mobile || '-'}
                                  </Typography>
                                )}

                                {accountEditList?.gst_no && (
                                  <Typography variant="body2">
                                    GST No. {accountEditList?.gst_no || '-'}
                                  </Typography>
                                )}

                                {accountEditList?.pan_no && (
                                  <Typography variant="body2">
                                    PAN No. {accountEditList?.pan_no || '-'}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        </GroupFooter>
                      </li>
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                          {option?.name}
                        </ListItem>
                        <ListItem
                          sx={{ padding: 0, margin: 0, justifyContent: 'end', width: '30%' }}
                        >
                          {option?.closingBalance
                            ? fMoney.format(option?.closingBalance, { symbol: '₹ ' })
                            : '-'}
                        </ListItem>
                      </Box>
                    )}
                    componentsProps={{
                      paper: {
                        sx: {
                          width: 600,
                        },
                      },
                    }}
                    ListboxProps={{
                      ...bgColorAutocomplete,
                      style: { maxHeight: '70vh' },
                      onScroll: (event) =>
                        handleScroll({
                          event,
                          allValues,
                          setValue,
                          fieldName: 'entry_details.0.accountMeta',
                          meta: allValues.entry_details[0].accountMeta,
                        }),
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                spacing={2}
                alignContent="start"
              >
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={2}>
                  <Grid item xs={12} sm={12} md={4.5} lg={4.5} xl={4.5} xxl={4.5}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      freeSolo
                      size="small"
                      options={Tax_BilSupply?.map((option) => option.name)}
                      getOptionLabel={(option) => option}
                      value={
                        Tax_BilSupply?.find((data) => data.id === allValues.tax_bi_Id)?.name || ''
                      }
                      onBlur={(e) => {
                        if (!allValues.tax_bi_Id) {
                          document.getElementById(e.target.id).focus();
                        }
                      }}
                      disabled={!allValues.entry_details[0].accountId || row}
                      openOnFocus={!quickEditAccount.value}
                      onChange={(event, newValue) => {
                        setValue(
                          'tax_bi_Id',
                          Tax_BilSupply?.find((data) => data.name === newValue)?.id
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} name="tax_bi_Id" label="Tax/Bill of Supply" />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                      componentsProps={{
                        paper: {
                          sx: {
                            width: 200,
                          },
                        },
                      }}
                      ListboxProps={{ ...bgColorAutocomplete }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                    <RHFTextField
                      name="v_no"
                      label="Vch No."
                      size="small"
                      value={allValues.v_no || ''}
                      disabled={!allValues.tax_bi_Id}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4.5} lg={4.5} xl={4.5} xxl={4.5}>
                    <Controller
                      name="dt"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DatePicker
                          defaultValue={new Date(field.value)}
                          timezone="Asia/Kolkata"
                          label="Voucher Date"
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          disabled={!allValues.tax_bi_Id}
                          format="dd/MM/yyyy"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!error,
                              helperText: error?.message,
                              size: 'small',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: allValues?.gst_no ? 'text.primary' : 'red',
                      padding: '5px',
                      textAlign: 'center',
                    }}
                  >
                    {allValues?.gst_no ? `GST IN : ${allValues?.gst_no}` : 'NILL'}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                spacing={2}
                alignContent="start"
              >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField
                    name="bill_no"
                    label="Bill No."
                    size="small"
                    value={allValues.bill_no || ''}
                    disabled={!allValues.tax_bi_Id}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Controller
                    name="b_date"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <DatePicker
                        defaultValue={new Date(field.value)}
                        timezone="Asia/Kolkata"
                        label="Bill Date"
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        disabled={!allValues.tax_bi_Id}
                        format="dd/MM/yyyy"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                            size: 'small',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    size="small"
                    disableCloseOnSelect
                    options={
                      pAccountList.length ? pAccountList : [{ name: 'No Options', value: null }]
                    }
                    groupBy={(option) => option?.a}
                    getOptionLabel={(option) => option.name}
                    filterOptions={(options, state) =>
                      pAccountList.length ? pAccountList : [{ name: 'No Options', value: null }]
                    }
                    inputValue={allValues.entry_details[1].accountMeta.name}
                    onInputChange={(event, newInputValue) => {
                      setValue('entry_details.1.accountMeta', {
                        ...allValues.entry_details[1].accountMeta,
                        page: 1,
                        name: newInputValue,
                      });
                    }}
                    onBlur={(e) => {
                      if (
                        !allValues.entry_details.find((x) => x.order === 2)?.accountId &&
                        pAccountList.length &&
                        !quickEditAccount.value
                      ) {
                        document.getElementById(e.target.id).focus();
                      }
                    }}
                    onFocus={() => {
                      setPAccountEditList(
                        pAccountList?.find(
                          (data) => data.id === allValues.entry_details[1].accountId
                        )
                      );
                    }}
                    disabled={!allValues.tax_bi_Id}
                    openOnFocus={!quickEditAccount.value}
                    onChange={(event, newValue) => {
                      setPAccountEditList(newValue);
                      setaccountEditList(null);
                      setValue('entry_details.1.accountId', newValue?.id);
                    }}
                    value={(() => {
                      const addRecord = pAccountList?.find(
                        (data) => data.id === allValues.entry_details[1].accountId
                      );
                      const editRecord = row?.entry_details[1]?.account;

                      if (addRecord) return addRecord;
                      if (editRecord) return editRecord;
                      return null;
                    })()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${allValues.t_type === 8 ? 'Purchase' : 'Sale'} A/C.`}
                        name="entry_details[1].accountId"
                        error={
                          errors && entry_details.length > 0 && errors?.entry_details[1].accountId
                        }
                      />
                    )}
                    onHighlightChange={(event, newValue) => {
                      setPAccountEditList(newValue);
                    }}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader component="li">
                          <ListItem sx={{ padding: 0, margin: 0 }}>Account Name</ListItem>
                          <ListItem
                            sx={{ padding: 0, margin: 0, justifyContent: 'end', width: '30%' }}
                          >
                            Balance
                          </ListItem>
                        </GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                        <GroupFooter component="li" sx={{ display: 'block' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-around',
                              bgcolor: (theme) => alpha(theme.palette.primary.lighter, 1),
                            }}
                          >
                            <Button
                              onClick={() => {
                                setaccountEditList(null);
                                setPAccountEditList(null);
                                quickEditAccount.onTrue();
                              }}
                            >
                              Add
                            </Button>
                            {PaccountEditList?.id && (
                              <>
                                <Button
                                  onClick={() => {
                                    setaccountEditList(null);
                                    quickEditAccount.onTrue();
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  onClick={() => {
                                    confirm.onTrue();
                                  }}
                                >
                                  Delete
                                </Button>
                              </>
                            )}
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              color: 'black',
                              px: 2,
                              bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={6} md={6}>
                                {PaccountEditList?.address && (
                                  <>
                                    <Typography variant="body2">
                                      <strong>Address</strong>
                                    </Typography>
                                    <Typography variant="body2">
                                      {PaccountEditList?.address || '-'}
                                      {PaccountEditList?.district &&
                                        `${PaccountEditList?.district}, `}
                                      {PaccountEditList?.city?.name &&
                                        `${PaccountEditList?.city?.name}, `}
                                      {PaccountEditList?.state?.name &&
                                        `${PaccountEditList?.state?.name}, `}
                                      {PaccountEditList?.country?.name &&
                                        `${PaccountEditList?.country?.name}.`}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                              <Grid item xs={6} md={6} sx={{ textAlign: 'left' }}>
                                {PaccountEditList?.mobile && (
                                  <Typography variant="body2">
                                    Mobile No. {PaccountEditList?.mobile || '-'}
                                  </Typography>
                                )}

                                {PaccountEditList?.gst_no && (
                                  <Typography variant="body2">
                                    GST No. {PaccountEditList?.gst_no || '-'}
                                  </Typography>
                                )}

                                {PaccountEditList?.pan_no && (
                                  <Typography variant="body2">
                                    PAN No. {PaccountEditList?.pan_no || '-'}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        </GroupFooter>
                      </li>
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                          {option?.name}
                        </ListItem>
                        <ListItem
                          sx={{ padding: 0, margin: 0, justifyContent: 'end', width: '30%' }}
                        >
                          {option?.closingBalance
                            ? fMoney.format(option?.closingBalance, { symbol: '₹ ' })
                            : '-'}
                        </ListItem>
                      </Box>
                    )}
                    ListboxProps={{
                      ...bgColorAutocomplete,
                      style: { maxHeight: '70vh' },
                      onScroll: (event) =>
                        handleScroll({
                          event,
                          allValues,
                          setValue,
                          fieldName: 'entry_details.1.accountMeta',
                          meta: allValues.entry_details[1].accountMeta,
                        }),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item container xs={12} md={12} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <TableContainer
                  component={Paper}
                  sx={{ overflow: 'auto', maxHeight: '30vh', width: '98%', margin: 'auto' }}
                >
                  <Table
                    stickyHeader
                    aria-label="simple table"
                    sx={{
                      '& .MuiTableCell-sizeMedium': {
                        padding: '0px !important',
                      },
                      '& fieldset': { borderRadius: '0px', border: 'none' },
                      '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                      '& .MuiOutlinedInput-root': { padding: '0px !important' },
                      '& .autoComplete>div': { padding: '0px !important' },
                      '& .MuiTableCell-root': {
                        border: '0.01px solid #e9ecee',
                      },
                      '& .MuiTableCell-head': {
                        padding: '5px !important',
                        borderRight: '1px solid #d0d1d2 !important',
                      },
                      '& .MuiTableCell-footer': {
                        padding: '5px !important',
                      },
                      '& .MuiTableCell-head:last-child': {
                        borderRight: '0px !important',
                      },
                      '&:last-child td': {
                        borderColor: '#e9ecee !important',
                      },
                    }}
                  >
                    <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
                      <TableRow>
                        <TableCell sx={{ width: 20, textAlign: 'center' }}>No</TableCell>
                        <TableCell sx={{ width: 400, textAlign: 'center' }}>Product Name</TableCell>
                        <TableCell sx={{ width: 70, textAlign: 'center' }}>Unit</TableCell>
                        <TableCell sx={{ width: 70, textAlign: 'center' }}>Qty</TableCell>
                        <TableCell sx={{ width: 70, textAlign: 'center' }}>Rate</TableCell>
                        <TableCell sx={{ width: 70, textAlign: 'center' }}>Disc.(₹)</TableCell>
                        <TableCell sx={{ width: 70, textAlign: 'center' }}>Disc.(%)</TableCell>
                        {[1, 2, 4, 5].includes(allValues.tax_bi_Id) && (
                          <>
                            <TableCell sx={{ width: 100, textAlign: 'center' }}>
                              Gross Amount
                            </TableCell>
                            <TableCell sx={{ width: 40, textAlign: 'center' }}>GST(%)</TableCell>

                            {allValues.in_type === 1 && (
                              <>
                                <TableCell sx={{ width: 80, textAlign: 'center' }}>CGST</TableCell>
                                <TableCell sx={{ width: 80, textAlign: 'center' }}>SGST</TableCell>
                              </>
                            )}
                            {allValues.in_type === 2 && (
                              <TableCell sx={{ width: 80, textAlign: 'center' }}>IGST</TableCell>
                            )}
                          </>
                        )}

                        <TableCell
                          sx={{ width: 130, textAlign: 'center', position: 'sticky', right: 0 }}
                        >
                          Amount
                        </TableCell>
                        <TableCell
                          sx={{ width: 10, textAlign: 'center', position: 'sticky', right: 0 }}
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {inv_details?.fields?.map((field, index) => {
                        const GSTCommodity = productList.find(
                          (x) => x.id === allValues.inv_details[index]?.productId
                        )?.gst_commodity;
                        const gstRate = nearestDateList(
                          productList.find((x) => x.id === allValues.inv_details[index]?.productId)
                            ?.gst_commodity?.gst_com_details
                        );
                        let DPtotalAmount = 0;
                        let DRtotalAmount = 0;
                        let GrossAmount = 0;
                        let finalTotal = 0;
                        let totalAmount = 0;
                        let CGST = 0;
                        let SGST = 0;
                        let IGST = 0;

                        if (
                          allValues.inv_details[index]?.rate &&
                          allValues.inv_details[index]?.qty
                        ) {
                          totalAmount =
                            allValues.inv_details[index].rate * allValues.inv_details[index].qty;
                        }
                        if (allValues.inv_details[index]?.dprice) {
                          DPtotalAmount =
                            (allValues.inv_details[index].rate -
                              allValues.inv_details[index].dprice) *
                            allValues.inv_details[index].qty;
                        }
                        if (allValues.inv_details[index]?.drate) {
                          const rq =
                            allValues.inv_details[index].rate * allValues.inv_details[index].qty;
                          DRtotalAmount = rq - (rq / 100) * allValues.inv_details[index].drate;
                        }

                        if (DPtotalAmount || allValues.inv_details[index]?.dprice) {
                          finalTotal = DPtotalAmount;
                        } else if (DRtotalAmount || allValues.inv_details[index]?.drate) {
                          finalTotal = DRtotalAmount;
                        } else {
                          finalTotal = totalAmount;
                        }
                        GrossAmount = finalTotal;
                        if (allValues?.in_type === 1 && [1, 4].includes(allValues.tax_bi_Id)) {
                          SGST = (finalTotal / 100 || 0) * (gstRate?.gst_rate?.SGST || 0);
                          CGST = (finalTotal / 100 || 0) * (gstRate?.gst_rate?.CGST || 0);
                          if (allValues?.tax_bi_Id === 1) finalTotal += CGST + SGST;
                        } else if (
                          allValues?.in_type === 2 &&
                          [1, 4].includes(allValues.tax_bi_Id)
                        ) {
                          IGST = (finalTotal / 100 || 0) * (gstRate?.gst_rate?.IGST || 0);
                          if (allValues?.tax_bi_Id === 1) finalTotal += IGST;
                        }

                        gTotal += GrossAmount;

                        return (
                          <TableRow key={index} ref={tableBodyRef}>
                            <TableCell
                              sx={{
                                textAlign: 'center',
                                position: 'sticky',
                                left: 0,
                              }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              sx={
                                index >= 0 &&
                                errors &&
                                errors?.inv_details &&
                                errors?.inv_details[index] &&
                                errors?.inv_details[index]?.productId && {
                                  border: '0.01px solid #F90635 !important',
                                }
                              }
                            >
                              <Autocomplete
                                autoHighlight={allValues.inv_details[index].productName?.length > 0}
                                key={`${field.productId}-${index}`}
                                onFocus={(e) => {
                                  setCurrentRowIndex(index);
                                }}
                                disabled={!allValues.entry_details[1].accountId > 0}
                                onBlur={(e) => {
                                  if (allValues.inv_details[0]?.productId) {
                                    setEnterPressCount((prevCount) => prevCount + 1);
                                    if (enterPressCount === 1) {
                                      document.getElementById('narration-field').focus();
                                      setEnterPressCount(0);
                                    } else if (
                                      !allValues.inv_details[index]?.productId &&
                                      !quickEditProduct
                                    ) {
                                      document.getElementById(e.target.id).focus();
                                    }
                                    setCurrentRowIndex(null);
                                  } else {
                                    document.getElementById(e.target.id).focus();
                                  }
                                }}
                                fullWidth
                                freeSolo
                                size="small"
                                options={
                                  productList.length
                                    ? productList
                                    : [{ name: 'No Option', value: null }]
                                }
                                openOnFocus={!quickEditProduct.value}
                                getOptionDisabled={(option) => option?.value === null}
                                getOptionLabel={(option) => option.name || ''}
                                value={(() => {
                                  const fna =
                                    productList?.find(
                                      (data) => data?.id === allValues.inv_details[index]?.productId
                                    ) || row?.inv_details[index]?.product;

                                  return fna || '';
                                })()}
                                onInputChange={(e) => {
                                  if (e?.target?.value)
                                    setValue(`inv_details[${index}].productName`, e.target.value);
                                }}
                                onChange={async (event, newValue) => {
                                  setProductEditValues(
                                    productList?.find((data) => data?.name === newValue?.name)
                                  );

                                  setEnterPressCount(0); // Reset the coun
                                  if (allValues.in_type) {
                                    setValue(
                                      `inv_details[${index}].productId`,
                                      productList?.find((data) => data?.name === newValue?.name)?.id
                                    );
                                  }
                                }}
                                groupBy={(option) => option?.a}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    name={`inv_details[${index}].productId`}
                                    className="autoComplete"
                                  />
                                )}
                                renderGroup={(params) => (
                                  <li key={params.key}>
                                    <GroupHeader component="li">
                                      <ListItem sx={{ padding: 0, margin: 0 }}>
                                        Product Name
                                      </ListItem>
                                    </GroupHeader>
                                    <GroupItems>{params.children}</GroupItems>
                                    <GroupFooter component="li">
                                      <Button
                                        onClick={() => {
                                          quickEditProduct.onTrue();
                                          setProductEditValues(null);
                                        }}
                                      >
                                        Add
                                      </Button>

                                      {productEditValues?.id && (
                                        <>
                                          <Button
                                            onClick={() => {
                                              quickEditProduct.onTrue();
                                            }}
                                          >
                                            Edit
                                          </Button>
                                          <Button
                                            onClick={() => {
                                              confirm.onTrue();
                                            }}
                                          >
                                            Delete
                                          </Button>
                                        </>
                                      )}
                                    </GroupFooter>
                                  </li>
                                )}
                                renderOption={(props, option) => (
                                  <Box component="li" {...props}>
                                    <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                      {option?.name}
                                    </ListItem>
                                  </Box>
                                )}
                                ListboxProps={{ ...bgColorAutocomplete }}
                              />
                            </TableCell>

                            <TableCell sx={{ textAlign: 'end' }}>
                              {productList.find(
                                (x) => x.id === allValues.inv_details[index]?.productId
                              )?.punit?.name || ''}
                            </TableCell>

                            <TableCell
                              sx={
                                index >= 0 &&
                                  errors &&
                                  errors?.inv_details &&
                                  errors?.inv_details[index] &&
                                  errors?.inv_details[index]?.qty
                                  ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                  : { textAlign: 'end' }
                              }
                            >
                              <RHFTextField
                                onFocus={(e) => {
                                  setCurrentRowIndex(index);
                                }}
                                onBlur={(e) => {
                                  if (!allValues.inv_details[index]?.qty) {
                                    setValue(`inv_details[${index}].qty`, 1);
                                    document.getElementById(e.target.id).focus();
                                  }
                                  setCurrentRowIndex(null);
                                }}
                                inputProps={{
                                  style: { textAlign: 'right' },
                                }}
                                name={`inv_details[${index}].qty`}
                                size="small"
                                type="number"
                                value={allValues.inv_details[index]?.qty || ''}
                                onChange={(e) => {
                                  const qty = e.target.value >= 0 && e.target.value;
                                  setValue(`inv_details[${index}].qty`, qty);
                                }}
                              // disabled={!(allValues.inv_details[index]?.productId > 0)}
                              />
                            </TableCell>

                            <TableCell
                              sx={
                                index >= 0 &&
                                  errors &&
                                  errors?.inv_details &&
                                  errors?.inv_details[index] &&
                                  errors?.inv_details[index]?.rate
                                  ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                  : { textAlign: 'end' }
                              }
                            >
                              <RHFTextField
                                inputProps={{
                                  style: { textAlign: 'right' },
                                }}
                                onFocus={(e) => {
                                  setCurrentRowIndex(index);
                                }}
                                onBlur={(e) => {
                                  if (!allValues.inv_details[index]?.productId) {
                                    document.getElementById(e.target.id).focus();
                                  }
                                  let { rate } = allValues.inv_details[index];
                                  if (!isNaN(rate) && rate >= 0) {
                                    rate = Number(rate).toFixed(2);
                                    setValue(`inv_details[${index}].rate`, rate);
                                  }
                                  setCurrentRowIndex(null);
                                }}
                                name={`inv_details[${index}].rate`}
                                size="small"
                                type="number"
                                value={allValues.inv_details[index]?.rate || ''}
                                disabled={!(allValues.inv_details[index]?.qty > 0)}
                                onChange={(e) => {
                                  let rate = e.target.value;
                                  rate = parseFloat(rate);
                                  setValue(`inv_details[${index}].rate`, rate);
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <RHFTextField
                                onFocus={(e) => {
                                  setCurrentRowIndex(index);
                                }}
                                onBlur={(e) => {
                                  setCurrentRowIndex(null);
                                }}
                                inputProps={{
                                  style: { textAlign: 'right' },
                                }}
                                name={`inv_details[${index}].dprice`}
                                size="small"
                                type="number"
                                value={
                                  parseFloat(allValues.inv_details[index]?.dprice) ||
                                  (allValues.inv_details[index]?.drate > 0 &&
                                    allValues.inv_details[index]?.rate > 0 &&
                                    (
                                      (parseFloat(allValues.inv_details[index]?.rate) *
                                        parseFloat(allValues.inv_details[index]?.drate)) /
                                      100
                                    )?.toFixed(2))
                                }
                                onChange={(e) => {
                                  if (
                                    !isNaN(parseFloat(e.target.value)) &&
                                    !isNaN(parseFloat(allValues.inv_details[index]?.rate))
                                  ) {
                                    const disPrice =
                                      parseFloat(e.target.value) >= 0 &&
                                      parseFloat(e.target.value) <=
                                      parseFloat(allValues.inv_details[index]?.rate) &&
                                      parseFloat(e.target.value);
                                    setValue(`inv_details[${index}].dprice`, parseFloat(disPrice));
                                  } else {
                                    setValue(`inv_details[${index}].dprice`, null);
                                  }
                                }}
                                disabled={
                                  !(allValues.inv_details[index]?.rate > 0) ||
                                  allValues.inv_details[index]?.drate
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <RHFTextField
                                onFocus={(e) => {
                                  setCurrentRowIndex(index);
                                }}
                                onBlur={(e) => {
                                  setCurrentRowIndex(null);
                                }}
                                inputProps={{
                                  style: { textAlign: 'right' },
                                }}
                                name={`inv_details[${index}].drate`}
                                size="small"
                                type="number"
                                value={
                                  parseFloat(allValues.inv_details[index]?.drate) ||
                                  (allValues.inv_details[index]?.dprice > 0 &&
                                    allValues.inv_details[index]?.rate > 0 &&
                                    (
                                      (parseFloat(allValues.inv_details[index]?.dprice) /
                                        parseFloat(allValues.inv_details[index]?.rate)) *
                                      100
                                    )?.toFixed(2))
                                }
                                onChange={(e) => {
                                  const disRate =
                                    e.target.value >= 0 && e.target.value <= 100 && e.target.value;
                                  setValue(`inv_details[${index}].drate`, parseFloat(disRate));
                                }}
                                disabled={
                                  !(allValues.inv_details[index]?.rate > 0) ||
                                  allValues.inv_details[index]?.dprice
                                }
                              />
                            </TableCell>

                            {[1, 2, 4, 5].includes(allValues.tax_bi_Id) && (
                              <>
                                <TableCell sx={{ textAlign: 'end' }}>
                                  {GrossAmount ? GrossAmount?.toFixed(2) : ''}
                                </TableCell>
                                <TableCell
                                  sx={{ textAlign: 'end' }}
                                  onClick={() => {
                                    if (allValues.inv_details[index]?.productId) {
                                      setGSTCommodity({
                                        ...GSTCommodity,
                                        productId: allValues.inv_details[index]?.productId,
                                      });
                                      quickEditGSTCommdity.setValue(true);
                                    }
                                  }}
                                >
                                  {gstRate?.gst_rate?.IGST ? `${gstRate?.gst_rate?.IGST} %` : ''}
                                </TableCell>
                                {allValues.in_type === 1 && (
                                  <>
                                    <TableCell sx={{ textAlign: 'end' }}>
                                      {CGST ? CGST?.toFixed(2) : ''}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: 'end' }}>
                                      {SGST ? SGST?.toFixed(2) : ''}
                                    </TableCell>
                                  </>
                                )}
                                {allValues.in_type === 2 && (
                                  <TableCell sx={{ textAlign: 'end' }}>
                                    {IGST ? IGST?.toFixed(2) : ''}
                                  </TableCell>
                                )}
                              </>
                            )}

                            <TableCell
                              sx={{ textAlign: 'end', position: 'sticky', right: 0, index: 9 }}
                            >
                              {finalTotal ? finalTotal?.toFixed(2) : ''}
                            </TableCell>

                            {allValues.inv_details.length > 1 ? (
                              <TableCell
                                sx={{
                                  textAlign: 'center',
                                  position: 'sticky',
                                  right: 0,
                                  index: 9,
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleRemove(index)}
                              >
                                <Iconify icon="solar:trash-bin-trash-bold" color="red" />
                              </TableCell>
                            ) : (
                              <TableCell sx={{ textAlign: 'end' }} />
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <TableFooter
                      sx={{
                        bottom: '0',
                        position: 'sticky',
                        zIndex: '11111',
                        bgcolor: (theme) => alpha(theme.palette.background.default, 1),
                      }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '15px' }}
                          colSpan={2}
                        >
                          Total Entry : {itemTotal?.length ? itemTotal?.length : '0'}
                        </TableCell>

                        <TableCell sx={{ textAlign: 'center' }} />
                        <TableCell sx={{ textAlign: 'end', fontWeight: 'bold', fontSize: '15px' }}>
                          {TotalQty?.toFixed(2) || ''}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }} />
                        <TableCell sx={{ textAlign: 'end', fontWeight: 'bold', fontSize: '15px' }}>
                          {TotalDPrice?.toFixed(2) || ''}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }} />
                        {[1, 2, 4, 5].includes(allValues.tax_bi_Id) && (
                          <>
                            <TableCell
                              sx={{ textAlign: 'end', fontWeight: 'bold', fontSize: '15px' }}
                            >
                              {gTotal?.toFixed(2) || ''}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }} />
                            {allValues.in_type === 1 && (
                              <>
                                <TableCell
                                  sx={{ textAlign: 'end', fontWeight: 'bold', fontSize: '15px' }}
                                >
                                  {total.CGST?.toFixed(2)}
                                </TableCell>
                                <TableCell
                                  sx={{ textAlign: 'end', fontWeight: 'bold', fontSize: '15px' }}
                                >
                                  {total.SGST?.toFixed(2)}
                                </TableCell>
                              </>
                            )}
                            {allValues.in_type === 2 && (
                              <TableCell
                                sx={{ textAlign: 'end', fontWeight: 'bold', fontSize: '15px' }}
                              >
                                {total.IGST?.toFixed(2)}
                              </TableCell>
                            )}
                          </>
                        )}

                        <TableCell
                          sx={{
                            textAlign: 'end',
                            position: 'sticky',
                            right: 0,
                            fontWeight: 'bold',
                            fontSize: '15px',
                          }}
                        >
                          {total.TotalAmount?.toFixed(2)}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }} />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            md={12}
            sx={{
              bottom: 80,
              zIndex: 111111,
              p: 2,
              position: 'absolute',
              justifyContent: 'space-between',
              bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
            }}
            spacing={2}
          >
            <Grid item Container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <Typography variant="subtitle2" sx={{ ml: '10px' }}>
                  Narration
                </Typography>
                <RHFTextField id="narration-field" name="narr" size="small" multiline rows={3} />
              </Grid>
            </Grid>
            <Grid
              item
              Container
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              sx={{ display: 'flex' }}
            >
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} />
              <Grid
                item
                Container
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
                sx={{ alignSelf: 'end' }}
              >
                {/* <Divider sx={{ borderStyle: 'dashed', mt: '4px' }} /> */}
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="subtitle1">Bill Amount</Typography>
                  <Box sx={{ textAlign: 'right' }}>
                    <Typography variant="subtitle1" sx={{ color: 'error.main' }}>
                      <strong>{total.BillAmount?.toFixed(2)}</strong>
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Grid>

          <Stack
            direction="row-reverse"
            alignItems="center"
            sx={{
              bottom: 0,
              zIndex: 111111,
              p: 3,
              position: 'absolute',
              justifyContent: 'space-between',
              width: '100%',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
            id="modelfooter"
          >
            <Tooltip title="(Ctrl + A)" arrow>
              <LoadingButton
                type="button"
                variant="contained"
                loading={isSubmitting}
                onClick={() => setGST(allValues)}
              >
                {row ? 'Update' : 'Add'}
              </LoadingButton>
            </Tooltip>

            <Tooltip title="(Esc)" arrow>
              <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
                Cancel
              </Button>
            </Tooltip>

            <Button
              variant="soft"
              onClick={() => {
                reset(values);
                document.getElementById('invoice_type').focus();
              }}
              sx={{ mr: 2 }}
            >
              Reset
            </Button>

            <Stack direction="row" alignItems="center" flexGrow={1} spacing={2}>
              <Tooltip title="Add Product (Alt+A)" arrow>
                <IconButton color="primary" onClick={handleAdd}>
                  <Iconify icon="mingcute:add-line" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </FormProvider>
      </Dialog>

      {quickEditAccount.value && (
        <AUserQuickEditForm
          row={accountEditList || PaccountEditList || null}
          open={quickEditAccount.value}
          onClose={handleCloseAccount}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}

      {quickEdit.value && allValues.c_d === 3 && (
        <QuickEditForm
          row={null || credit}
          open={quickEdit.value}
          onClose={handleCloseCredit}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}

      {quickEdit.value && allValues.c_d === 1 && (
        <AUserQuickEditForm
          row={null || credit}
          open={quickEdit.value}
          onClose={handleCloseCredit}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}

      {quickEditProduct.value && (
        <PUserQuickEditForm
          row={productEditValues || null}
          open={quickEditProduct.value}
          onClose={handleCloseProduct}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
      {quickEditGSTCommdity.value && (
        <GSTCommodityQuickEditForm
          row={null || GSTCommodity}
          open={quickEditGSTCommdity.value}
          onClose={handleCloseProduct}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() =>
              (productEditValues?.id && deleteProductModel(productEditValues?.id)) ||
              (accountEditList?.id && deleteAccountModel(accountEditList?.id)) ||
              (PaccountEditList?.id && deleteAccountModel(PaccountEditList?.id))
            }
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
  invoiceSettings: PropTypes.object,
};
