import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useEventListener } from 'src/hooks/use-event-listener';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFCheckbox,
  RHFMultiCheckbox,
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { KEY, MODULE_STATUS, TITLES } from 'src/_mock/constant';
import { useBoolean } from 'src/hooks/use-boolean';
import {
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Toolbar,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import Grid from '@mui/system/Unstable_Grid/Grid';
import ComponentBlock from '../_examples/component-block';

// ----------------------------------------------------------------------

export default function Gpermission({ grow, open, onClose, getAll }) {
  const { enqueueSnackbar } = useSnackbar();
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gp, setGp] = useState(grow.gr_pers ? [...grow.gr_pers] : []);
  const password = useBoolean();
  const userSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    title: Yup.string().required('Required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    mobile: Yup.string().required('Mobile number is required').min(10).max(10),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  const values = useMemo(
    () => ({
      name: grow?.name || '',
      title: grow?.title || '',
      email: grow?.email || '',
      mobile: grow?.mobile || '',
      password: grow?.password || '',
      confirmPassword: grow?.confirmPassword || '',
      status: grow?.status || false,
    }),
    [grow]
  );
  useEffect(() => {
    setGp(grow.gr_pers ? [...grow.gr_pers] : []);
  }, [grow, grow.gr_pers]);

  useEffect(() => {
    if (open)
      grow.gr_pers = grow?.gr_pers?.map((x) => {
        x.co_pers = [];
        x.co_pers = grow?.co_pers.filter((y) =>
          grow?.companies?.find((z1) => z1.id === y.companyId)
        );
        return x;
      });
  }, [grow, open]);
  const methods = useForm({
    resolver: yupResolver(userSchema),
    values,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const onSubmit = async (payload) => {
    setLoading(true);
    try {
      let URL = 'users/gpermission/';
      if (grow.id) {
        URL += grow.id;
        const { success, show } = await update({
          url: URL,
          payload: { userId: grow.id, gr_per: payload },
        });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!', {
              variant: 'success',
            });
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!', {
              variant: 'success',
            });
          }
        }
      }
      reset();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      setLoading(false);
      console.error(error);
    }
  };
  const getAllGroups = useCallback(async () => {
    try {
      const URL = `/groups?page=1&limit=1000&company=true`;
      const { success, show, data } = await fetcher(URL);
      if (success) {
        setGroups(data.rows);
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  }, [reset, enqueueSnackbar]);
  const handleChange1 = (event, groupId) => {
    if (event.target.checked) {
      gp.push({
        groupId,
        userId: grow.id,
        co_pers: groups
          .find((x) => x.id === groupId)
          .companies.map((y) => ({
            groupId: y.groupId,
            companyId: y.id,
            userId: grow.id,
          })),
      });
      setGp([...gp]);
    } else {
      setGp(gp.filter((x) => x.groupId !== groupId));
    }
  };
  const handleChange2 = (event, groupId, companyId) => {
    const gpIndex = gp.findIndex((x) => x.groupId === groupId);
    if (event.target.checked) {
      if (gpIndex >= 0) {
        gp[gpIndex].co_pers.push({
          groupId: gp[gpIndex].groupId,
          companyId,
          userId: grow.id,
        });
      } else {
        gp.push({
          groupId,
          userId: grow.id,
          co_pers: [
            {
              groupId,
              companyId,
              userId: grow.id,
            },
          ],
        });
      }
    } else if (gpIndex >= 0) {
      gp[gpIndex].co_pers = gp[gpIndex].co_pers.filter((y) => y.companyId !== companyId);
    }
    setGp([...gp]);
  };
  useEffect(() => {
    reset();
    getAllGroups();
  }, [open, reset, getAllGroups]);

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 950 },
      }}
    >
      <FormProvider methods={methods}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            top: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Toolbar>
            <Typography variant="h5" sx={{ flex: 1 }}>
              Group Permission
            </Typography>

            <IconButton color="inherit" edge="start" onClick={onClose}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>

        <DialogContent>
          {groups?.map((item) => {
            const findI = gp?.find((x1) => x1.groupId === item.id);
            let gpChecked = false;
            if (findI) {
              gpChecked = true;
            } else {
              gpChecked = false;
            }

            return (
              <Card sx={{ display: 'flex', alignItems: 'center', p: 2, mt: 2, mb: 2 }}>
                <Box sx={{ flexGrow: 1 }}>
                  {/* {grow.roleId === 2 ? (
                    <FormControlLabel
                      label={item.name}
                      control={
                        <Checkbox
                          size="medium"
                          checked={grow.roleId === 2 && gpChecked}
                          disabled={grow.roleId === 3}
                          onChange={(event) => handleChange1(event, item.id)}
                        />
                      }
                    />
                  ) : (
                    <span>{item.name}</span>
                  )} */}

                  <FormControlLabel
                    label={item.name}
                    control={
                      <Checkbox
                        size="medium"
                        checked={gpChecked}
                        onChange={(event) => handleChange1(event, item.id)}
                      />
                    }
                  />

                  <Divider sx={{ borderStyle: 'dashed' }} />
                  <Grid>
                    {/* {grow.roleId === 2 ? (
                      item.companies.map((y) => (
                        <FormControlLabel
                          label={y.name}
                          control={
                            <Checkbox
                              size="small"
                              checked={
                                !!gp
                                  ?.find((x1) => x1.groupId === item.id)
                                  ?.co_pers.find((y1) => y1.companyId === y.id)
                              }
                              onChange={(event) => handleChange2(event, item.id, y.id)}
                            />
                          }
                        />
                      ))
                    ) : (
                      <FormControl component="fieldset">
                        <RadioGroup row defaultValue="top">
                          {item.companies.map((y) => (
                            <FormControlLabel
                              key={y.id}
                              value={y.id}
                              label={y.name}
                              labelPlacement={y.name}
                              control={<Radio size="medium" />}
                              sx={{ textTransform: 'capitalize' }}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    )} */}
                    {item.companies.map((y) => (
                      <FormControlLabel
                        label={y.name}
                        control={
                          <Checkbox
                            size="small"
                            checked={
                              !!gp
                                ?.find((x1) => x1.groupId === item.id)
                                ?.co_pers.find((y1) => y1.companyId === y.id)
                            }
                            onChange={(event) => handleChange2(event, item.id, y.id)}
                          />
                        }
                      />
                    ))}
                  </Grid>
                </Box>
              </Card>
            );
          })}
        </DialogContent>

        <DialogActions
          sx={{
            bottom: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton
            type="button"
            variant="contained"
            loading={loading}
            onClick={() => {
              onSubmit(gp);
            }}
          >
            {grow ? 'Update' : 'Add'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

Gpermission.propTypes = {
  grow: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  getAll: PropTypes.func,
};
