import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  Divider,
  Fab,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { KEY } from 'src/_mock/constant';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom, useTable } from 'src/components/table';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'src/components/hook-form/form-provider';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { create, fetcher } from 'src/utils/axios';
import { TableRowsLoader } from 'src/_mock/constant_funcation';
import EmptyContent from 'src/components/empty-content/empty-content';
import { AuthContext } from 'src/auth/context/jwt';
import { useEventListener } from 'src/hooks/use-event-listener';
import ImportTableRow from './import-table';

const TABLE_HEAD = [
  { id: 'no', label: 'No.', width: 90 },
  { id: 'name', label: 'MYBOOK Label', width: 250 },
  { id: 'name', label: 'Uploaded Label' },
];

export default function ImportFile({ open, onClose, row, uploadFile, uploadedLabel, page }) {
  const table = useTable();
  const [file, setFile] = useState();
  const { user, changeAdvance, initialize } = useContext(AuthContext);

  const [importColumnsData, setImportColumnsData] = useState([]);
  const [softwareList, setSoftwareList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [setrow, setRow] = useState(null);
  const [enterWithValue, setEnterWithValue] = useState('');

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    console.log(enterWithValue);
  }, [enterWithValue]);
  const fileRef = useRef(null);

  const validationSchema = Yup.object().shape({
    s_name: Yup.string().required('Required'),
    page: Yup.string().required('Required'),
    v_name: Yup.string().required('Required'),
    uploadFields: Yup.array(),
  });

  const values = useMemo(
    () => ({
      s_name: row?.s_name || '',
      v_name: row?.v_name || '',
      page: row?.page || page,
      uploadFields: row?.uploadFields || [],
    }),
    [row, page]
  );

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    values,
  });

  const {
    reset,
    control,
    setValue,
    watch,
    handleSubmit,
    trigger,
    formState: { errors },
  } = methods;
  const allValue = watch();

  const onSubmit = async (payload) => {
    setLoading(true);
    try {
      const URL = 'columns/importSetting/';

      const { success, show } = await create({ url: URL, payload });
      if (success) {
        getAll();
        if (show) {
          enqueueSnackbar('Create success!');
        }
      }

      setLoading(false);
      reset();
    } catch (error) {
      setLoading(false);
      if (error?.show) {
        enqueueSnackbar(error?.message, {
          variant: 'info',
        });
      }
      reset();
      console.error(error);
    }
  };

  const getAll = useCallback(async () => {
    setLoader(true);
    try {
      const URL = 'co_years/';

      const { data } = await fetcher(URL);
      setCount(data?.count);
      setTableData(data?.rows);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  }, []);

  const getFileColumn = async (data, query) => {
    try {
      const URL = `columns/import/${query}`;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formdata = new FormData();
      formdata.append('file', data);
      const response = await create({ url: URL, payload: formdata, config });
      return response?.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  };
  const isPermission = user?.modules?.find((x) => x.title === 'Financial Year')?.user_permission;

  const ImportColumnsList = useCallback(async () => {
    let URL = 'columns/';
    URL += `?page=${1}&limit=${10000}&status=true&table=${page}`;
    const { data } = await fetcher(URL);
    setImportColumnsData(data?.rows);
    setValue(
      'uploadFields',
      data?.rows.map((x) => ({ left: x, right: [], col_options: [] }))
    );
  }, [page, setValue]);
  const getAllSoftwareList = useCallback(async () => {
    let URL = 'sof_names/';
    URL += `?page=${1}&limit=${10000}&status=true&pageName=${page}`;
    const { data } = await fetcher(URL);
    setSoftwareList(data?.rows);
  }, [page]);

  useEffect(() => {
    getAll();
    ImportColumnsList();
    getAllSoftwareList();
  }, [getAll, open, ImportColumnsList, getAllSoftwareList]);

  const notFound = count === 0;

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
      initialize();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading) {
        await trigger();
        onSubmit();
      }
    }
  };

  const handleAttach = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <>
      <input
        ref={fileRef}
        type="file"
        style={{ display: 'none' }}
        multiple={false}
        onChange={async (e) => {
          if (e.target.files['0']) {
            setFile(e.target.files['0']);
            await uploadFile(e.target.files['0']);
            // e.target.value = '';
          }
        }}
      />

      <Dialog
        fullWidth
        maxWidth={false}
        open={open}
        onKeyDown={handleKeyDown}
        PaperProps={{
          sx: { maxWidth: 1224, minHeight: 650 },
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            top: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Toolbar>
            <Typography variant="h5" sx={{ flex: 1 }}>
              Import File
            </Typography>

            <IconButton
              color="inherit"
              edge="start"
              onClick={() => {
                onClose();
                reset();
                initialize();
              }}
            >
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>

        <DialogContent sx={{ p: 2 }}>
          <FormProvider methods={methods}>
            <Grid container>
              <Grid
                container
                item
                xs={12}
                md={12}
                sx={{ p: 1 }}
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item container xs={12} sm={12} md={9} lg={9} xl={9} xxl={9} spacing={2}>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      freeSolo
                      size="small"
                      name="s_name"
                      options={softwareList?.map((option) => option.name)}
                      getOptionLabel={(option) => option}
                      onChange={(event, newValue) => {
                        if (newValue) setValue('s_name', newValue);
                      }}
                      value={
                        softwareList?.find((data) => data.name === allValue.s_name)?.name ||
                        allValue.s_name
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Software Name"
                          name="s_name"
                          onChange={(event, newValue) => {
                            if (event.target.value) setValue('s_name', event.target.value);
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      freeSolo
                      size="small"
                      options={
                        softwareList
                          ?.find((x) => x.name === allValue.s_name)
                          ?.sof_vers?.map((option) => option.name) || []
                      }
                      getOptionLabel={(option) => option}
                      onChange={(event, newValue) => {
                        if (newValue) setValue('v_name', newValue);
                      }}
                      value={
                        softwareList
                          ?.find((data) => data.name === allValue.s_name)
                          ?.sof_vers?.find((data) => data.name === allValue.v_name)?.name ||
                        allValue.v_name
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Version Name"
                          name="v_name"
                          onChange={(event, newValue) => {
                            if (event.target.value) setValue('v_name', event.target.value);
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3} textAlign="end">
                  <Fab size="small" color="inherit" variant="softExtended" onClick={handleAttach}>
                    <Iconify
                      icon="solar:import-bold-duotone"
                      width={24}
                      sx={{ color: 'success.main' }}
                    />
                    Import File
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
            <TableContainer sx={{ position: 'relative', overflow: 'unset', mt: 2 }}>
              <Scrollbar sx={{ maxHeight: 400 }}>
                <Table stickyHeader size={table.dense ? 'small' : 'medium'}>
                  <TableHeadCustom headLabel={TABLE_HEAD} />

                  <TableBody>
                    {loader ? (
                      <TableRowsLoader rowsNum={5} colNum={3} />
                    ) : (
                      <>
                        {importColumnsData?.map((row, index) => (
                          <ImportTableRow
                            setValue={setValue}
                            allValue={allValue}
                            enterWithValue={enterWithValue}
                            setEnterWithValue={setEnterWithValue}
                            key={row.id}
                            row={row}
                            index={index}
                            table={{ table }}
                            getAll={getAll}
                            setRow={setRow}
                            user={user}
                            selected={index === selectedRow}
                            uploadedLabel={uploadedLabel}
                          />
                        ))}
                        <TableRow>
                          {notFound && (
                            <TableCell colSpan={4}>
                              <EmptyContent filled title="No Data" />
                            </TableCell>
                          )}
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
            <DialogActions>
              <LoadingButton
                type="button"
                variant="contained"
                loading={loading}
                onClick={async () => {
                  if (await trigger()) {
                    onSubmit(
                      JSON.parse(
                        JSON.stringify({
                          ...allValue,
                          uploadFields: allValue.uploadFields?.filter((x) => x.right.length),
                        })
                      )
                    );
                  }
                }}
              >
                Submit
              </LoadingButton>
            </DialogActions>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}

ImportFile.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  uploadFile: PropTypes.any,
  row: PropTypes.any,
  uploadedLabel: PropTypes.array,
  page: PropTypes.string,
};
