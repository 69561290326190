import PropTypes from 'prop-types';
import { memo, useContext, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
//
import Iconify from 'src/components/iconify';
import { IconButton } from '@mui/material';
import { useBoolean } from 'src/hooks/use-boolean';
import GroupCompanyYear from 'src/layouts/_common/group-company-year';
import { AuthContext } from 'src/auth/context/jwt';
import { navMiniConfig } from '../config';
import NavList from './nav-list';

// ----------------------------------------------------------------------

function NavSectionMini({ data, config, sx, ...other }) {
  return (
    <Stack sx={sx} {...other}>
      {data.map((group, index) => (
        <Group key={group.subheader || index} items={group.items} config={navMiniConfig(config)} />
      ))}
    </Stack>
  );
}

NavSectionMini.propTypes = {
  config: PropTypes.object,
  data: PropTypes.array,
  sx: PropTypes.object,
};

export default memo(NavSectionMini);

// ----------------------------------------------------------------------

function Group({ items, config }) {
  const { user, changeAdvance } = useContext(AuthContext);

  const [company, setCompany] = useState(
    user.companyId || null
  );
  const [branch, setBranch] = useState(
    user.branchId || null
  );
  const [gId, setGId] = useState(user?.groupId);
  const quickEdit = useBoolean();

  useEffect(() => {
    if (user?.co_per?.length === 1) {
      quickEdit.onTrue();
    }
  }, [user, quickEdit]);

  return (
    <>
      {items.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={1}
          hasChild={!!list.children}
          config={config}
        />
      ))}
      <IconButton
        color="primary"
        onClick={() => {
          if (user.roleId < 3 || user?.companies.length > 1) quickEdit.onTrue();
        }}
      >
        <Iconify icon="ic:round-change-circle" />
      </IconButton>

      <GroupCompanyYear
        open={quickEdit.value}
        setGId={setGId}
        onClose={() => {
          quickEdit.onFalse();
        }}
        onOk={() => {
          if (company !== user?.companyId) {
            changeAdvance({ companyId: company, groupId: gId, co_yearId: null, branchId: branch });
          } else {
            changeAdvance({ companyId: company, groupId: gId, branchId: branch });
          }
          quickEdit.onFalse();
        }}
        user={user}
        company={company}
        setCompany={setCompany}
        branch={branch}
        setBranch={setBranch}
      />
    </>
  );
}

Group.propTypes = {
  config: PropTypes.object,
  items: PropTypes.array,
};
