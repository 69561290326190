import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { create, update } from 'src/utils/axios';
import { KEY } from 'src/_mock/constant';
import { Divider, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { alpha } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import { useEventListener } from 'src/hooks/use-event-listener';
// ----------------------------------------------------------------------

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();

  const [openModelName, setOpenModelName] = useState('');

  const dialog = useBoolean();

  const departmentSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const values = useMemo(
    () => ({
      name: row?.name || '',
      status: row?.status,
      description: row?.description || '',
      balance_method: row?.balance_method || 'Bill To Bill',
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(departmentSchema),
    values,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const onSubmit = handleSubmit(async (payload) => {
    try {
      let URL = 'roles/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const { watch, control, setValue } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'company_banks',
  });

  const handleAdd = () => {
    append({
      name: '',
      branch_name: '',
      ifsc_code: '',
      iban_no: '',
      swift_code: '',
      upi_code: '',
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };
  useEventListener('keydown', handleKeyDown);

  function createData(sr, partNumber, partDescription, s, qty, mrp, vat, rate, disc, amount) {
    return { sr, partNumber, partDescription, s, qty, mrp, vat, rate, disc, amount };
  }

  const rows = [
    createData(1, '43546', 'abckjasncb', 50, 100, 100, 100, 100, 100, 100),
    createData(2, '43546', 'abckjasncb', 50, 100, 100, 100, 100, 100, 100),
    createData(3, '43546', 'abckjasncb', 50, 100, 100, 100, 100, 100, 100),
  ];

  function BasicTable() {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 1350 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Sr.</TableCell>
              <TableCell sx={{ minWidth: 300 }}>Part Number</TableCell>
              <TableCell align="left">Part Description</TableCell>
              <TableCell align="left">S</TableCell>
              <TableCell align="left">Qty</TableCell>
              <TableCell align="left">M.R.P.</TableCell>
              <TableCell align="left">VAT%</TableCell>
              <TableCell align="left">Rate</TableCell>
              <TableCell align="left">Dis%</TableCell>
              <TableCell align="left">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.gst_slab}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{row.sr}</TableCell>
                <TableCell sx={{ minWidth: 300 }} align="left">
                  {row.partNumber}
                </TableCell>
                <TableCell align="left">{row.partDescription}</TableCell>
                <TableCell align="left">{row.s}</TableCell>
                <TableCell align="left">{row.qty}</TableCell>
                <TableCell align="left">{row.mrp}</TableCell>
                <TableCell align="left">{row.vat}</TableCell>
                <TableCell align="left">{row.rate}</TableCell>
                <TableCell align="left">{row.disc}</TableCell>
                <TableCell align="left">{row.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  function BasicTable2() {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 400 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Opening</TableCell>
              <TableCell align="left">Recived</TableCell>
              <TableCell align="left">issued</TableCell>
              <TableCell align="left">Closing</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.gst_slab}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{row.vat}</TableCell>
                <TableCell align="left">{row.rate}</TableCell>
                <TableCell align="left">{row.disc}</TableCell>
                <TableCell align="left">{row.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 1320 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update Job Card' : 'Create Job Card'}
              </Typography>

              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>

            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          <Grid item container xs={12} md={12} sx={{ p: 3 }} columnSpacing={8} spacing={4}>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              spacing={2}
              columnSpacing={8}
              alignContent="start"
            >
              <Grid item container xs={12} sm={12} md={12} lg={8} xl={8} xxl={8} spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} xxl={3}>
                  <RHFTextField name="job_card_year" label="Job Card year" size="small" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} xxl={3}>
                  <RHFTextField name="job_card_no" label="Job Card No." size="small" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} xxl={3}>
                  <DatePicker
                    label="Date."
                    value={new Date()}
                    // onChange={handleFilterStartDate}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: 'small',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} xxl={3}>
                  <RHFTextField name="regi_no" label="Regi. No." size="small" />
                </Grid>
                <Grid item xs={12} sm={6} md={8} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="chassis_no" label="Chassis. No." size="small" />
                </Grid>
                <Grid item xs={12} sm={6} md={7} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="engine_no" label="Engine. No." size="small" />
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={5} xl={5} xxl={5}>
                  <RHFTextField name="coupon_no" label="Coupon No." size="small" />
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={12} md={12} lg={4} xl={4} xxl={4} spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="model" label="Model" size="small" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="type" label="Type." size="small" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="serv" label="Serv." size="small" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="km_hrs" label="km/Hrs" size="small" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={6} xxl={6}>
                  <DatePicker
                    label="Sl.Dt."
                    value={new Date()}
                    // onChange={handleFilterStartDate}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: 'small',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="dl_km_hrs" label="Dl.km/hrs" size="small" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <BasicTable />
            </Grid>
            <Grid item container xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
              <Grid item container xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                <BasicTable2 />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              xxl={4}
              spacing={2}
              alignContent="start"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="net_amount" label="Net Amount" size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="total_tax_amount" label="Total Tax Amount" size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="total_amount" label="Total Amount" size="small" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DialogActions
          sx={{
            bottom: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {row ? 'Update' : 'Add'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
