import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useState, useCallback, useEffect, useContext, useMemo } from 'react';
import CompanyYear from 'src/layouts/_common/company-year';
// @mui
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import queryString from 'query-string';
import { useSnackbar } from 'src/components/snackbar';
import { useDebounce } from 'src/hooks/use-debounce';
import { useTable } from 'src/components/table';
import { bank_cash_report_formate, automobile_report_periods, KEY } from 'src/_mock/constant';
//
import { fMoney } from 'src/_mock/constant_funcation';
import { AuthContext } from 'src/auth/context/jwt';
import moment from 'moment';
import Iconify from 'src/components/iconify';
import { DatePicker } from '@mui/x-date-pickers';
import { fetcher, destroy } from 'src/utils/axios';
import {
  Autocomplete,
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import NotFound from 'src/sections/error/notfound-invoice';
import { useBoolean } from 'src/hooks/use-boolean';

function formatNumberInIndianStyle(number) {
  return new Intl.NumberFormat("en-IN", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
}
const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
};

// ----------------------------------------------------------------------
const TABLE_HEAD = [[
  { id: 'ProductCategory', label: '' },
  { id: 'Opening', label: 'Opening', align: 'center', colSpan: 2 },
  { id: 'Purchase', label: 'Purchase', align: 'center', colSpan: 2 },
  { id: 'Sale', label: 'Sale', align: 'center', colSpan: 2 },
  { id: 'Closing', label: 'Closing', align: 'center', colSpan: 2 },
], [
  { id: '', label: 'Products' },
  { id: 'Openingqty', label: 'Qty', align: 'right' },
  { id: 'Openingvalue', label: 'Value', align: 'left' },
  { id: 'Purchaseqty', label: 'Qty', align: 'right' },
  { id: 'Purchasevalue', label: 'Value', align: 'left' },
  { id: 'Saleqty', label: 'Qty', align: 'right' },
  { id: 'Salevalue', label: 'Value', align: 'left' },
  { id: 'Closingqty', label: 'Qty', align: 'right' },
  { id: 'Closingvalue', label: 'Value', align: 'left' },
]];
function generateMonthList(start, end) {
  const startOfMonth = start.startOf('month');
  const endOfMonth = end.endOf('month');
  const current = startOfMonth.clone(); // Clone to avoid mutating the original date
  const months = [];

  while (current.isBefore(endOfMonth)) {
    months.push(current.month() + 1); // month() is zero-based, so add 1 for 1-based index
    current.add(1, 'month');
  }

  return months;
}
function genrateQuaterList(start, end) {
  // Parse the initial start, assuming format "MM/YYYY"
  const initialDate = moment(start, "MM/YYYY");

  // Calculate quarters
  const firstQuarterStart = initialDate.clone();
  const firstQuarterEnd = initialDate.clone().add(2, 'months').endOf('month');

  const secondQuarterStart = firstQuarterEnd.clone().add(1, 'day');
  const secondQuarterEnd = secondQuarterStart.clone().add(2, 'months').endOf('month');

  const thirdQuarterStart = secondQuarterEnd.clone().add(1, 'day');
  const thirdQuarterEnd = thirdQuarterStart.clone().add(2, 'months').endOf('month');

  const forthQuarterStart = thirdQuarterEnd.clone().add(1, 'day');
  const forthQuarterEnd = forthQuarterStart.clone().add(2, 'months').endOf('month');

  // Update state with quarter dates
  return [{ label: 'First', value: 1, start: firstQuarterStart.toDate(), end: firstQuarterEnd.toDate() }, { label: 'Second', value: 2, start: secondQuarterStart.toDate(), end: secondQuarterEnd.toDate() }, { label: 'Third', value: 3, start: thirdQuarterStart.toDate(), end: thirdQuarterEnd.toDate() }, { label: 'Four', value: 4, start: forthQuarterStart.toDate(), end: forthQuarterEnd.toDate() }]


}
export default function Vehiclecolourautomobile({ row, open, onClose }) {
  const table = useTable();
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();
  const [loading, setLoading] = useState(0);
  const [product, setProduct] = useState(null);
  const dialog = useBoolean();
  const { user } = useContext(AuthContext);
  const startDate = moment(user?.co_year?.sdate);
  const endDate = moment(user?.co_year?.edate);


  // Generate the list of month numbers
  const monthList = generateMonthList(startDate, endDate);
  const QuaterList = genrateQuaterList(startDate, endDate);
  // Format the dates in the desired format
  const formattedStartDate = startDate.format('DD/MM/YYYY');
  const formattedEndDate = endDate.format('DD/MM/YYYY');

  // Convert formattedStartDate and formattedEndDate back to moment objects for comparison
  const startDateMoment = moment(formattedStartDate, 'DD/MM/YYYY');
  const endDateMoment = moment(formattedEndDate, 'DD/MM/YYYY');

  const [information, setData] = useState({
    ac_groupId: null,
    accountId: null,
    formate: '2',
    period: '5',
    transaction: 'sale',
    month: null,
    fdate: startDateMoment,
    tdate: endDateMoment,
    gst: true,
    quater: "1",
    accountList: [],
  });
  const [tableData, setTableData] = useState([]);
  const [productcategoryList, setProductCategoriesList] = useState([]);

  const getAll = useCallback(async () => {
    setLoading(true);
    try {
      let URL = `automobile/products/stock/${row.id}?page=1&limit=100`;
      if (information.fdate && information.tdate) {
        URL += `&fdate=${information.fdate
          }&tdate=${information.tdate}`
      }
      if (information.gst) {
        URL += `&gst=${information.gst}`
      }
      if (information.transaction) {
        URL += `&transaction=${information.transaction}`
      }

      const { data } = await fetcher(URL);
      if (data) {
        setProductCategoriesList(data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    information.accountId,
    information.fdate,
    information.gst,
    information.tdate,
    information.transaction
  ]);



  useEffect(() => {
    getAll();
  }, [getAll,
    open,
    information.accountId,
    information.transaction,
    information.gst,
    information.fdate,
    information.tdate]);

  useEffect(() => {
    console.log(productcategoryList)
  }, [productcategoryList])

  useEffect(() => {
    switch (information.period) {
      case '1': {
        const startOfWeek = moment().format("YYYY-MM-DD");
        const endOfWeek = moment().endOf('YYYY-MM-DD');

        setData((pre) => ({ ...pre, fdate: startOfWeek, tdate: endOfWeek, month: null, quater: null }));
        break;
      }
      case '3': {
        const startOfMonth = moment(startDate).startOf('month');
        const endOfMonth = moment(startOfMonth).endOf('month');

        setData((pre) => ({ ...pre, fdate: startOfMonth, tdate: endOfMonth, month: null, quater: null }));
        break;
      }
      case '4': {
        const startOfQuarter = moment(startDate).startOf('quarter');
        const endOfQuarter = moment(startOfQuarter).endOf('quarter');

        setData((pre) => ({ ...pre, fdate: startOfQuarter, tdate: endOfQuarter, month: null, quater: null }));
        break;
      }
      case '5': {

        setData((pre) => ({ ...pre, fdate: startDate, tdate: endDate, month: null, quater: null }));
        break;
      }
      default:
        setData((pre) => ({ ...pre, fdate: startDate, tdate: endDate, month: null, quater: null }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [information.period])
  useEffect(() => {
    if (information.month) {
      // Determine the year dynamically, or set a fixed year
      // Example uses the current year, adjust as needed
      const year = moment().year();

      // Set start and end dates based on the selected month
      const startDate = moment().year(year).month(information.month - 1).startOf('month');
      const endDate = moment().year(year).month(information.month - 1).endOf('month');

      // Update state with the new dates
      setData(prev => ({
        ...prev,
        fdate: startDate.toDate(), // Convert moment objects to JavaScript Date objects if needed
        tdate: endDate.toDate()
      }));
    }
  }, [information.month]); // Dependency array includes information.month
  useEffect(() => {
    if (information.quater) {

      const findQuater = QuaterList.find(x => x.value === information.quater)
      // Update state with the new dates
      if (findQuater)
        setData(prev => ({
          ...prev,
          fdate: findQuater.start, // Convert moment objects to JavaScript Date objects if needed
          tdate: findQuater.end
        }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [information.quater]); // Dependency array includes information.month

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };
  return (
    <Dialog
      fullScreen
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { p: 1 },
      }}
    >
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            top: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Toolbar>
            <Typography variant="h5" sx={{ flex: 1 }}>
              {row ? `${row.name} STOCK` : ''}
            </Typography>

            <CompanyYear />

            <IconButton color="inherit" edge="start" onClick={onClose} tabIndex={-1}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>

          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>
        <Card sx={{ p: 2 }}>
          <Grid container>
            <Grid item container xs={12} sm={12} md={11} lg={11} xl={11} xxl={11} spacing={2}>
              <Grid item sm={12} md={3} lg={2} xl={1.5}>
                <Select
                  fullWidth
                  value={information.period}
                  onChange={(e) => {
                    setData((pre) => ({ ...pre, period: e.target.value }))
                  }}
                  size='small'
                  MenuProps={{
                    PaperProps: {
                      sx: { maxHeight: 240 },
                    },
                  }}
                >
                  {
                    automobile_report_periods.map((x, index) => <MenuItem key={index} value={x.value}>
                      {x.label}
                    </MenuItem>)
                  }
                </Select>
              </Grid>

              {
                information.period === "4" && <Grid item sm={12} md={3} lg={2} xl={1.5}>
                  <Select
                    fullWidth
                    value={information.quater}
                    onChange={(e) => {
                      setData((pre) => ({ ...pre, quater: e.target.value }))
                    }}
                    size='small'
                    MenuProps={{
                      PaperProps: {
                        sx: { maxHeight: 240 },
                      },
                    }}
                  >
                    {
                      QuaterList.map((x, index) => <MenuItem key={index} value={x.value}>
                        {x.label}
                      </MenuItem>)
                    }
                  </Select>
                </Grid>
              }
              {
                information.period === "3" && <Grid item sm={12} md={3} lg={2} xl={1}>
                  <Select
                    fullWidth
                    value={information.month}
                    onChange={(e) => {
                      console.log("month", information.month)
                      setData((pre) => ({ ...pre, month: e.target.value }))
                    }}
                    size='small'
                    MenuProps={{
                      PaperProps: {
                        sx: { maxHeight: 240 },
                      },
                    }}
                  >
                    {
                      monthList.map((x, index) => <MenuItem key={index} value={x}>
                        {x}
                      </MenuItem>)
                    }
                  </Select>
                </Grid>
              }
              <Grid item sm={12} md={3} lg={2} xl={1.2}>
                <Select
                  fullWidth
                  value={information.transaction}
                  onChange={(e) => {
                    console.log("transaction", information.transaction)
                    setData((pre) => ({ ...pre, transaction: e.target.value }))
                  }}
                  size='small'
                  MenuProps={{
                    PaperProps: {
                      sx: { maxHeight: 240 },
                    },
                  }}
                >
                  <MenuItem key="sale" value="sale">
                    sale
                  </MenuItem>
                  <MenuItem key="challan" value="challan">
                    challan
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item sm={12} md={3} lg={2} xl={1.8}>

                <Select
                  fullWidth
                  value={information.gst}
                  onChange={(e) => {
                    const va = e.target.value === "true";
                    setData((pre) => ({ ...pre, gst: va }))
                  }}
                  size='small'
                  MenuProps={{
                    PaperProps: {
                      sx: { maxHeight: 240 },
                    },
                  }}
                >
                  <MenuItem key={1} value="true">
                    With Gst
                  </MenuItem>
                  <MenuItem key={2} value="false">
                    Without Gst
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item sm={12} md={6} lg={4} xl={2}>
                <DatePicker
                  value={new Date(information.fdate)}
                  onChange={(newValue) => {
                    setData({ ...information, fdate: newValue });
                  }}
                  format="dd/MM/yyyy"
                  label="From Date"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                    },
                  }}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={4} xl={2}>
                <DatePicker
                  value={new Date(information.tdate)}
                  onChange={(newValue) => {
                    setData({ ...information, tdate: newValue });
                  }}
                  format="dd/MM/yyyy"
                  label="To Date"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ p: 4, mt: 3 }} id="pdf-content">
          <Table sx={{ minWidth: 800 }}>
            <TableHead >
              <TableRow>
                {TABLE_HEAD[0].map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    colSpan={headCell.colSpan}
                    align={headCell.align || 'left'}
                    sx={{ width: headCell.width, minWidth: headCell.minWidth }}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                {TABLE_HEAD[1].map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    colSpan={headCell.colSpan || 0}
                    align={headCell.align || 'left'}
                    sx={{ width: headCell.width, minWidth: headCell.minWidth }}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>

            </TableHead>

            <TableBody>
              {productcategoryList.map((row) => (
                <TableRow key={row.name}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell align="right">{row.openingstock.qty}</TableCell>
                  <TableCell align="left">{formatNumberInIndianStyle(row.openingstock.amount)}</TableCell>
                  <TableCell align="right">{row.purchasestock.qty}</TableCell>
                  <TableCell align="left">{formatNumberInIndianStyle(row.purchasestock.amount)}</TableCell>
                  <TableCell align="right">{row.salestock.qty}</TableCell>
                  <TableCell align="left">{formatNumberInIndianStyle(row.salestock.amount)}</TableCell>
                  <TableCell align="right">{row.closingstock.qty}</TableCell>
                  <TableCell align="left">{formatNumberInIndianStyle(row.closingstock.amount)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </Card>
        {!productcategoryList.length && <NotFound name="Report" />}
      </Container></Dialog>
  );
}

Vehiclecolourautomobile.propTypes = {
  row: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func
};
