import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Badge, { badgeClasses } from '@mui/material/Badge';
// components
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { useSettingsContext } from 'src/components/settings';
import { Tooltip } from '@mui/material';
import useSpeechToText from 'react-hook-speech-to-text';
import { useRouter } from 'src/routes/hooks';
import { useEventListener } from 'src/hooks/use-event-listener';
import { useEffect } from 'react';
import { KEY } from 'src/_mock/constant';

// ----------------------------------------------------------------------

export default function VoiceButton({ sx }) {
  const { error, interimResult, isRecording, results, startSpeechToText, stopSpeechToText } =
    useSpeechToText({
      continuous: true,
      useLegacyResults: false,
    });

  const router = useRouter();

  const handleSearch = () => {
    if (!isRecording) {
      startSpeechToText();
    }
  };

  useEffect(() => {
    switch (interimResult?.trim().toLowerCase()) {
      case 'dashboard':
        router.push('/');
        break;
      case 'company':
        router.push('/company');
        break;
      case 'account list':
        router.push('/master/account');
        break;
      case 'account group':
        router.push('/master/account-group');
        break;
      case 'product list':
        router.push('/master/product');
        break;
      case 'product group':
        router.push('/master/product-group');
        break;
      case 'product unit':
        router.push('/master/product-unit');
        break;
      case 'product category':
        router.push('/master/product-category');
        break;
      case 'hsn':
        router.push('/gst/hsn-sac');
        break;
      case 'gst unit':
        router.push('/gst/gst-unit');
        break;
      case 'gst slab':
        router.push('/gst/slab');
        break;
      case 'gst commodity':
        router.push('/gst/commodity');
        break;
      case 'vouchers':
        router.push('/transaction/vouchers');
        break;
      case 'journal entry':
        router.push('/transaction/journal');
        break;
      case 'invoice':
        router.push('/transaction/invoice');
        break;
      case 'report':
        router.push('/reports/report');
        break;
      case 'account ledger':
        router.push('/reports/account-ledger');
        break;
      case 'my profile':
        router.push('/settings/profile');
        break;
      case 'group':
        router.push('/management/group');
        break;
      case 'users':
        router.push('/management/admin-user');
        break;
      case 'department':
        router.push('/management/departments');
        break;
      case 'roles':
        router.push('/management/roles');
        break;
      default:
    }
  }, [interimResult, router]);

  const handleKeyDown = (event) => {
    if (event.altKey && event.key.toLowerCase() === 'v') {
      event.stopPropagation();
      handleSearch();
    }
    if (event.key === KEY.CLOSE) {
      event.stopPropagation();
      stopSpeechToText();
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Badge
      color="error"
      variant="dot"
      sx={{
        [`& .${badgeClasses.badge}`]: {
          top: 30,
          right: 8,
        },
        ...sx,
      }}
    >
      <Box
        component={m.div}
        animate={{
          scale: [1, isRecording ? 1.5 : 1],
        }}
        transition={{
          duration: 0.5,
          ease: 'linear',
          repeat: Infinity,
          repeatType: 'reverse',
        }}
      >
        <Tooltip title="Voice" arrow>
          <IconButton
            component={m.button}
            whileTap="tap"
            whileHover="hover"
            variants={varHover(1.05)}
            aria-label="settings"
            sx={{
              width: 40,
              height: 40,
            }}
            onClick={handleSearch}
          >
            <Iconify icon="icon-park-solid:voice" width={24} />
          </IconButton>
        </Tooltip>
      </Box>
    </Badge>
  );
}

VoiceButton.propTypes = {
  sx: PropTypes.object,
};
