import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { AuthContext } from 'src/auth/context/jwt';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { create, destroy, fetcher, update } from 'src/utils/axios';
import {
  ACCOUNT_TYPES,
  BALANCE_METHOD,
  BALANCE_TYPE,
  GST_TYPE,
  KEY,
  TRANSPORT,
} from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import {
  NumericFormatCustom,
  onScollerAutoComplate,
  useBgColorAutocomplete,
} from 'src/_mock/constant_funcation';
import { useEventListener } from 'src/hooks/use-event-listener';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { isNaN } from 'lodash';
import CompanyYear from 'src/layouts/_common/company-year';
import { useDebounce } from 'src/hooks/use-debounce';
import { handleScroll } from 'src/layouts/_common/scroller';
import GroupAccountForm from '../group/quick-edit-form';
// ----------------------------------------------------------------------

export default function AUserQuickEditForm({ row, open, onClose, table, getAll, tableData, set }) {
  const { enqueueSnackbar } = useSnackbar();

  const bgColorAutocomplete = useBgColorAutocomplete();

  const isUpdate = row && Object.keys(row)?.length;
  const [openModelName, setOpenModelName] = useState('');
  const [groupList, setGroupList] = useState([]);

  const [loading, setLoading] = useState(false);

  const [fieldName, setFieldName] = useState('');

  const [cityValue, setCityValue] = useState({ key: '', search: '' });

  const [aGroup, setAGroup] = useState({});

  const dialog = useBoolean();
  const quickEdit = useBoolean();
  const confirm = useBoolean();

  const searchCity = useDebounce(cityValue);

  const AccountSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    ac_groupId: Yup.number().required('Account Group is required'),
    bal_type: Yup.number().required('Balance Type is required'),
    gst_no: Yup.string()
      .notRequired()
      .matches(/^[0-9A-Z]{15}$/i, 'Invalid GST number'),
    op_bal: Yup.number()
      .nullable()
      .min(0)
      .notRequired()
      .transform((value) => (isNaN(value) ? null : value)),
  });

  const values = useMemo(
    () => ({
      name: row?.name || '',
      s_n: row?.s_n || '',
      accGroupMeta: {
        page: 1,
        name: '',
        totalPage: 1,
        editRow: null,
      },
      ac_groupId: row?.ac_groupId || null,
      gst_type: row?.gst_type || null,
      isHighligh: '',
      tra_type: row?.tra_type || null,
      bal_met: row?.bal_met || null,
      op_bal: row?.op_bal || null,
      bal_type: row?.bal_type || null,
      cr_limit: row?.cr_limit || null,
      cr_day: row?.cr_day || null,
      in_rate: row?.in_rate || null,
      in_ac: row?.in_ac || null,
      tds_ac: row?.tds_ac || null,
      in_bal_type: row?.in_bal_type || null,
      bank_name: row?.bank_name || null,
      branch_name: row?.branch_name || null,
      acc_no: row?.acc_no || null,
      ifsc_code: row?.ifsc_code || null,
      accounttypeId: row?.accounttypeId || null,
      b_address: row?.b_address || null,
      c_name: row?.c_name || null,
      pincode: row?.pincode || null,
      mobile: row?.mobile || null,
      pan_no: row?.pan_no || "",
      gst_no: row?.gst_no || null,
      email: row?.email || null,
      adno: row?.adno || null,
      countryId: row?.countryId || null,
      country: row?.country || null,
      cityList: row?.cityList || [],
      stateId: row?.stateId || null,
      city: row?.city || null,
      state: row?.state || null,
      cityId: row?.cityId || null,
      district: row?.district || null,
      address: row?.address || null,
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(AccountSchema),
    values,
    mode: 'onChange',
  });

  const {
    reset,
    handleSubmit,
    trigger,
    watch,
    setValue,
    control,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const accountGroup = groupList?.find((data) => data?.id === allValues?.ac_groupId);

  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      let URL = 'automobile/accounts/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show, data } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (set) {
            set.setValue(set.fieldName, data.id);
            set.list((pre) => [...pre, data]);
          }
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      setLoading(false);
      reset();
    } catch (error) {
      setLoading(false);
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  const debouncedGroup = useDebounce(allValues.accGroupMeta.name);

  useEffect(() => {
    reset();
  }, [open, reset]);

  const handleSubModelClose = (event) => {
    if (
      (openModelName === 'interest' || openModelName === 'gstin' || openModelName === 'bank') &&
      event.key === KEY.CLOSE
    ) {
      dialog.onFalse();
      setOpenModelName('null');
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };
  useEffect(() => {
    if (allValues?.gst_no && allValues?.gst_no.length === 15) {
      const panNumber = allValues?.gst_no.substring(2, 12);
      setValue('pan_no', panNumber);
    }
  }, [allValues?.gst_no, setValue]);
  useEventListener('keydown', handleKeyDown);

  const HandleCityList = useCallback(async () => {
    let URL = 'cities/';
    URL += `?page=${1}&limit=${100}&name=${searchCity.search}&status=true`;

    const { data } = await fetcher(URL);
    if (searchCity.key) {
      setValue(searchCity.key, data?.rows);
    }
  }, [searchCity, setValue]);

  useEffect(() => {
    if (open) {
      HandleCityList();
    }
  }, [HandleCityList, open]);

  const HandleGroupList = useCallback(
    async () => {
      let URL = 'automobile/ac_groups/';
      URL += `?page=${allValues.accGroupMeta.page
        }&limit=${500}&status=true&allGroups=false&name=${debouncedGroup}`;
      const { data } = await fetcher(URL);
      onScollerAutoComplate({
        data,
        fieldName: 'accGroupMeta',
        meta: allValues.accGroupMeta,
        setList: setGroupList,
        setValue,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allValues.accGroupMeta.page, debouncedGroup, setValue]
  );

  useEffect(() => {
    if (open) {
      HandleGroupList();
    }
  }, [open, HandleGroupList]);

  const handleGroupClose = () => {
    quickEdit.onFalse();
    HandleGroupList();
  };

  const deleteAccountGroupModel = useCallback(
    async (id) => {
      try {
        let URL = 'automobile/ac_groups/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          HandleGroupList();
          confirm.onFalse();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [HandleGroupList, confirm, enqueueSnackbar]
  );

  const list = {
    ac_groupId: setGroupList,
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={false}
        open={open}
        onKeyDown={handleKeyDown}
        PaperProps={{
          sx: { maxWidth: 1320 },
        }}
      >
        <FormProvider methods={methods}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                top: 0,
                zIndex: 9,
                position: 'sticky',
                bgcolor: (theme) => alpha(theme.palette.background.default, 1),
              }}
            >
              <Toolbar>
                <Typography variant="h5" sx={{ flex: 1 }}>
                  {isUpdate ? 'Update Account ' : 'Add Account'}
                </Typography>

                <CompanyYear />

                <IconButton color="inherit" edge="start" onClick={onClose}>
                  <Iconify icon="mingcute:close-line" />
                </IconButton>
              </Toolbar>

              <Divider sx={{ borderStyle: 'dashed' }} />
            </Grid>

            <Grid item container xs={12} md={12} sx={{ p: 2 }} spacing={2}>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
                spacing={1}
                alignContent="flex-start"
              >
                <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="subtitle1">Main Details</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={7} lg={7} xl={7} xxl={7}>
                  <RHFTextField
                    name="name"
                    label={
                      <span>
                        Name <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    size="small"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3.5} lg={3.5} xl={3.5} xxl={3.5}>
                  <RHFTextField name="s_n" label="Alias" size="small" />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    size="small"
                    disableCloseOnSelect
                    options={groupList.length ? groupList : [{ name: 'No Options', value: null }]}
                    groupBy={(option) => option?.a}
                    getOptionLabel={(option) => option.name}
                    filterOptions={(options, state) =>
                      groupList.length ? groupList : [{ name: 'No Options', value: null }]
                    }
                    onFocus={() => {
                      setAGroup(groupList?.find((data) => data.id === allValues.ac_groupId));
                    }}
                    inputValue={allValues.accGroupMeta.name}
                    onInputChange={(event, newInputValue) => {
                      setValue('accGroupMeta', {
                        ...allValues.accGroupMeta,
                        page: 1,
                        name: newInputValue,
                      });
                    }}
                    onBlur={(e) => {
                      if (!allValues?.ac_groupId && !aGroup?.value && !quickEdit?.value) {
                        document.getElementById(e.target.id).focus();
                      }
                    }}
                    openOnFocus={!aGroup?.value}
                    onChange={(event, newValue) => {
                      setAGroup(newValue);
                      setValue('bal_type', newValue?.acd);
                      setValue('ac_groupId', newValue?.id);
                    }}
                    value={(() => {
                      const addRecord = groupList?.find((data) => data.id === allValues.ac_groupId);
                      // const editRecord = row?.ac_group;

                      if (addRecord) return addRecord;
                      // if (editRecord) return editRecord;
                      return null;
                    })()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <span>
                            Group Name<span style={{ color: 'red' }}>*</span>
                          </span>
                        }
                        name="ac_groupId"
                        error={errors?.ac_groupId && !allValues?.ac_groupId}
                      />
                    )}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader component="li">
                          <ListItem sx={{ padding: 0, margin: 0 }}>Name</ListItem>
                          <ListItem sx={{ padding: 0, margin: 0 }}>Effect On</ListItem>
                        </GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                        <GroupFooter component="li">
                          <Button
                            onClick={() => {
                              setFieldName('ac_groupId');
                              setAGroup(null);
                              quickEdit.onTrue();
                            }}
                          >
                            Add
                          </Button>
                          {aGroup?.id && (
                            <>
                              <Button
                                onClick={() => {
                                  quickEdit.onTrue();
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                onClick={() => {
                                  confirm.onTrue();
                                }}
                              >
                                Delete
                              </Button>
                            </>
                          )}
                        </GroupFooter>
                      </li>
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                          {option?.name}
                        </ListItem>
                        <ListItem sx={{ padding: 0, margin: 0 }}>
                          {option.ac_group ? option.ac_group?.name : '-'}
                        </ListItem>
                      </Box>
                    )}
                    ListboxProps={{
                      ...bgColorAutocomplete,
                      onScroll: (event) =>
                        handleScroll({
                          event,
                          allValues,
                          setValue,
                          fieldName: 'accGroupMeta',
                          meta: allValues.accGroupMeta,
                        }),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2} lg={2} xl={2} xxl={2}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    options={GST_TYPE?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={GST_TYPE?.find((data) => data.id === allValues.gst_type)?.name || ''}
                    onChange={(event, newValue) => {
                      setValue('gst_type', GST_TYPE?.find((data) => data.name === newValue)?.id);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} name="gst_type" label="GST Type" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2.5} lg={2.5} xl={2.5} xxl={2.5}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    options={TRANSPORT?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={TRANSPORT?.find((data) => data.id === allValues.tra_type)?.name || ''}
                    onChange={(event, newValue) => {
                      setValue('tra_type', TRANSPORT?.find((data) => data.name === newValue)?.id);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} name="tra_type" label="Transport Type" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
                <Grid item container xs={12} md={12} sx={{ p: 2, mt: 1 }} spacing={2}>
                  <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Typography variant="subtitle1">Balance Method</Typography>
                  </Grid>
                  <Grid item container xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                      <Autocomplete
                        autoHighlight={allValues.isHighligh}
                        fullWidth
                        freeSolo
                        size="small"
                        openOnFocus
                        onInputChange={(event, newValue) => {
                          setValue('isHighligh', newValue);
                        }}
                        onBlur={() => {
                          setValue('isHighligh', '');
                        }}
                        options={BALANCE_METHOD?.map((option) => option.name)}
                        getOptionLabel={(option) => option}
                        value={
                          BALANCE_METHOD?.find((data) => data.id === allValues.bal_met)?.name || ''
                        }
                        onChange={(event, newValue) => {
                          setValue(
                            'bal_met',
                            BALANCE_METHOD?.find((data) => data.name === newValue)?.id
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} name="bal_met" label="Balance Method" />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                      <RHFTextField
                        inputProps={{
                          style: { textAlign: 'right' },
                        }}
                        label="Opening Balance"
                        name="op_bal"
                        size="small"
                        type="text"
                        onChange={(e) => {
                          const amount = e.target.value >= 0 && e.target.value;
                          if (amount) setValue(`op_bal`, parseFloat(amount));
                          else setValue(`op_bal`, null);
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                          inputComponent: NumericFormatCustom,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                      <Autocomplete
                        autoHighlight={allValues.isHighligh}
                        fullWidth
                        freeSolo
                        size="small"
                        openOnFocus
                        onInputChange={(event, newValue) => {
                          setValue('isHighligh', newValue);
                        }}
                        onBlur={() => {
                          setValue('isHighligh', '');
                        }}
                        options={BALANCE_TYPE?.map((option) => option.name)}
                        getOptionLabel={(option) => option}
                        value={
                          BALANCE_TYPE?.find((data) => data.id === allValues.bal_type)?.name || ''
                        }
                        onChange={(event, newValue) => {
                          setValue(
                            'bal_type',
                            BALANCE_TYPE?.find((data) => data.name === newValue)?.id
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="bal_type"
                            label={
                              <span>
                                Balance Type<span style={{ color: 'red' }}>*</span>
                              </span>
                            }
                            error={errors?.bal_type && !allValues?.bal_type}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {accountGroup?.crdl === true && (
                  <Grid item container xs={12} md={12} sx={{ p: 2 }} spacing={2}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Typography variant="subtitle1">Credit Limit</Typography>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} spacing={1}>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                        <RHFTextField
                          inputProps={{
                            style: { textAlign: 'right' },
                          }}
                          label="Credit Limit"
                          name="cr_limit"
                          size="small"
                          type="text"
                          onChange={(e) => {
                            const amount = e.target.value >= 0 && e.target.value;
                            if (amount) setValue(`cr_limit`, parseFloat(amount));
                            else setValue(`cr_limit`, null);
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                            inputComponent: NumericFormatCustom,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                        <RHFTextField name="cr_day" label="Credit Day" size="small" type="number" />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Grid
                item
                container
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                spacing={1}
                alignContent="flex-start"
              >
                {accountGroup?.gst === true && accountGroup?.party !== true && (
                  <>
                    <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Typography variant="subtitle1">GST IN</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFTextField
                        name="gst_no"
                        label="GST No."
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </>
                )}
                {accountGroup?.bank === true && accountGroup?.party !== true && (
                  <>
                    <Grid
                      item
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      sx={{ mt: 1 }}
                    >
                      <Typography variant="subtitle1">Bank Details</Typography>
                    </Grid>

                    <Grid item container xs={12} sm={12} spacing={1}>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                        <RHFTextField name="bank_name" label="Bank Name" size="small" />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                        <RHFTextField name="branch_name" label="Branch Name" size="small" />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                        <RHFTextField
                          name="acc_no"
                          label="Account No."
                          size="small"
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                        <RHFTextField name="ifsc_code" label="IFSC Code" size="small" />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                        <Autocomplete
                          autoHighlight={allValues.isHighligh}
                          fullWidth
                          freeSolo
                          size="small"
                          openOnFocus
                          onInputChange={(event, newValue) => {
                            setValue('isHighligh', newValue);
                          }}
                          onBlur={() => {
                            setValue('isHighligh', '');
                          }}
                          options={ACCOUNT_TYPES?.map((option) => option.name)}
                          getOptionLabel={(option) => option}
                          value={
                            ACCOUNT_TYPES?.find((data) => data.id === allValues.accounttypeId)
                              ?.name || ''
                          }
                          onChange={(event, newValue) => {
                            setValue(
                              'accounttypeId',
                              ACCOUNT_TYPES?.find((data) => data.name === newValue)?.id
                            );
                          }}
                          renderInput={(params) => (
                            <TextField {...params} name="accounttypeId" label="Type of a/c" />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option}>
                              {option}
                            </li>
                          )}
                          ListboxProps={{ ...bgColorAutocomplete }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <RHFTextField
                          name="b_address"
                          label="Address"
                          size="small"
                          rows={2}
                          multiline
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                {accountGroup?.party === true && (
                  <>
                    <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Typography variant="subtitle1">Party Details</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFTextField name="c_name" label="Contact Person" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFTextField
                        name="address"
                        label="Address"
                        size="small"
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFTextField name="district" label="Area" size="small" />
                    </Grid>

                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
                      <Autocomplete
                        autoHighlight={allValues.isHighligh}
                        fullWidth
                        size="small"
                        openOnFocus
                        onBlur={() => {
                          setValue('isHighligh', '');
                        }}
                        options={allValues.cityList?.map((option) => option.name)}
                        value={
                          allValues.cityList?.find((x) => x.id === allValues.cityId)?.name ||
                          allValues?.city?.name ||
                          ''
                        }
                        onInputChange={(event, newInputValue) => {
                          const city = allValues.cityList?.find((x) => x.name === newInputValue);
                          setValue('isHighligh', newInputValue);
                          if (newInputValue) {
                            setCityValue({ key: 'cityList', search: newInputValue });
                            if (city) {
                              reset({
                                ...allValues,
                                cityId: city.id,
                                stateId: city.state.id,
                                countryId: city.country.id,
                                state: city.state,
                                country: city.country,
                              });
                            }
                          }
                        }}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField {...params} name="cityId" label="Select City" />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                      <RHFTextField name="pincode" label="Pincode" size="small" type="number" />
                    </Grid>
                    {console.log('allValues?.state', allValues?.state)}
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        freeSolo
                        size="small"
                        options={[allValues?.state]?.map((option) => option?.name)}
                        getOptionLabel={(option) => option}
                        value={allValues?.state?.name || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="stateId"
                            label="Select State"
                            error={errors?.stateId && !allValues?.stateId}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                        disabled="true"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        freeSolo
                        size="small"
                        options={[allValues?.country]?.map((option) => option?.name)}
                        getOptionLabel={(option) => option}
                        value={allValues?.country?.name || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="countryId"
                            label="Select Country"
                            error={errors?.countryId && !allValues?.countryId}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                        disabled="true"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField
                        name="mobile"
                        label="Mobile"
                        size="small"
                        type="number"
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.length <= 10) setValue('mobile', e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="pan_no" label="PAN No." size="small" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <RHFTextField name="email" label="Email" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} lg={5} xl={5} xxl={5}>
                      <RHFTextField name="adno" label="Aadhar No." size="small" type="number" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={7} lg={7} xl={7} xxl={7}>
                      <RHFTextField
                        name="gst_no"
                        label="GST No."
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Dialog
            open={dialog.value && openModelName === 'bank'}
            onKeyDown={(event) => handleSubModelClose(event)}
            onClose={dialog.onTrue}
          >
            <Toolbar>
              <Typography variant="h6" sx={{ flex: 1 }}>
                Bank Details
              </Typography>

              <CompanyYear />

              <IconButton color="inherit" edge="start" onClick={dialog.onFalse} tabIndex={-1}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>

            <Divider sx={{ borderStyle: 'dashed' }} />
            <DialogContent>
              <Grid item container xs={12} sm={12} sx={{ mt: 1 }} spacing={1}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="bank_name" label="Bank Name" size="small" autoFocus />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="branch_name" label="Branch Name" size="small" />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <RHFTextField name="acc_no" label="Account No." size="small" type="number" />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <RHFTextField name="ifsc_code" label="IFSC Code" size="small" />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    options={ACCOUNT_TYPES?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      ACCOUNT_TYPES?.find((data) => data.id === allValues.accounttypeId)?.name || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'accounttypeId',
                        ACCOUNT_TYPES?.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} name="accounttypeId" label="Type of a/c" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="b_address" label="Address" size="small" rows={2} multiline />
                </Grid>
              </Grid>
            </DialogContent>

            <Stack
              direction="row-reverse"
              alignItems="center"
              sx={{
                bottom: 0,
                zIndex: 111111,
                p: 2,
                mt: 2,
                position: 'sticky',
                width: '100%',
                bgcolor: (theme) => alpha(theme.palette.background.default, 1),
              }}
              id="modelfooter"
            >
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
                onClick={dialog.onFalse}
              >
                {row ? 'Update' : 'Add'}
              </LoadingButton>

              <Button variant="outlined" onClick={dialog.onFalse} sx={{ mr: 1 }}>
                Cancel
              </Button>
            </Stack>
          </Dialog>
          <Dialog
            open={dialog.value && openModelName === 'interest'}
            onKeyDown={(event) => handleSubModelClose(event)}
            onClose={dialog.onTrue}
          >
            <Toolbar>
              <Typography variant="h6" sx={{ flex: 1 }}>
                Interest Details
              </Typography>

              <CompanyYear />

              <IconButton color="inherit" edge="start" onClick={dialog.onFalse} tabIndex={-1}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>

            <Divider sx={{ borderStyle: 'dashed' }} />
            <DialogContent>
              <Grid item container xs={12} sm={12} sx={{ mt: 1 }} spacing={1}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField
                    name="in_rate"
                    label="Interest %"
                    size="small"
                    type="number"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="in_ac" label="Interest A/c." size="small" type="number" />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="tds_ac" label="TDS A/c." size="small" type="number" />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    options={BALANCE_TYPE?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      BALANCE_TYPE?.find((data) => data.id === allValues.in_bal_type)?.name || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'in_bal_type',
                        BALANCE_TYPE?.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} name="in_bal_type" label="Credit/Debit" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <Stack
              direction="row-reverse"
              alignItems="center"
              sx={{
                bottom: 0,
                zIndex: 111111,
                p: 2,
                mt: 2,
                position: 'sticky',
                width: '100%',
                bgcolor: (theme) => alpha(theme.palette.background.default, 1),
              }}
              id="modelfooter"
            >
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
                onClick={dialog.onFalse}
              >
                {row ? 'Update' : 'Add'}
              </LoadingButton>

              <Button variant="outlined" onClick={dialog.onFalse} sx={{ mr: 1 }}>
                Cancel
              </Button>
            </Stack>
          </Dialog>

          <Stack
            direction="row-reverse"
            alignItems="center"
            sx={{
              bottom: 0,
              zIndex: 111111,
              p: 3,
              position: 'sticky',
              justifyContent: 'space-between',
              width: '100%',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
            id="modelfooter"
          >
            <Tooltip title="(Alt + A)" arrow>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={async () => {
                  if (await trigger()) {
                    onSubmit();
                  }
                }}
              >
                {isUpdate ? 'Update' : 'Add'}
              </LoadingButton>
            </Tooltip>

            <Tooltip title="(Esc)" arrow>
              <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
                Cancel
              </Button>
            </Tooltip>

            <Button variant="soft" onClick={() => reset(values)} sx={{ mr: 2 }}>
              Reset
            </Button>

            <Stack direction="row" alignItems="center" flexGrow={1} spacing={2}>
              {accountGroup?.inte === true && (
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    setOpenModelName('interest');
                    dialog.onTrue();
                  }}
                >
                  Interest
                </Button>
              )}
              {accountGroup?.bank === true && accountGroup?.party === true && (
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    setOpenModelName('bank');
                    dialog.onTrue();
                  }}
                >
                  Bank
                </Button>
              )}
            </Stack>
          </Stack>
        </FormProvider>
      </Dialog>

      <GroupAccountForm
        row={aGroup || null}
        open={quickEdit.value}
        onClose={handleGroupClose}
        table={table}
        getAll={getAll}
        tableData={tableData}
        set={{
          fieldName,
          setValue,
          list: list[fieldName],
        }}
      />

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => aGroup?.id && deleteAccountGroupModel(aGroup?.id)}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

AUserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  set: PropTypes.object,
  tableData: PropTypes.array,
};
