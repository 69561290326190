import { Grid, Paper } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import AppWidget from 'src/sections/overview/app/app-widget';

function JobCardList({ enquiryDashboard, setEnqstatusData, enqstatusData }) {
  console.log('enqstatusData', enqstatusData);
  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={1} sx={{ pb: 1 }}>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          xxl={3}
          onClick={() => setEnqstatusData('E,Q')}
        >
          <AppWidget
            title="Create Job Card"
            total={enquiryDashboard?.open || 0}
            icon="solar:user-rounded-bold"
            chart={{
              series: 48,
            }}
            enquiryActive={enqstatusData === 'E,Q' && 'true'}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} onClick={() => setEnqstatusData('B')}>
          <AppWidget
            title="JOB Card Open"
            total={enquiryDashboard?.book || 0}
            icon="solar:user-rounded-bold"
            chart={{
              series: 48,
            }}
            enquiryActive={enqstatusData === 'B' && 'true'}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} onClick={() => setEnqstatusData('I')}>
          <AppWidget
            title="Job Card Closed"
            total={enquiryDashboard?.converted || 0}
            icon="solar:user-rounded-bold"
            chart={{
              series: 48,
            }}
            enquiryActive={enqstatusData === 'I' && 'true'}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} onClick={() => setEnqstatusData('L')}>
          <AppWidget
            title="Instant Feedback"
            total={enquiryDashboard?.close || 0}
            icon="solar:user-rounded-bold"
            chart={{
              series: 2,
            }}
            enquiryActive={enqstatusData === 'L' && 'true'}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default JobCardList;

JobCardList.propTypes = {
  enquiryDashboard: PropTypes.object,
  setEnqstatusData: PropTypes.func,
  enqstatusData: PropTypes.string,
};
