import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { create, update } from 'src/utils/axios';
import {
  DataGrid,
  GridActionsCellItem,
  GridAddIcon,
  GridCellModes,
  GridColDef,
  GridRowEditStopReasons,
  GridRowModes,
  GridSaveAltIcon,
  GridValueGetterParams,
  GridValueSetterParams,
} from '@mui/x-data-grid';
import {
  BALANCE_TYPE,
  BANK_CASH,
  CASH_DEBIT,
  CITY,
  Effect,
  KEY,
  MODULE_STATUS,
  OPP_AC,
  OPTION,
  RCPT_PYMT,
  STATE,
  TITLES,
  Tax_BilOfSupply,
} from 'src/_mock/constant';
import {
  Card,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { alpha, spacing } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import { useEventListener } from 'src/hooks/use-event-listener';
// ----------------------------------------------------------------------

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();

  const [openModelName, setOpenModelName] = useState('');

  const dialog = useBoolean();

  const departmentSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const values = useMemo(
    () => ({
      name: row?.name || '',
      status: row?.status || false,
      description: row?.description || '',
      balance_method: row?.balance_method || 'Bill To Bill',
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(departmentSchema),
    values,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const onSubmit = handleSubmit(async (payload) => {
    try {
      let URL = 'roles/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const { watch, control, setValue } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'company_banks',
  });

  const handleAdd = () => {
    append({
      name: '',
      branch_name: '',
      ifsc_code: '',
      iban_no: '',
      swift_code: '',
      upi_code: '',
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };
  useEventListener('keydown', handleKeyDown);

  const [cellModesModel, setCellModesModel] = useState({});

  const handleCellClick = useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (!event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => ({
      // Revert the mode of the other cells from other rows
      ...Object.keys(prevModel).reduce(
        (acc, id) => ({
          ...acc,
          [id]: Object.keys(prevModel[id]).reduce(
            (acc2, field) => ({
              ...acc2,
              [field]: { mode: GridCellModes.View },
            }),
            {}
          ),
        }),
        {}
      ),
      [params.id]: {
        // Revert the mode of other cells in the same row
        ...Object.keys(prevModel[params.id] || {}).reduce(
          (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
          {}
        ),
        [params.field]: { mode: GridCellModes.Edit },
      },
    }));
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  function ValueParserSetterGrid() {
    return (
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={defaultRows}
          columns={columns}
          rowModesModel={rowModesModel}
          // onRowModesModelChange={handleRowModesModelChange}
          // onRowEditStop={handleRowEditStop}
          // processRowUpdate={processRowUpdate}
          rowHeight={40}
          // slots={{
          //   toolbar: EditToolbar,
          // }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          hideFooterPagination
          hideFooterSelectedRowCount
          disableRowSelectionOnClick
        />
      </div>
    );
  }

  const defaultRows = [
    { id: 1, productName: 'Snow', quantity: 10, rate: 1000, amount: 20000 },
    { id: 2, productName: 'Lannister', quantity: 20, rate: 1000, amount: 20000 },
    { id: 3, productName: 'Lannister', quantity: 10, rate: 1000, amount: 20000 },
    { id: 4, productName: 'Stark', quantity: 100, rate: 1000, amount: 20000 },
    { id: 5, productName: 'Targaryen', quantity: 2000, rate: 1000, amount: 20000 },
  ];

  const [rows, setRows] = useState(defaultRows);
  const [rowModesModel, setRowModesModel] = useState({});
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleDeleteClick = (id) => () => {
    setRows(defaultRows.filter((row) => row.id !== id));
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow = defaultRows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(defaultRows.filter((row) => row.id !== id));
    }
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: 'productName', headerName: 'Product Name', width: 750, editable: true },
    { field: 'quantity', headerName: 'Quantity', width: 120, editable: true },
    {
      field: 'rate',
      headerName: 'Rate',
      width: 120,
      editable: true,
    },
    { field: 'amount', headerName: 'Amount', width: 120, editable: true },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 120,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<GridSaveAltIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<GridSaveAltIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<GridAddIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<GridSaveAltIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 1320 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update CN Entry With Stock ' : 'Create CN Entry With Stock'}
              </Typography>

              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>

            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          <Grid item container xs={12} md={12} sx={{ p: 3 }} columnSpacing={8} spacing={4}>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              xxl={4}
              spacing={2}
              alignContent="start"
            >
              <Grid item xs={12} sm={6} md={12} lg={12} xl={12} xxl={12}>
                <RHFSelect name="cash/debit" label="Cash/Debit" size="small" autoFocus>
                  {CASH_DEBIT.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="partyA/C" label="Party A/C." size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFSelect name="stockEffect" label="Stock Effect" size="small">
                  {OPTION.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              xxl={4}
              spacing={2}
              alignContent="start"
            >
              <Grid item xs={12} sm={6} md={12} lg={6} xl={6} xxl={6}>
                <RHFTextField name="invoiceType" label="Invoice Type" size="small" />
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={6} xl={6} xxl={6}>
                <RHFTextField name="reason" label="Reason" size="small" />
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={12} xl={12} xxl={12}>
                <RHFSelect name="effect" label="Effect" size="small">
                  {Effect.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={12} xl={12} xxl={12}>
                <RHFSelect name="taxBilOfSupply" label="Tax/Bil of Supply" size="small">
                  {Tax_BilOfSupply.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              xxl={4}
              spacing={2}
              alignContent="start"
            >
              <Grid item xs={12} sm={6} md={12} lg={6} xl={6} xxl={6}>
                <DatePicker
                  label="Voucher Date"
                  value={new Date()}
                  // onChange={handleFilterStartDate}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={6} xl={6} xxl={6}>
                <RHFTextField name="voucherNo" label="Voucher No." size="small" />
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={6} xl={6} xxl={6}>
                <DatePicker
                  label="Doc Date"
                  value={new Date()}
                  // onChange={handleFilterStartDate}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={6} xl={6} xxl={6}>
                <RHFTextField name="docNo" label="Doc. No." size="small" />
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={6} xl={6} xxl={6}>
                <DatePicker
                  label="Original Bill Date"
                  value={new Date()}
                  // onChange={handleFilterStartDate}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={12} lg={6} xl={6} xxl={6}>
                <RHFTextField name="originalbillNo" label="Original Bill No." size="small" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={12} md={12} sx={{ p: 3 }} spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              {/* <BasicTable/> */}
              <ValueParserSetterGrid />
            </Grid>
          </Grid>

          <Grid item container xs={12} md={12} sx={{ p: 3 }} spacing={2}>
            <Grid item Container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <Grid item xs={12} sm={7} md={7} lg={7} xl={7} xxl={7}>
                <RHFTextField name="narration" label="Narration" size="small" multiline rows={3} />
              </Grid>
            </Grid>
            <Grid item Container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                marginBottom={2}
                marginLeft={5}
                display="flex"
                justifyContent="space-between"
              >
                <h5>Item Amount</h5>
                <h5>13,00,500 DB</h5>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                marginLeft={5}
                display="flex"
                justifyContent="space-between"
              >
                <h5>Bill Amount</h5>
                <h5>13,00,500 CR</h5>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DialogActions
          sx={{
            bottom: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {row ? 'Update' : 'Add'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
