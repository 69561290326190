import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFCheckbox,
  RHFMultiCheckbox,
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { KEY, MODULE_STATUS, TITLES } from 'src/_mock/constant';
import { useBoolean } from 'src/hooks/use-boolean';
import {
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Toolbar,
  Typography,
  alpha,
  Switch,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { useEventListener } from 'src/hooks/use-event-listener';
// ----------------------------------------------------------------------

export default function Permission({ row, open, onClose, from, getAll, userData }) {
  const { enqueueSnackbar } = useSnackbar();
  const [modules, setModules] = useState([]);
  const password = useBoolean();
  const userSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    title: Yup.string().required('Required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    mobile: Yup.string().required('Mobile number is required').min(10).max(10),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  const values = useMemo(
    () => ({
      name: row?.name || '',
      title: row?.title || '',
      email: row?.email || '',
      mobile: row?.mobile || '',
      password: row?.password || '',
      confirmPassword: row?.confirmPassword || '',
      status: row?.status || false,
    }),
    [row]
  );
  const [checkboxStates, setCheckboxStates] = useState({});
  const [overallPerms, setOverallPerms] = useState({
    C: false,
    R: false,
    U: false,
    D: false,
    I: false,
    E: false,
    P: false,
  });
  const buildMenuItemTree1 = useCallback(
    (node, title = '', initialStates) => {
      const userPermission =
        from === 'role'
          ? row?.role_permissions?.find((permission) => permission.moduleId === node.id)
          : userData?.user_permissions?.find((permission) => permission.moduleId === node.id);
      const permissionState = userPermission
        ? {
            C: userPermission.c,
            R: userPermission.r,
            U: userPermission.u,
            D: userPermission.d,
            I: userPermission.i,
            E: userPermission.e,
            P: userPermission.p,
          }
        : {
            C: false,
            R: false,
            U: false,
            D: false,
            I: false,
            E: false,
            P: false,
          };

      const menuItem = {
        title: title ? `${title} > ${node.title}` : node.title,
        moduleId: node.id,
        companyId: from === 'role' ? null : row.id,
        ...permissionState,
      };
      if (initialStates && node.id) {
        initialStates[node.id] = menuItem;
      }
      // console.log(userData.user_permissions)
      if (node.children?.length) {
        return {
          ...node,
          title: title ? `${title} > ${node.title}` : node.title,
          companyId: from === 'role' ? null : row?.id,
          children: node.children.map((item) =>
            buildMenuItemTree1(item, title ? `${title} > ${node.title}` : node.title, initialStates)
          ),
        };
      }

      return initialStates;
    },
    [userData, from, row?.role_permissions, row?.id]
  );
  // Initialize checkbox states based on modules
  useEffect(() => {
    const initialStates = {};
    modules.map((x) => buildMenuItemTree1(x, x.title, initialStates));
    setCheckboxStates(initialStates);
  }, [buildMenuItemTree1, modules]);

  // Handle 'Select All' for a module
  const selectAllForModule = (moduleId) => {
    const isAllSelected = !Object.values(checkboxStates[moduleId])
      .slice(2)
      .every((v) => v); // Ignore the first two keys (title, moduleId)

    setCheckboxStates((prev) => {
      const newCheckboxStates = {
        ...prev,
        [moduleId]: {
          ...prev[moduleId],
          C: isAllSelected,
          R: isAllSelected,
          U: isAllSelected,
          D: isAllSelected,
          I: isAllSelected,
          E: isAllSelected,
          P: isAllSelected,
        },
      };

      // Update overallPerms based on the new states of checkboxes
      Object.keys(overallPerms).forEach((permType) => {
        const isPermSelectedInAllModules = Object.values(newCheckboxStates).every(
          (module) => module[permType]
        );
        setOverallPerms((op) => ({ ...op, [permType]: isPermSelectedInAllModules }));
      });

      return newCheckboxStates;
    });
  };

  const selectAllOfPermission = (permType) => {
    setCheckboxStates((prev) => {
      const isAllSelected = !Object.values(prev).every((module) => module[permType]);
      return Object.fromEntries(
        Object.entries(prev).map(([moduleId, perms]) => [
          moduleId,
          { ...perms, [permType]: isAllSelected },
        ])
      );
    });
    setOverallPerms((prev) => ({ ...prev, [permType]: !prev[permType] }));
  };
  // Handle individual Switch change and update overallPerms accordingly

  const updateAllModulesPermissions = (newValue) => {
    setCheckboxStates((prev) =>
      Object.fromEntries(
        Object.entries(prev).map(([moduleId, perms]) => [
          moduleId,
          {
            ...perms,
            C: newValue,
            R: newValue,
            U: newValue,
            D: newValue,
            I: newValue,
            E: newValue,
            P: newValue,
          },
        ])
      )
    );
  };
  const methods = useForm({
    resolver: yupResolver(userSchema),
    values,
  });

  const {
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;
  const allValues = watch();
  const buildMenuItemTree = (node, title = '') => (
    <>
      <Grid container key={node.id}>
        <Grid item xs={12} md={4}>
          {title ? `${title} > ${node.title}` : node.title}
        </Grid>
        <Grid item xs={12} md={1}>
          <Switch
            checked={
              !['C', 'R', 'U', 'D', 'I', 'E', 'P'].filter(
                (x) => checkboxStates[node.id]?.[x] === false
              ).length
            }
            onClick={() => selectAllForModule(node.id)}
          />
        </Grid>
        {['C', 'R', 'U', 'D', 'I', 'E', 'P'].map((permType) => (
          <Grid item xs={12} md={1} key={`${node.id}-${permType}`}>
            <Switch
              checked={checkboxStates[node.id]?.[permType] || false}
              onClick={() => handleCheckboxChange(node.id, permType)}
            />
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ borderStyle: 'dashed' }} />
      {node.children?.length > 0 &&
        node.children.map((item) => buildMenuItemTree(item, node.title))}
    </>
  );
  const onSubmit = async (payload) => {
    try {
      let URL = from === 'role' ? 'roles/permissions/' : 'users/permissions/';

      const filteredModules = Object.entries(checkboxStates)
        .filter(
          ([moduleId, permissions]) =>
            permissions.C ||
            permissions.R ||
            permissions.U ||
            permissions.D ||
            permissions.I ||
            permissions.E ||
            permissions.P
        )
        .map(([moduleId, permissions]) => ({
          moduleId,
          roleId: row.id,
          ...permissions,
        }));

      if (userData?.id || row?.id) {
        URL += from === 'role' ? row.id : userData.id;
        const { success, show } = await update({ url: URL, payload: filteredModules });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  };

  const businessId = from === 'role' ? row?.department?.businessId : row?.businessId;

  const getAllModules = useCallback(async () => {
    try {
      const URL = `/modules?page=1&limit=1000&businessId=${businessId},null`;
      const { success, data } = await fetcher(URL);
      if (success) {
        setModules(data.rows);
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  }, [businessId, reset, enqueueSnackbar]);

  // Handle individual Switch change
  const handleCheckboxChange = (moduleId, permType) => {
    setCheckboxStates((prev) => {
      const newCheckboxStates = {
        ...prev,
        [moduleId]: {
          ...prev[moduleId],
          [permType]: !prev[moduleId][permType],
        },
      };

      // Check if all permissions of a particular type across modules are true
      const isAllSelected = Object.values(newCheckboxStates).every((module) => module[permType]);
      setOverallPerms((op) => ({ ...op, [permType]: isAllSelected }));
      return newCheckboxStates;
    });
  };
  useEffect(() => {
    reset();
    getAllModules();
  }, [open, reset, getAllModules]);

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 1120 },
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          top: 0,
          zIndex: 9,
          position: 'sticky',
          bgcolor: (theme) => alpha(theme.palette.background.default, 1),
        }}
      >
        <Toolbar>
          <Typography variant="h5" sx={{ flex: 1 }}>
            Update Permission
          </Typography>

          <IconButton color="inherit" edge="start" onClick={onClose}>
            <Iconify icon="mingcute:close-line" />
          </IconButton>
        </Toolbar>
        <Divider sx={{ borderStyle: 'dashed' }} />
      </Grid>

      <Divider sx={{ borderStyle: 'dashed' }} />
      <DialogContent sx={{ mt: 2 }}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <b>Modules Name</b>
          </Grid>
          <Grid item xs={12} md={1}>
            <b>ALL</b>
          </Grid>
          {['Create', 'Read', 'Update', 'Delete', 'Import', 'Export', 'Print'].map((permType) => (
            <Grid item xs={12} md={1} key={permType}>
              <b>{permType}</b>
            </Grid>
          ))}
        </Grid>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Grid container>
          <Grid item xs={12} md={4}>
            ALL
          </Grid>
          <Grid item xs={12} md={1}>
            <Switch
              checked={Object.values(overallPerms).every((v) => v)}
              onChange={() => {
                const newValue = !Object.values(overallPerms).every((v) => v);
                ['C', 'R', 'U', 'D', 'I', 'E', 'P'].forEach((permType) =>
                  selectAllOfPermission(permType)
                );
                setOverallPerms({
                  C: newValue,
                  R: newValue,
                  U: newValue,
                  D: newValue,
                  I: newValue,
                  E: newValue,
                  P: newValue,
                });
                updateAllModulesPermissions(newValue);
              }}
            />
          </Grid>
          {['C', 'R', 'U', 'D', 'I', 'E', 'P'].map((permType) => (
            <Grid item xs={12} md={1} key={permType}>
              <Switch
                checked={overallPerms[permType]}
                onChange={() => selectAllOfPermission(permType)}
              />
            </Grid>
          ))}
        </Grid>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {modules.map((x) => buildMenuItemTree(x, ''))}
      </DialogContent>

      <DialogActions
        sx={{
          bottom: 0,
          zIndex: 9,
          position: 'sticky',
          bgcolor: (theme) => alpha(theme.palette.background.default, 1),
        }}
      >
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>

        <LoadingButton
          type="button"
          variant="contained"
          loading={isSubmitting}
          onClick={() => {
            onSubmit();
          }}
        >
          {row ? 'Update' : 'Add'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

Permission.propTypes = {
  row: PropTypes.object,
  userData: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  getAll: PropTypes.func,
  from: PropTypes.string,
};
