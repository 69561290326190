import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { create, destroy, fetcher, update } from 'src/utils/axios';
import { KEY } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import CompanyYear from 'src/layouts/_common/company-year';
import { useBoolean } from 'src/hooks/use-boolean';
import { useDebounce } from 'src/hooks/use-debounce';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import Iconify from 'src/components/iconify';
import { Stack } from '@mui/system';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useEventListener } from 'src/hooks/use-event-listener';
import { handleScroll } from 'src/layouts/_common/scroller';
import {
  assignNullToEmptyOrUndefinedValues,
  useBgColorAutocomplete,
} from 'src/_mock/constant_funcation';
import {
  useGetAxles,
  useGetBodies,
  useGetChasformates,
  useGetClasses,
  useGetCommon,
  useGetEmistandards,
  useGetFuels,
  useGetSeatings,
  useGetStartes,
  useGetTovehicle,
  useGetVehnainrto,
} from '../global';
import GlobalQuickForm from '../globalmodel';
// Reusable hook for managing an input state and its debounced version
function useDebouncedInput(initialValue = { name: null, value: null }, delay = 500) {
  const [input, setInput] = useState(initialValue);
  const [debouncedInput, setDebouncedInput] = useState(input);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedInput(input), delay);
    return () => clearTimeout(handler);
  }, [input, delay]);

  return [input, setInput, debouncedInput];
}
export default function VehicleQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();

  const [vehicalObj, setVehicalObj] = useState({
    vehicleNo: 0,
    copyVehicleNo: null,
  });
  const [copyVehicleNo, setCopyVehicleNo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editrow, setEditRow] = useState({});
  const [fieldName, setFieldName] = useState('');
  const quickEditStatus = useBoolean();
  const confirm = useBoolean();

  const bgColorAutocomplete = useBgColorAutocomplete();

  const rolesSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });
  const [fulesInput, setFuelsInput, debouncedFuels] = useDebouncedInput();

  const fules = useGetFuels();
  const seatings = useGetSeatings();
  const axles = useGetAxles();
  const emistandards = useGetEmistandards();
  const bodies = useGetBodies();
  const classes = useGetClasses();
  const startes = useGetStartes();
  const tovehicles = useGetTovehicle();
  const vehnainrto = useGetVehnainrto();

  const metalist = useMemo(
    () => ({
      avehicleMeta: {
        URL: 'automobile/vehicles',
        query: {
          limit: 10,
          name: '',
        },
        editRow: null,
      },
    }),
    []
  );

  const values = useMemo(
    () => ({
      id: '',
      name: '',
      alias: '',
      atofuelId: 1,
      aseatingId: 2,
      atobodieId: 1,
      atoaxleId: 1,
      aemistandardId: 6,
      atostartId: 2,
      atoclasseId: 1,
      avehnainrtoId: 1,
      atovehicleId: 1,
      atogroupId: 1,
      chassis: '',
      identity: '',
      reartiersize: '',
      fronttiersize: '',
      grossweight: '',
      unladenweight: '',
      wheelbase: '',
      cc: '',
      cylinder: '',
      activeinworkshop: false,
      activeinsales: false,
      status: false,
      ...metalist,
    }),
    [metalist]
  );

  const methods = useForm({
    resolver: yupResolver(rolesSchema),
    values,
  });

  const {
    reset,
    handleSubmit,
    trigger,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const GetVehicleById = useCallback(
    async (id) => {
      try {
        if (id > 0) {
          const URL = `automobile/vehicles/${id}`;
          const { data } = await fetcher(URL);
          if (data?.id)
            reset({ ...data, id: setVehicalObj.copyVehicleNo ? '' : data.id, ...metalist });
          else reset(values);
        } else reset(values);
      } catch (error) {
        console.error(error);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [metalist, reset, values]
  );
  useEffect(() => {
    if (row?.id) {
      setVehicalObj((pre) => ({ ...pre, vehicleNo: row.id }));
    }
  }, [GetVehicleById, row?.id, setValue]);

  const debouncedVehicles = useDebounce(allValues.avehicleMeta.name, 500);

  const vehicles = useGetCommon(
    debouncedVehicles ? allValues.avehicleMeta : allValues.avehicleMeta
  );

  const onSubmit = handleSubmit(async (payload) => {
    payload = assignNullToEmptyOrUndefinedValues(payload);

    setLoading(true);
    try {
      const payload1 = Object.keys(payload).reduce((acc, key) => {
        if (!key.endsWith('Meta')) {
          acc[key] = payload[key];
        }
        return acc;
      }, {});
      let URL = 'automobile/vehicles/';
      if (vehicalObj.vehicleNo !== 0 && allValues.id) {
        URL += allValues.id;
        const { success, show } = await update({ url: URL, payload: payload1 });
        if (success) {
          getAll();
          // onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
          reset(allValues);
        }
      } else {
        const { success, show } = await create({ url: URL, payload: payload1 });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      setLoading(false);
      console.error(error);
    }
  });

  const deleteAccountModel = useCallback(
    async (id) => {
      try {
        let URL = 'accounts/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          confirm.onFalse();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [confirm, enqueueSnackbar]
  );
  useEffect(() => {
    reset();
  }, [open, reset]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);
  const allfiledmodel = {
    atofuelId: {
      title: 'fuels',
      url: 'automobile/fuels',
      getAll: setFuelsInput,
      fieldName,
      setValue,
    },
  };
  return (
    <Dialog fullScreen open={open} onKeyDown={handleKeyDown}>
      <FormProvider methods={methods}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            top: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Toolbar>
            <Typography variant="h5" sx={{ flex: 1 }}>
              {vehicalObj.vehicleNo !== 0 && allValues.id ? 'Update Vehicle' : 'Add Vehicle'}
            </Typography>
            <CompanyYear />
            <IconButton color="inherit" edge="start" tabIndex={-1} onClick={onClose}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>
        <DialogContent>
          <Grid
            spacing={2}
            container
            sx={{
              p: 3,
              mb: 10,
            }}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFTextField
                name="vNo"
                label="Vehical No."
                size="small"
                value={vehicalObj.vehicleNo}
                onChange={(e) => {
                  setVehicalObj((pre) => ({
                    ...pre,
                    copyVehicleNo: null,
                    vehicleNo: e.target.value,
                  }));
                  GetVehicleById(e.target.value);
                }}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <Autocomplete
                autoHighlight={allValues.avehicleMeta.query.name?.length > 0}
                fullWidth
                size="small"
                disableCloseOnSelect
                options={vehicles.rows}
                groupBy={(option) => option?.a}
                getOptionLabel={(option) => option.name}
                getOptionDisabled={(option) => option?.value === null}
                filterOptions={(options, state) =>
                  vehicles.rows.length ? vehicles.rows : [{ name: 'No Options', value: null }]
                }
                inputValue={allValues.avehicleMeta.query.name}
                onInputChange={(event, newInputValue) => {
                  setValue('avehicleMeta.query.name', newInputValue);
                }}
                onChange={(event, newValue) => {
                  setVehicalObj((pre) => ({ ...pre, copyVehicleNo: newValue, vehicleNo: 0 }));
                  GetVehicleById(newValue?.id);
                }}
                value={vehicalObj.copyVehicleNo}
                renderInput={(params) => <TextField {...params} label="Copy From Vehicle" />}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option.id}>
                    <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                  </Box>
                )}
                ListboxProps={{
                  ...bgColorAutocomplete,
                  onScroll: (event) =>
                    handleScroll({
                      event,
                      allValues,
                      setValue,
                      fieldName: 'avehicleMeta',
                      meta: allValues.avehicleMeta,
                      swr: vehicles,
                    }),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Typography variant="subtitle1">Vehical Details</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={4} xl={4} xxl={2}>
              <RHFTextField name="name" label="Product Name" size="small" autoFocus />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={2} xl={2} xxl={1}>
              <RHFTextField name="alias" label="Short Name" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2} xxl={1}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={classes.rows?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={classes.rows?.find((data) => data.id === allValues.atoclasseId)?.name || ''}
                onChange={(event, newValue) => {
                  setValue('atoclasseId', classes.rows?.find((data) => data.name === newValue)?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="atoclasseId"
                    label="Product Class (RTO)"
                    error={errors?.atoclasseId && !allValues?.atoclasseId}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
                ListboxProps={{
                  ...bgColorAutocomplete,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2} xxl={1}>
              <RHFTextField name="identity" label="Product Identity" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2} xxl={1}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={classes.rows?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={classes.rows?.find((data) => data.id === allValues.atoclasseId)?.name || ''}
                onChange={(event, newValue) => {
                  setValue('atoclasseId', classes.rows?.find((data) => data.name === newValue)?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="atoclasseId"
                    label="Product Class (RTO)"
                    error={errors?.atoclasseId && !allValues?.atoclasseId}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
                ListboxProps={{
                  ...bgColorAutocomplete,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2} xxl={1}>
              <RHFTextField
                name="wheelbase"
                label="Wheel Base"
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">MM</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2} xxl={1}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={seatings.rows?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={seatings.rows?.find((data) => data.id === allValues.aseatingId)?.name || ''}
                onChange={(event, newValue) => {
                  setValue('aseatingId', seatings.rows?.find((data) => data.name === newValue)?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="aseatingId"
                    label="Select Seating"
                    error={errors?.aseatingId && !allValues?.aseatingId}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
                ListboxProps={{
                  ...bgColorAutocomplete,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2} xxl={1}>
              <RHFTextField name="chassis" label="Chassis Format" size="small" />
            </Grid>

            {/* <Grid item xs={12} sm={12} md={6} lg={2} xl={2} xxl={1}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={axles.rows?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={axles.rows?.find((data) => data.id === allValues.atoaxleId)?.name || ''}
                onChange={(event, newValue) => {
                  setValue('atoaxleId', axles.rows?.find((data) => data.name === newValue)?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="atoaxleId"
                    label="Select Axles"
                    error={errors?.atoaxleId && !allValues?.atoaxleId}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />
            </Grid> */}
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2} xxl={1}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={emistandards.rows?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={
                  emistandards.rows?.find((data) => data.id === allValues.aemistandardId)?.name ||
                  ''
                }
                onChange={(event, newValue) => {
                  setValue(
                    'aemistandardId',
                    emistandards.rows?.find((data) => data.name === newValue)?.id
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="aemistandardId"
                    label="Select Emission"
                    error={errors?.aemistandardId && !allValues?.aemistandardId}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
                ListboxProps={{
                  ...bgColorAutocomplete,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2} xxl={1}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={bodies.rows?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={bodies.rows?.find((data) => data.id === allValues.atobodieId)?.name || ''}
                onChange={(event, newValue) => {
                  setValue('atobodieId', bodies.rows?.find((data) => data.name === newValue)?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="atobodieId"
                    label="Select Body"
                    error={errors?.atobodieId && !allValues?.atobodieId}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
                ListboxProps={{
                  ...bgColorAutocomplete,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2} xxl={1}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={startes.rows?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={startes.rows?.find((data) => data.id === allValues.atostartId)?.name || ''}
                onChange={(event, newValue) => {
                  setValue('atostartId', startes.rows?.find((data) => data.name === newValue)?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="atostartId"
                    label="Start"
                    error={errors?.atostartId && !allValues?.atostartId}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
                ListboxProps={{
                  ...bgColorAutocomplete,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2} xxl={1}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={tovehicles.rows?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={
                  tovehicles.rows?.find((data) => data.id === allValues.atovehicleId)?.name || ''
                }
                onChange={(event, newValue) => {
                  setValue(
                    'atovehicleId',
                    tovehicles.rows?.find((data) => data.name === newValue)?.id
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="atovehicleId"
                    label="Product Group"
                    error={errors?.atovehicleId && !allValues?.atovehicleId}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
                ListboxProps={{
                  ...bgColorAutocomplete,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2} xxl={1}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={vehnainrto.rows?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={
                  vehnainrto.rows?.find((data) => data.id === allValues.avehnainrtoId)?.name || ''
                }
                onChange={(event, newValue) => {
                  setValue(
                    'avehnainrtoId',
                    vehnainrto.rows?.find((data) => data.name === newValue)?.id
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="avehnainrtoId"
                    label="Product Name (RTO)"
                    error={errors?.avehnainrtoId && !allValues?.avehnainrtoId}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
                ListboxProps={{
                  ...bgColorAutocomplete,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2} xxl={1}>
              <RHFTextField name="fronttiersize" label="Front Tier Size" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2} xxl={1}>
              <RHFTextField name="reartiersize" label="Rear Tier Size" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2} xxl={1}>
              <RHFTextField
                name="cc"
                label="H.P.C.C"
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">CC</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={1}>
              <RHFTextField
                name="unladenweight"
                label="Unladen Weight"
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={1}>
              <RHFTextField
                name="grossweight"
                label="Gross Weight"
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={1}>
              <RHFTextField name="cylinder" label="Cylinder" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={1}>
              <RHFCheckbox name="activeinworkshop" label="Active In Workshop" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={1}>
              <RHFCheckbox name="activeinsales" label="Active In Sales" size="small" />
            </Grid>
          </Grid>
        </DialogContent>

        <Stack
          direction="row-reverse"
          alignItems="center"
          sx={{
            bottom: 0,
            zIndex: 111111,
            p: 3,
            position: 'fixed',
            justifyContent: 'space-between',
            width: '100%',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Tooltip title="(Ctrl + A)" arrow>
            <LoadingButton
              variant="contained"
              loading={loading}
              id="add-voucher"
              onClick={async () => {
                if (await trigger()) {
                  onSubmit(allValues);
                }
              }}
            >
              {vehicalObj.vehicleNo !== 0 && allValues.id ? 'Update' : 'Add'}
            </LoadingButton>
          </Tooltip>

          <Tooltip title="(Esc)" arrow>
            <Button variant="outlined" sx={{ mr: '10px' }} onClick={onClose}>
              Cancel
            </Button>
          </Tooltip>
          <Grid flexGrow={1} spacing={2} />
        </Stack>
      </FormProvider>
      {fieldName && (
        <GlobalQuickForm
          row={editrow}
          open={quickEditStatus.value}
          onClose={quickEditStatus.onFalse}
          modeldata={allfiledmodel[fieldName]}
        />
      )}

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => editrow?.id && deleteAccountModel(editrow?.id)}
          >
            Delete
          </Button>
        }
      />
    </Dialog>
  );
}

VehicleQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
