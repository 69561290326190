import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
//
import UserQuickEditForm from './quick-edit-form';
import JobcardRedyQuickEditForm from './jobcard-redy-form';

// ----------------------------------------------------------------------

export default function UserTableRow({
  tableData,
  row,
  index,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  table,
  getAll,
  jbcstatuses,
  serviceTypesData,
  servicedealtrData,
  finalIList,
  supervisorList,
  rempList,
  mechanicList,
  colourList,
  zoneDataList,
  yearData,
  sourcetypes,
  fuellevels,
  complaintCode
}) {
  const { identity, cname, account, mobile1, regNo, chassisNo, enginNo, status } = row;

  const confirm = useBoolean();
  const quickJBEdit = useBoolean();
  const quickEdit = useBoolean();

  const popover = usePopover();

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell>{identity}</TableCell>
        <TableCell>{cname}</TableCell>
        <TableCell>{mobile1}</TableCell>
        <TableCell>{regNo}</TableCell>
        <TableCell>{chassisNo}</TableCell>
        <TableCell>{enginNo}</TableCell>

        <TableCell>
          <Label variant="soft" color={status ? 'success' : 'error'}>
            {status ? 'Active' : 'DeActive'}
          </Label>
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Jobcard Redy" placement="top" arrow>
            <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={quickJBEdit.onTrue}>
              <Iconify icon="fluent-emoji-flat:mechanic-light" width="30" height="30" style={{ fontSize: '30px' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Quick Edit" placement="top" arrow>
            <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={quickEdit.onTrue}>
              <Iconify icon="solar:pen-bold" width="25" height="25" style={{ fontSize: '25px' }} />
            </IconButton>
          </Tooltip>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      {quickEdit.value && (
        <UserQuickEditForm
          tableData={tableData}
          row={row}
          open={quickEdit.value}
          onClose={quickEdit.onFalse}
          table={table}
          getAll={getAll}
          serviceTypesData={serviceTypesData}
          servicedealtrData={servicedealtrData}
          colourList={colourList}
          zoneDataList={zoneDataList}
          yearData={yearData}
          sourcetypes={sourcetypes}
          fuellevels={fuellevels}
          complaintCode={complaintCode}
          jbcstatuses={jbcstatuses}
          mechanicList={mechanicList}
          finalIList={finalIList}
          supervisorList={supervisorList}
          rempList={rempList}
        />
      )}
      {quickJBEdit.value && (
        <JobcardRedyQuickEditForm
          colourList={colourList}
          zoneDataList={zoneDataList}
          yearData={yearData}
          sourcetypes={sourcetypes}
          fuellevels={fuellevels}
          complaintCode={complaintCode}
          serviceTypesData={serviceTypesData}
          jbcstatuses={jbcstatuses}
          mechanicList={mechanicList}
          finalIList={finalIList}
          supervisorList={supervisorList}
          rempList={rempList}
          row={row}
          open={quickJBEdit.value}
          onClose={quickJBEdit.onFalse}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>

        <MenuItem
          onClick={() => {
            quickEdit.onTrue();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  index: PropTypes.number,
  selected: PropTypes.bool,
  table: PropTypes.object,
  getAll: PropTypes.func,
  tableData: PropTypes.array,
  jbcstatuses: PropTypes.array,
  serviceTypesData: PropTypes.array,
  servicedealtrData: PropTypes.array,
  supervisorList: PropTypes.array,
  mechanicList: PropTypes.array,
  complaintCode: PropTypes.array,
  colourList: PropTypes.array,
  zoneDataList: PropTypes.array,
  yearData: PropTypes.array,
  sourcetypes: PropTypes.array,
  fuellevels: PropTypes.array,
  rempList: PropTypes.array,
  finalIList: PropTypes.array,
};
