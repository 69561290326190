import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { usePathname } from 'src/routes/hooks';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { KEY } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Paper,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import { Stack } from '@mui/system';
import { DateField } from '@mui/x-date-pickers';
import {
  assignNullToEmptyOrUndefinedValues,
  calculateNetAmount,
  convertToRound,
  nearestDateList,
  onScollerAutoComplate,
  truncateToTwoDecimals,
  useBgColorAutocomplete,
  checkPropertiesTypeIsNumber
} from 'src/_mock/constant_funcation';
import { AuthContext } from 'src/auth/context/jwt';
import { GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { useDebounce } from 'src/hooks/use-debounce';

export default function ProductCategoryQuickEditForm({
  row,
  open,
  onClose,
  table,
  getAll,
  tableData,
}) {
  const pathname = usePathname();

  useEffect(() => {
    if (pathname.includes('labour')) {
      setChangeLabel('labour')
    } else
      setChangeLabel('parts')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [yearData, setYearData] = useState([]);
  const [issueDataList, setIssueDataList] = useState([]);

  const [changeLabel, setChangeLabel] = useState("Parts");

  const { user } = useContext(AuthContext);

  const bgColorAutocomplete = useBgColorAutocomplete();
  const tableBodyRef = useRef(null);

  const IssuesModuleSchema = Yup.object().shape({
    createdAt: Yup.date().required('challan date is required'),
    issue_details: Yup.array().of(
      Yup.object().shape({
        productId: Yup.number().required('productId is required'),
        mrp: Yup.number().required('MRP is required'),
        rate: Yup.number().required('Net Rate is required'),
        gstamt: Yup.number().required('GST Amt is required'),
        amount: Yup.number().required('Net Amt is required'),
      })
    ),
  });

  const cyearId = user?.co_year?.id;

  const values = useMemo(
    () => ({
      productMeta: {
        page: 1,
        name: '',
        totalPage: 1,
        editRow: null,
      },
      islabour: pathname.includes('labour'),
      yearId: row?.yearId || cyearId,
      jobcardId: row?.jobcardId || null,
      identity: row?.identity || null,
      createdAt: row?.jobcard?.createdAt || new Date(),
      chassisNo: row?.jobcard?.chassisNo || '',
      regNo: row?.jobcard?.regNo || '',
      enginNo: row?.jobcard?.enginNo || '',
      productId: row?.jobcard?.productId || '',
      product: row?.jobcard?.product || '',
      servicetype: row?.jobcard?.servicetype || '',
      servicetypeName: row?.jobcard?.servicetype.name || '',
      productName: row?.jobcard?.product.name || '',
      colourName: row?.jobcard?.acolour?.name || '',
      servicetypeId: row?.jobcard?.servicetypeId || '',
      currentkm: row?.jobcard?.currentkm || '',
      deliverykm: row?.jobcard?.deliverykm || '',
      colourId: row?.jobcard?.acolourId || '',
      acolour: row?.jobcard?.acolour || '',
      issue_details:
        row?.issue_details?.map((xdata) => ({
          id: xdata?.id || null,
          productId: xdata?.productId || null,
          issueId: xdata?.issueId || null,
          product: xdata?.product || '',
          productName: xdata?.product?.name || '',
          qty: xdata?.qty || '',
          mrp: xdata.mrp || null,
          rate: xdata.rate || null,
          gstamt: xdata.gstamt || null,
          discount: xdata?.discount || null,
          amount: xdata?.amount || null,
          productList: xdata?.product ? [xdata?.product] : []
        })) ||
        [...Array(1)].map(() => ({
          id: null,
          productId: null,
          issueId: null,
          product: null,
          productName: null,
          mrp: null,
          qty: '',
          discount: null,
          rate: null,
          gstamt: null,
          amount: null,
          productList: []
        })),

      subtotal: row?.subtotal || 0,
      billamount: row?.billamount || 0,
    }),
    [row, cyearId, pathname]
  );


  const methods = useForm({
    resolver: yupResolver(IssuesModuleSchema),
    values,
    mode: 'onChange',
  });

  const {
    reset,
    watch,
    control,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const productDataList = useCallback(async (index, query) => {
    try {
      let URL = 'automobile/products/';
      URL += `?page=${query.page}&limit=${10}&status=true&`;
      if (!pathname.includes('labour')) {
        URL += `category=4,5,7,8,9&`
      } else {
        URL += `category=6&`
      }
      if (query.name) {
        URL += `&name=${query.name}`
      }
      const { data } = await fetcher(URL);
      if (data) {
        if (typeof index === "number" && index >= 0) {
          setValue(`issue_details[${index}].productList`, data.rows);
        } else {
          // allValues.issue_details.forEach((x, yindex) => {
          //   if (x.productList.length) {
          //     setValue(`issue_details[${yindex}].productList`, data.rows)
          //   }
          // })
          // onScollerAutoComplate({
          //   data,
          //   fieldName: 'productMeta',
          //   meta: allValues.productMeta,
          //   setList: setIssueDataList,
          //   setValue,
          // });
        }
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  const issue_details = useFieldArray({
    control,
    name: 'issue_details',
  });
  const issue_details_live = useWatch({ control, name: "issue_details" });

  const handleAdd = useCallback(() => {
    issue_details?.append({
      id: null,
      productId: null,
      product: null,
      productName: null,
      name: '',
      issueDetail: null,
      mrp: null,
      discount: null,
      rate: null,
      gstamt: null,
      amount: null,
      productList: []
    });
    // Scroll to the bottom of the table body
    setTimeout(() => {
      tableBodyRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, 100);
  }, [issue_details]);

  const handleRemoveStockDetail = useCallback(
    (index) => {
      issue_details.remove(index);
    },
    [issue_details]
  );

  const YearList = useCallback(async () => {
    try {
      let URL = 'co_years';
      URL += `?page=${1}&limit=${10000}&status=true&companyId=${user?.companyId}&`;
      const { data } = await fetcher(URL);
      if (data) {
        setYearData(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, [user?.companyId]);

  useEffect(() => {

    //   allValues?.issue_details?.map((x) => {
    //     setValue(`issue_details[${x}]`, x);
    //  });

    // allValues?.issue_details?.map((x) => x?.amount).reduce((a, b) => a + b, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const JobCardDataList = useCallback(
    async (jobCardNumber) => {
      try {
        let URL = `automobile/jobcards/${jobCardNumber}`;
        URL += `?page=${1}&limit=${10000}&status=true&`;
        const { data } = await fetcher(URL);

        if (data) {
          reset({
            ...allValues,
            ...allValues?.issue_details,
            identity: data?.identity,
            createdAt: data?.createdAt,
            jobcardId: data?.id,
            chassisNo: data?.chassisNo || '',
            regNo: data?.regNo || '',
            enginNo: data?.enginNo || '',
            productId: data?.productId || '',
            product: data?.product || '',
            servicetype: data?.servicetype || '',
            servicetypeId: data?.servicetypeId || '',
            servicetypeName: data?.servicetype.name || '',
            productName: data?.product.name || '',
            colourName: data?.acolour?.name || '',
            currentkm: data?.currentkm || '',
            deliverykm: data?.deliverykm || '',
            colourId: data?.acolourId || '',
            acolour: data?.acolour || '',
          });
        } else {
          enqueueSnackbar('Jobcard Not Found', {
            variant: 'info',
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reset]
  );

  const jobCardNumber = useDebounce(allValues?.identity);

  useEffect(() => {
    if (jobCardNumber) JobCardDataList(jobCardNumber);
  }, [jobCardNumber, JobCardDataList]);



  useEffect(() => {
    if (allValues.issue_details.filter(x => !x.productId).length === 0) {
      [...Array(1)].forEach(() => {
        handleAdd();
      })
    }
    console.log("call productdata list")

    productDataList(null, {
      page: 1,
      name: '',
      totalPage: 1,
      category: 'parts',
      editRow: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, productDataList, changeLabel]);

  const onSubmit = handleSubmit(async (payload) => {
    payload.issue_details = payload.issue_details.filter((x) => x.avariantId !== null);
    payload = assignNullToEmptyOrUndefinedValues(payload);

    payload = {
      ...payload,
      billamount: convertToRound(payload.billamount),
    };

    setLoading(true);
    try {
      let URL = '/automobile/issues/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      onClose();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      setLoading(false);
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
    YearList();
  }, [YearList, open, reset]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  const prevDetailsRef = useRef();
  const calculateTotals = useCallback(() => {
    let newSubtotal = 0;
    let newBillAmount = 0;

    const propertiesToCheck = ['qty', 'rate', 'amount'];
    issue_details_live?.filter(x => checkPropertiesTypeIsNumber(x, propertiesToCheck)).forEach((item, index) => {
      const itemTotal = item.qty * item.rate;
      const amountTotal = item.amount;
      newSubtotal += itemTotal;
      newBillAmount += amountTotal; // Assuming bill amount is just the subtotal for now
      const netRate = item.qty * item.mrp;
      const gstAmt = netRate * item.IGST; // assuming 18% GST
      const netAmt = netRate + gstAmt;
      const qty = parseInt(item.qty, 10);
      const amount = qty * truncateToTwoDecimals(item.product?.sal_rate);
      const mrp = truncateToTwoDecimals(qty * (truncateToTwoDecimals(calculateNetAmount(
        item.product?.sal_rate,
        nearestDateList(item.product?.gst_commodity?.gst_com_details).gst_rate
          ?.IGST
      ))));
      const rate = truncateToTwoDecimals(calculateNetAmount(
        item.product?.sal_rate,
        nearestDateList(item.product?.gst_commodity?.gst_com_details).gst_rate
          ?.IGST
      ));
      const gstamt = truncateToTwoDecimals(qty * (truncateToTwoDecimals(item.product?.sal_rate) -
        (calculateNetAmount(
          item.product?.sal_rate,
          nearestDateList(item.product?.gst_commodity?.gst_com_details)
            .gst_rate?.IGST
        ) || 0)));
      // Include any other calculations for discounts, taxes etc.
      if (prevDetailsRef.current?.[index]?.mrp !== mrp)
        setValue(`issue_details[${index}].mrp`, mrp);
      if (prevDetailsRef.current?.[index]?.rate !== rate)
        setValue(`issue_details[${index}].rate`, rate);
      if (prevDetailsRef.current?.[index]?.gstamt !== gstamt)
        setValue(`issue_details[${index}].gstamt`, gstamt);
      if (prevDetailsRef.current?.[index]?.amount !== amount)
        setValue(`issue_details[${index}].amount`, amount);
      if (prevDetailsRef.current?.[index]?.rate !== rate)
        setValue(`issue_details[${index}].rate`, rate);

    });
    setValue('subtotal', truncateToTwoDecimals(newSubtotal));
    setValue('billamount', truncateToTwoDecimals(newBillAmount));

    prevDetailsRef.current = issue_details_live;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issue_details_live, setValue]);

  useEffect(() => {
    calculateTotals();
  }, [calculateTotals, issue_details_live]);


  // useEffect(() => {
  //   setValue('subtotal', issue_details.fields.reduce((acc, next) => acc + (next.mrp || 0)), 0)
  //   setValue('gstamt', issue_details.fields.reduce((acc, next) => acc + (next.gstamt || 0)), 0)
  //   setValue('billamount', issue_details.fields.reduce((acc, next) => acc + (next.amount || 0)), 0)
  // }, [issue_details, setValue])

  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog fullScreen open={open} onKeyDown={handleKeyDown}>
      <FormProvider methods={methods}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? `Update ${changeLabel} Issue` : `Add ${changeLabel} Issue`}
              </Typography>

              <IconButton color="inherit" edge="start" onClick={onClose} tabIndex="-1">
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          <DialogContent sx={{ p: 2 }}>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={8}>
              <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} spacing={2}>
                <Grid item xs={12} sm={12} md={4.5} lg={4.5} xl={4.5} xxl={4.5}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    size="small"
                    options={yearData.length ? yearData : [{ name: 'No Options', value: null }]}
                    groupBy={(option) => option?.a}
                    getOptionLabel={(option) => {
                      const startDate = new Date(option.sdate);
                      const endDate = new Date(option.edate);
                      return `${startDate.getFullYear()}-${endDate.getFullYear()}`;
                    }}
                    openOnFocus
                    onChange={(event, newValue) => {
                      setValue('yearId', newValue?.id);
                    }}
                    value={(() => {
                      const addRecord = yearData?.find((data) => data.id === allValues?.yearId);
                      const editRecord = row?.godown;

                      if (addRecord) return addRecord;
                      if (editRecord) return editRecord;
                      return null;
                    })()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Year"
                        name="yearId"
                        error={errors?.yearId && !allValues?.yearId}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                          {`${new Date(option.sdate).getFullYear()}-${new Date(
                            option.edate
                          ).getFullYear()}`}
                        </ListItem>
                      </Box>
                    )}
                    ListboxProps={{
                      ...bgColorAutocomplete,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5} xxl={3.5}>
                  <RHFTextField
                    name="identity"
                    label="Jobcard No."
                    size="small"
                    autoFocus
                    disabled={row?.id}
                    error={errors?.identity && !allValues?.identity}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <Controller
                    name="createdAt"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <DateField
                        defaultValue={new Date(field.value)}
                        timezone="Asia/Kolkata"
                        label="Jobcard Date"
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        error={errors?.challandate && !allValues?.challandate}
                        format="dd/MM/yyyy"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                            size: 'small',
                          },
                        }}
                        disabled
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField
                    name="chassisNo"
                    label="Chassis No."
                    size="small"
                    value={allValues?.chassisNo || ''}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField
                    name="regNo"
                    label="Reg. No."
                    size="small"
                    value={allValues?.regNo || ''}
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} spacing={2}>
                <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="enginNo" label="Enginee No." size="small" disabled />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="couponNo" label="Coupon No." size="small" disabled />
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="productName" label="Model" size="small" disabled />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField
                      name="servicetypeName"
                      label="Service Type"
                      size="small"
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} spacing={2}>
                <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="currentkm" label="Km/Hrs" size="small" disabled />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="st_dt" label="Sl.Dt." size="small" disabled />
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="deliverykm" label="Dl.Km/Hrs" size="small" disabled />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="colourName" label="Colour" size="small" disabled />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12} md={12} lg={12} xl={12} xxl={12} sx={{ mt: 1 }}>
              <TableContainer
                component={Paper}
                sx={{
                  overflow: 'auto',
                  width: '100%',
                  margin: 'auto',
                }}
              >
                <Table
                  stickyHeader
                  aria-label="simple table"
                  sx={{
                    '& .MuiTableCell-sizeMedium': {
                      padding: '0px !important',
                    },
                    '& fieldset': { borderRadius: '0px', border: 'none' },
                    '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                    '& .MuiOutlinedInput-root': { padding: '0px !important' },
                    '& .autoComplete>div': { padding: '0px !important' },
                    '& .MuiTableCell-root': {
                      border: '0.01px solid #e9ecee',
                    },
                    '& .MuiTableCell-head': {
                      padding: '0px !important',
                      borderRight: '1px solid #d0d1d2 !important',
                    },
                    '& .MuiTableCell-footer': {
                      padding: '0px !important',
                    },
                    '& .MuiTableCell-head:last-child': {
                      borderRight: '0px !important',
                    },
                    '&:last-child td': {
                      borderColor: '#e9ecee !important',
                    },
                  }}
                >
                  <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
                    <TableRow>
                      <TableCell sx={{ width: 20, textAlign: 'center' }}>sr.</TableCell>
                      <TableCell sx={{ width: 200, textAlign: 'center' }}>
                        {changeLabel} No.
                      </TableCell>
                      <TableCell sx={{ width: 300, textAlign: 'center' }}>
                        {changeLabel} Description
                      </TableCell>
                      <TableCell sx={{ width: 60, textAlign: 'center' }}>Qty</TableCell>
                      <TableCell sx={{ width: 140, textAlign: 'center' }}>MRP</TableCell>
                      <TableCell sx={{ width: 80, textAlign: 'center' }}>Net Rate</TableCell>
                      <TableCell sx={{ width: 80, textAlign: 'center' }}>GST Amt</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'center' }}>Net Amt</TableCell>
                      <TableCell
                        sx={{ width: 10, textAlign: 'center', position: 'sticky', right: 0 }}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {issue_details?.fields.map((xdata, index) => (
                      <TableRow
                        key={index + 1212}
                        sx={{
                          textAlign: 'center',
                          position: 'sticky',
                          left: 0,
                        }}
                      >
                        <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                        <TableCell>
                          <Autocomplete
                            autoHighlight
                            fullWidth
                            size="small"
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            options={
                              allValues.issue_details[index].productList?.length
                                ? allValues.issue_details[index].productList
                                : [{ name: 'No Options', value: null, id: null, partno: "" }]
                            }
                            groupBy={(option) => option?.pcategory?.name}
                            getOptionLabel={(option) => option.partno}
                            openOnFocus
                            onChange={(event, newValue) => {

                              // setValue(`issue_details[${index}]`, {
                              //   ...allValues.issue_details[index],
                              //   productId: newValue?.id,
                              //   productName: newValue?.name,
                              //   product: newValue,
                              //   qty: 1,
                              //   mrp: calculateNetAmount(
                              //     newValue?.sal_rate,
                              //     nearestDateList(newValue?.gst_commodity?.gst_com_details).gst_rate
                              //       ?.IGST
                              //   ),
                              //   rate: calculateNetAmount(
                              //     newValue?.sal_rate,
                              //     nearestDateList(newValue?.gst_commodity?.gst_com_details).gst_rate
                              //       ?.IGST
                              //   ),
                              //   gstamt:
                              //     (newValue?.sal_rate || 0) -
                              //     (calculateNetAmount(
                              //       newValue?.sal_rate,
                              //       nearestDateList(newValue?.gst_commodity?.gst_com_details)
                              //         .gst_rate?.IGST
                              //     ) || 0),
                              //   amount: newValue?.sal_rate,
                              // })
                              setValue(`issue_details[${index}].productId`, newValue?.id);
                              setValue(`issue_details[${index}].productName`, newValue?.name);
                              setValue(`issue_details[${index}].product`, newValue);
                              // setValue(`issue_details[${index}].qty`, 1);
                              // setValue(`issue_details[${index}].mrp`, newValue?.sal_rate);
                              // setValue(`issue_details[${index}].rate`, calculateNetAmount(
                              //   newValue?.sal_rate,
                              //   nearestDateList(newValue?.gst_commodity?.gst_com_details).gst_rate
                              //     ?.IGST
                              // ));
                              // setValue(`issue_details[${index}].IGST`, nearestDateList(newValue?.gst_commodity?.gst_com_details).gst_rate
                              //   ?.IGST);
                              // setValue(`issue_details[${index}].CGST`, nearestDateList(newValue?.gst_commodity?.gst_com_details).gst_rate
                              //   ?.CGST);
                              // setValue(`issue_details[${index}].SGST`, nearestDateList(newValue?.gst_commodity?.gst_com_details).gst_rate
                              //   ?.SGST);
                              // setValue(`issue_details[${index}].gstamt`, truncateToTwoDecimals((newValue?.sal_rate || 0) -
                              //   (calculateNetAmount(
                              //     newValue?.sal_rate,
                              //     nearestDateList(newValue?.gst_commodity?.gst_com_details)
                              //       .gst_rate?.IGST
                              //   ) || 0)));
                              // setValue(`issue_details[${index}].amount`, (newValue?.sal_rate || 0));
                              issue_details.update(index, {
                                ...allValues.issue_details[index],
                                productId: newValue?.id,
                                productName: newValue?.name || "",
                                product: newValue,
                                qty: 1,
                                mrp: newValue?.sal_rate,
                                rate: truncateToTwoDecimals(calculateNetAmount(
                                  newValue?.sal_rate,
                                  nearestDateList(newValue?.gst_commodity?.gst_com_details).gst_rate
                                    ?.IGST
                                )),
                                gstamt:
                                  truncateToTwoDecimals((newValue?.sal_rate || 0) -
                                    (calculateNetAmount(
                                      newValue?.sal_rate,
                                      nearestDateList(newValue?.gst_commodity?.gst_com_details)
                                        .gst_rate?.IGST
                                    ) || 0)),
                                IGST: nearestDateList(newValue?.gst_commodity?.gst_com_details).gst_rate
                                  ?.IGST,
                                SGST: nearestDateList(newValue?.gst_commodity?.gst_com_details).gst_rate
                                  ?.SGST,
                                CGST: nearestDateList(newValue?.gst_commodity?.gst_com_details).gst_rate
                                  ?.CGST,
                                amount: newValue?.sal_rate,
                              });

                            }}
                            value={allValues?.issue_details[index]?.product}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name={`issue_details[${index}].productId`}
                              />
                            )}
                            onInputChange={(event, newInputValue) => {
                              productDataList(index, {
                                page: 1,
                                name: newInputValue || '',
                                totalPage: 1,
                                category: 'parts',
                                editRow: null,
                              })
                            }}
                            renderGroup={(params) => (
                              <li key={params.key}>
                                <GroupHeader component="li">
                                  <ListItem
                                    sx={{
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    Part No
                                  </ListItem>
                                  <ListItem
                                    sx={{
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    Part Name
                                  </ListItem>
                                </GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                              </li>
                            )}
                            componentsProps={{
                              paper: {
                                sx: {
                                  width: 600,
                                },
                              },
                            }}
                            renderOption={(props, option) => (
                              <Box component="li" {...props} key={option.id} sx={{ minHeight: 'auto !important' }}>
                                <ListItem sx={{ padding: 0, margin: 0 }}>
                                  {option?.partno}
                                </ListItem>
                                <ListItem sx={{ padding: 0, margin: 0 }}>
                                  {option?.name}
                                </ListItem>
                              </Box>
                            )}
                            ListboxProps={{
                              ...bgColorAutocomplete,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <RHFTextField
                            name={`issue_details[${index}].productName`}
                            size="small"
                            fullWidth
                            variant="outlined"
                            disabled
                          />
                        </TableCell>
                        <TableCell>
                          <RHFTextField
                            name={`issue_details.${index}.qty`}
                            size="small"
                            fullWidth
                            variant="outlined"
                            type="number"
                            value={allValues.issue_details[index].qty}
                            inputProps={{
                              style: { textAlign: 'right' },
                            }}
                          />
                        </TableCell>

                        <TableCell>
                          <RHFTextField
                            name={`issue_details[${index}].mrp`}
                            size="small"
                            fullWidth
                            value={
                              allValues.issue_details[index].mrp
                              || ''
                            }
                            variant="outlined"
                            type="number"
                            inputProps={{
                              style: { textAlign: 'right' },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <RHFTextField
                            name={`issue_details[${index}].rate`}
                            size="small"
                            fullWidth
                            variant="outlined"
                            value={
                              allValues.issue_details[index].rate
                              || ''
                            }
                            inputProps={{
                              style: { textAlign: 'right' },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <RHFTextField
                            name={`issue_details[${index}].gstamt`}
                            size="small"
                            fullWidth
                            value={
                              allValues.issue_details[index].gstamt
                              || ''
                            }
                            variant="outlined"
                            inputProps={{
                              style: { textAlign: 'right' },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <RHFTextField
                            name={`issue_details[${index}].amount`}
                            size="small"
                            fullWidth
                            variant="outlined"
                            value={
                              allValues.issue_details[index].amount
                                ? Math.round(allValues.issue_details[index].amount)?.toFixed(2)
                                : ''
                            }
                            inputProps={{
                              style: { textAlign: 'right' },
                            }}
                          />
                        </TableCell>

                        {index !== 0 ? (
                          <TableCell
                            sx={{
                              textAlign: 'center',
                              position: 'sticky',
                              right: 0,
                              index: 9,
                              cursor: 'pointer',
                            }}
                            onClick={() => handleRemoveStockDetail(index)}
                          >
                            <Iconify icon="solar:trash-bin-trash-bold" color="red" />
                          </TableCell>
                        ) : (
                          <TableCell
                            sx={{
                              textAlign: 'center',
                              position: 'sticky',
                              right: 0,
                              index: 9,
                            }}
                          />
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid
              item
              container
              xs={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              sx={{ mt: 1, alignItems: 'end', bottom: 60, position: 'absolute' }}
            >
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} />
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} px={6}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ backgroundColor: '#F3F6F8', padding: '5px' }}
                >
                  <Typography variant="subtitle1">Sub Total</Typography>
                  <Box sx={{ textAlign: 'right' }}>
                    <Typography variant="subtitle1" sx={{ color: 'error.main' }}>
                      {methods.getValues('subtotal')}
                    </Typography>
                  </Box>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ backgroundColor: '#F3F6F8', padding: '5px' }}
                >
                  <Typography variant="subtitle1">Bill Amount</Typography>
                  <Box sx={{ textAlign: 'right' }}>
                    <Typography variant="subtitle1" sx={{ color: 'error.main' }}>
                      {methods.getValues('billamount')}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
        </Grid>

        <Stack
          direction="row-reverse"
          alignItems="center"
          sx={{
            bottom: 0,
            zIndex: 111111,
            p: 2,
            position: 'fixed',
            justifyContent: 'space-between',
            width: '100%',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
          id="modelfooter"
        >
          <Tooltip title="(Alt + A)" arrow>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={async () => {
                const propertiesToCheck = ['qty', 'rate', 'amount'];
                setValue('issue_details', allValues.issue_details?.filter(x => checkPropertiesTypeIsNumber(x, propertiesToCheck)));
                if (await trigger()) {
                  onSubmit();
                }
              }}
            >
              {row ? 'Update' : 'Add'}
            </LoadingButton>
          </Tooltip>

          <Tooltip title="(Esc)" arrow>
            <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
          </Tooltip>

          <Button
            variant="soft"
            onClick={() => {
              reset(values);
            }}
            sx={{ mr: 2 }}
          >
            Reset
          </Button>

          <Stack direction="row" alignItems="center" flexGrow={1} spacing={2}>
            <Tooltip title="Add" arrow>
              <IconButton color="primary" onClick={handleAdd}>
                <Iconify icon="mingcute:add-line" />
              </IconButton>
            </Tooltip>
            {/* <Button
              variant="outlined"
              onClick={() => {
                setChangeLabel((pre) => pre === "Parts" ? "Labour" : "Parts");
              }}
              sx={{ mr: 1 }}
            >
              <Iconify icon="ri:reset-left-fill" /> &nbsp; {changeLabel === "Parts" ? "Labour" : "Parts"} Issue
            </Button> */}
          </Stack>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}

ProductCategoryQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
