import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { GENDER, KEY, PURCHASE_TYPE } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Paper,
  TableFooter,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';

import { useEventListener } from 'src/hooks/use-event-listener';
import { Stack } from '@mui/system';
import {
  assignNullToEmptyOrUndefinedValues,
  useBgColorAutocomplete,
} from 'src/_mock/constant_funcation';
import { GroupHeader, GroupItems } from 'src/_mock/constant_style';
import Iconify from 'src/components/iconify';

export default function VehicleOpeningStock({
  row,
  open,
  onClose,
  table,
  getAll,
  tableData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [productVariantData, setProductVariantData] = useState([]);
  const [colourData, setColourData] = useState([]);
  const [stockDetailsData, setStockDetailsData] = useState([]);
  const bgColorAutocomplete = useBgColorAutocomplete();
  const tableBodyRef = useRef(null);
  const schema = Yup.object().shape({
    stockdetails: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().nullable(),
        pname: Yup.string().nullable(),
        productId: Yup.string().required('Product is required'),
        rate: Yup.string().required('Rate is required'),
        chassisNo: Yup.string().required('Chassis number is required'),
        enginNo: Yup.string().required('Engine number is required'),
        acolourId: Yup.string().required('Colour is required'),
        keyNo: Yup.string().nullable(),
        batteryNo: Yup.string().nullable(),
      })
    ),
  });
  const values = useMemo(
    () => ({
      stocks:
        row?.stocks?.map((data) => ({
          id: data?.id || null,
          productId: data?.productId || null,
          pname: data?.pname || null,
          qty: data?.qty || null,
          rate: data?.rate || null,
          amount: data?.amount || null,
          discount: data?.discount || null,
          gst: data?.gst || null,
          netAmount: data?.netAmount || null,
        })) || [],
      stockdetails:
        row?.stockdetails?.map((xdata) => ({
          id: xdata?.id || null,
          pname: xdata?.avariant?.name || null,
          productId: xdata?.productId || null,
          rate: xdata?.rate || null,
          chassisNo: xdata?.chassisNo || '',
          enginNo: xdata?.enginNo || '',
          acolourId: xdata?.acolourId || '',
          keyNo: xdata?.keyNo || '',
          batteryNo: xdata?.batteryNo || '',
        })) ||
        [...Array(5)].map(() => ({
          id: null,
          pname: null,
          productId: null,
          rate: null,
          chassisNo: '',
          enginNo: '',
          acolourId: '',
          keyNo: '',
          batteryNo: '',
        }))
    }),
    [row]
  );

  const methods = useForm({
    values,
    mode: 'onChange',
  });

  const {
    reset,
    watch,
    control,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const stocks = useFieldArray({
    control,
    name: 'stocks',
  });
  const stockdetails = useFieldArray({
    control,
    name: 'stockdetails',
  });

  const handleAdd = useCallback(() => {
    stockdetails?.append({
      id: null,
      pname: null,
      productId: null,
      rate: null,
      chassisNo: '',
      enginNo: '',
      acolourId: '',
      keyNo: '',
      batteryNo: '',
    });
    // Scroll to the bottom of the table body
    setTimeout(() => {
      tableBodyRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, 100);
  }, [stockdetails]);

  const handleRemoveStockDetail = useCallback(
    (index) => {
      stockdetails.remove(index);
    },
    [stockdetails]
  );

  const productVariantList = useCallback(async () => {
    try {
      let URL = 'automobile/products/';
      URL += `?page=${1}&limit=${10000}&status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setProductVariantData(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const groupByCode = allValues.stockdetails.reduce((acc, item) => {
      if (item.productId) {
        if (!acc[item.productId]) {
          acc[item.productId] = {
            id: null,
            productId: item.productId,
            pname: item.pname,
            qty: 0,
            totalrate: 0,
            rate: item.rate,
            amount: item.amount,
            discount: item.discount,
            gst: item.gst,
            netAmount: item.netAmount,
          };
        }
        acc[item.productId].qty += 1; // Increment the quantity for each occurrence
        acc[item.productId].totalrate += item.rate; // Increment the quantity for each occurrence
      }
      return acc;
    }, {});
    // Convert the grouped items into an array and update the stocks state
    const newStocks = Object.values(groupByCode).map((stock) => ({
      ...stock,
      rate: stock.totalrate / stock.qty,
      amount: (stock.totalrate / stock.qty) * stock.qty, // Calculate the total amount based on rate and quantity
      discount: allValues.stocks.find((x) => x.productId === stock.productId)?.discount || 0,
      gst: allValues.stocks.find((x) => x.productId === stock.productId)?.gst || 0,
      netAmount:
        (stock.totalrate / stock.qty) * stock.qty -
        (allValues.stocks.find((x) => x.productId === stock.productId)?.discount || 0) +
        (stock.gst || 0),
    }));

    if (JSON.stringify(newStocks) !== JSON.stringify(allValues.stocks)) {
      setValue('stocks', newStocks);
    }
  }, [allValues.stockdetails, allValues.stocks, setValue, stockdetails]);


  const productList = useCallback(
    async (code, index) => {
      const URL = `automobile/product/variants?id=${code}&page=1&limit=10000&status=true`;
      try {
        if (code === '') {
          setValue(`stockdetails[${index}]`, {
            ...allValues.stockdetails[index],
            pname: '',
            productId: null,
          });
          return;
        }

        const { data } = await fetcher(URL);
        if (data?.rows?.length) {
          const product = data.rows[0];
          if (product)
            setValue(`stockdetails[${index}]`, {
              ...allValues.stockdetails[index],
              pname: product.name,
              productId: product.id,
            });
        } else {
          setValue(`stockdetails[${index}]`, {
            ...allValues.stockdetails[index],
            pname: '',
            productId: null,
          });
          enqueueSnackbar('Product not Available!', { variant: 'info' });
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Failed to fetch product details!', { variant: 'error' });
      }
    },
    [setValue, allValues.stockdetails, enqueueSnackbar]
  );

  const onSubmit = handleSubmit(async (payload) => {
    payload.stockdetails.forEach((x, index) => {
      if (x.productId !== null || x.enginNo !== '' || x.chassisNo !== '') {
        console.log(index)
        handleRemoveStockDetail(index)
      }
    })
    payload.stockdetails = payload.stockdetails.filter((x) => x.productId || x.enginNo || x.chassisNo || x.rate);
    setValue('stockdetails', payload.stockdetails);
    payload = assignNullToEmptyOrUndefinedValues(payload);
    setLoading(true);
    try {
      const URL = '/automobile/stockdetails/';

      if (payload.stockdetails.length > 0) {
        const { success, show } = await create({ url: URL, payload: payload.stockdetails });
        if (success) {
          getAll();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload: payload.stockdetails });
        if (success) {
          getAll();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      onClose();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      setLoading(false);
      console.error(error);
    }
  });

  const colourDataList = useCallback(async () => {
    try {
      let URL = 'automobile/product/colours/';
      URL += `?page=${1}&limit=${10000}&status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setColourData(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  const stockdetailsList = useCallback(async () => {
    try {
      let URL = 'automobile/stockdetails/';
      URL += `?page=${1}&limit=${10000}&status=true&`;
      if (row.id) URL += `productId=${row.id}&entryId=null&`;

      const { data } = await fetcher(URL);
      if (data) {
        setStockDetailsData(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, [row?.id]);
  useEffect(() => {
    const stod = stockDetailsData.map(x => ({
      id: x.id,
      pname: x.product.name,
      productId: x.productId,
      rate: x.rate,
      chassisNo: x.chassisNo,
      enginNo: x.enginNo,
      acolourId: x.acolourId,
      keyNo: x.keyNo,
      batteryNo: x.batteryNo,
    }))
    if (stod.length)
      setValue('stockdetails', stod);
  }, [setValue, stockDetailsData])
  useEffect(() => {
    reset();
    productVariantList();
    colourDataList();
    stockdetailsList();
  }, [productVariantList, open, reset, colourDataList, stockdetailsList]);


  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      console.log('AMIDEEP 1');
      if ((await trigger()) && !loading && open) {
        console.log('AMIDEEP 2');
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog fullScreen open={open} onKeyDown={handleKeyDown}>
      <FormProvider methods={methods}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update Opening Stock' : 'Add Opening Stock'}
              </Typography>
              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          <DialogContent sx={{ p: 2 }}>
            <Grid container item xs={12} md={12} lg={12} xl={12} xxl={12} sx={{ mt: 1 }}>
              <TableContainer
                component={Paper}
                sx={{
                  overflow: 'auto',
                  maxHeight: '30vh',
                  minHeight: '30vh',
                  width: '100%',
                  margin: 'auto',
                }}
              >
                <Table
                  stickyHeader
                  aria-label="simple table"
                  sx={{
                    '& .MuiTableCell-sizeMedium': {
                      padding: '0px !important',
                    },
                    '& fieldset': { borderRadius: '0px', border: 'none' },
                    '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                    '& .MuiOutlinedInput-root': { padding: '0px !important' },
                    '& .autoComplete>div': { padding: '0px !important' },
                    '& .MuiTableCell-root': {
                      border: '0.01px solid #e9ecee',
                    },
                    '& .MuiTableCell-head': {
                      padding: '0px !important',
                      borderRight: '1px solid #d0d1d2 !important',
                    },
                    '& .MuiTableCell-footer': {
                      padding: '0px !important',
                    },
                    '& .MuiTableCell-head:last-child': {
                      borderRight: '0px !important',
                    },
                  }}
                >
                  <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
                    <TableRow>
                      <TableCell sx={{ width: 20, textAlign: 'center' }}>sr.</TableCell>
                      <TableCell sx={{ width: 40, textAlign: 'center' }}>Code</TableCell>
                      <TableCell sx={{ width: 400, textAlign: 'center' }}>Product Name</TableCell>
                      <TableCell sx={{ width: 80, textAlign: 'center' }}>Rate</TableCell>
                      <TableCell sx={{ width: 140, textAlign: 'center' }}>Chassis No.</TableCell>
                      <TableCell sx={{ width: 140, textAlign: 'center' }}>Engine No.</TableCell>
                      <TableCell sx={{ width: 140, textAlign: 'center' }}>Colour Name</TableCell>
                      <TableCell sx={{ width: 70, textAlign: 'center' }}>Key No.</TableCell>
                      <TableCell sx={{ width: 120, textAlign: 'center' }}>Bettry No.</TableCell>
                      <TableCell
                        sx={{ width: 10, textAlign: 'center', position: 'sticky', right: 0 }}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stockdetails.fields.map((xdata, index) => (
                      <TableRow
                        key={xdata.id}
                        sx={{
                          textAlign: 'center',
                          position: 'sticky',
                          left: 0,
                        }}
                      >
                        <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                        <TableCell sx={
                          index >= 0 &&
                            errors &&
                            errors?.stockdetails &&
                            errors?.stockdetails[index] &&
                            errors.stockdetails[index].productId
                            ? {
                              border: '0.01px solid #F90635 !important',
                              textAlign: 'end',
                            }
                            : { textAlign: 'end' }
                        }>
                          <RHFTextField
                            name={`stockdetails[${index}].productId`}
                            size="small"
                            fullWidth
                            variant="outlined"
                            type="number"
                            onChange={(e) => {
                              productList(e.target.value, index);
                            }}
                          />
                        </TableCell>
                        <TableCell >
                          <Autocomplete
                            autoHighlight
                            fullWidth
                            size="small"
                            options={
                              productVariantData?.length
                                ? productVariantData
                                : [{ name: 'No Options', value: null }]
                            }
                            groupBy={(option) => option?.a}
                            getOptionLabel={(option) => option.name}
                            openOnFocus
                            onChange={(event, newValue) => {
                              setValue(`stockdetails[${index}].productId`, newValue?.id);
                              setValue(`stockdetails[${index}].pname`, newValue?.name);
                            }}
                            value={(() => {
                              const addRecord = productVariantData?.find(
                                (data) => data.id === allValues?.stockdetails[index]?.productId
                              );
                              const editRecord = row?.ac_group;

                              if (addRecord) return addRecord;
                              if (editRecord) return editRecord;
                              return null;
                            })()}
                            renderInput={(params) => (
                              <TextField {...params} name={`stockdetails[${index}].pname`} />
                            )}
                            renderGroup={(params) => (
                              <li key={params.key}>
                                <GroupHeader component="li">
                                  <ListItem
                                    sx={{
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    Code
                                  </ListItem>
                                  <ListItem
                                    sx={{
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    Product Variant
                                  </ListItem>
                                </GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                              </li>
                            )}
                            componentsProps={{
                              paper: {
                                sx: {
                                  width: 600,
                                },
                              },
                            }}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                {...props}
                                sx={{ minHeight: 'auto !important' }}
                              >
                                <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                  {option?.id}
                                </ListItem>
                                <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                  {option?.name}
                                </ListItem>
                              </Box>
                            )}
                            ListboxProps={{
                              ...bgColorAutocomplete,
                            }}
                          />
                        </TableCell>
                        <TableCell sx={
                          index >= 0 &&
                            errors &&
                            errors?.stockdetails &&
                            errors?.stockdetails[index] &&
                            errors.stockdetails[index].rate
                            ? {
                              border: '0.01px solid #F90635 !important',
                              textAlign: 'end',
                            }
                            : { textAlign: 'end' }
                        }>
                          <RHFTextField
                            name={`stockdetails[${index}].rate`}
                            size="small"
                            fullWidth
                            variant="outlined"
                            type="number"
                          />
                        </TableCell>
                        <TableCell sx={
                          index >= 0 &&
                            errors &&
                            errors?.stockdetails &&
                            errors?.stockdetails[index] &&
                            errors.stockdetails[index].chassisNo
                            ? {
                              border: '0.01px solid #F90635 !important',
                              textAlign: 'end',
                            }
                            : { textAlign: 'end' }
                        }>
                          <RHFTextField
                            name={`stockdetails[${index}].chassisNo`}
                            size="small"
                            fullWidth
                            variant="outlined"
                            error={!!errors.stockdetails?.[index]?.chassisNo}
                            helperText={errors.stockdetails?.[index]?.chassisNo?.message}
                          />
                        </TableCell>

                        <TableCell sx={
                          index >= 0 &&
                            errors &&
                            errors?.stockdetails &&
                            errors?.stockdetails[index] &&
                            errors.stockdetails[index].enginNo
                            ? {
                              border: '0.01px solid #F90635 !important',
                              textAlign: 'end',
                            }
                            : { textAlign: 'end' }
                        }>
                          <RHFTextField
                            name={`stockdetails[${index}].enginNo`}
                            size="small"
                            fullWidth
                            variant="outlined"
                            error={!!errors.stockdetails?.[index]?.enginNo}
                            helperText={errors.stockdetails?.[index]?.enginNo?.message}
                          />
                        </TableCell>
                        <TableCell sx={
                          index >= 0 &&
                            errors &&
                            errors?.stockdetails &&
                            errors?.stockdetails[index] &&
                            errors.stockdetails[index].acolourId
                            ? {
                              border: '0.01px solid #F90635 !important',
                              textAlign: 'end',
                            }
                            : { textAlign: 'end' }
                        }>
                          <Autocomplete
                            autoHighlight
                            fullWidth
                            size="small"
                            options={
                              colourData.length
                                ? colourData
                                : [{ name: 'No Options', value: null }]
                            }
                            groupBy={(option) => option?.a}
                            getOptionLabel={(option) => option.name}
                            openOnFocus
                            onChange={(event, newValue) => {
                              setValue(`stockdetails[${index}].acolourId`, newValue?.id);
                            }}
                            value={(() => {
                              const addRecord = colourData?.find(
                                (data) => data.id === allValues?.stockdetails[index]?.acolourId
                              );
                              const editRecord = row?.ac_group;

                              if (addRecord) return addRecord;
                              if (editRecord) return editRecord;
                              return null;
                            })()}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name={`stockdetails[${index}].acolourId`}
                                error={
                                  errors?.acolourId && !allValues?.stockdetails[index]?.acolourId
                                }
                              />
                            )}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                {...props}
                                sx={{ minHeight: 'auto !important' }}
                              >
                                <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                  {option?.id}
                                </ListItem>
                                <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                  {option?.name}
                                </ListItem>
                              </Box>
                            )}
                            renderGroup={(params) => (
                              <li key={params.key}>
                                <GroupHeader component="li">
                                  <ListItem
                                    sx={{
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    Code
                                  </ListItem>
                                  <ListItem
                                    sx={{
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    Colour Name
                                  </ListItem>
                                </GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                              </li>
                            )}
                            componentsProps={{
                              paper: {
                                sx: {
                                  width: 400,
                                },
                              },
                            }}
                            ListboxProps={{
                              ...bgColorAutocomplete,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <RHFTextField
                            name={`stockdetails[${index}].keyNo`}
                            size="small"
                            fullWidth
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell>
                          <RHFTextField
                            name={`stockdetails[${index}].batteryNo`}
                            size="small"
                            fullWidth
                            variant="outlined"
                          />
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: 'center',
                            position: 'sticky',
                            right: 0,
                            index: 9,
                            cursor: 'pointer',
                          }}
                          onClick={() => handleRemoveStockDetail(index)}
                        >
                          <Iconify icon="solar:trash-bin-trash-bold" color="red" />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item container xs={12} md={12} lg={12} xl={12} xxl={12} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                <TableContainer
                  component={Paper}
                  sx={{ overflow: 'auto', maxHeight: '30vh', width: '100%', margin: 'auto' }}
                >
                  <Table
                    stickyHeader
                    aria-label="simple table"
                    sx={{
                      '& .MuiTableCell-sizeMedium': {
                        padding: '0px !important',
                      },
                      '& fieldset': { borderRadius: '0px', border: 'none' },
                      '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                      '& .MuiOutlinedInput-root': { padding: '0px !important' },
                      '& .autoComplete>div': { padding: '0px !important' },
                      '& .MuiTableCell-root': {
                        border: '0.01px solid #e9ecee',
                      },
                      '& .MuiTableCell-head': {
                        padding: '0px !important',
                        borderRight: '1px solid #d0d1d2 !important',
                      },
                      '& .MuiTableCell-footer': {
                        padding: '0px !important',
                      },
                      '& .MuiTableCell-head:last-child': {
                        borderRight: '0px !important',
                      },

                    }}
                  >
                    <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
                      <TableRow>
                        <TableCell sx={{ width: 20, textAlign: 'center' }}>sr.</TableCell>
                        <TableCell sx={{ width: 40, textAlign: 'center' }}>Code</TableCell>
                        <TableCell sx={{ width: 400, textAlign: 'center' }}>
                          Product Name
                        </TableCell>
                        <TableCell sx={{ width: 80, textAlign: 'center' }}>Spcode</TableCell>
                        <TableCell sx={{ width: 140, textAlign: 'center' }}>Qty</TableCell>
                        <TableCell sx={{ width: 140, textAlign: 'center' }}>Rate</TableCell>
                        <TableCell sx={{ width: 140, textAlign: 'center' }}>Amount</TableCell>
                        <TableCell sx={{ width: 120, textAlign: 'center' }}>Disc. Amt</TableCell>
                        <TableCell sx={{ width: 120, textAlign: 'center' }}>GST(%)</TableCell>
                        <TableCell sx={{ width: 120, textAlign: 'center' }}>Net Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stocks?.fields.map((item, index) => (
                        <TableRow key={item.id}>
                          <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`stocks[${index}].productId`}
                              size="small"
                              fullWidth
                              variant="outlined"
                              type="number"
                              disabled
                            />
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`stocks[${index}].pname`}
                              size="small"
                              fullWidth
                              variant="outlined"
                              disabled
                            />
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`stocks[${index}].spcode`}
                              size="small"
                              fullWidth
                              variant="outlined"
                            />
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`stocks[${index}].qty`}
                              size="small"
                              fullWidth
                              variant="outlined"
                              type="number"
                              disabled
                            />
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`stocks[${index}].rate`}
                              size="small"
                              fullWidth
                              variant="outlined"
                              type="number"
                            />
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`stocks[${index}].amount`}
                              size="small"
                              fullWidth
                              variant="outlined"
                              type="number"
                            />
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`stocks[${index}].discount`}
                              size="small"
                              fullWidth
                              variant="outlined"
                              type="number"
                            />
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`stocks[${index}].gst`}
                              size="small"
                              fullWidth
                              variant="outlined"
                              type="number"
                            />
                          </TableCell>

                          <TableCell>
                            <RHFTextField
                              name={`stocks[${index}].netAmount`}
                              size="small"
                              fullWidth
                              variant="outlined"
                              type="number"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

            </Grid>
          </DialogContent>
        </Grid>

        <Stack
          direction="row-reverse"
          alignItems="center"
          sx={{
            bottom: 0,
            zIndex: 111111,
            p: 2,
            position: 'fixed',
            justifyContent: 'space-between',
            width: '100%',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
          id="modelfooter"
        >
          <Tooltip title="(Alt + A)" arrow>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={async () => {
                if (await trigger()) {
                  onSubmit();
                }
              }}
            >
              {row ? 'Update' : 'Add'}
            </LoadingButton>
          </Tooltip>

          <Tooltip title="(Esc)" arrow>
            <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
          </Tooltip>

          <Button
            variant="soft"
            onClick={() => {
              reset(values);
            }}
            sx={{ mr: 2 }}
          >
            Reset
          </Button>

          <Stack direction="row" alignItems="center" flexGrow={1} spacing={2}>
            <Tooltip title="Add Product" arrow>
              <IconButton color="primary" onClick={handleAdd}>
                <Iconify icon="mingcute:add-line" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}

VehicleOpeningStock.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
