import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers';
import {
  Autocomplete,
  Divider,
  IconButton,
  TextField,
  Toolbar,
  Typography,
  alpha,
} from '@mui/material';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFCheckbox,
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form';
import { create, update } from 'src/utils/axios';
import {
  AMC_SUBTYPE,
  ASSIGNEDTO,
  BAY,
  CANCELLATION_REASON,
  CITY,
  CUSTOMERCATEGORY,
  DSA_SELF,
  ENQUIRYCATEGORY,
  ENQUIRYSOURCE,
  ENQUIRYTYPE,
  EXCHANGE_AGENT_NAME,
  GENDER,
  INVOICE_FORMAT,
  JOB_CARD_STATUS,
  KEY,
  LABOUR_RATE_LIST,
  MODELCATEGORY,
  MODELNAME,
  MODELVARIANT,
  MODEL_NAME,
  MODEL_VARIANT,
  MODEL_VARIENT,
  MODE_OF_SALES,
  MODULE_STATUS,
  MTOCPRODUCT,
  MTOC_PRODUCT,
  OCCUPATION,
  ORDER_TYPE,
  PAYMENT_MODE,
  PENDING_REASON,
  PURCHASETYPE,
  SALES_CANCELLATION_REASON,
  SERVICE_AT,
  STATE,
  TITLES,
  TRUE_FALSE_DROPDOWN,
  VEHICLEMAKE,
  VEHICLEMODEL,
} from 'src/_mock/constant';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import { useBoolean } from 'src/hooks/use-boolean';
// ----------------------------------------------------------------------

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();

  const [exchangeField, setExchangeField] = useState('');
  const dialog = useBoolean();

  const departmentSchema = Yup.object().shape({
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string().required('Last Name is required'),
  });

  const values = useMemo(
    () => ({
      gender: row?.name || '',
      status: row?.status || false,
      description: row?.description || '',
      exchange: row?.exchange || 'false',
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(departmentSchema),
    values,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const allfields = watch();

  const onSubmit = handleSubmit(async (payload) => {
    try {
      let URL = 'roles/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };

  useEventListener('keydown', handleKeyDown);
  const allValues = watch();

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 1300 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update Service Product Order' : 'Create Service Product Order'}
              </Typography>

              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>

            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          {/*  customer and vehicle section */}
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            sx={{ m: 1 }}
            spacing={5}
          >
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              spacing={2}
              alignContent="start"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Typography variant="h6" gutterBottom>
                  Order Details
                </Typography>
                <Divider sx={{ borderStyle: 'dashed' }} />
              </Grid>

              <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={5}>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  spacing={2}
                  alignContent="start"
                >
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="spo_ref_no" label="SPO Ref. No." size="small" />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      freeSolo
                      size="small"
                      options={ORDER_TYPE?.map((option) => option.name)}
                      getOptionLabel={(option) => option}
                      value={ORDER_TYPE.find((data) => data.id === allValues.ORDER_TYPE) || ''}
                      onChange={(event, newValue) => {
                        setValue(
                          'order_type',
                          ORDER_TYPE.find((data) => data.name === newValue)?.id
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} name="order_type" label="Order Type" />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="invoice_number" label="Invoice Number" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      freeSolo
                      size="small"
                      options={JOB_CARD_STATUS?.map((option) => option.name)}
                      getOptionLabel={(option) => option}
                      value={
                        JOB_CARD_STATUS.find((data) => data.id === allValues.JOB_CARD_STATUS) || ''
                      }
                      onChange={(event, newValue) => {
                        setValue(
                          'order_subtype',
                          JOB_CARD_STATUS.find((data) => data.name === newValue)?.id
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} name="order_subtype" label="Order SubType" />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <DatePicker
                      label="Order Creation Date"
                      value={new Date()}
                      // onChange={handleFilterStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField
                      name="order_creation_time"
                      label="Order Creation Time"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      freeSolo
                      size="small"
                      options={AMC_SUBTYPE?.map((option) => option.name)}
                      getOptionLabel={(option) => option}
                      value={AMC_SUBTYPE.find((data) => data.id === allValues.AMC_SUBTYPE) || ''}
                      onChange={(event, newValue) => {
                        setValue(
                          'amc_subtype',
                          AMC_SUBTYPE.find((data) => data.name === newValue)?.id
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} name="amc_subtype" label="AMC Sub Type" />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="sold_by" label="Sold By" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      freeSolo
                      size="small"
                      options={JOB_CARD_STATUS?.map((option) => option.name)}
                      getOptionLabel={(option) => option}
                      value={
                        JOB_CARD_STATUS.find((data) => data.id === allValues.JOB_CARD_STATUS) || ''
                      }
                      onChange={(event, newValue) => {
                        setValue(
                          'order_status',
                          JOB_CARD_STATUS.find((data) => data.name === newValue)?.id
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} name="order_status" label="Order Status" />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="selling_dealer" label="Selling Dealer" size="small" />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <DatePicker
                      label="Order Submit Date"
                      value={new Date()}
                      // onChange={handleFilterStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="order_submit_time" label="Order Submit Time" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <DatePicker
                      label="Contract Start Date"
                      value={new Date()}
                      // onChange={handleFilterStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField
                      name="contract_start_time"
                      label="Contract Start Time"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <DatePicker
                      label="Cancellation Date"
                      value={new Date()}
                      // onChange={handleFilterStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="cancellation_time" label="Cancellation Time" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <DatePicker
                      label="Contract End Date"
                      value={new Date()}
                      // onChange={handleFilterStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="contract_end_time" label="Contract End Time" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="sold_from" label="Sold From" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="policy_number" label="Policy Number" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="booklet_no" label="Booklet No." size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <DatePicker
                      label="Policy Sale Date"
                      value={new Date()}
                      // onChange={handleFilterStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="policy_sale_time" label="Policy Sale Time" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      freeSolo
                      size="small"
                      options={PENDING_REASON?.map((option) => option.name)}
                      getOptionLabel={(option) => option}
                      value={
                        PENDING_REASON.find((data) => data.id === allValues.PENDING_REASON) || ''
                      }
                      onChange={(event, newValue) => {
                        setValue(
                          'frame',
                          PENDING_REASON.find((data) => data.name === newValue)?.id
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="frame"
                          label="Frame #"
                          // onClick={() => {
                          //   dialog.onTrue();
                          // }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                    />
                  </Grid>
                  <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField
                        name="description"
                        label="Description"
                        size="small"
                        multiline
                        rows={3.5}
                      />
                    </Grid>
                    <Grid item container xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Autocomplete
                          autoHighlight
                          fullWidth
                          freeSolo
                          size="small"
                          options={SALES_CANCELLATION_REASON?.map((option) => option.name)}
                          getOptionLabel={(option) => option}
                          value={
                            SALES_CANCELLATION_REASON.find(
                              (data) => data.id === allValues.SALES_CANCELLATION_REASON
                            ) || ''
                          }
                          onChange={(event, newValue) => {
                            setValue(
                              'cancelation_reason',
                              SALES_CANCELLATION_REASON.find((data) => data.name === newValue)?.id
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="cancelation_reason"
                              label="Cancelation Reason"
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option}>
                              {option}
                            </li>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <DatePicker
                          label="Resubmit Date"
                          value={new Date()}
                          // onChange={handleFilterStartDate}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              size: 'small',
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  spacing={2}
                  alignContent="start"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Typography variant="h6" gutterBottom>
                      Service Product Details
                    </Typography>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    spacing={2}
                    alignContent="start"
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        freeSolo
                        size="small"
                        options={MODEL_NAME?.map((option) => option.name)}
                        getOptionLabel={(option) => option}
                        value={MODEL_NAME.find((data) => data.id === allValues.MODEL_NAME) || ''}
                        onChange={(event, newValue) => {
                          setValue(
                            'model_name',
                            MODEL_NAME.find((data) => data.name === newValue)?.id
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} name="model_name" label="Model Name" />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        freeSolo
                        size="small"
                        options={LABOUR_RATE_LIST?.map((option) => option.name)}
                        getOptionLabel={(option) => option}
                        value={
                          LABOUR_RATE_LIST.find((data) => data.id === allValues.LABOUR_RATE_LIST) ||
                          ''
                        }
                        onChange={(event, newValue) => {
                          setValue(
                            'service_product',
                            LABOUR_RATE_LIST.find((data) => data.name === newValue)?.id
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} name="service_product" label="Service Product" />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        freeSolo
                        size="small"
                        options={MODEL_VARIENT?.map((option) => option.name)}
                        getOptionLabel={(option) => option}
                        value={
                          MODEL_VARIENT.find((data) => data.id === allValues.MODEL_VARIENT) || ''
                        }
                        onChange={(event, newValue) => {
                          setValue(
                            'model_varient',
                            MODEL_VARIENT.find((data) => data.name === newValue)?.id
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} name="model_varient" label="Model Varient" />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="hsn_sac_code" label="HSN/SAC Code" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="period_of_cover" label="Period of Cover" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField
                        name="product_avail_days"
                        label="Product Avail Days"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  spacing={2}
                  alignContent="start"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Typography variant="h6" gutterBottom>
                      eInvoice Details
                    </Typography>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    spacing={2}
                    alignContent="start"
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="einvoice_number" label="eInvoice Number" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="einvoice_details" label="eInvoice Details" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="irn" label="IRN #" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <DatePicker
                        label="eInvoice Cancel Date"
                        value={new Date()}
                        // onChange={handleFilterStartDate}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            size: 'small',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  spacing={2}
                  alignContent="start"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Typography variant="h6" gutterBottom>
                      Frame Details
                    </Typography>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    spacing={2}
                    alignContent="start"
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <DatePicker
                        label="Frame Sale Date"
                        value={new Date()}
                        // onChange={handleFilterStartDate}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            size: 'small',
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField
                        name="last_visit_service_kms"
                        label="Last Visit Service KMs"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="frame_age_yrs" label="Frame Age (in Yrs.)" size="small" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} alignContent="start">
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                spacing={2}
                alignContent="start"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="h6" gutterBottom>
                    Customer Details
                  </Typography>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                </Grid>

                <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={5}>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    spacing={2}
                    alignContent="start"
                  >
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="title" label="Title" size="small" />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        freeSolo
                        size="small"
                        options={JOB_CARD_STATUS?.map((option) => option.name)}
                        getOptionLabel={(option) => option}
                        value={
                          JOB_CARD_STATUS.find((data) => data.id === allValues.JOB_CARD_STATUS) ||
                          ''
                        }
                        onChange={(event, newValue) => {
                          setValue(
                            'account',
                            JOB_CARD_STATUS.find((data) => data.name === newValue)?.id
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} name="account" label="Account" />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="first_name" label="First Name" size="small" />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        freeSolo
                        size="small"
                        options={JOB_CARD_STATUS?.map((option) => option.name)}
                        getOptionLabel={(option) => option}
                        value={
                          JOB_CARD_STATUS.find((data) => data.id === allValues.JOB_CARD_STATUS) ||
                          ''
                        }
                        onChange={(event, newValue) => {
                          setValue(
                            'last_name',
                            JOB_CARD_STATUS.find((data) => data.name === newValue)?.id
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} name="last_name" label="Last Name" />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField
                        name="contact_phone_number"
                        label="Contact Phone Number"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="address_1" label="Address 1" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="mobile_phone" label="Mobile Phone" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="address_2" label="Address 2" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="contact_city" label="Contact City" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="zipcode" label="Zipcode" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="contact_state" label="Contact State" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="contact_fax" label="Contact Fax #" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="contact_email" label="Contact Email" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="email_address" label="Email Address" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="loyalty_id" label="Loyalty ID" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFCheckbox row name="igst_tax" label="IGST Tax" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        freeSolo
                        size="small"
                        options={TRUE_FALSE_DROPDOWN?.map((option) => option.label)}
                        getOptionLabel={(option) => option}
                        value={
                          TRUE_FALSE_DROPDOWN.find(
                            (data) => data.value === allValues.TRUE_FALSE_DROPDOWN
                          ) || ''
                        }
                        onChange={(event, newValue) => {
                          setValue(
                            'amc_emi',
                            TRUE_FALSE_DROPDOWN.find((data) => data.label === newValue)?.value
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} name="amc_emi" label="AMC - EMI" />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                spacing={2}
                alignContent="start"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="h6" gutterBottom>
                    Payment Details
                  </Typography>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                </Grid>

                <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={5}>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    spacing={2}
                    alignContent="start"
                  >
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="total_payments" label="Total Payments" size="small" />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="sbc_rs" label="SBC (Rs)" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="balance_payment" label="Balance Payment" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField
                        name="service_tax_gst"
                        label="Service Tax/GST(Rs)"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField
                        name="total_payment_realized"
                        label="Total Payment Realized"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="base_price" label="Base Price" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="discount" label="Discount %" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="total_price" label="Total Price" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="discount_amount" label="Discount Amount" size="small" />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="tax_name" label="Tax Name" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="kkc" label="KKC(Rs)" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="comments" label="Comments" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="cgst" label="CGST %" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="cgst_rs" label="CGST(Rs)" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="sgst" label="SGST %" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="sgst_rs" label="SGST(Rs)" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="utgst" label="UTGST %" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="utgst_rs" label="UTGST(Rs)" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="igst" label="IGST %" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="igst_rs" label="IGST(Rs)" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="cess" label="Cess %" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <RHFTextField name="cess_amount" label="Cess Amount" size="small" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={6}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                spacing={2}
                alignContent="start"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="h6" gutterBottom>
                    Dealer Details
                  </Typography>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  spacing={2}
                  alignContent="start"
                >
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="network_code" label="Network Code" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="dealer_region" label="Dealer Region" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="dealer_code" label="Dealer Code" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="dealer_zone" label="Dealer Zone" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField name="dealer_area" label="Dealer Area" size="small" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={dialog.value}
          onClose={dialog.onTrue}
          PaperProps={{
            sx: { maxWidth: 820, maxHeight: 840 },
          }}
        >
          <Toolbar>
            <Typography variant="h6" sx={{ flex: 1 }}>
              Return Order Detail
            </Typography>

            <IconButton color="inherit" edge="start" onClick={dialog.onFalse}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>

          <Divider sx={{ borderStyle: 'dashed' }} />
          {/* <DialogContent>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                columnSpacing={4}
                rowSpacing={2}
                sx={{ mt: 2 }}
              >
                <Grid item container xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    spacing={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Iconify icon="formkit:dollar" sx={{ mr: 1 }} />
                    <Typography fontSize={16} fontWeight={600} sx={{ flex: 1 }}>
                      Payment Denomination
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <RHFTextField name="job_card" label="Job Card #" size="small" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent> */}

          <DialogActions>
            <Button onClick={dialog.onFalse} variant="outlined" color="inherit">
              Cancel
            </Button>
            <Button
              onClick={() => {
                dialog.onFalse();
              }}
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <DialogActions
          sx={{
            bottom: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {row ? 'Update' : 'Add'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
