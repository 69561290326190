import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useEventListener } from 'src/hooks/use-event-listener';
import { KEY } from 'src/_mock/constant';

// ----------------------------------------------------------------------

export default function SuccessConfirmDialog({ title, content, action, action2, open, onClose, ...other }) {
  const handleKeyDown = async (event) => {
    if (event.key.toLowerCase() === KEY.NO) {
      onClose();
    }
  };

  useEventListener('keydown', handleKeyDown);
  return (
    <Dialog fullWidth maxWidth="xs" open={open} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}

      <DialogActions>
        {action}

        <Button variant="outlined" color="inherit" onClick={onClose}>
          {action2 || 'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SuccessConfirmDialog.propTypes = {
  action: PropTypes.node,
  action2: PropTypes.string,
  content: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
};
