import { Helmet } from 'react-helmet-async';
import { COMPANY_NAME } from 'src/_mock/constant';
// sections
import { ListView } from 'src/sections/transaction/cn_dn_entry/dn_entry_wo_stock/view';

// ----------------------------------------------------------------------

export default function DnEntryWoStockListPage() {
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} : Dn Entry W/o Stock List</title>
      </Helmet>

      <ListView />
    </>
  );
}
