import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import { Grid, Link, Typography, useTheme } from '@mui/material';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import { useLocation } from 'react-router';

// ----------------------------------------------------------------------

export default function AuthModernLayout({ children, image }) {
  const mdUp = useResponsive('up', 'md');

  const path = useLocation();
  const isRegister = path.pathname === '/register' ? 'register' : 'login';

  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;

  const isData = {
    login: {
      title: (
        <>
          Welcome to <br /> <span style={{ color: PRIMARY_MAIN }}> MYBOOK</span>
        </>
      ),
      subTitle: (
        <>
          <Typography
            component="div"
            sx={{
              color: 'text.secondary',
              typography: 'caption',
            }}
          >
            {'Sign In to access '}
            <Link underline="always" color="text.primary" component={RouterLink} href={paths.home}>
              MYBOOK HOME
            </Link>
          </Typography>
        </>
      ),
      endTitle: (
        <>
          {' '}
          Dont have a MYBOOK account? <br />
          you can{' '}
          <Link
            style={{ color: PRIMARY_MAIN }}
            component={RouterLink}
            href={paths.authDemo.modern.register}
          >
            <strong>Sign Up Now</strong>
          </Link>
        </>
      ),
    },
    register: {
      title: (
        <>
          Start with your <br /> Free <span style={{ color: PRIMARY_MAIN }}> Account Today</span>{' '}
        </>
      ),
      subTitle: (
        <>
          <Typography
            component="div"
            sx={{
              color: 'text.secondary',
              typography: 'caption',
            }}
          >
            {'By signing up, I agree to '}
            <Link underline="always" color="text.primary">
              Terms of Service
            </Link>
            {' and '}
            <Link underline="always" color="text.primary">
              Privacy Policy
            </Link>
            .
          </Typography>
        </>
      ),
      endTitle: (
        <>
          {' '}
          If you have already an account
          <br />
          you can{' '}
          <Link
            style={{ color: PRIMARY_MAIN }}
            component={RouterLink}
            href={paths.authDemo.modern.login}
          >
            <strong>Sign In here</strong>
          </Link>
        </>
      ),
    },
  };

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        my: 'auto',
        maxWidth: 550,
        px: { xs: 2, md: 8 },
      }}
    >
      <Card
        sx={{
          py: { xs: 5, md: 0 },
          px: { xs: 3, md: 0 },
          boxShadow: { md: 'none' },
          overflow: { md: 'unset' },
          bgcolor: { md: 'background.default' },
        }}
      >
        {children}
      </Card>
    </Stack>
  );

  const renderSection = (
    <Stack flexGrow={1} sx={{ position: 'relative' }}>
      <Box
        component="img"
        alt="auth"
        src={image || '/assets/background/bg.svg'}
        sx={{
          objectFit: 'cover',
          position: 'absolute',
          width: '70%',
          height: '100%',
        }}
      />
      <Stack
        spacing={2.5}
        zIndex={2}
        sx={{
          margin: 'auto 15%',
          height: '55%',
          justifyContent: 'space-between',
        }}
      >
        <Stack>
          <Box
            component="img"
            alt="auth"
            src="/assets/background/logo_icon.svg"
            sx={{
              width: '40px',
              height: '40px',
              mr: 1,
            }}
          />
          <Grid
            xs={12}
            md={12}
            fontSize="60px"
            sx={{
              fontWeight: 'bold',
            }}
          >
            {isData[isRegister].title}
          </Grid>
          <Grid xs={12} md={12} fontSize="14px">
            {isData[isRegister].subTitle}
          </Grid>
        </Stack>
        <Stack>
          <Grid xs={12} md={12} fontSize="14px">
            {isData[isRegister].endTitle}
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
        position: 'relative',
        '&:before': {
          width: 1,
          height: 1,
          zIndex: -1,
          content: "''",
          position: 'absolute',
          backgroundSize: 'cover',
          opacity: { xs: 0.24, md: 0 },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage: 'url(/assets/background/overlay_4.jpg)',
        },
      }}
    >
      {mdUp && renderSection}
      {renderContent}
    </Stack>
  );
}

AuthModernLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
};
