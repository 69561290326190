import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import { Grid, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/auth/context/jwt';
import { useBoolean } from 'src/hooks/use-boolean';
import { useDebounce } from 'src/hooks/use-debounce';
import { useResponsive } from 'src/hooks/use-responsive';
import FileManagerFileDetails from './SidebarFilter';

function FilterEnquiry({
  isFileManagerVisible,
  setIsFileManagerVisible,
  productFilter,
  setProductFilter,
  getAll,
}) {
  const { user } = useContext(AuthContext);
  const [search, setSearch] = useState(false);

  const lgUp = useResponsive('down', 'md');

  const [searchEnquiry, setSearchEnquiry] = useState('');
  const favorite = useBoolean();

  useEffect(() => {
    setProductFilter((prev) => ({
      ...prev,
      search: searchEnquiry,
    }));
  }, [getAll, searchEnquiry, setProductFilter]);

  useEffect(() => {
    if (isFileManagerVisible) favorite.onTrue();
  }, [favorite, isFileManagerVisible]);

  return (
    <>
      {lgUp && (
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          sx={{ p: 0.5, mb: 1 }}
        >
          <Grid item xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}>
            {search ? (
              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                  <Icon
                    icon="ri:arrow-go-back-fill"
                    color="primary"
                    fontSize="large"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setSearch(false)}
                  />
                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
                  <TextField
                    name="search"
                    placeholder="Search a contact"
                    size="small"
                    type="number"
                    autoFocus
                    onChange={(e) => {
                      setSearchEnquiry(e.target.value);
                    }}
                    variant="standard"
                  />
                </Grid>
              </Grid>
            ) : (
              <Typography variant="subtitle2">Welcome, {user?.name}</Typography>
            )}
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} sx={{ textAlign: 'end' }}>
            <Icon
              icon="fa-solid:search"
              color="primary"
              fontSize="large"
              style={{ marginRight: 15, cursor: 'pointer' }}
              onClick={() => setSearch(true)}
            />
            <Icon
              icon="icomoon-free:filter"
              color="primary"
              fontSize="large"
              style={{ cursor: 'pointer' }}
              onClick={() => setIsFileManagerVisible(true)}
            />
          </Grid>
        </Grid>
      )}

      {isFileManagerVisible && (
        <FileManagerFileDetails
          open={isFileManagerVisible}
          onClose={() => {
            setIsFileManagerVisible(false);
          }}
          productFilter={productFilter}
          setProductFilter={setProductFilter}
          getAll={getAll}
        />
      )}
    </>
  );
}

export default FilterEnquiry;

FilterEnquiry.propTypes = {
  productFilter: PropTypes.object,
  setProductFilter: PropTypes.func,
  getAll: PropTypes.func,
  isFileManagerVisible: PropTypes.bool,
  setIsFileManagerVisible: PropTypes.func,
};
