import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { create, update } from 'src/utils/axios';
import { KEY, MODULE_STATUS, VOUCHERTYPE } from 'src/_mock/constant';
import {
  Autocomplete,
  Chip,
  Divider,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import { Stack } from '@mui/system';
import { useBgColorAutocomplete } from 'src/_mock/constant_funcation';
// ----------------------------------------------------------------------

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const bgColorAutocomplete = useBgColorAutocomplete();

  const TransportSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const values = useMemo(
    () => ({
      name: row?.name || '',
      isHighligh: '',
      voucherId: row?.voucherId || '',
      status: row?.status || false,
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(TransportSchema),
    values,
  });

  const {
    setValue,
    reset,
    trigger,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;

  const allValues = watch();
  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      let URL = 'bpoptions/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
      setLoading(false);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods}>
        <Grid
          container
          sx={{
            top: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Grid item xs={12} md={12}>
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update Payment Option' : 'Add Payment Option'}
              </Typography>

              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>
        </Grid>

        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            pt={3}
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)',
            }}
          >
            <RHFTextField
              name="name"
              label={
                <span>
                  Name<span style={{ color: 'red' }}>*</span>
                </span>
              }
              size="small"
              autoFocus
            />
            <Autocomplete
              autoHighlight={allValues.isHighligh}
              name="voucherId"
              fullWidth
              size="small"
              multiple
              limitTags={3}
              openOnFocus
              onInputChange={(event, newValue) => {
                setValue('isHighligh', newValue);
              }}
              onBlur={() => {
                setValue('isHighligh', '');
              }}
              options={VOUCHERTYPE.filter((y) => y.value < 7)}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Voucher Types"
                  name="voucherId"
                  placeholder="vochers"
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.label}>
                  {option.label}
                </li>
              )}
              value={(() => {
                const selectedVouchers = allValues.voucherId.split('').map((x) => parseInt(x, 10));
                const vo = VOUCHERTYPE.filter(
                  (y) => y.value < 7 && selectedVouchers.includes(y.value)
                );
                return vo;
              })()}
              onChange={(event, newValue) => {
                console.log(event, newValue);
                setValue(
                  'voucherId',
                  newValue.reduce((acc, next) => acc + next.value, '')
                );
              }}
              renderTags={(selected, getTagProps) =>
                selected.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={option.label}
                    label={option.label}
                    size="small"
                    variant="soft"
                  />
                ))
              }
              ListboxProps={{ ...bgColorAutocomplete }}
            />
            <RHFCheckbox name="status" label="Active" size="small" />
          </Box>
        </DialogContent>

        <Stack
          direction="row-reverse"
          alignItems="center"
          sx={{
            bottom: 0,
            zIndex: 111111,
            p: 3,
            position: 'sticky',
            width: '100%',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
          id="modelfooter"
        >
          <Tooltip title="(Alt + A)" arrow>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={async () => {
                if (await trigger()) {
                  onSubmit();
                }
              }}
            >
              {row ? 'Update' : 'Add'}
            </LoadingButton>
          </Tooltip>

          <Tooltip title="(Esc)" arrow>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
          </Tooltip>

          <Button
            variant="soft"
            onClick={() => {
              reset(values);
            }}
            sx={{ mr: 2 }}
          >
            Reset
          </Button>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
