import PropTypes from 'prop-types';
// @mui
import { DataGrid } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';
import moment from 'moment';

const columns = [
  {
    field: 'date',
    headerName: 'Date',
    width: 160,
    sortable: false,
  },
  {
    field: 'particular',
    headerName: 'Particulars',
    width: 400,
    sortable: false,
  },
  {
    field: 'vType',
    headerName: 'Voucher Type',
    width: 160,
    sortable: false,
  },
  {
    field: 'instNo',
    headerName: 'Instrument No',
    width: 150,
    sortable: false,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'banDate',
    headerName: 'Bank Date',
    width: 150,
    type: 'date',
    editable: true,
    sortable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => (params.value ? moment(params.value).format('DD/MM/YYYY') : ''),
  },
  {
    field: 'debit',
    headerName: 'Debit',
    width: 150,
    sortable: false,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'credit',
    headerName: 'Credit',
    width: 150,
    sortable: false,
    align: 'right',
    headerAlign: 'right',
  },
];

export default function BankDataGridBasic({ data, handleBankDateChange, onTableCellDoubleClick }) {
  const [editCellValue, setEditCellValue] = useState(null);

  const handleCellEditStop = useCallback(
    (params, event) => {
      if (editCellValue !== null && params.field === 'banDate') {
        const bValue = event?.target?.value;
        handleBankDateChange(params.id, bValue);
      }

      setEditCellValue(null);
    },
    [handleBankDateChange, editCellValue]
  );

  const handleCellClick = useCallback(
    (params, event) => {
      if (params.field === 'particular') {
        console.log('KISHAN DOBARIYA', params);
        onTableCellDoubleClick(params?.id);
      }
    },
    [onTableCellDoubleClick]
  );

  const handleCellEditStart = useCallback((params) => {
    setEditCellValue({ id: params.id, field: params.field, value: params?.value });
  }, []);

  return (
    <DataGrid
      columns={columns}
      rows={data}
      disableRowSelectionOnClick
      hideFooter
      onCellEditStart={handleCellEditStart}
      onCellEditStop={handleCellEditStop}
      onCellClick={handleCellClick}
    />
  );
}

BankDataGridBasic.propTypes = {
  data: PropTypes.array,
  handleBankDateChange: PropTypes.func,
  onTableCellDoubleClick: PropTypes.func,
};
