import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';
import { isNaN } from 'lodash';

// ----------------------------------------------------------------------

const toInputUppercase = (e) => {
  if (isNaN(e.target.value) && e.target.value !== null) {
    e.target.value = e.target.value.toUpperCase();
  }
};

const handleHover = (event) => { };

export default function RHFTextField({
  name,
  helperText,
  type,
  textDirection = 'ltr',
  isCamalCase = false,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={type}
          value={type === 'number' && field.value === 0 ? '' : field.value}
          onMouseOver={handleHover}
          onChange={(event) => {
            if (type === 'string' && other?.isUpperCase) {
              event.target.value = event.target.value.toUpperCase();
            }
            if (type === 'number') {
              field.onChange(Number(event.target.value));
            } else {
              field.onChange(event.target.value);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          inputProps={{
            autoComplete: 'off',
          }}
          {...other}
        />
      )}
    />
  );
}

RHFTextField.propTypes = {
  helperText: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  textDirection: PropTypes.string,
  isCamalCase: PropTypes.bool
};
