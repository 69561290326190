import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useBoolean } from 'src/hooks/use-boolean';
import LogTable from 'src/layouts/_common/log/log-manage';
import { create, fetcher } from 'src/utils/axios';
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

export default function UserTableToolbar({
  filters,
  onFilters,
  getAll,
  yearOptions,
  permission,
}) {
  const popover = usePopover();
  const fileRef = useRef(null);

  const handleAttach = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };
  const downloadFile = async () => {
    try {
      const URL = `automobile/product/colours/export/?token=${localStorage.getItem('token')}`;
      const data = await fetcher(URL, true);
      if (data) {
        window.open(`${HOST_API}${URL}}`, 'blank');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const uploadFile = async (data) => {
    try {
      const URL = 'automobile/product/colours/import/';
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formdata = new FormData();
      formdata.append('file', data);
      const response = await create({ url: URL, payload: formdata, config });
      if (response) {
        getAll();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const quickLog = useBoolean();

  const handleFilterName = useCallback(
    (event) => {
      onFilters('name', event.target.value);
    },
    [onFilters]
  );

  const handleFilterRole = useCallback(
    (event) => {
      onFilters(
        'year',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  return (
    <>
      <input
        ref={fileRef}
        type="file"
        style={{ display: 'none' }}
        multiple={false}
        onChange={async (e) => {
          if (e.target.files['0']) {
            await uploadFile(e.target.files['0']);
            e.target.value = '';
          }
        }}
      />
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        {yearOptions && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>Role</InputLabel>

            <Select
              multiple
              value={filters.year}
              onChange={handleFilterRole}
              input={<OutlinedInput label="Role" />}
              renderValue={(selected) => selected.map((value) => value).join(', ')}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              {yearOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox disableRipple size="small" checked={filters.year.includes(option)} />
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <TextField
            fullWidth
            value={filters.name}
            onChange={handleFilterName}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />

          {(permission?.p || permission?.i || permission?.e) && (
            <IconButton onClick={popover.onOpen}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          )}
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {permission?.p && (
          <MenuItem
            onClick={() => {
              popover.onClose();
            }}
          >
            <Iconify icon="solar:printer-minimalistic-bold" />
            Print
          </MenuItem>
        )}

        {permission?.i && (
          <MenuItem
            onClick={() => {
              handleAttach();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:import-bold" />
            Import
          </MenuItem>
        )}

        {permission?.e && (
          <MenuItem
            onClick={() => {
              downloadFile();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:export-bold" />
            Export
          </MenuItem>
        )}


        {permission?.l && (
          <MenuItem
            onClick={() => {
              quickLog.onTrue();
              popover.onClose();
            }}
          >
            <Iconify icon="mdi:refresh" />
            Log
          </MenuItem>
        )}
      </CustomPopover>

      {quickLog.value && (
        <LogTable
          open={quickLog.value}
          logDetails={{
            name: 'Product Group Log',
            API: 'automobile/product/categories/',
          }}
          onClose={() => {
            quickLog.onFalse();
          }}
        />
      )}
    </>
  );
}

UserTableToolbar.propTypes = {
  filters: PropTypes.object,
  permission: PropTypes.object,
  onFilters: PropTypes.func,
  getAll: PropTypes.func,
  yearOptions: PropTypes.array,
};
