import { Helmet } from 'react-helmet-async';
import { COMPANY_NAME } from 'src/_mock/constant';
// sections
import { ListView } from 'src/sections/work_shop/information/spare_information/default_accessories_for_vehicle/view';

// ----------------------------------------------------------------------

export default function DefaultAccessoriesForVehicleListPage() {
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} : Default Accessories For Vehicle List</title>
      </Helmet>

      <ListView />
    </>
  );
}
