import { Helmet } from 'react-helmet-async';
import { COMPANY_NAME } from 'src/_mock/constant';
// sections
import { ListView } from 'src/sections/work_shop/information/workshop_information/mechanic_information/view';

// ----------------------------------------------------------------------

export default function MechanicInformationListPage() {
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} : Mechanic Information List</title>
      </Helmet>

      <ListView />
    </>
  );
}
