import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';

//
import Label from 'src/components/label';
import { useBoolean } from 'src/hooks/use-boolean';
import { AuthContext } from 'src/auth/context/jwt';
import moment from 'moment';
import Iconify from 'src/components/iconify';
import { m } from 'framer-motion';
import { varHover } from 'src/components/animate';
import { popover } from 'src/theme/overrides/components/popover';
import { Grid, Tooltip, Typography } from '@mui/material';
import { KEY } from 'src/_mock/constant';
import { useEventListener } from 'src/hooks/use-event-listener';
import { HEADER, NAV } from '../config-layout';
import {
  Searchbar,
  AccountPopover,
  SettingsButton,
  LanguagePopover,
  NotificationsPopover,
  ContactsPopover,
  ImportPopover
} from '../_common';
import YearManage from '../_common/year-manage';
import VoiceButton from '../_common/voice-button';

// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const theme = useTheme();

  const settings = useSettingsContext();
  const { user } = useContext(AuthContext);

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const quickEdit = useBoolean();
  const quickImport = useBoolean();

  useEffect(() => {
    if (user.companyId > 0 && user?.co_yearId === null) quickEdit.onTrue();
  }, [user, quickEdit]);

  const startDate = moment(user?.co_year?.sdate);
  const endDate = moment(user?.co_year?.edate);
  const company = user?.companies?.find((x) => x.id === user?.companyId);
  const branch = user?.branches?.find((x) => x.id === user?.branchId)

  // Format the dates in the desired format
  const formattedStartDate = startDate.format('DD/MM/YYYY');
  const formattedEndDate = endDate.format('DD/MM/YYYY');

  const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;

  useEffect(() => {
    if (user?.companyId === null) onOpenNav();
  }, [user, onOpenNav]);

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav} id="sidebar-btn">
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      <Searchbar />
      <ImportPopover quickImport={quickImport} />
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {lgUp && (
          <Grid sx={{ textAlign: 'right', mr: '30px' }}>
            <Grid sx={{ display: 'flex' }}>
              <Typography variant="h5" color="primary">
                {company?.name
                  ? `${company?.name.toUpperCase()} > ${branch?.name.toUpperCase()}`
                  : ''}
              </Typography>
              {
                company?.businessId === 1 ? company?.gst_no && (
                  <span style={{ color: 'red', fontWeight: 'bold' }}>
                    GST NO: {company?.gst_no}
                  </span>
                ) : <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                  { }
                </Typography>
              }

            </Grid>
            {lgUp && user?.companyId > 0 && (
              <Tooltip title="Switch the Financial Year" arrow>

                <Typography
                  variant="h6"
                  onClick={() => {
                    if (user?.companyId > 0) quickEdit.onTrue();
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  {formattedDateRange}
                </Typography>
              </Tooltip>
            )}
          </Grid>
        )}

        {!lgUp && user?.companyId > 0 && (
          <Tooltip title="Switch the Financial Year" arrow>
            <IconButton
              component={m.button}
              whileTap="tap"
              whileHover="hover"
              variants={varHover(1.05)}
              onClick={() => {
                if (user.companyId > 0) quickEdit.onTrue();
              }}
              sx={{
                width: 40,
                height: 40,
                ...(popover.open && {
                  bgcolor: 'action.selected',
                }),
              }}
            >
              <Iconify icon="uim:calender" sx={{ borderRadius: 0.65, width: 28 }} />
            </IconButton>
          </Tooltip>
        )}
        {user?.companyId > 0 && <VoiceButton />}
        {/* <LanguagePopover /> */}
        <NotificationsPopover />
        {/* <ContactsPopover /> */}
        <SettingsButton />
        <AccountPopover />
      </Stack>

      {quickEdit.value && (
        <YearManage
          open={quickEdit.value}
          onClose={() => {
            quickEdit.onFalse();
          }}
        />
      )}
    </>
  );

  const handleKeyDown = async (event) => {
    if (event.ctrlKey && event.key.toLowerCase() === KEY.YEAR) {
      if (user.companyId > 0) quickEdit.onTrue();
    }
    if (event.key === KEY.CLOSE) {
      quickImport.onFalse();
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
