import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { create, destroy, fetcher, update } from 'src/utils/axios';
import { KEY } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import CompanyYear from 'src/layouts/_common/company-year';
import { DatePicker } from '@mui/x-date-pickers';
import { Upload } from 'src/components/upload';
import { Box } from '@mui/system';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';
import axios from 'axios';
import { HOST_API } from 'src/config-global';
import { useBgColorAutocomplete } from 'src/_mock/constant_funcation';
import GroupAccountForm from '../accounts/group/quick-edit-form';

export default function QuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();
  const quickEdit = useBoolean();
  const confirm = useBoolean();

  const bgColorAutocomplete = useBgColorAutocomplete();

  const [file, setFile] = useState(null);

  const [banksOptionList, setBanksOptionList] = useState([]);
  const [cardIssuersList, setCardIssuersList] = useState([]);
  const [aGroup, setAGroup] = useState({});
  const [groupList, setGroupList] = useState([]);

  const [loading, setLoading] = useState(false);

  const [showCvv, setShowCvv] = useState(true);
  const [showFullCardNumber, setShowFullCardNumber] = useState(true);

  const CreditCardSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    ac_groupId: Yup.number().required('Account Group is required'),
    creditcard: Yup.object().shape({
      bankId: Yup.number().min(0).max(4294967296).required('Bank ID is required'),
      no: Yup.string().min(13).max(16).required('Card number is required'),
      card_issuerId: Yup.number().required('Card Issuer ID is required'),
      expirtyDate: Yup.date()
        .required('Expiry date is required')
        .transform((value, originalValue) => (originalValue ? new Date(originalValue) : new Date()))
        .test('is-greater', 'Expiry date must be in the future', (value) => value > new Date()),
      billingDay: Yup.number().min(1).max(31),
      cvv: Yup.string().length(3, 'CVV must be 3 digits'),
      limit: Yup.number().min(0).required('Limit is required'),
      holder: Yup.string().min(0).max(255).required('Holder name is required'),
      image: Yup.string().min(0).max(255).notRequired(),
      plink: Yup.string().notRequired(),
    }),
  });

  const values = useMemo(
    () => ({
      name: row?.name || '',
      isHighligh: '',
      ac_groupId: row?.ac_groupId || null,
      creditcard: {
        bankId: row?.creditcard?.bankId || null,
        no: row?.creditcard?.no || null,
        seq: row?.creditcard?.seq || null,
        card_issuerId: row?.creditcard?.card_issuerId || null,
        expirtyDate: row?.creditcard?.expirtyDate || new Date(),
        billingDay: row?.creditcard?.billingDay || null,
        cvv: row?.creditcard?.cvv || null,
        limit: row?.creditcard?.limit || null,
        holder: row?.creditcard?.holder || '',
        image: row?.creditcard?.image || null,
        plink: row?.creditcard?.plink || null,
        status: row?.creditcard?.status || null,
      },
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(CreditCardSchema),
    values,
    mode: 'onChange',
  });

  const {
    reset,
    control,
    handleSubmit,
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      let URL = 'accounts/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      setValue('creditcard.cvv', null);
      setShowCvv(true);
      setLoading(false);
      setFile(null);
      reset();
    } catch (error) {
      setLoading(false);
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  useEffect(() => {
    if (row?.creditcard?.no) {
      setValue('creditcard.no', row.creditcard.no);
    }
  }, [row, setValue]);

  const toggleCardNumberVisibility = () => {
    setShowFullCardNumber(!showFullCardNumber);
  };

  const toggleCvvVisibility = () => {
    setShowCvv(!showCvv);
  };

  useEffect(() => {
    if (row) {
      setShowCvv(false);
      setShowFullCardNumber(false);
    } else {
      setShowCvv(true);
      setShowFullCardNumber(true);
    }
  }, [row]);

  const HandleBanksList = async () => {
    let URL = 'banks/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setBanksOptionList(data?.rows);
  };

  const HandleCardIssuersList = async () => {
    let URL = 'card_issuers/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setCardIssuersList(data?.rows);
  };

  const HandleGroupList = async () => {
    let URL = 'ac_groups/';
    URL += `?page=${1}&limit=${10000}&status=true&allGroups=false&`;
    const { data } = await fetcher(URL);
    setGroupList(data?.rows.map((x) => ({ ...x, a: 'name' })));
  };

  useEffect(() => {
    if (open) {
      HandleBanksList();
      HandleCardIssuersList();
      HandleGroupList();
    }
  }, [open]);

  useEffect(() => {
    reset();
    setFile(null);
  }, [open, reset]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  const handleDropSingleFile = useCallback(
    async (acceptedFiles, fieldName) => {
      const newFile = acceptedFiles[0];
      if (newFile) {
        setFile(
          Object.assign(newFile, {
            preview: URL.createObjectURL(newFile),
          })
        );
      }
      try {
        const URL = `${HOST_API}upload`;
        const formData = new FormData();
        formData.append('file', newFile);
        const response = await axios.post(URL, formData);
        setValue('creditcard.image', response.data.filename);
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
      }
    },
    [enqueueSnackbar, setValue]
  );

  const handleGroupClose = () => {
    quickEdit.onFalse();
    HandleGroupList();
  };

  const deleteAccountGroupModel = useCallback(
    async (id) => {
      try {
        let URL = 'ac_groups/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          HandleGroupList();
          confirm.onFalse();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [enqueueSnackbar, confirm]
  );

  useEventListener('keydown', handleKeyDown);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={false}
        open={open}
        onKeyDown={handleKeyDown}
        PaperProps={{
          sx: { width: '70vw' },
        }}
      >
        <FormProvider methods={methods}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                top: 0,
                zIndex: 9,
                position: 'sticky',
                bgcolor: (theme) => alpha(theme.palette.background.default, 1),
              }}
            >
              <Toolbar>
                <Typography variant="h5" sx={{ flex: 1 }}>
                  {row ? 'Update Credit Card' : 'Add Credit Card'}
                </Typography>

                <CompanyYear />

                <IconButton
                  color="inherit"
                  edge="start"
                  onClick={() => {
                    setValue('creditcard.cvv', null);
                    setShowCvv(true);
                    onClose();
                  }}
                >
                  <Iconify icon="mingcute:close-line" />
                </IconButton>
              </Toolbar>

              <Divider sx={{ borderStyle: 'dashed' }} />
            </Grid>

            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              sx={{ p: 2 }}
              spacing={4}
            >
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
                spacing={2}
                alignContent="flex-start"
              >
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField
                    name="name"
                    label={
                      <span>
                        Account Name<span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    size="small"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    size="small"
                    disableCloseOnSelect
                    options={groupList}
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    groupBy={(option) => option?.a}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setAGroup(newValue);
                      setValue('ac_groupId', newValue?.id);
                    }}
                    value={
                      groupList?.find((data) => data.id === allValues.ac_groupId) || row?.ac_group
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <span>
                            Group Name<span style={{ color: 'red' }}>*</span>
                          </span>
                        }
                        name="ac_groupId"
                        error={errors?.ac_groupId && !allValues?.ac_groupId}
                      />
                    )}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader component="li">
                          <ListItem sx={{ padding: 0, margin: 0 }}>Name</ListItem>
                          <ListItem sx={{ padding: 0, margin: 0 }}>Effect On</ListItem>
                        </GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                        <GroupFooter component="li">
                          <Button
                            onClick={() => {
                              quickEdit.onTrue();
                            }}
                          >
                            Add
                          </Button>
                          {aGroup && (
                            <>
                              <Button
                                onClick={() => {
                                  quickEdit.onTrue();
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                onClick={() => {
                                  confirm.onTrue();
                                }}
                              >
                                Delete
                              </Button>
                            </>
                          )}
                        </GroupFooter>
                      </li>
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                          {option?.name}
                        </ListItem>
                        <ListItem sx={{ padding: 0, margin: 0 }}>
                          {option.ac_group ? option.ac_group?.name : '-'}
                        </ListItem>
                      </Box>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    options={banksOptionList?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      banksOptionList?.find((data) => data.id === allValues?.creditcard?.bankId)
                        ?.name || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'creditcard.bankId',
                        banksOptionList?.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="creditcard.bankId"
                        label={
                          <span>
                            Select Bank<span style={{ color: 'red' }}>*</span>
                          </span>
                        }
                        error={errors?.creditcard?.bankId && !allValues?.creditcard?.bankId}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Controller
                    name="creditcard.no"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <TextField
                        ref={ref}
                        label="Card Number"
                        type="text" // Keep type as text for custom "X" masking
                        value={
                          // Check if we should show the full number or mask it
                          showFullCardNumber ? value : value?.replace(/.(?=.{4})/g, 'X')
                        }
                        onChange={(e) => {
                          // Update the form state with the new value
                          onChange(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={toggleCardNumberVisibility} edge="end">
                                {showFullCardNumber ? (
                                  <Iconify icon="eva:unlock-outline" />
                                ) : (
                                  <Iconify icon="eva:lock-outline" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField
                    name="creditcard.holder"
                    label={
                      <span>
                        Card Holder Name<span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={2.7} md={2.7} lg={2.7} xl={2.7} xxl={2.7}>
                  <RHFTextField
                    label={
                      <span>
                        Billing Day<span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    name="creditcard.billingDay"
                    size="small"
                    type="number"
                    inputProps={{ min: '1', max: '31' }}
                  />
                </Grid>
                <Grid item xs={12} sm={3.3} md={3.3} lg={3.3} xl={3.3} xxl={3.3}>
                  <Controller
                    name="creditcard.expirtyDate"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <DatePicker
                        defaultValue={new Date(field.value)}
                        timezone="Asia/Kolkata"
                        label={
                          <span>
                            Expiry Date<span style={{ color: 'red' }}>*</span>
                          </span>
                        }
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        format="MM/yyyy"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            size: 'small',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    options={cardIssuersList?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      cardIssuersList?.find(
                        (data) => data.id === allValues?.creditcard?.card_issuerId
                      )?.name || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'creditcard.card_issuerId',
                        cardIssuersList?.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="creditcard.card_issuerId"
                        label={
                          <span>
                            Select Card Type<span style={{ color: 'red' }}>*</span>
                          </span>
                        }
                        error={
                          errors?.creditcard?.card_issuerId && !allValues?.creditcard?.card_issuerId
                        }
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <RHFTextField
                    label={
                      <span>
                        Card Limit<span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    name="creditcard.limit"
                    size="small"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <Controller
                    name="creditcard.cvv"
                    control={control}
                    render={({ field }) => (
                      <RHFTextField
                        {...field}
                        label={
                          <span>
                            CVV<span style={{ color: 'red' }}>*</span>
                          </span>
                        }
                        size="small"
                        type="text"
                        value={showCvv ? field?.value : 'XXX'}
                        onChange={(e) => {
                          if (showCvv && e.target.value) {
                            field.onChange(e.target.value);
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  toggleCvvVisibility();
                                }}
                                edge="end"
                              >
                                {showCvv ? (
                                  <Iconify icon="eva:unlock-outline" />
                                ) : (
                                  <Iconify icon="eva:lock-outline" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
                  <RHFTextField label="Payment Link" name="creditcard.plink" size="small" />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2} xxl={2}>
                  <RHFCheckbox name="creditcard.status" label="Active" size="small" />
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Upload
                    file={
                      allValues.creditcard.image
                        ? `${HOST_API}uploads/medium/${allValues.creditcard.image}`
                        : file
                    }
                    onDrop={handleDropSingleFile}
                    onDelete={() => {
                      setFile(null);
                      setValue('creditcard.image', null);
                    }}
                    displayName="Upload Credit Card Image"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Stack
            direction="row-reverse"
            alignItems="center"
            sx={{
              bottom: 0,
              zIndex: 9,
              p: 3,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            {/* <Stack direction="row" alignItems="center" flexGrow={1} spacing={2}>
              {!true && (
                <Button variant="outlined" color="inherit">
                  View
                </Button>
              )}
            </Stack> */}

            <Tooltip title="(Alt + A)" arrow>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={async () => {
                  if (await trigger()) {
                    onSubmit();
                  }
                }}
              >
                {row ? 'Update' : 'Add'}
              </LoadingButton>
            </Tooltip>

            <Tooltip title="(Esc)" arrow>
              <Button
                variant="outlined"
                onClick={() => {
                  setValue('creditcard.cvv', null);
                  setShowCvv(true);
                  onClose();
                }}
                sx={{ mr: 2 }}
              >
                Cancel
              </Button>
            </Tooltip>
            <Tooltip title="Reset" arrow>
              <Button
                variant="soft"
                onClick={() => {
                  reset(values);
                }}
                sx={{ mr: 2 }}
              >
                Reset
              </Button>
            </Tooltip>
          </Stack>
        </FormProvider>
      </Dialog>

      <GroupAccountForm
        row={aGroup || null}
        open={quickEdit.value}
        onClose={handleGroupClose}
        table={table}
        getAll={getAll}
        tableData={tableData}
      />

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => aGroup?.id && deleteAccountGroupModel(aGroup?.id)}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

QuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
