import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { ADVANCE, KEY } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import { Stack, alpha } from '@mui/system';
// ----------------------------------------------------------------------

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();

  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(false);

  const modulesSchema = Yup.object().shape({
    title: Yup.string().required('Name is required'),
    status: Yup.boolean().required('Status is required'),
    path: Yup.string()
      .required('Path is required')
      .matches(/^[^/]+$/, 'Field cannot contain "/"'),
    moduleId: Yup.string().nullable().required('ModuleId is required'),
  });

  console.log(row);
  const values = useMemo(
    () => ({
      title: row?.title || '',
      status: row?.status,
      showOnMenu: row?.showOnMenu,
      businessId: row?.businessId == null ? 'null' : row?.businessId,
      icon: row?.icon || '',
      path: row?.path || '',
      component: row?.component || null,
      order: row?.order || null,
      moduleId: row?.moduleId == null ? 'null' : row?.moduleId,
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(modulesSchema),
    values,
  });

  const {
    watch,
    reset,
    trigger,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const value = watch();

  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      let URL = 'modules/';
      if (payload.moduleId === 'null') {
        payload.moduleId = null;
      }
      if (payload.businessId === 'null') {
        payload.businessId = null;
      }
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  });

  const moduleData = useCallback(async (id) => {
    let URLModules = 'modules/';
    URLModules += `?page=${1}&limit=${20000}&moduleId=${id}&`;
    const { data } = await fetcher(URLModules);
    // eslint-disable-next-line eqeqeq
    const mData = data?.rows?.filter((x) => x.businessId == id);
    setModules(mData);
  }, []);
  
    useEffect(() => {
      moduleData();
    }, [moduleData]);


  useEffect(() => {
    moduleData(value.businessId);
  }, [moduleData, value.businessId]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      reset();
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods}>
        <Grid item xs={12} md={12}>
          <Toolbar>
            <Typography variant="h5" sx={{ flex: 1 }}>
              {row ? 'Update Module' : 'Add Module'}
            </Typography>

            <IconButton
              color="inherit"
              edge="start"
              onClick={() => {
                onClose();
                reset();
              }}
            >
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>

        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            pt={3}
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField name="title" label="Title" size="small" autoFocus isCamalCase="true" />
            <RHFTextField name="icon" label="Icon Name" size="small" isCamalCase="true" />
            <RHFTextField name="path" label="Path" size="small" isCamalCase="true" />
            <RHFTextField name="component" label="Component" size="small" isCamalCase="true" />
            <RHFTextField
              type="number"
              name="order"
              label="Order"
              size="small"
              isCamalCase="true"
            />
            <Grid sx={{ display: 'flex' }}>
              <RHFCheckbox name="status" label="Active" size="small" />
              <RHFCheckbox name="showOnMenu" label="Show on Menu" size="small" />
            </Grid>
            <RHFSelect name="businessId" label="Businesse" size="small">
              <MenuItem value="null">None</MenuItem>
              {ADVANCE.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </RHFSelect>
            <RHFSelect name="moduleId" label="Select Module" size="small">
              <MenuItem value="null">None</MenuItem>
              {modules &&
                modules
                  .map((x) => ({ label: x.title, value: x.id }))
                  .map((x) => (
                    <MenuItem key={x.value} value={x.value}>
                      {x.label}
                    </MenuItem>
                  ))}
            </RHFSelect>
            {/* <Autocomplete
              autoHighlight
              fullWidth
              freeSolo
              size="small"
              options={modules.map((option) => option.title)}
              getOptionLabel={(option) => option}
              openOnFocus
              renderInput={(params) => (
                <TextField {...params} name="moduleId" label="Select Module" size="small" />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
            /> */}

            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }} /> */}
          </Box>
        </DialogContent>

        <Stack
          direction="row-reverse"
          alignItems="center"
          sx={{
            bottom: 0,
            zIndex: 111111,
            p: 3,
            position: 'sticky',
            width: '100%',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
          id="modelfooter"
        >
          <Tooltip title="(Alt + A)" arrow>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={async () => {
                if (await trigger()) {
                  onSubmit();
                }
              }}
            >
              {row ? 'Update' : 'Add'}
            </LoadingButton>
          </Tooltip>

          <Tooltip title="(Esc)" arrow>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
          </Tooltip>

          <Button
            variant="soft"
            onClick={() => {
              reset(values);
            }}
            sx={{ mr: 2 }}
          >
            Reset
          </Button>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
