import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { GENDER, KEY, PURCHASE_TYPE } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Paper,
  TableFooter,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import CompanyYear from 'src/layouts/_common/company-year';
import { Stack } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import {
  assignNullToEmptyOrUndefinedValues,
  useBgColorAutocomplete,
} from 'src/_mock/constant_funcation';
import { AuthContext } from 'src/auth/context/jwt';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { useBoolean } from 'src/hooks/use-boolean';
import AUserQuickEditForm from 'src/sections/automobile-accounts/account/quick-edit-form';

export default function ProductCategoryQuickEditForm({
  row,
  open,
  onClose,
  table,
  getAll,
  tableData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [goDownData, setgoDownData] = useState([]);
  const [accountsList, setAccountsList] = useState([]);
  const [productVariantData, setProductVariantData] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [colourData, setColourData] = useState([]);

  const [accountEditList, setaccountEditList] = useState({});

  const { user } = useContext(AuthContext);

  const quickEditAccount = useBoolean();

  const [partyData, setPartyData] = useState(null);

  const bgColorAutocomplete = useBgColorAutocomplete();

  const tableBodyRef = useRef(null);

  const VehiclePurchaseSchema = Yup.object().shape({
    invNo: Yup.string().required('Invoice No is required'),
    invDate: Yup.date().required('Invoice Date is required'),
    partyId: Yup.number().required('Party is required'),
    purchaseType: Yup.number().required('Purchase Type is required'),
  });

  const values = useMemo(
    () => ({
      docNo: row?.docNo || null,
      docDate: row?.docDate || new Date(),
      invNo: row?.invNo || null,
      invDate: row?.invDate || new Date(),
      partyId: row?.partyId || null,
      purchaseType: row?.purchaseType || null,
      truckNo: row?.truckNo || null,
      transportName: row?.transportName || null,
      receivedDate: row?.receivedDate || '',
      godownId: row?.godownId || null,
      inTras: !row?.receivedDate,
      stocks:
        row?.stocks?.map((data) => ({
          id: data?.id || null,
          avariantId: data?.avariantId || null,
          pname: data?.pname || null,
          qty: data?.qty || null,
          rate: data?.rate || null,
          amount: data?.amount || null,
          discount: data?.discount || null,
          gst: data?.gst || null,
          netAmount: data?.netAmount || null,
        })) || [],
      stockdetails:
        row?.stockdetails?.map((xdata) => ({
          id: xdata?.id || null,
          pname: xdata?.avariant?.name || null,
          avariantId: xdata?.avariantId || null,
          rate: xdata?.rate || null,
          chassisNo: xdata?.chassisNo || '',
          enginNo: xdata?.enginNo || '',
          acolourId: xdata?.acolourId || '',
          keyNo: xdata?.keyNo || '',
          batteryNo: xdata?.batteryNo || '',
        })) ||
        [...Array(5)].map(() => ({
          id: null,
          pname: null,
          avariantId: null,
          rate: null,
          chassisNo: '',
          enginNo: '',
          acolourId: '',
          keyNo: '',
          batteryNo: '',
        })),
      taxation: row?.taxation || {
        netAmount: null,
        insurance: null,
        cgst: null,
        sgst: null,
        igst: null,
        tcs: null,
        discount: null,
        totalAmount: null,
      },
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(VehiclePurchaseSchema),
    values,
    mode: 'onChange',
  });

  const {
    reset,
    watch,
    control,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const GST_NUMBER = user?.companies?.find((x) => x.id === user?.companyId)?.gst_no;
  const GST_Company = GST_NUMBER?.substring(0, 2);
  const GST_PARTY = partyData?.gst_no?.substring(0, 2);

  useEffect(() => {
    if (GST_PARTY === undefined) {
      setValue('purchaseType', null);
    } else if (GST_Company === GST_PARTY) {
      setValue('purchaseType', 1);
    } else if (GST_Company !== GST_PARTY) {
      setValue('purchaseType', 2);
    }
  }, [GST_Company, GST_PARTY, setValue, allValues.partyId]);

  useEffect(() => {
    if (allValues.receivedDate === null) {
      setValue('inTras', true);
    } else {
      setValue('inTras', false);
    }
  }, [allValues.receivedDate, setValue]);

  const stocks = useFieldArray({
    control,
    name: 'stocks',
  });
  const stockdetails = useFieldArray({
    control,
    name: 'stockdetails',
  });

  const handleAdd = useCallback(() => {
    stockdetails?.append({
      id: null,
      pname: null,
      avariantId: null,
      rate: null,
      chassisNo: '',
      enginNo: '',
      acolourId: '',
      keyNo: '',
      batteryNo: '',
    });
    // Scroll to the bottom of the table body
    setTimeout(() => {
      tableBodyRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, 100);
  }, [stockdetails]);

  const handleRemoveStockDetail = useCallback(
    (index) => {
      stockdetails.remove(index);
    },
    [stockdetails]
  );

  const goDownList = useCallback(async () => {
    try {
      let URL = 'automobile/godowns';
      URL += `?page=${1}&limit=${10000}&status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setgoDownData(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const partyAccountList = useCallback(async () => {
    try {
      let URL = 'automobile/accounts/';
      URL += `?page=${1}&limit=${10000}&status=true&ac_groupId=46&`;
      const { data } = await fetcher(URL);
      if (data) {
        setAccountsList(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const productVariantList = useCallback(async () => {
    try {
      let URL = 'automobile/product/variants/';
      URL += `?page=${1}&limit=${10000}&status=true&ac_groupId=46&`;
      const { data } = await fetcher(URL);
      if (data) {
        setProductVariantData(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const groupByCode = allValues.stockdetails.reduce((acc, item) => {
      if (item.avariantId) {
        if (!acc[item.avariantId]) {
          acc[item.avariantId] = {
            id: null,
            avariantId: item.avariantId,
            pname: item.pname,
            qty: 0,
            totalrate: 0,
            rate: item.rate,
            amount: item.amount,
            discount: item.discount,
            gst: item.gst,
            netAmount: item.netAmount,
          };
        }
        acc[item.avariantId].qty += 1; // Increment the quantity for each occurrence
        acc[item.avariantId].totalrate += item.rate; // Increment the quantity for each occurrence
      }
      return acc;
    }, {});
    // Convert the grouped items into an array and update the stocks state
    const newStocks = Object.values(groupByCode).map((stock) => ({
      ...stock,
      rate: stock.totalrate / stock.qty,
      amount: (stock.totalrate / stock.qty) * stock.qty, // Calculate the total amount based on rate and quantity
      discount: allValues.stocks.find((x) => x.avariantId === stock.avariantId)?.discount || 0,
      gst: allValues.stocks.find((x) => x.avariantId === stock.avariantId)?.gst || 0,
      netAmount:
        (stock.totalrate / stock.qty) * stock.qty -
        (allValues.stocks.find((x) => x.avariantId === stock.avariantId)?.discount || 0) +
        (stock.gst || 0),
    }));

    if (JSON.stringify(newStocks) !== JSON.stringify(allValues.stocks)) {
      setValue('stocks', newStocks);
    }
  }, [allValues.stockdetails, allValues.stocks, setValue, stockdetails]);

  useEffect(() => {
    // Calculate Total Net Amount
    const totalNetAmount = allValues.stocks.reduce((acc, item) => {
      const itemTotal = Number(item.rate) * Number(item.qty) - (Number(item.discount) || 0);
      return acc + itemTotal;
    }, 0);

    // Initialize tax variables
    let cgst = 0;
    let sgst = 0;
    let igst = 0;

    const GST = (totalNetAmount / 100) * 28;

    // Calculate GST based on purchase type and GST rate in each stock item
    allValues.stocks.forEach(() => {
      if (allValues?.purchaseType === 1) {
        // Local purchase
        cgst = GST / 2;
        sgst = GST / 2;
      } else if (allValues?.purchaseType === 2) {
        // Interstate purchase
        igst = GST;
      }
    });

    // Set the tax values
    setValue('taxation.cgst', cgst?.toFixed(2));
    setValue('taxation.sgst', sgst?.toFixed(2));
    setValue('taxation.igst', igst?.toFixed(2));

    // Set Net Amount and Total Amount
    setValue('taxation.netAmount', totalNetAmount);
    const totalAmount =
      totalNetAmount +
      cgst +
      sgst +
      igst +
      (allValues?.taxation?.insurance || 0) +
      (allValues?.taxation?.tcs || 0) -
      (allValues?.taxation?.discount || 0);

    setValue('taxation.totalAmount', totalAmount?.toFixed(2));
  }, [
    allValues.stocks,
    setValue,
    allValues?.purchaseType,
    allValues?.taxation?.discount,
    allValues?.taxation?.insurance,
    allValues?.taxation?.tcs,
  ]);

  const productList = useCallback(
    async (code, index) => {
      const URL = `automobile/product/variants?id=${code}&page=1&limit=10000&status=true`;
      try {
        if (code === '') {
          setValue(`stockdetails[${index}]`, {
            ...allValues.stockdetails[index],
            pname: '',
            avariantId: null,
          });
          return;
        }

        const { data } = await fetcher(URL);
        if (data?.rows?.length) {
          const product = data.rows[0];
          if (product)
            setValue(`stockdetails[${index}]`, {
              ...allValues.stockdetails[index],
              pname: product.name,
              avariantId: product.id,
            });
        } else {
          setValue(`stockdetails[${index}]`, {
            ...allValues.stockdetails[index],
            pname: '',
            avariantId: null,
          });
          enqueueSnackbar('Product not Available!', { variant: 'info' });
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Failed to fetch product details!', { variant: 'error' });
      }
    },
    [setValue, allValues.stockdetails, enqueueSnackbar]
  );

  const onSubmit = handleSubmit(async (payload) => {
    console.log('AMIDEEEP ');
    payload.stockdetails = payload.stockdetails.filter((x) => x.avariantId !== null);
    payload = assignNullToEmptyOrUndefinedValues(payload);

    setLoading(true);
    try {
      let URL = '/automobile/vehiclepurchase/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      onClose();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      setLoading(false);
      console.error(error);
    }
  });

  const colourDataList = useCallback(async () => {
    try {
      let URL = 'automobile/product/colours/';
      URL += `?page=${1}&limit=${10000}&status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setColourData(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    reset();
    goDownList();
    partyAccountList();
    productVariantList();
    colourDataList();
  }, [goDownList, partyAccountList, productVariantList, open, reset, colourDataList]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      console.log('AMIDEEP 1');
      if ((await trigger()) && !loading && open) {
        console.log('AMIDEEP 2');
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <>
      <Dialog fullScreen open={open} onKeyDown={handleKeyDown}>
        <FormProvider methods={methods}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                top: 0,
                zIndex: 9,
                position: 'sticky',
                bgcolor: (theme) => alpha(theme.palette.background.default, 1),
              }}
            >
              <Toolbar>
                <Typography variant="h5" sx={{ flex: 1 }}>
                  {row ? 'Update Vehicle Purchase' : 'Add Vehicle Purchase'}
                </Typography>
                <IconButton color="inherit" edge="start" onClick={onClose}>
                  <Iconify icon="mingcute:close-line" />
                </IconButton>
              </Toolbar>
              <Divider sx={{ borderStyle: 'dashed' }} />
            </Grid>

            <DialogContent sx={{ p: 2 }}>
              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={8}>
                <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField
                      name="docNo"
                      label="Document No."
                      size="small"
                      error={errors?.docNo && !allValues?.docNo}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Controller
                      name="docDate"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DatePicker
                          defaultValue={new Date(field.value)}
                          timezone="Asia/Kolkata"
                          label="Document Date"
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          error={errors?.docDate && !allValues?.docDate}
                          format="dd/MM/yyyy"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!error,
                              helperText: error?.message,
                              size: 'small',
                            },
                          }}
                          autoFocus
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <RHFTextField
                      name="invNo"
                      label={
                        <span>
                          Invoice No. <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      size="small"
                      error={errors?.invNo && !allValues?.invNo}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Controller
                      name="invDate"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DatePicker
                          defaultValue={new Date(field.value)}
                          timezone="Asia/Kolkata"
                          label="Invoice Date"
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          error={errors?.invDate && !allValues?.invDate}
                          format="dd/MM/yyyy"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!error,
                              helperText: error?.message,
                              size: 'small',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      size="small"
                      options={
                        accountsList.length ? accountsList : [{ name: 'No Options', value: null }]
                      }
                      groupBy={(option) => option?.a}
                      getOptionLabel={(option) => option.name}
                      openOnFocus
                      onChange={(event, newValue) => {
                        setValue('partyId', newValue?.id);
                        setaccountEditList(newValue);
                        setPartyData(newValue);
                      }}
                      onHighlightChange={(event, newValue) => {
                        setaccountEditList(newValue);
                      }}
                      value={(() => {
                        const addRecord = accountsList?.find(
                          (data) => data.id === allValues?.partyId
                        );
                        const editRecord = row?.ac_group;

                        if (addRecord) return addRecord;
                        if (editRecord) return editRecord;
                        return null;
                      })()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              Select Party <span style={{ color: 'red' }}>*</span>
                            </span>
                          }
                          name="partyId"
                          error={errors?.partyId && !allValues?.partyId}
                        />
                      )}
                      renderGroup={(params) => (
                        <li key={params.key}>
                          <GroupHeader component="li">
                            <ListItem sx={{ padding: 0, margin: 0 }}>Account Name</ListItem>
                            <ListItem
                              sx={{ padding: 0, margin: 0, justifyContent: 'end', width: '30%' }}
                            >
                              Balance
                            </ListItem>
                          </GroupHeader>
                          <GroupItems>{params.children}</GroupItems>
                          <GroupFooter component="li" sx={{ display: 'block' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                bgcolor: (theme) => alpha(theme.palette.primary.lighter, 1),
                              }}
                            >
                              <Button
                                onClick={() => {
                                  setaccountEditList(null);
                                  quickEditAccount.onTrue();
                                }}
                              >
                                Add
                              </Button>
                              {accountEditList?.id && (
                                <Button
                                  onClick={() => {
                                    quickEditAccount.onTrue();
                                  }}
                                >
                                  Edit
                                </Button>
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                color: 'black',
                                px: 2,
                                bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
                              }}
                            >
                              <Grid container spacing={1}>
                                <Grid item xs={7} md={7}>
                                  {accountEditList?.address && (
                                    <>
                                      <Typography variant="body2">
                                        <strong>Address</strong>
                                      </Typography>
                                      <Typography variant="body2">
                                        {accountEditList?.address || '-'}, <br />
                                        {accountEditList?.district &&
                                          `${accountEditList?.district}, `}
                                        {accountEditList?.city?.name &&
                                          `${accountEditList?.city?.name}, `}
                                        {accountEditList?.state?.name &&
                                          `${accountEditList?.state?.name}, `}
                                        {accountEditList?.country?.name &&
                                          `${accountEditList?.country?.name}.`}
                                      </Typography>
                                    </>
                                  )}
                                </Grid>
                                <Grid item xs={5} md={5} sx={{ textAlign: 'left' }}>
                                  {accountEditList?.mobile && (
                                    <Typography variant="body2">
                                      Mobile No. {accountEditList?.mobile || '-'}
                                    </Typography>
                                  )}

                                  {accountEditList?.gst_no && (
                                    <Typography variant="body2">
                                      GST No. {accountEditList?.gst_no || '-'}
                                    </Typography>
                                  )}

                                  {accountEditList?.pan_no && (
                                    <Typography variant="body2">
                                      PAN No. {accountEditList?.pan_no || '-'}
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          </GroupFooter>
                        </li>
                      )}
                      componentsProps={{
                        paper: {
                          sx: {
                            width: 600,
                          },
                        },
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                          <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                            {option?.name}
                          </ListItem>
                        </Box>
                      )}
                      ListboxProps={{
                        ...bgColorAutocomplete,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      size="small"
                      options={
                        PURCHASE_TYPE.length ? PURCHASE_TYPE : [{ name: 'No Options', value: null }]
                      }
                      groupBy={(option) => option?.a}
                      getOptionLabel={(option) => option.name}
                      openOnFocus
                      onChange={(event, newValue) => {
                        setValue('purchaseType', newValue?.value);
                      }}
                      value={(() => {
                        const addRecord = PURCHASE_TYPE?.find(
                          (data) => data.value === allValues?.purchaseType
                        );
                        const editRecord = row?.ac_group;

                        if (addRecord) return addRecord;
                        if (editRecord) return editRecord;
                        return null;
                      })()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              Purchase Type <span style={{ color: 'red' }}>*</span>
                            </span>
                          }
                          name="purchaseType"
                          error={errors?.purchaseType && !allValues?.purchaseType}
                          disabled
                        />
                      )}
                      disabled
                      renderOption={(props, option) => (
                        <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                          <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                            {option?.name}
                          </ListItem>
                        </Box>
                      )}
                      ListboxProps={{
                        ...bgColorAutocomplete,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      size="small"
                      options={
                        goDownData.length ? goDownData : [{ name: 'No Options', value: null }]
                      }
                      groupBy={(option) => option?.a}
                      getOptionLabel={(option) => option.name}
                      openOnFocus
                      onChange={(event, newValue) => {
                        setValue('godownId', newValue?.id);
                      }}
                      value={(() => {
                        const addRecord = goDownData?.find(
                          (data) => data.id === allValues?.godownId
                        );
                        const editRecord = row?.godown;

                        if (addRecord) return addRecord;
                        if (editRecord) return editRecord;
                        return null;
                      })()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Location"
                          name="godownId"
                          error={errors?.godownId && !allValues?.godownId}
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                          <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                            {option?.name}
                          </ListItem>
                        </Box>
                      )}
                      ListboxProps={{
                        ...bgColorAutocomplete,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <RHFTextField name="transportName" label="Transport Name" size="small" />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                    <RHFTextField name="truckNo" label="Truck No." size="small" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5} xxl={3.5}>
                    <RHFCheckbox name="inTras" label="In Transit" size="small" disabled />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4.5} lg={4.5} xl={4.5} xxl={4.5}>
                    <Controller
                      name="receivedDate"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DatePicker
                          defaultValue={new Date(field.value)}
                          timezone="Asia/Kolkata"
                          label="Rec. Date"
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          format="dd/MM/yyyy"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!error,
                              helperText: error?.message,
                              size: 'small',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item xs={12} md={12} lg={12} xl={12} xxl={12} sx={{ mt: 1 }}>
                <TableContainer
                  component={Paper}
                  sx={{
                    overflow: 'auto',
                    maxHeight: '30vh',
                    minHeight: '30vh',
                    width: '100%',
                    margin: 'auto',
                  }}
                >
                  <Table
                    stickyHeader
                    aria-label="simple table"
                    sx={{
                      '& .MuiTableCell-sizeMedium': {
                        padding: '0px !important',
                      },
                      '& fieldset': { borderRadius: '0px', border: 'none' },
                      '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                      '& .MuiOutlinedInput-root': { padding: '0px !important' },
                      '& .autoComplete>div': { padding: '0px !important' },
                      '& .MuiTableCell-root': {
                        border: '0.01px solid #e9ecee',
                      },
                      '& .MuiTableCell-head': {
                        padding: '0px !important',
                        borderRight: '1px solid #d0d1d2 !important',
                      },
                      '& .MuiTableCell-footer': {
                        padding: '0px !important',
                      },
                      '& .MuiTableCell-head:last-child': {
                        borderRight: '0px !important',
                      },
                      '&:last-child td': {
                        borderColor: '#e9ecee !important',
                      },
                    }}
                  >
                    <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
                      <TableRow>
                        <TableCell sx={{ width: 20, textAlign: 'center' }}>sr.</TableCell>
                        <TableCell sx={{ width: 40, textAlign: 'center' }}>Code</TableCell>
                        <TableCell sx={{ width: 400, textAlign: 'center' }}>Product Name</TableCell>
                        <TableCell sx={{ width: 80, textAlign: 'center' }}>Rate</TableCell>
                        <TableCell sx={{ width: 140, textAlign: 'center' }}>Chassis No.</TableCell>
                        <TableCell sx={{ width: 140, textAlign: 'center' }}>Engine No.</TableCell>
                        <TableCell sx={{ width: 140, textAlign: 'center' }}>Colour Name</TableCell>
                        <TableCell sx={{ width: 70, textAlign: 'center' }}>Key No.</TableCell>
                        <TableCell sx={{ width: 120, textAlign: 'center' }}>Bettry No.</TableCell>
                        <TableCell
                          sx={{ width: 10, textAlign: 'center', position: 'sticky', right: 0 }}
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stockdetails?.fields.map((xdata, index) => (
                        <TableRow
                          key={xdata.id}
                          sx={{
                            textAlign: 'center',
                            position: 'sticky',
                            left: 0,
                          }}
                        >
                          <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`stockdetails[${index}].avariantId`}
                              size="small"
                              fullWidth
                              variant="outlined"
                              type="number"
                              onChange={(e) => {
                                productList(e.target.value, index);
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Autocomplete
                              autoHighlight
                              fullWidth
                              size="small"
                              options={
                                productVariantData?.length
                                  ? productVariantData
                                  : [{ name: 'No Options', value: null }]
                              }
                              groupBy={(option) => option?.a}
                              getOptionLabel={(option) => option.name}
                              openOnFocus
                              onChange={(event, newValue) => {
                                setValue(`stockdetails[${index}].avariantId`, newValue?.id);
                                setValue(`stockdetails[${index}].pname`, newValue?.name);
                              }}
                              value={(() => {
                                const addRecord = productVariantData?.find(
                                  (data) => data.id === allValues?.stockdetails[index]?.avariantId
                                );
                                const editRecord = row?.ac_group;

                                if (addRecord) return addRecord;
                                if (editRecord) return editRecord;
                                return null;
                              })()}
                              renderInput={(params) => (
                                <TextField {...params} name={`stockdetails[${index}].pname`} />
                              )}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem
                                      sx={{
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      Code
                                    </ListItem>
                                    <ListItem
                                      sx={{
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      Product Variant
                                    </ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                </li>
                              )}
                              componentsProps={{
                                paper: {
                                  sx: {
                                    width: 600,
                                  },
                                },
                              }}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  {...props}
                                  sx={{ minHeight: 'auto !important' }}
                                >
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.id}
                                  </ListItem>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                              ListboxProps={{
                                ...bgColorAutocomplete,
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`stockdetails[${index}].rate`}
                              size="small"
                              fullWidth
                              variant="outlined"
                              type="number"
                            />
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`stockdetails[${index}].chassisNo`}
                              size="small"
                              fullWidth
                              variant="outlined"
                            />
                          </TableCell>

                          <TableCell>
                            <RHFTextField
                              name={`stockdetails[${index}].enginNo`}
                              size="small"
                              fullWidth
                              variant="outlined"
                            />
                          </TableCell>
                          <TableCell>
                            <Autocomplete
                              autoHighlight
                              fullWidth
                              size="small"
                              options={
                                colourData.length
                                  ? colourData
                                  : [{ name: 'No Options', value: null }]
                              }
                              groupBy={(option) => option?.a}
                              getOptionLabel={(option) => option.name}
                              openOnFocus
                              onChange={(event, newValue) => {
                                setValue(`stockdetails[${index}].acolourId`, newValue?.id);
                              }}
                              value={(() => {
                                const addRecord = colourData?.find(
                                  (data) => data.id === allValues?.stockdetails[index]?.acolourId
                                );
                                const editRecord = row?.ac_group;

                                if (addRecord) return addRecord;
                                if (editRecord) return editRecord;
                                return null;
                              })()}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`stockdetails[${index}].acolourId`}
                                  error={
                                    errors?.acolourId && !allValues?.stockdetails[index]?.acolourId
                                  }
                                />
                              )}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  {...props}
                                  sx={{ minHeight: 'auto !important' }}
                                >
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.id}
                                  </ListItem>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem
                                      sx={{
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      Code
                                    </ListItem>
                                    <ListItem
                                      sx={{
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      Colour Name
                                    </ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                </li>
                              )}
                              componentsProps={{
                                paper: {
                                  sx: {
                                    width: 400,
                                  },
                                },
                              }}
                              ListboxProps={{
                                ...bgColorAutocomplete,
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`stockdetails[${index}].keyNo`}
                              size="small"
                              fullWidth
                              variant="outlined"
                            />
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={`stockdetails[${index}].batteryNo`}
                              size="small"
                              fullWidth
                              variant="outlined"
                            />
                          </TableCell>

                          <TableCell
                            sx={{
                              textAlign: 'center',
                              position: 'sticky',
                              right: 0,
                              index: 9,
                              cursor: 'pointer',
                            }}
                            onClick={() => handleRemoveStockDetail(index)}
                          >
                            <Iconify icon="solar:trash-bin-trash-bold" color="red" />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item container xs={12} md={12} lg={12} xl={12} xxl={12} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                  <TableContainer
                    component={Paper}
                    sx={{ overflow: 'auto', maxHeight: '30vh', width: '100%', margin: 'auto' }}
                  >
                    <Table
                      stickyHeader
                      aria-label="simple table"
                      sx={{
                        '& .MuiTableCell-sizeMedium': {
                          padding: '0px !important',
                        },
                        '& fieldset': { borderRadius: '0px', border: 'none' },
                        '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                        '& .MuiOutlinedInput-root': { padding: '0px !important' },
                        '& .autoComplete>div': { padding: '0px !important' },
                        '& .MuiTableCell-root': {
                          border: '0.01px solid #e9ecee',
                        },
                        '& .MuiTableCell-head': {
                          padding: '0px !important',
                          borderRight: '1px solid #d0d1d2 !important',
                        },
                        '& .MuiTableCell-footer': {
                          padding: '0px !important',
                        },
                        '& .MuiTableCell-head:last-child': {
                          borderRight: '0px !important',
                        },
                        '&:last-child td': {
                          borderColor: '#e9ecee !important',
                        },
                      }}
                    >
                      <TableHead sx={{ position: 'sticky', zIndex: '11111' }}>
                        <TableRow>
                          <TableCell sx={{ width: 20, textAlign: 'center' }}>sr.</TableCell>
                          <TableCell sx={{ width: 40, textAlign: 'center' }}>Code</TableCell>
                          <TableCell sx={{ width: 400, textAlign: 'center' }}>
                            Product Name
                          </TableCell>
                          <TableCell sx={{ width: 80, textAlign: 'center' }}>Spcode</TableCell>
                          <TableCell sx={{ width: 140, textAlign: 'center' }}>Qty</TableCell>
                          <TableCell sx={{ width: 140, textAlign: 'center' }}>Rate</TableCell>
                          <TableCell sx={{ width: 140, textAlign: 'center' }}>Amount</TableCell>
                          <TableCell sx={{ width: 120, textAlign: 'center' }}>Disc. Amt</TableCell>
                          <TableCell sx={{ width: 120, textAlign: 'center' }}>GST(%)</TableCell>
                          <TableCell sx={{ width: 120, textAlign: 'center' }}>Net Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {stocks?.fields.map((item, index) => (
                          <TableRow key={item.id}>
                            <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                            <TableCell>
                              <RHFTextField
                                name={`stocks[${index}].avariantId`}
                                size="small"
                                fullWidth
                                variant="outlined"
                                type="number"
                                disabled
                              />
                            </TableCell>
                            <TableCell>
                              <RHFTextField
                                name={`stocks[${index}].pname`}
                                size="small"
                                fullWidth
                                variant="outlined"
                                disabled
                              />
                            </TableCell>
                            <TableCell>
                              <RHFTextField
                                name={`stocks[${index}].spcode`}
                                size="small"
                                fullWidth
                                variant="outlined"
                              />
                            </TableCell>
                            <TableCell>
                              <RHFTextField
                                name={`stocks[${index}].qty`}
                                size="small"
                                fullWidth
                                variant="outlined"
                                type="number"
                                disabled
                              />
                            </TableCell>
                            <TableCell>
                              <RHFTextField
                                name={`stocks[${index}].rate`}
                                size="small"
                                fullWidth
                                variant="outlined"
                                type="number"
                              />
                            </TableCell>
                            <TableCell>
                              <RHFTextField
                                name={`stocks[${index}].amount`}
                                size="small"
                                fullWidth
                                variant="outlined"
                                type="number"
                              />
                            </TableCell>
                            <TableCell>
                              <RHFTextField
                                name={`stocks[${index}].discount`}
                                size="small"
                                fullWidth
                                variant="outlined"
                                type="number"
                              />
                            </TableCell>
                            <TableCell>
                              <RHFTextField
                                name={`stocks[${index}].gst`}
                                size="small"
                                fullWidth
                                variant="outlined"
                                type="number"
                              />
                            </TableCell>

                            <TableCell>
                              <RHFTextField
                                name={`stocks[${index}].netAmount`}
                                size="small"
                                fullWidth
                                variant="outlined"
                                type="number"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} px={6}>
                  <Table
                    size="small"
                    sx={{
                      '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                      '& .MuiTableCell-root': {
                        padding: '1px !important',
                      },
                    }}
                  >
                    {/* Adjusted for smaller size */}
                    <TableBody>
                      {[
                        { label: 'Net Amount', name: 'taxation.netAmount' },
                        { label: 'Insurance', name: 'taxation.insurance' },
                        { label: 'CGST', name: 'taxation.cgst' },
                        { label: 'SGST', name: 'taxation.sgst' },
                        { label: 'IGST', name: 'taxation.igst' },
                        { label: 'TCS', name: 'taxation.tcs' },
                        { label: 'Discount', name: 'taxation.discount' },
                        { label: 'Total Amount', name: 'taxation.totalAmount' },
                      ].map((field, index) => (
                        <TableRow key={field.name}>
                          <TableCell>
                            <Typography variant="body2">{field.label}</Typography>
                          </TableCell>
                          <TableCell>
                            <RHFTextField
                              name={field.name}
                              size="small"
                              variant="standard"
                              fullWidth
                              type="number"
                            />
                          </TableCell>
                          {index === 7 && (
                            <TableRow>
                              <TableCell>
                                <Divider />
                              </TableCell>
                            </TableRow>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>

          <Stack
            direction="row-reverse"
            alignItems="center"
            sx={{
              bottom: 0,
              zIndex: 111111,
              p: 2,
              position: 'fixed',
              justifyContent: 'space-between',
              width: '100%',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
            id="modelfooter"
          >
            <Tooltip title="(Alt + A)" arrow>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={async () => {
                  console.log(errors, 'Error : --------');
                  if (await trigger()) {
                    onSubmit();
                  }
                }}
              >
                {row ? 'Update' : 'Add'}
              </LoadingButton>
            </Tooltip>

            <Tooltip title="(Esc)" arrow>
              <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
                Cancel
              </Button>
            </Tooltip>

            <Button
              variant="soft"
              onClick={() => {
                reset(values);
              }}
              sx={{ mr: 2 }}
            >
              Reset
            </Button>

            <Stack direction="row" alignItems="center" flexGrow={1} spacing={2}>
              <Tooltip title="Add Product" arrow>
                <IconButton color="primary" onClick={handleAdd}>
                  <Iconify icon="mingcute:add-line" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </FormProvider>
      </Dialog>
      {quickEditAccount && (
        <AUserQuickEditForm
          row={null || accountEditList}
          open={quickEditAccount.value}
          onClose={() => {
            quickEditAccount.onFalse();
            partyAccountList();
          }}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
    </>
  );
}

ProductCategoryQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
