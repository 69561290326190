import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import {
  BALANCE_TYPE,
  BANK_CASH,
  BANK_R_P,
  CITY,
  KEY,
  MODULE_STATUS,
  OPP_AC,
  RCPT_PYMT,
  STATE,
  TITLES,
} from 'src/_mock/constant';
import {
  Autocomplete,
  Card,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { alpha, spacing } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import { useEventListener } from 'src/hooks/use-event-listener';
import { DataGrid } from '@mui/x-data-grid';
// ----------------------------------------------------------------------

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();

  const [cashNotesList, setCashNotesList] = useState([]);
  const [accountList, setAccountList] = useState([]);

  const dialog = useBoolean();

  const departmentSchema = Yup.object().shape({
    v_no: Yup.number().nullable().min(0),
    amount: Yup.number().nullable().min(0),
    narr: Yup.string().required('Narration is required'),
  });

  const values = useMemo(
    () => ({
      v_no: row?.v_no || null,
      f_ac: row?.f_ac || null,
      s_ac: row?.s_ac || null,
      rp: row?.rp || 2,
      amount: row?.amount || null,
      dt: row?.dt || new Date(),
      narr: row?.narr || '',
      ce_details: [],
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(departmentSchema),
    values,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { isSubmitting },
  } = methods;

  const allValues = watch();

  const onSubmit = handleSubmit(async (payload) => {
    try {
      let URL = 'cbentries/';
      const cDetails = payload.ce_details.filter((x) => x.count > 0);
      payload = { ...payload, t_type: 2, ce_details: cDetails };
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };
  useEventListener('keydown', handleKeyDown);

  const ce_details = useFieldArray({
    control,
    name: 'ce_details',
  });

  const HandleCashNoteList = useCallback(async () => {
    let URL = 'cnotes/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setCashNotesList(data?.rows);
  }, []);

  useEffect(() => {
    HandleCashNoteList();
  }, [open, HandleCashNoteList]);

  useEffect(() => {
    const a = [];
    cashNotesList?.forEach((element) => {
      a.push(
        row
          ? {
              id: null,
              cnoteId: element.id,
              count: 0,
              rp: 1,
            }
          : {
              cnoteId: element.id,
              count: 0,
              rp: 1,
            }
      );
      a.push(
        row
          ? {
              id: null,
              cnoteId: element.id,
              count: 0,
              rp: 2,
            }
          : {
              cnoteId: element.id,
              count: 0,
              rp: 2,
            }
      );
    });
    row?.ce_details?.forEach((x) => {
      const f = a.findIndex((fi) => fi.rp === x.rp && fi.cnoteId === x.cnoteId);
      if (f >= 0) {
        a[f] = {
          id: x.id,
          count: x.count,
          rp: x.rp,
          cnoteId: x.cnoteId,
        };
      }
    });
    setValue('ce_details', a);
  }, [cashNotesList, setValue, row]);

  const HandleAccountList = async () => {
    let URL = 'accounts/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setAccountList(data?.rows);
  };

  useEffect(() => {
    HandleAccountList();
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 1120 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid
          containe
          sx={{
            top: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Grid item xs={12} md={12}>
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row
                  ? `Update Cash ${allValues.rp === 2 ? 'Receipt' : 'Payment'} `
                  : `Create  Cash ${allValues.rp === 2 ? 'Receipt' : 'Payment'} `}
              </Typography>

              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>

            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>
        </Grid>

        <Grid item container xs={12} md={12} sx={{ p: 3 }} columnSpacing={8} spacing={4}>
          <Grid item container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={accountList?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={accountList?.find((data) => data.id === allValues.f_ac)?.name || ''}
                onChange={(event, newValue) => {
                  setValue('f_ac', accountList?.find((data) => data.name === newValue)?.id);
                }}
                renderInput={(params) => (
                  <TextField {...params} name="f_ac" label="Bank A/C." autoFocus />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                display="flex"
                justifyContent="space-between"
              >
                <h5>Balance</h5>
                <h5>{accountList?.find((data) => data.id === allValues.f_ac)?.op_bal} CR</h5>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} spacing={1}>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              spacing={2}
              display="flex"
              justifyContent="end"
              alignItems="center"
              alignContent="start"
            >
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField name="v_no" label="Voucher No." size="small" />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <Controller
                  name="dt"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      defaultValue={new Date(field.value)}
                      timezone="Asia/Kolkata"
                      label="Voucher Date"
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      format="dd/MM/yyyy"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                          size: 'small',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <RHFSelect name="rp" label="Rcpt/Pymt" size="small">
                  {BANK_R_P.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={accountList?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={accountList?.find((data) => data.id === allValues.s_ac)?.name || ''}
                onChange={(event, newValue) => {
                  setValue('s_ac', accountList?.find((data) => data.name === newValue)?.id);
                }}
                renderInput={(params) => <TextField {...params} name="s_ac" label="Opp. A/c" />}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                display="flex"
                justifyContent="space-between"
              >
                <h5>Balance</h5>
                <h5>{accountList?.find((data) => data.id === allValues.s_ac)?.op_bal}CR</h5>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
            spacing={2}
            display="flex"
            justifyContent="end"
            alignItems="center"
            alignContent="start"
          >
            <Grid item xs={10.9} sm={10.9} md={5} lg={5} xl={5} xxl={5}>
              <RHFTextField
                name="amount"
                label="Cash Amount"
                size="small"
                onClick={() => {
                  dialog.onTrue();
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
              <Typography fontSize={14} variant="button" sx={{ flex: 1 }}>
                DR
              </Typography>
            </Grid>
          </Grid>

          <Grid item container xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} spacing={1}>
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={4} xxl={4}>
                <RHFTextField name="doc_no" label="Doc No." size="small" disabled />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={4} xxl={4}>
                <Controller
                  name="dt"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      disabled
                      defaultValue={new Date(field.value)}
                      timezone="Asia/Kolkata"
                      label="Doc/DD Date"
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      format="dd/MM/yyyy"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                          size: 'small',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={8} xl={8} xxl={8}>
                <RHFTextField name="doc_name" label="Doc Name" size="small" disabled />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
            spacing={1}
            justifyContent="end"
            alignItems="flex-start"
          >
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
              <RHFTextField name="narr" label="Narration" size="small" multiline rows={3} />
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={dialog.value}
          onClose={dialog.onTrue}
          PaperProps={{
            sx: { maxWidth: 820, maxHeight: 840 },
          }}
        >
          <Toolbar>
            <Typography variant="h6" sx={{ flex: 1 }}>
              Cash Payment
            </Typography>

            <IconButton color="inherit" edge="start" onClick={dialog.onFalse}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>

          <Divider sx={{ borderStyle: 'dashed' }} />
          <DialogContent>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                columnSpacing={4}
                rowSpacing={2}
                sx={{ mt: 2 }}
              >
                <Grid item container xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    spacing={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Iconify icon="formkit:dollar" sx={{ mr: 1 }} />
                    <Typography fontSize={16} fontWeight={600} sx={{ flex: 1 }}>
                      Payment Denomination
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Notes</TableCell>
                            <TableCell align="center">Count</TableCell>
                            <TableCell>Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ce_details?.fields.map((data, index) => {
                            const totalNotesAmount = cashNotesList.find(
                              (x) => x.id === data.cnoteId
                            )?.amount;

                            return data.rp === 1 ? (
                              <>
                                <TableRow
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell>{totalNotesAmount}</TableCell>
                                  <TableCell>
                                    <RHFTextField
                                      name={`ce_details[${index}].count`}
                                      size="small"
                                      type="notes"
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {(allValues?.ce_details[index]?.count || 0) *
                                      (totalNotesAmount || 0)}
                                  </TableCell>
                                </TableRow>
                              </>
                            ) : (
                              ''
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    display="flex"
                    justifyContent="start"
                    alignItems="center"
                  >
                    <Iconify icon="formkit:dollar" sx={{ mr: 1 }} />
                    <Typography fontSize={16} fontWeight={600} sx={{ flex: 1 }}>
                      Receive Denomination
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Notes</TableCell>
                            <TableCell align="center">Count</TableCell>
                            <TableCell>Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ce_details?.fields.map((data, index) => {
                            const totalNotesAmount = cashNotesList.find(
                              (x) => x.id === data.cnoteId
                            )?.amount;

                            return data.rp === 2 ? (
                              <>
                                <TableRow
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell>{totalNotesAmount}</TableCell>
                                  <TableCell>
                                    <RHFTextField
                                      name={`ce_details[${index}].count`}
                                      size="small"
                                      type="notes"
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {(allValues?.ce_details[index]?.count || 0) *
                                      (totalNotesAmount || 0)}
                                  </TableCell>
                                </TableRow>
                              </>
                            ) : (
                              ''
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    sx={{ mb: 1 }}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography fontSize={16} fontWeight={500} sx={{ flex: 1 }}>
                      Net Amount :
                    </Typography>
                    <Typography fontSize={20} fontWeight={700}>
                      ₹
                      {allValues.rp === 1 &&
                        allValues.ce_details
                          .filter((x) => x.rp === 1)
                          .reduce(
                            (pre, next) =>
                              pre +
                              next.count *
                                (cashNotesList.find((data) => data.id === next.cnoteId)?.amount ||
                                  0),
                            0
                          ) -
                          allValues.ce_details
                            .filter((x) => x.rp === 2)
                            .reduce(
                              (pre, next) =>
                                pre +
                                next.count *
                                  (cashNotesList.find((data) => data.id === next.cnoteId)?.amount ||
                                    0),
                              0
                            )}
                      {allValues.rp === 2 &&
                        allValues.ce_details
                          .filter((x) => x.rp === 2)
                          .reduce(
                            (pre, next) =>
                              pre +
                              next.count *
                                (cashNotesList.find((data) => data.id === next.cnoteId)?.amount ||
                                  0),
                            0
                          ) -
                          allValues.ce_details
                            .filter((x) => x.rp === 1)
                            .reduce(
                              (pre, next) =>
                                pre +
                                next.count *
                                  (cashNotesList.find((data) => data.id === next.cnoteId)?.amount ||
                                    0),
                              0
                            )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={dialog.onFalse} variant="outlined" color="inherit">
              Cancel
            </Button>
            <Button
              onClick={() => {
                dialog.onFalse();
                if (allValues.rp === 1) {
                  setValue(
                    'amount',
                    allValues.ce_details
                      .filter((x) => x.rp === 1)
                      .reduce(
                        (pre, next) =>
                          pre +
                          next.count *
                            (cashNotesList.find((data) => data.id === next.cnoteId)?.amount || 0),
                        0
                      ) -
                      allValues.ce_details
                        .filter((x) => x.rp === 2)
                        .reduce(
                          (pre, next) =>
                            pre +
                            next.count *
                              (cashNotesList.find((data) => data.id === next.cnoteId)?.amount || 0),
                          0
                        )
                  );
                } else {
                  setValue(
                    'amount',
                    allValues.ce_details
                      .filter((x) => x.rp === 2)
                      .reduce(
                        (pre, next) =>
                          pre +
                          next.count *
                            (cashNotesList.find((data) => data.id === next.cnoteId)?.amount || 0),
                        0
                      ) -
                      allValues.ce_details
                        .filter((x) => x.rp === 1)
                        .reduce(
                          (pre, next) =>
                            pre +
                            next.count *
                              (cashNotesList.find((data) => data.id === next.cnoteId)?.amount || 0),
                          0
                        )
                  );
                }
              }}
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <DialogActions
          sx={{
            bottom: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {row ? 'Update' : 'Add'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
