import PropTypes from 'prop-types';
import { m } from 'framer-motion';

// @mui
import Typography from '@mui/material/Typography';
// assets
import { SeverErrorIllustration } from 'src/assets/illustrations';
// components
import { MotionContainer, varBounce } from 'src/components/animate';
import { Button } from '@mui/material';

// ----------------------------------------------------------------------

export default function NotFound({ name, btnName, handleClick }) {
  return (
    <MotionContainer initial="initial" animate="enter" variants={varBounce}>
      <m.div variants={varBounce().in}>
        <Typography variant="h3" sx={{ textAlign: 'center', mt: '40px' }}>
          {name} Not Found!
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <SeverErrorIllustration sx={{ height: 260, my: { xs: 2, sm: 8 } }} />
      </m.div>

      {btnName && (
        <Typography variant="body2" sx={{ textAlign: 'center' }}>
          <Button size="large" variant="contained" onClick={handleClick}>
            {btnName}
          </Button>
        </Typography>
      )}
    </MotionContainer>
  );
}

NotFound.propTypes = {
  name: PropTypes.string,
  btnName: PropTypes.string,
  handleClick: PropTypes.func,
};
