import PropTypes from 'prop-types';
// @mui
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useContext, useState } from 'react';
import { Tooltip } from '@mui/material';
import { TableRowsLoader } from 'src/_mock/constant_funcation';
import { TableHeadCustom, TableNoData } from 'src/components/table';
import { AuthContext } from 'src/auth/context/jwt';
import Permission from './permission';
import Gpermission from './gpermission';

// ----------------------------------------------------------------------

export default function CollapsibleTable({
  table,
  tableData,
  isLoading,
  rowCount,
  colCount,
  getAll,
  notFound,
}) {
  const [userData, setUserData] = useState();
  const [row, setRow] = useState({});
  const [grow, setGRow] = useState({});
  const permissionEdit = useBoolean();
  const gpermissionEdit = useBoolean();

  return (
    <>
      <Permission
        row={row}
        open={permissionEdit.value}
        onClose={permissionEdit.onFalse}
        getAll={getAll}
        userData={userData}
      />
      <Gpermission
        grow={grow}
        open={gpermissionEdit.value}
        onClose={gpermissionEdit.onFalse}
        getAll={getAll}
      />
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 800 }}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={colCount}
              rowCount={tableData.length}
              numSelected={table.selected.length}
              onSort={table.onSort}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
            />

            <TableBody>
              {isLoading ? (
                <TableRowsLoader rowsNum={rowCount} colNum={colCount.length} />
              ) : (
                <>
                  {tableData.map((row) => (
                    <CollapsibleTableRow
                      key={row.name}
                      row={{ ...row, history: [] }}
                      grow={{ ...grow, history: [] }}
                      permissionEdit={permissionEdit}
                      gpermissionEdit={gpermissionEdit}
                      setRow={setRow}
                      setGRow={setGRow}
                      setUserData={setUserData}
                    />
                  ))}
                  <TableNoData notFound={notFound} />
                </>
              )}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </>
  );
}

// ----------------------------------------------------------------------

function CollapsibleTableRow({
  row,
  permissionEdit,
  setRow,
  grow,
  gpermissionEdit,
  setGRow,
  setUserData,
}) {
  const collapsible = useBoolean();
  const { user } = useContext(AuthContext);
  const companyName = row.companies.map((data) => data.name);
  return (
    <>
      <TableRow>
        <TableCell>
          {row.roleId >= 1 && (
            <IconButton
              size="small"
              color={collapsible.value ? 'inherit' : 'default'}
              onClick={collapsible.onToggle}
            >
              <Iconify
                icon={
                  collapsible.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'
                }
              />
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>

        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>

        <TableCell component="th" scope="row">
          {row.email}
        </TableCell>

        <TableCell component="th" scope="row" align="left">
          {companyName.length !== 0 ? companyName.join(', ') : '-'}
        </TableCell>

        <TableCell component="th" scope="row">
          {row.role.name}
        </TableCell>

        <TableCell>
          <Tooltip title="Group Permission" arrow>
            {row.roleId >= user?.roleId && user?.departmentId === 1 ? (
              <IconButton
                color={gpermissionEdit.value ? 'inherit' : 'default'}
                onClick={() => {
                  setGRow(row);
                  gpermissionEdit.setValue(true);
                }}
              >
                <Iconify icon="material-symbols:lock-outline" />
              </IconButton>
            ) : (
              '-'
            )}
          </Tooltip>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell sx={{ py: 0 }} colSpan={6}>
          <Collapse in={collapsible.value} unmountOnExit>
            <Paper
              variant="outlined"
              sx={{
                py: 2,
                borderRadius: 1.5,
                ...(collapsible.value && {
                  boxShadow: (theme) => theme.customShadows.z20,
                }),
              }}
            >
              <Typography variant="h6" sx={{ m: 2, mt: 0 }}>
                Company
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr No.</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Group</TableCell>
                    <TableCell>Permission</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {row.companies.map((companiesRow) => (
                    <TableRow key={companiesRow.id}>
                      <TableCell component="th" scope="row">
                        {companiesRow.id}
                      </TableCell>
                      <TableCell>{companiesRow.name}</TableCell>
                      <TableCell>{companiesRow.group.name}</TableCell>
                      <TableCell>
                        <Tooltip title="Company Permission" arrow>
                          <IconButton
                            color={permissionEdit.value ? 'inherit' : 'default'}
                            onClick={() => {
                              setRow(companiesRow);
                              setUserData(row);
                              permissionEdit.setValue(true);
                            }}
                          >
                            <Iconify icon="material-symbols:lock-outline" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

CollapsibleTableRow.propTypes = {
  row: PropTypes.object,
  permissionEdit: PropTypes.object,
  setRow: PropTypes.func,
  grow: PropTypes.object,
  gpermissionEdit: PropTypes.object,
  setGRow: PropTypes.func,
  setUserData: PropTypes.func,
};
CollapsibleTable.propTypes = {
  tableData: PropTypes.array,
  isLoading: PropTypes.object,
  table: PropTypes.array,
  rowCount: PropTypes.number,
  colCount: PropTypes.number,
  getAll: PropTypes.func,
  notFound: PropTypes.number,
};
