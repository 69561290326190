import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo, useRef } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { useRouter } from 'src/routes/hooks';
// eslint-disable-next-line import/no-extraneous-dependencies
import { io } from 'socket.io-client';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

export const initialState = {
  user: null,
  loading: true,
};

export const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'SOCKET') {
    return {
      ...state,
      socket: action.payload.socket,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'token';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const socketconnect = useRef(false);
  const router = useRouter();

  const initialize = useCallback(async () => {
    try {
      const token = localStorage.getItem(STORAGE_KEY);

      if (token && isValidToken(token)) {
        setSession(token);

        const response = await axios.get(endpoints.auth.me);
        const user = response.data.data;
        dispatch({
          type: 'INITIAL',
          payload: {
            user: {
              ...user,
              token,
            },
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
            socket: null
          },
        });
      }
    } catch (error) {
      // localStorage.removeItem('user');
      // sessionStorage.removeItem('token');
      // eslint-disable-next-line no-restricted-globals

      // localStorage.removeItem('users');
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // Update User
  const updateUser = useCallback(
    async (payload) => {
      await axios.put(endpoints.auth.put + state.user.id, payload);
      if (JSON.stringify({ ...state.user, ...payload }) !== JSON.stringify(state.user))
        dispatch({
          type: 'INITIAL',
          payload: { user: { ...state.user, ...payload } },
        });
    },
    [state.user]
  );
  const changeAdvance = useCallback(
    async (payload) => {
      const advanceData = await axios.put(endpoints.auth.advance, payload);
      if (advanceData && payload.co_yearId === null) {
        router.replace('/');
      }
      if (JSON.stringify({ ...state.user, ...payload }) !== JSON.stringify(state.user))
        dispatch({
          type: 'INITIAL',
          payload: { user: { ...state.user, ...payload } },
        });
    },
    [router, state.user]
  );
  // LOGIN
  const login = useCallback(async (email, password) => {
    const data = {
      email,
      password,
    };

    const response = await axios.post(endpoints.auth.login, data);

    const user = response.data.data;
    localStorage.setItem('users', JSON.stringify(response.data.data));
    setSession(user.token);

    dispatch({
      type: 'LOGIN',
      payload: { user: { ...user } },
    });
  }, []);

  // REGISTER
  const register = useCallback(async (title, name, email, mobile, password, otp) => {
    const data = {
      title,
      name,
      email,
      mobile,
      password,
      otp,
    };

    const response = await axios.post(endpoints.auth.register, data);
    localStorage.setItem('users', JSON.stringify(response.data.data));
    const { token } = response.data.data;
    localStorage.setItem(STORAGE_KEY, token);

    dispatch({
      type: 'REGISTER',
      payload: {
        user: { ...response.data.data },
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      ...state,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',

      login,
      register,
      logout,
      changeAdvance,
      updateUser,
      initialize,
    }),
    [state, status, login, register, logout, changeAdvance, updateUser, initialize]
  );

  useEffect(() => {
    if (state?.user?.token && socketconnect.current === false) {

      const socket = io(`${process.env.REACT_APP_ASSETS_API}`, {
        // path: process.env.REACT_APP_HOST_API.includes(':') ? 'socket.io' : '/api/socket.io',
        extraHeaders: {
          token: state.user.token
        }
      });

      socket.on('connect', () => {
        console.log('Connected to WebSocket server');
        socketconnect.current = true;
      });

      socket.on('disconnect', () => {
        console.log('Disconnected from WebSocket server');
        socketconnect.current = false;
      });
      dispatch({
        type: 'SOCKET',
        payload: {
          socket
        },
      });

    }
  }, [state.user])

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
