import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
// @mui
import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
// components
import Iconify from 'src/components/iconify';
import Chart, { useChart } from 'src/components/chart';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { Dialog, Modal } from '@mui/material';
import { maxHeight } from '@mui/system';

// ----------------------------------------------------------------------
const typesofInvoice = ['invoice', 'challan'];
export const CustomTooltip = ({ value }) => (
  <Modal open="true" className="custom-tooltip" PaperProps={{
    sx: {
      maxWidth: 500,
      maxHeight: 500
    },
  }}>
    <h1>Harshad</h1>
  </Modal>
);
export default function BookingStatistics({ title, subheader, chart, ...other }) {
  const { categories, colors, series, options } = chart;

  const popover = usePopover();
  const popover2 = usePopover();

  const [invoiceData, setInvoiceData] = useState('invoice');
  const [seriesData, setSeriesData] = useState('Day');
  const findIndex = typesofInvoice.findIndex(x => x === invoiceData);
  const chartOptions = useChart({
    colors,
    chart: {
      stacked: true, height: '100%',
      toolbar: {
        show: false
      }
      // events: {
      //   mouseMove: (event, chartContext, { config, seriesIndex, dataPointIndex }) => {
      //     // Custom tooltip logic
      //     // Ensure that seriesIndex and dataPointIndex exist and are valid numbers
      //     if (
      //       seriesIndex !== undefined &&
      //       dataPointIndex !== undefined &&
      //       Array.isArray(config.series) &&
      //       config.series[seriesIndex] &&
      //       config.series[seriesIndex].data[dataPointIndex] !== undefined
      //     ) {
      //       const hoveredValue = config.series[seriesIndex].data[dataPointIndex];
      //       if (hoveredValue !== undefined) {
      //         setTooltipContent(hoveredValue);
      //         const tooltipElement = document.getElementById('apex-tooltip-react');
      //         if (tooltipElement) {
      //           // Set tooltip position based on the mouse position
      //           tooltipElement.style.left = `${event.pageX}px`;
      //           tooltipElement.style.top = `${event.pageY}px`;
      //         }
      //       }
      //     }
      //   },
      //   mouseLeave: () => {
      //     setTooltipContent(null); // Hide the tooltip when the mouse leaves the chart area
      //   }
      // }
    },
    stroke: {
      // show: true,
      // width: 2,
      width: 0
      // colors: ['transparent'],
    },
    xaxis: {
      categories: categories[series[findIndex].findIndex(x => x.type === seriesData)],
      labels: {
        style: {
          colors: categories[series[findIndex].findIndex(x => x.type === seriesData)].map(day => day.includes('Su') ? 'red' : 'black'), // 'red' for Sundays, 'black' for others
        },
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 10, // Set the radius for the top corners
        borderRadiusApplication: 'end', // Apply the radius to the end (top in vertical bars)
        borderRadiusWhenStacked: 'last', // Ensure only the top bar in the stack has rounded corners
        columnWidth: '50%',
        dataLabels: {
          position: 'center', // Position the numbers inside the bars
          total: {
            enabled: true,
            offsetY: -10,
            style: {
              fontSize: '13px',
              fontWeight: 500,
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => val, // Display the exact value inside the bar
      style: {
        colors: ['#fff'], // Color of the numbers inside the bar
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      // custom({ series, seriesIndex, dataPointIndex }) {
      //   const tooltipElement = document.getElementById('apex-tooltip-react');
      //   if (!tooltipElement) {
      //     // Create a new tooltip element if it doesn't exist
      //     const div = document.createElement('div');
      //     div.id = 'apex-tooltip-react';
      //     div.style.position = 'absolute';
      //     div.style.width = "200px";
      //     div.style.pointerEvents = 'none';
      //     div.style.zIndex = '1000';
      //     document.body.appendChild(div);
      //   }
      //   return ''; // We handle the tooltip content using React
      // },
      y: {
        formatter: (val, { seriesIndex }) => {
          const names = ['VAR', 'AMR', 'LHR', 'KTM', 'MOT'];
          return `${names[seriesIndex]}: ${val}`;
        },
        title: {
          formatter(seriesName) {
            return ''
          }
        }
      },
    },
    zoom: {
      enabled: true
    },
    ...options,
  });

  const handleChangeSeries = useCallback(
    (newValue) => {
      popover.onClose();
      setSeriesData(newValue);
    },
    [popover]
  );
  const handleChangeInvoice = useCallback(
    (newValue) => {
      popover2.onClose();
      setInvoiceData(newValue);
    },
    [popover2]
  );
  const [tooltipContent, setTooltipContent] = useState(null);

  useEffect(() => () => {
    const tooltipElement = document.getElementById('apex-tooltip-react');
    if (tooltipElement) {
      tooltipElement.remove();
    }
  }, []);
  return (
    <>
      <Card {...other}>
        <CardHeader
          title={title}
          subheader={subheader}
          action={
            <>
              <ButtonBase
                onClick={popover2.onOpen}
                sx={{
                  mx: 1,
                  pl: 1,
                  py: 0.5,
                  pr: 0.5,
                  borderRadius: 1,
                  typography: 'subtitle2',
                  bgcolor: 'background.neutral',
                }}
              >
                {invoiceData}

                <Iconify
                  width={16}
                  icon={popover2.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                  sx={{ mx: 1 }}
                />
              </ButtonBase>
              <ButtonBase
                onClick={popover.onOpen}
                sx={{
                  mx: 1,
                  pl: 1,
                  py: 0.5,
                  pr: 0.5,
                  borderRadius: 1,
                  typography: 'subtitle2',
                  bgcolor: 'background.neutral',
                }}
              >
                {seriesData}

                <Iconify
                  width={16}
                  icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                  sx={{ mx: 1 }}
                />
              </ButtonBase>
            </>
          }
        />

        {series[findIndex].map((item) => (
          <Box key={item.type} sx={{ mt: 3, mx: 3 }}>
            {item.type === seriesData && (
              <Chart dir="ltr" type="bar" series={item.data} options={chartOptions} height={460} />
            )}
            {tooltipContent !== null &&
              ReactDOM.createPortal(<CustomTooltip value={tooltipContent} />, document.getElementById('apex-tooltip-react'))}
          </Box>
        ))}
      </Card>

      <CustomPopover open={popover2.open} onClose={popover2.onClose} sx={{ width: 140 }}>
        {typesofInvoice.map((option) => (
          <MenuItem
            key={option}
            selected={option === invoiceData}
            onClick={() => handleChangeInvoice(option)}
          >
            {option}
          </MenuItem>
        ))}
      </CustomPopover>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140 }}>
        {series[findIndex].map((option) => (
          <MenuItem
            key={option.type}
            selected={option.type === seriesData}
            onClick={() => handleChangeSeries(option.type)}
          >
            {option.type}
          </MenuItem>
        ))}
      </CustomPopover>

    </>
  );
}

BookingStatistics.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string,
};
CustomTooltip.propTypes = {
  value: PropTypes.object
};
