import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { ConfirmDialog } from 'src/components/custom-dialog';
import { TRANSACTION_TYPES } from 'src/_mock/constant';
import { DateField } from '@mui/x-date-pickers';
import { fMoney, formatDate } from 'src/_mock/constant_funcation';
import { useState } from 'react';

export default function UserTableRow({
  tableData,
  row,
  index,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  table,
  getAll,
  bankData,
  setBDate,
  onTableCellDoubleClick,
}) {
  const confirm = useBoolean();

  const [bDates, setBDates] = useState(null);

  console.log(row, 'row');

  function getDateFieldValue() {
    if (bDates) {
      return new Date(bDates);
    }
    if (row?.bankdate) {
      return new Date(row.bankdate);
    }
    return null;
  }

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell>{formatDate(row.dt)}</TableCell>
        <TableCell
          onDoubleClick={() => {
            onTableCellDoubleClick(row);
          }}
        >
          {row?.entry_details?.find((x) => x.order === 2)?.account?.name}
        </TableCell>
        <TableCell>{TRANSACTION_TYPES[row.t_type]}</TableCell>
        <TableCell>{row?.entry_details?.find((x) => x.order === 1)?.ac_no}</TableCell>

        <TableCell sx={{ padding: '0px' }}>
          {bankData?.modeId === 1 ? (
            <TableCell>{row?.bankdate ? formatDate(row?.bankdate) : '-'}</TableCell>
          ) : (
            <DateField
              value={getDateFieldValue()}
              autoFocus={index === 0}
              onFocus={() => {
                console.log('FOCUS');
                if (!bDates && !row?.bankdate) {
                  setBDates(row.t_date);
                }
              }}
              onChange={(newValue) => {
                const newDate = newValue; // Format newValue if necessary
                setBDates(newDate.toISOString());
              }}
              onBlur={() => {
                setBDate({
                  id: row.id,
                  date: bDates,
                });
                setBDates(null);
              }}
              format="dd/MM/yyyy"
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small',
                  variant: 'standard',
                },
              }}
            />
          )}
        </TableCell>

        <TableCell sx={{ textAlign: 'right' }}>
          {row.entry_details.find((x) => x.accountId === bankData?.bankId)?.cb === 2
            ? fMoney.format(row?.amount, { symbol: '₹ ' })
            : ''}
        </TableCell>
        <TableCell sx={{ textAlign: 'right' }}>
          {row.entry_details.find((x) => x.accountId === bankData?.bankId)?.cb === 1
            ? fMoney.format(row?.amount, { symbol: '₹ ' })
            : ''}
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  index: PropTypes.number,
  selected: PropTypes.bool,
  table: PropTypes.object,
  getAll: PropTypes.func,
  tableData: PropTypes.array,
  bankData: PropTypes.object,
  setBDate: PropTypes.func,
  onTableCellDoubleClick: PropTypes.any,
};
