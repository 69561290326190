import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  ListItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { KEY } from 'src/_mock/constant';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'src/components/hook-form/form-provider';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { fetcher, update } from 'src/utils/axios';
import { useEventListener } from 'src/hooks/use-event-listener';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { useBoolean } from 'src/hooks/use-boolean';
import AUserQuickEditForm from 'src/sections/accounts/account/quick-edit-form';
import { useBgColorAutocomplete } from 'src/_mock/constant_funcation';
import CompanyYear from './company-year';

export default function InvoiceSettings({ open, onClose }) {
  const [loading, setLoading] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  const [accountEditList, setAccountEditList] = useState({});
  const [fieldName, setFieldName] = useState('');

  const bgColorAutocomplete = useBgColorAutocomplete();

  const values = useMemo(
    async () => ({
      transactions: [
        {
          id: 1,
          t_type: 8,
          name: 'Purchase',
          tfields: [
            {
              id: 3,
              name: 'IGST',
              accountId: null,
              accountName: null,
              tsettingId: 1,
            },
            {
              id: 2,
              name: 'CGST',
              accountId: null,
              accountName: null,
              tsettingId: 1,
            },
            {
              id: 1,
              name: 'SGST',
              accountId: null,
              accountName: null,
              tsettingId: 1,
            },
          ],
        },
        {
          id: 2,
          t_type: 9,
          name: 'Sale',
          tfields: [
            {
              id: 6,
              name: 'CGST',
              accountId: null,
              accountName: null,
              tsettingId: 2,
            },
            {
              id: 5,
              name: 'IGST',
              accountId: null,
              accountName: null,
              tsettingId: 2,
            },
            {
              id: 4,
              name: 'SGST',
              accountId: null,
              accountName: null,
              tsettingId: 2,
            },
          ],
        },
      ],
    }),
    []
  );

  const methods = useForm({
    defaultValues: values,
    values: initialValues,
  });

  const {
    reset,
    setValue,
    watch,
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = methods;

  const allValues = watch();
  const quickEditAccount = useBoolean();
  const confirm = useBoolean();

  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      const URL = 'automobile/tsettings/';
      const { success, show } = await update({ url: URL, payload });
      if (success) {
        if (show) {
          enqueueSnackbar('Updated!');
        }
      }
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });
  const handleCloseAccount = () => {
    quickEditAccount.onFalse();
    HandleAccountList();
  };
  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if (!loading) {
        await trigger();
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  const HandleAccountList = async () => {
    let URL = 'automobile/accounts/';
    URL += `?page=${1}&limit=${10000}&status=true&ac_groupId=32,35,4,16,116,91,118,70&`;
    const { data } = await fetcher(URL);
    setAccountList(data?.rows);
  };

  const fetchInitialValues = useCallback(async () => {
    let URL = 'automobile/tsettings/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    if (data?.rows?.length) {
      setInitialValues({
        transactions: data?.rows,
      });
    } else {
      setInitialValues(values);
    }
  }, [values]);

  useEffect(() => {
    if (open) {
      HandleAccountList();
      fetchInitialValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const transactions = useFieldArray({
    control,
    name: 'transactions',
  });

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 1024, minHeight: 500 },
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          top: 0,
          zIndex: 9,
          position: 'sticky',
          bgcolor: (theme) => alpha(theme.palette.background.default, 1),
        }}
      >
        <Toolbar>
          <Typography variant="h5" sx={{ flex: 1 }}>
            Invoice Settings
          </Typography>

          <CompanyYear />

          <IconButton
            color="inherit"
            edge="start"
            onClick={() => {
              onClose();
            }}
            tabIndex="-1"
          >
            <Iconify icon="mingcute:close-line" />
          </IconButton>
        </Toolbar>
        <Divider sx={{ borderStyle: 'dashed' }} />
      </Grid>

      <DialogContent sx={{ p: 2 }}>
        <FormProvider methods={methods}>
          <Grid container item xs={12} md={12} sx={{ p: 2 }} spacing={2}>
            {allValues?.transactions?.map((x, xindex) => (
              <Grid container item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <Typography variant="h6" sx={{ flex: 1 }}>
                  {x.name}
                </Typography>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  spacing={2}
                  mt={1}
                >
                  {x.tfields.map((y, yindex) => (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        disableCloseOnSelect
                        options={(() => {
                          const acc = accountList;
                          return acc.length ? acc : [{ name: 'Select Option', value: null }];
                        })()}
                        getOptionDisabled={(option) => option?.value === null}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option?.name}
                        openOnFocus={!quickEditAccount.value}
                        inputValue={allValues.transactions[xindex].tfields[yindex].accountName}
                        onInputChange={(event, newInputValue) => {
                          setValue(
                            `transactions[${xindex}].tfields[${yindex}].accountName`,
                            newInputValue
                          );
                        }}
                        onChange={(event, newValue) => {
                          setAccountEditList(newValue);
                          if (newValue?.id)
                            setValue(
                              `transactions[${xindex}].tfields[${yindex}].accountId`,
                              newValue.id
                            );
                          else
                            setValue(`transactions[${xindex}].tfields[${yindex}].accountId`, null);
                        }}
                        value={(() => {
                          const acc = accountList?.find(
                            (y) => y.id === allValues.transactions[xindex].tfields[yindex].accountId
                          );

                          const editbank = allValues.transactions[xindex].tfields[yindex].accountId;
                          console.log(acc, editbank);
                          if (acc) return acc;
                          if (editbank) return editbank;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={`transactions[${xindex}].tfields[${yindex}].accountId`}
                            label={`${y.name} Account`}
                          />
                        )}
                        renderGroup={(params) => (
                          <li key={params.key}>
                            <GroupHeader component="li">
                              <ListItem sx={{ padding: 0, margin: 0 }}>Account Name</ListItem>
                            </GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                            <GroupFooter component="li">
                              <Tooltip title="(Insert)" arrow>
                                <Button
                                  onClick={() => {
                                    setFieldName(
                                      `transactions[${xindex}].tfields[${yindex}].accountId`
                                    );
                                    setAccountEditList(null);
                                    quickEditAccount.onTrue();
                                  }}
                                >
                                  Add
                                </Button>
                              </Tooltip>
                              {accountEditList?.id && (
                                <>
                                  <Button
                                    onClick={() => {
                                      quickEditAccount.onTrue();
                                    }}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      confirm.onTrue();
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </>
                              )}
                            </GroupFooter>
                          </li>
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                              {option?.name}
                            </ListItem>
                          </Box>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogActions
        sx={{
          bottom: 0,
          zIndex: 9,
          position: 'sticky',
          bgcolor: (theme) => alpha(theme.palette.background.default, 1),
        }}
      >
        <LoadingButton
          variant="contained"
          onClick={async () => {
            await trigger();
            onSubmit();
          }}
        >
          ok
        </LoadingButton>
      </DialogActions>
      <AUserQuickEditForm
        row={accountEditList || null}
        open={quickEditAccount.value}
        onClose={handleCloseAccount}
        set={{
          fieldName,
          setValue,
          list: HandleAccountList,
        }}
      />
    </Dialog>
  );
}

InvoiceSettings.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
