import { useContext, useEffect, useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import axiosInstance, { endpoints } from 'src/utils/axios';
import { AuthContext } from 'src/auth/context/jwt';

// ----------------------------------------------------------------------

const icon = (name) => {
  if (name?.includes('ic_')) {
    return <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
  }
  return <Iconify icon={name} sx={{ borderRadius: 0.65, width: 28 }} />;
};

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const { user } = useContext(AuthContext);
  const bId = user?.companies.find((x) => x.id === user.companyId);

  const data = useMemo(() => {
    const buildMenuItemTree = (node) =>
      node.children.length && (node.businessId === bId?.businessId || node.businessId === null)
        ? {
            ...node,
            title: t(node.title),
            path: node.spath,
            icon: icon(node.icon),
            children: node.children
              .filter(
                (x) => (x.showOnMenu && x.businessId === bId?.businessId) || x.businessId === null
              )
              .map((item) => buildMenuItemTree(item)),
          }
        : { title: t(node.title), path: node.spath, icon: icon(node.icon) };
    return [
      {
        subheader: bId?.name ? bId?.name : t('overview'),
        items: user.modules
          .filter((x) => x.showOnMenu)
          .filter((x) => x?.businessId === bId?.businessId || x.businessId === null)
          .map((x) => buildMenuItemTree(x)),
      },
    ];
  }, [t, user, bId]);

  return data;
}
