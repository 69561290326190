import { Helmet } from 'react-helmet-async';
import { COMPANY_NAME } from 'src/_mock/constant';
// sections
import { ListView } from 'src/sections/automobiles/reports/stock/view';

// ----------------------------------------------------------------------

const reports = {
  name: 'stock',
  cond: {
    label: 'stock',
    value: '22',
    t_type: '1,2,5,6,7',
  },
};

export default function AutomobileStockReport() {
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} : Stock Report</title>
      </Helmet>

      <ListView reportsDetails={reports} />
    </>
  );
}
