import { Grid, Tab, Tabs, alpha } from '@mui/material';
import Container from '@mui/material/Container';
import { useCallback, useState } from 'react';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { MultiListView } from '../../mini_max_qty_set_multi_part/view';
import { SingleListView } from '../../mini_max_qty_set_single_part/view';

const TABS = [
  {
    value: 'single_part',
    label: 'Single Part',
    icon: <Iconify icon="solar:bill-list-bold" width={24} />,
  },
  {
    value: 'multi_part',
    label: 'Multi Part',
    icon: <Iconify icon="solar:bill-list-bold" width={24} />,
  },
];

export default function ListView() {
  const settings = useSettingsContext();

  const [currentTab, setCurrentTab] = useState('single_part');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <Grid
          sx={{
            top: 55,
            width: 1,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            sx={{
              mb: { xs: 3, md: 5 },
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
            ))}
          </Tabs>
        </Grid>

        {currentTab === 'single_part' && <SingleListView />}
        {currentTab === 'multi_part' && <MultiListView />}
      </Container>
    </>
  );
}
