import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { create, update } from 'src/utils/axios';
import {
  DataGrid,
  GridActionsCellItem,
  GridAddIcon,
  GridCellModes,
  GridRowEditStopReasons,
  GridRowModes,
  GridSaveAltIcon,
} from '@mui/x-data-grid';
import {
  AMCSERVICE,
  FUELLEVEL,
  FUEL_LEVEL,
  JOB_CARD_STATUS,
  KEY,
  LABOURRATELIST,
  LABOUR_RATE_LIST,
  OPERATION,
  PERIODICJOB,
  PERMIUMAMCTYPE,
  REPEATCOMPLAINTREASON,
  REPEAT_COMPLAINT_REASON,
  SERVICETYPE,
  SERVICE_SOURCE,
  SERVICE_STATUS,
  SERVICE_TYPE,
  SOURCE,
  STATUS,
  TYPEOFCONTACT,
} from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { alpha, spacing } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import { useEventListener } from 'src/hooks/use-event-listener';
import createData from 'src/sections/_examples/mui/table-view/utils';
// ----------------------------------------------------------------------

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();

  const [openModelName, setOpenModelName] = useState('');

  const dialog = useBoolean();

  const departmentSchema = Yup.object().shape({
    frame: Yup.string().required('Frame Number is required'),
    engine_no: Yup.string().required('Engine Number is required'),
    registration: Yup.string().required('Registration Number is required'),
    mobile_phone: Yup.string().required('Mobile Phone is required'),
  });

  const values = useMemo(
    () => ({
      name: row?.name || '',
      status: row?.status || false,
      description: row?.description || '',
      balance_method: row?.balance_method || 'Bill To Bill',
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(departmentSchema),
    values,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;
  const onSubmit = handleSubmit(async (payload) => {
    try {
      let URL = 'roles/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const { watch, control, setValue } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'company_banks',
  });

  const handleAdd = () => {
    append({
      name: '',
      branch_name: '',
      ifsc_code: '',
      iban_no: '',
      swift_code: '',
      upi_code: '',
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };
  useEventListener('keydown', handleKeyDown);

  const [cellModesModel, setCellModesModel] = useState({});

  const handleCellClick = useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (!event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => ({
      // Revert the mode of the other cells from other rows
      ...Object.keys(prevModel).reduce(
        (acc, id) => ({
          ...acc,
          [id]: Object.keys(prevModel[id]).reduce(
            (acc2, field) => ({
              ...acc2,
              [field]: { mode: GridCellModes.View },
            }),
            {}
          ),
        }),
        {}
      ),
      [params.id]: {
        // Revert the mode of other cells in the same row
        ...Object.keys(prevModel[params.id] || {}).reduce(
          (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
          {}
        ),
        [params.field]: { mode: GridCellModes.Edit },
      },
    }));
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  function ValueParserSetterGrid() {
    return (
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={defaultRows}
          columns={columns}
          rowModesModel={rowModesModel}
          // onRowModesModelChange={handleRowModesModelChange}
          // onRowEditStop={handleRowEditStop}
          // processRowUpdate={processRowUpdate}
          rowHeight={40}
          // slots={{
          //   toolbar: EditToolbar,
          // }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          hideFooterPagination
          hideFooterSelectedRowCount
          disableRowSelectionOnClick
        />
      </div>
    );
  }

  const defaultRows = [
    { id: 1, productName: 'Snow', quantity: 10, rate: 1000, amount: 20000 },
    { id: 2, productName: 'Lannister', quantity: 20, rate: 1000, amount: 20000 },
    { id: 3, productName: 'Lannister', quantity: 10, rate: 1000, amount: 20000 },
    { id: 4, productName: 'Stark', quantity: 100, rate: 1000, amount: 20000 },
    { id: 5, productName: 'Targaryen', quantity: 2000, rate: 1000, amount: 20000 },
  ];

  const [setRows] = useState(defaultRows);
  const [rowModesModel, setRowModesModel] = useState({});
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleDeleteClick = (id) => () => {
    setRows(defaultRows.filter((row) => row.id !== id));
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow = defaultRows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(defaultRows.filter((row) => row.id !== id));
    }
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: 'productName', headerName: 'Product Name', width: 750, editable: true },
    { field: 'quantity', headerName: 'Quantity', width: 120, editable: true },
    {
      field: 'rate',
      headerName: 'Rate',
      width: 120,
      editable: true,
    },
    { field: 'amount', headerName: 'Amount', width: 120, editable: true },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 120,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<GridSaveAltIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<GridSaveAltIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<GridAddIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<GridSaveAltIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const rows = [
    createData(
      1,
      '43546',
      'abckjasncb',
      50,
      100,
      100,
      '43546',
      'abckjasncb',
      50,
      100,
      100,
      '43546',
      'abckjasncb',
      50,
      100,
      100,
      100
    ),
    createData(
      2,
      '43546',
      'abckjasncb',
      50,
      100,
      100,
      '43546',
      'abckjasncb',
      50,
      100,
      100,
      '43546',
      'abckjasncb',
      50,
      100,
      100,
      100
    ),
    createData(
      3,
      '43546',
      'abckjasncb',
      50,
      100,
      100,
      '43546',
      'abckjasncb',
      50,
      100,
      100,
      '43546',
      'abckjasncb',
      50,
      100,
      100,
      100
    ),
  ];

  const allValues = watch();
  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 1320 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update Service request' : 'Create Service request'}
              </Typography>

              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>

            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          <Grid item container xs={12} md={12} sx={{ p: 3 }} spacing={5}>
            {/* <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              alignContent="start"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Typography variant="h6" gutterBottom>
                  Service Requests
                </Typography>
                <Divider sx={{ borderStyle: 'dashed' }} />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} xxl={3}>
                <RHFTextField name="sr" label="SR #" size="small" />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2} xl={2} xxl={2}>
                <RHFSelect name="source" label="Source" size="small">
                  {SOURCE.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>

              <Grid item xs={12} sm={4} md={3} lg={2.5} xl={2.5} xxl={2.5}>
                <RHFSelect name="servicetype" label="Service Type" size="small">
                  {SERVICETYPE.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2.5} xl={2.5} xxl={2.5}>
                <RHFTextField name="frame" label="Frame #" size="small" />
              </Grid>

              <Grid item xs={12} sm={4} md={3} lg={2} xl={2} xxl={2}>
                <RHFTextField name="currentkms" label="Current Kms" size="small" />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2} xl={2} xxl={2}>
                <RHFTextField name="lastname" label="Last name" size="small" />
              </Grid>

              <Grid item xs={12} sm={4} md={3} lg={2} xl={2} xxl={2}>
                <RHFTextField name="firstname" label="First Name" size="small" />
              </Grid>

              <Grid item xs={12} sm={4} md={3} lg={2} xl={2} xxl={2}>
                <RHFTextField name="account" label="Account" size="small" />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={1.5} xl={1.5} xxl={1.5}>
                <RHFSelect name="status" label="Status" size="small">
                  {STATUS.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>

              <Grid item xs={12} sm={4} md={3} lg={2.3} xl={2.3} xxl={2.3}>
                <RHFTextField name="registration" label="Registration #" size="small" />
              </Grid>

              <Grid item xs={12} sm={4} md={3} lg={2.2} xl={2.2} xxl={2.2}>
                <RHFSelect name="operation" label="Operation" size="small">
                  {OPERATION.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2.5} xl={2.5} xxl={2.5}>
                <RHFTextField name="partpricelist" label="Part Price List" size="small" />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={1.5} xl={1.5} xxl={1.5}>
                <RHFTextField name="perioddaysdiff" label="Period Days(Diff)" size="small" />
              </Grid>

              <Grid item xs={12} sm={4} md={3} lg={1.8} xl={1.8} xxl={1.8}>
                <DatePicker
                  label="SA Attended Date"
                  value={new Date()}
                  // onChange={handleFilterStartDate}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={1.8} xl={1.8} xxl={1.8}>
                <RHFTextField name="saattendedtime" label="SA Attended Time" size="small" />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={1.8} xl={1.8} xxl={1.8}>
                <DatePicker
                  label="Booking Date"
                  value={new Date()}
                  // onChange={handleFilterStartDate}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2.6} lg={2.6} xl={2.6} xxl={2.6}>
                <RHFSelect name="labourratelist" label="Labour Rate List" size="small">
                  {LABOURRATELIST.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2.1} xl={2.1} xxl={2.1}>
                <RHFCheckbox row name="repeatcomplaintflag" label="Repeat Complaint Flag" />
              </Grid>
              <Grid item xs={12} sm={4} md={3.2} lg={2.7} xl={2.7} xxl={2.7}>
                <RHFSelect
                  name="repeatcomplaintreason"
                  label="Repeat Complaint Reason"
                  size="small"
                >
                  {REPEATCOMPLAINTREASON.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
              <Grid item xs={12} sm={4} md={3.2} lg={1.7} xl={1.7} xxl={1.7}>
                <RHFTextField name="latestcontactno" label="Latest Contact No" size="small" />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={1.8} xl={1.8} xxl={1.8}>
                <DatePicker
                  label="SR Created Date"
                  value={new Date()}
                  // onChange={handleFilterStartDate}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3} lg={1.8} xl={1.8} xxl={1.8}>
                <RHFTextField name="srcreatedetime" label="SR Created Time" size="small" />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={1.9} xl={1.9} xxl={1.9}>
                <RHFSelect name="typeofcontact" label="Type Of Contact" size="small">
                  {TYPEOFCONTACT.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>

              <Grid item xs={12} sm={4} md={3} lg={2.2} xl={2.2} xxl={2.2}>
                <DatePicker
                  label="Cancellation/Returned Date"
                  value={new Date()}
                  // onChange={handleFilterStartDate}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3.6} lg={2.4} xl={2.4} xxl={2.4}>
                <RHFTextField
                  name="cancellation_returnedtime"
                  label="Cancellation/Returned Time"
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={2.7} lg={2.5} xl={2.5} xxl={2.5}>
                <RHFTextField name="assignedto" label="Assigned To" size="small" />
              </Grid>

              <Grid item xs={12} sm={4} md={2.7} lg={2} xl={2} xxl={2}>
                <RHFSelect name="amcservice" label="AMC Service" size="small">
                  {AMCSERVICE.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2.9} xl={2.9} xxl={2.9}>
                <RHFTextField name="amccouponnumber" label="AMC Coupon Number" size="small" />
              </Grid>
              <Grid item xs={12} sm={4} md={2.2} lg={2} xl={2} xxl={2}>
                <RHFSelect name="fuellevel" label="Fuel Level" size="small">
                  {FUELLEVEL.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
              <Grid item xs={12} sm={4} md={2.8} lg={3} xl={3} xxl={3}>
                <RHFSelect name="premiumamctype" label="Premium AMC Type" size="small">
                  {PERMIUMAMCTYPE.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>

              <Grid item xs={12} sm={4} md={2.4} lg={2} xl={2} xxl={2}>
                <RHFSelect name="periodicjob" label="Periodic Job" size="small">
                  {PERIODICJOB.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>

              <Grid item xs={12} sm={4} md={2.3} lg={2} xl={2} xxl={2}>
                <RHFCheckbox row name="amcemiflag" label="AMC - EMI Flag" />
              </Grid>

              <Grid item xs={12} sm={4} md={2.3} lg={1.7} xl={1.7} xxl={1.7}>
                <RHFTextField name="cellularphone" label="Cellular Phone" size="small" />
              </Grid>
            </Grid> */}

            <Grid
              item
              container
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              xxl={3}
              spacing={2}
              alignContent="start"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Typography variant="h6" gutterBottom>
                  SR Information
                </Typography>
                <Divider sx={{ borderStyle: 'dashed' }} />
              </Grid>

              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                spacing={2}
                alignContent="start"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="sr" label="SR #" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    options={SERVICE_TYPE?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={SERVICE_TYPE.find((data) => data.id === allValues.SERVICE_TYPE) || ''}
                    onChange={(event, newValue) => {
                      setValue(
                        'service_type',
                        SERVICE_TYPE.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} name="service_type" label="Service Type" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    options={JOB_CARD_STATUS?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      JOB_CARD_STATUS.find((data) => data.id === allValues.JOB_CARD_STATUS) || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'assigned_to',
                        JOB_CARD_STATUS.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="assigned_to"
                        label="Assigned To"
                        onClick={() => {
                          dialog.onTrue();
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    options={SERVICE_STATUS?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      SERVICE_STATUS.find((data) => data.id === allValues.SERVICE_STATUS) || ''
                    }
                    onChange={(event, newValue) => {
                      setValue('status', SERVICE_STATUS.find((data) => data.name === newValue)?.id);
                    }}
                    renderInput={(params) => <TextField {...params} name="status" label="Status" />}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    options={SERVICE_SOURCE?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      SERVICE_SOURCE.find((data) => data.id === allValues.SERVICE_SOURCE) || ''
                    }
                    onChange={(event, newValue) => {
                      setValue('source', SERVICE_SOURCE.find((data) => data.name === newValue)?.id);
                    }}
                    renderInput={(params) => <TextField {...params} name="source" label="Source" />}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    options={LABOUR_RATE_LIST?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      LABOUR_RATE_LIST.find((data) => data.id === allValues.LABOUR_RATE_LIST) || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'labour_rate_list',
                        LABOUR_RATE_LIST.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} name="labour_rate_list" label="Labour Rate List" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    options={JOB_CARD_STATUS?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      JOB_CARD_STATUS.find((data) => data.id === allValues.JOB_CARD_STATUS) || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'parts_price_list',
                        JOB_CARD_STATUS.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="parts_price_list"
                        label="Parts Price List"
                        onClick={() => {
                          dialog.onTrue();
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="division" label="Division" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFCheckbox
                    name="repeat_complaint_flag"
                    label="Repeat Complaint Flag"
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    options={REPEAT_COMPLAINT_REASON?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      REPEAT_COMPLAINT_REASON.find(
                        (data) => data.id === allValues.REPEAT_COMPLAINT_REASON
                      ) || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'repeat_complaint_reason',
                        REPEAT_COMPLAINT_REASON.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="repeat_complaint_reason"
                        label="Repeat Complaint Reason"
                        onClick={() => {
                          dialog.onTrue();
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    options={FUEL_LEVEL?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={FUEL_LEVEL.find((data) => data.id === allValues.FUEL_LEVEL) || ''}
                    onChange={(event, newValue) => {
                      setValue('fuel_level', FUEL_LEVEL.find((data) => data.name === newValue)?.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="fuel_level"
                        label="Fuel Level"
                        onClick={() => {
                          dialog.onTrue();
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              xxl={3}
              spacing={2}
              alignContent="start"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Typography variant="h6" gutterBottom>
                  Frame Details
                </Typography>
                <Divider sx={{ borderStyle: 'dashed' }} />
              </Grid>

              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                spacing={2}
                alignContent="start"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    options={JOB_CARD_STATUS?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      JOB_CARD_STATUS.find((data) => data.id === allValues.JOB_CARD_STATUS) || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'road_type',
                        JOB_CARD_STATUS.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} name="road_type" label="Road Type" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    options={JOB_CARD_STATUS?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      JOB_CARD_STATUS.find((data) => data.id === allValues.JOB_CARD_STATUS) || ''
                    }
                    onChange={(event, newValue) => {
                      setValue('frame', JOB_CARD_STATUS.find((data) => data.name === newValue)?.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={errors.frame}
                        {...params}
                        name="frame"
                        label="Frame"
                        onClick={() => {
                          dialog.onTrue();
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="engine_no" label="Engine No." size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="registration" label="Registration #" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="current_kms" label="Current KMs" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <DatePicker
                    label="First Sale Date"
                    value={new Date()}
                    // onChange={handleFilterStartDate}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: 'small',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="selling_dealer" label="Selling Dealer" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <DatePicker
                    label="Last Service Date"
                    value={new Date()}
                    // onChange={handleFilterStartDate}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: 'small',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="last_service_kms" label="Last Service Kms" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <DatePicker
                    label="Warranty Expiry Date"
                    value={new Date()}
                    // onChange={handleFilterStartDate}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: 'small',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              xxl={3}
              spacing={2}
              alignContent="start"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Typography variant="h6" gutterBottom>
                  Customer Information
                </Typography>
                <Divider sx={{ borderStyle: 'dashed' }} />
              </Grid>

              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                spacing={2}
                alignContent="start"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    options={JOB_CARD_STATUS?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      JOB_CARD_STATUS.find((data) => data.id === allValues.JOB_CARD_STATUS) || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'last_name',
                        JOB_CARD_STATUS.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="last_name"
                        label="Last Name"
                        onClick={() => {
                          dialog.onTrue();
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="mobile_phone" label="Mobile Phone #" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="email" label="Email" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    options={JOB_CARD_STATUS?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      JOB_CARD_STATUS.find((data) => data.id === allValues.JOB_CARD_STATUS) || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'account',
                        JOB_CARD_STATUS.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="account"
                        label="Account"
                        onClick={() => {
                          dialog.onTrue();
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="account_phone" label="Account Phone" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Autocomplete
                    autoHighlight
                    fullWidth
                    freeSolo
                    size="small"
                    options={JOB_CARD_STATUS?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      JOB_CARD_STATUS.find((data) => data.id === allValues.JOB_CARD_STATUS) || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'operation',
                        JOB_CARD_STATUS.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} name="operation" label="Operation" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="model_name" label="Model Name" size="small" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <RHFTextField name="cellular_phone" label="Cellular Phone #" size="small" />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              xxl={3}
              spacing={2}
              alignContent="start"
            >
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                spacing={2}
                alignContent="start"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="h6" gutterBottom>
                    Dates
                  </Typography>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  spacing={2}
                  alignContent="start"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <DatePicker
                      label="Booking Date"
                      value={new Date()}
                      // onChange={handleFilterStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <DatePicker
                      label="SA Attended Date"
                      value={new Date()}
                      // onChange={handleFilterStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <RHFTextField name="sa_attended_time" label="SA Attended Time" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <DatePicker
                      label="SR Created Date"
                      value={new Date()}
                      // onChange={handleFilterStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <RHFTextField name="sr_created_time" label="SR Created Time" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <DatePicker
                      label="SR Closed Date"
                      value={new Date()}
                      // onChange={handleFilterStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <RHFTextField name="sr_closed_time" label="SR Closed Time" size="small" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                spacing={2}
                alignContent="start"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="h6" gutterBottom>
                    Job Details
                  </Typography>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  spacing={2}
                  alignContent="start"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <RHFTextField name="job_card" label="Job Card #" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <DatePicker
                      label="Job Card Created Date"
                      value={new Date()}
                      // onChange={handleFilterStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <DatePicker
                      label="Job Card Closed Date"
                      value={new Date()}
                      // onChange={handleFilterStartDate}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DialogActions
          sx={{
            bottom: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {row ? 'Update' : 'Add'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
