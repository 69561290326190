import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect, useContext } from 'react';
// @mui
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import queryString from 'query-string';
import { useSnackbar } from 'src/components/snackbar';
import { useDebounce } from 'src/hooks/use-debounce';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';
//
import { fetcher } from 'src/utils/axios';
import { TableRowsLoader, UPermission, handleKeyDownInTable } from 'src/_mock/constant_funcation';
import { useEventListener } from 'src/hooks/use-event-listener';
import { usePopover } from 'src/components/custom-popover';
import { AuthContext } from 'src/auth/context/jwt';
import UserTableRow from '../table-row';
import UserTableToolbar from '../table-toolbar';
import UserTableFiltersResult from '../table-filters-result';
import AUserQuickEditForm from '../quick-edit-form';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'no', label: 'No.', width: 90 },
  { id: 'account_name', label: 'Account Name' },
  { id: 'opening', label: 'Opening' },
  { id: 'credit', label: 'Receipts (Cr.)' },
  { id: 'debit', label: 'Payments (Dr.)' },
  { id: 'closing', label: 'Closing' },
];

const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
};

// ----------------------------------------------------------------------

window.$accLedList = 'out';

export default function ListView() {
  const table = useTable();
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();
  const { user } = useContext(AuthContext);
  const permission = UPermission('component', 'AccountLedgerListPage');

  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [filters, setFilters] = useState(defaultFilters);

  const debouncedQuery = useDebounce(filters);

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = count === 0;
  const [selectedRow, setSelectedRow] = useState(1);

  const { page } = table;
  const limit = table.rowsPerPage;

  const getAll = useCallback(async () => {
    setIsLoading(true);
    try {
      let URL = 'transaction/ledgers/';
      URL += `?page=${page + 1}&limit=${limit}&`;
      if (Object.keys(debouncedQuery).length) {
        const nefil = { ...debouncedQuery };
        if (debouncedQuery.status === 'all') {
          delete nefil.status;
        }
        URL += queryString.stringify(nefil);
      }

      const { data } = await fetcher(URL);
      if (data) {
        setCount(data?.count);
        setTableData(data?.rows);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, [debouncedQuery, limit, page]);

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const [editRow, setEditRow] = useState(null);

  useEffect(() => {
    getAll();
  }, [getAll, user.co_year.id]);

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const quickEdit = useBoolean();
  const popover = usePopover();

  const tableEnterKeyListner = () => {
    setEditRow(tableData[selectedRow - 1]);
    quickEdit.onTrue();
    window.$accLedList = 'In';
    popover.onClose();
  };
  useEventListener('keydown', (event) =>
    handleKeyDownInTable(
      event,
      tableData.length + 1,
      selectedRow,
      selectedRow,
      setSelectedRow,
      tableEnterKeyListner,
      1,
      'out'
    )
  );

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Account Ledger List"
          links={[{ name: 'Dashboard', href: paths.home }, { name: 'List' }]}
        />

        <Card>
          <UserTableToolbar
            filters={filters}
            onFilters={handleFilters}
            getAll={getAll}
            permission={permission}
          />

          {canReset && (
            <UserTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              onResetFilters={handleResetFilters}
              results={count}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar sx={{ maxHeight: 400 }}>
              <Table stickyHeader size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  // rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {isLoading ? (
                    <TableRowsLoader rowsNum={table.rowsPerPage} colNum={TABLE_HEAD.length} />
                  ) : (
                    <>
                      {tableData.map((row, index) => (
                        <UserTableRow
                          popover={popover}
                          selectedRow={selectedRow}
                          setSelectedRow={setSelectedRow}
                          setEditRow={setEditRow}
                          quickEdit={quickEdit}
                          tableData={tableData}
                          key={row.id}
                          row={row}
                          index={page * limit + index + 1}
                        />
                      ))}

                      <TableNoData notFound={notFound} />
                    </>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>

      {quickEdit.value && (
        <AUserQuickEditForm
          row={editRow}
          open={quickEdit.value}
          onClose={quickEdit.onFalse}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
    </>
  );
}
