import { m } from 'framer-motion';
import PropTypes from 'prop-types';
// @mui
import Dialog from '@mui/material/Dialog';

import { Divider, Grid, Paper, Toolbar, Typography, alpha } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Iconify from 'src/components/iconify';
import { useCallback, useEffect, useState } from 'react';
import { fetcher } from 'src/utils/axios';
import Badge from '@mui/material/Badge';
import { varHover } from 'src/components/animate';

export default function ActivityListing({ row, open, onClose }) {
  const [activityData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [totalCount, setTotalCount] = useState(0);

  const ActivityFollowup = useCallback(async () => {
    try {
      setLoading(true);
      let URL = `automobile/followups/?aenquiryId=${row?.aenquiryId}`;
      URL += `&page=${1}&limit=${10000}&status=true&astatus=2,3&`;
      const { data } = await fetcher(URL);
      if (data) {
        setActivityData(data?.rows);
        setTotalCount(data?.count);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [row?.aenquiryId]);

  useEffect(() => {
    ActivityFollowup();
  }, [ActivityFollowup]);

  return (
    <Dialog fullScreen open={open}>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          top: 0,
          zIndex: 9,
          position: 'sticky',
          bgcolor: 'primary.dark',
          color: 'white',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            tabIndex={-1}
            onClick={() => {
              onClose();
            }}
          >
            <Iconify icon="material-symbols:arrow-back" />
          </IconButton>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ p: 1, textAlign: 'center', display: 'contents' }}
          >
            <Typography variant="h5" sx={{ flex: 1 }}>
              {row?.aenquiryId} : {row?.aenquiry?.customer?.firstname}{' '}
              {row?.aenquiry?.customer?.lastname}
            </Typography>

            <IconButton
              component={m.button}
              whileTap="tap"
              whileHover="hover"
              variants={varHover(1.05)}
              color="default"
            >
              <Badge badgeContent={totalCount} color="primary" />
            </IconButton>
          </Grid>
        </Toolbar>
        <Divider sx={{ borderStyle: 'dashed' }} />
      </Grid>

      <Grid item xs={12} sm={12} md={12} sx={{ p: 1, textAlign: 'center' }}>
        {loading
          ? 'Loading...'
          : activityData?.map((item, index) => (
              <Paper
                sx={{
                  borderRadius: 2,
                  position: 'relative',
                  bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
                  boxShadow: 2,
                  mt: 2,
                }}
              >
                <Grid container item xs={12} sm={12} sx={{ p: 2 }}>
                  <Grid container item xs={12} sm={12}>
                    <Grid container item xs={12} sm={12} key={index}>
                      <Grid item xs={12} sm={12} sx={{ textAlign: 'left' }}>
                        <Typography variant="subtitle1" color="primary.dark">
                          {index + 1}. {item?.createdAt}
                        </Typography>
                        <Typography variant="subtitle1">{item?.remarks}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            ))}
      </Grid>
    </Dialog>
  );
}

ActivityListing.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  row: PropTypes.object,
};
