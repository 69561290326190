import PropTypes from 'prop-types';
// @mui
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
  alpha,
} from '@mui/material';
import { KEY } from 'src/_mock/constant';
import CompanyYear from 'src/layouts/_common/company-year';
import Iconify from 'src/components/iconify';
import { TableHeadCustom } from 'src/components/table';
import Scrollbar from 'src/components/scrollbar';
import ComponentBlock from '../../sections/_examples/component-block';

export default function Importerrors({
  row,
  open,
  onClose
}) {
  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };
  const TABLE_HEAD = [
    { id: 'ROW NO', label: 'ROW NO' },
    { id: 'COLUM', label: 'COLUM', align: 'right' },
    { id: 'VALUE', label: 'VALUE', align: 'right' },
    { id: 'ERROR', label: 'ERROR', align: 'right' }
  ];
  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 1320 },
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            top: 0,
            zIndex: 111111,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Toolbar>
            <Typography variant="h5" sx={{ flex: 1 }}>
              Import Error
            </Typography>

            <CompanyYear />

            <IconButton
              color="inherit"
              edge="start"
              onClick={() => {
                onClose();
              }}
            >
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>

          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>
      </Grid>

      <ComponentBlock sx={{ overflow: 'scroll' }}>
        <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
          <Scrollbar>
            <Table sx={{ minWidth: 800 }}>
              <TableHeadCustom headLabel={TABLE_HEAD} />

              <TableBody>
                {row.errors.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.row}</TableCell>
                    <TableCell align="right">{row.column}</TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                    <TableCell align="right">{row.error}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </ComponentBlock>
    </Dialog>
  );
}

Importerrors.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
