import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Box, Grid, Typography, alpha } from '@mui/material';
import { Icon } from '@iconify/react';

import { useBoolean } from 'src/hooks/use-boolean';
import Image from 'src/components/image';
import SingleCategoryModel from './SingleCategoryModel';

function CategoryList() {
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false); // Initialize loading state to true
  const [cData, setCData] = useState([]);
  const location = useLocation();
  const data = location.state;

  useEffect(() => {
    if (data?.category?.model?.length) {
      setLoading(true);
      setTimeout(() => {
        setCData(data.category.model);
      }, 1000);
    }
    setLoading(false); // Set loading to false after setting the data
  }, [data?.category?.model]);

  const quickEdit = useBoolean();
  const navigate = useNavigate();

  return (
    <>
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        xxl={12}
        sx={{
          p: 1,
          mb: 1,
          bgcolor: 'primary.dark',
          color: (theme) => alpha(theme.palette.background.default, 1),
          zIndex: 9,
          position: 'fixed',
        }}
      >
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
          <Icon
            icon="ri:arrow-go-back-fill"
            color="primary"
            fontSize="large"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/enquiry/list');
            }}
          />
        </Grid>
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
          <Typography variant="subtitle2">{data?.category?.name}</Typography>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        xxl={12}
        sx={{
          p: 1,
          mb: 5,
        }}
      >
        {loading ? (
          <Typography>Loading...</Typography> // Show loading message
        ) : (
          cData.map((x) => (
            <Box
              key={x.id}
              gap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(3, 1fr)',
                md: 'repeat(4, 1fr)',
              }}
              sx={{ p: 2, mt: 2 }}
              boxShadow=" 0 0 10px 0 rgb(0 0 0 / 10%) !important"
              onClick={() => {
                quickEdit.onTrue();
                setValue(x);
              }}
            >
              <Typography variant="h3">{x.name}</Typography>
              <Image alt={x.name} src={x.image} sx={{ borderRadius: 2 }} />
            </Box>
          ))
        )}
      </Grid>

      <SingleCategoryModel value={value} open={quickEdit.value} onClose={quickEdit.onFalse} />
    </>
  );
}

export default CategoryList;
