import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { create, update } from 'src/utils/axios';
import { KEY, ORDER_TYPE } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { Box, alpha, spacing } from '@mui/system';
import { useEventListener } from 'src/hooks/use-event-listener';
import createData from 'src/sections/_examples/mui/table-view/utils';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { isNaN } from 'lodash';
// ----------------------------------------------------------------------

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();

  const [openModelName, setOpenModelName] = useState('');

  const dialog = useBoolean();

  const departmentSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const values = useMemo(
    () => ({
      name: row?.name || '',
      status: row?.status || false,
      description: row?.description || '',
      balance_method: row?.balance_method || 'Bill To Bill',
      performed_job_details: [
        {
          id: null,
          complaintCode: null,
          billingType: null,
          complaintDesc: null,
          jobCodeName: null,
          otherCharges: null,
          desc: null,
          status: null,
          billingHours: null,
          modelCode: null,
          jobValue: null,
          netJobPrice: null,
          discount: null,
          discountAmount: null,
          remarks: null,
          sacCode: null,
          rate: null,
          billingCategory: null,
          numberofJobs: null,
          modelVarient: null,
          subContractorName: null,
          subContractor: null,
          ofJobForVat: null,
          vatTaxableWcAmount: null,
          discValue: null,
          rateList: null,
          htrFlag: null,
          invoicedFlag: null,
          cgst: null,
          cgstAmount: null,
          sgst: null,
          sgstAmount: null,
          igst: null,
          igstAmount: null,
          utgst: null,
          utgstAmount: null,
          insuranceContribution: null,
          insuranceContributionAmt: null,
          insrcGst: null,
          insrcGstAmount: null,
          insrSGST: null,
          insrSGSTAmount: null,
          insrISGST: null,
          insrISGSTAmount: null,
          insrUTGST: null,
          insrUTGSTAmount: null,
          cess: null,
          cessAmount: null,
        },
      ],
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(departmentSchema),
    values,
  });

  const {
    watch,
    control,
    trigger,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const performed_job_details = useFieldArray({
    control,
    name: 'performed_job_details',
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const onSubmit = handleSubmit(async (payload) => {
    try {
      let URL = 'roles/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'company_banks',
  });

  const handleAdd = useCallback(() => {
    performed_job_details?.append({
      id: null,
      productId: null,
      qty: null,
      rate: null,
      dprice: null,
      drate: null,
      CGST: null,
      SGST: null,
      IGST: null,
    });
  }, [performed_job_details]);

  const handleRemove = useCallback(
    (index) => {
      if (index >= 0 && index !== null) {
        performed_job_details?.remove(index);
      }
    },
    [performed_job_details]
  );

  const PerformedJobsSchema = Yup.object().shape({
    inv_details: Yup.array().of(
      Yup.object().shape({
        productId: Yup.number().nullable().required('Product is required'),
        qty: Yup.number()
          .nullable()
          .min(0)
          .required('Qty is required')
          .transform((value) => (isNaN(value) ? null : value)),
      })
    ),
  });

  const allValues = watch();
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [productList, setProductList] = useState([]);
  const [productEditValues, setProductEditValues] = useState({});
  const quickEditProduct = useBoolean();
  const confirm = useBoolean();

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };
  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 2220 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update Performed Jobs' : 'Create Performed Jobs'}
              </Typography>

              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>

            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          <Grid item container xs={12} md={12} lg={12} xl={12} xxl={12}>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              alignContent="start"
              spacing={2}
              sx={{ p: 2 }}
            >
              <TableContainer
                component={Paper}
                sx={{ overflow: 'auto', maxHeight: '50vh', width: '100%', margin: 'auto' }}
              >
                <Table
                  stickyHeader
                  aria-label="simple table"
                  sx={{
                    minWidth: 9000,
                    '& .MuiTableCell-sizeMedium': {
                      padding: '0px !important',
                    },
                    '& fieldset': { borderRadius: '0px', border: 'none' },
                    '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                    '& .MuiOutlinedInput-root': { padding: '0px !important' },
                    '& .autoComplete>div': { padding: '0px !important' },
                    '& .MuiTableCell-root': {
                      border: '0.01px solid #e9ecee',
                    },
                    '& .MuiTableCell-head': {
                      padding: '5px !important',
                      borderRight: '1px solid #d0d1d2 !important',
                    },
                    '& .MuiTableCell-head:last-child': {
                      borderRight: '0px !important',
                    },
                    '&:last-child td': {
                      borderColor: '#E9ECEE !important',
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: 20, left: 0 }}>No</TableCell>
                      <TableCell sx={{ width: 100 }}>Complaint Code</TableCell>

                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Billing Type</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>
                        Complaint Description
                      </TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Job Code Name</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Other Charges</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Description</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Status</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Billing Hours</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Model Code</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Job Value</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Net Job Price</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Discount%</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Discount Amount</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Remarks</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>SAC Code</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Rate</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Billing Category</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>No Of Jobs</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Model Variant</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>
                        Sub Contractor Name
                      </TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Sub Contractor?</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>%of Job for Vat</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>
                        VAT Taxable WC Amount
                      </TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Discount Value</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Rate List</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>HTR Flag</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Invoiced Flag</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>CGST%</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>CGST Amount</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>SGST%</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>SGST Amount</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>IGST%</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>IGST Amount</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>UTGST%</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>UTGST Amount</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>
                        Insurance contribution %
                      </TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>
                        Insurance contribution amount
                      </TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>INSR CGST%</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>INSR CGST Amount</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>INSR SGST%</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>INSR SGST Amount</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>INSR IGST%</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>INSR IGST Amount</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>INSR UTGST%</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>INSR UTGST Amount</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Cess%</TableCell>
                      <TableCell sx={{ width: 100, textAlign: 'end' }}>Cess Amount </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {performed_job_details?.fields?.map((field, index) => {
                      // const gstRate = nearestDateList(
                      //   productList.find(
                      //     (x) => x.id === allValues.performed_job_details[index]?.productId
                      //   )?.gst_commodity?.gst_com_details
                      // );
                      console.log(allValues);

                      return (
                        <TableRow key={index}>
                          <TableCell
                            sx={{
                              textAlign: 'center',
                              // position: 'sticky',
                              left: 0,
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.productId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
                              autoHighlight
                              key={`${field.productId}-${index}`}
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              fullWidth
                              freeSolo
                              size="small"
                              disableCloseOnSelect
                              options={ORDER_TYPE}
                              getOptionLabel={(option) => option.name}
                              value={
                                ORDER_TYPE.find((data) => data.id === allValues.ORDER_TYPE) || ''
                              }
                              onChange={async (event, newValue) => {
                                setProductEditValues(
                                  ORDER_TYPE?.find((data) => data?.name === newValue?.name)
                                );
                                if (await trigger('in_type')) {
                                  setValue(
                                    `complaintCode`,
                                    ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                                  );
                                }
                              }}
                              groupBy={(option) => option?.a}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="complaintCode"
                                  className="autoComplete"
                                />
                              )}
                              // disabled={!noch(allValues.in_type)}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                  <GroupFooter component="li">
                                    <Button
                                      onClick={() => {
                                        quickEditProduct.onTrue();
                                      }}
                                    >
                                      Add
                                    </Button>

                                    {productEditValues?.id && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            quickEditProduct.onTrue();
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            confirm.onTrue();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    )}
                                  </GroupFooter>
                                </li>
                              )}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.productId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
                              autoHighlight
                              key={`${field.productId}-${index}`}
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              fullWidth
                              freeSolo
                              size="small"
                              disableCloseOnSelect
                              options={ORDER_TYPE}
                              getOptionLabel={(option) => option.name}
                              value={
                                ORDER_TYPE.find((data) => data.id === allValues.ORDER_TYPE) || ''
                              }
                              onChange={async (event, newValue) => {
                                setProductEditValues(
                                  ORDER_TYPE?.find((data) => data?.name === newValue?.name)
                                );
                                if (await trigger('in_type')) {
                                  setValue(
                                    `billingType`,
                                    ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                                  );
                                }
                              }}
                              groupBy={(option) => option?.a}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="billingType"
                                  className="autoComplete"
                                />
                              )}
                              // disabled={!noch(allValues.in_type)}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                  <GroupFooter component="li">
                                    <Button
                                      onClick={() => {
                                        quickEditProduct.onTrue();
                                      }}
                                    >
                                      Add
                                    </Button>

                                    {productEditValues?.id && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            quickEditProduct.onTrue();
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            confirm.onTrue();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    )}
                                  </GroupFooter>
                                </li>
                              )}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="complaintDesc"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.complaintDesc}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`complaintDesc`, qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.productId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
                              autoHighlight
                              key={`${field.productId}-${index}`}
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              fullWidth
                              freeSolo
                              size="small"
                              disableCloseOnSelect
                              options={ORDER_TYPE}
                              getOptionLabel={(option) => option.name}
                              value={
                                ORDER_TYPE.find((data) => data.id === allValues.ORDER_TYPE) || ''
                              }
                              onChange={async (event, newValue) => {
                                setProductEditValues(
                                  ORDER_TYPE?.find((data) => data?.name === newValue?.name)
                                );
                                if (await trigger('in_type')) {
                                  setValue(
                                    'jobCodeName',
                                    ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                                  );
                                }
                              }}
                              groupBy={(option) => option?.a}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="jobCodeName"
                                  className="autoComplete"
                                />
                              )}
                              // disabled={!noch(allValues.in_type)}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                  <GroupFooter component="li">
                                    <Button
                                      onClick={() => {
                                        quickEditProduct.onTrue();
                                      }}
                                    >
                                      Add
                                    </Button>

                                    {productEditValues?.id && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            quickEditProduct.onTrue();
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            confirm.onTrue();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    )}
                                  </GroupFooter>
                                </li>
                              )}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="otherCharges"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.otherCharges}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('otherCharges', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="desc"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.desc}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('desc', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.productId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
                              autoHighlight
                              key={`${field.productId}-${index}`}
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              fullWidth
                              freeSolo
                              size="small"
                              disableCloseOnSelect
                              options={ORDER_TYPE}
                              getOptionLabel={(option) => option.name}
                              value={
                                ORDER_TYPE.find((data) => data.id === allValues.ORDER_TYPE) || ''
                              }
                              onChange={async (event, newValue) => {
                                setProductEditValues(
                                  ORDER_TYPE?.find((data) => data?.name === newValue?.name)
                                );
                                if (await trigger('in_type')) {
                                  setValue(
                                    'status',
                                    ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                                  );
                                }
                              }}
                              groupBy={(option) => option?.a}
                              renderInput={(params) => (
                                <TextField {...params} name="status" className="autoComplete" />
                              )}
                              // disabled={!noch(allValues.in_type)}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                  <GroupFooter component="li">
                                    <Button
                                      onClick={() => {
                                        quickEditProduct.onTrue();
                                      }}
                                    >
                                      Add
                                    </Button>

                                    {productEditValues?.id && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            quickEditProduct.onTrue();
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            confirm.onTrue();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    )}
                                  </GroupFooter>
                                </li>
                              )}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="billingHours"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.billingHours}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('billingHours', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="modelCode"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.modelCode}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('modelCode', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="jobValue"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.jobValue}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('jobValue', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="netJobPrice"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.netJobPrice}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('netJobPrice', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="discount"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.discount}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('discount', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="discountAmount"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.discountAmount}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('discountAmount', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="remarks"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.remarks}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('remarks', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="sacCode"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.sacCode}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('sacCode', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="rate"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.rate}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('rate', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.productId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
                              autoHighlight
                              key={`${field.productId}-${index}`}
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              fullWidth
                              freeSolo
                              size="small"
                              disableCloseOnSelect
                              options={ORDER_TYPE}
                              getOptionLabel={(option) => option.name}
                              value={
                                ORDER_TYPE.find((data) => data.id === allValues.ORDER_TYPE) || ''
                              }
                              onChange={async (event, newValue) => {
                                setProductEditValues(
                                  ORDER_TYPE?.find((data) => data?.name === newValue?.name)
                                );
                                if (await trigger('in_type')) {
                                  setValue(
                                    'billingCategory',
                                    ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                                  );
                                }
                              }}
                              groupBy={(option) => option?.a}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="billingCategory"
                                  className="autoComplete"
                                />
                              )}
                              // disabled={!noch(allValues.in_type)}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                  <GroupFooter component="li">
                                    <Button
                                      onClick={() => {
                                        quickEditProduct.onTrue();
                                      }}
                                    >
                                      Add
                                    </Button>

                                    {productEditValues?.id && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            quickEditProduct.onTrue();
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            confirm.onTrue();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    )}
                                  </GroupFooter>
                                </li>
                              )}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="numberofJobs"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.numberofJobs}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('numberofJobs', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="modelVarient"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.modelVarient}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('modelVarient', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.productId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
                              autoHighlight
                              key={`${field.productId}-${index}`}
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              fullWidth
                              freeSolo
                              size="small"
                              disableCloseOnSelect
                              options={ORDER_TYPE}
                              getOptionLabel={(option) => option.name}
                              value={
                                ORDER_TYPE.find((data) => data.id === allValues.ORDER_TYPE) || ''
                              }
                              onChange={async (event, newValue) => {
                                setProductEditValues(
                                  ORDER_TYPE?.find((data) => data?.name === newValue?.name)
                                );
                                if (await trigger('in_type')) {
                                  setValue(
                                    'subContractorName',
                                    ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                                  );
                                }
                              }}
                              groupBy={(option) => option?.a}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="subContractorName"
                                  className="autoComplete"
                                />
                              )}
                              // disabled={!noch(allValues.in_type)}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                  <GroupFooter component="li">
                                    <Button
                                      onClick={() => {
                                        quickEditProduct.onTrue();
                                      }}
                                    >
                                      Add
                                    </Button>

                                    {productEditValues?.id && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            quickEditProduct.onTrue();
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            confirm.onTrue();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    )}
                                  </GroupFooter>
                                </li>
                              )}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFCheckbox row name="igst_tax" />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="ofJobForVat"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.ofJobForVat}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('ofJobForVat', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="vatTaxableWcAmount"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.vatTaxableWcAmount}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('vatTaxableWcAmount', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="discValue"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.discValue}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('discValue', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.productId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
                              autoHighlight
                              key={`${field.productId}-${index}`}
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              fullWidth
                              freeSolo
                              size="small"
                              disableCloseOnSelect
                              options={ORDER_TYPE}
                              getOptionLabel={(option) => option.name}
                              value={
                                ORDER_TYPE.find((data) => data.id === allValues.ORDER_TYPE) || ''
                              }
                              onChange={async (event, newValue) => {
                                setProductEditValues(
                                  ORDER_TYPE?.find((data) => data?.name === newValue?.name)
                                );
                                if (await trigger('in_type')) {
                                  setValue(
                                    'rateList',
                                    ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                                  );
                                }
                              }}
                              groupBy={(option) => option?.a}
                              renderInput={(params) => (
                                <TextField {...params} name="rateList" className="autoComplete" />
                              )}
                              // disabled={!noch(allValues.in_type)}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                  <GroupFooter component="li">
                                    <Button
                                      onClick={() => {
                                        quickEditProduct.onTrue();
                                      }}
                                    >
                                      Add
                                    </Button>

                                    {productEditValues?.id && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            quickEditProduct.onTrue();
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            confirm.onTrue();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    )}
                                  </GroupFooter>
                                </li>
                              )}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFCheckbox row name="htrFlag" />
                          </TableCell>{' '}
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFCheckbox row name="invoicedFlag" />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="cgst"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.cgst}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('cgst', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="cgstAmount"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.cgstAmount}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('cgstAmount', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="sgst"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.sgst}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('sgst', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="sgstAmount"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.sgstAmount}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('sgstAmount', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="igst"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.igst}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('igst', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="igstAmount"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.igstAmount}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('igstAmount', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="utgst"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.utgst}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('utgst', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="utgstAmount"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.utgstAmount}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('utgstAmount', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="insuranceContribution"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.insuranceContribution}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('insuranceContribution', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="insuranceContributionAmt"
                              size="small"
                              type="number"
                              value={
                                allValues.performed_job_details[index]?.insuranceContributionAmt
                              }
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('insuranceContributionAmt', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="insrcGst"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.insrcGst}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('insrcGst', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="insrcGstAmount"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.insrcGstAmount}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('insrcGstAmount', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="insrSGST"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.insrSGST}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('insrSGST', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="insrSGSTAmount"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.insrSGSTAmount}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('insrSGSTAmount', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="insrISGST"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.insrISGST}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('insrISGST', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="insrISGSTAmount"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.insrISGSTAmount}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('insrISGSTAmount', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="insrUTGST"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.insrUTGST}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('insrUTGST', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="insrUTGSTAmount"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.insrUTGSTAmount}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('insrUTGSTAmount', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="cess"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.cess}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('cess', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                                errors &&
                                errors?.performed_job_details &&
                                errors?.performed_job_details[index] &&
                                errors?.performed_job_details[index]?.qty
                                ? {
                                  border: '0.01px solid #F90635 !important',
                                  textAlign: 'end',
                                }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name="cessAmount"
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.cessAmount}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue('cessAmount', qty);
                              }}
                            // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          {/* <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        <DialogActions
          sx={{
            bottom: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {row ? 'Update' : 'Add'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
