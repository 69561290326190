import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import queryString from 'query-string';
import { useSnackbar } from 'src/components/snackbar';
import { useDebounce } from 'src/hooks/use-debounce';
import { useTable } from 'src/components/table';
//
import { fetcher, destroy } from 'src/utils/axios';
import { useEventListener } from 'src/hooks/use-event-listener';
import { useResponsive } from 'src/hooks/use-responsive';
import { useRouter } from 'src/routes/hooks';
import { formatDate } from 'src/_mock/constant_funcation';
import NotFound from 'src/sections/error/notfound-invoice';
import FilterEnquiry from 'src/sections/work_shop/enquiry/FilterEnquiry';
import ActivityList from '../activity-list';
// import FollowUpQuickEditForm from '../FollowUpQuickEditForm';
import MobileFollowUp from '../MobileFollowup';
import FollowUpQuickEditForm from '../quick-edit-form';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'DeActive' },
];

const TABLE_HEAD = [
  { id: 'no', label: 'No.', width: 90 },
  { id: 'name', label: 'Name' },
  { id: 'status', label: 'Status', width: 100 },
  { id: '', label: 'Action', width: 90 },
];

const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
};

const MAINTABS = [
  {
    value: 'enquiry',
    label: 'Enquiry',
  },
  {
    value: 'activity',
    label: 'Activity',
  },
];

const TABS = [
  {
    value: 'follow_up',
    label: 'Follow-Up',
  },
  // {
  //   value: 'test_ride',
  //   label: 'Test Ride',
  // },
];

const activityStatus = {
  1: 'Scheduled',
  2: 'Closed',
  3: 'Done',
};

// ----------------------------------------------------------------------

export default function ListView() {
  const table = useTable();
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();

  const [followUpDashboard, setFollowUpDashboard] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);

  const [followUpData, setFollowUpData] = useState(null);

  const [filterModel, setFilterModel] = useState({ items: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [enqstatusData, setFollowUpstatusData] = useState();

  const [sortModel, setSortModel] = useState([{ field: 'id', sort: 'desc' }]);

  const [filters, setFilters] = useState(defaultFilters);

  const [isFileManagerVisible, setIsFileManagerVisible] = useState(false);

  const debouncedQuery = useDebounce(filters);

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = count === 0;

  const lgUp = useResponsive('down', 'md');
  const router = useRouter();

  const { page } = table;
  const limit = table.rowsPerPage;

  const [productFilter, setProductFilter] = useState({
    modelCategory: [],
    selectedCategories: [],
    modelName: [],
    selectedModelName: [],
    ModelVariant: [],
    selectedModelVariant: [],
    modelColor: [],
    selectedModelColor: [],
    dseName: [],
    selectedDSEName: [],
    fromDate: new Date(),
    toDate: new Date(),
    search: null,
  });

  const debouncedQuerySearch = useDebounce(productFilter.search);

  const getAll = useCallback(
    async (apply = false) => {
      if ((apply && isFileManagerVisible) || (!isFileManagerVisible && !apply)) {
        setIsLoading(true);

        try {
          let URL = 'automobile/followups/?';

          const query = {
            page: paginationModel.page + 1,
            limit: paginationModel.pageSize,
            sortModel: JSON.stringify(sortModel),
            filterModel: JSON.stringify(filterModel),
          };
          const categoryId = productFilter?.selectedCategories.map((x) => x.id).join(',');
          const modelId = productFilter?.selectedModelName.map((x) => x.id).join(',');
          const variantId = productFilter?.selectedModelVariant.map((x) => x.id).join(',');
          const colourId = productFilter?.selectedModelColor.map((x) => x.id).join(',');
          const DSEId = productFilter?.selectedDSEName.map((x) => x.id).join(',');
          const fromDate = productFilter?.fromDate;
          const toDate = productFilter?.toDate;
          if (categoryId) {
            // eslint-disable-next-line dot-notation
            query['amodcategoryId'] = categoryId;
          }
          if (modelId) {
            // eslint-disable-next-line dot-notation
            query['amodelId'] = modelId;
          }
          if (variantId) {
            // eslint-disable-next-line dot-notation
            query['avariantId'] = variantId;
          }
          if (colourId) {
            // eslint-disable-next-line dot-notation
            query['acolourId'] = colourId;
          }
          if (DSEId) {
            // eslint-disable-next-line dot-notation
            query['salesmanId'] = DSEId;
          }
          if (fromDate) {
            // eslint-disable-next-line dot-notation
            query['fromDate'] = formatDate(fromDate);
          }
          if (toDate) {
            // eslint-disable-next-line dot-notation
            query['toDate'] = formatDate(toDate);
          }

          URL += `${queryString.stringify(query)}&name=${productFilter.search}&`;

          if (enqstatusData) URL += `astatus=${enqstatusData}&`;
          if (Object.keys(debouncedQuery).length) {
            const nefil = { ...debouncedQuery };
            if (debouncedQuery.status === 'all') {
              delete nefil.status;
            }
            // URL += queryString.stringify(nefil);
          }

          const { data } = await fetcher(URL);
          if (data) {
            setCount(data?.count);
            setTableData(data?.rows);
          }
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      }
    },
    [
      debouncedQuery,
      enqstatusData,
      filterModel,
      isFileManagerVisible,
      paginationModel.page,
      paginationModel.pageSize,
      productFilter?.fromDate,
      productFilter.search,
      productFilter?.selectedCategories,
      productFilter?.selectedDSEName,
      productFilter?.selectedModelColor,
      productFilter?.selectedModelName,
      productFilter?.selectedModelVariant,
      productFilter?.toDate,
      sortModel,
    ]
  );

  const getFollowUpDashboard = useCallback(async () => {
    try {
      let URL = 'automobile/followups/dashboard/?';

      const query = {
        page: paginationModel.page + 1,
        limit: paginationModel.pageSize,
        sortModel: JSON.stringify(sortModel),
        filterModel: JSON.stringify(filterModel),
      };
      const categoryId = productFilter?.selectedCategories.map((x) => x.id).join(',');
      const modelId = productFilter?.selectedModelName.map((x) => x.id).join(',');
      const variantId = productFilter?.selectedModelVariant.map((x) => x.id).join(',');
      const colourId = productFilter?.selectedModelColor.map((x) => x.id).join(',');
      const DSEId = productFilter?.selectedDSEName.map((x) => x.id).join(',');
      const fromDate = productFilter?.fromDate;
      const toDate = productFilter?.toDate;
      if (categoryId) {
        // eslint-disable-next-line dot-notation
        query['amodcategoryId'] = categoryId;
      }
      if (modelId) {
        // eslint-disable-next-line dot-notation
        query['amodelId'] = modelId;
      }
      if (variantId) {
        // eslint-disable-next-line dot-notation
        query['avariantId'] = variantId;
      }
      if (colourId) {
        // eslint-disable-next-line dot-notation
        query['acolourId'] = colourId;
      }
      if (DSEId) {
        // eslint-disable-next-line dot-notation
        query['salesmanId'] = DSEId;
      }
      if (fromDate) {
        // eslint-disable-next-line dot-notation
        query['fromDate'] = formatDate(fromDate);
      }
      if (toDate) {
        // eslint-disable-next-line dot-notation
        query['toDate'] = formatDate(toDate);
      }

      URL += `${queryString.stringify(query)}&`;
      if (enqstatusData) URL += `astatus=${enqstatusData}&`;
      if (Object.keys(debouncedQuery).length) {
        const nefil = { ...debouncedQuery };
        if (debouncedQuery.status === 'all') {
          delete nefil.status;
        }
        URL += queryString.stringify(nefil);
      }

      const { data } = await fetcher(URL);
      if (data) {
        setFollowUpDashboard(data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    debouncedQuery,
    enqstatusData,
    filterModel,
    paginationModel.page,
    paginationModel.pageSize,
    productFilter?.fromDate,
    productFilter?.selectedCategories,
    productFilter?.selectedDSEName,
    productFilter?.selectedModelColor,
    productFilter?.selectedModelName,
    productFilter?.selectedModelVariant,
    productFilter?.toDate,
    sortModel,
  ]);

  useEffect(() => {
    getFollowUpDashboard();
  }, [getFollowUpDashboard]);

  const toggleFileManager = () => {
    setIsFileManagerVisible(true);
  };

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleDeleteRows = useCallback(
    async (id) => {
      try {
        let URL = 'roles/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          getAll();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [enqueueSnackbar, getAll]
  );

  useEffect(() => {
    getAll();
  }, [getAll, handleDeleteRows]);

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const quickEdit = useBoolean();
  const mobileFollowUp = useBoolean();

  const handleKeyDown = (event) => {
    if (event.altKey && event.key.toLowerCase() === 'c') {
      event.stopPropagation();
      quickEdit.onTrue();
    }
  };

  useEventListener('keydown', handleKeyDown);

  const [mainCurrentTab, setMainCurrentTab] = useState('activity');
  const [currentTab, setCurrentTab] = useState('follow_up');

  const handleChangeMainTab = useCallback(
    (event, newValue) => {
      setMainCurrentTab(newValue);
      console.log(newValue, 'newValue');
      if (newValue === 'enquiry') router.replace('/sales/enquiry/list');
    },
    [router]
  );

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {!lgUp && (
          <CustomBreadcrumbs
            heading="Activity List"
            settingIcon="fluent:search-12-filled"
            settingTitle="Enquiry Filters"
            openSettingTrue="true"
            openSetting={toggleFileManager}
            links={[{ name: 'Dashboard', href: paths.home }, { name: 'List' }]}
            action={
              <Tooltip title="(Alt + C)" arrow>
                <Button
                  component={RouterLink}
                  variant="contained"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                  onClick={quickEdit.onTrue}
                >
                  Followup
                </Button>
              </Tooltip>
            }
            sx={{
              mb: { xs: 3, md: 5 },
            }}
          />
        )}

        <Grid
          sx={{
            top: 62,
            width: 1,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <FilterEnquiry
            isFileManagerVisible={isFileManagerVisible}
            setIsFileManagerVisible={setIsFileManagerVisible}
            productFilter={productFilter}
            setProductFilter={setProductFilter}
            getAll={getAll}
          />

          {lgUp && (
            <Tabs
              value={mainCurrentTab}
              onChange={handleChangeMainTab}
              sx={{
                mb: { xs: 2, md: 2 },
              }}
              variant="fullWidth"
            >
              {MAINTABS.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  sx={{
                    bgcolor: tab.value === mainCurrentTab && 'primary.dark',
                    color: tab.value === mainCurrentTab && 'common.white',
                  }}
                />
              ))}
            </Tabs>
          )}

          <ActivityList
            followUpDashboard={followUpDashboard}
            enqstatusData={enqstatusData}
            setFollowUpstatusData={setFollowUpstatusData}
          />

          {lgUp && (
            <Tabs value={currentTab} onChange={handleChangeTab} variant="fullWidth">
              {TABS.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          )}
        </Grid>

        {lgUp && (
          <>
            {currentTab === 'follow_up' && mainCurrentTab === 'activity' && (
              <Grid container xs={12} sx={{ mt: 2 }}>
                {tableData?.length ? (
                  tableData.map((data, index) => (
                    <Grid container item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} mt={2}>
                      <Paper
                        sx={{
                          borderRadius: 2,
                          position: 'relative',
                          bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
                          boxShadow: 2,
                        }}
                      >
                        <Grid container item xs={12} sm={12} sx={{ p: 1 }}>
                          <Grid container item xs={12} sm={12}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              sx={{
                                cursor: 'pointer',
                                justifyContent: 'space-between',
                                display: 'flex',
                              }}
                              onClick={() => {
                                mobileFollowUp.onTrue();
                                setFollowUpData(data);
                              }}
                            >
                              <Stack
                                direction="column"
                                alignItems="right"
                                spacing={1}
                                sx={{ color: 'text.secondary', typography: 'caption' }}
                              >
                                <Typography variant="subtitle2">
                                  {data?.aenquiry?.customer?.firstname}{' '}
                                  {data?.aenquiry?.customer?.lastname}
                                </Typography>
                              </Stack>
                              <Stack
                                direction="column"
                                alignItems="right"
                                spacing={1}
                                sx={{
                                  color: 'text.secondary',
                                  typography: 'caption',
                                  bgcolor: 'background.neutral',
                                  px: 1,
                                  borderRadius: 2,
                                }}
                              >
                                Enquiry No. {data?.aenquiryId}
                              </Stack>
                            </Grid>
                          </Grid>
                          <Grid container item xs={6} sm={6}>
                            <Typography variant="subtitle2" color="primary.dark">
                              {data?.aenquiry?.customer?.mobile1}
                            </Typography>
                          </Grid>
                          <Grid container item xs={6} sm={6}>
                            <Typography variant="subtitle2" color="primary.dark">
                              {activityStatus[data?.astatus]}
                            </Typography>
                          </Grid>
                          <Grid container item xs={6} sm={6}>
                            <Typography variant="subtitle2">
                              {data?.aenquiry?.amodcategory?.name}
                            </Typography>
                          </Grid>
                          <Grid container item xs={6} sm={6}>
                            <Typography variant="subtitle2">
                              {data?.aenquiry?.amodel?.name}
                            </Typography>
                          </Grid>
                          <Grid container item xs={12} sm={12}>
                            <Typography variant="subtitle2">
                              {data?.aenquiry?.avariant?.name}
                            </Typography>
                          </Grid>

                          <Grid container item xs={12} sm={12}>
                            <Stack
                              direction="row"
                              spacing={1}
                              sx={{ color: 'text.secondary', typography: 'caption' }}
                            >
                              <Stack direction="row" alignItems="center">
                                <Iconify
                                  icon="solar:calendar-date-bold"
                                  width={16}
                                  sx={{ mr: 0.5 }}
                                />
                                {formatDate(data?.aenquiry?.date)}
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Iconify
                                  icon="solar:calendar-date-bold"
                                  width={16}
                                  sx={{ mr: 0.5 }}
                                />
                                {formatDate(data?.nextdate)}
                              </Stack>

                              <Stack direction="row" alignItems="center">
                                <Iconify
                                  icon="solar:users-group-rounded-bold"
                                  width={16}
                                  sx={{ mr: 0.5 }}
                                />
                                {data?.aenquiry?.salesman?.name}
                              </Stack>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={6}
                    xxl={6}
                    sx={{ display: 'block' }}
                  >
                    <NotFound name="Data" />
                  </Grid>
                )}
              </Grid>
            )}
            {console.log('tableData', tableData)}
            {currentTab === 'test_ride' && mainCurrentTab === 'activity' && (
              <Grid container xs={12} sx={{ mt: 2 }}>
                {tableData?.length ? (
                  tableData.map((data, index) => (
                    <Grid container item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} mt={2}>
                      <Paper
                        sx={{
                          borderRadius: 2,
                          position: 'relative',
                          bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
                          boxShadow: 2,
                        }}
                      >
                        <Grid container item xs={12} sm={12} sx={{ p: 1 }}>
                          <Grid container item xs={12} sm={12}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              sx={{
                                cursor: 'pointer',
                                justifyContent: 'space-between',
                                display: 'flex',
                              }}
                              onClick={quickEdit.onTrue}
                            >
                              <Stack
                                direction="column"
                                alignItems="right"
                                spacing={1}
                                sx={{ color: 'text.secondary', typography: 'caption' }}
                              >
                                <Typography variant="subtitle2">
                                  {data?.aenquiry?.customer?.firstname}{' '}
                                  {data?.aenquiry?.customer?.lastname}
                                </Typography>
                              </Stack>
                              <Stack
                                direction="column"
                                alignItems="right"
                                spacing={1}
                                sx={{
                                  color: 'text.secondary',
                                  typography: 'caption',
                                  bgcolor: 'background.neutral',
                                  px: 1,
                                  borderRadius: 2,
                                }}
                              >
                                Enquiry No. {data?.aenquiryId}
                              </Stack>
                            </Grid>
                          </Grid>
                          <Grid container item xs={6} sm={6}>
                            <Typography variant="subtitle2" color="primary.dark">
                              {data?.aenquiry?.customer?.mobile1}
                            </Typography>
                          </Grid>
                          <Grid container item xs={6} sm={6}>
                            <Typography variant="subtitle2" color="primary.dark">
                              {enqstatusData.toUpperCase()}
                            </Typography>
                          </Grid>
                          <Grid container item xs={6} sm={6}>
                            <Typography variant="subtitle2">
                              {data?.aenquiry?.amodcategory?.name}
                            </Typography>
                          </Grid>
                          <Grid container item xs={6} sm={6}>
                            <Typography variant="subtitle2">
                              {data?.aenquiry?.amodel?.name}
                            </Typography>
                          </Grid>
                          <Grid container item xs={12} sm={12}>
                            <Typography variant="subtitle2">
                              {data?.aenquiry?.avariant?.name}
                            </Typography>
                          </Grid>

                          <Grid container item xs={12} sm={12}>
                            <Stack
                              direction="row"
                              spacing={1}
                              sx={{ color: 'text.secondary', typography: 'caption' }}
                            >
                              <Stack direction="row" alignItems="center">
                                <Iconify
                                  icon="solar:calendar-date-bold"
                                  width={16}
                                  sx={{ mr: 0.5 }}
                                />
                                {formatDate(data?.aenquiry?.date)}
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <Iconify
                                  icon="solar:calendar-date-bold"
                                  width={16}
                                  sx={{ mr: 0.5 }}
                                />
                                {formatDate(data?.nextdate)}
                              </Stack>

                              <Stack direction="row" alignItems="center">
                                <Iconify
                                  icon="solar:users-group-rounded-bold"
                                  width={16}
                                  sx={{ mr: 0.5 }}
                                />
                                {data?.aenquiry?.salesman?.name}
                              </Stack>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={6}
                    xxl={6}
                    sx={{ display: 'block' }}
                  >
                    <NotFound name="Test Ride" />
                  </Grid>
                )}
              </Grid>
            )}
          </>
        )}
      </Container>

      {quickEdit.value && (
        <FollowUpQuickEditForm
          row={null}
          open={quickEdit.value}
          onClose={quickEdit.onFalse}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}

      {mobileFollowUp.value && (
        <MobileFollowUp
          row={followUpData}
          activeTab={currentTab}
          open={mobileFollowUp.value}
          onClose={mobileFollowUp.onFalse}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
    </>
  );
}
