import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Autocomplete,
  Button,
  DialogActions,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { KEY, PARTNER_STATUS, TYPE_OF_ACCOUNTS, WORKING_PARTNER } from 'src/_mock/constant';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import { LoadingButton } from '@mui/lab';
import { UploadAvatar } from 'src/components/upload';
import { DatePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import { HOST_API } from 'src/config-global';
import { useDebounce } from 'src/hooks/use-debounce';
import { AuthContext } from 'src/auth/context/jwt';
import CompanyYear from 'src/layouts/_common/company-year';
import { Stack } from '@mui/system';
import { useBgColorAutocomplete } from 'src/_mock/constant_funcation';

export default function UserQuickEditForm({
  row,
  open,
  onClose,
  table,
  getAll,
  tableData,
  setComLoader,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [groupsList, setGroupsList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [companyStatusList, setCompanyStatusList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadProgressLogo, setUploadProgressLogo] = useState(0);

  const [cityValue, setCityValue] = useState({ key: '', search: '' });

  const { initialize } = useContext(AuthContext);

  const bgColorAutocomplete = useBgColorAutocomplete();

  let com_status = null;

  useEffect(() => {
    if (row?.company) setCompanyList((pre) => [...pre, row.company]);
  }, [row]);

  const validationSchema = Yup.object()
    .shape({
      // gst_no: Yup.string()
      //   .notRequired()
      //   .trim()
      //   .matches(/^[0-9]{2}[0-9A-Z]{10}[0-9]{1}[Z]{1}[A-Z]{1}$/i, 'Invalid GST number'),
      sdate: Yup.date().required('Start date is required'),
      edate: Yup.date().required('End date is required'),
      name: Yup.string().required('Name is required').max(50, 'Name must be at most 50 characters'),
      // short_name: Yup.string().max(50, 'Short name must be at most 50 characters').nullable(),
      // trade_name: Yup.string().max(50, 'Trade name must be at most 50 characters').nullable(),
      groupId: Yup.number().integer().required('Group ID is required'),
      businessId: Yup.number().integer().required('Business ID is required'),
      co_ownId: Yup.number().integer().required('Business ID is required'),
      // enable_audit_trail: Yup.boolean().required('Audit trail status is required'),
      typeof_account: Yup.number()
        .integer()
        .required('Type of account is required')
        .oneOf([1, 2], 'Invalid type of account'),
      // pan_no: Yup.string()
      //   .notRequired()
      //   .trim()
      //   .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i, 'Invalid GST number'),
      // tds_no: Yup.string()
      //   .notRequired()
      //   .trim()
      //   .matches(/^[0-9A-Z]{10}$/i, 'Invalid GST number'),
      // cin_no: Yup.string().max(200, 'CIN number must be at most 200 characters').nullable(),
      // rof_no: Yup.string().max(200, 'ROF number must be at most 200 characters').nullable(),
      // website: Yup.string().max(200, 'Website must be at most 200 characters').nullable(),
      // logo: Yup.string().max(200, 'Logo must be at most 200 characters').required(),
      // pincode: Yup.string().max(100, 'Pincode must be at most 100 characters').nullable(),
      // countryId: Yup.number().integer().required('Country ID is required'),
      // stateId: Yup.number().integer().required('State ID is required'),
      // cityId: Yup.number().integer().required('City ID is required'),
      // district: Yup.string()
      //   .required('District is required')
      //   .max(200, 'District must be at most 200 characters'),
      // address: Yup.string()
      //   .required('Address is required')
      //   .max(512, 'Address must be at most 512 characters'),
      // userId: Yup.number().integer().nullable(),
      // status: Yup.boolean().required('Status is required'),
      // co_ownId: Yup.number()
      //   .integer()
      //   .required('Co-ownership type is required')
      //   .oneOf([1, 2, 3, 4], 'Invalid co-ownership type'),
      com_mems: Yup.array().of(
        Yup.object().shape({
          // photo: Yup.string().max(200, 'Photo must be at most 200 characters').required(),
          name: Yup.string()
            .required('Name is required')
            .max(100, 'Name must be at most 100 characters'),
          // email: Yup.string().email('Invalid email format').required('Email is required'),
          // phone_no: Yup.number().required('Phone number is required'),
          // dob: Yup.date()
          //   .nullable()
          //   .transform((originalValue, originalObject) =>
          //     originalObject?.dob ? Yup.date(originalObject?.dob) : null
          //   ),
          // adno: Yup.string().max(50, 'Adno must be at most 50 characters').nullable(),
          pan_no: Yup.string()
            .required()
            .trim()
            .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i, 'Invalid GST number'),
          // din_no: Yup.string()
          //   .required('DIN number is required')
          //   .max(100, 'DIN number must be at most 100 characters'),
          // work_m: Yup.boolean()
          //   .when('$co_ownId', (co_ownId, schema) =>
          //     [1, 3].includes(com_status) ? schema : schema.required('Work status is required')
          //   )
          //   .nullable(),
          // pl_rat: Yup.number()
          //   .when('$co_ownId', (co_ownId, schema) =>
          //     [1, 3, 4].includes(com_status) ? schema : schema.required('PL Rat is required')
          //   )
          //   .nullable(),
          // m_stat: Yup.number()
          //   .when('$co_ownId', (co_ownId, schema) =>
          //     [1, 3].includes(com_status) ? schema : schema.required('Member Stat is required')
          //   )
          //   .nullable(),
          // rem_tion: Yup.number().nullable(),
          // eff_dat: Yup.date()
          //   .nullable()
          //   .when('com_status', {
          //     is: 1,
          //     then: (schema) => schema,
          //     otherwise: (schema) => schema.required('Effective date is required'),
          //   }),

          // countryId: Yup.number().required('Country ID is required'),
          // stateId: Yup.number().required('State ID is required'),
          // cityId: Yup.number().required('City ID is required'),
          // district: Yup.string()
          //   .required('District is required')
          //   .max(200, 'District must be at most 200 characters'),
          // pincode: Yup.string().max(100, 'Pincode must be at most 100 characters').nullable(),
          // address: Yup.string()
          //   .required('Address is required')
          //   .max(512, 'Address must be at most 512 characters'),
          // companyId: Yup.number().nullable(),
          // status: Yup.boolean(),
        })
      ),
      // co_phs: Yup.array().of(
      //   Yup.object().shape({
      //     phone_no: Yup.number().required('Phone number is required'),
      //   })
      // ),
      // co_ems: Yup.array().of(
      //   Yup.object().shape({
      //     email: Yup.string().email('Invalid email format').required('Email is required'),
      //   })
      // ),
    })
    .defined();

  const values = useMemo(
    () => ({
      state: row?.state || null,
      isHighligh: '',
      isHighlighBranch: '',
      country: row?.country || null,
      cityList: row?.cityList || [],
      logo: row?.logo || null,
      name: row?.name || null,
      short_name: row?.short_name || null,
      trade_name: row?.trade_name || null,
      typeof_account: row?.typeof_account || null,
      groupId: row?.groupId || null,
      businessId: row?.businessId || null,
      gst_no: row?.gst_no || null,
      pan_no: row?.pan_no || null,
      tds_no: row?.tds_no || null,
      status: row?.status || false,
      rof_no: row?.rof_no || null,
      cin_no: row?.cin_no || null,
      sdate: row?.sdate || null,
      edate: row?.edate || null,
      countryId: row?.countryId || null,
      co_ownId: row?.co_ownId || 1,
      stateId: row?.stateId || null,
      cityId: row?.cityId || null,
      district: row?.district || null,
      address: row?.address || null,
      pincode: row?.pincode || null,
      enable_audit_trail: row?.enable_audit_trail || true,
      companyId: row?.companyId || null,

      com_mems: row?.com_mems?.map((data) => ({
        id: data?.id || null,
        photo: data?.photo || null,
        name: data?.name || null,
        email: data?.email || null,
        phone_no: data?.phone_no || null,
        adno: data?.adno || null,
        pan_no: data?.pan_no || null,
        din_no: data?.din_no || null,
        dob: data?.dob || null,
        countryId: data?.countryId || null,
        stateId: data?.stateId || null,
        cityId: data?.cityId || null,
        district: data?.district || null,
        pincode: data?.pincode || null,
        address: data?.address || null,
        pl_rat: data?.pl_rat || null,
        work_m: data?.work_m || null,
        m_stat: data?.m_stat || null,
        rem_tion: data?.rem_tion || null,
        eff_dat: data?.eff_dat || null,
        state: data?.state,
        country: data?.country,
        cityList: data?.cityList || [],
      })) || [
          {
            id: null,
            photo: null,
            name: null,
            email: null,
            phone_no: null,
            adno: null,
            pan_no: null,
            din_no: null,
            dob: null,
            countryId: null,
            stateId: null,
            cityId: null,
            district: null,
            pincode: null,
            address: null,
            pl_rat: null,
            work_m: null,
            m_stat: null,
            rem_tion: null,
            eff_dat: null,
            state: null,
            country: null,
            cityList: [],
          },
        ],
      co_phs: row?.co_phs?.length
        ? row?.co_phs?.map((data) => ({
          id: data?.id || null,
          phone_no: data?.phone_no || null,
        }))
        : [
          {
            phone_no: null,
          },
        ],
      co_ems: row?.co_ems?.length
        ? row?.co_ems?.map((data) => ({
          id: data?.id || null,
          email: data?.email || null,
        }))
        : [
          {
            email: null,
          },
        ],
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    values,
  });

  const {
    reset,
    control,
    watch,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = methods;

  const allValues = watch();

  com_status = allValues.co_ownId;

  useEffect(() => {
    if (allValues?.gst_no && allValues?.gst_no.length === 15) {
      const panNumber = allValues?.gst_no.substring(2, 12);
      setValue('pan_no', panNumber);
      if (allValues.co_ownId === 1) setValue(`com_mems[0].pan_no`, panNumber);
      else setValue(`com_mems[0].pan_no`, null);
    } else {
      setValue('pan_no', null);
    }
  }, [allValues.co_ownId, allValues?.gst_no, setValue]);

  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      let URL = 'company/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
          setComLoader(true);
        }
      }
      setLoading(false);
      reset();
      initialize();
    } catch (error) {
      setLoading(false);
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if (!loading) {
        await trigger();
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  const searchCity = useDebounce(cityValue);

  const getAllCompany = useCallback(async () => {
    try {
      let URL = 'company/';
      URL += `?page=${1}&limit=${10}&name=${allValues?.isHighlighBranch
        }&companyId=null&businessId=${allValues?.businessId}`;

      const { data } = await fetcher(URL);
      if (data) {
        if (row?.id) setCompanyList(data?.rows.filter((x) => x.id !== row?.id));
        else setCompanyList(data?.rows);
      }
    } catch (error) {
      console.error(error);
    }
  }, [allValues?.businessId, allValues?.isHighlighBranch, row?.id]);

  useEffect(() => {
    if (allValues?.businessId || allValues?.isHighlighBranch) getAllCompany();
  }, [allValues?.businessId, allValues?.isHighlighBranch, getAllCompany]);

  const HandleCityList = useCallback(async () => {
    let URL = 'cities/';
    URL += `?page=${1}&limit=${100}&name=${searchCity.search}&status=true`;

    const { data } = await fetcher(URL);
    if (searchCity.key) {
      setValue(searchCity.key, data?.rows);
    }
  }, [searchCity, setValue]);

  useEffect(() => {
    if (open) {
      HandleCityList();
    }
  }, [HandleCityList, open]);

  const HandleCompanyStatusList = async () => {
    let URL = 'com_owns/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setCompanyStatusList(data?.rows);
  };

  const HandleGroupsList = async () => {
    let URL = 'groups/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setGroupsList(data?.rows);
  };

  const HandleBusinessList = async () => {
    let URL = 'businesses/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setBusinessList(data?.rows);
  };

  useEffect(() => {
    if (open) {
      HandleGroupsList();
      HandleCompanyStatusList();
      HandleBusinessList();
      HandleBusinessList();
    }
  }, [open]);

  const com_mems = useFieldArray({
    control,
    name: 'com_mems',
    rules: { minLength: allValues.co_ownId === 1 ? 1 : 2 },
  });

  const co_phs = useFieldArray({
    control,
    name: 'co_phs',
  });

  const co_ems = useFieldArray({
    control,
    name: 'co_ems',
  });

  const handleDropAvatar = useCallback(
    async (acceptedFiles, fieldName) => {
      const newFile = acceptedFiles[0];

      try {
        const URL = `${HOST_API}upload`;

        if (newFile) {
          setValue(fieldName, null);
        }

        const formData = new FormData();
        formData.append('file', newFile);
        console.log(newFile, 'newFile');

        const response = await axios.post(URL, formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            if (fieldName === 'logo') {
              setUploadProgressLogo(percentCompleted);
            } else {
              setUploadProgress(percentCompleted);
            }
          },
        });
        setValue(fieldName, response.data.filename);
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
      }
    },
    [enqueueSnackbar, setValue]
  );

  const handleAdd = () => {
    com_mems?.append({
      id: null,
      photo: null,
      name: null,
      email: null,
      phone_no: null,
      adno: null,
      pan_no: null,
      din_no: null,
      dob: null,
      countryId: null,
      stateId: null,
      cityId: null,
      district: null,
      pincode: null,
      address: null,
      pl_rat: null,
      work_m: null,
      m_stat: null,
      rem_tion: null,
      eff_dat: null,
      state: null,
      country: null,
      cityList: [],
    });
  };

  const handleRemove = (index) => {
    com_mems?.remove(index);
  };

  const handleAddPhone = () => {
    co_phs?.append({
      phone_no: null,
    });
  };

  const handleRemovePhone = (index) => {
    co_phs?.remove(index);
  };

  const handleAddEmail = () => {
    co_ems?.append({
      email: null,
    });
  };

  const handleRemoveEmail = (index) => {
    co_ems?.remove(index);
  };

  return (
    <Dialog fullScreen open={open} onKeyDown={handleKeyDown}>
      <FormProvider methods={methods}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 111111,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update Company ' : 'Add Company'}
              </Typography>

              <CompanyYear />

              <IconButton
                color="inherit"
                edge="start"
                onClick={() => {
                  onClose();
                }}
              >
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>

            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          <Grid container item xs={12} md={12} sx={{ p: 1 }}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              xxl={2}
              sx={{ display: 'block' }}
            >
              <UploadAvatar
                file={`${HOST_API}uploads/medium/${allValues.logo}`}
                name="logo"
                onDrop={(e) => handleDropAvatar(e, 'logo')}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 1,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.disabled',
                    }}
                  >
                    Upload Logo
                  </Typography>
                }
                error={errors?.logo && !allValues?.logo}
                uploadProgressLogo={uploadProgressLogo}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              spacing={2}
              alignContent="flex-start"
              sx={{ p: 2 }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Typography variant="subtitle1">Main Details</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                <RHFTextField
                  name="name"
                  label={
                    <span>
                      Company Name <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  size="small"
                  autoFocus
                  value={allValues.name}
                  onChange={(e) => {
                    setValue('name', e.target.value.toUpperCase());
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField
                  name="short_name"
                  label="Short Name"
                  size="small"
                  value={allValues.short_name}
                  onChange={(e) => {
                    setValue('short_name', e.target.value.toUpperCase());
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField name="trade_name" label="Trade Name" size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Autocomplete
                  autoHighlight={allValues.isHighligh}
                  fullWidth
                  freeSolo
                  size="small"
                  openOnFocus
                  onInputChange={(event, newValue) => {
                    setValue('isHighligh', newValue);
                  }}
                  onBlur={() => {
                    setValue('isHighligh', '');
                  }}
                  options={TYPE_OF_ACCOUNTS?.map((option) => option.name)}
                  getOptionLabel={(option) => option}
                  value={
                    TYPE_OF_ACCOUNTS?.find((data) => data.id === allValues.typeof_account)?.name ||
                    ''
                  }
                  onChange={(event, newValue) => {
                    setValue(
                      'typeof_account',
                      TYPE_OF_ACCOUNTS?.find((data) => data.name === newValue)?.id
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="typeof_account"
                      label={
                        <span>
                          Type of Account <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      error={errors?.typeof_account && !allValues?.typeof_account}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                  ListboxProps={{ ...bgColorAutocomplete }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Autocomplete
                  autoHighlight={allValues.isHighligh}
                  fullWidth
                  freeSolo
                  size="small"
                  openOnFocus
                  onInputChange={(event, newValue) => {
                    setValue('isHighligh', newValue);
                  }}
                  onBlur={() => {
                    setValue('isHighligh', '');
                  }}
                  options={groupsList?.map((option) => option.name)}
                  getOptionLabel={(option) => option}
                  value={groupsList?.find((data) => data.id === allValues.groupId)?.name || ''}
                  onChange={(event, newValue) => {
                    setValue('groupId', groupsList?.find((data) => data.name === newValue)?.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="groupId"
                      label={
                        <span>
                          Select Group <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      error={errors?.groupId && !allValues?.groupId}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                  ListboxProps={{ ...bgColorAutocomplete }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Autocomplete
                  autoHighlight={allValues.isHighligh}
                  fullWidth
                  freeSolo
                  size="small"
                  openOnFocus
                  onInputChange={(event, newValue) => {
                    setValue('isHighligh', newValue);
                  }}
                  onBlur={() => {
                    setValue('isHighligh', '');
                  }}
                  options={businessList?.map((option) => option.name)}
                  getOptionLabel={(option) => option}
                  value={businessList?.find((data) => data.id === allValues.businessId)?.name || ''}
                  onChange={(event, newValue) => {
                    setValue(
                      'businessId',
                      businessList?.find((data) => data.name === newValue)?.id
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="businessId"
                      label={
                        <span>
                          Select Businesses <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      error={errors?.businessId && !allValues?.businessId}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                  ListboxProps={{ ...bgColorAutocomplete }}
                />
              </Grid>
              {allValues?.businessId !== 1 && (
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                  <Autocomplete
                    autoHighlight={allValues.isHighlighBranch}
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighlighBranch', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighlighBranch', '');
                    }}
                    options={companyList?.map((option) => option?.name)}
                    getOptionLabel={(option) => option}
                    value={
                      companyList?.find((data) => data?.id === allValues.companyId)?.name || ''
                    }
                    onChange={(event, newValue) => {
                      if (newValue)
                        setValue(
                          'companyId',
                          companyList?.find((data) => data?.name === newValue)?.id
                        );
                      else setValue('companyId', null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="companyId"
                        label="Branch of"
                        error={errors?.companyId && !allValues?.companyId}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField
                  name="gst_no"
                  label="GST No."
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField
                  name="pan_no"
                  label="Pan No."
                  size="small"
                  value={allValues?.pan_no ? allValues?.pan_no : ''}
                  InputLabelProps={{ shrink: allValues?.pan_no && true }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField name="tds_no" label="TDS No." size="small" />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <Autocomplete
                  autoHighlight={allValues.isHighligh}
                  fullWidth
                  freeSolo
                  size="small"
                  openOnFocus
                  onInputChange={(event, newValue) => {
                    setValue('isHighligh', newValue);
                  }}
                  onBlur={() => {
                    setValue('isHighligh', '');
                  }}
                  options={companyStatusList?.map((option) => option.name)}
                  getOptionLabel={(option) => option}
                  value={
                    companyStatusList?.find((data) => data.id === allValues.co_ownId)?.name || ''
                  }
                  onChange={(event, newValue) => {
                    setValue(
                      'co_ownId',
                      companyStatusList?.find((data) => data.name === newValue)?.id
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="co_ownId"
                      label={
                        <span>
                          Status <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      error={errors?.co_ownId && !allValues?.co_ownId}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                  ListboxProps={{ ...bgColorAutocomplete }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                <RHFCheckbox name="status" label="Active" size="small" />
              </Grid>

              {allValues.co_ownId === 2 && (
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <RHFTextField name="rof_no" label="ROF No." size="small" />
                </Grid>
              )}
              {allValues.co_ownId === 4 && (
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <RHFTextField name="cin_no" label="CIN No." size="small" />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Typography variant="subtitle1">Financial Year</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Controller
                  name="sdate"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      defaultValue={field.value ? new Date(field.value) : null}
                      timezone="Asia/Kolkata"
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      format="dd/MM/yyyy"
                      label={
                        <span>
                          Start Date <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                          size: 'small',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Controller
                  name="edate"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      defaultValue={field.value ? new Date(field.value) : null}
                      timezone="Asia/Kolkata"
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      format="dd/MM/yyyy"
                      label={
                        <span>
                          End Date <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                          size: 'small',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              xxl={4}
              spacing={2}
              alignContent="flex-start"
              sx={{ p: 2 }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Typography variant="subtitle1">Contact Details</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="address" label="Address" size="small" multiline rows={2} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <RHFTextField name="district" label="Area" size="small" />
              </Grid>

              <Grid item xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
                <Autocomplete
                  autoHighlight={allValues.isHighligh}
                  fullWidth
                  size="small"
                  openOnFocus
                  onBlur={() => {
                    setValue('isHighligh', '');
                  }}
                  options={allValues.cityList?.map((option) => option.name)}
                  value={allValues.cityList?.find((x) => x.id === allValues.cityId)?.name || ''}
                  onInputChange={(event, newInputValue) => {
                    const city = allValues.cityList?.find((x) => x.name === newInputValue);
                    setValue('isHighligh', newInputValue);
                    if (newInputValue) {
                      setCityValue({ key: 'cityList', search: newInputValue });
                      if (city) {
                        reset({
                          ...allValues,
                          cityId: city.id,
                          stateId: city.state.id,
                          countryId: city.country.id,
                          state: city.state,
                          country: city.country,
                        });
                      }
                    }
                  }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} name="cityId" label="Select City" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                  ListboxProps={{ ...bgColorAutocomplete }}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                <RHFTextField name="pincode" label="Pincode" size="small" type="number" />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  freeSolo
                  size="small"
                  options={[allValues?.state]?.map((option) => option?.name)}
                  getOptionLabel={(option) => option}
                  value={allValues?.state?.name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="stateId"
                      label="Select State"
                      error={errors?.stateId && !allValues?.stateId}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                  disabled="true"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  freeSolo
                  size="small"
                  options={[allValues?.country]?.map((option) => option?.name)}
                  getOptionLabel={(option) => option}
                  value={allValues?.country?.name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="countryId"
                      label="Select Country"
                      error={errors?.countryId && !allValues?.countryId}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                  disabled="true"
                />
              </Grid>

              {co_ems?.fields?.map((fieldName, index) => (
                <>
                  <Grid item xs={12} sm={10} md={10} lg={10} xl={10} xxl={10} sx={{ p: 1 }}>
                    <RHFTextField name={`co_ems[${index}].email`} label="Email" size="small" />
                  </Grid>
                  {co_ems?.fields?.length > 1 && (
                    <Grid item xs={12} sm={1} md={1} lg={1} xl={1} xxl={1} sx={{ p: 1 }}>
                      <Tooltip title="Remove Email" arrow>
                        <IconButton color="error" onClick={() => handleRemoveEmail(index)}>
                          <Iconify icon="solar:trash-bin-trash-bold" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </>
              ))}
              <Grid item xs={12} sm={1} md={1} lg={1} xl={1} xxl={1}>
                <Tooltip title="Add Email" arrow onClick={handleAddEmail}>
                  <IconButton>
                    <Iconify icon="mingcute:add-line" />
                  </IconButton>
                </Tooltip>
              </Grid>

              {co_phs?.fields?.map((fieldName, index) => (
                <>
                  <Grid item xs={12} sm={10} md={10} lg={10} xl={10} xxl={10} sx={{ p: 1 }}>
                    <RHFTextField
                      name={`co_phs[${index}].phone_no`}
                      label="Mobile"
                      size="small"
                      type="number"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.length <= 10)
                          setValue(`co_phs[${index}].phone_no`, e.target.value);
                      }}
                    />
                  </Grid>
                  {co_phs?.fields?.length > 1 && (
                    <Grid item xs={12} sm={1} md={1} lg={1} xl={1} xxl={1} sx={{ p: 1 }}>
                      <Tooltip title="Remove Mobile" arrow>
                        <IconButton color="error" onClick={() => handleRemovePhone(index)}>
                          <Iconify icon="solar:trash-bin-trash-bold" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </>
              ))}

              <Grid item xs={12} sm={1} md={1} lg={1} xl={1} xxl={1}>
                <Tooltip title="Add Mobile" arrow onClick={handleAddPhone}>
                  <IconButton>
                    <Iconify icon="mingcute:add-line" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} md={12} sx={{ p: 1 }} mb={2}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              spacing={2}
              alignContent="flex-start"
              sx={{ p: 2 }}
            >
              {com_mems?.fields?.map((fieldName, index) => (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        p: 2,
                      }}
                    >
                      <Grid item xs={12} sm={12} md={11} lg={11} xl={11} xxl={11}>
                        <Typography variant="subtitle1">
                          {allValues.co_ownId === 1 && 'Personal Details'}
                          {allValues.co_ownId === 2 && 'Partners Details'}
                          {allValues.co_ownId === 3 && 'Family Memeber Details'}
                          {allValues.co_ownId === 4 && 'Director Details'}
                        </Typography>
                      </Grid>
                      {allValues.co_ownId !== 1 && (
                        <Grid item xs={12} sm={12} md={1} lg={1} xl={1} xxl={1}>
                          <Tooltip title="Remove" arrow>
                            <IconButton color="error" onClick={() => handleRemove(index)}>
                              <Iconify icon="solar:trash-bin-trash-bold" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}

                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Divider sx={{ borderStyle: 'dashed' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    spacing={2}
                    alignContent="flex-start"
                  >
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                      <UploadAvatar
                        file={`${HOST_API}uploads/medium/${allValues.com_mems[index]?.photo}`}
                        name="photo"
                        onDrop={(e) => handleDropAvatar(e, `com_mems[${index}].photo`)}
                        helperText={
                          <Typography
                            variant="caption"
                            sx={{
                              mt: 1,
                              mx: 'auto',
                              display: 'block',
                              textAlign: 'center',
                              color: 'text.disabled',
                            }}
                          >
                            Upload Image
                          </Typography>
                        }
                        error={
                          errors?.com_mems?.length >= 0 &&
                          errors?.com_mems[0].photo &&
                          !allValues?.com_mems[0].photo
                        }
                        uploadProgress={uploadProgress}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={10}
                      lg={10}
                      xl={10}
                      xxl={10}
                      alignContent="flex-start"
                    >
                      <Grid item xs={12} sm={12} md={5} lg={5} xl={5} xxl={5}>
                        <RHFTextField
                          name={`com_mems[${index}].name`}
                          label={
                            <span>
                              Name <span style={{ color: 'red' }}>*</span>
                            </span>
                          }
                          size="small"
                          error={Boolean(errors.com_mems?.[index]?.name)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <RHFTextField
                          name={`com_mems[${index}].email`}
                          label="Email"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                        <RHFTextField
                          name={`com_mems[${index}].phone_no`}
                          label="Mobile"
                          size="small"
                          type="number"
                        />
                      </Grid>
                      {allValues.co_ownId !== 3 && (
                        <>
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                            <RHFTextField
                              name={`com_mems[${index}].adno`}
                              label="Aadhar No."
                              size="small"
                              type="number"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                            <RHFTextField
                              name={`com_mems[${index}].pan_no`}
                              label={
                                <span>
                                  Pan No. <span style={{ color: 'red' }}>*</span>
                                </span>
                              }
                              size="small"
                              value={
                                allValues?.com_mems[index].pan_no
                                  ? allValues?.com_mems[index].pan_no
                                  : ''
                              }
                              InputLabelProps={{
                                shrink: allValues?.com_mems[index].pan_no && true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                            <RHFTextField
                              name={`com_mems[${index}].din_no`}
                              label="DIN No."
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                            <Controller
                              name={`com_mems[${index}].dob`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                  defaultValue={field.value ? new Date(field.value) : null}
                                  timezone="Asia/Kolkata"
                                  onChange={(newValue) => {
                                    field.onChange(newValue);
                                  }}
                                  label="Date of Birth"
                                  format="dd/MM/yyyy"
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      error: !!error,
                                      helperText: error?.message,
                                      size: 'small',
                                    },
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}
                      {allValues.co_ownId === 3 && (
                        <>
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                            <RHFTextField
                              name={`com_mems[${index}].adno`}
                              label="Aadhar No."
                              size="small"
                              type="number"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                            <RHFTextField
                              name={`com_mems[${index}].pan_no`}
                              label="Pan No."
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                            <RHFTextField
                              name={`com_mems[${index}].din_no`}
                              label="DIN No."
                              size="small"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                            <Controller
                              name={`com_mems[${index}].dob`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                  defaultValue={field.value ? new Date(field.value) : null}
                                  timezone="Asia/Kolkata"
                                  onChange={(newValue) => {
                                    field.onChange(newValue);
                                  }}
                                  label="Date of Birth"
                                  format="dd/MM/yyyy"
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      error: !!error,
                                      helperText: error?.message,
                                      size: 'small',
                                    },
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                            <Controller
                              name={`com_mems[${index}].eff_dat`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                  defaultValue={field.value ? new Date(field.value) : null}
                                  timezone="Asia/Kolkata"
                                  onChange={(newValue) => {
                                    field.onChange(newValue);
                                  }}
                                  label="Effactive Date"
                                  format="dd/MM/yyyy"
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      error: !!error,
                                      helperText: error?.message,
                                      size: 'small',
                                    },
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}
                      {allValues.co_ownId === 2 && (
                        <>
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                            <Autocomplete
                              autoHighlight={allValues.isHighligh}
                              fullWidth
                              freeSolo
                              size="small"
                              openOnFocus
                              onInputChange={(event, newValue) => {
                                setValue('isHighligh', newValue);
                              }}
                              onBlur={() => {
                                setValue('isHighligh', '');
                              }}
                              options={WORKING_PARTNER?.map((option) => option.name)}
                              getOptionLabel={(option) => option}
                              value={
                                WORKING_PARTNER?.find(
                                  (data) => data.id === allValues.com_mems[index].work_m
                                )?.name || ''
                              }
                              onChange={(event, newValue) => {
                                setValue(
                                  `com_mems[${index}].work_m`,
                                  WORKING_PARTNER?.find((data) => data.name === newValue)?.id
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`com_mems[${index}].work_m`}
                                  label={
                                    <span>
                                      Working Partner <span style={{ color: 'red' }}>*</span>
                                    </span>
                                  }
                                  error={
                                    (errors &&
                                      errors.com_mems?.length &&
                                      errors.com_mems[index]?.work_m) ||
                                    allValues.com_mems[index]?.work_m == null
                                  }
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={option}>
                                  {option}
                                </li>
                              )}
                              ListboxProps={{ ...bgColorAutocomplete }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                            <Autocomplete
                              autoHighlight={allValues.isHighligh}
                              fullWidth
                              freeSolo
                              size="small"
                              openOnFocus
                              onInputChange={(event, newValue) => {
                                setValue('isHighligh', newValue);
                              }}
                              onBlur={() => {
                                setValue('isHighligh', '');
                              }}
                              options={PARTNER_STATUS?.map((option) => option.name)}
                              getOptionLabel={(option) => option}
                              value={
                                PARTNER_STATUS?.find(
                                  (data) => data.id === allValues.com_mems[index].m_stat
                                )?.name || ''
                              }
                              onChange={(event, newValue) => {
                                setValue(
                                  `com_mems[${index}].m_stat`,
                                  PARTNER_STATUS?.find((data) => data.name === newValue)?.id
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`com_mems[${index}].m_stat`}
                                  label="Partner Status"
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={option}>
                                  {option}
                                </li>
                              )}
                              ListboxProps={{ ...bgColorAutocomplete }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                            <RHFTextField
                              name={`com_mems[${index}].pl_rat`}
                              label="P & L Ratio"
                              size="small"
                              type="number"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                            <RHFTextField
                              name={`com_mems[${index}].rem_tion`}
                              label="Rate of Interest(%)"
                              size="small"
                              type="number"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                            <Controller
                              name={`com_mems[${index}].eff_dat`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                  defaultValue={field.value ? new Date(field.value) : null}
                                  timezone="Asia/Kolkata"
                                  onChange={(newValue) => {
                                    field.onChange(newValue);
                                  }}
                                  label="Effactive Date"
                                  format="dd/MM/yyyy"
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      error: !!error,
                                      helperText: error?.message,
                                      size: 'small',
                                    },
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}
                      {allValues.co_ownId === 4 && (
                        <>
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                            <Autocomplete
                              autoHighlight={allValues.isHighligh}
                              fullWidth
                              freeSolo
                              size="small"
                              openOnFocus
                              onInputChange={(event, newValue) => {
                                setValue('isHighligh', newValue);
                              }}
                              onBlur={() => {
                                setValue('isHighligh', '');
                              }}
                              options={WORKING_PARTNER?.map((option) => option.name)}
                              getOptionLabel={(option) => option}
                              value={
                                WORKING_PARTNER?.find(
                                  (data) => data.id === allValues.com_mems[index].work_m
                                )?.name || ''
                              }
                              onChange={(event, newValue) => {
                                setValue(
                                  `com_mems[${index}].work_m`,
                                  WORKING_PARTNER?.find((data) => data.name === newValue)?.id
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`com_mems[${index}].work_m`}
                                  label="Working Partner"
                                  error={
                                    (errors &&
                                      errors.com_mems?.length &&
                                      errors.com_mems[index]?.work_m) ||
                                    allValues.com_mems[index]?.work_m == null
                                  }
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={option}>
                                  {option}
                                </li>
                              )}
                              ListboxProps={{ ...bgColorAutocomplete }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                            <Autocomplete
                              autoHighlight={allValues.isHighligh}
                              fullWidth
                              freeSolo
                              size="small"
                              openOnFocus
                              onInputChange={(event, newValue) => {
                                setValue('isHighligh', newValue);
                              }}
                              onBlur={() => {
                                setValue('isHighligh', '');
                              }}
                              options={PARTNER_STATUS?.map((option) => option.name)}
                              getOptionLabel={(option) => option}
                              value={
                                PARTNER_STATUS?.find(
                                  (data) => data.id === allValues.com_mems[index].m_stat
                                )?.name || ''
                              }
                              onChange={(event, newValue) => {
                                setValue(
                                  `com_mems[${index}].m_stat`,
                                  PARTNER_STATUS?.find((data) => data.name === newValue)?.id
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`com_mems[${index}].m_stat`}
                                  label="Director Status"
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={option}>
                                  {option}
                                </li>
                              )}
                              ListboxProps={{ ...bgColorAutocomplete }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                            <RHFTextField
                              name={`com_mems[${index}].rem_tion`}
                              label="Rate of Interest(%)"
                              size="small"
                              type="number"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                            <Controller
                              name={`com_mems[${index}].eff_dat`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                  defaultValue={field.value ? new Date(field.value) : null}
                                  timezone="Asia/Kolkata"
                                  onChange={(newValue) => {
                                    field.onChange(newValue);
                                  }}
                                  label="Effactive Date"
                                  format="dd/MM/yyyy"
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      error: !!error,
                                      helperText: error?.message,
                                      size: 'small',
                                    },
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <RHFTextField
                          name={`com_mems[${index}].address`}
                          label="Address"
                          size="small"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <RHFTextField
                          name={`com_mems[${index}].district`}
                          label="Area"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3} xl={3} xxl={3}>
                        <RHFTextField
                          name={`com_mems[${index}].pincode`}
                          label="Pincode"
                          size="small"
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3} xl={3} xxl={3}>
                        <Autocomplete
                          autoHighlight={allValues.isHighligh}
                          fullWidth
                          name={`com_mems[${index}].cityId`}
                          size="small"
                          openOnFocus
                          onBlur={() => {
                            setValue('isHighligh', '');
                          }}
                          value={
                            allValues.com_mems[index]?.cityList?.find(
                              (x) => x.id === allValues.com_mems[index]?.cityId
                            )?.name || ''
                          }
                          options={allValues.com_mems[index]?.cityList?.map(
                            (option) => option?.name
                          )}
                          onInputChange={(event, newInputValue) => {
                            const city = allValues.com_mems[index]?.cityList?.find(
                              (x) => x.name === newInputValue
                            );
                            setValue('isHighligh', newInputValue);
                            if (newInputValue) {
                              setCityValue({
                                key: `com_mems[${index}].cityList`,
                                search: newInputValue,
                              });
                              if (city) {
                                allValues.com_mems[index].cityId = city.id;
                                allValues.com_mems[index].stateId = city.state.id;
                                allValues.com_mems[index].countryId = city.country.id;
                                allValues.com_mems[index].state = city.state;
                                allValues.com_mems[index].country = city.country;
                                reset({
                                  ...allValues,
                                });
                              }
                            }
                          }}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={`com_mems[${index}].cityId`}
                              label="Select City"
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option}>
                              {option}
                            </li>
                          )}
                          ListboxProps={{ ...bgColorAutocomplete }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={3} lg={3} xl={3} xxl={3}>
                        <Autocomplete
                          autoHighlight
                          fullWidth
                          freeSolo
                          size="small"
                          options={[allValues.com_mems[index]?.state]?.map(
                            (option) => option?.name
                          )}
                          getOptionLabel={(option) => option}
                          value={allValues.com_mems[index]?.state?.name || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={`com_mems[${index}].stateId`}
                              label="Select State"
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option}>
                              {option}
                            </li>
                          )}
                          disabled="true"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3} xl={3} xxl={3}>
                        <Autocomplete
                          autoHighlight
                          fullWidth
                          freeSolo
                          size="small"
                          options={[allValues?.com_mems[index]?.country]?.map(
                            (option) => option?.name
                          )}
                          getOptionLabel={(option) => option}
                          value={allValues?.com_mems[index]?.country?.name || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={`com_mems[${index}].countryId`}
                              label="Select Country"
                              error={errors?.countryId && !allValues?.com_mems[index]?.countryId}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option}>
                              {option}
                            </li>
                          )}
                          disabled="true"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ))}

              {allValues.co_ownId !== 1 && (
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} sx={{ m: 3 }}>
                  <Button
                    size="small"
                    color="primary"
                    startIcon={<Iconify icon="mingcute:add-line" />}
                    onClick={handleAdd}
                    sx={{ flexShrink: 0 }}
                  >
                    Add
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <DialogActions
          sx={{
            bottom: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Stack
            direction="row-reverse"
            alignItems="center"
            sx={{
              width: '100%',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Tooltip title="(Alt + A)" arrow>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={async () => {
                  await trigger();
                  onSubmit();
                }}
              >
                {row ? 'Update' : 'Add'}
              </LoadingButton>
            </Tooltip>

            <Tooltip title="(Esc)" arrow>
              <Button variant="outlined" onClick={onClose} sx={{ mr: 2 }}>
                Cancel
              </Button>
            </Tooltip>

            <Tooltip title="(Reset)" arrow>
              <Button variant="soft" onClick={() => reset(values)} sx={{ mr: 2 }}>
                Reset
              </Button>
            </Tooltip>
          </Stack>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
  setComLoader: PropTypes.bool,
};
