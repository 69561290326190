import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { create, update } from 'src/utils/axios';
import { KEY } from 'src/_mock/constant';
import { Divider, Grid, IconButton, Toolbar, Tooltip, Typography, alpha } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import { Stack } from '@mui/system';
// ----------------------------------------------------------------------

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const CountrySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    isoCode: Yup.string().required('Country Code is required'),
    flag: Yup.string().required('Flag is required'),
    phonecode: Yup.string().required('Phonecode is required'),
    currency: Yup.string().required('Currency is required'),
    latitude: Yup.number().required('Latitude is required'),
    longitude: Yup.number().required('Longitude is required'),
    timezones: Yup.array().of(
      Yup.object().shape({
        zoneName: Yup.string().required('ZoneName is required'),
        gmtOffset: Yup.number().max(2147483647).required('GmtOffset is required'),
        gmtOffsetName: Yup.string().max(20).required('GmtOffsetName is required'),
        abbreviation: Yup.string().max(20).required('Abbreviation is required'),
        tzName: Yup.string().max(100).required('TzName is required'),
      })
    ),
  });

  const values = useMemo(
    () => ({
      name: row?.name || '',
      isoCode: row?.isoCode || '',
      flag: row?.flag || '',
      phonecode: row?.phonecode || '',
      currency: row?.currency || '',
      latitude: row?.latitude || '',
      longitude: row?.longitude || '',
      timezones: row?.timezones?.map((data) => ({
        id: data?.id || null,
        zoneName: data?.zoneName || '',
        gmtOffset: data?.gmtOffset || '',
        gmtOffsetName: data?.gmtOffsetName || '',
        abbreviation: data?.abbreviation || '',
        tzName: data?.tzName || '',
      })),
      status: row?.status || false,
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(CountrySchema),
    values,
  });

  const { reset, control, trigger, handleSubmit } = methods;

  const timezones = useFieldArray({
    control,
    name: 'timezones',
  });

  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      let URL = 'countries/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      setLoading(false);
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const handleAdd = () => {
    timezones?.append({
      id: null,
      zoneName: '',
      gmtOffset: '',
      gmtOffsetName: '',
      abbreviation: '',
      tzName: '',
    });
  };

  const handleRemove = (index) => {
    timezones?.remove(index);
  };

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 1120 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            top: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Toolbar>
            <Typography variant="h5" sx={{ flex: 1 }}>
              {row ? 'Update Country' : 'Add Country'}
            </Typography>

            <IconButton color="inherit" edge="start" onClick={onClose} tabIndex={-1}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>

        <Grid container spacing={2} sx={{ p: 3 }}>
          <Grid item container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
              <RHFTextField name="name" label="Country Name" size="small" autoFocus />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFTextField name="isoCode" label="ISO Code" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFTextField name="flag" label="Flag" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFTextField name="phonecode" label="Phone Code" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFTextField name="currency" label="Currency" size="small" />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFTextField name="latitude" label="Latitude" size="small" type="number" />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFTextField name="longitude" label="Longitude" size="small" type="number" />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
              <RHFCheckbox name="status" label="Active" size="small" />
            </Grid>
          </Grid>
          {timezones?.fields?.map((fieldName, index) => (
            <Grid item container spacing={1} key={index}>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                <RHFTextField
                  name={`timezones[${index}].zoneName`}
                  label="Zone Name"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                <RHFTextField
                  name={`timezones[${index}].gmtOffset`}
                  label="GmtOffset"
                  size="small"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                <RHFTextField
                  name={`timezones[${index}].gmtOffsetName`}
                  label="GmtOffsetName"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                <RHFTextField
                  name={`timezones[${index}].abbreviation`}
                  label="Abbreviation"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                <RHFTextField name={`timezones[${index}].tzName`} label="TzName" size="small" />
              </Grid>
              <Grid item xs={12} sm={12} md={1} lg={1} xl={1} xxl={1}>
                <Tooltip title="Remove" arrow>
                  <IconButton color="error" onClick={() => handleRemove(index)}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ))}

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} sx={{ m: 3 }}>
            <Button
              size="small"
              color="primary"
              startIcon={<Iconify icon="mingcute:add-line" />}
              onClick={handleAdd}
              sx={{ flexShrink: 0 }}
            >
              Add Timezone
            </Button>
          </Grid>
        </Grid>

        <Stack
          direction="row-reverse"
          alignItems="center"
          sx={{
            bottom: 0,
            zIndex: 111111,
            p: 3,
            position: 'sticky',
            width: '100%',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
          id="modelfooter"
        >
          <Tooltip title="(Alt + A)" arrow>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={async () => {
                if (await trigger()) {
                  onSubmit();
                }
              }}
            >
              {row ? 'Update' : 'Add'}
            </LoadingButton>
          </Tooltip>

          <Tooltip title="(Esc)" arrow>
            <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
          </Tooltip>

          <Button
            variant="soft"
            onClick={() => {
              reset(values);
            }}
            sx={{ mr: 2 }}
          >
            Reset
          </Button>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
