import { Helmet } from 'react-helmet-async';
import { COMPANY_NAME } from 'src/_mock/constant';
// sections
import { ListView } from 'src/sections/automobile-transaction/receipt/view';

// ----------------------------------------------------------------------

export default function AutoMobileReceiptListPage() {
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} : Receipt List</title>
      </Helmet>

      <ListView />
    </>
  );
}
