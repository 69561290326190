import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
// utils
import { useResponsive } from 'src/hooks/use-responsive';
import { fNumber } from 'src/utils/format-number';
// components
import Iconify from 'src/components/iconify';
import Chart, { useChart } from 'src/components/chart';

// ----------------------------------------------------------------------

export default function AppWidget({
  title,
  total,
  icon,
  color = 'primary',
  enquiryActive = false,
  chart,
  sx,
  ...other
}) {
  const theme = useTheme();

  const lgUp = useResponsive('up', 'md', 'lg');

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    legend: {
      show: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          { offset: 0, color: theme.palette[color].light },
          { offset: 100, color: theme.palette[color].main },
        ],
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '78%',
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 6,
            color: theme.palette.common.white,
            fontSize: theme.typography.subtitle2.fontSize,
          },
        },
      },
    },
    // ...options,
  });

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        p: lgUp ? 3 : 1,
        cursor: 'pointer',
        borderRadius: 2,
        border: enquiryActive ? '0px solid black' : '3px solid inherit',
        borderColor: `inherit`,
        overflow: 'hidden',
        position: 'relative',
        bgcolor: enquiryActive ? `${color}.light` : 'common.white',
        color: `${color}.dark`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    >
      {/* {lgUp && (
        <Chart type="radialBar" series={[series]} options={chartOptions} width={86} height={86} />
      )} */}

      <ListItemText
        sx={{ ml: lgUp ? 3 : 0 }}
        primary={fNumber(total)}
        secondary={title}
        primaryTypographyProps={{
          typography: lgUp ? 'h4' : 'h6',
          component: 'span',
          textAlign: 'center',
        }}
        secondaryTypographyProps={{
          color: 'common.black',
          component: 'span',
          sx: { opacity: 0.64, fontSize: !lgUp && 11, fontWeight: 'bold', textAlign: 'center' },
          typography: lgUp ? 'subtitle2' : 'small',
        }}
      />

      {lgUp && (
        <Iconify
          icon={icon}
          sx={{
            width: 112,
            right: -32,
            height: 112,
            opacity: 0.08,
            position: 'absolute',
          }}
        />
      )}
    </Stack>
  );
}

AppWidget.propTypes = {
  chart: PropTypes.object,
  color: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
  title: PropTypes.string,
  total: PropTypes.number,
  enquiryActive: PropTypes.bool,
};
