import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { KEY } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  ListItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEventListener } from 'src/hooks/use-event-listener';
import { Stack } from '@mui/system';
import { useBgColorAutocomplete } from 'src/_mock/constant_funcation';
import { DateField } from '@mui/x-date-pickers';

export default function ProductPriceQuickEditForm({
  row,
  open,
  onClose,
  table,
  getAll,
  view,
  tableData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [modelVariant, setModelVariant] = useState([]);

  const bgColorAutocomplete = useBgColorAutocomplete();

  const PricesMasterSchema = Yup.object().shape({
    avariantId: Yup.number().required('Product Variant is required'),
    dealprice: Yup.number().required('Dealer Billing Price is required'),
    cgst: Yup.number().required('CGST is required'),
    sgst: Yup.number().required('SGST is required'),
    roadtax: Yup.number().required('roadtax is required'),
    insurance: Yup.number().required('insurance is required'),
    rtotax: Yup.number().required('rto is required'),
    rtorfsc: Yup.number().required('rto is required'),
    rtohndl: Yup.number().required('rto is required'),
    effdate: Yup.string().required('effdate is required'),
    access: Yup.number().required('access is required'),
    hpcharges: Yup.number().required('hpcharges is required'),
  });

  const values = useMemo(
    () => ({
      avariantId: row?.avariantId || null,
      dealprice: row?.dealprice || null,
      hcharge: row?.hcharge || null,
      cgst_per: row?.cgst_per || 14,
      sgst_per: row?.sgst_per || 14,
      cgst: row?.cgst || null,
      sgst: row?.sgst || null,
      exshowroomprice: row?.exshowroomprice || null,
      roadtax_per: row?.roadtax_per || 2,
      roadtax: row?.roadtax || null,
      regischarge: row?.regischarge || null,
      insurance: row?.insurance || null,
      rtotax: row?.rtotax || null,
      rtorfsc: row?.rtorfsc || 500,
      rtohndl: row?.rtohndl || 450,
      rtotax_per: row?.rtotax_per || 6,
      rto: row?.rto || null,
      effdate: row?.effdate || new Date(),
      access: row?.access || null,
      hpcharges: row?.hpcharges || null,
      extwar: row?.extwar || null,
      others: row?.others || null,
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(PricesMasterSchema),
    values,
    mode: 'onChange',
  });

  const {
    reset,
    trigger,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const rtohndlRef = useRef(null);

  const onSubmit = handleSubmit(async (payload) => {
    payload = {
      ...payload,
      cgst: parseFloat(payload.cgst),
      sgst: parseFloat(payload.sgst),
      roadtax: parseFloat(payload.roadtax),
      regischarge: parseFloat(payload.regischarge),
      insurance: parseFloat(payload.insurance),
      rto: parseFloat(payload.rto),
    };
    setLoading(true);
    try {
      let URL = 'automobile/product/prices/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      setLoading(false);
      console.error(error);
    }
  });

  const ModelVariantList = useCallback(async () => {
    try {
      let URL = 'automobile/product/variants/';
      URL += `?page=${1}&limit=${10000}&status=true&`;
      const { data } = await fetcher(URL);
      if (data) {
        setModelVariant(data?.rows);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    reset();
  }, [open, reset]);

  useEffect(() => {
    if (allValues?.cgst_per) {
      const cGST = (
        ((parseFloat(allValues.dealprice || 0) + parseFloat(allValues.hcharge || 0)) / 100) *
        allValues.cgst_per
      ).toFixed(2);
      setValue('cgst', parseFloat(cGST));
    }
    if (allValues?.sgst_per) {
      const sGST = (
        ((parseFloat(allValues.dealprice || 0) + parseFloat(allValues.hcharge || 0)) / 100) *
        allValues.sgst_per
      ).toFixed(2);
      setValue('sgst', parseFloat(sGST));
    }

    setValue(
      'exshowroomprice',
      Math.round(
        parseFloat(allValues.dealprice || 0) +
        parseFloat(allValues.hcharge || 0) +
        parseFloat(allValues.cgst || 0) +
        parseFloat(allValues.sgst || 0)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allValues.cgst_per,
    allValues.hcharge,
    allValues.sgst_per,
    allValues.exshowroomprice,
    allValues.cgst,
    allValues.sgst,
    allValues.dealprice,
    setValue,
  ]);

  useEffect(() => {
    if (allValues?.rtotax_per) {
      const rtoTax = (
        ((parseFloat(allValues.dealprice || 0) + parseFloat(allValues.hcharge || 0)) / 100) *
        allValues.rtotax_per
      ).toFixed(2);
      setValue('rtotax', parseFloat(rtoTax));
    }

    const rto = (
      parseFloat(allValues.rtotax || 0) +
      parseFloat(allValues.rtorfsc || 0) +
      parseFloat(allValues.rtohndl || 0)
    ).toFixed(2);

    setValue('rto', Math.round(parseFloat(rto)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allValues.rtotax_per,
    allValues.dealprice,
    allValues.rtohndl,
    allValues.exshowroomprice,
    allValues.rtorfsc,
    allValues.rtotax,
    setValue,
  ]);

  useEffect(() => {
    if (allValues?.roadtax_per) {
      const roadTax = (
        (parseFloat(allValues.exshowroomprice) / 100) *
        allValues.roadtax_per
      ).toFixed(2);
      setValue('roadtax', Math.round(parseFloat(roadTax)));
    }
  }, [allValues.roadtax_per, allValues.exshowroomprice, setValue]);

  useEffect(() => {
    if (open) ModelVariantList();
  }, [open, ModelVariantList]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 620 },
      }}
    >
      <FormProvider methods={methods}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            top: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Toolbar>
            {view && (
              <Typography variant="h5" sx={{ flex: 1 }}>
                View Price Master
              </Typography>
            )}
            {!view && (
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update Price Master' : 'Add Price Master'}
              </Typography>
            )}
            <IconButton color="inherit" edge="start" onClick={onClose}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>

        <DialogContent>
          <Grid container sm={12} md={12} lg={12} xl={12} xxl={12} p={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Autocomplete
                autoHighlight
                fullWidth
                size="small"
                options={modelVariant}
                groupBy={(option) => option?.a}
                getOptionLabel={(option) => option.name}
                getOptionDisabled={(option) => option?.value === null}
                openOnFocus
                onChange={(event, newValue) => {
                  setValue('avariantId', newValue?.id);
                }}
                value={(() => {
                  const addRecord = modelVariant?.find((data) => data.id === allValues.avariantId);
                  const editRecord = row?.ac_group;

                  if (addRecord) return addRecord;
                  if (editRecord) return editRecord;
                  return null;
                })()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span>
                        Select Product Variant <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    name="avariantId"
                    error={errors?.avariantId && !allValues?.avariantId}
                    autoFocus={!row}
                  />
                )}
                disabled={view}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                    key={option.id}
                    sx={{ minHeight: 'auto !important' }}
                  >
                    <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                  </Box>
                )}
                ListboxProps={{ ...bgColorAutocomplete }}
              />
            </Grid>

            <Grid
              container
              mt={2}
              sx={{
                bgcolor: 'primary.dark',
                p: 0.5,
                color: (theme) => alpha(theme.palette.background.default, 1),
              }}
            >
              <Grid item xs={6}>
                <Typography variant="subtitle1">Prices HMSI - 2F</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle1">Perc.(%)</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" sx={{ textAlign: 'right' }}>
                  Amount
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={0.3} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Dealer Billing Price <span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2} />
              <Grid item xs={3}>
                <Typography variant="body2">
                  <RHFTextField
                    disabled={view}
                    type="number"
                    name="dealprice"
                    variant="standard"
                    size="small"
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={0.3} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2">Handling Charge</Typography>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2} />
              <Grid item xs={3}>
                <Typography variant="body2">
                  <RHFTextField
                    disabled={view}
                    name="hcharge"
                    type="number"
                    variant="standard"
                    size="small"
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={0.3} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  CGST <span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body2">
                  <RHFTextField
                    disabled={view}
                    type="number"
                    name="cgst_per"
                    variant="standard"
                    size="small"
                  />
                </Typography>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={3}>
                <Typography variant="body2">
                  <RHFTextField
                    disabled={view || allValues?.cgst_per}
                    name="cgst"
                    type="number"
                    variant="standard"
                    size="small"
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={0.3} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  SGST <span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body2">
                  <RHFTextField
                    disabled={view}
                    type="number"
                    name="sgst_per"
                    variant="standard"
                    size="small"
                  />
                </Typography>
              </Grid>

              <Grid item xs={2} />
              <Grid item xs={3}>
                <Typography variant="body2">
                  <RHFTextField
                    disabled={view || allValues?.sgst_per > 0}
                    type="number"
                    name="sgst"
                    variant="standard"
                    size="small"
                  />
                </Typography>
              </Grid>
            </Grid>

            <Grid container mt={0.3} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2">Ex-Showroom Price</Typography>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2} />
              <Grid item xs={3}>
                <Typography variant="body2">
                  <RHFTextField
                    type="number"
                    name="exshowroomprice"
                    variant="standard"
                    size="small"
                    disabled
                  />
                </Typography>
              </Grid>
            </Grid>

            <Grid container mt={0.3} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  MC Road Tax <span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body2">
                  <RHFTextField
                    disabled={view}
                    type="number"
                    name="roadtax_per"
                    variant="standard"
                    size="small"
                  />
                </Typography>
              </Grid>

              <Grid item xs={2} />
              <Grid item xs={3}>
                <Typography variant="body2">
                  <RHFTextField
                    disabled={view || allValues?.roadtax_per > 0}
                    type="number"
                    name="roadtax"
                    variant="standard"
                    size="small"
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={0.3} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2">Reg. Charges</Typography>
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={2} />
              <Grid item xs={3}>
                <Typography variant="body2">
                  <RHFTextField
                    disabled={view}
                    type="number"
                    name="regischarge"
                    variant="standard"
                    size="small"
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={0.3} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  RTO Tax<span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body2">
                  <RHFTextField
                    disabled={view}
                    type="number"
                    name="rtotax_per"
                    variant="standard"
                    size="small"
                  />
                </Typography>
              </Grid>

              <Grid item xs={2} />
              <Grid item xs={3}>
                <Typography variant="body2">
                  <RHFTextField
                    disabled={view || allValues?.rtotax_per > 0}
                    type="number"
                    name="rtotax"
                    variant="standard"
                    size="small"
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={0.3} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  RTO RF/SC<span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={2} />
              <Grid item xs={3}>
                <Typography variant="body2">
                  <RHFTextField
                    disabled={view}
                    type="number"
                    name="rtorfsc"
                    variant="standard"
                    size="small"
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={0.3} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  RTO HNDL<span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={2} />
              <Grid item xs={3}>
                <Typography variant="body2">
                  <Controller
                    name="rtohndl"
                    control={methods.control} // Assuming 'methods' is your useForm() return object
                    render={({ field }) => (
                      <TextField
                        {...field}
                        inputRef={rtohndlRef} // Attach the ref here
                        onFocus={() => {
                          // Ensure the element is not null
                          if (rtohndlRef.current) {
                            rtohndlRef.current.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                            });
                          }
                        }}
                        type="number"
                        variant="standard"
                        size="small"
                        disabled={view}
                      />
                    )}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={0.3} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  RTO <span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={2} />
              <Grid item xs={3}>
                <Typography variant="body2">
                  <RHFTextField type="number" name="rto" variant="standard" size="small" disabled />
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={0.3} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Insurance <span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={2} />
              <Grid item xs={3}>
                <Typography variant="body2">
                  <RHFTextField
                    disabled={view}
                    type="number"
                    name="insurance"
                    variant="standard"
                    size="small"
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={0.3} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Accessories <span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={2} />
              <Grid item xs={3}>
                <Typography variant="body2">
                  <RHFTextField
                    disabled={view}
                    type="number"
                    name="access"
                    variant="standard"
                    size="small"
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={0.3} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  HP Charges <span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={2} />
              <Grid item xs={3}>
                <Typography variant="body2">
                  <RHFTextField
                    disabled={view}
                    type="number"
                    name="hpcharges"
                    variant="standard"
                    size="small"
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={0.3} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2">Extended Warranty</Typography>
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={2} />
              <Grid item xs={3}>
                <Typography variant="body2">
                  <RHFTextField
                    disabled={view}
                    type="number"
                    name="extwar"
                    variant="standard"
                    size="small"
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={0.3} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2">Others Charges</Typography>
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={2} />
              <Grid item xs={3}>
                <Typography variant="body2">
                  <Controller
                    name="others"
                    control={methods.control} // Assuming 'methods' is your useForm() return object
                    render={({ field }) => (
                      <TextField
                        {...field}
                        inputRef={rtohndlRef} // Attach the ref here
                        onFocus={() => {
                          // Ensure the element is not null
                          if (rtohndlRef.current) {
                            rtohndlRef.current.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                            });
                          }
                        }}
                        type="number"
                        variant="standard"
                        size="small"
                        disabled={view}
                      />
                    )}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={0.3} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  Effective Date <span style={{ color: 'red' }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={2} />
              <Grid item xs={3}>
                <Typography variant="body2">
                  <Controller
                    name="effdate"
                    render={({ field, fieldState: { error } }) => (
                      <DateField
                        value={field.value ? new Date(field.value) : null}
                        timezone="Asia/Kolkata"
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        format="dd/MM/yyyy"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!error,
                            size: 'small',
                          },
                        }}
                        disabled={view}
                        variant="standard"
                      />
                    )}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        {!view && (
          <Stack
            direction="row-reverse"
            alignItems="center"
            sx={{
              bottom: 0,
              zIndex: 111111,
              p: 3,
              position: 'sticky',
              width: '100%',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
            id="modelfooter"
          >
            <Tooltip title="(Alt + A)" arrow>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={async () => {
                  if (await trigger()) {
                    onSubmit();
                  }
                }}
              >
                {row ? 'Update' : 'Add'}
              </LoadingButton>
            </Tooltip>

            <Tooltip title="(Esc)" arrow>
              <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
                Cancel
              </Button>
            </Tooltip>

            <Button
              variant="soft"
              onClick={() => {
                reset(values);
              }}
              sx={{ mr: 2 }}
            >
              Reset
            </Button>
          </Stack>
        )}
      </FormProvider>
    </Dialog>
  );
}

ProductPriceQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  view: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
