import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { create, destroy, fetcher, update } from 'src/utils/axios';
import { KEY, PROCESS_NAME, QUANTITY_EFFECT, STOCK_REQUIRED, STOCK_TYPE } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useDebounce } from 'src/hooks/use-debounce';
import { useGetCommon } from 'src/sections/work_shop/global';
import { useBoolean } from 'src/hooks/use-boolean';
import { useEventListener } from 'src/hooks/use-event-listener';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import GSTCommodityQuickEditForm from 'src/sections/gst/master/commodity/quick-edit-form';
import ProductModelQuickEditForm from 'src/sections/honda/productmodel/quick-edit-form';
import CompanyYear from 'src/layouts/_common/company-year';
import ProductPriceQuickEditForm from 'src/sections/honda/pricesmaster/quick-edit-form';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBgColorAutocomplete } from 'src/_mock/constant_funcation';
import ProductGroupQuickEditForm from '../product-group/quick-edit-form';
import ProductCategoryEditForm from '../product-category/quick-edit-form';
import ProductUnitEditForm from '../product-unit/quick-edit-form';
import VehicleOpeningStock from './vehicle-openingstock';
import AccessoriesGroups from './accesories-groups';
import ServiceQuickEditForm from '../../honda/service-master/quick-edit-form';

export default function PUserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [accesoriesgroup, setaccesoriesgroup] = useState([]);
  const bgColorAutocomplete = useBgColorAutocomplete();

  const [openModelName, setOpenModelName] = useState('');
  const [GSTCommodityList, setGSTCommodityList] = useState([]);
  const [productGroupList, setProductGroupList] = useState([]);
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [productUnitList, setProductUnitList] = useState([]);
  const [GSTUnitList, setGSTUnitList] = useState([]);
  const [GSTCommodity, setGSTCommodity] = useState({});
  const [PGroup, setPGroup] = useState({});
  const [PCategory, setPCategory] = useState(row ? row.pcategory : {});
  const [saleUnit, setSaleUnit] = useState({});
  const [purchaseUnit, setPurchaseUnit] = useState({});

  const dialog = useBoolean();
  const modelDialog = useBoolean();
  const accessoriesBombDialog = useBoolean();
  const serviceMasterDialog = useBoolean();
  const pricemasterdialog = useBoolean();
  const vehicleopeningstockdialog = useBoolean();
  const quickEdit = useBoolean();
  const PGquickEdit = useBoolean();
  const PCquickEdit = useBoolean();
  const PUquickEdit = useBoolean();
  const confirm = useBoolean();

  const ProductSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    partno: Yup.string().nullable().notRequired().min(0),
    pur_rate: Yup.number().nullable().notRequired().min(0),
    sal_rate: Yup.number().nullable().notRequired().min(0),
    quantity: Yup.number().nullable().notRequired().min(0),
    rate: Yup.number().nullable().notRequired().min(0),
    amount: Yup.number().nullable().notRequired().min(0),
    alt_stock: Yup.number().nullable().notRequired().min(0),
    min_stock: Yup.number().nullable().notRequired().min(0),
    reo_stock: Yup.number().nullable().notRequired().min(0),
    srcon: Yup.number().nullable().notRequired().min(0),
    prcon: Yup.number().nullable().notRequired().min(0),
    stcon: Yup.number().nullable().notRequired().min(0),
  });
  const metalist = useMemo(
    () => ({
      modelCategoryMeta: {
        URL: 'automobile/product/categories/',
        query: {
          limit: 50000,
          name: '',
        },
        editRow: null,
      },
      productModelMeta: {
        URL: 'automobile/product/models/',
        query: {
          limit: 50000,
          name: '',
        },
        editRow: null,
      },
      productVariantMeta: {
        URL: 'automobile/product/variants/',
        query: {
          limit: 50000,
          name: '',
        },
        editRow: null,
      },
      acolourMeta: {
        URL: 'automobile/product/colours/',
        query: {
          limit: 200,
          name: '',
        },
        editRow: null,
      },
      productMtocMeta: {
        URL: 'automobile/product/mtoc/',
        query: {
          limit: 50000,
          name: '',
        },
        editRow: null,
      },
    }),
    []
  );

  const values = useMemo(
    () => ({
      name: row?.name || '',
      s_n: row?.s_n || '',
      partno: row?.partno || '',
      gst_com_Id: row?.gst_com_Id || null,
      pgroupId: row?.pgroupId || null,
      pcategoryId: row?.pcategoryId || null,
      st_req: row?.st_req,
      qu_eff: row?.qu_eff,
      stocktype: row?.stocktype,
      isHighligh: '',
      pur_rate: row?.pur_rate || null,
      sal_rate: row?.sal_rate || null,
      sunitId: row?.sunitId || null,
      punitId: row?.punitId || null,
      ugst_unitId: row?.ugst_unitId || null,
      ogst_unitId: row?.ogst_unitId || null,
      agst_unitId: row?.agst_unitId || null,
      quantity: row?.quantity || null,
      rate: row?.rate || null,
      amount: row?.amount || null,
      alt_stock: row?.alt_stock || null,
      min_stock: row?.min_stock || null,
      reo_stock: row?.reo_stock || null,
      auto_pro: row?.auto_pro,
      pro_id: row?.pro_id || null,
      srcon: row?.srcon || null,
      prcon: row?.prcon || null,
      stcon: row?.stcon || null,
      pricemaster: row?.pricemaster || null,
      productmodel: row?.productmodel || null,
      avariantId: row?.avariantId || null,
      amodcategoryId: row?.amodcategoryId || null,
      amodelId: row?.amodelId || null,
      acolourId: row?.acolourId || null,
      amtocId: row?.amtocId || null,
      acolour: row?.acolour || null,
      accessoriesgroups: row?.accessoriesgroups || [],
      ...metalist,
    }),
    [row, metalist]
  );

  const methods = useForm({
    resolver: yupResolver(ProductSchema),
    values,
    mode: 'onChange',
  });

  const {
    reset,
    watch,
    setValue,
    control,
    trigger,
    handleSubmit,
    formState: { errors },
  } = methods;
  const allValues = watch();
  const getPriceByVariatnId = useCallback(async () => {
    try {
      let URL = 'automobile/product/prices/';
      URL += `?page=${1}&limit=${10}&avariantId=${allValues.avariantId}`;

      const { data } = await fetcher(URL);
      if (data?.rows.length) {
        setValue('pricemaster', data?.rows[0]);
      }
    } catch (error) {
      console.error(error);
    }
  }, [allValues.avariantId, setValue]);
  const getVehicleModelBYId = useCallback(async () => {
    try {
      let URL = 'automobile/product/models/';
      URL += `?page=${1}&limit=${10}&id=${allValues.amodelId}`;

      const { data } = await fetcher(URL);
      if (data?.rows.length) {
        setValue('productmodel', data?.rows[0]);
      }
    } catch (error) {
      console.error(error);
    }
  }, [allValues.amodelId, setValue]);
  useEffect(() => {
    if (allValues.avariantId) {
      getPriceByVariatnId();
    } else {
      setValue('pricemaster', null);
    }
  }, [allValues.avariantId, getPriceByVariatnId, setValue]);
  useEffect(() => {
    if (allValues.amodelId) {
      getVehicleModelBYId();
    } else {
      setValue('productmodel', null);
    }
  }, [allValues.amodelId, getVehicleModelBYId, setValue]);
  const debouncedMCategory = useDebounce(allValues.productModelMeta?.name, 500);
  const debouncedProductVariant = useDebounce(allValues.productVariantMeta?.name, 500);
  const debouncedProductMtoc = useDebounce(allValues.productMtocMeta?.name, 500);
  const debouncedProductModel = useDebounce(allValues.productModelMeta?.name, 500);
  const debouncedColours = useDebounce(allValues.acolourMeta?.name, 500);
  const colours = useGetCommon(debouncedColours ? allValues.acolourMeta : allValues.acolourMeta);
  const modelCategory = useGetCommon(
    debouncedMCategory ? allValues.modelCategoryMeta : allValues.modelCategoryMeta
  );
  const productModel = useGetCommon(
    debouncedProductModel ? allValues.productModelMeta : allValues.productModelMeta
  );
  const productVariant = useGetCommon(
    debouncedProductVariant ? allValues.productVariantMeta : allValues.productVariantMeta
  );
  const productMtoc = useGetCommon(
    debouncedProductMtoc ? allValues.productMtocMeta : allValues.productMtocMeta
  );
  useEffect(() => {
    setValue('accessoriesgroups', []);
  }, [allValues.pcategoryId, setValue]);
  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      let URL = 'automobile/products/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      setLoading(false);
      console.error(error);
    }
  });

  useEffect(() => {
    if (row?.pcategory) {
      setPCategory(row?.pcategory);
    } else {
      console.log('null');
      setPCategory({});
    }
  }, [row?.pcategory]);
  const handleSubModelClose = (event) => {
    if (openModelName === 'otherDetails' && event.key === KEY.CLOSE) {
      dialog.onFalse();
      setOpenModelName('null');
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  const HandleGSTCommodityList = async () => {
    try {
      let URL = 'automobile/gst_commodities/';
      URL += `?page=${1}&limit=${10000}&status=true`;
      const { data } = await fetcher(URL);
      setGSTCommodityList(data?.rows.map((x) => ({ ...x, a: 'name' })));
    } catch (error) {
      console.error(error);
    }
  };

  const HandleProductGroupList = async () => {
    try {
      let URL = 'automobile/pgroups/';
      URL += `?page=${1}&limit=${10000}&status=true`;
      const { data } = await fetcher(URL);
      setProductGroupList(data?.rows.map((x) => ({ ...x, a: 'name' })));
    } catch (error) {
      console.error(error);
    }
  };

  const HandleProductCategoryList = async () => {
    try {
      let URL = 'automobile/pcategories/';
      URL += `?page=${1}&limit=${10000}&status=true`;
      const { data } = await fetcher(URL);
      setProductCategoryList(data?.rows.map((x) => ({ ...x, a: 'name' })));
    } catch (error) {
      console.error(error);
    }
  };

  const HandleProductUnitList = async () => {
    try {
      let URL = 'automobile/punits/';
      URL += `?page=${1}&limit=${10000}&status=true`;
      const { data } = await fetcher(URL);
      setProductUnitList(data?.rows.map((x) => ({ ...x, a: 'name' })));
    } catch (error) {
      console.error(error);
    }
  };

  const HandleGSTUnitList = async () => {
    try {
      let URL = 'automobile/gst_units/';
      URL += `?page=${1}&limit=${10000}&status=true`;
      const { data } = await fetcher(URL);
      setGSTUnitList(data?.rows.map((x) => ({ ...x, a: 'name' })));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    reset();
  }, [open, reset]);

  useEffect(() => {
    if (open) {
      HandleGSTCommodityList();
      HandleProductGroupList();
      HandleProductCategoryList();
      HandleProductUnitList();
      HandleGSTUnitList();
    }
  }, [open]);

  const handleGroupClose = () => {
    quickEdit.onFalse();
    HandleGSTCommodityList();
    setGSTCommodity(null);
    setPGroup(null);
    setPCategory(null);
    setSaleUnit(null);
    setPurchaseUnit(null);
  };

  const handlePGroupClose = () => {
    PGquickEdit.onFalse();
    HandleProductGroupList();
    setGSTCommodity(null);
    setPGroup(null);
    setPCategory(null);
    setSaleUnit(null);
    setPurchaseUnit(null);
  };

  const handlePCategoryClose = () => {
    PCquickEdit.onFalse();
    HandleProductCategoryList();
    setGSTCommodity(null);
    setPGroup(null);
    setPCategory(null);
    setSaleUnit(null);
    setPurchaseUnit(null);
  };

  const handlePUnitClose = () => {
    PUquickEdit.onFalse();
    HandleProductUnitList();
    setGSTCommodity(null);
    setPGroup(null);
    setPCategory(null);
    setSaleUnit(null);
    setPurchaseUnit(null);
  };

  const deleteAccountGroupModel = useCallback(
    async (id) => {
      try {
        let URL = 'automobile/gst_commodities/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          HandleGSTCommodityList();
          setGSTCommodity(null);
          setPGroup(null);
          setPCategory(null);
          setSaleUnit(null);
          setPurchaseUnit(null);
          confirm.onFalse();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [confirm, enqueueSnackbar]
  );
  const deletePGroupModel = useCallback(
    async (id) => {
      try {
        let URL = 'automobile/pgroups/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          HandleProductGroupList();
          setGSTCommodity(null);
          setPGroup(null);
          setPCategory(null);
          setSaleUnit(null);
          setPurchaseUnit(null);
          confirm.onFalse();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [enqueueSnackbar, confirm]
  );
  const deletePCategoryModel = useCallback(
    async (id) => {
      try {
        let URL = 'automobile/pcategories/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          HandleProductCategoryList();
          setGSTCommodity(null);
          setPGroup(null);
          setPCategory(null);
          setSaleUnit(null);
          setPurchaseUnit(null);
          confirm.onFalse();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [enqueueSnackbar, confirm]
  );
  const deletePUnitModel = useCallback(
    async (id) => {
      try {
        let URL = 'automobile/punits/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          HandleProductUnitList();
          setGSTCommodity(null);
          setPGroup(null);
          setPCategory(null);
          setSaleUnit(null);
          setPurchaseUnit(null);
          confirm.onFalse();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [enqueueSnackbar, confirm]
  );

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={false}
        open={open}
        onKeyDown={handleKeyDown}
        PaperProps={{
          sx: { maxWidth: 1320 },
        }}
      >
        <FormProvider methods={methods}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                top: 0,
                zIndex: 9,
                position: 'sticky',
                bgcolor: (theme) => alpha(theme.palette.background.default, 1),
              }}
            >
              <Toolbar>
                <Typography variant="h5" sx={{ flex: 1 }}>
                  {row ? 'Update Product ' : 'Add Product'}
                </Typography>

                <CompanyYear />

                <IconButton color="inherit" edge="start" onClick={onClose} tabIndex={-1}>
                  <Iconify icon="mingcute:close-line" />
                </IconButton>
              </Toolbar>

              <Divider sx={{ borderStyle: 'dashed' }} />
            </Grid>

            <Grid item container xs={12} md={12} sx={{ p: 3 }} spacing={10}>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
                spacing={2}
                alignContent="start"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="subtitle1">Main Details</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField
                    name="name"
                    label={
                      <span>
                        Name <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    size="small"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2.5} lg={2.5} xl={2.5} xxl={2.5}>
                  <RHFTextField name="s_n" label="Alias" size="small" />
                </Grid>
                <Grid item xs={12} sm={6} md={3.5} lg={3.5} xl={3.5} xxl={3.5}>
                  <RHFTextField
                    name="partno"
                    label={
                      <span>
                        Product/Part No.<span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    size="small"
                    disableCloseOnSelect
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    options={
                      GSTCommodityList.length
                        ? GSTCommodityList
                        : [{ description: 'No Option', value: null }]
                    }
                    getOptionDisabled={(option) => option?.value === null}
                    groupBy={(option) => option?.a}
                    getOptionLabel={(option) => option.description}
                    onChange={(event, newValue) => {
                      setGSTCommodity(newValue);
                      setValue('gst_com_Id', newValue?.id);
                    }}
                    value={
                      GSTCommodityList?.find((data) => data.id === allValues.gst_com_Id) ||
                      row?.gst_commodity
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="GST Commodity"
                        name="gst_com_Id"
                        error={errors?.gst_com_Id}
                      />
                    )}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader component="li">
                          <ListItem sx={{ padding: 0, margin: 0 }}>Name</ListItem>
                        </GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                        <GroupFooter component="li">
                          <Button
                            onClick={() => {
                              quickEdit.onTrue();
                              setGSTCommodity(null);
                            }}
                          >
                            Add
                          </Button>
                          {GSTCommodity && (
                            <>
                              <Button
                                onClick={() => {
                                  quickEdit.onTrue();
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                onClick={() => {
                                  confirm.onTrue();
                                }}
                              >
                                Delete
                              </Button>
                            </>
                          )}
                        </GroupFooter>
                      </li>
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                          {option?.description}
                        </ListItem>
                      </Box>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    size="small"
                    disableCloseOnSelect
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    options={
                      productGroupList.length
                        ? productGroupList
                        : [{ name: 'No Option', value: null }]
                    }
                    getOptionDisabled={(option) => option?.value === null}
                    groupBy={(option) => option?.a}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setPGroup(newValue);
                      setValue('pgroupId', newValue?.id);
                    }}
                    value={
                      productGroupList?.find((data) => data.id === allValues.pgroupId) ||
                      row?.pgroup
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Group Name"
                        name="pgroupId"
                        error={errors?.pgroupId}
                      />
                    )}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader component="li">
                          <ListItem sx={{ padding: 0, margin: 0 }}>Group</ListItem>
                          <ListItem sx={{ padding: 0, margin: 0 }}>Short Name</ListItem>
                        </GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                        <GroupFooter component="li">
                          <Button
                            onClick={() => {
                              PGquickEdit.onTrue();
                              setPGroup(null);
                            }}
                          >
                            Add
                          </Button>
                          {PGroup && (
                            <>
                              <Button
                                onClick={() => {
                                  PGquickEdit.onTrue();
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                onClick={() => {
                                  confirm.onTrue();
                                }}
                              >
                                Delete
                              </Button>
                            </>
                          )}
                        </GroupFooter>
                      </li>
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                          {option?.name}
                        </ListItem>
                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                          {option?.shortname ? option?.shortname : '-'}
                        </ListItem>
                      </Box>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    size="small"
                    disableCloseOnSelect
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    options={
                      productCategoryList.length
                        ? productCategoryList
                        : [{ name: 'No Option', value: null }]
                    }
                    getOptionDisabled={(option) => option?.value === null}
                    groupBy={(option) => option?.a}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setPCategory(newValue);
                      setValue('pcategoryId', newValue?.id);
                    }}
                    value={
                      productCategoryList?.find((data) => data.id === allValues.pcategoryId) ||
                      row?.pcategory
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Category Name"
                        name="pcategoryId"
                        error={errors?.pcategoryId}
                      />
                    )}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader component="li">
                          <ListItem sx={{ padding: 0, margin: 0 }}>Category</ListItem>
                          <ListItem sx={{ padding: 0, margin: 0 }}>Short Name</ListItem>
                        </GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                        <GroupFooter component="li">
                          <Button
                            onClick={() => {
                              PCquickEdit.onTrue();
                              setPCategory(null);
                            }}
                          >
                            Add
                          </Button>
                          {PCategory && (
                            <>
                              <Button
                                onClick={() => {
                                  PCquickEdit.onTrue();
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                onClick={() => {
                                  confirm.onTrue();
                                }}
                              >
                                Delete
                              </Button>
                            </>
                          )}
                        </GroupFooter>
                      </li>
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                          {option?.name}
                        </ListItem>
                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                          {option?.shortname ? option?.shortname : '-'}
                        </ListItem>
                      </Box>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>

                {PCategory?.name?.toLowerCase() === 'vehicle' && (
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Typography variant="subtitle1">Vehicle Details</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={modelCategory.rows}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        getOptionDisabled={(option) => option?.value === null}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('modelCategoryMeta.editRow', newValue);
                          setValue('amodcategoryId', newValue?.id);
                          setValue('amodelId', null);
                          setValue('avariantId', null);
                          setValue('amtocId', null);
                        }}
                        value={(() => {
                          const addRecord = modelCategory.rows?.find(
                            (data) => data.id === allValues.amodcategoryId
                          );
                          const editRecord = row?.ac_group;

                          if (addRecord) return addRecord;
                          if (editRecord) return editRecord;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span>
                                Model Category <span style={{ color: 'red' }}>*</span>
                              </span>
                            }
                            name="amodcategoryId"
                            error={errors?.amodcategoryId && !allValues?.amodcategoryId}
                            variant="outlined"
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            key={option.id}
                            sx={{ minHeight: 'auto !important' }}
                          >
                            <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                          </Box>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={productModel.rows.filter(
                          (data) => data.amodcategoryId === allValues.amodcategoryId
                        )}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        getOptionDisabled={(option) => option?.value === null}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('productModelMeta.editRow', newValue);
                          setValue('amodelId', newValue?.id);
                          setValue('avariantId', null);
                          setValue('amtocId', null);
                        }}
                        value={(() => {
                          const addRecord = productModel.rows?.find(
                            (data) => data.id === allValues.amodelId
                          );
                          const editRecord = row?.ac_group;

                          if (addRecord) return addRecord;
                          if (editRecord) return editRecord;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span>
                                Product Model <span style={{ color: 'red' }}>*</span>
                              </span>
                            }
                            name="amodelId"
                            error={errors?.amodelId && !allValues?.amodelId}
                            variant="outlined"
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            key={option.id}
                            sx={{ minHeight: 'auto !important' }}
                          >
                            <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                          </Box>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={productVariant.rows.filter(
                          (data) => data.amodelId === allValues.amodelId
                        )}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        getOptionDisabled={(option) => option?.value === null}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('productVariantMeta.editRow', newValue);
                          setValue('avariantId', newValue?.id);
                          setValue('amtocId', null);
                        }}
                        value={(() => {
                          const addRecord = productVariant.rows?.find(
                            (data) => data.id === allValues.avariantId
                          );
                          const editRecord = row?.ac_group;

                          if (addRecord) return addRecord;
                          if (editRecord) return editRecord;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span>
                                Product Variant <span style={{ color: 'red' }}>*</span>
                              </span>
                            }
                            name="avariantId"
                            error={errors?.avariantId && !allValues?.avariantId}
                            variant="outlined"
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            key={option.id}
                            sx={{ minHeight: 'auto !important' }}
                          >
                            <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                          </Box>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid
                item
                container
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
                spacing={2}
                alignContent="start"
              >
                <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="subtitle1">Unit Name</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={6} xxl={6}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    size="small"
                    disableCloseOnSelect
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    options={
                      productUnitList.length
                        ? productUnitList
                        : [{ name: 'No Option', value: null }]
                    }
                    getOptionDisabled={(option) => option?.value === null}
                    groupBy={(option) => option?.a}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setSaleUnit(newValue);
                      setPurchaseUnit(null);
                      setValue('sunitId', newValue?.id);
                    }}
                    value={
                      productUnitList?.find((data) => data.id === allValues.sunitId) || row?.sunit
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Sale" name="sunitId" error={errors?.sunitId} />
                    )}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader component="li">
                          <ListItem sx={{ padding: 0, margin: 0 }}>Sale Unit</ListItem>
                        </GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                        <GroupFooter component="li">
                          <Button
                            onClick={() => {
                              PUquickEdit.onTrue();
                              setSaleUnit(null);
                            }}
                          >
                            Add
                          </Button>
                          {saleUnit && (
                            <>
                              <Button
                                onClick={() => {
                                  PUquickEdit.onTrue();
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                onClick={() => {
                                  confirm.onTrue();
                                }}
                              >
                                Delete
                              </Button>
                            </>
                          )}
                        </GroupFooter>
                      </li>
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                          {option?.name}
                        </ListItem>
                      </Box>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={6} xxl={6}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    size="small"
                    disableCloseOnSelect
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    options={
                      productUnitList.length
                        ? productUnitList
                        : [{ name: 'No Option', value: null }]
                    }
                    getOptionDisabled={(option) => option?.value === null}
                    groupBy={(option) => option?.a}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setPurchaseUnit(newValue);
                      setSaleUnit(null);
                      setValue('punitId', newValue?.id);
                    }}
                    value={
                      productUnitList?.find((data) => data.id === allValues.punitId) || row?.punit
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Purchase"
                        name="punitId"
                        error={errors?.punitId}
                      />
                    )}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader component="li">
                          <ListItem sx={{ padding: 0, margin: 0 }}>Purchase Unit</ListItem>
                        </GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                        <GroupFooter component="li">
                          <Button
                            onClick={() => {
                              PUquickEdit.onTrue();
                              setPurchaseUnit(null);
                            }}
                          >
                            Add
                          </Button>
                          {purchaseUnit && (
                            <>
                              <Button
                                onClick={() => {
                                  PUquickEdit.onTrue();
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                onClick={() => {
                                  confirm.onTrue();
                                }}
                              >
                                Delete
                              </Button>
                            </>
                          )}
                        </GroupFooter>
                      </li>
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                          {option?.name}
                        </ListItem>
                      </Box>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={6} xxl={6}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    options={GSTUnitList?.map((option) => option.name)}
                    value={
                      GSTUnitList?.find((data) => data.id === allValues.ugst_unitId)?.name ||
                      row?.ugst_unit?.name
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'ugst_unitId',
                        GSTUnitList?.find((data) => data.name === newValue)?.id
                      );
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} label="GST Unit(UQC)" name="ugst_unitId" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
                <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="subtitle1">Opening Stock</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2} xxl={2}>
                  <RHFTextField name="quantity" label="Quantity" size="small" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    options={GSTUnitList?.map((option) => option.name)}
                    value={
                      GSTUnitList?.find((data) => data.id === allValues.ogst_unitId)?.name ||
                      row?.ogst_unit?.name
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'ogst_unitId',
                        GSTUnitList?.find((data) => data.name === newValue)?.id
                      );
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} label="Unit(UQC)" name="ogst_unitId" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2} xxl={2}>
                  <RHFTextField name="rate" label="Rate" size="small" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                  <RHFTextField
                    name="amount"
                    label="Amount"
                    size="small"
                    disabled
                    value={allValues.quantity * allValues.rate}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                  <RHFTextField name="alt_stock" label="Alternate Stock" size="small" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    options={GSTUnitList?.map((option) => option.name)}
                    value={
                      GSTUnitList?.find((data) => data.id === allValues.agst_unitId)?.name ||
                      row?.agst_unit?.name
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'agst_unitId',
                        GSTUnitList?.find((data) => data.name === newValue)?.id
                      );
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} label="Unit(UQC)" name="agst_unitId" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="subtitle1">Stock Options</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    options={STOCK_REQUIRED?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={STOCK_REQUIRED?.find((data) => data.id === allValues.st_req)?.name || ''}
                    onChange={(event, newValue) => {
                      setValue(
                        'st_req',
                        STOCK_REQUIRED?.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} name="st_req" label="Stock Required" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    options={QUANTITY_EFFECT?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      QUANTITY_EFFECT?.find((data) => data.id === allValues.qu_eff)?.name || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'qu_eff',
                        QUANTITY_EFFECT?.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} name="qu_eff" label="Quantity Effect" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} xxl={4}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    value={
                      STOCK_TYPE?.find((data) => data.label === allValues.stocktype)?.value || ''
                    }
                    options={STOCK_TYPE?.map((option) => option.label)}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                      setValue('stocktype', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} name="stocktype" label="Stock Type" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="subtitle1">Rate</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={5} xl={5} xxl={5}>
                  <RHFTextField name="pur_rate" label="Purchase Rate" size="small" />
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={5} xl={5} xxl={5}>
                  <RHFTextField name="sal_rate" label="Sales Rate" size="small" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Dialog
            open={dialog.value && openModelName === 'otherDetails'}
            onClose={dialog.onTrue}
            onKeyDown={(event) => handleSubModelClose(event)}
            PaperProps={{
              sx: { maxWidth: 1120 },
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              sx={{
                top: 0,
                zIndex: 9,
                position: 'sticky',
                bgcolor: (theme) => alpha(theme.palette.background.default, 1),
              }}
            >
              <Toolbar>
                <Typography variant="h6" sx={{ flex: 1 }}>
                  Edit Other Details of - test
                </Typography>

                <CompanyYear />

                <IconButton color="inherit" edge="start" onClick={dialog.onFalse}>
                  <Iconify icon="mingcute:close-line" />
                </IconButton>
              </Toolbar>

              <Divider sx={{ borderStyle: 'dashed' }} />
            </Grid>

            <Grid
              container
              xs={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              sx={{ p: 2 }}
              spacing={2}
              style={{ margin: 'auto' }}
            >
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
                spacing={2}
                sx={{ p: 2 }}
                alignContent="start"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="subtitle1">Stock Details</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="min_stock" label="Minimum Stock" size="small" autoFocus />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="reo_stock" label="Reorder Stock" size="small" />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="subtitle1">Production Detail</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    options={STOCK_REQUIRED?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={
                      STOCK_REQUIRED?.find((data) => data.id === allValues.auto_pro)?.name || ''
                    }
                    onChange={(event, newValue) => {
                      setValue(
                        'auto_pro',
                        STOCK_REQUIRED?.find((data) => data.name === newValue)?.id
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} name="auto_pro" label="Auto Production" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Autocomplete
                    autoHighlight={allValues.isHighligh}
                    fullWidth
                    freeSolo
                    size="small"
                    openOnFocus
                    onInputChange={(event, newValue) => {
                      setValue('isHighligh', newValue);
                    }}
                    onBlur={() => {
                      setValue('isHighligh', '');
                    }}
                    options={PROCESS_NAME?.map((option) => option.name)}
                    getOptionLabel={(option) => option}
                    value={PROCESS_NAME?.find((data) => data.id === allValues.pro_id)?.name || ''}
                    onChange={(event, newValue) => {
                      setValue('pro_id', PROCESS_NAME?.find((data) => data.name === newValue)?.id);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} name="pro_id" label="Process Name" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    ListboxProps={{ ...bgColorAutocomplete }}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
                sx={{ p: 2 }}
                alignContent="start"
                spacing={2}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="subtitle1">Rate Conversion</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="srcon" label="Sales Conversion Factor" size="small" />
                  <Typography fontSize={11} variant="subtitle1">
                    Sales Rate = Rate / Conversion Factor
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="prcon" label="Purchase Conversion Factor" size="small" />
                  <Typography fontSize={11} variant="subtitle1">
                    Purchase Rate = Rate / Conversion Factor
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Typography variant="subtitle1">Stock Conversion</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <RHFTextField name="stcon" label="Stock Conversion Factor" size="small" />
                  <Typography fontSize={11} variant="subtitle1">
                    Stock = Conversion Factor * Purchase
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <DialogActions
              sx={{
                bottom: 0,
                zIndex: 9,
                position: 'sticky',
                bgcolor: (theme) => alpha(theme.palette.background.default, 1),
              }}
            >
              <Button onClick={dialog.onFalse} variant="outlined" color="inherit">
                Cancel
              </Button>
              <Button onClick={dialog.onFalse} variant="contained">
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <Stack
            direction="row-reverse"
            alignItems="center"
            sx={{
              bottom: 0,
              zIndex: 111111,
              p: 3,
              position: 'sticky',
              justifyContent: 'space-between',
              width: '100%',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
            id="modelfooter"
          >
            <Tooltip title="(Alt + A)" arrow>
              <LoadingButton
                onClick={async () => {
                  await trigger();
                  onSubmit();
                }}
                variant="contained"
                loading={loading}
              >
                {row ? 'Update' : 'Add'}
              </LoadingButton>
            </Tooltip>

            <Tooltip title="(Esc)" arrow>
              <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
                Cancel
              </Button>
            </Tooltip>

            <Button variant="soft" onClick={() => reset(values)} sx={{ mr: 2 }}>
              Reset
            </Button>

            <Stack direction="row" alignItems="center" flexGrow={1} spacing={2}>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => {
                  setOpenModelName('otherDetails');
                  dialog.onTrue();
                }}
              >
                Other Details
              </Button>
              {PCategory?.name?.toLowerCase() === 'vehicle' && (
                <>
                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => {
                      setOpenModelName('productmodel');
                      modelDialog.onTrue();
                    }}
                  >
                    Product Model
                  </Button>
                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => {
                      setOpenModelName('pricemaster');
                      pricemasterdialog.onTrue();
                    }}
                  >
                    Price Master
                  </Button>
                  {row?.id && (
                    <Button
                      variant="outlined"
                      color="inherit"
                      onClick={() => {
                        serviceMasterDialog.onTrue();
                      }}
                    >
                      Service Master
                    </Button>
                  )}

                  {row && (
                    <Button
                      variant="outlined"
                      color="inherit"
                      onClick={() => {
                        setOpenModelName('openingstock');
                        vehicleopeningstockdialog.onTrue();
                      }}
                    >
                      Opening Stock
                    </Button>
                  )}
                </>
              )}
              {PCategory?.name?.toLowerCase() === 'accessories' && (
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    setOpenModelName('accesoriesgroup');
                    accessoriesBombDialog.onTrue();
                  }}
                >
                  GROUP
                </Button>
              )}
            </Stack>
          </Stack>
        </FormProvider>
      </Dialog>
      <GSTCommodityQuickEditForm
        row={null || GSTCommodity}
        open={quickEdit.value}
        onClose={handleGroupClose}
        table={table}
        getAll={getAll}
        tableData={tableData}
      />
      <ProductGroupQuickEditForm
        row={null || PGroup}
        open={PGquickEdit.value}
        onClose={handlePGroupClose}
        table={table}
        getAll={getAll}
        tableData={tableData}
      />
      {pricemasterdialog.value && (
        <ProductPriceQuickEditForm
          row={{ ...allValues.pricemaster, avariantId: allValues.avariantId } || null}
          open={pricemasterdialog.value}
          onClose={pricemasterdialog.onFalse}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
      {modelDialog.value && (
        <ProductModelQuickEditForm
          row={allValues.productmodel || null}
          open={modelDialog.value}
          onClose={modelDialog.onFalse}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
      {vehicleopeningstockdialog.value && (
        <VehicleOpeningStock
          row={row || null}
          open={vehicleopeningstockdialog.value}
          onClose={vehicleopeningstockdialog.onFalse}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
      {accessoriesBombDialog.value && (
        <AccessoriesGroups
          row={null}
          accesoriesgroup={allValues.accessoriesgroups}
          setaccesoriesgroup={setValue}
          open={accessoriesBombDialog.value}
          onClose={accessoriesBombDialog.onFalse}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
      {serviceMasterDialog.value && (
        <ServiceQuickEditForm
          productId={row?.id || null}
          open={serviceMasterDialog.value}
          onClose={serviceMasterDialog.onFalse}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}
      <ProductUnitEditForm
        row={null || saleUnit || purchaseUnit}
        open={PUquickEdit.value}
        onClose={handlePUnitClose}
        table={table}
        getAll={getAll}
        tableData={tableData}
      />
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() =>
              (GSTCommodity?.id && deleteAccountGroupModel(GSTCommodity?.id)) ||
              (PGroup?.id && deletePGroupModel(PGroup?.id)) ||
              (PCategory?.id && deletePCategoryModel(PCategory?.id)) ||
              (saleUnit?.id && deletePUnitModel(saleUnit?.id)) ||
              (purchaseUnit?.id && deletePUnitModel(purchaseUnit?.id))
            }
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

PUserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
