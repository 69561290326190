import { Helmet } from 'react-helmet-async';
import { COMPANY_NAME } from 'src/_mock/constant';
// sections
import { AccountView } from 'src/sections/settings/view';

// ----------------------------------------------------------------------

export default function AccountPage() {
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} : Account Settings</title>
      </Helmet>

      <AccountView />
    </>
  );
}
