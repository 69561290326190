import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Button,
  Typography,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { KEY } from 'src/_mock/constant';
import FormProvider from 'src/components/hook-form/form-provider';
import { useEventListener } from './use-event-listener';

export function CashManagementModal({
  open,
  onClose,
  cashCounter,
  updateCashCounter,
  setNetTotal,
}) {
  const [localCashCounter, setLocalCashCounter] = useState([...cashCounter]);
  // Calculate the total for either payments or refunds
  const calculateTotal = (type) =>
    localCashCounter.reduce((total, item) => total + item[type] * item.cash, 0);

  const [mainTotal, setMainTotal] = useState(calculateTotal('Payment') || null);

  // Initialize the modal's local state with the cashCounter from the parent component when the modal opens
  useEffect(() => {
    setLocalCashCounter([...cashCounter]);
  }, [cashCounter, open]);

  // Handle changes to payment and refund counts
  const handleCountChange = (index, type, delta) => {
    const updated = [...localCashCounter];
    const newValue = updated[index][type] + delta;
    updated[index][type] = Math.max(0, newValue); // Ensure values do not go negative
    setLocalCashCounter(updated);
  };

  // Submit the updated cash counters back to the parent component
  const handleSubmit = () => {
    const totalPayment = calculateTotal('Payment');
    const totalRefund = calculateTotal('Refund');
    const netTotal = totalPayment - totalRefund;
    setNetTotal(netTotal);

    updateCashCounter(localCashCounter);
    onClose();
  };

  const nextTotal2 = calculateTotal('Payment') - calculateTotal('Refund');
  
  const handleKeyDown = async (event) => {
    // eslint-disable-next-line eqeqeq
    if (mainTotal == nextTotal2 && event.key.toLowerCase() === KEY.OK) {
      handleSubmit();
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: { maxWidth: 850 },
      }}
    >
      <FormProvider>
        <Grid sx={{ display: 'flex' }}>
          <Typography variant="h6" sx={{ m: 2 }}>
            Cash Breakdown
          </Typography>
          <Typography variant="h6" sx={{ m: 2 }}>
            <TextField
              autoFocus
              label="Total"
              name="total"
              value={mainTotal}
              onChange={(e) => setMainTotal(e.target.value)}
              sx={{ width: '6rem' }}
              size="small"
              variant="standard"
            />
          </Typography>
        </Grid>

        <Grid m={2} sx={{display:"flex"}}>
          <Table>
            <TableBody
              sx={{
                '& .MuiTableCell-sizeMedium': {
                  padding: '2px !important',
                  textAlign: 'center',
                },
                '& .MuiInputBase-input': {
                  padding: '5px !important',
                },
              }}
            >
              <TableRow>
                <TableCell>Payment</TableCell>
                <TableCell>Payment Total</TableCell>
              </TableRow>
              {localCashCounter.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      value={item.Payment}
                      onChange={(e) =>
                        handleCountChange(index, 'Payment', e.target.value - item.Payment)
                      }
                      type="number"
                      sx={{ width: '4rem', mx: 1 }}
                    />
                  </TableCell>

                  <TableCell
                    style={{
                      backgroundColor: '#D3D3D3',
                      minWidth: '4rem',
                    }}
                  >
                    {item.cash * item.Payment}
                  </TableCell>
                 
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Table>
            <TableBody
              sx={{
                '& .MuiTableCell-sizeMedium': {
                  padding: '2px !important',
                  textAlign: 'center',
                },
                '& .MuiInputBase-input': {
                  padding: '5px !important',
                },
              }}
            >
              <TableRow>
                <TableCell>Cash Denomination</TableCell>
              </TableRow>
              {localCashCounter.map((item, index) => (
                <TableRow key={index}>
                  
                  <TableCell
                    style={{
                      minWidth: '4rem',
                    }}
                  >
                    Rs. {item.cash}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Table>
            <TableBody
              sx={{
                '& .MuiTableCell-sizeMedium': {
                  padding: '2px !important',
                  textAlign: 'center',
                },
                '& .MuiInputBase-input': {
                  padding: '5px !important',
                },
              }}
            >
              <TableRow>
                <TableCell>Refund</TableCell>
                <TableCell>Refund Total</TableCell>
              </TableRow>
              {localCashCounter.map((item, index) => (
                <TableRow key={index}>
                   <TableCell>
                    <TextField
                      value={item.Refund}
                      onChange={(e) =>
                        handleCountChange(index, 'Refund', e.target.value - item.Refund)
                      }
                      type="number"
                      sx={{ width: '4rem', mx: 1 }}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: '#D3D3D3',
                      minWidth: '4rem',
                    }}
                  >
                    {item.cash * item.Refund}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
          <Typography>Total Payments: Rs. {calculateTotal('Payment')}</Typography>
          <Typography>
            Total Refunds: Rs.{' '}
            {mainTotal - calculateTotal('Payment') + calculateTotal('Refund') || 0}
          </Typography>
          {/* calculateTotal('Refund') */}
          <Typography>
            Net Total: Rs. {calculateTotal('Payment') - calculateTotal('Refund')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}>
          <Button onClick={handleSubmit} variant="contained">
            OK
          </Button>
        </Box>
      </FormProvider>
    </Dialog>
  );
}

CashManagementModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  cashCounter: PropTypes.array.isRequired,
  updateCashCounter: PropTypes.func.isRequired,
  setNetTotal: PropTypes.func.isRequired,
};
