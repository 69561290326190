import { Helmet } from 'react-helmet-async';
import { COMPANY_NAME } from 'src/_mock/constant';
// sections
import { ListView } from 'src/sections/gst/master/gst-unit/view';

// ----------------------------------------------------------------------

export default function GSTUnitPage() {
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} : GST Unit</title>
      </Helmet>

      <ListView />
    </>
  );
}
