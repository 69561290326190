import { Helmet } from 'react-helmet-async';
import { COMPANY_NAME } from 'src/_mock/constant';
// sections
import { ListView } from 'src/sections/gst/entry/utilization-entry/view';

// ----------------------------------------------------------------------

export default function UtilizationEntryListPage() {
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} : Utilization Entry List</title>
      </Helmet>

      <ListView />
    </>
  );
}
