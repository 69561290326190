import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
// @mui
import Chip from '@mui/material/Chip';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { Box } from '@mui/material';
import { ConfirmDialog } from 'src/components/custom-dialog';

// ----------------------------------------------------------------------

export default function JobFilters({
  open,
  onOpen,
  onClose,
  filters,
  onFilters,
  data,
  canReset,
  onResetFilters,
  handleDeleteRows
}) {

  const [openConfirm, setOpenConfirm] = useState(null); // Track which item to confirm delete
  const handleOpenConfirm = (id) => setOpenConfirm(id);
  const handleCloseConfirm = () => setOpenConfirm(null);

  const handleFilterExperience = useCallback(
    (newValue) => {
      onFilters('experience', newValue);
    },
    [onFilters]
  );

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        List
      </Typography>

      <Tooltip title="Reset">
        <IconButton onClick={onResetFilters}>
          <Badge color="error" variant="dot" invisible={!canReset}>
            <Iconify icon="solar:restart-bold" />
          </Badge>
        </IconButton>
      </Tooltip>

      <IconButton onClick={onClose}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  const renderExperience = (
    <Stack>
      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Experience
      </Typography>
      {data.map((option) => (
        <Stack direction="row"
          key={option.id}
          alignItems="center"
          justifyContent="space-between">
          <FormControlLabel
            control={
              <Radio
                checked={option.id === filters.experience}
                onClick={() => handleFilterExperience(option.id)}
              />
            }
            label={option.name}
            sx={{
              ...(option.name === 'all' && {
                textTransform: 'capitalize',
              }),
            }}
          />
          <Button
            size="small"
            color="error"
            startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
            onClick={() => handleOpenConfirm(option.id)}
          />
          <ConfirmDialog
            open={openConfirm === option.id}
            onClose={handleCloseConfirm}
            title="Delete"
            content="Are you sure want to delete?"
            action={
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  handleDeleteRows(option.id); // Delete specific item
                  handleCloseConfirm(); // Close dialog after deletion
                }}
              >
                Delete
              </Button>
            }
          />
        </Stack>
      ))}
    </Stack>
  );

  return (
    <>
      <Button
        disableRipple
        color="inherit"
        endIcon={
          <Badge color="error" variant="dot" invisible={!canReset}>
            <Iconify icon="ic:round-filter-list" />
          </Badge>
        }
        onClick={onOpen}
      >
        Lists
      </Button>

      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: "40%" },
        }}
      >
        {renderHead}

        <Divider />

        <Scrollbar sx={{ px: 2.5, py: 3 }}>
          <Stack spacing={3}>
            {renderExperience}
          </Stack>
        </Scrollbar>

      </Drawer>
    </>
  );
}

JobFilters.propTypes = {
  canReset: PropTypes.bool,
  filters: PropTypes.object,
  onClose: PropTypes.func,
  onFilters: PropTypes.func,
  onOpen: PropTypes.func,
  onResetFilters: PropTypes.func,
  open: PropTypes.bool,
  data: PropTypes.array,
  handleDeleteRows: PropTypes.func,
};
