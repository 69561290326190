/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-case-declarations */
/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
/* eslint-disable new-cap */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-pascal-case */
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import React, { useState, useCallback, useEffect, useContext, useMemo } from 'react';
import { PDFDownloadLink, Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';

// @mui
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
// import PivotTableUI from 'src/components/pivottable/PivotTableUI';
// import TableRenderers from 'src/components/pivottable/TableRenderers';
import 'src/components/pivottable/pivottable.css';
import 'src/components/pivottable/grouping.css';
// import createPlotlyRenderers from 'src/components/pivottable/PlotlyRenderers';
import PivotTableUI from 'react-pivottable-grouping/PivotTableUI';
import TableRenderers from 'react-pivottable-grouping/TableRenderers';
import createPlotlyRenderers from 'react-pivottable-grouping/PlotlyRenderers';
import Plot from 'react-plotly.js';
import { useDebounce } from 'src/hooks/use-debounce';
// routes
import { paths } from 'src/routes/paths';

// components
import {
  RHFCheckbox,
  RHFSelect,
} from 'src/components/hook-form';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import queryString from 'query-string';
import { useSnackbar } from 'src/components/snackbar';
import { useTable, TablePaginationCustom } from 'src/components/table';
import { bank_cash_report_formate, report_periods } from 'src/_mock/constant';
import { useEventListener } from 'src/hooks/use-event-listener';
import MenuItem from '@mui/material/MenuItem';
//

import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { fMoney, stickHeader } from 'src/_mock/constant_funcation';
import { AuthContext } from 'src/auth/context/jwt';
import moment from 'moment';
import { DateField, DatePicker } from '@mui/x-date-pickers';
import { fetcher, destroy } from 'src/utils/axios';
import LOGO from 'src/assets/logo/amideep_honda.jpg';
import Scrollbar from 'src/components/scrollbar';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import NotFound from 'src/sections/error/notfound-invoice';
import Iconify from 'src/components/iconify';
import { isNaN } from 'lodash';
import { MaterialReactTable, MRT_ExpandAllButton, useMaterialReactTable } from 'material-react-table';
import { useBoolean } from 'src/hooks/use-boolean';
import { CheckBox, Label } from '@mui/icons-material';
import { aggregatorTemplates, PivotData } from 'react-pivottable/Utilities';
import useSWR, { mutate } from 'swr';
import tableToExcel from "@linways/table-to-excel";
import ReportFilters from './report-filters';

import CreateEdit from './report-new-edit-form';
import ReportSearch from './report-search';
import SaleInvoicePDF from './sale-invoice-pdf';


export function useGetReports(URL) {

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, { keepPreviousData: false });

  const memoizedValue = useMemo(
    () => ({
      reports: data?.data?.rows || [],
      allreadyusedkeys: data?.data?.allreadyusedkeys || [],
      reportLoading: isLoading,
      reportError: error,
      reportValidating: isValidating,
      reportEmpty: !isLoading && !data?.data?.rows?.length,
    }),
    [data?.data?.allreadyusedkeys, data?.data?.rows, error, isLoading, isValidating]
  );

  return memoizedValue;
}
const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
  experience: '',
};

const TABS = [
  {
    value: 'general',
    label: 'General',
    icon: <Iconify icon="solar:user-id-bold" width={24} />,
  },
  {
    value: 'matrix',
    label: 'Matrix',
    icon: <Iconify icon="solar:bill-list-bold" width={24} />,
  },
];
// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);


// see documentation for supported input formats
const data = [['attribute', 'attribute2'], ['value1', 'value2']];
// ----------------------------------------------------------------------
const based = { 1: 'SALE', 2: 'STOCK' }
const TYP = { 'i': 'INVOICE', 'c': 'CHALLAN' }

// Styles for the PDF
const styles = StyleSheet.create({
  page: { padding: 10 },
  table: { display: 'table', width: 'auto', borderCollapse: 'collapse' },
  tableRow: { flexDirection: 'row' },
  tableCell: {
    borderWidth: 1,
    borderColor: '#000', // Border color for the cells
    borderStyle: 'solid',
    padding: 5,
    fontSize: 10,
    textAlign: 'center',
  },
  headerCell: {
    backgroundColor: '#f0f0f0',
    fontWeight: 'bold',
    borderWidth: 1,
    borderColor: '#000', // Border color for header cells
    borderStyle: 'solid',
    padding: 5,
    fontSize: 10,
    textAlign: 'center',
  },
});


// Function to convert the HTML table to an array structure
const parseTableHTML = (pivotTableElement) => {
  const rows = Array.from(pivotTableElement.querySelectorAll('tr'));
  return rows.map((row) => {
    const cells = Array.from(row.querySelectorAll('th, td'));
    return cells.map((cell) => ({
      content: cell.textContent,
      colspan: cell.colSpan,
      rowspan: cell.rowSpan,
      isHeader: cell.tagName === 'TH',
    }));
  });
};

// Function to convert parsed table data to react-pdf components
const generateTable = (tableData) => {
  return (
    <View style={styles.table}>
      {tableData.map((row, rowIndex) => (
        <View key={rowIndex} style={styles.tableRow}>
          {row.map((cell, cellIndex) => (
            <View
              key={cellIndex}
              style={[
                styles.tableCell,
                cell.isHeader && styles.headerCell,
                { flexBasis: cell.colspan ? `${cell.colspan * 100}%` : 'auto' },
              ]}
              colSpan={cell.colspan}
              rowSpan={cell.rowspan}
            >
              <Text>{cell.content}</Text>
            </View>
          ))}
        </View>
      ))}
    </View>
  );
};

// Custom aggregator function for summing integers
// eslint-disable-next-line arrow-body-style
const sumIntegerAggregator = function (attributeArray) {
  const attribute1 = attributeArray[0]; // First attribute
  const attribute2 = attributeArray[1]; // Second attribute
  return function (data, rowKey, colKey) {
    let sum1 = 0;
    let sum2 = 0;
    return {
      push: (record) => {
        const value1 = parseInt(record[attribute1], 10);
        const value2 = parseInt(record[attribute2], 10);
        if (!isNaN(value1)) {
          sum1 += value1;
        }
        if (!isNaN(value2)) {
          sum2 += value2;
        }
      },
      value: () => [sum1, sum2], // Return an array of sums
      format: (x) => x.map((v) => v.toLocaleString()).join(' | '), // Format the two sums
      numInputs: 2, // Specifies that the aggregator expects two input attributes
    };
  };
};
// Custom aggregator function to sum integers
// const sumIntegerAggregator = function (attribute) {
//   return function () {
//     return {
//       sum: 0,
//       push: (record) => {
//         const value = parseInt(record[attribute], 10);
//         if (!isNaN(value)) {
//           this.sum += value;
//         }
//       },
//       value: () => this.sum,
//       format: (x) => x.toLocaleString(),
//     };
//   };
// };

export default function ListView({ reportsDetails }) {
  const [grouping, setGrouping] = useState(false)
  // const table = useTable({ defaultRowsPerPage: 10 });
  const aggregators = {
    ...aggregatorTemplates, // default aggregators provided by PivotTable
    "integersumqty": sumIntegerAggregator,
  };
  // Custom Renderer for dynamically added fields
  const customRenderer = (props) => {
    const data = new PivotData(props);
    console.log(data)
    const rowKeys = data.getRowKeys();
    const colKeys = data.getColKeys();

    return (
      <table className="pvtTable">
        <thead>
          <tr>
            {props.rows.map((r) => (
              <th className="pvtAxisLabel" key={`row-${r}`}>
                {r}
              </th>
            ))}
            {colKeys.map((colKey) =>
              ['sold', 'amount', 'otherField'].map((field) => (
                <th className="pvtColLabel" key={`col-${colKey.join("-")}-${field}`}>
                  {field}
                </th>
              ))
            )}
          </tr>
        </thead>
        <tbody>
          {rowKeys.map((rowKey, i) => (
            <tr key={`row-${rowKey.join("-")}`}>
              {rowKey.map((r, j) => (
                <td className="pvtRowLabel" key={`rowKey-${i}-${j}`}>
                  {r}
                </td>
              ))}
              {colKeys.map((colKey) => {
                const aggregator = data.getAggregator(rowKey, colKey);
                const values = aggregator.value();
                return (
                  <React.Fragment key={`cell-${colKey.join("-")}`}>
                    {['qty', 'amount', 'otherField'].map((field) => (
                      <td className="pvtVal" key={`value-${field}`}>
                        {values?.[field]}
                      </td>
                    ))}
                  </React.Fragment>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  const users = useSWR(
    `users?page=${1}&limit=${1000}&status=true&forpermission=true&`,
    fetcher,
    { keepPreviousData: false }
  );
  // useEffect(() => {
  //   // Call stickHeader on mount and after each update
  //   const timer = setTimeout(() => {
  //     stickHeader();
  //   }, 0);

  //   return () => clearTimeout(timer); // Clean up the timeout on unmount
  // });
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();
  const { user } = useContext(AuthContext);
  const ur = `automobile/reports/report?page=${1}&limit=${100}&status=true&userId=${user.id}&`;
  // Call this function after creating or deleting a report
  const refreshReports = useCallback(() => {
    mutate(ur);
  }, [ur])

  const reports = useGetReports(ur)

  const startDate = moment(user?.co_year?.sdate);
  const endDate = moment(user?.co_year?.edate);

  // Format the dates in the desired format
  const formattedStartDate = startDate.format('DD/MM/YYYY');
  const formattedEndDate = endDate.format('DD/MM/YYYY');

  // Convert formattedStartDate and formattedEndDate back to moment objects for comparison
  const startDateMoment = moment();
  const endDateMoment = moment();

  const [reactpdfData, setReactpdfData] = useState([]);
  const [pivotData, setPivotData] = useState({
    grouping: false, rowGroupBefore: false,
    colGroupBefore: false,
    compactRows: false
  });
  const [currentReport, setCurrentReport] = useState({});

  const [information, setData] = useState({
    ac_groupId: null,
    accountId: null,
    t_type: 'i',
    basedon: 1,
    formate: '2',
    period: '1',
    fdate: startDateMoment,
    tdate: endDateMoment,
    accountList: [],
    accountMeta: {
      page: 1,
      name: '',
      totalPage: 1,
      editRow: null,
    },
  });
  useEffect(() => {
    switch (information.period) {
      case '1': {
        setData((pre) => ({ ...pre, fdate: new Date(), tdate: new Date() }))
        break;
      }
      case '2': {
        const startOfWeek = moment().startOf('isoWeek');
        const endOfWeek = moment(startOfWeek).endOf('isoWeek');
        setData((pre) => ({ ...pre, fdate: startOfWeek, tdate: endOfWeek }))
        break;
      }
      case '3': {
        const startOfMonth = moment().startOf('month');
        const endOfMonth = moment(startOfMonth).endOf('month');
        setData((pre) => ({ ...pre, fdate: startOfMonth, tdate: endOfMonth }))
        break;
      }
      case '4': {
        const startOfQuarter = moment().startOf('quarter');
        const endOfQuarter = moment(startOfQuarter).endOf('quarter');

        setData((pre) => ({ ...pre, fdate: startOfQuarter, tdate: endOfQuarter }))
        break;
      }
      case '5': {

        const startOfYear = moment().startOf('year');
        const endOfYear = moment(startOfYear).endOf('year');
        setData((pre) => ({ ...pre, fdate: startOfYear, tdate: endOfYear }));
        break;
      }
      default:
        const startOfMonth = moment().subtract('month', 1).startOf('month');
        const endOfMonth = moment().subtract('month', 1).endOf('month');
        setData((pre) => ({ ...pre, fdate: startOfMonth, tdate: endOfMonth }))
        break;

    }
  }, [information.period])
  const debouncedInfromation = useDebounce(information);
  const [pageDimensions, setPageDimensions] = useState({ width: 600, height: 400 }); // Default size
  const handleExportRows = (rows) => {
    const doc = new jsPDF({ orientation: 'landscape' });
    const tableHeaders = columns.map((c) => c.header);
    const tableHeaderObj = {}
    columns.forEach((c) => {
      tableHeaderObj[c.header] = c.id
    });
    const tableData = rows.map((row) => tableHeaders.map(y => row.original[tableHeaderObj[y]]));
    // Text for the header

    const companyName = user?.companies.find((x) => x.id === user.companyId)?.name;
    const reportTitle = `DAILY ${based[debouncedInfromation.basedon]} REPORT ${moment(debouncedInfromation.fdate).format('DD-MM-YYYY')} TO ${moment(debouncedInfromation.tdate).format('DD-MM-YYYY')}`;

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      styles: { fontSize: 6 },
      margin: { top: 20 },
      didDrawPage: (data) => {
        // Set font to bold and size
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold');

        // Add company name on the left side
        doc.text(`COMPANY : ${companyName}`, data.settings.margin.left, 10);

        // Add the logo on the left side within the header height
        const imgWidth = 15; // Adjust width according to your logo
        const imgHeight = 15; // Adjust height according to your logo
        const logoXPos = data.settings.margin.left + 2; // Adjust X position
        const logoYPos = 10; // Adjust Y position as needed
        // doc.addImage(LOGO, 'PNG', logoXPos, logoYPos, imgWidth, imgHeight);
        // Calculate the center position for the report title
        const pageWidth = doc.internal.pageSize.getWidth();
        const centerPosition = pageWidth / 2;

        // Add the report title at the top center of each page
        doc.text(reportTitle, centerPosition, 10, { align: 'center' });
      },
      didDrawCell: (data) => {
        if (data.cell.section === 'body') {
          const rowData = rows[data.row.index].original;
          console.log(table.getGr)

        }
      }
    });
    doc.save(`${based[information.basedon]}_VEHICLE_${TYP[information.t_type]}_REPORT_${moment().format('DD_MM_YYYY_HH_MM_ss')}.pdf`);
  };
  const exportHTMLTableToPDF = () => {
    const pixelToPointRatio = 96 / 72; // Conversion ratio from pixels to points
    const widthInPoints = 2000 / pixelToPointRatio; // Convert 2000px to points
    const pivotTableElement = document.getElementsByClassName('pvtTable')[0];
    if (pivotTableElement) {
      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'pt', // Set the unit to points
        format: [widthInPoints, 595.28], // 595.28 is the default height for A4 landscape in points
        compressPdf: true
      });
      const tableHeaders = columns.map((c) => c.header);
      const tableHeaderObj = {}
      columns.forEach((c) => {
        tableHeaderObj[c.header] = c.id
      });

      const companyName = user?.companies.find((x) => x.id === user.companyId)?.name;
      const reportTitle = `DAILY ${based[information.basedon]} REPORT ${moment(information.fdate).format('DD-MM-YYYY')} TO ${moment(information.tdate).format('DD-MM-YYYY')}`;

      // Use the html method of autoTable to directly convert the HTML table to a PDF
      autoTable(doc, {
        html: pivotTableElement,
        margin: { top: 20 },
        styles: { fontSize: 8 },
        didDrawPage: (data) => {
          // Set font to bold and size
          doc.setFontSize(10);
          doc.setFont('helvetica', 'bold');

          // Add company name on the left side
          doc.text(`COMPANY : ${companyName}`, data.settings.margin.left, 10);

          // Add the logo on the left side within the header height
          const imgWidth = 15; // Adjust width according to your logo
          const imgHeight = 15; // Adjust height according to your logo
          const logoXPos = data.settings.margin.left + 2; // Adjust X position
          const logoYPos = 10; // Adjust Y position as needed
          // doc.addImage(LOGO, 'PNG', logoXPos, logoYPos, imgWidth, imgHeight);
          // Calculate the center position for the report title
          const pageWidth = doc.internal.pageSize.getWidth();
          const centerPosition = pageWidth / 2;

          // Add the report title at the top center of each page
          doc.text(reportTitle, centerPosition, 10, { align: 'center' });
        },
      });

      doc.save('pivot-table-export.pdf');
    } else {
      console.error('Pivot table element not found.');
    }
  };

  const exportHTMLTOREACtPDF = () => {
    const pivotTableElement = document.getElementsByClassName('pvtTable')[0];
    if (pivotTableElement) {
      const parsedData = parseTableHTML(pivotTableElement);
      setTableData(parsedData);
      // Get the dimensions of the pivot table element
      const { offsetWidth: width, offsetHeight: height } = pivotTableElement;
      setPageDimensions({ width, height });
    }
  }
  const exportExcel = () => {
    const htmlTable = document.querySelector(".pvtTable").cloneNode(true);
    const reportTitle = `DAILY ${based[information.basedon]} REPORT ${moment(information.fdate).format('DD-MM-YYYY')} TO ${moment(information.tdate).format('DD-MM-YYYY')}`;

    // Calculate max width for each column
    const columns = [];
    const htmlTableHead = htmlTable.querySelector("thead");
    const htmlTableBody = htmlTable.querySelector("tbody");
    const htmlHeadRows = htmlTableHead.querySelectorAll("tr");
    const htmlBodyRows = htmlTableBody.querySelectorAll("tr");

    // Initialize columns array based on the number of columns in the first row of either header or body
    const firstRowCells = htmlBodyRows[0]?.querySelectorAll("th, td") || htmlHeadRows[0].querySelectorAll("th");
    for (let i = 0; i < firstRowCells.length; i += 1) {
      columns.push(0); // Start with 0 width for each column
    }

    // Function to update max length in columns array
    const updateMaxWidth = (htmlCells, columns) => {
      htmlCells.forEach((htmlCell, index) => {
        const cellContent = htmlCell.textContent.replace(/,/g, ''); // Remove commas
        const cellLength = cellContent.length;
        if (cellLength > columns[index]) {
          columns[index] = cellLength; // Update max length for this column
        }
      });
    };

    // Calculate max width for each column in the header
    htmlHeadRows.forEach((headRow) => {
      const htmlHeadCells = headRow.querySelectorAll("th");
      updateMaxWidth(htmlHeadCells, columns);
    });

    // Calculate max width for each column in the body
    htmlBodyRows.forEach((bodyRow) => {
      const htmlBodyCells = bodyRow.querySelectorAll("th, td");
      updateMaxWidth(htmlBodyCells, columns);
    });

    // Create data-cols-width attribute value
    const colsWidth = columns.map(length => Math.max(length, 12)).join(","); // Minimum width of 5 characters (40px)


    // Set data-cols-width attribute
    htmlTable.setAttribute("data-cols-width", colsWidth);
    console.log(htmlTable)

    // Function to set column width
    const setColumnWidth = (htmlCell, maxLength) => {
      const estimatedWidth = Math.max(maxLength * 8, 64); // 8px per character, minimum 64px
      htmlCell.setAttribute("data-width", estimatedWidth);
    };

    htmlHeadRows.forEach((headRow) => {
      const htmlHeadCells = headRow.querySelectorAll("th");
      htmlHeadCells.forEach((htmlCell) => {
        const isAxisLabel = htmlCell.classList.contains("pvtAxisLabel");
        const isColLabel = htmlCell.classList.contains("pvtColLabel");
        const isTotalLabel = htmlCell.classList.contains("pvtTotalLabel");
        htmlCell.setAttribute("data-f-bold", true);

        if (isAxisLabel) {
          htmlCell.setAttribute("data-a-h", "left");
          htmlCell.setAttribute("data-a-v", "middle");
        }
        if (isColLabel) {
          htmlCell.setAttribute("data-a-h", "center");
          htmlCell.setAttribute("data-a-v", "middle");
        }
        if (isTotalLabel) {
          htmlCell.setAttribute("data-a-h", "center");
          htmlCell.setAttribute("data-a-v", "middle");
        }
        setColumnWidth(htmlCell, htmlCell.textContent.length);
      });
    });

    htmlBodyRows.forEach((bodyRow) => {
      const htmlBodyCells = bodyRow.querySelectorAll("th, td");
      htmlBodyCells.forEach((htmlCell) => {
        const isRowLabel = htmlCell.classList.contains("pvtRowLabel");
        const isValue = htmlCell.classList.contains("pvtVal");
        const isTotal = htmlCell.classList.contains("pvtTotal");
        const isTotalLabel = htmlCell.classList.contains("pvtTotalLabel");
        const isGrandTotal = htmlCell.classList.contains("pvtGrandTotal");

        if (isRowLabel) {
          htmlCell.setAttribute("data-a-h", "left");
          htmlCell.setAttribute("data-a-v", "middle");
        }
        if (isValue) {
          // Remove commas for numeric check and parse the value
          const cellContent = htmlCell.textContent.replace(/,/g, '');
          const cellValue = parseFloat(cellContent);

          // Check if the parsed value is NaN, if so set it to 0, otherwise set it as a number
          if (isNaN(cellValue)) {
            htmlCell.textContent = "0";
          } else {
            htmlCell.textContent = cellValue.toString();
          }
          htmlCell.setAttribute("data-a-h", "right");
          htmlCell.setAttribute("data-a-v", "middle");
          htmlCell.setAttribute("data-t", "n");
        }
        if (isTotal || isTotalLabel || isGrandTotal) {
          htmlCell.setAttribute("data-a-h", "center");
          htmlCell.setAttribute("data-a-v", "middle");
        }
        setColumnWidth(htmlCell, htmlCell.textContent.length);
      });
    });

    tableToExcel.convert(htmlTable, { name: `${reportTitle}.xlsx` });
  };


  // eslint-disable-next-line react/no-unstable-nested-components
  const MyDocument = () => (
    <Document>
      <Page size={[pageDimensions.width, pageDimensions.height + 1000]} style={styles.page}>
        {generateTable(tableData)}
      </Page>
    </Document>
  );
  const openFilters = useBoolean();
  const createedit = useBoolean();
  const creatreport = useBoolean();
  const [filters, setFilters] = useState(defaultFilters);
  const handleFilters = useCallback((name, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);
  const canReset = !isEqual(defaultFilters, filters);
  const [tableData, setTableData] = useState([]);
  const [reportData, setReportData] = useState({});
  const [count, setCount] = useState(0);
  const view = useBoolean();
  const [search, setSearch] = useState({
    query: '',
    results: [],
  });
  const handleSearch = useCallback(
    (inputValue) => {
      setSearch((prevState) => ({
        ...prevState,
        query: inputValue,
      }));

      if (inputValue) {
        const results = reports.reports.filter(
          (job) => job.name?.toLowerCase().indexOf(search.query.toLowerCase()) !== -1
        );

        setSearch((prevState) => ({
          ...prevState,
          results,
        }));
      }
    },
    [reports.reports, search.query]
  );
  useEffect(() => {
    exportHTMLTOREACtPDF()
  }, [reports.reports])

  useEffect(() => {
    if (currentReport.setting) {
      setData((pre) => ({ ...pre, t_type: currentReport.setting.t_type, basedon: currentReport.setting.basedon }))
      setPivotData(pre => ({ ...pre, ...currentReport.setting }))
    }
  }, [currentReport])
  // Custom aggregator to dynamically sum multiple fields
  const dynamicFieldSumAggregator = (fields) => {
    return function () {
      const sums = {}; // Initialize an object to hold sums for each field

      // Initialize sums for each field dynamically
      fields.forEach((field) => {
        sums[field] = 0;
      });

      return {
        push(record) {
          // Sum each field's value in the record
          fields.forEach((field) => {
            sums[field] += parseFloat(record[field]) || 0;
          });
        },
        value() {
          // Return the sums object containing each field's sum
          return sums;
        },
        format: (x) => x, // Display as-is without further formatting
      };
    };
  };

  useEffect((s) => {
    const temp = {};

    const findIndex = reports.reports.findIndex(x => x.id === filters.experience);
    console.log(filters.experience, findIndex, reports.reports)
    if (findIndex >= 0) {
      const updatedBCDATA = [...reports.reports]; // Create a new array to maintain immutability
      updatedBCDATA[findIndex] = {
        ...updatedBCDATA[findIndex],
        setting: {
          ...updatedBCDATA[findIndex].setting,
          ...temp
        }
      };

      setCurrentReport(updatedBCDATA[findIndex])
    }
    else {
      setCurrentReport({})
    }

  }, [reports.reports, filters.experience]); // Added excludepviot dependency if it changes


  const [currentTab, setCurrentTab] = useState('general');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);
  const columns = [
    {
      header: 'BRANCH',
      accessorKey: 'branchName',
      id: 'branchName',
      width: 50
    },
    {
      header: 'MODEL',
      accessorKey: 'productName',
      id: 'productName',
      size: 250
    },
    {
      header: 'CUSTOMER NAME',
      accessorKey: 'customerName',
      id: 'customerName',
      size: 250
    },
    {
      header: 'INV NO',
      accessorKey: 'identity',
      id: 'identity',
    },
    {
      header: 'INV DT',
      accessorKey: 'dt',
      id: 'dt',
    },
    {
      header: 'COLOUR',
      accessorKey: 'colourName',
      id: 'colourName',
    },

    {
      header: 'CHASSIS NO',
      accessorKey: 'chassisNo',
      id: 'chassisNo',
    },
    {
      header: 'ENGIN NO',
      accessorKey: 'enginNo',
      id: 'enginNo',
    },
    {
      header: 'AMOUNT',
      accessorKey: 'amount',
      id: 'amount',
    },
    {
      header: 'TOTAL',
      accessorKey: 'sold',
      id: 'sold',
      AggregatedCell: ({ cell, table }) => (
        <>
          Total by {' '}
          {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header} :{' '}
          <Box
            sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
          >
            {' '} &nbsp;{cell.getValue()}
          </Box>
        </>
      ),
      Footer: () => (
        <Stack>
          Total :
          <Box color="warning.main">{totalvehicle}</Box>
        </Stack>
      ),
    }
  ];
  const totalvehicle = useMemo(
    () => {
      if (reportData && reportData.rows && Array.isArray(reportData.rows))
        return reportData.rows.reduce((acc, curr) => acc + curr.sold, 0)
      return 0
    },
    [reportData],
  );

  const table = useMaterialReactTable({
    columns,
    data: reportData && reportData.rows && Array.isArray(reportData.rows) ? reportData.rows : [],
    displayColumnDefOptions: {
      'mrt-row-expand': {
        Header: () => (
          <Stack direction="row" alignItems="center">
            <MRT_ExpandAllButton table={table} />
            <Box>Groups</Box>
          </Stack>
        ),
        GroupedCell: ({ row, table }) => {
          const { grouping } = table.getState();
          return row.getValue(grouping[grouping.length - 1]);
        },
        enableResizing: true,
        muiTableBodyCellProps: ({ row }) => ({
          sx: (theme) => ({
            color:
              row.depth === 0
                ? theme.palette.primary.main
                : row.depth === 1
                  ? theme.palette.secondary.main
                  : undefined,
          }),
        }),
        size: 200,
      },
    },
    muiSelectProps: {
      sx: {
        // Applies styles to rows per page dropdown
        backgroundColor: '#ffffff',
        border: '1px solid #dcdcdc',
        borderRadius: '5px',
        padding: '4px 8px',
        fontSize: '14px',
        color: '#333',
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)',
        },
        '&:active': {
          transform: 'translateY(2px)',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    enableGrouping: true,
    enableColumnResizing: true,
    columnFilterDisplayMode: 'popover',
    groupedColumnMode: 'reorder',
    enableColumnFilters: true,
    enableFilters: true,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    initialState: {
      density: 'compact',
      expanded: true,
      pagination: { pageIndex: 0, pageSize: 20 },
      sorting: [{ id: 'productName', desc: false }],
      grouping: ['branchName', 'productName'],
      // columnPinning: {
      //   left: ['branchName', 'productName'],
      //   right: ['sold'],
      // },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Tooltip title="View">
          <IconButton onClick={view.onTrue}>
            <Iconify icon="solar:eye-bold" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Download PDF File" arrow>
          <IconButton onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          } disabled={table.getPrePaginationRowModel().rows.length === 0}>
            <Iconify icon="vscode-icons:file-type-pdf2" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  });


  const debouncedQuery = useDebounce(filters);

  console.log(pivotData)

  const notFound = count === 0;

  const { page } = table;
  const limit = table.rowsPerPage;

  const getAll = useCallback(async () => {
    try {
      let URL = 'automobile/reports/sales';

      if (debouncedInfromation.t_type && debouncedInfromation.fdate && debouncedInfromation.tdate) {
        let t_type = '';
        if (debouncedInfromation.basedon === 1) {
          if (debouncedInfromation.t_type === 'c')
            t_type = 'c'
          if (debouncedInfromation.t_type === 'i')
            t_type = 'i'
        }
        if (debouncedInfromation.basedon === 2) {
          if (debouncedInfromation.t_type === 'c')
            t_type = 'cs'
          if (debouncedInfromation.t_type === 'i')
            t_type = 'is'
        }
        URL += `?page=${page + 1}&limit=${limit}&t_type=${t_type}&fdate=${moment(debouncedInfromation.fdate).format('YYYY-MM-DD 00:00:00')
          }&tdate=${moment(debouncedInfromation.tdate).format('YYYY-MM-DD 23:59:59')}&asc=id&wantbooking=false&isCashbook=true&reportType=matrix&`;
        if (Object.keys(debouncedQuery).length) {
          const nefil = { ...debouncedQuery };
          if (debouncedQuery.status === 'all') {
            delete nefil.status;
          }
          URL += queryString.stringify(nefil);
        }

        const { data } = await fetcher(URL);
        console.log(data)
        if (data) {
          setReportData(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [debouncedQuery, debouncedInfromation.basedon, debouncedInfromation.fdate, debouncedInfromation.t_type, debouncedInfromation.tdate, limit, page]);

  const handleDeleteRows = useCallback(
    async (id, confirm) => {
      try {
        let URL = 'automobile/reports/report/';
        if (id) {
          URL += id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
            refreshReports();
          }
          confirm.onFalse();
          getAll();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [enqueueSnackbar, getAll, refreshReports]
  );
  useEffect(() => {
    setPivotData(pre => ({ ...pre, t_type: information.t_type, basedon: information.basedon }))
  }, [information.t_type, information.basedon])
  useEffect(() => {
    getAll();
  }, [getAll, debouncedInfromation]);
  // useEffect(() => {
  //   console.log(debouncedInfromation.t_type)
  //   getAll();
  // }, [getAll, debouncedInfromation.t_type]);
  const downloadPdf = async () => {

  };
  const typeofstock = useMemo(() => [{ value: 'i', name: 'invoice' }, { value: 'c', name: 'challan' }], []);
  const throughstock = useMemo(() => [{ value: 1, name: 'SALES' }, { value: 2, name: 'STOCK' }], []);


  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      table.setIsFullScreen(!table.getState().isFullScreen);
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <Card sx={{ p: 0, mt: 1, zIndex: table.getState().isFullScreen ? 1111 : undefined, }} id="pdf-content">
        <Grid container spacing={5} sx={{ p: 5 }}>
          <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={2}>
            <Grid item sm={12} md={6} lg={1.2} xl={1.2}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={throughstock?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={
                  throughstock?.find((data) => data.value === information.basedon)
                    ?.name || ''
                }
                onChange={(event, newValue) => {
                  const label = throughstock?.find(
                    (data) => data.name === newValue
                  )?.value;
                  if (label) {
                    setData({ ...information, basedon: label });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} name="basedon" label="Type Of Stock" />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={1.2} xl={1.2}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={typeofstock?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={
                  typeofstock?.find((data) => data.value === information.t_type)
                    ?.name || ''
                }
                onChange={(event, newValue) => {
                  const label = typeofstock?.find(
                    (data) => data.name === newValue
                  )?.value;
                  if (label) {
                    setData({ ...information, t_type: label });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} name="t_type" label="Based On" />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={1.2} xl={1.2}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                options={report_periods?.map((option) => option.label)}
                getOptionLabel={(option) => option}
                value={
                  report_periods?.find((data) => data.value === information.period)?.label || ''
                }
                onChange={(event, newValue) => {
                  const label = report_periods?.find((data) => data.label === newValue)?.value;
                  if (label) {
                    setData({ ...information, period: label });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} name="bookId" label="Select Periods" />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={1.2} xl={1.2}>
              <DateField
                value={new Date(information.fdate)}
                onChange={(newValue) => {
                  setData({ ...information, fdate: newValue });
                }}
                format="dd/MM/yyyy"
                label="From Date"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                  },
                }}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={1.2} xl={1.2}>
              <DateField
                value={new Date(information.tdate)}
                onChange={(newValue) => {
                  setData({ ...information, tdate: newValue });
                }}
                format="dd/MM/yyyy"
                label="To Date"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                  },
                }}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={1.5} xl={1.5}>
              <ReportSearch
                query={search.query}
                results={search.results}
                onSearch={handleSearch}
                handleFilters={handleFilters}
                hrefItem={(id) => paths.dashboard.job.details(id)}
              />

            </Grid>
            <Grid item sm={12} md={6} lg={2} xl={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    onClick={() => setPivotData((prev) => ({ ...prev, grouping: !prev.grouping }))}
                    checked={pivotData.grouping}
                  />
                }
                label="Subtotal"
              />
              <Tooltip title="Download PDF File" arrow>
                <IconButton onClick={() =>
                  exportHTMLTableToPDF()
                } disabled={table.getPrePaginationRowModel().rows.length === 0}>
                  <Iconify icon="vscode-icons:file-type-pdf2" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Download Excel File" arrow>
                <IconButton onClick={() =>
                  exportExcel()
                } disabled={table.getPrePaginationRowModel().rows.length === 0}>
                  <Iconify icon="vscode-icons:file-type-excel2" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item sm={12} md={6} lg={1} xl={1}>
              <ReportFilters
                open={openFilters.value}
                onOpen={openFilters.onTrue}
                onClose={openFilters.onFalse}
                handleDeleteRows={handleDeleteRows}
                //
                data={reports.reports}
                filters={filters}
                onFilters={handleFilters}
                //
                canReset={canReset}
              />

            </Grid>
            <Grid item sm={12} md={6} lg={1} xl={1}>
              <CreateEdit users={users?.data?.data?.rows.filter(x => x.id !== user.id)} open={creatreport.value}
                onOpen={creatreport.onTrue}
                onClose={creatreport.onFalse}
                reportlist={reports.reports.filter(x => x.userId === user.id)}
                refreshReports={refreshReports}
                currentData={{
                  name: '',
                  reportcategoryId: null,
                  perrecuser: {},
                  reportpermissions: [{ perrecusId: null, edit: false, share: false }],
                  reportshortcutkey: { key: null }
                }} pivotData={pivotData} alreadyUsedKeyList={reports.allreadyusedkeys.filter(y => y !== currentReport?.reportshortcutkey?.key)} />
              {currentReport?.id && <CreateEdit users={users?.data?.data?.rows.filter(x => x.id !== user.id)} open={createedit.value}
                onOpen={createedit.onTrue}
                onClose={createedit.onFalse}
                reportlist={reports.reports.filter(x => x.userId === user.id)}
                currentData={currentReport} pivotData={pivotData} alreadyUsedKeyList={reports.allreadyusedkeys.filter(y => y !== currentReport?.reportshortcutkey?.key)} />}

            </Grid>
            <Grid item sm={12} md={6} lg={12} xl={12} sx={{ textAlign: 'center' }}>
              <h2>{currentReport?.name}</h2>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={2}>
            <Box >
              <PivotTableUI

                derivedAttributes={{}}
                data={reportData && reportData.rows && Array.isArray(reportData.rows) ? reportData.rows : []}
                onChange={s => {
                  console.log(s.data)
                  delete s.data;
                  setPivotData(pre => ({ ...pre, ...s, t_type: information.t_type, basedon: information.basedon }))
                }}
                unusedOrientationCutoff="Infinity"
                renderers={({ ...TableRenderers, ...PlotlyRenderers, customRenderer })}
                // {...reports.reports.findIndex(x => x.value === filters.experience) >= 0 ? reports.reports[reports.reports.findIndex(x => x.value === filters.experience)].pivotfilter : {}}
                {...pivotData}
                aggregators={{
                  ...PivotData.defaultProps.aggregators,
                  "Dynamic Multi-Field Sum": dynamicFieldSumAggregator(['qty', 'amount', 'otherField']),
                }}

              />
            </Box>
          </Grid>
        </Grid>
      </Card>
      {reportData.length === 0 && <NotFound name="Report" />}
    </Container >
  );
}

ListView.propTypes = {
  reportsDetails: PropTypes.object,
};
