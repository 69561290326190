import PropTypes from 'prop-types';
import {
  Backdrop,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';

export default function Importloaders({
  progress,
  open,
  onClose
}) {

  return (
    <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}>
      <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', height: '30vh', width: '30%' }}>
        {
          progress?.infinit ? <CircularProgress color="primary" style={{ height: 'auto', width: '100%' }} /> : <>
            <CircularProgress color="primary" variant="determinate" style={{ height: 'auto', width: '100%' }} value={(progress.current / progress.total) * 100} />
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="h1"
                component="div"
                color="white"
              >{progress.status !== "running" ? progress.current : `${Math.round((progress.current / progress.total) * 100)}%`}</Typography>
            </Box>
          </>
        }

      </Box>
    </Backdrop>
  );
}

Importloaders.propTypes = {
  progress: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
