import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { create, fetcher, update } from 'src/utils/axios';
import { KEY, TITLES } from 'src/_mock/constant';
import { useBoolean } from 'src/hooks/use-boolean';
import {
  Autocomplete,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { useEventListener } from 'src/hooks/use-event-listener';
import { Stack } from '@mui/system';
import { useBgColorAutocomplete } from 'src/_mock/constant_funcation';

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();
  const password = useBoolean();
  const [loading, setLoading] = useState(false);

  const bgColorAutocomplete = useBgColorAutocomplete();

  const [departmentData, setDepartmentData] = useState([]);
  const [rolesData, setRolesData] = useState([]);

  const userSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    title: Yup.string().required('Required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    mobile: Yup.string()
      .required('Mobile number is required')
      .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  const values = useMemo(
    () => ({
      name: row?.name || '',
      isHighligh: '',
      title: row?.title || '',
      email: row?.email || '',
      mobile: row?.mobile || '',
      roleId: row?.roleId || '',
      departmentId: row?.departmentId || '',
      password: row?.password || '',
      confirmPassword: row?.confirmPassword || '',
      status: row?.status || false,
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(userSchema),
    values,
  });

  const {
    reset,
    handleSubmit,
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const onSubmit = handleSubmit(async (payload) => {
    setLoading(true);
    try {
      let URL = 'users/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
      setLoading(false);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const departmentList = async () => {
    let URL = 'departments/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setDepartmentData(
      data?.rows.map((x) => {
        x.name = x.business?.name ? `${x.name} - ${x.business?.name}` : x.name;
        return x;
      })
    );
  };

  const rolesList = async () => {
    let URL = 'roles/';
    URL += `?page=${1}&limit=${10000}&status=true`;
    const { data } = await fetcher(URL);
    setRolesData(data?.rows);
  };

  useEffect(() => {
    if (open) {
      departmentList();
      rolesList();
    }
  }, [open]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            top: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Toolbar>
            <Typography variant="h5" sx={{ flex: 1 }}>
              {row ? 'Update User' : 'Create User'}
            </Typography>

            <IconButton color="inherit" edge="start" onClick={onClose}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>

        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={2} sx={{ p: 3 }}>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
            <Autocomplete
              autoHighlight={allValues.isHighligh}
              fullWidth
              freeSolo
              size="small"
              openOnFocus
              onInputChange={(event, newValue) => {
                setValue('isHighligh', newValue);
              }}
              onBlur={() => {
                setValue('isHighligh', '');
              }}
              options={TITLES?.map((option) => option.name)}
              getOptionLabel={(option) => option}
              value={TITLES?.find((data) => data.id === allValues.title)?.name || ''}
              onChange={(event, newValue) => {
                setValue('title', TITLES?.find((data) => data.name === newValue)?.id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="title"
                  autoFocus
                  label={
                    <span>
                      Title <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  error={errors?.title && !allValues?.title}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
              ListboxProps={{ ...bgColorAutocomplete }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10} xl={10} xxl={10}>
            <RHFTextField
              name="name"
              label={
                <span>
                  Full Name <span style={{ color: 'red' }}>*</span>
                </span>
              }
              size="small"
              error={errors?.name && !allValues?.name}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <RHFTextField
              name="email"
              label={
                <span>
                  Email <span style={{ color: 'red' }}>*</span>
                </span>
              }
              size="small"
              error={errors?.email && !allValues?.email}
              isCamalCase="true"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <RHFTextField
              name="password"
              label={
                <span>
                  Password <span style={{ color: 'red' }}>*</span>
                </span>
              }
              type={password.value ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={password.onToggle} edge="end" tabIndex={-1}>
                      <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              size="small"
              error={errors?.password && !allValues?.password}
              isCamalCase="true"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <RHFTextField
              name="mobile"
              type="number"
              label={
                <span>
                  Mobile No. <span style={{ color: 'red' }}>*</span>
                </span>
              }
              size="small"
              onChange={(e) => {
                const { value } = e.target;
                if (value.length <= 10) setValue('mobile', e.target.value);
              }}
              value={allValues?.mobile || ''}
              error={errors?.mobile && !allValues?.mobile && allValues?.mobile?.length !== 10}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Autocomplete
              autoHighlight={allValues.isHighligh}
              fullWidth
              freeSolo
              size="small"
              openOnFocus
              onInputChange={(event, newValue) => {
                setValue('isHighligh', newValue);
              }}
              onBlur={() => {
                setValue('isHighligh', '');
              }}
              options={departmentData?.map((option) => option.name)}
              getOptionLabel={(option) => option}
              value={departmentData?.find((data) => data.id === allValues.departmentId)?.name || ''}
              onChange={(event, newValue) => {
                setValue(
                  'departmentId',
                  departmentData?.find((data) => data.name === newValue)?.id
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="departmentId"
                  label="Select Department"
                  error={errors?.departmentId && !allValues?.departmentId}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
              ListboxProps={{ ...bgColorAutocomplete }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Autocomplete
              autoHighlight={allValues.isHighligh}
              fullWidth
              freeSolo
              size="small"
              openOnFocus
              onInputChange={(event, newValue) => {
                setValue('isHighligh', newValue);
              }}
              onBlur={() => {
                setValue('isHighligh', '');
              }}
              options={rolesData?.map((option) => option.name)}
              getOptionLabel={(option) => option}
              value={rolesData?.find((data) => data.id === allValues.roleId)?.name || ''}
              onChange={(event, newValue) => {
                setValue('roleId', rolesData?.find((data) => data.name === newValue)?.id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="roleId"
                  label="Select Role"
                  error={errors?.roleId && !allValues?.roleId}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
              ListboxProps={{ ...bgColorAutocomplete }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <RHFCheckbox name="status" label="Active" size="small" />
          </Grid>
        </Grid>

        <Stack
          direction="row-reverse"
          alignItems="center"
          sx={{
            bottom: 0,
            zIndex: 111111,
            p: 3,
            position: 'sticky',
            width: '100%',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
          id="modelfooter"
        >
          <Tooltip title="(Alt + A)" arrow>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={async () => {
                if (await trigger()) {
                  onSubmit();
                }
              }}
            >
              {row ? 'Update' : 'Add'}
            </LoadingButton>
          </Tooltip>

          <Tooltip title="(Esc)" arrow>
            <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
          </Tooltip>

          <Button
            variant="soft"
            onClick={() => {
              reset(values);
            }}
            sx={{ mr: 2 }}
          >
            Reset
          </Button>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
