import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import {
  Autocomplete,
  Box,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
  alpha,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useBgColorAutocomplete } from 'src/_mock/constant_funcation';
import { KEY } from 'src/_mock/constant';
import { useEventListener } from 'src/hooks/use-event-listener';

export default function GroupCompanyYear({
  open,
  onClose,
  user,
  company,
  setCompany,
  branch,
  setBranch,
  onOk,
  setGId,
}) {
  const [isgroup, setIsGroup] = useState(user?.groupId);

  const [groupList, setGroupList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [companybranchList, setCompanyBranchList] = useState([]);

  const bgColorAutocomplete = useBgColorAutocomplete(); // Use the hook here

  useEffect(() => {
    if (user?.gr_pers?.length > 0) {
      setGroupList([
        { id: null, name: 'All' },
        ...user.gr_pers
          .filter((x) => user?.companies.find((y) => y.groupId === x.groupId))
          .map((x) => x.group),
      ]);
    }
    if (user?.companies?.length > 0) {
      setCompanyList([{ id: null, name: 'All' }, ...user.companies.map((x) => x)]);
    }
  }, [user.gr_pers, user.companies]);

  useEffect(() => {
    if (isgroup) {
      setCompanyList([
        { id: null, name: 'All' },
        ...user.companies.filter((x) => x.groupId === isgroup),
      ]);
    }
  }, [isgroup, user?.companies]);
  useEffect(() => {
    if (user.branches)
      setCompanyBranchList([
        { id: null, name: 'All' },
        ...user.branches.filter((x) => x.companyId === company),
      ]);
  }, [company, user?.branches]);

  const handleKeyDown = (event) => {
    if (event.key?.toLowerCase() === KEY.YES) {
      onOk();
    }
  };
  useEventListener('keydown', handleKeyDown);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      PaperProps={{
        sx: { maxWidth: 1024 },
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          top: 0,
          zIndex: 9,
          position: 'sticky',
          bgcolor: (theme) => alpha(theme.palette.background.default, 1),
        }}
      >
        <Toolbar>
          <Typography variant="h5" sx={{ flex: 1 }}>
            Switch Comapny
          </Typography>

          {((user?.companyId !== null && user?.roleId > 1) || company !== undefined) && (
            <IconButton color="inherit" edge="start" onClick={onClose} tabIndex="-1">
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          )}
        </Toolbar>
        <Divider sx={{ borderStyle: 'dashed' }} />
      </Grid>

      <DialogContent>
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          pt={3}
          pb={3}
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          {groupList.length > 1 && (
            <Autocomplete
              autoHighlight
              fullWidth
              freeSolo
              size="small"
              options={groupList?.map((option) => option.name)}
              getOptionLabel={(option) => option}
              value={groupList?.find((data) => data.id === isgroup)?.name || ''}
              onChange={(event, newValue) => {
                setIsGroup(groupList?.find((data) => data.name === newValue)?.id);
                setGId(groupList?.find((data) => data.name === newValue)?.id);
              }}
              renderInput={(params) => <TextField {...params} name="group" label="Select Group" />}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
              openOnFocus
              ListboxProps={{ ...bgColorAutocomplete }}
            />
          )}

          {companyList.length > 1 && (
            <Autocomplete
              autoHighlight
              fullWidth
              freeSolo
              size="small"
              options={companyList?.map((option) => option.name)}
              getOptionLabel={(option) => option}
              value={companyList?.find((data) => data.id === company)?.name || ''}
              onChange={(event, newValue) => {
                setCompany(companyList?.find((data) => data.name === newValue)?.id);
              }}
              renderInput={(params) => (
                <TextField {...params} name="company" label="Select Company" />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
              openOnFocus
              ListboxProps={{ ...bgColorAutocomplete }}
            />
          )}
          {companybranchList.length > 1 && (
            <Autocomplete
              autoHighlight
              fullWidth
              freeSolo
              size="small"
              options={companybranchList?.map((option) => option.name)}
              getOptionLabel={(option) => option}
              value={companybranchList?.find((data) => data.id === branch)?.name || ''}
              onChange={(event, newValue) => {
                setBranch(companybranchList?.find((data) => data.name === newValue)?.id);
              }}
              renderInput={(params) => (
                <TextField {...params} name="branchId" label="Select Branch" />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
              openOnFocus
              ListboxProps={{ ...bgColorAutocomplete }}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          bottom: 0,
          zIndex: 9,
          position: 'sticky',
          bgcolor: (theme) => alpha(theme.palette.background.default, 1),
        }}
      >
        <LoadingButton variant="contained" onClick={onOk}>
          ok
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

GroupCompanyYear.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onOk: PropTypes.any,
  user: PropTypes.object,
  company: PropTypes.number,
  setCompany: PropTypes.func,
  branch: PropTypes.number,
  setBranch: PropTypes.func,
  setGId: PropTypes.any,
};
