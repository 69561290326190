import { Helmet } from 'react-helmet-async';
import { COMPANY_NAME } from 'src/_mock/constant';
// sections
import { ListView } from 'src/sections/work_shop/reports/general-printing/job-card-printing/view';

// ----------------------------------------------------------------------

export default function JobCardPrintingListPage() {
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} : Job Card Printing List</title>
      </Helmet>

      <ListView />
    </>
  );
}
