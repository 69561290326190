// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// components
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/auth/context/jwt';
import { useBoolean } from 'src/hooks/use-boolean';
import Iconify from 'src/components/iconify';
import { HOST_API } from 'src/config-global';
import GroupCompanyYear from './group-company-year';

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const { user, changeAdvance } = useContext(AuthContext);

  const [company, setCompany] = useState(
    user.companyId || null
  );
  const [branch, setBranch] = useState(
    user.branchId || null
  );
  const [gId, setGId] = useState(user?.groupId);
  const quickEdit = useBoolean();

  useEffect(() => {
    if (user?.co_per?.length === 1) {
      quickEdit.onTrue();
    }
  }, [user, quickEdit]);

  return (
    <>
      <Stack
        sx={{
          px: 2,
          py: 5,
          textAlign: 'center',
        }}
      >
        <Stack alignItems="center">
          <Box sx={{ position: 'relative' }}>
            <Avatar
              src={user?.photo ? `${HOST_API}uploads/medium/${user?.photo}` : user?.photoURL}
              alt={user?.displayName}
              sx={{
                width: 36,
                height: 36,
                border: (theme) => `solid 2px ${theme.palette.background.default}`,
              }}
            >
              {user?.name ? user?.name.charAt(0).toUpperCase() : 'K'}
            </Avatar>
          </Box>

          <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
            <Typography variant="subtitle2" noWrap>
              {user?.name ? user?.name : 'None'}
            </Typography>

            <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
              {user?.userName ? user?.userName : 'None'}
            </Typography>
          </Stack>

          <Button
            variant="contained"
            rel="noopener"
            onClick={() => {
              if (user.roleId < 3 || user?.companies.length > 1) quickEdit.onTrue();
            }}
            sx={{ bgcolor: (theme) => theme.palette.primary.main }}
          >
            {user?.companies.find((x) => x.id === user.companyId)?.name
              ? user?.companies.find((x) => x.id === user.companyId)?.name
              : 'Select Company'}
            <Iconify icon="ic:round-change-circle" sx={{ borderRadius: 0.65, width: 28 }} />
          </Button>
        </Stack>
      </Stack>
      <GroupCompanyYear
        open={quickEdit.value}
        setGId={setGId}
        onClose={() => {
          quickEdit.onFalse();
        }}
        onOk={() => {
          if (company !== user?.companyId) {
            changeAdvance({ companyId: company, groupId: gId, co_yearId: null, branchId: branch });
          } else {
            changeAdvance({ companyId: company, groupId: gId, branchId: branch });
          }
          quickEdit.onFalse();
        }}
        user={user}
        company={company}
        setCompany={setCompany}
        branch={branch}
        setBranch={setBranch}
      />
    </>
  );
}
