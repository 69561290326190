import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
//
import App from './App';
import './index.css';

// const notIncludesIds = ["sidebar-btn"];

const notIncludesIds = ["sidebar-btn"];
// document.addEventListener('keydown', (event) => {
//   // Check if the pressed key is Enter
//   if (event.key === 'Enter') {
//     event.preventDefault(); // Prevent the default Enter key behavior

//     // Get the nearest parent form of the currently focused element
//     const form = event.target.closest('form');

//     // Proceed only if the event target is within a form
//     if (form) {
//       // Get all focusable elements inside this form
//       const focusableElements = Array.from(
//         form.querySelectorAll('input, select, textarea, button, a[href]')
//       ).filter((el) => !el.disabled && el.tabIndex >= 0);

//       const currentElement = event.target;

//       const currentIndex = focusableElements.indexOf(currentElement);
//       const nextIndex = (currentIndex + 1) % focusableElements.length; // Loop back to first element if at the end

//       const nextElement = focusableElements[nextIndex];
//       const currentElements = focusableElements[currentIndex];

//       if (currentElements?.type === 'button' && !currentElements?.ariaLabel?.includes('date') && !notIncludesIds.includes(currentElement?.id)) {
//         currentElements.click();
//       } else {
//         nextElement.focus(); // Set focus to the next element
//       }
//     }
//   }
// });
document.addEventListener('keydown', (event) => {
  if (event.key === 'Enter') {
    const target = event.target;

    // Exclude specific elements where Enter key should retain its default behavior
    if (
      target.tagName !== 'TEXTAREA' &&
      target.tagName !== 'BUTTON' &&
      !target.isContentEditable
    ) {
      event.preventDefault();

      // Get all focusable elements in the document
      const focusableElements = Array.from(
        document.querySelectorAll(
          'a[href], button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])'
        )
      ).filter(
        (el) =>
          !el.disabled &&
          el.offsetParent !== null &&
          getComputedStyle(el).visibility !== 'hidden'
      );

      const currentIndex = focusableElements.indexOf(target);
      let nextIndex = currentIndex + 1;

      // Loop back to the first element if at the end
      if (nextIndex >= focusableElements.length) {
        nextIndex = 0;
      }

      const nextElement = focusableElements[nextIndex];
      if (nextElement) {
        nextElement.focus();
      }
    }
  }
});


// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <HashRouter>
      <Suspense>
        <App />
      </Suspense>
    </HashRouter>
  </HelmetProvider>
);
