import { Helmet } from 'react-helmet-async';
import { COMPANY_NAME } from 'src/_mock/constant';
// sections
import { ListView } from 'src/sections/work_shop/reports/general-printing/challan-printing-accessories/view';

// ----------------------------------------------------------------------

export default function ChallanPrintingAListPage() {
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} : Challan Printing (Accessories) List</title>
      </Helmet>

      <ListView />
    </>
  );
}
