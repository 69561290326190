import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { create, update } from 'src/utils/axios';
import {
  DataGrid,
  GridActionsCellItem,
  GridAddIcon,
  GridCellModes,
  GridRowEditStopReasons,
  GridRowModes,
  GridSaveAltIcon,
} from '@mui/x-data-grid';
import { DOWNTIMEYN, JOBSTATUS, JOBTEAYNO, KEY } from 'src/_mock/constant';
import { Autocomplete, Divider, Grid, IconButton, TextField, Toolbar, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { alpha } from '@mui/system';
import { DatePicker, DateTimeField, DateTimePicker } from '@mui/x-date-pickers';
import { useEventListener } from 'src/hooks/use-event-listener';
import createData from 'src/sections/_examples/mui/table-view/utils';
import { assignNullToEmptyOrUndefinedValues } from 'src/_mock/constant_funcation';
// ----------------------------------------------------------------------

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData, jbcstatuses, serviceTypesData, supervisorList, mechanicList, yearData, rempList, finalIList }) {
  const { enqueueSnackbar } = useSnackbar();
  console.log(row)
  // Regular expression for Indian vehicle registration number validation
  const jobCardSchema = Yup.object().shape({
    identity: Yup.string().required('Job Card No is required'),
  });
  const values = useMemo(
    () => ({

      co_yearId: row?.co_yearId,
      jobcardstatusId: row?.jobcardstatusId,
      identity: row?.identity || '',
      jbdt: row?.jbdt || new Date(),
      deliverydate: row?.deliverydate || new Date(),
      productId: row?.productId || null,
      product: row?.product || '',
      servicetypeId: row?.servicetypeId || '',
      sourcetypeId: row?.sourcetypeId || '',
      supervisorId: row?.supervisorId || null,
      mechanicId: row?.mechanicId || null,
      jobcomlinks:
        row?.jobcomlinks?.map((x) => ({
          complaintcodeId: x?.complaintcodeId,
          description: x?.description,
        })) || []
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(jobCardSchema),
    values,
  });

  const {
    reset,
    control,
    trigger,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting, errors },
  } = methods;
  const onSubmit = handleSubmit(async (payload) => {
    try {
      assignNullToEmptyOrUndefinedValues(payload);
      let URL = '/automobile/jobcards/';
      console.log(payload)
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });
  const allValues = watch();
  useEffect(() => {
    reset();
  }, [open, reset]);


  const { fields, append, remove } = useFieldArray({
    control,
    name: 'company_banks',
  });

  const handleAdd = () => {
    append({
      name: '',
      branch_name: '',
      ifsc_code: '',
      iban_no: '',
      swift_code: '',
      upi_code: '',
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };
  useEventListener('keydown', handleKeyDown);

  const [cellModesModel, setCellModesModel] = useState({});

  const handleCellClick = useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (!event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => ({
      // Revert the mode of the other cells from other rows
      ...Object.keys(prevModel).reduce(
        (acc, id) => ({
          ...acc,
          [id]: Object.keys(prevModel[id]).reduce(
            (acc2, field) => ({
              ...acc2,
              [field]: { mode: GridCellModes.View },
            }),
            {}
          ),
        }),
        {}
      ),
      [params.id]: {
        // Revert the mode of other cells in the same row
        ...Object.keys(prevModel[params.id] || {}).reduce(
          (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
          {}
        ),
        [params.field]: { mode: GridCellModes.Edit },
      },
    }));
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  function ValueParserSetterGrid() {
    return (
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={defaultRows}
          columns={columns}
          rowModesModel={rowModesModel}
          // onRowModesModelChange={handleRowModesModelChange}
          // onRowEditStop={handleRowEditStop}
          // processRowUpdate={processRowUpdate}
          rowHeight={40}
          // slots={{
          //   toolbar: EditToolbar,
          // }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          hideFooterPagination
          hideFooterSelectedRowCount
          disableRowSelectionOnClick
        />
      </div>
    );
  }

  const defaultRows = [
    { id: 1, productName: 'Snow', quantity: 10, rate: 1000, amount: 20000 },
    { id: 2, productName: 'Lannister', quantity: 20, rate: 1000, amount: 20000 },
    { id: 3, productName: 'Lannister', quantity: 10, rate: 1000, amount: 20000 },
    { id: 4, productName: 'Stark', quantity: 100, rate: 1000, amount: 20000 },
    { id: 5, productName: 'Targaryen', quantity: 2000, rate: 1000, amount: 20000 },
  ];

  const [setRows] = useState(defaultRows);
  const [rowModesModel, setRowModesModel] = useState({});
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleDeleteClick = (id) => () => {
    setRows(defaultRows.filter((row) => row.id !== id));
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow = defaultRows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(defaultRows.filter((row) => row.id !== id));
    }
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: 'productName', headerName: 'Product Name', width: 750, editable: true },
    { field: 'quantity', headerName: 'Quantity', width: 120, editable: true },
    {
      field: 'rate',
      headerName: 'Rate',
      width: 120,
      editable: true,
    },
    { field: 'amount', headerName: 'Amount', width: 120, editable: true },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 120,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<GridSaveAltIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<GridSaveAltIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<GridAddIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<GridSaveAltIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const rows = [createData(1, '43546', 'abckjasncb')];

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 1100 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                Job Ready
              </Typography>

              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>

            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          <Grid item container xs={12} md={12} sx={{ p: 3 }} spacing={2}>

            <Grid item xs={12} sm={4} md={2} lg={2} xl={2} xxl={2}>
              <RHFSelect name="co_yearId" label="Job Year" size="small" defaultValue={row.co_yearId}>
                {yearData.map((status) => {
                  const startDate = new Date(status.sdate);
                  const endDate = new Date(status.edate);
                  return (
                    <MenuItem key={status.id} value={status.id}>
                      {`${startDate.getFullYear()}-${endDate.getFullYear()}`}
                    </MenuItem>
                  )
                })}
              </RHFSelect>
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={2} xl={2} xxl={2}>
              <RHFTextField
                name="identity"
                label="Job Card No."
                size="small"
                value={row.identity}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={2} xl={2} xxl={2}>
              <DatePicker
                label="job Card Date"
                value={new Date()}
                // onChange={handleFilterStartDate}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: 'small',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={3} xl={3} xxl={3}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                openOnFocus
                options={mechanicList?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={
                  mechanicList?.find((data) => data.id === row.mechanicId)?.name ||
                  ''
                }
                onChange={(event, newValue) => {
                  setValue(
                    'mechanicId',
                    mechanicList?.find((data) => data.name === newValue)?.id
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="mechanicId"
                    label="Mechanic"
                    error={!row?.mechanicId}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3} xl={3} xxl={3}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                openOnFocus
                options={supervisorList?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={
                  supervisorList?.find((data) => data.id === row.supervisorId)
                    ?.name || ''
                }
                onChange={(event, newValue) => {
                  setValue(
                    'supervisorId',
                    supervisorList?.find((data) => data.name === newValue)?.id
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="supervisorId"
                    label="Supervisor"
                    error={!row?.supervisorId}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={6} lg={6} xl={6} xxl={6}>
              <RHFTextField name="product" label="Model Code" size="small" value={row?.product?.name || ''} />
            </Grid>

            <Grid item xs={12} sm={4} md={6} lg={6} xl={6} xxl={6}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                openOnFocus
                options={serviceTypesData?.map((option) => option?.name || '')}
                getOptionLabel={(option) => option}
                value={
                  serviceTypesData?.find((data) => data.id === row.servicetypeId)?.name || ''
                }
                onChange={(event, newValue) => {
                  setValue(
                    'servicetypeId',
                    serviceTypesData?.find((data) => data.name === newValue)?.id
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="servicetypeId"
                    label="Service Type"
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
                componentsProps={{
                  paper: {
                    sx: {
                      width: 300,
                    },
                  },
                }}
              />
            </Grid>




            <Grid item xs={12} sm={4} md={3} lg={3} xl={3} xxl={3}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                openOnFocus
                options={rempList?.map((option) => option?.name || '')}
                getOptionLabel={(option) => option}
                value={
                  rempList?.find((data) => data.id === row.rampno)?.name || ''
                }
                onChange={(event, newValue) => {
                  setValue(
                    'rampno',
                    rempList?.find((data) => data.name === newValue)?.id
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="rampno"
                    label="RampNo"
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
                componentsProps={{
                  paper: {
                    sx: {
                      width: 300,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3} xl={3} xxl={3}>
              <Autocomplete
                autoHighlight
                fullWidth
                freeSolo
                size="small"
                openOnFocus
                options={finalIList?.map((option) => option.name)}
                getOptionLabel={(option) => option}
                value={
                  finalIList?.find((data) => data.id === row.finalinspectionId)
                    ?.name || ''
                }
                onChange={(event, newValue) => {
                  setValue(
                    'finalinspectionId',
                    finalIList?.find((data) => data.name === newValue)?.id
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="finalinspectionId"
                    label="Final Inspection"
                    error={!row?.finalinspectionId}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={2} lg={2} xl={2} xxl={2}>
              <Controller
                name="jbdt"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DateTimeField
                    value={field.value ? new Date(field.value) : null}
                    timezone="Asia/Kolkata"
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    format="dd/MM/yyyy hh:mm"
                    label="Start Date"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                        size: 'small',
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={2} xl={2} xxl={2}>
              <Controller
                name="deliverydate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DateTimeField
                    value={field.value ? new Date(field.value) : null}
                    timezone="Asia/Kolkata"
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    format="dd/MM/yyyy hh:mm"
                    label="Out Date"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                        size: 'small',
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={2} xl={2} xxl={2}>
              <RHFSelect name="jobcardstatusId" label="Job Status" size="small" defaultValue={row.jobcardstatusId}>
                {jbcstatuses.map((status) => (
                  <MenuItem key={status.id} value={status.id}>
                    {status.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            {/* <Grid item xs={12} sm={4} md={3} lg={3} xl={3} xxl={3}>
              <RHFSelect name="downtimeyn" label="Down Time Y/N" size="small">
                {DOWNTIMEYN.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid> */}
          </Grid>
        </Grid>
        <DialogActions
          sx={{
            bottom: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {row ? 'Update' : 'Add'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>

    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
  jbcstatuses: PropTypes.array,
  serviceTypesData: PropTypes.array,
  supervisorList: PropTypes.array,
  mechanicList: PropTypes.array,
  yearData: PropTypes.array,
  rempList: PropTypes.array,
  finalIList: PropTypes.array,
};
