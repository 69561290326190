import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { create, update } from 'src/utils/axios';
import { KEY, ORDER_TYPE, SERVICE_TYPE } from 'src/_mock/constant';
import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Toolbar,
  Typography,
  CircularProgress,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { Box, alpha, spacing } from '@mui/system';
import { useEventListener } from 'src/hooks/use-event-listener';
import createData from 'src/sections/_examples/mui/table-view/utils';
import { GroupFooter, GroupHeader, GroupItems } from 'src/_mock/constant_style';
import { isNaN } from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { CheckBox } from '@mui/icons-material';
// ----------------------------------------------------------------------

export default function UserQuickEditForm({ row, open, onClose, table, getAll, tableData }) {
  const { enqueueSnackbar } = useSnackbar();

  const [openModelName, setOpenModelName] = useState('');

  const dialog = useBoolean();

  const departmentSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });
  const values = useMemo(
    () => ({
      name: row?.name || '',
      status: row?.status || false,
      description: row?.description || '',
      balance_method: row?.balance_method || 'Bill To Bill',
      job_card_parts: [
        {
          id: null,
          firstName: false,
          lastName: ORDER_TYPE.find((data) => data.id === row?.lastName)?.id || 'null',
          email: null,
          state: ORDER_TYPE.find((data) => data.id === row?.state)?.id || 'null',
        },
      ],
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(departmentSchema),
    values,
  });

  const {
    watch,
    control,
    trigger,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const job_card_parts = useFieldArray({
    control,
    name: 'job_card_parts',
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const onSubmit = handleSubmit(async (payload) => {
    try {
      let URL = 'roles/';
      if (row?.id) {
        URL += row.id;
        const { success, show } = await update({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Update success!');
          }
        }
      } else {
        const { success, show } = await create({ url: URL, payload });
        if (success) {
          getAll();
          onClose();
          if (show) {
            enqueueSnackbar('Create success!');
          }
        }
      }
      reset();
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      console.error(error);
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'company_banks',
  });

  const PerformedJobsSchema = Yup.object().shape({
    inv_details: Yup.array().of(
      Yup.object().shape({
        productId: Yup.number().nullable().required('Product is required'),
        qty: Yup.number()
          .nullable()
          .min(0)
          .required('Qty is required')
          .transform((value) => (isNaN(value) ? null : value)),
      })
    ),
  });

  const allValues = watch();
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [productList, setProductList] = useState([]);
  const [productEditValues, setProductEditValues] = useState({});
  const quickEditProduct = useBoolean();
  const confirm = useBoolean();

  const handleKeyDown = (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    }
  };
  useEventListener('keydown', handleKeyDown);

  const fakeData = [
    {
      id: '1',
      firstName: '',
      lastName: '',
      email: '',
      state: '',
    },
  ];

  // 50 us states array
  const usStates = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
    'Puerto Rico',
  ];

  const Example = () => {
    const [validationErrors, setValidationErrors] = useState({});
    const [productEditValues, setproductEditValues] = useState({});

    // export type User = {
    //   id: string;
    //   firstName: string;
    //   lastName: string;
    //   email: string;
    //   state: string;
    // };

    const columns = useMemo(
      () => [
        {
          accessorKey: 'id',
          header: 'Id',
          enableEditing: false,
          size: 80,
        },
        {
          accessorKey: 'firstName',
          header: 'First Name',
          Edit: ({ row }) => (
            // <CheckBox
            //   checked={false}
            //   // onChange={() => field.onChange(getSelected(field.value, option.value))}
            // />
            <RHFCheckbox
              name="firstName"
              value={allValues.job_card_parts[row.index].firstName}
              onChange={(event) => {
                // setValue(`job_card_parts[${row.index}].firstName`, e.target.checked);
                setproductEditValues({
                  ...productEditValues,
                  [row.id]: { ...row.original, state: event.target.checked },
                });
              }}
              label="Repeat Complaint Flag"
              size="small"
            />
          ),
          // muiEditTextFieldProps: ({ cell, row }) => ({
          //   type: 'text',
          //   required: true,
          //   error: !!validationErrors?.[cell.id],
          //   helperText: validationErrors?.[cell.id],
          //   // store edited user in state to be saved later
          //   onBlur: (event) => {
          //     const validationError = !validateRequired(event.currentTarget.value)
          //       ? 'Required'
          //       : undefined;
          //     setValidationErrors({
          //       ...validationErrors,
          //       [cell.id]: validationError,
          //     });
          //     setEditedUsers({ ...editedUsers, [row.id]: row.original });
          //   },
          // }),
          size: 250,
        },
        {
          accessorKey: 'lastName',
          header: 'Last Name',
          Edit: ({ row }) => (
            <Autocomplete
              autoHighlight
              key={`${row.id}-${row.index}`}
              onFocus={(e) => {
                setCurrentRowIndex(row.index);
              }}
              onBlur={(e) => {
                setCurrentRowIndex(null);
              }}
              fullWidth
              freeSolo
              size="small"
              disableCloseOnSelect
              options={ORDER_TYPE}
              getOptionLabel={(option) => option.name}
              value={
                ORDER_TYPE.find(
                  (data) => data.id === allValues.job_card_parts[row.index].lastName
                ) || ''
              }
              onChange={async (event, newValue) => {
                setProductEditValues(ORDER_TYPE?.find((data) => data?.name === newValue?.name));
                if (await trigger('in_type')) {
                  setValue(
                    `job_card_parts[${row.index}].lastName`,
                    ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                  );
                }
              }}
              groupBy={(option) => option?.a}
              renderInput={(params) => (
                <TextField {...params} name="lastName" className="autoComplete" />
              )}
              // disabled={!noch(allValues.in_type)}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader component="li">
                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                  </GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                  <GroupFooter component="li">
                    <Button
                      onClick={() => {
                        quickEditProduct.onTrue();
                      }}
                    >
                      Add
                    </Button>

                    {productEditValues?.id && (
                      <>
                        <Button
                          onClick={() => {
                            quickEditProduct.onTrue();
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={() => {
                            confirm.onTrue();
                          }}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </GroupFooter>
                </li>
              )}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                    {option?.name}
                  </ListItem>
                </Box>
              )}
            />
          ),

          size: 300,
        },
        {
          accessorKey: 'email',
          header: 'Email',
          Edit: ({ row }) => (
            <RHFTextField
              onFocus={(e) => {
                setCurrentRowIndex(row.index);
              }}
              onBlur={(e) => {
                setCurrentRowIndex(null);
              }}
              // textDirection="rtl"
              name="Email"
              size="small"
              // type="number"
              value={allValues.job_card_parts[row.index]?.email}
              onChange={(event) => {
                setValue(`job_card_parts[${row.index}].email`, event.target.value);
                // const qty = e.target.value >= 0 && e.target.value;
                // setValue(`email`, qty);
              }}
            />
          ),

          size: 300,
        },
        {
          accessorKey: 'state',
          header: 'State',
          editVariant: 'select',
          editSelectOptions: usStates,
          Edit: ({ row }) => (
            <Autocomplete
              autoHighlight
              key={`${row.id}-${row.index}`}
              onFocus={(e) => {
                setCurrentRowIndex(row.index);
              }}
              onBlur={(e) => {
                setCurrentRowIndex(null);
              }}
              fullWidth
              freeSolo
              size="small"
              disableCloseOnSelect
              options={ORDER_TYPE}
              getOptionLabel={(option) => option.name}
              value={
                ORDER_TYPE.find((data) => data.id === allValues.job_card_parts[row.index].state) ||
                ''
              }
              onChange={async (event, newValue) => {
                setProductEditValues(ORDER_TYPE?.find((data) => data?.name === newValue?.name));
                if (await trigger('in_type')) {
                  setValue(
                    `job_card_parts[${row.index}].state`,
                    ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                  );
                }
              }}
              groupBy={(option) => option?.a}
              renderInput={(params) => (
                <TextField {...params} name="state" className="autoComplete" />
              )}
              // disabled={!noch(allValues.in_type)}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader component="li">
                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                  </GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                  <GroupFooter component="li">
                    <Button
                      onClick={() => {
                        quickEditProduct.onTrue();
                      }}
                    >
                      Add
                    </Button>

                    {productEditValues?.id && (
                      <>
                        <Button
                          onClick={() => {
                            quickEditProduct.onTrue();
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={() => {
                            confirm.onTrue();
                          }}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </GroupFooter>
                </li>
              )}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                    {option?.name}
                  </ListItem>
                </Box>
              )}
            />
          ),
          size: 300,
        },
      ],
      [productEditValues]
    );

    // call CREATE hook
    // const { mutateAsync: createUser, isPending: isCreatingUser } = useCreateUser();
    // call READ hook
    // const {
    //   data: fetchedUsers = [],
    //   isError: isLoadingUsersError,
    //   isFetching: isFetchingUsers,
    //   isLoading: isLoadingUsers,
    // } = useGetUsers();
    // call UPDATE hook
    // const { mutateAsync: updateUsers, isPending: isUpdatingUsers } = useUpdateUsers();
    // call DELETE hook
    // const { mutateAsync: deleteUser, isPending: isDeletingUser } = useDeleteUser();

    // CREATE action
    // const handleCreateUser = async ({ values, table }) => {
    //   const newValidationErrors = validateUser(values);
    //   if (Object.values(newValidationErrors).some((error) => error)) {
    //     setValidationErrors(newValidationErrors);
    //     return;
    //   }
    //   setValidationErrors({});
    //   await createUser(values);
    //   table.setCreatingRow(null); // exit creating mode
    // };

    // UPDATE action
    // const handleSaveUsers = async () => {
    //   if (Object.values(validationErrors).some((error) => !!error)) return;
    //   await updateUsers(Object.values(productEditValues));
    //   setproductEditValues({});
    // };

    // DELETE action
    // const openDeleteConfirmModal = (row) => {
    //   if (window.confirm('Are you sure you want to delete this user?')) {
    //     deleteUser(row.original.id);
    //   }
    // };

    const table = useMaterialReactTable({
      columns,
      data: fakeData,
      createDisplayMode: 'row', // ('modal', and 'custom' are also available)
      editDisplayMode: 'table', // ('modal', 'row', 'cell', and 'custom' are also
      enableEditing: true,
      enableRowActions: true,
      muiTableBodyCellProps: {
        align: 'center',
        sx: {
          '& fieldset': { borderRadius: '0px', border: 'none' },
          '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
          '& .MuiOutlinedInput-root': { padding: '0px !important' },

          '& .MuiTableCell-sizeMedium': {
            padding: '0px !important',
          },
          '& .autoComplete>div': { padding: '0px !important' },
          '& .MuiTableCell-root': {
            border: '0.01px solid #e9ecee',
          },
          '& .MuiTableCell-head': {
            padding: '5px !important',
            borderRight: '1px solid #d0d1d2 !important',
          },
          '& .MuiTableCell-head:last-child': {
            borderRight: '0px !important',
          },
          borderRight: '2px solid #e0e0e0',
          borderTop: '2px solid #e0e0e0',
          margin: '0px',
        },
      },
      muiTableProps: {
        sx: {
          '&:last-child td': {
            borderColor: '#E9ECEE !important',
          },
        },
      },
      enableColumnResizing: true,
      positionActionsColumn: 'last',
      getRowId: (row) => row.id,
      // muiToolbarAlertBannerProps: isLoadingUsersError
      //   ? {
      //       color: 'error',
      //       children: 'Error loading data',
      //     }
      //   : undefined,
      muiTableContainerProps: {
        sx: {
          // minHeight: '500px',
          // minWidth:'1200px',
        },
      },
      onCreatingRowCancel: () => setValidationErrors({}),
      // onCreatingRowSave: handleCreateUser,
      // renderRowActions: ({ row }) => (
      //   <Box sx={{ display: 'flex', gap: '1rem' }}>
      //     <Tooltip title="Delete">
      //       <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
      //         <DeleteIcon />
      //       </IconButton>
      //     </Tooltip>
      //   </Box>
      // ),
      // renderBottomToolbarCustomActions: () => (
      //   <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
      //     <Button
      //       color="success"
      //       variant="contained"
      //       onClick={handleSaveUsers}
      //       disabled={
      //         Object.keys(productEditValues).length === 0 ||
      //         Object.values(validationErrors).some((error) => !!error)
      //       }
      //     >
      //       {isUpdatingUsers ? <CircularProgress size={25} /> : 'Save'}
      //     </Button>
      //     {Object.values(validationErrors).some((error) => !!error) && (
      //       <Typography color="error">Fix errors before submitting</Typography>
      //     )}
      //   </Box>
      // ),
      renderTopToolbarCustomActions: ({ table }) => (
        <Button
          variant="contained"
          onClick={() => {
            table.setCreatingRow(true); // simplest way to open the create row modal with no default values
            // or you can pass in a row object to set default values with the `createRow` helper function
            // table.setCreatingRow(
            //   createRow(table, {
            //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
            //   }),
            // );
          }}
        >
          Create New User
        </Button>
      ),
      state: {
        // isLoading: isLoadingUsers,
        // isSaving: isCreatingUser || isUpdatingUsers || isDeletingUser,
        // showAlertBanner: isLoadingUsersError,
        // showProgressBars: isFetchingUsers,
      },
    });

    return <MaterialReactTable table={table} />;
  };

  // CREATE hook (post new user to api)
  function useCreateUser() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async (user) => {
        // send api update request here
        await new Promise((resolve) => setTimeout(resolve, 1000)); // fake api call
        return Promise.resolve();
      },
      // client side optimistic update
      onMutate: (newUserInfo) => {
        queryClient.setQueryData(['users'], (prevUsers) => [
          ...prevUsers,
          {
            ...newUserInfo,
            id: (Math.random() + 1).toString(36).substring(7),
          },
        ]);
      },
      // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), // refetch users after mutation, disabled for demo
    });
  }

  // // READ hook (get users from api)
  // function useGetUsers() {
  //   return useQuery({
  //     queryKey: ['users'],
  //     queryFn: async () => {
  //       // send api request here
  //       await new Promise((resolve) => setTimeout(resolve, 1000)); // fake api call
  //       return Promise.resolve(fakeData);
  //     },
  //     refetchOnWindowFocus: false,
  //   });
  // }

  // // UPDATE hook (put user in api)
  // function useUpdateUsers() {
  //   const queryClient = useQueryClient();
  //   return useMutation({
  //     mutationFn: async (users) => {
  //       // send api update request here
  //       await new Promise((resolve) => setTimeout(resolve, 1000)); // fake api call
  //       return Promise.resolve();
  //     },
  //     // client side optimistic update
  //     onMutate: (newUsers) => {
  //       queryClient.setQueryData(['users'], (prevUsers) =>
  //         prevUsers?.map((user) => {
  //           const newUser = newUsers.find((u) => u.id === user.id);
  //           // const myUser = ;
  //           return newUser != null ? newUser : user;
  //         })
  //       );
  //     },
  //     // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), // refetch users after mutation, disabled for demo
  //   });
  // }

  // // DELETE hook (delete user in api)
  // function useDeleteUser() {
  //   const queryClient = useQueryClient();
  //   return useMutation({
  //     mutationFn: async (userId) => {
  //       // send api update request here
  //       await new Promise((resolve) => setTimeout(resolve, 1000)); // fake api call
  //       return Promise.resolve();
  //     },
  //     // client side optimistic update
  //     onMutate: (userId) => {
  //       queryClient.setQueryData(['users'], (prevUsers) =>
  //         prevUsers?.filter((user) => user.id !== userId)
  //       );
  //     },
  //     // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), // refetch users after mutation, disabled for demo
  //   });
  // }

  const queryClient = new QueryClient();

  const ExampleWithProviders = () => (
    // Put this with your other react-query providers near root of your app
    <QueryClientProvider client={queryClient}>
      <Example />
    </QueryClientProvider>
  );

  const validateRequired = (value) => !!value.length;
  const validateEmail = (email) =>
    !!email.length &&
    email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  function validateUser(user) {
    return {
      firstName: !validateRequired(user.firstName) ? 'First Name is Required' : '',
      lastName: !validateRequired(user.lastName) ? 'Last Name is Required' : '',
      email: !validateEmail(user.email) ? 'Incorrect Email Format' : '',
    };
  }

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onKeyDown={handleKeyDown}
      PaperProps={{
        sx: { maxWidth: 2220 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              top: 0,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <Toolbar>
              <Typography variant="h5" sx={{ flex: 1 }}>
                {row ? 'Update Job Card Parts' : 'Create Job Card Parts'}
              </Typography>

              <IconButton color="inherit" edge="start" onClick={onClose}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            </Toolbar>

            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>

          <Grid item container xs={12} md={12} lg={12} xl={12} xxl={12}>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              alignContent="start"
              spacing={2}
              sx={{ p: 2 }}
            // sx={{ m: 1 }}
            >
              <ExampleWithProviders />
              {/* <TableContainer
                component={Paper}
                sx={{ overflow: 'auto', maxHeight: '50vh', width: '98%', margin: 'auto' }}
              >
                <Table
                  stickyHeader
                  aria-label="simple table"
                  sx={{
                    minWidth: 10000,
                    '& .MuiTableCell-sizeMedium': {
                      padding: '0px !important',
                    },
                    '& fieldset': { borderRadius: '0px', border: 'none' },
                    '& .MuiInputBase-input': { padding: '0px !important', border: 'none' },
                    '& .MuiOutlinedInput-root': { padding: '0px !important' },
                    '& .autoComplete>div': { padding: '0px !important' },
                    '& .MuiTableCell-root': {
                      border: '0.01px solid #e9ecee',
                    },
                    '& .MuiTableCell-head': {
                      padding: '5px !important',
                      borderRight: '1px solid #d0d1d2 !important',
                    },
                    '& .MuiTableCell-head:last-child': {
                      borderRight: '0px !important',
                    },
                    '&:last-child td': {
                      borderColor: '#E9ECEE !important',
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: 20, left: 0 }}>No</TableCell>
                      <TableCell sx={{ width: 1500 }}>Source Location</TableCell>
                      <TableCell sx={{ width: 1200 }}>Outline #</TableCell>
                      <TableCell sx={{ width: 1500 }}>Part #</TableCell>
                      <TableCell sx={{ width: 1200 }}>Quantity</TableCell>
                      <TableCell sx={{ width: 1200 }}>Allocated Quantity</TableCell>
                      <TableCell sx={{ width: 1200 }}>Discount % / Items</TableCell>
                      <TableCell sx={{ width: 1200 }}>HSN Code</TableCell>
                      <TableCell sx={{ width: 1200 }}>MRP</TableCell>
                      <TableCell sx={{ width: 1200 }}>Price List</TableCell>
                      <TableCell sx={{ width: 1200 }}>Status</TableCell>
                      <TableCell sx={{ width: 1200 }}>Product Category</TableCell>
                      <TableCell sx={{ width: 1200 }}>Description</TableCell>
                      <TableCell sx={{ width: 1200 }}>Job Code Name</TableCell>
                      <TableCell sx={{ width: 1200 }}>On Hand QTY</TableCell>
                      <TableCell sx={{ width: 1200 }}>UoM</TableCell>
                      <TableCell sx={{ width: 1200 }}>Billing Type</TableCell>
                      <TableCell sx={{ width: 1200 }}>Billing Category</TableCell>
                      <TableCell sx={{ width: 1200 }}>Net Price</TableCell>
                      <TableCell sx={{ width: 1200 }}>Selling Price</TableCell>
                      <TableCell sx={{ width: 1200 }}>Discount Amount / Item</TableCell>
                      <TableCell sx={{ width: 1200 }}>Line Total</TableCell>
                      <TableCell sx={{ width: 1200 }}>Tax Name</TableCell>
                      <TableCell sx={{ width: 1200 }}>Remarks</TableCell>
                      <TableCell sx={{ width: 1200 }}>Unit Price</TableCell>
                      <TableCell sx={{ width: 1200 }}>Returned Qty</TableCell>
                      <TableCell sx={{ width: 1200 }}>Cancellation Reason</TableCell>
                      <TableCell sx={{ width: 1200 }}>Covered Product</TableCell>
                      <TableCell sx={{ width: 1200 }}>Insurance Contribution %</TableCell>
                      <TableCell sx={{ width: 1200 }}>Insurance Contribution Amount</TableCell>
                      <TableCell sx={{ width: 1200 }}>Customer Contribution %</TableCell>
                      <TableCell sx={{ width: 1200 }}>Customer Contribution Amount</TableCell>
                      <TableCell sx={{ width: 1200 }}>INSR CGST%</TableCell>
                      <TableCell sx={{ width: 1200 }}>INSR CGST Amount</TableCell>
                      <TableCell sx={{ width: 1200 }}>INSR SGST%</TableCell>
                      <TableCell sx={{ width: 1200 }}>INSR SGST Amount</TableCell>
                      <TableCell sx={{ width: 1200 }}>INSR IGST%</TableCell>
                      <TableCell sx={{ width: 1200 }}>INSR IGST Amount</TableCell>
                      <TableCell sx={{ width: 1200 }}>INSR UTGST%</TableCell>
                      <TableCell sx={{ width: 1200 }}>INSR UTGST Amount</TableCell>
                      <TableCell sx={{ width: 1200 }}>Quantity Shipped</TableCell>
                      <TableCell sx={{ width: 1200 }}>Other Charges</TableCell>
                      <TableCell sx={{ width: 1200 }}>Invoice</TableCell>
                      <TableCell sx={{ width: 1200 }}>Forecastable</TableCell>
                      <TableCell sx={{ width: 1200 }}>Override Policy Discounts</TableCell>
                      <TableCell sx={{ width: 1200 }}>First Name</TableCell>
                      <TableCell sx={{ width: 1200 }}>Line #</TableCell>
                      <TableCell sx={{ width: 1200 }}>Minimum Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {performed_job_details?.fields?.map((field, index) => {
                      // const gstRate = nearestDateList(
                      //   productList.find(
                      //     (x) => x.id === allValues.performed_job_details[index]?.productId
                      //   )?.gst_commodity?.gst_com_details
                      // );

                      return (
                        <TableRow key={index}>
                          <TableCell
                            sx={{
                              textAlign: 'center',
                              // position: 'sticky',
                              left: 0,
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.productId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
     autoHighlight
                              key={`${field.productId}-${index}`}
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              fullWidth
                              freeSolo
                              size="small"
                              disableCloseOnSelect
                              options={ORDER_TYPE}
                              getOptionLabel={(option) => option.name}
                              value={
                                ORDER_TYPE.find((data) => data.id === allValues.ORDER_TYPE) || ''
                              }
                              onChange={async (event, newValue) => {
                                // setProductEditValues(
                                //   ORDER_TYPE?.find((data) => data?.name === newValue?.name)
                                // );
                                // if (await trigger('in_type')) {

                                // }
                                setValue(
                                  'source_location',
                                  ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                                );
                              }}
                              groupBy={(option) => option?.a}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="source_location"
                                  className="autoComplete"
                                />
                              )}
                              // disabled={!noch(allValues.in_type)}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                  <GroupFooter component="li">
                                    <Button
                                      onClick={() => {
                                        quickEditProduct.onTrue();
                                      }}
                                    >
                                      Add
                                    </Button>

                                    {productEditValues?.id && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            quickEditProduct.onTrue();
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            confirm.onTrue();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    )}
                                  </GroupFooter>
                                </li>
                              )}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.productId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
     autoHighlight
                              key={`${field.productId}-${index}`}
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              fullWidth
                              freeSolo
                              size="small"
                              disableCloseOnSelect
                              options={ORDER_TYPE}
                              getOptionLabel={(option) => option.name}
                              value={
                                ORDER_TYPE.find((data) => data.id === allValues.ORDER_TYPE) || ''
                              }
                              onChange={async (event, newValue) => {
                                setProductEditValues(
                                  ORDER_TYPE?.find((data) => data?.name === newValue?.name)
                                );
                                if (await trigger('in_type')) {
                                  setValue(
                                    `inv_details[${index}].productId`,
                                    ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                                  );
                                }
                              }}
                              groupBy={(option) => option?.a}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`inv_details[${index}].productId`}
                                  className="autoComplete"
                                />
                              )}
                              // disabled={!noch(allValues.in_type)}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                  <GroupFooter component="li">
                                    <Button
                                      onClick={() => {
                                        quickEditProduct.onTrue();
                                      }}
                                    >
                                      Add
                                    </Button>

                                    {productEditValues?.id && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            quickEditProduct.onTrue();
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            confirm.onTrue();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    )}
                                  </GroupFooter>
                                </li>
                              )}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.productId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
     autoHighlight
                              key={`${field.productId}-${index}`}
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              fullWidth
                              freeSolo
                              size="small"
                              disableCloseOnSelect
                              options={ORDER_TYPE}
                              getOptionLabel={(option) => option.name}
                              value={
                                ORDER_TYPE.find((data) => data.id === allValues.ORDER_TYPE) || ''
                              }
                              onChange={async (event, newValue) => {
                                setProductEditValues(
                                  ORDER_TYPE?.find((data) => data?.name === newValue?.name)
                                );
                                if (await trigger('in_type')) {
                                  setValue(
                                    `inv_details[${index}].productId`,
                                    ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                                  );
                                }
                              }}
                              groupBy={(option) => option?.a}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`inv_details[${index}].productId`}
                                  className="autoComplete"
                                />
                              )}
                              // disabled={!noch(allValues.in_type)}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                  <GroupFooter component="li">
                                    <Button
                                      onClick={() => {
                                        quickEditProduct.onTrue();
                                      }}
                                    >
                                      Add
                                    </Button>

                                    {productEditValues?.id && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            quickEditProduct.onTrue();
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            confirm.onTrue();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    )}
                                  </GroupFooter>
                                </li>
                              )}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.productId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
     autoHighlight
                              key={`${field.productId}-${index}`}
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              fullWidth
                              freeSolo
                              size="small"
                              disableCloseOnSelect
                              options={ORDER_TYPE}
                              getOptionLabel={(option) => option.name}
                              value={
                                ORDER_TYPE.find((data) => data.id === allValues.ORDER_TYPE) || ''
                              }
                              onChange={async (event, newValue) => {
                                setProductEditValues(
                                  ORDER_TYPE?.find((data) => data?.name === newValue?.name)
                                );
                                if (await trigger('in_type')) {
                                  setValue(
                                    `inv_details[${index}].productId`,
                                    ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                                  );
                                }
                              }}
                              groupBy={(option) => option?.a}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`inv_details[${index}].productId`}
                                  className="autoComplete"
                                />
                              )}
                              // disabled={!noch(allValues.in_type)}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                  <GroupFooter component="li">
                                    <Button
                                      onClick={() => {
                                        quickEditProduct.onTrue();
                                      }}
                                    >
                                      Add
                                    </Button>

                                    {productEditValues?.id && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            quickEditProduct.onTrue();
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            confirm.onTrue();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    )}
                                  </GroupFooter>
                                </li>
                              )}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.productId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
     autoHighlight
                              key={`${field.productId}-${index}`}
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              fullWidth
                              freeSolo
                              size="small"
                              disableCloseOnSelect
                              options={ORDER_TYPE}
                              getOptionLabel={(option) => option.name}
                              value={
                                ORDER_TYPE.find((data) => data.id === allValues.ORDER_TYPE) || ''
                              }
                              onChange={async (event, newValue) => {
                                setProductEditValues(
                                  ORDER_TYPE?.find((data) => data?.name === newValue?.name)
                                );
                                if (await trigger('in_type')) {
                                  setValue(
                                    `inv_details[${index}].productId`,
                                    ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                                  );
                                }
                              }}
                              groupBy={(option) => option?.a}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`inv_details[${index}].productId`}
                                  className="autoComplete"
                                />
                              )}
                              // disabled={!noch(allValues.in_type)}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                  <GroupFooter component="li">
                                    <Button
                                      onClick={() => {
                                        quickEditProduct.onTrue();
                                      }}
                                    >
                                      Add
                                    </Button>

                                    {productEditValues?.id && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            quickEditProduct.onTrue();
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            confirm.onTrue();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    )}
                                  </GroupFooter>
                                </li>
                              )}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.productId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
     autoHighlight
                              key={`${field.productId}-${index}`}
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              fullWidth
                              freeSolo
                              size="small"
                              disableCloseOnSelect
                              options={ORDER_TYPE}
                              getOptionLabel={(option) => option.name}
                              value={
                                ORDER_TYPE.find((data) => data.id === allValues.ORDER_TYPE) || ''
                              }
                              onChange={async (event, newValue) => {
                                setProductEditValues(
                                  ORDER_TYPE?.find((data) => data?.name === newValue?.name)
                                );
                                if (await trigger('in_type')) {
                                  setValue(
                                    `inv_details[${index}].productId`,
                                    ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                                  );
                                }
                              }}
                              groupBy={(option) => option?.a}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`inv_details[${index}].productId`}
                                  className="autoComplete"
                                />
                              )}
                              // disabled={!noch(allValues.in_type)}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                  <GroupFooter component="li">
                                    <Button
                                      onClick={() => {
                                        quickEditProduct.onTrue();
                                      }}
                                    >
                                      Add
                                    </Button>

                                    {productEditValues?.id && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            quickEditProduct.onTrue();
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            confirm.onTrue();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    )}
                                  </GroupFooter>
                                </li>
                              )}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.productId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
     autoHighlight
                              key={`${field.productId}-${index}`}
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              fullWidth
                              freeSolo
                              size="small"
                              disableCloseOnSelect
                              options={ORDER_TYPE}
                              getOptionLabel={(option) => option.name}
                              value={
                                ORDER_TYPE.find((data) => data.id === allValues.ORDER_TYPE) || ''
                              }
                              onChange={async (event, newValue) => {
                                setProductEditValues(
                                  ORDER_TYPE?.find((data) => data?.name === newValue?.name)
                                );
                                if (await trigger('in_type')) {
                                  setValue(
                                    `inv_details[${index}].productId`,
                                    ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                                  );
                                }
                              }}
                              groupBy={(option) => option?.a}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`inv_details[${index}].productId`}
                                  className="autoComplete"
                                />
                              )}
                              // disabled={!noch(allValues.in_type)}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                  <GroupFooter component="li">
                                    <Button
                                      onClick={() => {
                                        quickEditProduct.onTrue();
                                      }}
                                    >
                                      Add
                                    </Button>

                                    {productEditValues?.id && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            quickEditProduct.onTrue();
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            confirm.onTrue();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    )}
                                  </GroupFooter>
                                </li>
                              )}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.productId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
     autoHighlight
                              key={`${field.productId}-${index}`}
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              fullWidth
                              freeSolo
                              size="small"
                              disableCloseOnSelect
                              options={ORDER_TYPE}
                              getOptionLabel={(option) => option.name}
                              value={
                                ORDER_TYPE.find((data) => data.id === allValues.ORDER_TYPE) || ''
                              }
                              onChange={async (event, newValue) => {
                                setProductEditValues(
                                  ORDER_TYPE?.find((data) => data?.name === newValue?.name)
                                );
                                if (await trigger('in_type')) {
                                  setValue(
                                    `inv_details[${index}].productId`,
                                    ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                                  );
                                }
                              }}
                              groupBy={(option) => option?.a}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`inv_details[${index}].productId`}
                                  className="autoComplete"
                                />
                              )}
                              // disabled={!noch(allValues.in_type)}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                  <GroupFooter component="li">
                                    <Button
                                      onClick={() => {
                                        quickEditProduct.onTrue();
                                      }}
                                    >
                                      Add
                                    </Button>

                                    {productEditValues?.id && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            quickEditProduct.onTrue();
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            confirm.onTrue();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    )}
                                  </GroupFooter>
                                </li>
                              )}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.productId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
     autoHighlight
                              key={`${field.productId}-${index}`}
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              fullWidth
                              freeSolo
                              size="small"
                              disableCloseOnSelect
                              options={ORDER_TYPE}
                              getOptionLabel={(option) => option.name}
                              value={
                                ORDER_TYPE.find((data) => data.id === allValues.ORDER_TYPE) || ''
                              }
                              onChange={async (event, newValue) => {
                                setProductEditValues(
                                  ORDER_TYPE?.find((data) => data?.name === newValue?.name)
                                );
                                if (await trigger('in_type')) {
                                  setValue(
                                    `inv_details[${index}].productId`,
                                    ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                                  );
                                }
                              }}
                              groupBy={(option) => option?.a}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`inv_details[${index}].productId`}
                                  className="autoComplete"
                                />
                              )}
                              // disabled={!noch(allValues.in_type)}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                  <GroupFooter component="li">
                                    <Button
                                      onClick={() => {
                                        quickEditProduct.onTrue();
                                      }}
                                    >
                                      Add
                                    </Button>

                                    {productEditValues?.id && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            quickEditProduct.onTrue();
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            confirm.onTrue();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    )}
                                  </GroupFooter>
                                </li>
                              )}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.productId && {
                                border: '0.01px solid #F90635 !important',
                              }
                            }
                          >
                            <Autocomplete
     autoHighlight
                              key={`${field.productId}-${index}`}
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              fullWidth
                              freeSolo
                              size="small"
                              disableCloseOnSelect
                              options={ORDER_TYPE}
                              getOptionLabel={(option) => option.name}
                              value={
                                ORDER_TYPE.find((data) => data.id === allValues.ORDER_TYPE) || ''
                              }
                              onChange={async (event, newValue) => {
                                setProductEditValues(
                                  ORDER_TYPE?.find((data) => data?.name === newValue?.name)
                                );
                                if (await trigger('in_type')) {
                                  setValue(
                                    `inv_details[${index}].productId`,
                                    ORDER_TYPE?.find((data) => data?.name === newValue?.name)?.id
                                  );
                                }
                              }}
                              groupBy={(option) => option?.a}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={`inv_details[${index}].productId`}
                                  className="autoComplete"
                                />
                              )}
                              // disabled={!noch(allValues.in_type)}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader component="li">
                                    <ListItem sx={{ padding: 0, margin: 0 }}>Product Name</ListItem>
                                  </GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                  <GroupFooter component="li">
                                    <Button
                                      onClick={() => {
                                        quickEditProduct.onTrue();
                                      }}
                                    >
                                      Add
                                    </Button>

                                    {productEditValues?.id && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            quickEditProduct.onTrue();
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            confirm.onTrue();
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </>
                                    )}
                                  </GroupFooter>
                                </li>
                              )}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                                    {option?.name}
                                  </ListItem>
                                </Box>
                              )}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFCheckbox row name="igst_tax" />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFCheckbox row name="igst_tax" />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFCheckbox row name="igst_tax" />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                          <TableCell
                            sx={
                              index >= 0 &&
                              errors &&
                              errors?.performed_job_details &&
                              errors?.performed_job_details[index] &&
                              errors?.performed_job_details[index]?.qty
                                ? {
                                    border: '0.01px solid #F90635 !important',
                                    textAlign: 'end',
                                  }
                                : { textAlign: 'end' }
                            }
                          >
                            <RHFTextField
                              onFocus={(e) => {
                                setCurrentRowIndex(index);
                              }}
                              onBlur={(e) => {
                                setCurrentRowIndex(null);
                              }}
                              textDirection="rtl"
                              name={`performed_job_details[${index}].qty`}
                              size="small"
                              type="number"
                              value={allValues.performed_job_details[index]?.qty}
                              onChange={(e) => {
                                const qty = e.target.value >= 0 && e.target.value;
                                setValue(`performed_job_details[${index}].qty`, qty);
                              }}
                              // disabled={!noch(allValues.performed_job_details[index]?.productId)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer> */}
            </Grid>
          </Grid>
        </Grid>
        <DialogActions
          sx={{
            bottom: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: (theme) => alpha(theme.palette.background.default, 1),
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {row ? 'Update' : 'Add'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
};
