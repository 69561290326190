import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useState, useCallback, useEffect, useContext } from 'react';
// @mui
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import queryString from 'query-string';
import { useSnackbar } from 'src/components/snackbar';
import { useDebounce } from 'src/hooks/use-debounce';
import { useTable } from 'src/components/table';
import Importerrors from 'src/layouts/_common/importerrors';
import Importloaders from 'src/layouts/_common/importloaders';
//
import { fetcher, destroy } from 'src/utils/axios';
import { UPermission, formatDate } from 'src/_mock/constant_funcation';
import {
  Box,
  CardContent,
  Fab,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import { useEventListener } from 'src/hooks/use-event-listener';
import Permission from 'src/sections/users/permission';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import moment from 'moment';
import { ConfirmDialog } from 'src/components/custom-dialog';
import EmptyContent from 'src/components/empty-content';
import { AuthContext } from 'src/auth/context/jwt';
import FollowUpQuickEditForm from 'src/sections/enquiry-followup/quick-edit-form';
import { useResponsive } from 'src/hooks/use-responsive';
import { HONDA_CATEGORY } from 'src/_mock/constant';
import Image from 'src/components/image';
import Label from 'src/components/label';
import { useRouter } from 'src/routes/hooks';
import NotFound from 'src/sections/error/notfound-invoice';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router';
import UserQuickEditForm from '../quick-edit-form';
import EnquiryList from '../enquiry-list';
import FilterEnquiry from '../FilterEnquiry';
import CustomToolbar from '../grid-toolbar';


const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
};

const MAINTABS = [
  {
    value: 'enquiry',
    label: 'Enquiry',
  },
  {
    value: 'activity',
    label: 'Activity',
  },
];

const TABS = [
  {
    value: 'today_enquiry',
    label: 'Today Enquiry',
  },
  {
    value: 'model_category',
    label: 'Model Category',
  },
];



export default function ListView() {
  const table = useTable();
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();
  const [row, setRow] = useState(null);
  const [enquiryDashboard, setEnquiryDashboard] = useState(null);
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    rowCount: 10000,
    page: 0,
    pageSize: 10,
  });
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [sortModel, setSortModel] = useState([{ field: 'id', sort: 'desc' }]);
  const [editPermission, setEditPermission] = useState(null);
  const permission = UPermission('component', 'EnquiryListPage');
  const [progress, setProgress] = useState({ status: 'done' });
  const { user, socket } = useContext(AuthContext);
  const [enqstatusData, setEnqstatusData] = useState('E,Q');

  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [isFileManagerVisible, setIsFileManagerVisible] = useState(false);

  const lgUp = useResponsive('down', 'md');

  const [filters, setFilters] = useState(defaultFilters);

  const debouncedQuery = useDebounce(filters);

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = count === 0;

  const { page } = table;
  const limit = table.rowsPerPage;

  const [productFilter, setProductFilter] = useState({
    modelCategory: [],
    selectedCategories: [],
    modelName: [],
    selectedModelName: [],
    ModelVariant: [],
    selectedModelVariant: [],
    modelColor: [],
    selectedModelColor: [],
    dseName: [],
    selectedDSEName: [],
    fromDate: new Date(),
    toDate: new Date(),
    search: null,
  });
  const debouncedQuerySearch = useDebounce(productFilter.search);

  const toggleFileManager = () => {
    setIsFileManagerVisible(true);
  };
  const getAll = useCallback(
    async (apply = false) => {
      if ((apply && isFileManagerVisible) || (!isFileManagerVisible && !apply)) {
        setIsLoading(true);

        try {
          let URL = 'automobile/enquiries/?';

          const query = {
            page: paginationModel.page + 1,
            limit: paginationModel.pageSize,
            sortModel: JSON.stringify(sortModel),
            filterModel: JSON.stringify(filterModel),
          };
          const categoryId = productFilter?.selectedCategories.map((x) => x.id).join(',');
          const modelId = productFilter?.selectedModelName.map((x) => x.id).join(',');
          const variantId = productFilter?.selectedModelVariant.map((x) => x.id).join(',');
          const colourId = productFilter?.selectedModelColor.map((x) => x.id).join(',');
          const DSEId = productFilter?.selectedDSEName.map((x) => x.id).join(',');
          const fromDate = productFilter?.fromDate;
          const toDate = productFilter?.toDate;
          if (categoryId) {
            // eslint-disable-next-line dot-notation
            query['amodcategoryId'] = categoryId;
          }
          if (modelId) {
            // eslint-disable-next-line dot-notation
            query['amodelId'] = modelId;
          }
          if (variantId) {
            // eslint-disable-next-line dot-notation
            query['avariantId'] = variantId;
          }
          if (colourId) {
            // eslint-disable-next-line dot-notation
            query['acolourId'] = colourId;
          }
          if (DSEId) {
            // eslint-disable-next-line dot-notation
            query['salesmanId'] = DSEId;
          }
          if (fromDate) {
            // eslint-disable-next-line dot-notation
            query['fromDate'] = formatDate(fromDate);
          }
          if (toDate) {
            // eslint-disable-next-line dot-notation
            query['toDate'] = formatDate(toDate);
          }
          if (lgUp)
            URL += `${queryString.stringify(
              query
            )}&name=${debouncedQuerySearch}&enqstatusId=${enqstatusData}&`;
          else URL += `${queryString.stringify(
            query
          )}&name=${debouncedQuerySearch}&`;

          if (Object.keys(debouncedQuery).length) {
            const nefil = { ...debouncedQuery };
            if (debouncedQuery.status === 'all') {
              delete nefil.status;
            }
            // URL += queryString.stringify(nefil);
          }

          const { data } = await fetcher(URL);
          if (data) {
            if ('count' in data) {

              setRowCount(data.count)
              setCount(data?.count);
            }
            if ('rows' in data && Array.isArray(data.rows))
              setTableData(data?.rows);
          }
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      }
    },
    [lgUp,
      debouncedQuery,
      debouncedQuerySearch,
      filterModel,
      isFileManagerVisible,
      paginationModel.page,
      paginationModel.pageSize,
      productFilter?.fromDate,
      productFilter?.selectedCategories,
      productFilter?.selectedDSEName,
      productFilter?.selectedModelColor,
      productFilter?.selectedModelName,
      productFilter?.selectedModelVariant,
      productFilter?.toDate,
      sortModel,
      enqstatusData,
    ]
  );

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const hiddenFields = ['id', 'action'];

  const handleDeleteRows = useCallback(
    async (id) => {
      try {
        let URL = 'automobile/enquiries/';
        if (id) {
          URL += +id;
        }
        const { success, show } = await destroy({ url: URL });
        if (success) {
          if (show) {
            enqueueSnackbar('Delete success!');
          }
          getAll();
        }
      } catch (error) {
        if (error?.show) {
          enqueueSnackbar(error?.message || '', {
            variant: 'info',
          });
        }
        console.error(error);
      }
    },
    [enqueueSnackbar, getAll]
  );
  const columns = [
    {
      field: 'identity',
      headerName: 'Enq. No.',
      flex: 1,
      editable: false,
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      editable: false,
    },
    {
      field: "customer.firstname",
      headerName: 'Name',
      flex: 1,
      editable: false,
    },
    {
      field: 'customer.mobile1',
      headerName: 'Mobile No',
      flex: 1,
      editable: false,
    },
    {
      field: 'vehicle',
      headerName: 'Vehicle',
      flex: 1,
      editable: false,
    },
    {
      editable: false,
      field: "salesman.name",
      headerName: 'Sales Man',
      align: 'left',
      headerAlign: 'left',
      width: 200,
    },
    {
      editable: false,
      field: 'action',
      headerName: ' ',
      align: 'right',
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params, row) => (
        <>
          <Tooltip title="Create Quotation" placement="top" arrow>
            <IconButton
              color={quickEdit.value ? 'inherit' : 'default'}
              onClick={() => {
                setRow(params.row);
                quickEdit.onTrue();
              }}
            >
              <Iconify icon="solar:bill-list-bold" />
            </IconButton>
          </Tooltip>

          <Tooltip title="FollowUp View" placement="top" arrow>
            <IconButton
              color={quickFollowUpEdit.value ? 'inherit' : 'default'}
              onClick={() => {
                setRow(params.row);
                quickFollowUpEdit.onTrue();
              }}
            >
              <Iconify icon="mdi:eye" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="top" arrow>
            <IconButton
              color={quickFollowUpEdit.value ? 'inherit' : 'default'}
              onClick={() => {
                handleDeleteRows(params.id)
              }}
            >
              <Iconify icon="mingcute:close-line" />
            </IconButton>

          </Tooltip>
        </>
      ),
    },
  ];

  const getEnquiryDashboard = useCallback(async () => {
    try {
      let URL = 'automobile/enquiries/dashboard/?';

      const query = {
        page: paginationModel.page + 1,
        limit: paginationModel.pageSize,
        sortModel: JSON.stringify(sortModel),
        filterModel: JSON.stringify(filterModel),
      };
      const categoryId = productFilter?.selectedCategories.map((x) => x.id).join(',');
      const modelId = productFilter?.selectedModelName.map((x) => x.id).join(',');
      const variantId = productFilter?.selectedModelVariant.map((x) => x.id).join(',');
      const colourId = productFilter?.selectedModelColor.map((x) => x.id).join(',');
      const DSEId = productFilter?.selectedDSEName.map((x) => x.id).join(',');
      const fromDate = productFilter?.fromDate;
      const toDate = productFilter?.toDate;
      if (categoryId) {
        // eslint-disable-next-line dot-notation
        query['amodcategoryId'] = categoryId;
      }
      if (modelId) {
        // eslint-disable-next-line dot-notation
        query['amodelId'] = modelId;
      }
      if (variantId) {
        // eslint-disable-next-line dot-notation
        query['avariantId'] = variantId;
      }
      if (colourId) {
        // eslint-disable-next-line dot-notation
        query['acolourId'] = colourId;
      }
      if (DSEId) {
        // eslint-disable-next-line dot-notation
        query['salesmanId'] = DSEId;
      }
      if (fromDate) {
        // eslint-disable-next-line dot-notation
        query['fromDate'] = formatDate(fromDate);
      }
      if (toDate) {
        // eslint-disable-next-line dot-notation
        query['toDate'] = formatDate(toDate);
      }

      URL += `${queryString.stringify(
        query
      )}&name=${debouncedQuerySearch}&enqstatusId=${enqstatusData}&`;

      if (Object.keys(debouncedQuery).length) {
        const nefil = { ...debouncedQuery };
        if (debouncedQuery.status === 'all') {
          delete nefil.status;
        }
        // URL += queryString.stringify(nefil);
      }

      const { data } = await fetcher(URL);
      if (data) {
        setEnquiryDashboard(data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    debouncedQuery,
    debouncedQuerySearch,
    enqstatusData,
    filterModel,
    paginationModel.page,
    paginationModel.pageSize,
    productFilter?.fromDate,
    productFilter?.selectedCategories,
    productFilter?.selectedDSEName,
    productFilter?.selectedModelColor,
    productFilter?.selectedModelName,
    productFilter?.selectedModelVariant,
    productFilter?.toDate,
    sortModel,
  ]);

  useEffect(() => {
    getAll();
    getEnquiryDashboard();
  }, [getAll, getEnquiryDashboard, user.branchId]);

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );
  const getTogglableColumns = () =>
    columns.filter((column) => !hiddenFields.includes(column.field)).map((column) => column.field);
  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const quickFollowUpEdit = useBoolean();
  const quickEdit = useBoolean();
  const permissionEdit = useBoolean();

  const handleKeyDown = (event) => {
    if (event.altKey && event.key.toLowerCase() === 'c') {
      event.stopPropagation();
      setRow(null);
      quickEdit.onTrue();
    }
  };
  const confirm = useBoolean();

  const router = useRouter();
  const navigate = useNavigate();

  const popover = usePopover();
  useEventListener('keydown', handleKeyDown);

  const [mainCurrentTab, setMainCurrentTab] = useState('enquiry');
  const [currentTab, setCurrentTab] = useState('today_enquiry');

  const handleChangeMainTab = useCallback(
    (event, newValue) => {
      setMainCurrentTab(newValue);
      if (newValue === 'activity') router.replace('/sales/enquiry/activity');
    },
    [router]
  );

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);
  const onImportError = useBoolean();
  const so = useCallback(() => {
    socket?.on('progress', (data) => {
      if ('aenquiries' in data && data.aenquiries.status === 'error') {
        setProgress(data.aenquiries);
        onImportError.setValue(true);
      }
      if ('aenquiries' in data && data.aenquiries.status === 'done') {
        setProgress(data.aenquiries);
      } else if ('aenquiries' in data && data.aenquiries.error) {
        // setError(data.error);
      } else if ('aenquiries' in data) {
        setProgress(data.aenquiries);

      }
    });
  }, [onImportError, socket])
  useEffect(() => so(), [so, socket])
  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {!lgUp && (
          <CustomBreadcrumbs
            heading="Enquiry List"
            settingIcon="fluent:search-12-filled"
            settingTitle="Enquiry Filters"
            openSettingTrue="true"
            openSetting={toggleFileManager}
            links={[{ name: 'Dashboard', href: paths.home }, { name: 'List' }]}
            action={
              <Tooltip title="(Alt + C)" arrow>
                <Button
                  component={RouterLink}
                  variant="contained"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                  onClick={() => {
                    quickEdit.onTrue();
                    setRow(null);
                  }}
                >
                  Enquiry
                </Button>
              </Tooltip>
            }
            sx={{
              mb: { xs: 2, md: 2 },
            }}
          />
        )}

        {!lgUp && isFileManagerVisible && (
          <Grid
            sx={{
              top: 62,
              width: 1,
              zIndex: 9,
              position: 'sticky',
              bgcolor: (theme) => alpha(theme.palette.background.default, 1),
            }}
          >
            <FilterEnquiry
              isFileManagerVisible={isFileManagerVisible}
              setIsFileManagerVisible={setIsFileManagerVisible}
              productFilter={productFilter}
              setProductFilter={setProductFilter}
              getAll={getAll}
            />
          </Grid>
        )}

        {lgUp ? (
          <>
            <Grid
              sx={{
                top: 62,
                width: 1,
                zIndex: 9,
                position: 'sticky',
                bgcolor: (theme) => alpha(theme.palette.background.default, 1),
              }}
            >
              <FilterEnquiry
                isFileManagerVisible={isFileManagerVisible}
                setIsFileManagerVisible={setIsFileManagerVisible}
                productFilter={productFilter}
                setProductFilter={setProductFilter}
                getAll={getAll}
              />

              <Tabs
                value={mainCurrentTab}
                onChange={handleChangeMainTab}
                sx={{
                  mb: { xs: 2, md: 2 },
                }}
                variant="fullWidth"
              >
                {MAINTABS.map((tab) => (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                    sx={{
                      bgcolor: tab.value === mainCurrentTab && 'primary.dark',
                      color: tab.value === mainCurrentTab && 'common.white',
                    }}
                  />
                ))}
              </Tabs>
              <EnquiryList
                enquiryDashboard={enquiryDashboard}
                setEnqstatusData={setEnqstatusData}
                enqstatusData={enqstatusData}
              />
              <Tabs value={currentTab} onChange={handleChangeTab} variant="fullWidth">
                {TABS.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
            </Grid>

            {currentTab === 'today_enquiry' && mainCurrentTab === 'enquiry' && (
              <Grid container xs={12} sx={{ mt: 2 }}>
                {tableData.length ? (
                  tableData.map((data, index) => (
                    <Grid container item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} mt={2}>
                      <Paper
                        sx={{
                          borderRadius: 2,
                          position: 'relative',
                          bgcolor: (theme) => alpha(theme.palette.background.paper, 1),
                          boxShadow: 2,
                        }}
                      >
                        <Grid container item xs={12} sm={12} sx={{ p: 1 }}>
                          <Grid container item xs={12} sm={12}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              sx={{
                                cursor: 'pointer',
                                justifyContent: 'space-between',
                                display: 'flex',
                              }}
                              onClick={() => {
                                setRow(data);
                                quickEdit.onTrue();
                              }}
                            >
                              <Stack
                                direction="column"
                                alignItems="right"
                                spacing={1}
                                sx={{ color: 'text.secondary', typography: 'caption' }}
                              >
                                {data?.amodcategory?.name} || {data?.amodel?.name}
                              </Stack>
                              <Stack
                                direction="column"
                                alignItems="right"
                                spacing={1}
                                sx={{
                                  color: 'text.secondary',
                                  typography: 'caption',
                                  bgcolor: 'background.neutral',
                                  px: 1,
                                  borderRadius: 2,
                                }}
                              >
                                Enquiry No. {data?.identity}
                              </Stack>
                            </Grid>
                          </Grid>
                          <Grid container item xs={12} sm={12}>
                            <Typography variant="subtitle2">
                              {data?.customer?.firstname} {data?.customer?.lastname}
                            </Typography>
                          </Grid>
                          <Grid container item xs={12} sm={12}>
                            <Grid item xs={5} sm={5} md={5} sx={{ cursor: 'pointer' }}>
                              <Typography variant="subtitle2" color="primary.dark">
                                {data?.customer?.mobile1 ? `Mo. ${data?.customer?.mobile1}` : '-'}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={7}
                              sm={7}
                              md={7}
                              sx={{ display: 'flex', justifyContent: 'space-evenly' }}
                            >
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  backgroundColor: data?.enqstatusId === 'E' && '#CB182E',
                                  color: data?.enqstatusId === 'E' && 'white',
                                  px: 1,
                                }}
                              >
                                E
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  backgroundColor: data?.enqstatusId === 'B' && '#CB182E',
                                  color: data?.enqstatusId === 'B' && 'white',
                                  px: 1,
                                }}
                              >
                                B
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  backgroundColor: data?.enqstatusId === 'I' && '#CB182E',
                                  color: data?.enqstatusId === 'I' && 'white',
                                  px: 1,
                                }}
                              >
                                I
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  backgroundColor: data?.enqstatusId === 'Q' && '#CB182E',
                                  color: data?.enqstatusId === 'Q' && 'white',
                                  px: 1,
                                }}
                              >
                                Q
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  backgroundColor: data?.enqstatusId === 'A' && '#CB182E',
                                  color: data?.enqstatusId === 'A' && 'white',
                                  px: 1,
                                }}
                              >
                                A
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  backgroundColor: data?.enqstatusId === 'L' && '#CB182E',
                                  color: data?.enqstatusId === 'L' && 'white',
                                  px: 1,
                                }}
                              >
                                L
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12} sm={12}>
                            <Stack
                              direction="row"
                              alignItems="right"
                              spacing={1}
                              mt={1}
                              sx={{ color: 'text.secondary', typography: 'caption' }}
                            >
                              {false && '-'}
                            </Stack>
                          </Grid>

                          <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            onClick={() => {
                              setRow(data);
                              quickEdit.onTrue();
                            }}
                          >
                            <Grid item xs={9}>
                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{ color: 'text.secondary', typography: 'caption' }}
                              >
                                <Stack direction="row" alignItems="center">
                                  <Iconify
                                    icon="solar:calendar-date-bold"
                                    width={16}
                                    sx={{ mr: 0.5 }}
                                  />
                                  {formatDate(data?.date)}
                                </Stack>

                                <Stack direction="row" alignItems="center">
                                  <Iconify
                                    icon="solar:users-group-rounded-bold"
                                    width={16}
                                    sx={{ mr: 0.5 }}
                                  />
                                  {data?.salesman?.name}
                                </Stack>
                              </Stack>
                            </Grid>
                            <Grid item xs={3}>
                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                  color: 'text.secondary',
                                  typography: 'caption',
                                  display: 'block',
                                  textAlign: 'end',
                                }}
                              >
                                <Label variant="soft" color="warning">
                                  TEST RIDE
                                </Label>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={6}
                    xxl={6}
                    sx={{ display: 'block' }}
                  >
                    <NotFound name="Data" />
                  </Grid>
                )}
              </Grid>
            )}

            {currentTab === 'model_category' && mainCurrentTab === 'enquiry' && (
              <>
                {HONDA_CATEGORY.map((category, index) => (
                  <Box
                    sx={{ position: 'relative', mt: 3 }}
                    spacing={2}
                    key={index}
                    onClick={() => {
                      navigate('/enquiry/honda-category', { state: { category } });
                    }}
                  >
                    <CardContent
                      sx={{
                        left: 0,
                        width: 1,
                        bottom: 0,
                        zIndex: 8,
                        textAlign: 'left',
                        position: 'absolute',
                        color: 'common.white',
                      }}
                    >
                      <Button color="warning" variant="contained">
                        {category?.name}
                      </Button>
                    </CardContent>

                    <Image
                      alt="Images"
                      src={category?.image}
                      ratio="16/9"
                      sx={{ borderRadius: 1.5 }}
                    />
                  </Box>
                ))}
              </>
            )}
          </>
        ) : (
          <Card>
            <DataGrid
              disableRowSelectionOnClick
              rows={tableData.map((x) => ({
                id: x?.id,
                identity: x?.identity,
                date: formatDate(x?.date),
                "customer.firstname": x?.customer?.firstname,
                "customer.mobile1": x?.customer?.mobile1,
                vehicle: x.amodel?.name,
                "salesman.name": x.salesman?.name,
              }))}
              columns={columns}
              sortingMode="server"
              filterMode="server"
              paginationMode="server"
              rowCount={rowCount}
              onPaginationModelChange={setPaginationModel}
              onSortModelChange={setSortModel}
              onFilterModelChange={setFilterModel}
              slots={{
                toolbar: () => <CustomToolbar permission={permission} />,
                noRowsOverlay: () => <EmptyContent title="No Data" />,
                noResultsOverlay: () => <EmptyContent title="No Results found" />,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: {
                    debounceMs: 2000,
                  },
                },
                columnsPanel: {
                  getTogglableColumns,
                },
              }}
            />
            {/* <Tabs
            value={filters.status}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab key={tab.value} iconPosition="end" value={tab.value} label={tab.label} />
            ))}
          </Tabs>

          <UserTableToolbar filters={filters} onFilters={handleFilters} permission={permission} getAll={getAll} />

          {canReset && (
            <UserTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              onResetFilters={handleResetFilters}
              //
              results={count}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar sx={{ maxHeight: 400 }}>
              <Table stickyHeader size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {isLoading ? (
                    <TableRowsLoader rowsNum={table.rowsPerPage} colNum={TABLE_HEAD.length} />
                  ) : (
                    <>
                      {tableData.map((row, index) => (
                        <UserTableRow
                          tableData={tableData}
                          key={row.id}
                          row={row}
                          index={page * limit + index + 1}
                          onDeleteRow={() => handleDeleteRows(row.id)}
                          selected={table.selected.includes(row.id)}
                          setRow={setRow}
                          quickEdit={quickEdit}
                          getAll={getAll}
                          setEditPermission={setEditPermission}
                          permissionEdit={permissionEdit}
                          permission={permission}
                        />
                      ))}

                      <TableNoData notFound={notFound} />
                    </>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          /> */}
          </Card>
        )}

        {lgUp && (
          <Box
            sx={{
              position: 'fixed',
              bottom: 25,
              right: 20,
              borderRadius: '50%',
              width: 56,
              height: 56,
              backgroundColor: 'primary.dark',
              color: 'common.white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: 3,
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
              cursor: 'pointer',
            }}
            onClick={() => {
              quickEdit.onTrue();
              setRow(null);
            }}
          >
            <Iconify icon="mingcute:add-line" width={24} height={24} />
          </Box>
        )}
      </Container>

      {quickEdit.value && (
        <UserQuickEditForm
          row={row || null}
          open={quickEdit.value}
          onClose={() => {
            quickEdit.onFalse();
            getEnquiryDashboard();
          }}
          table={table}
          getAll={getAll}
          tableData={tableData}
        />
      )}

      {quickFollowUpEdit.value && (
        <FollowUpQuickEditForm
          row={row || null}
          open={quickFollowUpEdit.value}
          onClose={() => {
            quickFollowUpEdit.onFalse();
            getEnquiryDashboard();
          }}
          table={table}
          getAll={getAll}
          tableData={tableData}
          onlydisplay={false}
        />
      )}

      {popover.open && (
        <CustomPopover
          open={popover.open}
          onClose={popover.onClose}
          arrow="right-top"
          sx={{ width: 140 }}
        >
          <MenuItem
            onClick={() => {
              confirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon="solar:trash-bin-trash-bold" />
            Delete
          </MenuItem>

          <MenuItem
            onClick={() => {
              quickEdit.onTrue();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:pen-bold" />
            Edit
          </MenuItem>
        </CustomPopover>
      )}

      {confirm.value && (
        <ConfirmDialog
          open={confirm.value}
          onClose={confirm.onFalse}
          title="Delete"
          content="Are you sure want to delete?"
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                console.log(row);
                if (row?.id) handleDeleteRows(row?.id);
              }}
            >
              Delete
            </Button>
          }
        />
      )}

      <Permission
        row={editPermission}
        from="role"
        open={permissionEdit.value}
        onClose={permissionEdit.onFalse}
        getAll={getAll}
      // userData={userData}
      />
      {onImportError.value && progress?.status === "error" && (
        <Importerrors
          row={progress}
          open={onImportError.value}
          onClose={onImportError.onFalse}
        />
      )}
      {!['error', 'done'].includes(progress.status) && <Importloaders progress={progress} />}
    </>
  );
}
