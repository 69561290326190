import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { DateField, DatePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { create, destroy, fetcher, update } from 'src/utils/axios';
import { ACTIVITYSTATUS, GENDER, KEY, KMSDRIVEN } from 'src/_mock/constant';
import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  IconButton,
  ListItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { useBoolean } from 'src/hooks/use-boolean';
import { useDebounce } from 'src/hooks/use-debounce';
import Iconify from 'src/components/iconify';
import { Stack } from '@mui/system';
import { useEventListener } from 'src/hooks/use-event-listener';
import { handleScroll } from 'src/layouts/_common/scroller';
import {
  assignNullToEmptyOrUndefinedValues,
  useBgColorAutocomplete,
} from 'src/_mock/constant_funcation';
import { AuthContext } from 'src/auth/context/jwt';
import { useResponsive } from 'src/hooks/use-responsive';
import {
  useGetCommon,
  useGetFinances,
  useGetReasones,
  useGetSources,
  useGetZones,
} from '../work_shop/global';
import GlobalQuickForm from '../work_shop/globalmodel';
import VehicleQuickEditForm from '../work_shop/vehicleentry/quick-edit-form';
import ActivityListing from './ActivityListing';

// Reusable hook for managing an input state and its debounced version
function useDebouncedInput(
  initialValue = { name: null, value: null, page: null, limit: null },
  delay = 500
) {
  const [input, setInput] = useState(initialValue);
  const [debouncedInput, setDebouncedInput] = useState(input);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedInput(input), delay);
    return () => clearTimeout(handler);
  }, [input, delay]);

  return [input, setInput, debouncedInput];
}
export default function MobileFollowUp({
  row,
  open,
  onClose,
  activeTab,
  table,
  getAll,
  tableData,
}) {
  const theme = useTheme();
  const { user } = useContext(AuthContext);

  console.log(row, 'row');

  const state_Id = user.companies?.find((x) => x?.id === user?.companyId)?.stateId;

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [fieldName, setFieldName] = useState('');
  const [enqNo, setNumber] = useState(null);
  const quickEditStatus = useBoolean();
  const confirm = useBoolean();
  const exitPage = useBoolean();
  const activityListOpen = useBoolean();

  const bgColorAutocomplete = useBgColorAutocomplete();

  const lgUp = useResponsive('down', 'md');

  const rolesSchema = Yup.object().shape({
    customer: Yup.object().shape({
      firstname: Yup.string().required('Name is required'),
      gender: Yup.number().required('Gender is required'),
      mobile1: Yup.string()
        .required('Mobile number is required')
        .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits'),
    }),

    astatus: Yup.number().required('astatus is required'),
    afterday: Yup.string().min(0).required('After Day is required'),
    remarks: Yup.string().required('remarks is required'),
    aenqcatieId: Yup.number().required('Enquiry Category is required'),
    aenqtypeId: Yup.number().required('Enquiry Type is required'),
    aenqsrceId: Yup.number().required('Enquiry Source is required'),
    apurtypeId: Yup.number().required('Purchase Type is required'),

    salesmanId: Yup.number().required('Salesman is required'),
    amakerId: Yup.number().when('exchange', {
      is: (val) => val === true,
      then: () => Yup.number().required('Vehicle Maker is required'),
      otherwise: () => Yup.number().nullable().notRequired(),
    }),
    emodelId: Yup.number().when('exchange', {
      is: (val) => val === true,
      then: () => Yup.number().required('Vehicle Model is required'),
      otherwise: () => Yup.number().nullable().notRequired(),
    }),

    amodcategoryId: Yup.number().required('Model Category is required'),
    amodelId: Yup.number().required('Product Model is required'),
    avariantId: Yup.number().required('Product Variant is required'),
    amtocId: Yup.number().required('MTOC (Product) is required'),

    afinancesId: Yup.number().when('apurtypeId', {
      is: (val) => val === 2,
      then: () => Yup.number().required('Financier is required'),
      otherwise: () => Yup.number().nullable().notRequired(),
    }),
    areasoneId: Yup.number().when('afterday', {
      is: (val) => val === '0',
      then: () => Yup.number().required('Reason is required'),
      otherwise: () => Yup.number().nullable().notRequired(),
    }),
    closeremarkId: Yup.number().when('afterday', {
      is: (val) => val === '0',
      then: () => Yup.number().required('Reason is required'),
      otherwise: () => Yup.number().nullable().notRequired(),
    }),
  });

  const metalist = useMemo(
    () => ({
      genderMeta: {
        page: 1,
        limit: 10,
        name: '',
        editRow: null,
        totalPage: 1,
      },
      aenqcatieMeta: {
        URL: 'automobile/enquiries/categories/',
        query: {
          limit: 10,
          name: '',
        },
        editRow: null,
      },
      aenqtypesMeta: {
        URL: 'automobile/enquiries/types/',
        query: {
          limit: 500,
          name: '',
        },
        editRow: null,
      },
      modelMakersMeta: {
        URL: 'automobile/product/makers/',
        query: {
          limit: 50000,
          name: '',
        },
        editRow: null,
      },
      modelCategoryMeta: {
        URL: 'automobile/product/categories/',
        query: {
          limit: 50000,
          name: '',
        },
        editRow: null,
      },
      productModelMeta: {
        URL: 'automobile/product/models/',
        query: {
          limit: 50000,
          name: '',
        },
        editRow: null,
      },
      productVariantMeta: {
        URL: 'automobile/product/variants/',
        query: {
          limit: 50000,
          name: '',
        },
        editRow: null,
      },
      productMtocMeta: {
        URL: 'automobile/product/mtoc/',
        query: {
          limit: 50000,
          name: '',
        },
        editRow: null,
      },
      aenqsrceMeta: {
        URL: 'automobile/enquiries/sources/',
        query: {
          limit: 10,
          name: '',
        },
        editRow: null,
      },
      acolourMeta: {
        URL: 'automobile/product/colours/',
        query: {
          limit: 200,
          name: '',
        },
        editRow: null,
      },
      afinancesMeta: {
        URL: 'automobile/finances/',
        query: {
          limit: 1000,
          name: '',
        },
        editRow: null,
      },
      apurtypeMeta: {
        URL: 'automobile/purtypes/',
        query: {
          limit: 10,
          name: '',
        },
        editRow: null,
      },
      areasoneMeta: {
        URL: 'automobile/reasones/',
        query: {
          limit: 10,
          name: '',
        },
        editRow: null,
      },
      salesmanMeta: {
        URL: 'users/',
        query: {
          departmentId: 2,
          limit: 1000,
          status: true,
          companyId: user?.companyId,
          name: '',
        },
        editRow: null,
      },

      cityMeta: {
        URL: 'cities/',
        query: {
          limit: 10,
          name: '',
          stateId: state_Id,
        },
        editRow: null,
      },
      zoneMeta: {
        URL: 'automobile/zones/',
        query: {
          limit: 200,
          name: '',
        },
        editRow: null,
      },
    }),
    [state_Id, user?.companyId]
  );

  const values = useMemo(
    () => ({
      enq_no: null,
      aenquiryId: null,
      date: new Date(),
      customer: {
        id: null,
        firstname: '',
        lastname: '',
        gender: null,
        dob: null,
        address1: '',
        email: null,
        mobile1: null,
        adate: null,
        localityId: null,
        locality: null,
        cityId: 58795,
        city: {
          id: 58795,
          name: 'Surat',
          countryCode: 'IN',
          stateCode: 'GJ',
          latitude: 21.178,
          longitude: 72.8119,
          status: true,
          createdAt: '2023-11-10 13:12:28',
          updatedAt: '2023-11-10 13:12:28',
          state: {
            id: 1985,
            name: 'Gujarat',
            isoCode: 'GJ',
            countryCode: 'IN',
            latitude: 22.2587,
            longitude: 71.1924,
            status: 1,
            createdAt: '2023-11-10 13:12:27',
            updatedAt: '2023-11-10 13:12:27',
          },
          country: {
            id: 101,
            name: 'India',
            isoCode: 'IN',
            flag: '??',
            phonecode: '91',
            latitude: 20,
            longitude: 77,
            currency: 'INR',
            status: 1,
            createdAt: '2023-11-10 13:13:10',
            updatedAt: '2023-11-10 13:13:10',
          },
        },
      },
      afterday: 1,
      testrideDate: new Date(),
      nextdate: null,
      yofmfd: null,
      kmsdriven: null,
      eamt: null,
      aenqcatieId: 1,
      aenqsrceId: null,
      acolourId: null,
      acolour: null,
      afinancesId: null,
      apurtypeId: null,
      areasoneId: null,
      closeremark: null,
      closeremarkId: null,
      makerlostId: null,
      modellostId: null,
      salesmanId: null,
      mcolor: '',
      remarks: '',
      astatus: null,
      counter: true,
      exchange: null,
      avehicleId: null,
      testrideflag: null,
      salesman: null,
      zone: null,
      areasone: null,
      ...metalist,
    }),
    [metalist]
  );

  const methods = useForm({
    resolver: yupResolver(rolesSchema),
    values,
    mode: 'onChange',
  });

  const {
    reset,
    handleSubmit,
    trigger,
    setValue,
    watch,
    control,
    formState: { errors },
  } = methods;

  const allValues = watch();

  const debouncedCategories = useDebounce(allValues.aenqcatieMeta?.name, 500);
  const debouncedPurtypes = useDebounce(allValues.apurtypeMeta?.name, 500);
  const debouncedSources = useDebounce(allValues.aenqsrceMeta?.name, 500);
  const debouncedTypes = useDebounce(allValues.aenqtypesMeta?.name, 500);
  const debouncedMMakers = useDebounce(allValues.modelMakersMeta?.name, 500);
  const debouncedMCategory = useDebounce(allValues.modelCategoryMeta?.name, 500);
  const debouncedProductModel = useDebounce(allValues.productModelMeta?.name, 500);
  const debouncedProductVariant = useDebounce(allValues.productVariantMeta?.name, 500);
  const debouncedProductMtoc = useDebounce(allValues.productMtocMeta?.name, 500);
  const debouncedReasones = useDebounce(allValues.areasoneMeta?.name, 500);
  const debouncedFinances = useDebounce(allValues.afinancesMeta?.name, 500);
  const debouncedZones = useDebounce(allValues.zoneMeta?.name, 500);
  const debouncedColours = useDebounce(allValues.acolourMeta?.name, 500);
  const debouncedSalesMan = useDebounce(allValues.salesmanMeta?.query?.name, 500);
  const debouncedCities = useDebounce(allValues.cityMeta?.query?.name, 500);

  const categories = useGetCommon(
    debouncedCategories ? allValues.aenqcatieMeta : allValues.aenqcatieMeta
  );
  const purtypes = useGetCommon(
    debouncedPurtypes ? allValues.apurtypeMeta : allValues.apurtypeMeta
  );
  const sources = useGetSources(debouncedSources ? allValues.aenqsrceMeta : allValues.aenqsrceMeta);
  const types = useGetCommon(debouncedTypes ? allValues.aenqtypesMeta : allValues.aenqtypesMeta);
  const modelMakers = useGetCommon(
    debouncedMMakers ? allValues.modelMakersMeta : allValues.modelMakersMeta
  );
  const modelCategory = useGetCommon(
    debouncedMCategory ? allValues.modelCategoryMeta : allValues.modelCategoryMeta
  );
  const productModel = useGetCommon(
    debouncedProductModel ? allValues.productModelMeta : allValues.productModelMeta
  );
  const productVariant = useGetCommon(
    debouncedProductVariant ? allValues.productVariantMeta : allValues.productVariantMeta
  );
  const productMtoc = useGetCommon(
    debouncedProductMtoc ? allValues.productMtocMeta : allValues.productMtocMeta
  );

  const reasones = useGetReasones(
    debouncedReasones ? allValues.areasoneMeta : allValues.areasoneMeta
  );
  const finances = useGetFinances(
    debouncedFinances ? allValues.afinancesMeta : allValues.afinancesMeta
  );
  const zones = useGetZones(debouncedZones ? allValues.zoneMeta : allValues.zoneMeta);
  const colours = useGetCommon(debouncedColours ? allValues.acolourMeta : allValues.acolourMeta);

  const salesmans = useGetCommon(
    debouncedSalesMan ? allValues.salesmanMeta : allValues.salesmanMeta
  );
  const cities = useGetCommon(debouncedCities ? allValues.cityMeta : allValues.cityMeta);

  console.log(row, 'row');

  useEffect(() => {
    if (row?.id) {
      reset({
        ...row.aenquiry,
        ...metalist,
        astatus: row?.astatus,
        remarks: row?.remarks,
        afterday: row?.followup?.afterday,
        nextdate: row?.followup?.nextdate,
      });

      setNumber(row.aenquiryId);
    } else reset(values);
  }, [metalist, reset, row, row?.id, setValue, values]);

  useEffect(() => {
    reset();
  }, [reset]);

  const onSubmit = handleSubmit(async (payload) => {
    payload.followupId = row.id;
    delete payload.id;
    payload = assignNullToEmptyOrUndefinedValues(payload);
    payload = { ...payload, aenquiryId: row?.aenquiryId };

    setLoading(true);
    try {
      const payload1 = Object.keys(payload).reduce((acc, key) => {
        if (!key.endsWith('Meta')) {
          acc[key] = payload[key];
        }
        return acc;
      }, {});

      const URL = 'automobile/followups/';
      const { success, show } = await create({ url: URL, payload: payload1 });
      if (success) {
        getAll();
        onClose();
        if (show) {
          enqueueSnackbar('Create success!');
        }
      }
      reset();
      setLoading(false);
    } catch (error) {
      if (error?.show) {
        enqueueSnackbar(error?.message || '', {
          variant: 'info',
        });
      }
      setLoading(false);
      console.error(error);
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const handleKeyDown = async (event) => {
    if (event.key === KEY.CLOSE) {
      onClose();
    } else if (event.altKey && event.key.toLowerCase() === KEY.ADD) {
      if ((await trigger()) && !loading && open) {
        onSubmit();
      }
    }
  };
  useEventListener('keydown', handleKeyDown);

  const allfiledmodel = {
    aenqcatieId: {
      title: 'Enquiry Category',
      url: 'automobile/enquiries/categories/',
      fieldName,
      setValue,
      fieldMeta: 'aenqcatieMeta',
    },
    aenqsrceId: {
      title: 'Enquiry Source',
      url: 'automobile/enquiries/sources/',
      fieldName,
      setValue,
      fieldMeta: 'aenqsrceMeta',
    },
    aenqtypeId: {
      title: 'Enquiry Types',
      url: 'automobile/enquiries/types/',
      fieldName,
      setValue,
      fieldMeta: 'aenqtypesMeta',
    },
    amodmakersId: {
      title: 'Model Makers',
      url: 'automobile/product/makers/',
      fieldName,
      setValue,
      fieldMeta: 'modelMakersMeta',
    },
    amodcategoryId: {
      title: 'Model Category',
      url: 'automobile/product/categories/',
      fieldName,
      setValue,
      fieldMeta: 'modelCategoryMeta',
    },
    amodelId: {
      title: 'Product Model',
      url: 'automobile/product/models/',
      fieldName,
      setValue,
      fieldMeta: 'productModelMeta',
    },
    avariantId: {
      title: 'Product Variant',
      url: 'automobile/product/variants/',
      fieldName,
      setValue,
      fieldMeta: 'productVariantMeta',
    },
    amtocId: {
      title: 'Product MTOC',
      url: 'automobile/product/mtoc/',
      fieldName,
      setValue,
      fieldMeta: 'productVariantMeta',
    },
    acolourId: {
      title: 'Enquiry Colour',
      url: 'automobile/product/colours/',
      fieldName,
      setValue,
      fieldMeta: 'acolourMeta',
    },
    afinancesId: {
      title: 'Finance',
      url: 'automobile/finances/',
      fieldName,
      setValue,
      fieldMeta: 'afinancesMeta',
    },
    apurtypeId: {
      title: 'Purchase Types',
      url: 'automobile/purtypes/',
      fieldName,
      setValue,
      fieldMeta: 'apurtypeId',
    },
    areasoneId: {
      title: 'Reasones',
      url: 'automobile/reasones/',
      fieldName,
      setValue,
      fieldMeta: 'areasoneMeta',
    },
    salesmanId: {
      title: 'SalesMan',
      url: 'users/',
      fieldName,
      setValue,
      fieldMeta: 'salesmanMeta',
    },
    localityId: {
      title: 'zone',
      url: 'zones/',
      fieldName,
      setValue,
      fieldMeta: 'zoneMeta',
    },
    avehicleId: {
      title: 'Vehicle',
      url: 'vehicles/',
      fieldName,
      setValue,
      fieldMeta: 'avehicleMeta',
    },
  };

  useEffect(() => {
    if (allValues.astatus === 1) {
      setValue('afterday', allValues.astatus);
      setValue('nextdate', moment().add('day', allValues.astatus));
    } else if (allValues.astatus === 2) {
      setValue('afterday', '0');
      setValue('nextdate', moment().add('day', 0));
    }
  }, [allValues.astatus, setValue]);

  return (
    <Dialog fullScreen open={open} onKeyDown={handleKeyDown}>
      <FormProvider methods={methods}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            top: 0,
            zIndex: 9,
            position: 'sticky',
            bgcolor: lgUp ? 'primary.dark' : (theme) => alpha(theme.palette.background.default, 1),
            color: lgUp && 'white',
          }}
        >
          <Toolbar>
            <Typography variant="h5" sx={{ flex: 1 }}>
              Update Activity
            </Typography>
            <IconButton color="inherit" edge="start" tabIndex={-1} onClick={onClose}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Toolbar>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Grid>
        <DialogContent>
          <Grid
            spacing={1}
            container
            sx={{
              marginTop: 2,
              mb: 15,
            }}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              spacing={2}
              alignContent="start"
            >
              <Grid item xs={6} sm={6} md={2} lg={1.1} xl={1.1} xxl={1.1}>
                <RHFTextField
                  type="number"
                  variant={lgUp ? 'standard' : 'outlined'}
                  focused={lgUp}
                  name="aenquiryId"
                  label="Enquiry No."
                  size="small"
                  value={enqNo || ''}
                  onChange={(e) => {
                    setNumber(e.target.value);
                  }}
                  autoFocus
                  disabled={!!row?.id}
                />
              </Grid>
              {/* <Grid item xs={12} sm={4} md={2} lg={1.5} xl={1} xxl={1}>
                <Controller
                  name="date"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DateField
                      value={field.value ? new Date(field.value) : null}
                      timezone="Asia/Kolkata"
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      format="dd/MM/yyyy"
                      label="Date"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                          size: 'small',
                        },
                      }}
                      variant={lgUp ? 'standard' : 'outlined'}
                      focused={lgUp}
                      disabled={row?.id}
                    />
                  )}
                />
              </Grid> */}

              <Grid item xs={6} sm={6} md={4} lg={2} xl={2} xxl={1}>
                <RHFTextField
                  name="customer.firstname"
                  label={
                    <span>
                      First Name <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  size="small"
                  variant={lgUp ? 'standard' : 'outlined'}
                  focused={lgUp}
                  value={allValues?.customer?.firstname || ''}
                  error={errors?.customer?.firstname && !allValues?.customer?.firstname}
                  // onChange={(e) => {
                  //   setValue('customer.firstname', e.target.value);
                  // }}
                  disabled={row?.id}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={2} xl={2} xxl={1}>
                <RHFTextField
                  name="customer.lastname"
                  label="Last Name"
                  size="small"
                  variant={lgUp ? 'standard' : 'outlined'}
                  focused={lgUp}
                  value={allValues?.customer?.lastname || ''}
                  error={errors?.customer?.lastname && !allValues?.customer?.lastname}
                  // onChange={(e) => {
                  //   setValue('customer.lastname', e.target.value);
                  // }}
                  disabled={row?.id}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={2} lg={2} xl={1.5} xxl={1}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  size="small"
                  options={GENDER.length ? GENDER : [{ name: 'No Options', value: null }]}
                  groupBy={(option) => option?.a}
                  getOptionLabel={(option) => option.name}
                  openOnFocus
                  onChange={(event, newValue) => {
                    setValue('genderMeta.editRow', newValue);
                    setValue('customer.gender', newValue?.value);
                  }}
                  value={(() => {
                    const addRecord = GENDER?.find(
                      (data) => data.value === allValues?.customer?.gender
                    );
                    const editRecord = row?.ac_group;

                    if (addRecord) return addRecord;
                    if (editRecord) return editRecord;
                    return null;
                  })()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span>
                          Gender <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      name="customer.gender"
                      error={errors?.customer?.gender && !allValues?.customer?.gender}
                      variant={lgUp ? 'standard' : 'outlined'}
                      focused={lgUp}
                      disabled={row?.id}
                    />
                  )}
                  disabled={row?.id}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                      <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                        {option?.name}
                      </ListItem>
                    </Box>
                  )}
                  ListboxProps={{
                    ...bgColorAutocomplete,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={2} xl={1.5} xxl={1}>
                <RHFTextField
                  name="customer.mobile1"
                  label={
                    <span>
                      Mobile No. <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  size="small"
                  type="number"
                  variant={lgUp ? 'standard' : 'outlined'}
                  focused={lgUp}
                  value={allValues?.customer?.mobile1 || ''}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value?.length <= 10) setValue('customer.mobile1', e.target.value);
                  }}
                  inputProps={{
                    pattern: '\\d*',
                  }}
                  disabled={row?.id}
                  error={
                    errors?.customer?.mobile1 &&
                    !allValues?.customer?.mobile1 &&
                    allValues?.customer?.mobile1?.length !== 10
                  }
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4} xl={2} xxl={1}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  size="small"
                  options={salesmans.rows}
                  groupBy={(option) => option?.dpartmentId}
                  getOptionLabel={(option) => option.name}
                  getOptionDisabled={(option) => option?.value === null}
                  openOnFocus
                  onChange={(event, newValue) => {
                    setValue('salesmanMeta.editRow', newValue);
                    setValue('salesmanId', newValue?.id);
                  }}
                  value={(() => {
                    const addRecord = salesmans.rows?.find(
                      (data) => data.id === allValues.salesmanId
                    );
                    const editRecord = row?.ac_group;
                    if (addRecord) return addRecord;
                    if (editRecord) return editRecord;
                    return null;
                  })()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span>
                          Assigned To <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      name="salesmanId"
                      error={errors?.salesmanId && !allValues?.salesmanId}
                      variant={lgUp ? 'standard' : 'outlined'}
                      focused={lgUp}
                      disabled={row?.id}
                    />
                  )}
                  disabled={row?.id}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                      key={option.id}
                      sx={{ minHeight: 'auto !important' }}
                    >
                      <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                    </Box>
                  )}
                  ListboxProps={{
                    ...bgColorAutocomplete,
                    onScroll: (event) =>
                      handleScroll({
                        event,
                        allValues,
                        setValue,
                        fieldName: 'salesmanMeta',
                        meta: allValues.salesmanMeta,
                        swr: salesmans,
                      }),
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} sm={4} md={4} lg={2.9} xl={2.9} xxl={2.9}>
                <RHFTextField
                  name="customer.email"
                  label="Email"
                  size="small"
                  type="text"
                  variant={lgUp ? 'standard' : 'outlined'}
                  focused={lgUp}
                  value={allValues?.customer?.email || ''}
                  onChange={(e) => {
                    setValue('customer.email', e.target.value);
                  }}
                  disabled={row?.id}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={4} md={3} lg={3} xl={2} xxl={2}>
                <Autocomplete
                  autoHighlight={allValues.zoneMeta.query.name?.length > 0}
                  fullWidth
                  size="small"
                  options={zones.rows}
                  groupBy={(option) => option?.a}
                  getOptionLabel={(option) => option.name}
                  filterOptions={(options, state) => {
                    if (zones.rows.length) {
                      const filteredZones = zones.rows.filter((zone) =>
                        zone.name.toLowerCase().includes(state.inputValue.toLowerCase())
                      );
                      return filteredZones.length
                        ? filteredZones
                        : [{ name: 'No matching options', value: null }];
                    }
                    return [{ name: 'No Options', value: null }];
                  }}
                  inputValue={allValues.zoneMeta.query.name}
                  onInputChange={(event, newInputValue) => {
                    setValue('zoneMeta.query.name', newInputValue);
                  }}
                  openOnFocus
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    setValue('zoneMeta.editRow', newValue);
                    setValue('customer.localityId', newValue?.id);
                    setValue('customer.locality', newValue);
                  }}
                  value={allValues.customer.locality}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Zone"
                      name="customer.localityId"
                      error={errors?.customer?.localityId && !allValues?.customer?.localityId}
                      variant={lgUp ? 'standard' : 'outlined'}
                      focused={lgUp}
                    />
                  )}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.id}>
                      <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                    </Box>
                  )}
                  ListboxProps={{ ...bgColorAutocomplete }}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={4} md={3} lg={3} xl={2} xxl={2}>
                <Autocomplete
                  autoHighlight={allValues.cityMeta?.query?.name?.length > 0}
                  fullWidth
                  size="small"
                  options={cities.rows}
                  groupBy={(option) => option?.a}
                  getOptionLabel={(option) => option.name}
                  filterOptions={(options, state) =>
                    cities.rows.length ? cities.rows : [{ name: 'No Options', value: null }]
                  }
                  inputValue={allValues.cityMeta?.query?.name}
                  onInputChange={(event, newInputValue) => {
                    setValue('cityMeta.query.name', newInputValue);
                  }}
                  openOnFocus
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    setValue('cityMeta.editRow', newValue);
                    setValue('customer.cityId', newValue?.id);
                    setValue('customer.city', newValue);
                  }}
                  value={allValues?.customer?.city}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select City"
                      name="customer.cityId"
                      error={errors?.customer?.cityId && !allValues?.customer?.cityId}
                      variant={lgUp ? 'standard' : 'outlined'}
                      focused={lgUp}
                      disabled={row?.id}
                    />
                  )}
                  disabled={row?.id}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                      key={option.id}
                      sx={{ minHeight: 'auto !important' }}
                    >
                      <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                    </Box>
                  )}
                  ListboxProps={{
                    ...bgColorAutocomplete,
                    onScroll: (event) =>
                      handleScroll({
                        event,
                        allValues,
                        setValue,
                        fieldName: 'cityMeta',
                        meta: allValues.cityMeta,
                        swr: cities,
                      }),
                  }}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={4} md={2} lg={1.5} xl={1.5} xxl={1}>
                <Controller
                  name="customer.dob"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DateField
                      value={field.value ? new Date(field.value) : null}
                      timezone="Asia/Kolkata"
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      format="dd/MM/yyyy"
                      label="Birth Date"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                          size: 'small',
                        },
                      }}
                      variant={lgUp ? 'standard' : 'outlined'}
                      focused={lgUp}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={1.5} xl={1.5} xxl={1}>
                <Controller
                  name="customer.adate"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DateField
                      value={field.value ? new Date(field.value) : null}
                      timezone="Asia/Kolkata"
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      format="dd/MM/yyyy"
                      label="Anniv. Date"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                          size: 'small',
                        },
                      }}
                      variant={lgUp ? 'standard' : 'outlined'}
                      focused={lgUp}
                    />
                  )}
                />
              </Grid> */}

              {/* <Grid item xs={12} sm={12} md={5} lg={6} xl={5} xxl={6}>
                <RHFTextField
                  name="customer.address1"
                  label="Address"
                  size="small"
                  multiline
                  variant={lgUp ? 'standard' : 'outlined'}
                  focused={lgUp}
                  rows={isSmallScreen ? 2 : 1}
                  value={allValues?.customer?.address1 || ''}
                  onChange={(e) => {
                    setValue('customer.address1', e.target.value);
                  }}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Product Details
                </Typography>
                <Divider sx={{ borderStyle: 'dashed' }} />
              </Grid> */}
              {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={2} xxl={1}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  size="small"
                  options={categories.rows}
                  groupBy={(option) => option?.a}
                  getOptionLabel={(option) => option.name}
                  getOptionDisabled={(option) => option?.value === null}
                  openOnFocus
                  onChange={(event, newValue) => {
                    setValue('aenqcatieMeta.editRow', newValue);
                    setValue('aenqcatieId', newValue?.id);
                  }}
                  value={(() => {
                    const addRecord = categories.rows?.find(
                      (data) => data.id === allValues.aenqcatieId
                    );
                    const editRecord = row?.ac_group;

                    if (addRecord) return addRecord;
                    if (editRecord) return editRecord;
                    return null;
                  })()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span>
                          Enquiry Category <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      name="aenqcatieId"
                      error={errors?.aenqcatieId && !allValues?.aenqcatieId}
                      variant={lgUp ? 'standard' : 'outlined'}
                      focused={lgUp}
                      disabled={row?.id}
                    />
                  )}
                  disabled={row?.id}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                      key={option.id}
                      sx={{ minHeight: 'auto !important' }}
                    >
                      <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                    </Box>
                  )}
                  ListboxProps={{ ...bgColorAutocomplete }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} xxl={1}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  size="small"
                  options={types.rows}
                  groupBy={(option) => option?.a}
                  getOptionLabel={(option) => option.name}
                  getOptionDisabled={(option) => option?.value === null}
                  openOnFocus
                  onChange={(event, newValue) => {
                    setValue('aenqtypesMeta.editRow', newValue);
                    setValue('aenqtypeId', newValue?.id);
                  }}
                  value={(() => {
                    const addRecord = types.rows?.find((data) => data.id === allValues.aenqtypeId);
                    const editRecord = row?.ac_group;

                    if (addRecord) return addRecord;
                    if (editRecord) return editRecord;
                    return null;
                  })()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span>
                          Enquiry Type <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      name="aenqtypeId"
                      error={errors?.aenqtypeId && !allValues?.aenqtypeId}
                      variant={lgUp ? 'standard' : 'outlined'}
                      focused={lgUp}
                      disabled={row?.id}
                    />
                  )}
                  disabled={row?.id}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                      key={option.id}
                      sx={{ minHeight: 'auto !important' }}
                    >
                      <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                    </Box>
                  )}
                  ListboxProps={{ ...bgColorAutocomplete }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} xxl={1}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  size="small"
                  options={sources.rows}
                  groupBy={(option) => option?.a}
                  getOptionLabel={(option) => option.name}
                  getOptionDisabled={(option) => option?.value === null}
                  openOnFocus
                  onChange={(event, newValue) => {
                    setValue('aenqsrceMeta.editRow', newValue);
                    setValue('aenqsrceId', newValue?.id);
                  }}
                  value={(() => {
                    const addRecord = sources.rows?.find(
                      (data) => data.id === allValues.aenqsrceId
                    );
                    const editRecord = row?.ac_group;

                    if (addRecord) return addRecord;
                    if (editRecord) return editRecord;
                    return null;
                  })()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span>
                          Enquiry Source <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      name="aenqsrceId"
                      error={errors?.aenqsrceId && !allValues?.aenqsrceId}
                      variant={lgUp ? 'standard' : 'outlined'}
                      focused={lgUp}
                      disabled={row?.id}
                    />
                  )}
                  disabled={row?.id}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                      key={option.id}
                      sx={{ minHeight: 'auto !important' }}
                    >
                      <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                    </Box>
                  )}
                  ListboxProps={{ ...bgColorAutocomplete }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={2} xxl={1}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  size="small"
                  options={purtypes.rows}
                  groupBy={(option) => option?.a}
                  getOptionLabel={(option) => option.name}
                  getOptionDisabled={(option) => option?.value === null}
                  openOnFocus
                  onChange={(event, newValue) => {
                    setValue('apurtypeMeta.editRow', newValue);
                    setValue('apurtypeId', newValue?.id);
                  }}
                  value={(() => {
                    const addRecord = purtypes.rows?.find(
                      (data) => data.id === allValues.apurtypeId
                    );
                    const editRecord = row?.ac_group;

                    if (addRecord) return addRecord;
                    if (editRecord) return editRecord;
                    return null;
                  })()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span>
                          Purchase Type <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      name="apurtypeId"
                      error={errors?.apurtypeId && !allValues?.apurtypeId}
                      variant={lgUp ? 'standard' : 'outlined'}
                      focused={lgUp}
                      disabled={row?.id}
                    />
                  )}
                  disabled={row?.id}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                      key={option.id}
                      sx={{ minHeight: 'auto !important' }}
                    >
                      <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                    </Box>
                  )}
                  ListboxProps={{ ...bgColorAutocomplete }}
                />
              </Grid> */}

              {allValues.apurtypeId === 2 && (
                <>
                  {' '}
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={2} xxl={1}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      size="small"
                      options={finances.rows}
                      groupBy={(option) => option?.a}
                      getOptionLabel={(option) => option.name}
                      openOnFocus
                      onChange={(event, newValue) => {
                        setValue('afinancesMeta.editRow', newValue);
                        setValue('afinancesId', newValue?.id);
                      }}
                      value={(() => {
                        const addRecord = finances.rows?.find(
                          (data) => data.id === allValues.afinancesId
                        );
                        const editRecord = row?.finance;

                        if (addRecord) return addRecord;
                        if (editRecord) return editRecord;
                        return null;
                      })()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              Financier <span style={{ color: 'red' }}>*</span>
                            </span>
                          }
                          name="afinancesId"
                          error={errors?.afinancesId && !allValues?.afinancesId}
                          variant={lgUp ? 'standard' : 'outlined'}
                          focused={lgUp}
                          disabled={row?.id}
                        />
                      )}
                      disabled={row?.id}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                          <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                            {option?.name}
                          </ListItem>
                        </Box>
                      )}
                      ListboxProps={{ ...bgColorAutocomplete }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={3} lg={3} xl={2} xxl={1}>
                    <RHFCheckbox name="counter" label="Counter Sale" disabled={row?.id} />
                  </Grid> */}
                </>
              )}

              {/* <Grid item xs={12} sm={6} md={3} lg={3} xl={2} xxl={1}>
                <RHFCheckbox name="exchange" label="Exchange" disabled={row?.id} />
              </Grid> */}

              {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={3} xxl={1}>
                <Autocomplete
                  autoHighlight={allValues.avehicleMeta.query.name?.length > 0}
                  fullWidth
                  size="small"
                  options={vehicles.rows}
                  groupBy={(option) => option?.a}
                  getOptionLabel={(option) => option.name}
                  getOptionDisabled={(option) => option?.value === null}
                  filterOptions={(options, state) =>
                    vehicles.rows.length ? vehicles.rows : [{ name: 'No Options', value: null }]
                  }
                  inputValue={allValues.avehicleMeta.query.name}
                  onInputChange={(event, newInputValue) => {
                    setValue('avehicleMeta.query.name', newInputValue);
                  }}
                  openOnFocus
                  onChange={(event, newValue) => {
                    setValue('avehicleMeta.editRow', newValue);
                    setValue('avehicleId', newValue?.id);
                  }}
                  value={(() => {
                    const addRecord = vehicles.rows?.find(
                      (data) => data.id === allValues.avehicleId
                    );
                    const editRecord = row?.ac_group;

                    if (addRecord) return addRecord;
                    if (editRecord) return editRecord;
                    return null;
                  })()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Vehicle"
                      name="avehicleId"
                      error={errors?.avehicleId && !allValues?.avehicleId}
                      variant={lgUp ? 'standard' : 'outlined'}
                      focused={lgUp}
                    />
                  )}
                  renderGroup={(params) => (
                    <li key={params.key + 11}>
                      <GroupHeader component="li">
                        <ListItem sx={{ padding: 0, margin: 0 }} key={params.key + 2}>
                          Name
                        </ListItem>
                      </GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                      <GroupFooter component="li">
                        <Button
                          onClick={() => {
                            setFieldName('avehicleId');
                            quickEditStatus.onTrue();
                            setValue('avehicleMeta.editRow', null);
                          }}
                        >
                          Add
                        </Button>
                        {allValues.avehicleMeta.editRow && (
                          <>
                            <Button
                              onClick={() => {
                                quickEditStatus.onTrue();
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              onClick={() => {
                                confirm.onTrue();
                              }}
                            >
                              Delete
                            </Button>
                          </>
                        )}
                      </GroupFooter>
                    </li>
                  )}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.id}>
                      <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                    </Box>
                  )}
                  ListboxProps={{
                    ...bgColorAutocomplete,
                    onScroll: (event) =>
                      handleScroll({
                        event,
                        allValues,
                        setValue,
                        fieldName: 'avehicleMeta',
                        meta: allValues.avehicleMeta,
                        swr: vehicles,
                      }),
                  }}
                />
              </Grid> */}

              <Grid item xs={6} sm={6} md={6} lg={3} xl={2} xxl={1}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  size="small"
                  options={modelCategory.rows}
                  groupBy={(option) => option?.a}
                  getOptionLabel={(option) => option.name}
                  getOptionDisabled={(option) => option?.value === null}
                  openOnFocus
                  onChange={(event, newValue) => {
                    setValue('modelCategoryMeta.editRow', newValue);
                    setValue('amodcategoryId', newValue?.id);
                    setValue('amodelId', null);
                    setValue('avariantId', null);
                    setValue('amtocId', null);
                  }}
                  value={(() => {
                    const addRecord = modelCategory.rows?.find(
                      (data) => data.id === allValues.amodcategoryId
                    );
                    const editRecord = row?.ac_group;

                    if (addRecord) return addRecord;
                    if (editRecord) return editRecord;
                    return null;
                  })()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span>
                          Model Category <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      name="amodcategoryId"
                      error={errors?.amodcategoryId && !allValues?.amodcategoryId}
                      variant={lgUp ? 'standard' : 'outlined'}
                      focused={lgUp}
                      disabled={row?.id}
                    />
                  )}
                  disabled={row?.id}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                      key={option.id}
                      sx={{ minHeight: 'auto !important' }}
                    >
                      <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                    </Box>
                  )}
                  ListboxProps={{ ...bgColorAutocomplete }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={2} xxl={1}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  size="small"
                  options={productModel.rows.filter(
                    (data) => data.amodcategoryId === allValues.amodcategoryId
                  )}
                  groupBy={(option) => option?.a}
                  getOptionLabel={(option) => option.name}
                  getOptionDisabled={(option) => option?.value === null}
                  openOnFocus
                  onChange={(event, newValue) => {
                    setValue('productModelMeta.editRow', newValue);
                    setValue('amodelId', newValue?.id);
                    setValue('avariantId', null);
                    setValue('amtocId', null);
                  }}
                  value={(() => {
                    const addRecord = productModel.rows?.find(
                      (data) => data.id === allValues.amodelId
                    );
                    const editRecord = row?.ac_group;

                    if (addRecord) return addRecord;
                    if (editRecord) return editRecord;
                    return null;
                  })()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span>
                          Product Model <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      name="amodelId"
                      error={errors?.amodelId && !allValues?.amodelId}
                      variant={lgUp ? 'standard' : 'outlined'}
                      focused={lgUp}
                      disabled={row?.id}
                    />
                  )}
                  disabled={row?.id}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                      key={option.id}
                      sx={{ minHeight: 'auto !important' }}
                    >
                      <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                    </Box>
                  )}
                  ListboxProps={{ ...bgColorAutocomplete }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={2} xxl={1}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  size="small"
                  options={productVariant.rows.filter(
                    (data) => data.amodelId === allValues.amodelId
                  )}
                  groupBy={(option) => option?.a}
                  getOptionLabel={(option) => option.name}
                  getOptionDisabled={(option) => option?.value === null}
                  openOnFocus
                  onChange={(event, newValue) => {
                    setValue('productVariantMeta.editRow', newValue);
                    setValue('avariantId', newValue?.id);
                    setValue('amtocId', null);
                  }}
                  value={(() => {
                    const addRecord = productVariant.rows?.find(
                      (data) => data.id === allValues.avariantId
                    );
                    const editRecord = row?.ac_group;

                    if (addRecord) return addRecord;
                    if (editRecord) return editRecord;
                    return null;
                  })()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span>
                          Product Variant <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      name="avariantId"
                      error={errors?.avariantId && !allValues?.avariantId}
                      variant={lgUp ? 'standard' : 'outlined'}
                      focused={lgUp}
                      disabled={row?.id}
                    />
                  )}
                  disabled={row?.id}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                      key={option.id}
                      sx={{ minHeight: 'auto !important' }}
                    >
                      <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                    </Box>
                  )}
                  ListboxProps={{ ...bgColorAutocomplete }}
                />
              </Grid>
              {/* <Grid item xs={6} sm={6} md={6} lg={3} xl={2} xxl={1}>
                <Autocomplete
                  autoHighlight
                  fullWidth
                  size="small"
                  options={productMtoc.rows.filter(
                    (data) => data.avariantId === allValues.avariantId
                  )}
                  groupBy={(option) => option?.a}
                  getOptionLabel={(option) => option.name}
                  getOptionDisabled={(option) => option?.value === null}
                  openOnFocus
                  onChange={(event, newValue) => {
                    setValue('productMtocMeta.editRow', newValue);
                    setValue('amtocId', newValue?.id);
                    setValue('acolourId', newValue?.acolour?.id);
                    setValue('acolour', newValue?.acolour);
                  }}
                  value={(() => {
                    const addRecord = productMtoc.rows?.find(
                      (data) => data.id === allValues.amtocId
                    );
                    const editRecord = row?.ac_group;

                    if (addRecord) return addRecord;
                    if (editRecord) return editRecord;
                    return null;
                  })()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span>
                          MTOC (Product) <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      name="amtocId"
                      error={errors?.amtocId && !allValues?.amtocId}
                      variant={lgUp ? 'standard' : 'outlined'}
                      focused={lgUp}
                      disabled={row?.id}
                    />
                  )}
                  disabled={row?.id}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                      key={option.id}
                      sx={{ minHeight: 'auto !important' }}
                    >
                      <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                    </Box>
                  )}
                  ListboxProps={{ ...bgColorAutocomplete }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4} xl={3} xxl={1}>
                <RHFTextField
                  name="acolourId"
                  label="Modal Color"
                  size="small"
                  variant={lgUp ? 'standard' : 'outlined'}
                  focused={lgUp}
                  value={allValues.acolour?.name || ''}
                  error={errors?.acolourId && !allValues?.acolourId}
                  disabled
                />
              </Grid> */}

              {/* {allValues.exchange && (
                <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Exchange Vehicle Information
                    </Typography>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    spacing={2}
                    alignContent="start"
                  >
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2} xxl={1}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={modelMakers.rows}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        getOptionDisabled={(option) => option?.value === null}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('modelMakersMeta.editRow', newValue);
                          setValue('amakerId', newValue?.id);
                        }}
                        value={(() => {
                          const addRecord = modelMakers.rows?.find(
                            (data) => data.id === allValues.amakerId
                          );
                          const editRecord = row?.ac_group;

                          if (addRecord) return addRecord;
                          if (editRecord) return editRecord;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Vehicle Make"
                            name="amakerId"
                            error={errors?.amakerId && !allValues?.amakerId}
                            variant={lgUp ? 'standard' : 'outlined'}
                            focused={lgUp}
                            required={allValues.exchange}
                            disabled={row?.id}
                          />
                        )}
                        disabled={row?.id}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            key={option.id}
                            sx={{ minHeight: 'auto !important' }}
                          >
                            <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                          </Box>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2} xxl={1}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={productModel.rows.filter(
                          (data) => data.amakerId === allValues.amakerId
                        )}
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        getOptionDisabled={(option) => option?.value === null}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('productModelMeta.editRow', newValue);
                          setValue('emodelId', newValue?.id);
                        }}
                        value={(() => {
                          const addRecord = productModel.rows?.find(
                            (data) => data.id === allValues.emodelId
                          );
                          const editRecord = row?.ac_group;

                          if (addRecord) return addRecord;
                          if (editRecord) return editRecord;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Vehicle Model"
                            name="emodelId"
                            error={errors?.emodelId && !allValues?.emodelId}
                            variant={lgUp ? 'standard' : 'outlined'}
                            focused={lgUp}
                            required={allValues.exchange}
                            disabled={row?.id}
                          />
                        )}
                        disabled={row?.id}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            key={option.id}
                            sx={{ minHeight: 'auto !important' }}
                          >
                            <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                          </Box>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={1.5} xxl={1}>
                      <RHFTextField
                        name="yofmfd"
                        label="Model Year"
                        size="small"
                        type="number"
                        variant={lgUp ? 'standard' : 'outlined'}
                        focused={lgUp}
                        value={allValues?.yofmfd || ''}
                        error={errors?.yofmfd && !allValues?.yofmfd}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.length <= 4) {
                            setValue('yofmfd', value);
                          }
                        }}
                        inputProps={{
                          pattern: '\\d*',
                        }}
                        disabled={row?.id}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2} xxl={1}>
                      <Autocomplete
                        autoHighlight
                        fullWidth
                        size="small"
                        options={
                          KMSDRIVEN.length ? KMSDRIVEN : [{ name: 'No Options', value: null }]
                        }
                        groupBy={(option) => option?.a}
                        getOptionLabel={(option) => option.name}
                        openOnFocus
                        onChange={(event, newValue) => {
                          setValue('kmsdriven', newValue?.value);
                        }}
                        value={(() => {
                          const addRecord = KMSDRIVEN?.find(
                            (data) => data.value === allValues.kmsdriven
                          );
                          const editRecord = row?.ac_group;

                          if (addRecord) return addRecord;
                          if (editRecord) return editRecord;
                          return null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Kms Driven"
                            name="kmsdriven"
                            error={errors?.kmsdriven && !allValues?.kmsdriven}
                            variant={lgUp ? 'standard' : 'outlined'}
                            focused={lgUp}
                            disabled={row?.id}
                          />
                        )}
                        disabled={row?.id}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                            <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                              {option?.name}
                            </ListItem>
                          </Box>
                        )}
                        ListboxProps={{ ...bgColorAutocomplete }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2} xxl={1}>
                      <RHFTextField
                        name="eamt"
                        label="Proposed Amount"
                        size="small"
                        type="number"
                        disabled={row?.id}
                        error={errors?.eamt && !allValues?.eamt}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )} */}

              <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={2}>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  spacing={2}
                  alignContent="start"
                >
                  {/* <Grid item xs={12} sm={6} md={2} lg={1.5} xl={1.5} xxl={1.5}>
                    <RHFCheckbox name="testrideflag" label="Test Ride" disabled={row?.id} />
                  </Grid>

                  {allValues.testrideflag && (
                    <Grid item xs={12} sm={6} md={2} lg={3} xl={2} xxl={1}>
                      <Controller
                        name="testrideDate"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <DatePicker
                            value={field.value ? new Date(field.value) : null}
                            timezone="Asia/Kolkata"
                            onChange={(newValue) => {
                              field.onChange(newValue);
                            }}
                            format="dd/MM/yyyy"
                            label="Test Ride Date"
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                error: !!error,
                                helperText: error?.message,
                                size: 'small',
                              },
                            }}
                            variant={lgUp ? 'standard' : 'outlined'}
                            focused={lgUp}
                            disabled={row?.id}
                          />
                        )}
                      />
                    </Grid>
                  )} */}

                  <Grid item xs={6} sm={6} md={12} lg={4} xl={4} xxl={4}>
                    <RHFTextField
                      name="activityType"
                      label="Activity Type"
                      size="small"
                      value={activeTab?.toUpperCase()}
                      variant={lgUp ? 'standard' : 'outlined'}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={2} lg={2} xl={1.5} xxl={1}>
                    <Autocomplete
                      autoHighlight
                      fullWidth
                      size="small"
                      options={
                        ACTIVITYSTATUS.length
                          ? ACTIVITYSTATUS
                          : [{ name: 'No Options', value: null }]
                      }
                      groupBy={(option) => option?.a}
                      getOptionLabel={(option) => option.name}
                      openOnFocus
                      onChange={(event, newValue) => {
                        setValue('astatus', newValue?.value);
                      }}
                      value={(() => {
                        const addRecord = ACTIVITYSTATUS?.find(
                          (data) => data.value === Number(allValues?.astatus)
                        );
                        const editRecord = row?.ac_group;

                        if (addRecord) return addRecord;
                        if (editRecord) return editRecord;
                        return null;
                      })()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              Activity Status <span style={{ color: 'red' }}>*</span>
                            </span>
                          }
                          name="astatus"
                          error={errors?.astatus && !allValues?.astatus}
                          placeholder="Select Activity Status"
                          variant={lgUp ? 'standard' : 'outlined'}
                          focused={lgUp}
                          disabled={row?.astatus?.toString() !== '1'}
                        />
                      )}
                      disabled={row?.astatus?.toString() !== '1'}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} sx={{ minHeight: 'auto !important' }}>
                          <ListItem key={option.id} sx={{ padding: 0, margin: 0 }}>
                            {option?.name}
                          </ListItem>
                        </Box>
                      )}
                      ListboxProps={{
                        ...bgColorAutocomplete,
                      }}
                    />
                  </Grid>
                  {allValues?.astatus?.toString() === '2' && (
                    <>
                      <Grid item xs={6} sm={6} md={6} lg={4} xl={2.5} xxl={2}>
                        <Autocomplete
                          autoHighlight
                          fullWidth
                          size="small"
                          options={reasones.rows}
                          getOptionLabel={(option) => option.name}
                          filterOptions={(options, state) => {
                            if (reasones?.rows?.length) {
                              const filteredZones = reasones.rows.filter((zone) =>
                                zone.name?.toLowerCase().includes(state.inputValue?.toLowerCase())
                              );
                              return filteredZones?.length
                                ? filteredZones
                                : [{ name: 'No matching options', value: null }];
                            }
                            return [{ name: 'No Options', value: null }];
                          }}
                          openOnFocus
                          onChange={(event, newValue) => {
                            setValue('areasoneId', newValue?.id);
                            setValue('areasone', newValue);
                          }}
                          value={allValues.areasone}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <span>
                                  Reason For Closure <span style={{ color: 'red' }}>*</span>
                                </span>
                              }
                              name="areasoneId"
                              error={
                                errors?.areasoneId &&
                                !allValues?.areasoneId &&
                                allValues.afterday === '0'
                              }
                              variant={lgUp ? 'standard' : 'outlined'}
                              focused={lgUp}
                              disabled={row?.astatus?.toString() !== '1'}
                            />
                          )}
                          disabled={row?.astatus?.toString() !== '1'}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              {...props}
                              key={option.id}
                              sx={{ minHeight: 'auto !important' }}
                            >
                              <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                            </Box>
                          )}
                          componentsProps={{
                            paper: {
                              sx: {
                                width: 280,
                              },
                            },
                          }}
                          ListboxProps={{ ...bgColorAutocomplete }}
                        />
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={4} xl={2.5} xxl={2}>
                        <Autocomplete
                          autoHighlight
                          fullWidth
                          size="small"
                          options={
                            allValues?.areasone?.closurremarks?.length
                              ? allValues?.areasone?.closurremarks
                              : [{ name: 'No Options', value: null }]
                          }
                          getOptionLabel={(option) => option.name}
                          filterOptions={(options, state) => {
                            if (allValues?.areasone?.closurremarks?.length) {
                              const filteredZones = allValues?.areasone?.closurremarks.filter((x) =>
                                x.name?.toLowerCase().includes(state.inputValue?.toLowerCase())
                              );
                              return filteredZones?.length
                                ? filteredZones
                                : [{ name: 'No matching options', value: null }];
                            }
                            return [{ name: 'No Options', value: null }];
                          }}
                          openOnFocus
                          onChange={(event, newValue) => {
                            setValue('closeremarkId', newValue?.id);
                            setValue('closeremark', newValue);
                          }}
                          value={allValues.areasone}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <span>
                                  Closure Remarks <span style={{ color: 'red' }}>*</span>
                                </span>
                              }
                              name="closeremarkId"
                              error={
                                errors?.closeremarkId &&
                                !allValues?.closeremarkId &&
                                allValues.afterday === '0'
                              }
                              variant={lgUp ? 'standard' : 'outlined'}
                              focused={lgUp}
                              disabled={row?.astatus?.toString() !== '1'}
                            />
                          )}
                          disabled={row?.astatus?.toString() !== '1'}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              {...props}
                              key={option.id}
                              sx={{ minHeight: 'auto !important' }}
                            >
                              <ListItem sx={{ padding: 0, margin: 0 }}>{option?.name}</ListItem>
                            </Box>
                          )}
                          ListboxProps={{ ...bgColorAutocomplete }}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Select Date
                    </Typography>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                  </Grid>

                  {console.log(allValues.astatus, 'astatus')}

                  <Grid item xs={12} sm={6} md={3} lg={3} xl={1.5} xxl={1}>
                    <RHFTextField
                      name="afterday"
                      type="number"
                      label={
                        <span>
                          After Day <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      size="small"
                      variant={lgUp ? 'standard' : 'outlined'}
                      focused={lgUp}
                      value={allValues.afterday || ''}
                      onChange={(e) => {
                        if (e.target.value !== allValues.afterday) {
                          setValue('afterday', e.target.value);
                          if (e.target.value)
                            setValue('nextdate', moment().add('day', e.target.value));
                          else setValue('nextdate', moment());
                        }
                      }}
                      disabled={row?.astatus?.toString() !== '1' || allValues.astatus === 2}
                      error={errors?.afterday && !allValues?.afterday}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={1.5} xxl={1}>
                    <Controller
                      name="nextdate"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DatePicker
                          value={field.value ? new Date(field.value) : null}
                          timezone="Asia/Kolkata"
                          onChange={(newValue) => {
                            field.onChange(newValue);
                            // Calculate the difference in days between newValue and the current date.
                            const currentDate = moment(); // This gets the current date.
                            const selectedDate = moment(newValue); // Convert the new value to a Moment.js object.
                            const differenceInDays = selectedDate.diff(currentDate, 'days');

                            // Now, set the value of "afterday" to the calculated difference in days.
                            // Assume `setValue` is available in your component's props or context.
                            if (differenceInDays !== allValues.afterday)
                              setValue('afterday', differenceInDays + 1);
                          }}
                          format="dd/MM/yyyy"
                          label="Next FollowUp Date"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!error,
                              helperText: error?.message,
                              size: 'small',
                            },
                          }}
                          disabled={row?.astatus?.toString() !== '1' || allValues.astatus}
                          focused={lgUp}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                    <RHFTextField
                      name="remarks"
                      label={
                        <span>
                          Remarks <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      size="small"
                      onChange={(e) => {
                        setValue('remarks', e.target.value);
                      }}
                      multiline
                      rows={2}
                      variant={lgUp ? 'standard' : 'outlined'}
                      focused={lgUp}
                      disabled={row?.astatus?.toString() !== '1'}
                      error={errors?.remarks && !allValues?.remarks}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    sx={{ display: 'grid' }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => {
                        activityListOpen.onTrue();
                      }}
                    >
                      <Iconify icon="fluent:shifts-activity-24-filled" /> &nbsp;&nbsp;&nbsp;Activity
                      History
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <Stack
          direction="row-reverse"
          alignItems="center"
          sx={{
            bottom: 0,
            zIndex: 111111,
            p: 2,
            position: 'fixed',
            justifyContent: 'space-between',
            width: '100%',
            display: lgUp && 'block',
            bgcolor: lgUp ? 'primary.dark' : (theme) => alpha(theme.palette.background.default, 1),
            color: lgUp && 'white',
          }}
        >
          {lgUp && row?.astatus?.toString() === '1' && (
            <Typography
              variant="subtitle2"
              id="add-voucher"
              onClick={async () => {
                console.log(errors, 'KISHAN ERRORS');

                if (await trigger()) {
                  onSubmit(allValues);
                }
              }}
              sx={{ textAlign: 'center', cursor: 'pointer' }}
            >
              {loading ? 'Loading...' : 'APPLY'}
            </Typography>
          )}

          <Grid flexGrow={1} spacing={2} />
        </Stack>
      </FormProvider>

      {fieldName && fieldName !== 'avehicleId' && allfiledmodel[fieldName]?.fieldMeta && (
        <GlobalQuickForm
          row={allValues[allfiledmodel[fieldName].fieldMeta].editrow}
          open={quickEditStatus.value}
          onClose={quickEditStatus.onFalse}
          modeldata={allfiledmodel[fieldName]}
        />
      )}
      {fieldName && fieldName === 'avehicleId' && allfiledmodel[fieldName]?.fieldMeta && (
        <VehicleQuickEditForm
          row={allValues[allfiledmodel[fieldName].fieldMeta].editrow}
          open={quickEditStatus.value}
          onClose={quickEditStatus.onFalse}
          modeldata={allfiledmodel[fieldName]}
        />
      )}

      {activityListOpen.value && (
        <ActivityListing
          row={row}
          open={activityListOpen.value}
          onClose={activityListOpen.onFalse}
        />
      )}
    </Dialog>
  );
}

MobileFollowUp.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  getAll: PropTypes.func,
  open: PropTypes.bool,
  table: PropTypes.object,
  tableData: PropTypes.array,
  activeTab: PropTypes.string,
};
