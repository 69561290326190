import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, nameDisabled = true, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <div style={{ height: '50px', width: '50px' }}>
        <svg
          width="45"
          height="45"
          viewBox="0 0 512 512"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M256 409.6C215.262 409.6 176.194 393.417 147.388 364.612C118.582 335.806 102.4 296.737 102.4 256C102.4 215.263 118.582 176.194 147.388 147.388C176.194 118.583 215.262 102.4 256 102.4L256 256L256 409.6Z"
            fill="#0048D3"
          />
          <path
            d="M409.6 256C409.6 276.171 405.627 296.145 397.907 314.78C390.188 333.416 378.874 350.349 364.611 364.612C350.348 378.875 333.415 390.189 314.78 397.908C296.144 405.627 276.171 409.6 256 409.6L256 256L409.6 256Z"
            fill="#FBC813"
          />
        </svg>
      </div>
      <div
        style={{
          fontWeight: 'bold',
          alignSelf: 'center',
          marginLeft: '12px',
          marginTop: '5px',
          fontSize: '25px',
        }}
      >
        {nameDisabled && 'MYBOOK'}
      </div>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  nameDisabled: PropTypes.bool,
};

export default Logo;
